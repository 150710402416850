import { Component, Input } from '@angular/core';
import { SkeletonConfig } from '@app/shared/models/dialog';

@Component({
  selector: 'loading-skeleton',
  templateUrl: './loading-skeleton.component.html',
  styleUrls: ['./loading-skeleton.component.scss']
})
export class LoadingSkeletonComponent {
  @Input() loadingConfig: SkeletonConfig = {
    rounded: false,
  };
  @Input() peopleCardVertical = false;
  @Input() loadingShareGroup = false;
  @Input() isHistoryCoin = false;
  @Input() rectSkeleton = false;
  @Input() rectSkeletonWidth = '';
  @Input() rectSkeletonHeight = '';
  @Input() rectSkeletonRounded = '';
}
