import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Access, listQuickAccess } from '@app/shared/models/post';

@Component({
  selector: 'quick-access-popup',
  templateUrl: './quick-access-popup.component.html',
  styleUrls: ['./quick-access-popup.component.scss']
})
export class QuickAccessPopupComponent {
  @Input() isDialogVisible = false;
  @Output() isDialogVisibleChange = new EventEmitter<boolean>();
  @Output() onApplyDisplayQuickAccess = new EventEmitter();
  listQuickAccess: Access[];
  listDisplayQuickAccess: Access[];
  constructor(private router: Router) {}

  ngOnInit() {
    const storedDisplayAccesses = JSON.parse(localStorage.getItem('listDisplayQuickAccess') || '[]');
    this.listQuickAccess = !storedDisplayAccesses.length
      ? listQuickAccess(this.router)
      : listQuickAccess(this.router).filter(
          access => !storedDisplayAccesses.some((displayAccess: any) => displayAccess.index === access.index)
        );
    this.listDisplayQuickAccess = storedDisplayAccesses.length ? storedDisplayAccesses : [];
  }

  onHide(): void {
    this.isDialogVisibleChange.emit(false);
  }

  addToDisplayList(item: any): void {
    this.listDisplayQuickAccess = [...this.listDisplayQuickAccess, item];
    const index = this.listQuickAccess.indexOf(item);
    if (index !== -1) {
      this.listQuickAccess.splice(index, 1);
    }
  }

  removeFromDisplayList(item: any): void {
    const originalIndex = item.index;
    this.listQuickAccess.splice(originalIndex, 0, item);
    const index = this.listDisplayQuickAccess.indexOf(item);
    if (index !== -1) {
      this.listDisplayQuickAccess.splice(index, 1);
    }
  }

  applyDisplayQuickAccess() {
    localStorage.setItem('listDisplayQuickAccess', JSON.stringify(this.listDisplayQuickAccess));
    this.isDialogVisibleChange.emit(false);
    this.onApplyDisplayQuickAccess.emit(this.listDisplayQuickAccess);
  }
}
