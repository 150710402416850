import { Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AccountSettingNotificationsService } from '@app/core/services/account-setting-notifications.service';
import { CommonService } from '@app/core/services/common.service';
import { NotificationsService } from '@app/core/services/notifications.service';
import { ACCOUNT_SETTING_KEY, NotificationType } from '@app/shared/constant';
import { MessagingService } from '@app/shared/messaging.service';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
interface Notification {
  id: string;
  avatar_url: string;
  description: string;
  name: string;
  date: string;
  isView: boolean;
  color: string;
  icon: string;
  type: number;
}

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @Input() notificationList: any[];
  @Input() badgeRead: any;
  @Input() overlayVisible = false;
  @Input() mobileOverlayVisible = false;
  @Output() updateBadgeRead = new EventEmitter();
  @Output() overlayVisibleChange = new EventEmitter();
  @Output() mobileOverlayVisibleChange = new EventEmitter();
  @ViewChildren('overlayPanelElement') overlayPanels!: QueryList<OverlayPanel>;
  items: MenuItem[];
  pageNum = 0;
  pageSize = 100;
  fromNow = '';

  showButtonIndex: number | null = null;
  indexOverlay: number;
  noti: any;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private accountSettingNotificationsService: AccountSettingNotificationsService,
    private messagingService: MessagingService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'New',
        icon: 'pi pi-fw pi-plus'
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash'
      }
    ];
    this.messagingService.receiveMessage().subscribe(() => {
      this.incrementBadgeCount();
    });
    this.messagingService.startListeningForMessages();
  }

  getColorIcon(notificationType: string): { background: string; color: string } {
    switch (notificationType) {
      case NotificationType.RequestNewFriend:
        return { background: 'bg-palette-yellow-100', color: 'text-palette-yellow-500' };
      case NotificationType.TagYouInPost:
        return { background: 'bg-palette-amber-100', color: 'text-palette-amber-500' };
      case NotificationType.TagYouInComment:
        return { background: 'bg-branding-primary-100', color: 'text-branding-primary-500' };
      case NotificationType.UploadEvent:
        return { background: 'bg-palette-rose-100', color: 'text-palette-rose-500' };
      case NotificationType.NewPost:
        return { background: 'bg-palette-violet-100', color: 'text-palette-violet-500' };
      case NotificationType.ReactOnPost:
        return { background: 'bg-palette-fuchsia-100', color: 'text-palette-fuchsia-500' };
      case NotificationType.Event:
        return { background: 'bg-palette-rose-100', color: 'text-palette-rose-500' };
      case NotificationType.Livestream:
        return { background: 'bg-palette-red-100', color: 'text-palette-red-500' };
      case NotificationType.Memory:
        return { background: 'bg-palette-teal-100', color: 'text-palette-teal-500' };
      case NotificationType.Gift:
        return { background: 'bg-palette-orange-100', color: 'text-palette-orange-500' };
      default:
        return { background: 'bg-branding-primary-100', color: 'text-branding-primary-500' };
    }
  }

  isSingleDigit(number: number): boolean {
    return !isNaN(number) && number >= 0 && number <= 9;
  }

  onMouseEnter(index: number) {
    this.showButtonIndex = index;
  }

  onMouseLeave() {
    this.showButtonIndex = null;
  }

  getNotificationList() {
    const pageNum = 0;
    const pageSize = 100;
    this.notificationsService.getNotifications(pageNum, pageSize).subscribe(data => {
      if (data && data.data) {
        const notifications = data.data;
        notifications.sort((a: any, b: any) => {
          return +new Date(a.send_time) - +new Date(b.send_time);
        });
        this.notificationList = notifications.reverse();
        let countNoti = '0';
        if (data.totalElement >= 100) {
          countNoti = '99+';
        } else if (data.totalElement <= 99) {
          countNoti = data.totalElement;
        }
        this.badgeRead = countNoti;
        this.handleBadgeRead(countNoti);
      }
    });
  }

  handleBadgeRead(event: any) {
    this.updateBadgeRead.emit(event);
  }
  calculateFromNow(date: any) {
    return moment(date).fromNow();
  }

  ngOnChanges() {
    this.messagingService.receiveMessage();
  }

  navigationUrl(isRead: any, url: any, id: any, event: Event) {
    !isRead && this.toggleNotification(id, event);
    this.overlayVisible = false;
    this.router.navigate([url]).then(() => window.location.reload());
    localStorage.setItem('groupDetailEvent', 'active');
    localStorage.setItem('activeIndexGroup', '3');
  }

  toggleNotification(id: any, event: Event) {
    event.stopPropagation();
    this.notificationsService.toggleReadNotification(id).subscribe(data => {
      this.updateBadgeCount(data);
      this.getNotificationList();
    });
    const notificationIndex = this.notificationList.findIndex(item => item.id === id);
    if (notificationIndex !== -1) {
      this.notificationList[notificationIndex]._read = !this.notificationList[notificationIndex]._read;
    }
  }

  markAllReadNotification(event: Event) {
    event.stopPropagation();
    this.notificationsService.markAllReadNotification().subscribe(data => {
      this.handleBadgeRead(0);
      this.getNotificationList();
    });
  }

  updateBadgeCount(data: any) {
    if (data && data._read) {
      this.decrementBadgeCount();
    } else {
      this.incrementBadgeCount();
    }
  }

  incrementBadgeCount() {
    this.badgeRead += 1;
    this.handleBadgeRead(this.badgeRead);
  }

  decrementBadgeCount() {
    if (this.badgeRead > 0) {
      this.badgeRead -= 1;
      this.handleBadgeRead(this.badgeRead);
    }
  }
  deleteNotification(id: any, event: Event, isRead: boolean) {
    event.stopPropagation();
    this.notificationsService.deleteNotification(id).subscribe(data => {
      this.handleNotificationDeletion(isRead);
      this.getNotificationList();
    });
  }

  handleNotificationDeletion(isRead: boolean) {
    if (!isRead) {
      this.decrementBadgeCount();
    }
  }

  navigateToNotifications() {
    this.router.navigate(['/account-settings?setting=notificationss']);
    this.accountSettingNotificationsService.selectSetting$.next(ACCOUNT_SETTING_KEY.notifications);
    this.overlayVisible = false;
  }

  processNotificationAction(ctaURL: any, id: any) {
    this.notificationsService.callToActionNotification(ctaURL).subscribe(() => {
      this.notificationsService.completeCTANotification(id).subscribe(() => {
        this.getNotificationList();
      });
    });
  }

  navigateToProfile(userId: string): void {
    if (!userId) return;
    this.router.navigate(['/personal-profile', userId]);
  }

  getAvatar(id: string): string {
    return this.commonService.getImageUrl(id);
  }

  @HostListener('window:scroll', ['$event'])
  handleHideOverlayPanel() {
    if (typeof this.indexOverlay === 'number') {
      this.overlayPanels.toArray()[this.indexOverlay].hide();
    }
  }

  closeDialog() {
    this.mobileOverlayVisible = false;
    this.mobileOverlayVisibleChange.emit(this.mobileOverlayVisible);
  }
}
