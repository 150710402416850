import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  ensureURLProtocol(url: string): string {
    const protocolRegex = /^(?:(?:https?|ftp):\/\/)/i;
    if (!protocolRegex.test(url)) {
      url = 'https://' + url;
    }
    return url;
  }

  extractDomain(url: string): string {
    let domain = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (domain && domain.length > 0) {
      return domain[2];
    }
    return '';
  }

  isWebsite(url: string, requireSlash = false): boolean {
    if (typeof url !== 'string' || !url.length) {
      return false;
    }
    const websitePattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (requireSlash) {
      return websitePattern.test(url) && url.includes('/');
    } else {
      return websitePattern.test(url);
    }
  }

  removeDuplicatesFromArrayOfObjects<T>(array: T[], key: string): T[] {
    return array.filter((item: any, index, self) => index === self.findIndex((t: any) => t[key] === item[key]));
  }

  shortenName(name: string, maxCharacter: number): string {
    if ((name || '').trim().length > maxCharacter) {
      return `${name.substring(0, maxCharacter - 2).trim()}...`;
    }
    return (name || '').trim();
  }
}
