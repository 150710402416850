import { Story } from "@app/shared/models/story";
import { createActionGroup, props } from "@ngrx/store";

export const StoryActions = createActionGroup({
    source: 'Story',
    events: {
        'On Load Story': props<{ pageNum: number, pageSize: number }>(),
        'On Load Your Story': props<{ stories: Story[] }>()  
    }
});

export const StoryApiActions = createActionGroup({
    source: 'Story Api',
    events: {
        'On Load Story Success': props<{ stories: Story[] }>(),
        'On Load Story Fail': props<{ message: string }>()
    }
});