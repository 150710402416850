import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() userInfo?: UserInfo | any;
  @Input() isSmallThumbnail = false;
  @Input() isDisableNavigate = false;
  @Input() avatarSize = 40;
  @Input() isUseLogoSoctrip = false;
  userAvatarUrl: string = '';
  userFrameUrl: string = '';
  baseUrl: string = environment.baseURL;
  isModerate = false;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userInfo'] && changes['userInfo'].currentValue) {
      setTimeout(() => {
        const userInfo = changes['userInfo'].currentValue;
        this.getAvatar(userInfo);
        this.getFrame(userInfo);
      }, 500);
    }
  }

  ngOnInit(): void {
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
    this.getAvatar(this.userInfo);
    this.getFrame(this.userInfo);
    if (this.isUseLogoSoctrip) {
      this.userAvatarUrl = 'assets/images/soctrip-branding/soctrip-logo-blue.svg';
    }
  }

  navigateToProfile(): void {
    if (this.isModerate) return;
    if (!this.isDisableNavigate) {
      if (this.isUseLogoSoctrip) {
        window.open(environment.ECOMMERCE_URL, '_blank');
      } else {
        this.router.navigate(['/personal-profile', this.userInfo?.username || this.userInfo?.id]);
      }
    }
  }

  getAvatar(userInfo: any) {
    this.userAvatarUrl = this.commonService.getImageUrl(userInfo && userInfo.avatar_thumbnail_url);
  }

  getFrame(userInfo: any) {
    this.userFrameUrl = this.commonService.getImageUrl(userInfo && userInfo.frame_url);
  }
}
