import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { EventImg } from '@app/shared/components/media/media.component';
import { MAX_TRUNCATED_TEXT_HEIGHT, MEDIA_TYPE } from '@app/shared/constant';
import { FILE_TYPE_URL, Media, checkUrl } from '@app/shared/models/post';

@Component({
  selector: 'app-preview-content-link',
  templateUrl: './preview-content-link.component.html',
  styleUrls: ['./preview-content-link.component.scss']
})
export class PreviewContentLinkComponent implements OnInit {
  @Input() data: any;
  @Input() loading: any;
  mediaList: Media[] = [];
  descriptionHeight = 0;
  activeIndex: number;
  displayFullImage = false;
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  MEDIA_TYPE = MEDIA_TYPE;

  constructor(
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data.image) {
      this.mediaList.push({
        type: MEDIA_TYPE.image,
        thumbnail: this.data.image + '.webp',
        original: this.data.image + '.webp'
      });
    }
  }

  ngAfterViewInit(): void {
    this.descriptionHeight = this.el.nativeElement.querySelector('.post-description')?.offsetHeight | 0;
    this.cdRef.detectChanges();
  }

  navigatePostOriginal() {
    window.open(this.data.link, '_blank');
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    return checkUrl(urlString, style);
  }

  clickImage(event: EventImg) {
    this.displayFullImage = true;
    this.activeIndex = event.i;
  }

  onExpandText() {
    this.isShowAllContent = !this.isShowAllContent;
  }
}
