import { Component } from '@angular/core';

@Component({
  selector: 'list-theme',
  templateUrl: './list-theme.component.html',
  styleUrls: ['./list-theme.component.scss']
})
export class ListThemeComponent {

}
