import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { SIGNIN_METHOD } from '@app/shared';
import { environment } from '@env/environment';
import { Observable, catchError, map } from 'rxjs';
import { setCookie } from 'typescript-cookie';
import * as UAParser from 'ua-parser-js';
import { AppQueryParam } from '../enums/app.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private api: ApiClient;
  public readonly isLoggedIn: boolean = false;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
    this.isLoggedIn = this.getAuthStatus();
  }

  requestTimeout = 6000;

  getReturnUrl(): string {
    return new URL(window.location.href).searchParams?.get(AppQueryParam.ReturnUrl) ?? '';
  }

  login(data: AuthAPI.SignIn): Observable<any> {
    return this.api.auth.signIn(data).pipe(
      map(user => {
        return user;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  logout(data: AuthAPI.SignOut): Observable<any> {
    return this.api.auth.signOut(data).pipe(
      map(user => {
        return user;
      })
    );
  }

  getDeviceInfo(): Observable<AuthAPI.DeviceInfo> {
    return this.api.auth.getDetailInfo().pipe(
      map(response => {
        const device_id = response?.data[0]?.ip ?? '';
        const device = this.getDeviceName(navigator.userAgent) ?? '';
        const country = response?.data[0]?.country ?? '';
        const city = response?.data[0]?.city ?? '';
        const country_code = response?.data[0]?.country_code ?? '';
        const regions = response?.data[0]?.region ?? '';
        const city_code = response?.data[0]?.zipcode ?? '';
        const currency = response?.data[0]?.currency ?? '';
        const language_code = response?.data[0]?.language_code ?? '';
        return { device, device_id, country, city, country_code, regions, city_code, currency, language_code };
      })
    );
  }

  isTokenExpired(): boolean {
    const access_token_expiry_date = localStorage.getItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
    const refresh_token_expiry_date = localStorage.getItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    if (access_token_expiry_date && refresh_token_expiry_date) {
      const refreshTokenExpiry = new Date(access_token_expiry_date);
      const accessTokenExpiry = new Date(refresh_token_expiry_date);
      const currentDate = new Date();
      return refreshTokenExpiry < currentDate || accessTokenExpiry < currentDate;
    }
    return false;
  }

  getDeviceName(userAgentString: string): string {
    const parser = new UAParser();
    parser.setUA(userAgentString);
    const name = parser.getOS().name || '';
    const version = parser.getOS().version || '';
    return `${name} ${version}`;
  }

  detectWindows11(): Observable<string> {
    return new Observable(observer => {
      (window.navigator as any).userAgentData.getHighEntropyValues(['platformVersion']).then((ua: any) => {
        setTimeout(() => {
          if ((window.navigator as any).userAgentData.platform === 'Windows') {
            const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
            if (majorPlatformVersion >= 13) observer.next('Windows 11');
            else observer.next('');
          } else observer.next('');
          observer.complete();
        }, 2000);
      });
    });
  }

  getLoginHistory(data: AuthAPI.LoginHistory) {
    return this.api.auth.getLoginHistory(data).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  deleteLoginHistory(id: string) {
    return this.api.auth.deleteLoginHistory(id).pipe(
      map(res => {
        return res;
      })
    );
  }

  signOutAllDevices() {
    return this.api.auth.signOutAllDevices().pipe(
      map(res => {
        return res;
      })
    );
  }

  setInfoLocalStorage(response: AuthAPI.SignIn): void {
    const deviceSignInInfo = {
      device_id: response?.device_id || '',
      device: response?.device || '',
      device_type: response?.device_type || '',
      city: response?.city || '',
      country: response?.country || ''
    };

    localStorage.setItem(environment.TOKEN_KEY, JSON.stringify({ accessToken: response?.access_token }));
    localStorage.setItem(environment.REFRESH_TOKEN_KEY, JSON.stringify({ refreshToken: response?.refresh_token }));
    localStorage.setItem('device_sign_in', JSON.stringify(deviceSignInInfo || {}));
    localStorage.setItem('signin_method', SIGNIN_METHOD.SOCTRIP);
    setCookie(environment.TOKEN_KEY, JSON.stringify({ accessToken: response?.access_token }));
  }

  private getAuthStatus(): boolean {
    return JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
  }
}
