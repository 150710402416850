import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BookmarksService } from '@app/core/services/bookmarks.service';
import { TranslationService } from '@app/core/services/translation.service';
import { BookmarkRequest, Collection } from '@app/lib/api/bookmark/api.bookmark.model';
import { NewsFeedPostsActions } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.actions';
import { selectBookMarkCollection, selectLoadMoreBookMarkCollection } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { DEFAULT_COLLECTION } from '@app/shared/constant';
import { DagObjectBookmark } from '@app/shared/models/post';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'book-mark-category',
  templateUrl: './book-mark-category.component.html',
  styleUrls: ['./book-mark-category.component.scss']
})
export class BookMarkCategoryComponent {
  @Input() shareEvent: boolean;
  @Input() showBookMarkCategory: boolean;
  @Input() postId: string;
  @Output() showBookMarkCategoryChange = new EventEmitter();
  @Output() bookmarkAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output() isBookMarked = new EventEmitter();
  newCollection = '';
  translateBookMarkResult: any;
  translateCommonResult: any;
  listBookMarkCollection: Collection[];
  selectedCollection: string;
  optionNewCollection = 'newCollectionOption';
  nameCollection: string;
  defaultCollectionIndex: number;
  isLoading = false;
  continueGetPost = false;
  private pageNum = 0;
  private pageSize = 10;
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef | undefined;
  constructor(
    private bookmarksService: BookmarksService,
    private messageService: MessageService,
    private translateService: TranslationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.translateBookMarkResult = this.translateService.getTranslation('BOOKMARK');
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
    this.store.select(selectBookMarkCollection).subscribe(res => {
      this.continueGetPost = true;
      this.listBookMarkCollection = cloneDeep(res);
      this.defaultCollectionIndex = this.listBookMarkCollection.findIndex((collection: any) => collection.is_default === true);
      if (this.defaultCollectionIndex !== -1) {
        this.listBookMarkCollection[this.defaultCollectionIndex].name = 'BOOKMARK.MY_COLLECTION';
      }
    })
  }

  loadToScrolled() {
    const isScrolled =
      this.scrollContainer?.nativeElement?.scrollHeight <=
      this.scrollContainer?.nativeElement?.scrollTop +
      this.scrollContainer?.nativeElement?.clientHeight;
    this.store.select(selectLoadMoreBookMarkCollection).subscribe(res => {
      if (isScrolled && res.length !== 0 || this.pageNum === 0) {
        this.handleLoadMore();
      }
      else {
        return
      }
    })
  }

  handleLoadMore() {
    this.pageNum++;
    this.store.dispatch(NewsFeedPostsActions.onLoadMoreBookmarkCollection({ pageNum: this.pageNum, pageSize: this.pageSize }));
  }

  handleCloseModal() {
    this.selectedCollection = '';
    this.showBookMarkCategoryChange.emit(false);
  }

  addBookMark() {
    const param: BookmarkRequest = {
      object_id: this.postId,
      dag_object: DagObjectBookmark,
      group_id: this.selectedCollection === this.optionNewCollection ? '' : this.selectedCollection,
      group_name: this.newCollection
    };
    this.isLoading = true,
      this.bookmarksService.createBookmark(param).subscribe(res => {
        if (res) {
          this.isLoading = false
          const collectionName = res.group_name === DEFAULT_COLLECTION ? this.listBookMarkCollection[this.defaultCollectionIndex].name : res.group_name
          this.bookmarkAdded.emit();
          this.isBookMarked.emit(true);
          this.handleCloseModal();
          this.store.dispatch(NewsFeedPostsActions.onGetBookmarkCollection({ pageNum: 0, pageSize: this.pageSize }));
          this.messageService.add({
            severity: 'success',
            summary: this.translateCommonResult.SUCCESS,
            detail: this.translateService.getTranslation('BOOKMARK.SAVE_BOOKMARK_SUCCESSFULLY') + ' ' + this.translateService.getTranslation(collectionName)
          });
        } else {
          this.isLoading = false
          this.messageService.add({
            severity: 'error',
            summary: this.translateCommonResult.SUCCESS,
            detail: this.translateService.getTranslation('BOOKMARK.FAIL_TO_SAVE_BOOKMARK')
          });
        }
      });
  }
}
