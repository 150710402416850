import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { FriendAPI } from './api.friend.model';
import { FriendApiTemp } from './api.friend.temp';

export class FriendApi {
  private apiUrl: string = environment.baseURL;
  FRIEND = '/friend';
  friendApiTemp: FriendApiTemp;

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {
    this.friendApiTemp = new FriendApiTemp(http, config);
  }

  getRelationshipStatus(userId: string): Observable<FriendAPI.Friend> {
    return this.friendApiTemp.getMethod(`${this.apiUrl}${this.FRIEND}/relationship_status?userId=${userId}`);
  }

  isFriend(friendId: string): Observable<FriendAPI.Relationship> {
    return this.friendApiTemp.getMethod(`${this.apiUrl}${this.FRIEND}/is_friend?friendId=${friendId}`);
  }

  postFriendRequest(userId: string, body: any): Observable<FriendAPI.FriendStatus> {
    return this.friendApiTemp.postMethod(`${this.apiUrl}${this.FRIEND}/send-invite/${userId}`, body);
  }

  putCancelFriendRequest(requestId: string, body: any): Observable<FriendAPI.FriendStatus> {
    return this.friendApiTemp.putMethod(`${this.apiUrl}${this.FRIEND}/cancel?requestId=${requestId}`, body);
  }

  putAcceptFriendRequest(requestId: string, body: any): Observable<FriendAPI.FriendStatus> {
    return this.friendApiTemp.putMethod(`${this.apiUrl}${this.FRIEND}/accept?requestId=${requestId}`, body);
  }

  putDeclineFriendRequest(requestId: string, body: any): Observable<FriendAPI.FriendStatus> {
    return this.friendApiTemp.putMethod(`${this.apiUrl}${this.FRIEND}/decline?requestId=${requestId}`, body);
  }

  deleteFriend(userId: string): Observable<FriendAPI.FriendStatus> {
    return this.friendApiTemp.deleteMethod(`${this.apiUrl}${this.FRIEND}/unfriend?friendId=${userId}`);
  }

  getFriend(pageNum?: number, pageSize?: number, userId?: string): Observable<FriendAPI.Friend> {
    const url = userId
      ? `${this.apiUrl}${this.FRIEND}/friends?page=${pageNum}&size=${pageSize}&specifiedUserId=${userId}`
      : `${this.apiUrl}${this.FRIEND}/friends?page=${pageNum}&size=${pageSize}`;
    return this.friendApiTemp.getMethod(url);
  }

  getSendFriendRequest(pageNum: number, pageSize: number, searchText: string): Observable<FriendAPI.Friend> {
    const url = `${this.apiUrl}${this.FRIEND}/friend-request/sent?page=${pageNum}&size=${pageSize}&textSearch=${searchText}`;
    return this.friendApiTemp.getMethod(url);
  }

  getFriendRequestReceive(pageNum: number, pageSize: number, searchText: string): Observable<FriendAPI.Friend> {
    const url = `${this.apiUrl}${this.FRIEND}/friend-request/received?page=${pageNum}&size=${pageSize}&textSearch=${searchText}`;
    return this.friendApiTemp.getMethod(url);
  }

  getSearchFriends(searchText?: string, pageSize?: number, pageNum?: number): Observable<FriendAPI.Friend> {
    const pageSizePram = pageSize && pageSize > 0 ? `&size=${pageSize}` : '';
    const pageNumPram = pageNum && pageNum >= 0 ? `&page=${pageNum}` : '';
    return this.friendApiTemp.getMethod(
      `${this.apiUrl}${this.FRIEND}/search?text=${encodeURI(searchText || '')}${pageSizePram}${pageNumPram}`
    );
  }

  getSearchFriendsV2(
    searchText?: string,
    pageSize?: number,
    pageNum?: number,
    isMentionEnabled?: boolean,
    isTagEnabled?: boolean
  ): Observable<FriendAPI.Friend> {
    const pageSizePram = pageSize && pageSize > 0 ? `&size=${pageSize}` : '';
    const pageNumPram = pageNum && pageNum >= 0 ? `&page=${pageNum}` : '';

    return this.friendApiTemp.getMethod(
      `${this.apiUrl}${this.FRIEND}/v2/search?textSearch=${encodeURI(
        searchText || ''
      )}${pageSizePram}${pageNumPram}&is_mention_enabled=${isMentionEnabled}&is_tag_enabled=${isTagEnabled}`
    );
  }

  getSuggestFriends(pageNum: number, pageSize: number, searchText?: string): Observable<FriendAPI.Friend> {
    const url = `${this.apiUrl}${this.FRIEND}/suggest-friends?text=${
      searchText ?? ''
    }&page=${pageNum}&page_size=${pageSize}`;
    return this.friendApiTemp.getMethod(url);
  }
}
