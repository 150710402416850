import { Component, Input, SimpleChanges } from '@angular/core';
import { GroupService } from '@app/core/services/group.service';
import { TopPerfPost } from '@app/lib/api/post/api.post.model';

@Component({
  selector: 'group-top-post',
  templateUrl: './group-top-post.component.html',
  styleUrls: ['./group-top-post.component.scss']
})
export class GroupTopPostComponent {
  @Input() groupId: string | null;
  topPerfomingPostList = {} as TopPerfPost;
  isLoading = true;

  constructor(private groupService: GroupService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['groupId']) {
      this.getTopPerformingPost();
    }
  }

  getTopPerformingPost(): void {
    if (this.groupId) {
      this.isLoading = true;
      this.groupService.getGroupTopPerfPost(this.groupId).subscribe({
        next: res => {
          if (res) {
            this.topPerfomingPostList = res;
            this.isLoading = false;
          }
        }
      });
    }
  }
}
