<div class="flex flex-col gap-4 text-palette-gray-900">
  <div>
    <span class="text-xl font-semibold">{{ 'STATISTIC.TOP_PERFORMING_POST' | translate }}</span>
  </div>
  <ng-container *ngIf="!isLoading; else loadingSkeleton">
    <ng-container *ngIf="topPerformingPosts.length; else noPost">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <ng-container *ngFor="let topPost of topPerformingPosts">
          <ng-container
            [ngTemplateOutlet]="postCard"
            [ngTemplateOutletContext]="{
              topPostData: topPost
            }"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noPost>
      <div class="flex flex-col gap-2 md:gap-4 w-full justify-center items-center py-0 md:py-4">
        <img src="./assets/images/Illustration.svg" alt="No post" class="w-28 md:w-32" />
        <span class="text-sm md:text-base text-palette-gray-600 font-semibold">{{
          'STATISTIC.TOP_POST.NO_TOP_POST' | translate
        }}</span>
      </div>
    </ng-template>
  </ng-container>
</div>

<ng-template #postCard let-topPostData="topPostData">
  <div
    class="rounded-lg border border-palette-gray-200 p-3 flex flex-col gap-2 justify-center"
    (click)="onClickedPost(topPostData.post)">
    <div class="flex justify-between items-center">
      <div class="flex gap-2 justify-center items-center">
        <img class="w-5 h-5 md:w-6 md:h-6" src="{{ topPostData.context.icon }}" alt="" />
        <span class="text-sm md:text-base font-semibold text-palette-gray-700 align-middle">{{
          'STATISTIC.TOP_POST.' + topPostData.context.title | translate
        }}</span>
      </div>
      <!-- TODO: Enable navigate icon when supporting top post details -->
      <!-- <div
        class="w-6 h-6 md:w-8 md:h-8 flex justify-center items-center rounded-md focus:ring-2 focus:ring-branding-primary-300 focus:ring-offset-2">
        <i class="sctr-icon-chevron-right align-middle"></i>
      </div> -->
    </div>
    <div class="flex gap-2">
      <img
        defaultImage
        class="rounded-lg w-[60px] h-[60px] md:w-[78px] md:h-[78px] border border-palette-gray-200 object-cover"
        [src]="topPostData.thumbnailURL || './assets/images/Illustration.svg'"
        alt="" />
      <div class="flex flex-col justify-center text-sm truncate gap-[2px]">
        <span class="text-xs text-palette-gray-500">{{
          topPostData.post.posted_date | dateFormatPipe: undefined : undefined : 'mediumDate'
        }}</span>
        <span class="truncate text-palette-gray-900 font-semibold emoji">{{ topPostData.post.content }}</span>
        <span class="text-xs md:text-sm text-palette-gray-500 font-medium">
          <span
            title="{{ topPostData.totalInteraction }} {{
              (topPostData.totalInteraction > 1 ? topPostData.context.pluralLabel : topPostData.context.label)
                | translate
            }}"
            >{{ topPostData.totalInteraction | shortNumber }}
            {{
              (topPostData.totalInteraction > 1 ? topPostData.context.pluralLabel : topPostData.context.label)
                | translate
            }}</span
          ></span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingSkeleton>
  <div class="card">
    <div class="border-round border-1 surface-border p-4 surface-card">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2 " />
        <div>
          <p-skeleton width="10rem" styleClass="mb-2" />
          <p-skeleton width="5rem" styleClass="mb-2" />
          <p-skeleton height="5rem" />
        </div>
      </div>
      <p-skeleton width="w-full" height="150px" />
    </div>
  </div>
</ng-template>
