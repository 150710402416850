<div class="flex flex-col gap-4 text-palette-gray-900">
  <div>
    <span class="text-xl font-semibold">{{ 'STATISTIC.INSIGHTS' | translate }}</span>
  </div>
  <compare-date-ranges (compareDateRanges)="onDateRangesChanged($event)"></compare-date-ranges>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <div>
      <line-chart [lineChartData]="newPostChartData"></line-chart>
    </div>
    <div>
      <line-chart [lineChartData]="interactionsChartData"></line-chart>
    </div>
    <div>
      <line-chart [lineChartData]="newMembersChartData"></line-chart>
    </div>
    <div>
      <line-chart [lineChartData]="userLeaveGroupChartData" [isLowerBetter]="true"></line-chart>
    </div>
  </div>
</div>
