<p-overlay
  class="absolute right-80 top-8 max-xs:hidden"
  [(visible)]="overlayVisible"
  [contentStyleClass]="'shadow-2 border-round'"
  [styleClass]="'z-10 min-w-0 left-0'"
  (onHide)="overlayVisibleChange.emit(false)">
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</p-overlay>

<div *ngIf="mobileOverlayVisible" class="xs:hidden fixed top-0 left-0 w-full h-full overflow-y-auto bg-palette-base-white z-10">
  <ng-container *ngTemplateOutlet="notifications"></ng-container>
</div>

<ng-template #notifications>
  <div class="w-96 bg-white p-0 rounded-lg shadow-lg max-xs:w-full">
    <div class="flex flex-col gap-3 p-4 pb-3">
      <div #targetElement class="relative flex justify-between w-full">
        <div class="flex flex-row gap-2 justify-start items-center w-full">
          <p>{{ 'ENTITY.NOTIFICATIONS' | translate }}</p>
          <span
            class="bg-system-error-500 rounded-full text-xs text-white p-2 {{ isSingleDigit(badgeRead) && 'px-3' }}"
            >{{ badgeRead }}</span
          >
        </div>
        <div class="gap-1 flex flex-row">
          <span class="flex sctr-icon-dots-vertical text-lg cursor-pointer" (click)="op1.toggle($event)"></span>
          <span class="flex sctr-icon-x-close text-lg cursor-pointer xs:hidden" (click)="closeDialog()"></span>
        </div>
      </div>
      <p-overlayPanel
        #op1
        [appendTo]="targetElement"
        [styleClass]="
          'overlayPanel-custom left-auto right-0 top-full mt-0 before:hidden after:hidden mt-[1px] border-none shadow-lg'
        ">
        <button
          class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-palette-gray-200"
          (click)="markAllReadNotification($event); op1.toggle($event)">
          <i class="sctr-icon-book-open-01 text-base"></i>
          <span class="text-palette-gray-700 text-sm font-medium">{{ 'COMMON.MARK_ALL_AS_READ' | translate }}</span>
        </button>
        <button
          class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-palette-gray-200"
          (click)="navigateToNotifications(); op1.toggle($event)">
          <i class="sctr-icon-settings-02 text-base"></i>
          <span class="text-palette-gray-700 text-sm font-medium">{{ 'HEADER.MEGA_MENU.SETTINGS' | translate }}</span>
        </button>
      </p-overlayPanel>
    </div>
    <div class="p-2 space-y-2 xs:max-h-[588px] scroll-auto overflow-x-hidden">
      <ng-container *ngFor="let item of notificationList; let i = index">
        <div
          class="space-x-3 w-full flex flex-col flex-nowrap {{
            !item._read ? 'bg-branding-primary-50' : null
          }} p-2 rounded-md"
          (mouseenter)="onMouseEnter(i)"
          (mouseleave)="onMouseLeave()">
          <div class="flex justify-start items-start gap-x-4">
            <div class="relative max-w-fit w-full h-full">
              <img
                (click)="navigateToProfile(item.created_by)"
                class="w-11 h-11 rounded-full cursor-pointer"
                onerror="this.src='assets/images/default_user_avatar.png'"
                [src]="getAvatar(item.sender_avatar)" />
              <div
                class="absolute bottom-[-1px] right-[-8px] cursor-pointer w-6 h-6 {{
                  getColorIcon(item.icon_color).background
                }} rounded-full flex items-center justify-center">
                <span
                  class="{{ item.icon_image }} text-xs {{ getColorIcon(item.icon_color).color }} text-center"></span>
              </div>
            </div>

            <div
              class="flex flex-col text-sm text-gray-700 font-semibold cursor-pointer"
              (click)="item.web_link && navigationUrl(item._read, item.web_link, item.id, $event)">
              <div class="font-normal text-sm">
                <span class="font-semibold">{{ item.sender_full_name }}</span
                >{{ ' ' + item.body }}
              </div>
              <span class="text-xs font-normal text-gray-500"> {{ this.calculateFromNow(item.send_time) }} </span>
            </div>
            <button
              class="relative sctr-icon-dots-horizontal text-lg last-of-type:ml-auto transition ease-in-out {{
                showButtonIndex === i ? ' cursor-pointer' : 'text-transparent'
              }} "
              (click)="overlayPanelElement.toggle($event); indexOverlay = i"></button>
            <div></div>
          </div>
          <p-overlayPanel
            #overlayPanelElement
            [appendTo]="'body'"
            [styleClass]="
              'overlayPanel-notifications-custom right-0 mt-0 p-0 before:hidden w-max after:hidden mt-[1px] border-none'
            ">
            <button
              class="flex gap-2 items-center w-full py-[9px] px-[10px] hover:bg-palette-gray-200"
              (click)="toggleNotification(item.id, $event); overlayPanelElement.toggle($event); indexOverlay = i">
              <i class="sctr-icon-book-open-01 text-base"></i>
              <span class="text-palette-gray-700 w-max text-sm font-medium">{{
                item._read ? ('COMMON.UNREAD' | translate) : ('COMMON.READ' | translate)
              }}</span>
            </button>
            <button
              class="flex text-system-error-500 gap-2 items-center w-full py-[9px] px-[10px] hover:bg-palette-gray-200"
              (click)="
                deleteNotification(item.id, $event, item._read); overlayPanelElement.toggle($event); indexOverlay = i
              ">
              <i class="sctr-icon-trash-03 text-base"></i>
              <span class="text-system-error-500 w-max text-sm font-medium">{{ 'COMMON.REMOVE' | translate }}</span>
            </button>
          </p-overlayPanel>
          <div *ngIf="!item._cta_complete && item.cta_action" class="flex justify-end gap-3 py-3 pb-1">
            <ng-container *ngFor="let action of item?.cta_action">
              <button
                (click)="processNotificationAction(action?.post_link, item.id)"
                *ngIf="action?.label === 'Decline' || action?.label === 'Reject'"
                type="button"
                class="border-palette-gray-300 bg-white text-palette-gray-700 px-3 py-1 rounded-md cursor-pointer p-button">
                <span class="{{ action?.icon }} text-palette-gray-700  text-lg cursor-pointer px-2"></span>
                <span>{{ action?.label }}</span>
              </button>
              <button
                *ngIf="action?.label === 'Accept'"
                (click)="processNotificationAction(action?.post_link, item.id)"
                type="button"
                class="border-branding-primary-600 bg-branding-primary-600 text-white px-3 py-1 rounded-md cursor-pointer p-button">
                <span class="{{ action?.icon }} text-white text-lg cursor-pointer px-2"></span>
                <span>{{ action?.label }}</span>
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
