<div [ngClass]="{ 'mb-3 ml-px': isPostView }">
  <comment-form
    *ngIf="!isPostView && !hideCommentBox"
    [loginUser]="userInfo$ | async"
    (valueComment)="addComment($event)"></comment-form>
  <div class="scroll-bar-comment heightComments" [ngClass]="!isPostView ? 'max-h-[500px]' : ''">
    <div *ngFor="let comment of comments; let i = index">
      <comment-tree
        *ngIf="!isHideComment(comment.id)"
        [hideReply]="hideCommentBox"
        [loginUser]="userInfo$ | async"
        [comment]="comment"
        [post]="post"
        [postOwnerId]="postOwnerId"
        (increaseTotal)="onIncreaseTotal($event)"
        (deletedCommentId)="deleteComment($event)"
        (createdComment)="createdComment.emit()"
        [isPostView]="isPostView"></comment-tree>
    </div>
    <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeleton"></ng-container>
    <ng-template #skeleton>
      <div class="py-4 px-0 rounded-lg bg-palette-base-white border-palette-gray-200 gap-4 mb-2">
        <loading-skeleton rounded></loading-skeleton>
      </div>
    </ng-template>
    <div
      class="cursor-pointer text-sm font-semibold text-branding-primary-700 pt-2 hover:underline"
      *ngIf="comments && totalComments > comments.length && !isLoading"
      (click)="!isGettingComment ? showMoreComments() : null">
      {{ 'COMMENT.VIEW_MORE_COMMENTS' | translate }}
      <div [ngClass]="isGettingComment ? 'pi-spin pi sctr-icon-loading-02' : ''"></div>
    </div>
  </div>
</div>
<comment-form
  *ngIf="isPostView"
  class="commentForm"
  [isPostView]="true"
  [loginUser]="userInfo$ | async"
  (valueComment)="addComment($event)">
</comment-form>
