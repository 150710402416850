import { FriendTabs } from '@app/core/enums/makeFriend.enum';
import { createReducer, on } from '@ngrx/store';
import { AuthenticationActions } from '../account-settings/authentication/authentication.actions';
import { FriendActions, FriendApiActions } from './friend.actions';
import {
  FollowStatusState,
  FriendState,
  FriendStatusState,
  IsFollowingState,
  RelationShipStatusState
} from './friend.state';
const initFriendStatus: FriendStatusState = {
  loading: false,
  data: {
    friend_invitation_id: '',
    relationship_status: ''
  }
};

const initRelationshipStatus: RelationShipStatusState = {
  loading: false,
  isFriend: undefined
};

const initFollowStatus: FollowStatusState = {
  loading: false,
  followStatus: false
};

const initIsFollowingStatus: IsFollowingState = {
  loading: false,
  isFollowing: false
};

const initFriendState: FriendState = {
  receiveRequest: {
    loading: false,
    data: {
      content: [],
      totalElements: 0
    }
  },
  sendRequest: {
    loading: false,
    data: {
      content: [],
      totalElements: 0
    }
  },
  friendList: {
    loading: false,
    data: {
      content: [],
      totalElements: 0
    }
  },
  friendWidgetList: {
    loading: false,
    data: {
      content: [],
      totalElements: 0
    }
  },
  suggestFriends: {
    loading: false,
    data: {
      content: [],
      total_records: 0,
      number_of_elements: 0,
      is_last_page: true
    }
  },
  userFollowing: {
    loading: false,
    data: {
      page: 0,
      pageSize: 0,
      totalElement: 0,
      content: []
    }
  },
  userFollower: {
    loading: false,
    data: {
      page: 0,
      pageSize: 0,
      totalElement: 0,
      content: []
    }
  }
};

export const friendStatusReducer = createReducer(
  initFriendStatus,
  on(FriendActions.onGetRelationshipState, state => ({
    ...state,
    loading: true
  })),

  on(FriendApiActions.onGetFriendRelationshipStateSuccess, (state, { friendStatus }) => ({
    ...state,
    loading: false,
    data: {
      relationship_status: friendStatus.data.status,
      friend_invitation_id: friendStatus.data?.friend_invitation_id
    }
  })),

  on(FriendApiActions.onGetFriendRelationshipStateFail, state => ({
    ...state,
    loading: false
  })),

  on(FriendActions.onMakeFriendState, state => ({
    ...state,
    loading: true
  })),

  on(FriendActions.onAcceptRequestFriendState, state => ({
    ...state,
    loading: true
  })),

  on(FriendActions.onDeclineRequestFriendState, state => ({
    ...state,
    loading: true
  })),

  on(FriendActions.onUnfriendState, state => ({
    ...state,
    loading: true
  })),

  on(FriendActions.onCancelRequestFriendState, state => ({
    ...state,
    loading: true
  }))
);

export const friendReducer = createReducer(
  initFriendState,

  on(FriendApiActions.onUpdateItemFriendList, (state, { friendStatus, index }) => {
    let contentFriendList = state.friendList?.data.content?.length ? [...state.friendList.data.content] : [];

    if (contentFriendList.length && contentFriendList[index]) {
      let friendUpdated = { ...contentFriendList[index] };

      const { relationship_status = [] } = friendUpdated;
      friendUpdated.relationship_status = relationship_status.includes(friendStatus)
        ? relationship_status.filter(relationship => relationship !== friendStatus)
        : [...relationship_status, friendStatus];

      contentFriendList[index] = friendUpdated;
    }

    return {
      ...state,
      friendList: {
        ...state.friendList,
        data: {
          ...state.friendList.data,
          content: contentFriendList
        }
      }
    };
  }),

  on(FriendActions.onGetFriendsSendRequestState, state => ({
    ...state,
    sendRequest: {
      loading: true,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendApiActions.onGetFriendsSendRequestStateSuccess, (state, { sendRequest }) => {
    return {
      ...state,
      sendRequest: {
        loading: false,
        data: {
          content: sendRequest?.data?.content?.filter(item => item) ?? [],
          totalElements: sendRequest?.data?.number_of_elements ?? 0,
          total_records: sendRequest?.data?.total_records ?? 0,
          is_last_page: sendRequest?.data?.is_last_page
        }
      }
    };
  }),

  on(FriendActions.onLoadMoreFriendsSendRequestState, state => ({
    ...state,
    sendRequest: {
      loading: true,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendApiActions.onLoadMoreFriendsSendRequestStateSuccess, (state, { data }) => {
    const newContent = data?.data?.content?.filter(item => item) ?? [];
    const updatedContent = state?.sendRequest?.data?.content?.concat(newContent);
    return {
      ...state,
      sendRequest: {
        loading: false,
        data: {
          content: updatedContent,
          totalElements: data?.data?.number_of_elements ?? 0,
          total_records: data?.data?.total_records ?? 0,
          is_last_page: data?.data?.is_last_page ?? false
        }
      }
    };
  }),

  on(FriendApiActions.onLoadMoreFriendsSendRequestStateFail, state => ({
    ...state,
    sendRequest: {
      loading: false,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendApiActions.onGetFriendsSendRequestStateFail, state => ({
    ...state,
    sendRequest: {
      loading: false,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendActions.onGetFriendsRequestReceiveState, state => ({
    ...state,
    receiveRequest: {
      loading: true,
      data: { ...state.receiveRequest.data }
    }
  })),

  on(FriendApiActions.onGetFriendsRequestReceiveStateSuccess, (state, { receiveRequest }) => ({
    ...state,
    receiveRequest: {
      loading: false,
      data: {
        content: receiveRequest?.data?.content?.filter(item => item && item.user_info_aggregated) || [],
        totalElements: receiveRequest.data.number_of_elements,
        total_records: receiveRequest?.data?.total_records ?? 0,
        is_last_page: receiveRequest?.data?.is_last_page
      }
    }
  })),

  on(FriendApiActions.onGetFriendsRequestReceiveStateFail, state => ({
    ...state,
    receiveRequest: {
      loading: false,
      data: { ...state.receiveRequest.data }
    }
  })),

  on(FriendActions.onLoadMoreFriendsRequestReceiveState, state => ({
    ...state,
    sendRequest: {
      loading: true,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendActions.onLoadMoreFriendsRequestReceiveState, state => {
    return {
      ...state,
      receiveRequest: {
        ...state.receiveRequest,
        loading: true
      }
    };
  }),

  on(FriendApiActions.onLoadMoreFriendsRequestReceiveStateSuccess, (state, { data }) => {
    const newContent = data?.data?.content?.filter(item => item && item.user_info_aggregated) || [];
    const updatedContent = state?.receiveRequest?.data?.content?.concat(newContent);
    return {
      ...state,
      receiveRequest: {
        loading: false,
        data: {
          content: updatedContent,
          totalElements: data?.data?.number_of_elements ?? 0,
          total_records: data?.data?.total_records ?? 0,
          is_last_page: data?.data?.is_last_page ?? false
        }
      }
    };
  }),

  on(FriendApiActions.onLoadMoreFriendsRequestReceiveStateFail, state => ({
    ...state,
    sendRequest: {
      loading: false,
      data: { ...state.sendRequest.data }
    }
  })),

  on(FriendActions.onSearchFriendsState, state => ({
    ...state,
    friendList: {
      loading: true,
      data: {
        ...state.friendList.data,
        content: []
      }
    }
  })),

  on(FriendActions.onLoadMoreSearchFriendsState, state => ({
    ...state,
    friendList: {
      loading: true,
      data: {
        ...state.friendList.data
      }
    }
  })),

  on(FriendActions.onGetListFriendState, state => ({
    ...state,
    friendList: {
      loading: true,
      data: { ...state.friendList.data }
    }
  })),

  on(FriendActions.onResetListFriendState, state => ({
    ...state,
    friendList: {
      loading: true,
      data: { ...state.friendList.data, content: [] }
    }
  })),

  on(FriendApiActions.onGetListFriendStateSuccess, (state, { friendList }) => ({
    ...state,
    friendList: {
      loading: false,
      data: {
        content: [...(state.friendList.data.content ?? []), ...(friendList?.data?.content?.filter(item => item) || [])],
        total_records: friendList.data.total_records,
        is_last_page: friendList.data.is_last_page
      }
    }
  })),

  on(FriendApiActions.onGetListFriendStateFail, state => ({
    ...state,
    friendList: {
      loading: false,
      data: { ...state.friendList.data, is_last_page: true }
    }
  })),

  on(FriendApiActions.onGetListSearchFriendStateSuccess, (state, { friendList }) => ({
    ...state,
    friendList: {
      loading: false,
      data: {
        content: [...(state.friendList.data.content ?? []), ...(friendList.data.data?.filter(item => item) ?? [])],
        total_records: friendList.data.totalElement,
        is_last_page: 20 * friendList.data.page + 1 >= friendList.data.totalElement
      }
    }
  })),

  on(FriendActions.onGetListFriendWidgetState, state => ({
    ...state,
    friendWidgetList: {
      loading: true,
      data: { ...state.friendWidgetList.data }
    }
  })),

  on(FriendApiActions.onGetListFriendWidgetStateSuccess, (state, { friendWidgetList }) => ({
    ...state,
    friendWidgetList: {
      loading: false,
      data: {
        content: friendWidgetList?.data?.content?.filter(item => item) || [],
        total_records: friendWidgetList.data.total_records
      }
    }
  })),

  on(FriendApiActions.onGetListFriendWidgetStateFail, state => ({
    ...state,
    friendWidgetList: {
      loading: false,
      data: { ...state.friendWidgetList.data }
    }
  })),

  on(
    FriendActions.onUpdateFriendsStateById,
    (state, { idToFind, valueId, stateItem, loadingStatus, currentRelationValue, updateRelationValue }) => {
      let updatedContent;
      if (currentRelationValue && updateRelationValue) {
        updatedContent = (state[stateItem]?.data?.content || []).map((friend: any) =>
          friend[idToFind] === valueId
            ? {
                ...friend,
                loadingStatus: loadingStatus,
                ...updateRelationValue?.data,
                relationship_status: friend.relationship_status.map((item: any) =>
                  item === currentRelationValue ? updateRelationValue?.data?.status : item
                )
              }
            : friend
        );
      } else {
        updatedContent = (state[stateItem]?.data?.content || []).map((friend: any) =>
          friend[idToFind] === valueId
            ? {
                ...friend,
                loadingStatus: loadingStatus
              }
            : friend
        );
      }
      const updatedStateFriends = {
        ...state[stateItem],
        data: {
          ...state[stateItem].data,
          content: updatedContent
        }
      };
      return { ...state, [stateItem]: updatedStateFriends };
    }
  ),

  on(FriendActions.onGetSuggestFriendsState, state => ({
    ...state,
    suggestFriends: {
      loading: true,
      data: { ...state.suggestFriends.data }
    }
  })),

  on(FriendApiActions.onGetSuggestFriendsStateSuccess, (state, { suggestFriends }) => ({
    ...state,
    suggestFriends: {
      loading: false,
      data: {
        content: [...(state.suggestFriends.data.content ?? []), ...(suggestFriends.data.content ?? [])],
        total_records: suggestFriends.data.total_records,
        number_of_elements: suggestFriends.data.number_of_elements,
        is_last_page: suggestFriends.data.is_last_page
      }
    }
  })),

  on(AuthenticationActions.onLogout, state => ({
    ...state,
    loading: false,
    suggestFriends: initFriendState.suggestFriends
  })),

  on(FriendApiActions.onGetSuggestFriendsStateFail, state => ({
    ...state,
    suggestFriends: {
      loading: false,
      data: { ...state.suggestFriends.data, is_last_page: true }
    }
  })),

  on(FriendActions.onDeleteFriendsStateByIndex, (state, { item, index }) => {
    const updatedContent = (state[item].data.content || []).filter((friend: any, i: number) => i !== index);

    const updatedFriendsState = {
      ...state[item],
      data: {
        ...state[item].data,
        content: updatedContent
      }
    };

    return { ...state, [item]: updatedFriendsState };
  }),

  on(FriendActions.onDeleteFriendsStateById, (state, { item, id }) => {
    const updatedContent = (state[item].data.content || []).filter((friend: any) => friend.id !== id);
    const updatedFriendsState = {
      ...state[item],
      data: {
        ...state[item].data,
        content: updatedContent
      }
    };

    return { ...state, [item]: updatedFriendsState };
  }),

  on(FriendActions.onResetFriendState, (state, { item }) => {
    const updatedFriendsState = {
      ...state[item],
      data: {
        ...state[item].data,
        content: []
      }
    };

    return { ...state, [item]: updatedFriendsState };
  }),

  on(FriendApiActions.onUpdateFriendsStateByAction, (state, { itemUpdated, currentTab, indexUpdate }) => {
    switch (currentTab) {
      case FriendTabs.FriendRequest:
        let itemNeedUpdate = state.receiveRequest.data.content
          ? ({ ...state.receiveRequest.data.content[indexUpdate] } as any)
          : null;
        const newContent = [...(state.receiveRequest.data.content || [])];
        if (itemNeedUpdate) {
          itemNeedUpdate = {
            ...itemNeedUpdate,
            user_info_aggregated: itemUpdated
          };
          newContent[indexUpdate] = itemNeedUpdate;
        }
        return {
          ...state,
          receiveRequest: {
            ...state.receiveRequest,
            data: {
              ...state.receiveRequest.data.content,
              content: newContent
            }
          }
        };
      default:
        return state;
    }
  }),

  on(FriendActions.onGetUserFollowerState, state => ({
    ...state,
    userFollower: {
      loading: true,
      data: {
        ...state.userFollower.data
      }
    }
  })),

  on(FriendApiActions.onGetUserFollowerStateSuccess, (state, { userFollower }) => {
    return {
      ...state,
      userFollower: {
        loading: false,
        data: {
          page: userFollower.page,
          pageSize: userFollower.pageSize,
          totalElement: userFollower.totalElement,
          content: [...(state.userFollower.data.content ?? []), ...(userFollower.content?.filter(item => item) ?? [])]
        }
      }
    };
  }),

  on(FriendApiActions.onGetUserFollowerStateFail, state => ({
    ...state,
    userFollower: {
      loading: false,
      data: {
        ...state.userFollower.data
      }
    }
  })),
  on(FriendActions.onGetUserFollowingState, state => ({
    ...state,
    userFollowing: {
      loading: true,
      data: {
        ...state.userFollowing.data
      }
    }
  })),
  on(FriendApiActions.onGetUserFollowingStateSuccess, (state, { userFollowing }) => {
    return {
      ...state,
      userFollowing: {
        loading: false,
        data: {
          page: userFollowing.page,
          pageSize: userFollowing.pageSize,
          totalElement: userFollowing.totalElement,
          content: [...(state.userFollowing.data.content ?? []), ...(userFollowing.content?.filter(item => item) ?? [])]
        }
      }
    };
  }),
  on(FriendApiActions.onGetUserFollowingStateFail, state => ({
    ...state,
    userFollowing: {
      loading: false,
      data: {
        ...state.userFollowing.data
      }
    }
  }))
);

export const followStatus = createReducer(
  initFollowStatus,
  on(FriendActions.onUnfollowUserState, state => ({
    ...state,
    loading: true,
    followStatus: false
  })),
  on(FriendActions.onFollowUserState, state => ({
    ...state,
    loading: true,
    followStatus: false
  })),
  on(FriendActions.onRemoveUserFollowerState, state => ({
    ...state,
    loading: true,
    followStatus: false
  })),
  on(FriendApiActions.onFollowUserSuccess, (state, { followUser }) => ({
    ...state,
    loading: false,
    followStatus: followUser?.data ?? false
  })),
  on(FriendApiActions.onFollowUserFail, state => ({
    ...state,
    loading: false,
    followStatus: false
  }))
);

export const isFollowing = createReducer(
  initIsFollowingStatus,
  on(FriendActions.onFollowing, state => ({
    ...state,
    loading: false,
    isFollowing: true
  })),
  on(FriendActions.onUnfollow, state => ({
    ...state,
    loading: false,
    isFollowing: false
  })),
  on(FriendActions.onCheckingFollowingState, state => ({
    ...state,
    loading: true,
    isFollowing: false
  })),
  on(FriendApiActions.onCheckFollowingSuccess, (state, { isFollowing }) => ({
    ...state,
    loading: false,
    isFollowing: isFollowing?.data ?? false
  })),
  on(FriendApiActions.onCheckFollowingFail, state => ({
    ...state,
    loading: false,
    isFollowing: false
  })),
  on(FriendActions.updateFollowingStatus, (state, { isFollowing }) => ({
    ...state,
    isFollowing
  }))
);

export const relationshipStatusReducer = createReducer(
  initRelationshipStatus,
  on(FriendActions.onCheckRelationshipState, state => ({
    ...state,
    loading: true
  })),

  on(FriendApiActions.onCheckRelationshipSuccess, (state, { relationshipStatus }) => {
    return {
      ...state,
      loading: false,
      isFriend: relationshipStatus?.data
    };
  }),

  on(FriendApiActions.onResetRelationshipStatus, state => {
    return {
      ...state,
      loading: false,
      isFriend: undefined
    };
  }),

  on(FriendApiActions.onCheckRelationshipFail, state => ({
    ...state,
    loading: false
  }))
);
