import { Injectable } from '@angular/core';
import { Observable, interval, map, take } from 'rxjs';

const WELCOME_IMAGES = [
  { src: '/assets/images/honey_moon.svg', text: 'SIGNIN_SIGNUP.WELCOME.SLOGAN_1' },
  { src: '/assets/images/trip.svg', text: "SIGNIN_SIGNUP.WELCOME.SLOGAN_2" }
];

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  currentIndex = 0;
  imageUrls = WELCOME_IMAGES;

  getImageAndText(): Object {
    this.currentIndex = (this.currentIndex + 1) % this.imageUrls.length;
    return this.imageUrls[this.currentIndex];
  }

  startCountDown(duration: number): Observable<number> {
    return interval(1000).pipe(
      map((tick: number) => duration - tick - 1),
      take(duration)
    );
  }
}
