import { createReducer, on } from '@ngrx/store';
import * as CoinTypes from './actions';

export interface CoinInterfaceState {
  availableCoinAmounts: any;
}

const initialState: CoinInterfaceState = {
  availableCoinAmounts: null
};

export const CoinReducer = createReducer(
  initialState,
  on(CoinTypes.getAvailableCoinAmounts, state => ({ ...state })),
  on(CoinTypes.getAvailableCoinAmountsSuccess, (state, { availableCoinAmounts }) => ({
    ...state,
    availableCoinAmounts
  }))
);
