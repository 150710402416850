import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, retry, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  ClaimedRewardModel,
  ClaimedRewardResponseModel,
  CoinBloomFilterResponse,
  CoinSentModel,
  CoinSentResponseModel,
  FanpageCoinModel,
  FanpageCoinResponseModel,
  MyCoinModel,
  MyCoinResponseModel,
  QuestModel,
  QuestResponseModel,
  SentCoinToFriendModel,
  SentCoinToFriendResponseModel,
  TopUpCoinModel,
  TransactionHistoryModel,
  TransactionHistoryResponse
} from './api.coin.model';

export class CoinApi {
  private apiUrl = environment.baseURL;

  PURCHASES = '/api/purchases';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) { }

  getTasks(pageNum: number, pageSize: number): Observable<QuestModel[]> {
    const url = `${this.apiUrl}/coin/coins/quest-reward`;
    return this.http.get<QuestResponseModel>(url).pipe(
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getMyCoin(): Observable<MyCoinModel> {
    return this.http.get<MyCoinResponseModel>(`${this.apiUrl}/coin-data/coins/me`).pipe(
      retry(1),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postGiftCoin(body: any): Observable<CoinSentModel> {
    return this.http.post<CoinSentResponseModel>(`${this.apiUrl}/coin-gift/make`, body).pipe(
      retry(1),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postSendCoinToFriend(body: any): Observable<SentCoinToFriendModel> {
    return this.http
      .post<SentCoinToFriendResponseModel>(`${this.apiUrl}/coin/coins/me/transaction?mode=SEND`, body)
      .pipe(
        retry(1),
        map(res => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  postClaimReward(questId: string, body: any): Observable<ClaimedRewardModel> {
    return this.http
      .post<ClaimedRewardResponseModel>(`${this.apiUrl}/coin/coins/me/claim?questId=${questId}`, body)
      .pipe(
        retry(1),
        map(res => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCoinFanpage(objectId: string): Observable<FanpageCoinModel> {
    return this.http.get<FanpageCoinResponseModel>(`${this.apiUrl}/coin-data/coins/page/${objectId}`).pipe(
      retry(1),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getTransactionHistory(pageNum: number, pageSize: number): Observable<TransactionHistoryModel> {
    return this.http
      .get<TransactionHistoryResponse>(
        `${this.apiUrl}/coin/coins/me/transaction/history?page=${pageNum}&size=${pageSize}`
      )
      .pipe(
        retry(1),
        map(res => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCoinBloomFilter(): Observable<string> {
    return this.http.get<CoinBloomFilterResponse>(`${this.apiUrl}/coin/coins/me/gift-bloom`).pipe(
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getAvailableCoinAmounts(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/coin${this.PURCHASES}`).pipe(
      map(res => {
        return res?.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  createTransition(token: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/payment/payments/APP_OTA/charge?payment_token=${token}`, {}).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  topUpCoins(payload: TopUpCoinModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/coin/coins/top-up`, payload).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response?.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  depositCoins(encrypted: string) {
    return this.http.post<any>(`${this.apiUrl}/coin/coins/transaction/deposit`, {encrypted: encrypted}).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
