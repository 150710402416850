import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import QRCodeStyling, { DotType, DrawType, ErrorCorrectionLevel, Mode } from 'qr-code-styling';

const QR_CODE_STYLING = {
  width: 475,
  height: 475,
  type: 'svg' as DrawType,
  image: '/assets/images/soctrip-branding/soctrip-logo-blue.svg',
  margin: 6,
  qrOptions: {
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.5,
    margin: 5,
    crossOrigin: 'anonymous'
  },
  dotsOptions: {
    color: '#000',
    type: 'extra-rounded' as DotType
  },
  backgroundOptions: {
    color: '#fff'
  },
  cornersSquareOptions: {
    color: '#000'
  },
  cornersDotOptions: {
    color: '#000'
  }
};

@Component({
  selector: 'post-content-poll-qr-code',
  templateUrl: './post-content-poll-qr-code.component.html',
  styleUrls: ['./post-content-poll-qr-code.component.scss']
})
export class PostContentPollQrCodeComponent {
  @Input() pollId: string;
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild('qrCodeCanvas', { static: true }) qrCodeCanvas: ElementRef;

  qrCode: any;
  loadingQrCode: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    const visible = changes['visible']?.currentValue;
    if (visible) {
      this.loadingQrCode = true;

      if (this.qrCodeCanvas?.nativeElement) {
        this.qrCodeCanvas.nativeElement.innerHTML = '';
      }

      const data = {
        ...QR_CODE_STYLING,
        data: `${environment.SOCIAL_APP_URL}/post/${this.pollId}`
      };

      this.qrCode = new QRCodeStyling(data);
      setTimeout(() => {
        this.qrCode.append(this.qrCodeCanvas.nativeElement);
        this.loadingQrCode = false;
      }, 0);
    }
  }

  downloadQR(extension: 'png' | 'svg'): void {
    this.qrCode.download({
      name: 'Poll QR Code',
      extension
    });
  }
}
