import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlockListState, BlockUserState, UserInfoState } from './users.state';

export const userInfoState = createFeatureSelector<UserInfoState>('userInfo');

export const selectUserInfo = createSelector(userInfoState, userInfoState => userInfoState.userInfo);

export const isCurrentUserFriendLimitReached = createSelector(
  userInfoState,
  userInfoState => userInfoState.isFriendLimit
);

export const selectLoadingUserInfo = createSelector(userInfoState, userInfoState => userInfoState.loadingUserInfo);

export const selectBloomStringComment = createSelector(userInfoState, userInfoState => userInfoState.bloomStringData);

export const blockUserState = createFeatureSelector<BlockUserState>('blockUser');

export const selectLoadingBlockUser = createSelector(blockUserState, blockUserState => blockUserState.loadingBlockUser);

export const selectBlockUserData = createSelector(blockUserState, blockUserState => blockUserState.data);

export const manageBlockReducer = createFeatureSelector<BlockListState>('manageBlockReducer');

export const selectBlockingList = createSelector(manageBlockReducer, manageBlockReducer => manageBlockReducer.data);

export const selectLoadingBlockingList = createSelector(
  manageBlockReducer,
  manageBlockReducer => manageBlockReducer.loadingBlockList
);

export const selectBlockedUserBloomString = createSelector(
  userInfoState,
  userInfoState => userInfoState.bloomStringData.blockedBloomString
);

export const selectBlockingUserBloomString = createSelector(
  userInfoState,
  userInfoState => userInfoState.bloomStringData.blockingBloomString
);

export const selectCurrentRegion = createSelector(
  userInfoState,
  userInfoState => userInfoState.currentRegion
);
