import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomNumberInRange'
})
export class RandomNumberInRangePipe implements PipeTransform {
  transform(minimum: number, maximum: number) {
    return Math.round(Math.random() * (maximum - minimum) + minimum);
  }
}
