import { HttpClient } from '@angular/common/http';
import * as stagingEnv from '@env/environment.staging';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { SystemResponse } from './api.system.module';

export class SystemApi {
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}
  stagingEnv = stagingEnv.environment;

  getSystemLatestVersion(searchText: string): Observable<SystemResponse> {
    let url = `${this.stagingEnv.baseURL}/system/versions/latestVersion`;
    if (searchText !== '') url = `${url}?env=${searchText}`;
    return this.httpClient.get<SystemResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map((res: SystemResponse) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
