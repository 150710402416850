<div class="rounded-full ring-2 ring-white" [ngStyle]="{ 'width': avatarSize + 'px', 'height': avatarSize + 'px' }">
  <div class="rounded-full p-[2px] ring-2" [ngClass]="isUseLogoSoctrip ? 'ring-blue-500' : 'ring-orange-500'">
    <div
      (click)="navigateToProfile()"
      class="relative cursor-pointer"
      [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
      <div
        class="rounded-full ring-2 ring-transparent"
        [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
        <p-skeleton shape="circle" [styleClass]="'w-full h-full'" *ngIf="userAvatarUrl ===''"></p-skeleton>
        <img
          *ngIf="userAvatarUrl"
          class="rounded-full w-full h-full"
          onerror="this.src='assets/images/default_user_avatar.png'"
          [src]="userAvatarUrl" />
        <img
          onerror="this.style.display = 'none'"
          *ngIf="userFrameUrl"
          class="rounded-full absolute top-0"
          [src]="userFrameUrl" />
      </div>
    </div>
  </div>
</div>
