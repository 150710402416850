import { createActionGroup, props } from '@ngrx/store';

export const PostDialogActions = createActionGroup({
  source: 'Post Dialog',
  events: {
    'On Get Poll Id': props<{ poll_id: string }>(),
    'On Get Previous Url': props<{ previousUrl: string }>(),
    'On Upload File': props<{ fileUpload: { showProgress: boolean; progress: number } }>()
  }
});
