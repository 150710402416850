import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusIcons'
})
export class StatusIconsPipe implements PipeTransform {
  transform(label: string): string {
    switch (label) {
      case 'Excited':
        return '😀';
      case 'Cheerful':
        return '😂';
      case 'Happy':
        return '😇';
      case 'Loving':
        return '😍';
      case 'Relax':
        return '😌';
      case 'Playful':
        return '😜';
      case 'Frighten':
        return '😱';
      case 'Sad':
        return '😥';
      case 'Angry':
        return '😡';
      case 'Quite':
        return '😶';
      case 'Scornful':
        return '😏';
      case 'Sick':
        return '😷';
      case 'Cool':
        return '😎';
      case 'Sleepy':
        return '😴';
      case 'Fine':
        return '🙂';
      case 'Bored':
        return '😖';
      case 'Surprised':
        return '😲';
      case 'Tearful':
        return '😭';
    }
    return '';
  }
}
