import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PostDialogState } from './post-dialog.state';

export const postDialogStates = createFeatureSelector<PostDialogState>('postDialog');

export const selectPollId = createSelector(postDialogStates, postDialogState => postDialogState.poll_id);

export const selectPreviousUrl = createSelector(postDialogStates, postDialogState => postDialogState.previousUrl);

export const selectShowProgress = createSelector(
  postDialogStates,
  postDialogState => postDialogState.fileUpload.showProgress
);

export const selectProgress = createSelector(postDialogStates, postDialogState => postDialogState.fileUpload.progress);
