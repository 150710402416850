<div class="language-dropdown">
  <p-dropdown
    styleClass="h-9 rounded-lg bg-transparent border-none focus:ring-transparent"
    panelStyleClass="w-full rounded-lg px-2 gap-2 shadow-sm border border-palette-gray-200"
    dropdownIcon="sctr-icon-chevron-down"
    optionLabel="label"
    [options]="optionLanguage"
    [(ngModel)]="selectedLanguage"
    (onChange)="onChangeLanguage($event.value)">
    <ng-template pTemplate="selectedItem">
      <div class="flex gap-1.5 items-center" *ngIf="selectedLanguage">
        <img [src]="selectedLanguage.icon" class="h-5" />
        <div class="text-sm font-medium text-palette-gray-700">
          {{ selectedLanguage.label | translate }} ({{ selectedLanguage.nativeCountryName }})
        </div>
      </div>
    </ng-template>
    <ng-template let-languageItem pTemplate="languageItem">
      <div class="flex items-center justify-between w-full pr-10 relative">
        <div class="flex gap-1.5 items-center text-sm font-medium text-palette-gray-700">
          <img [src]="languageItem.icon" />
          <div>{{ languageItem.label | translate }} ({{ languageItem.nativeCountryName }})</div>
        </div>
        <i
          *ngIf="selectedLanguage.value === languageItem.value"
          class="sctr-icon-check text-base/4 absolute right-0 text-palette-blue-600"></i>
      </div>
    </ng-template>
  </p-dropdown>
</div>
