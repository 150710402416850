<p-dialog
  [(visible)]="deviceService.showDownloadAppDialog"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="false"
  [dismissableMask]="false"
  class="download-app-dialog">
  <div class="flex flex-col gap-3 lg:justify-between xl:gap-7 items-center">
    <span class="text-center font-normal text-palette-gray-900 text-sm lg:text-xl xl:text-2xl">{{
      'COMMON.DOWNLOAD_APP_SUGGESTION' | translate
    }}</span>
    <p-button
      class="download-btn"
      (click)="deviceService.openAppStore()"
      label="{{ 'COMMON.DOWNLOAD_NOW' | translate }}"></p-button>
    <span class="text-center font-normal text-palette-gray-500 text-xs lg:text-xl xl:text-2xl">{{
      'COMMON.FOR_MOBILE_ONLY' | translate
    }}</span>
  </div>
</p-dialog>
