import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UserService } from '@app/core/services/user.service';
import { CoinManagementActions } from '@app/modules/main/personal-profile/components/personal-profile-coin/store/action';
import { selectUserProfile } from '@app/modules/main/states/user-profiles/user-profiles.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { SIGNIN_METHOD, TYPE_SEND_COIN } from '@app/shared/constant';
import { Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';

const ERROR_CODES = {
  incorrectOTPCode: 46084,
  blockedResendOTP: 46081
};

@Component({
  selector: 'coin-sending',
  templateUrl: './coin-sending.component.html',
  styleUrls: ['./coin-sending.component.scss']
})
export class CoinSendingComponent implements OnInit {
  @Input() visible = false;
  @Input() userToSendCoin: UserInfo;
  @Input() currCoin: number;
  @Input() postToSendCoin: Post;
  @Input() handleUpdateCoin: (coin: number) => void;
  @Input() isSendToFriends = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() sendCoinSuccess = new EventEmitter<boolean>();
  isLoading = false;
  coinToSend: number;
  incorrectPassword = false;
  isStep2 = false;
  password: string = '';
  userProfile$ = this.store.select(selectUserProfile);
  isAvatarDefault = false;
  userInfo$ = this.store.select(selectUserInfo);
  email = '';
  isVerifyCodeFailed = false;
  OTPCode = '';
  isBlockedResend = false;

  constructor(
    private store: Store,
    private commonService: CommonService,
    private userService: UserService
  ) {}

  get isThirdPartySignIn() {
    const signInMethod = localStorage?.getItem('signin_method');
    return signInMethod === SIGNIN_METHOD.GOOGLE;
  }

  ngOnInit(): void {
    this.userInfo$.subscribe(res => {
      if (res) {
        this.email = res.email ? res.email : '';
      }
    });
  }

  handleNext() {
    if (this.coinToSend && !this.isStep2) {
      this.isStep2 = true;
      this.resendCode();
    }
  }

  handleBackDown() {
    if (this.isStep2) {
      this.isStep2 = false;
    } else {
      this.visible = false;
    }
  }

  handleSetCoin(coin: number) {
    this.coinToSend = coin;
  }

  handleIncreaseCoinToSend() {
    if (this.coinToSend === undefined) {
      this.coinToSend = 0;
    }
    this.coinToSend = Number(this.coinToSend) + 1;
  }

  handleReduceCoinToSend() {
    if (Number(this.coinToSend) >= 1) {
      this.coinToSend = Number(this.coinToSend) - 1;
    } else {
      this.coinToSend = 0;
    }
  }

  isCoinToSendString(): boolean {
    return isNaN(parseFloat(this.coinToSend.toString())) && !isFinite(Number(this.coinToSend));
  }

  closeDialog() {
    this.coinToSend = 0;
    this.isStep2 = false;
    this.visibleChange.emit(false);
    this.password = '';
  }

  // handle disable button
  handleDisableButton(): boolean {
    let remainCoin = this.currCoin - this.coinToSend;
    if (
      (!this.isStep2 && (this.coinToSend === undefined || Number(this.coinToSend) === 0)) ||
      this.currCoin === 0 ||
      remainCoin < 0
    ) {
      return true;
    }
    return false;
  }

  onSumbitWithOtp() {
    this.isLoading = true;
    const verifyCodeData = {
      phone_or_email: this.email,
      area_code: '',
      get_code_verification: this.OTPCode
    };
    this.userService.verifyOTPCode(verifyCodeData).subscribe(res => {
      if (res) {
        if (res.success) {
          this.onSubmitSuccess();
        } else {
          this.isLoading = false;
          if (res!.error!.code) {
            if (res.error.code === ERROR_CODES.incorrectOTPCode) {
              this.isVerifyCodeFailed = true;
            } else if (res!.error!.code === ERROR_CODES.blockedResendOTP) {
              this.blockedResend();
            }
          }
        }
      } else {
        this.isLoading = false;
      }
    });
  }

  onSubmitWithPassword() {
    this.isLoading = true;
    this.userService.confirmPassword(this.password).subscribe((res: any) => {
      if (res) {
        this.onSubmitSuccess();
      } else {
        this.isLoading = false;
        this.incorrectPassword = true;
      }
    });
  }

  onSubmitSuccess() {
    const body = {
      to: this.postToSendCoin?.page_object
        ? this.postToSendCoin?.page_object?.page_id
        : this.postToSendCoin?.user_object?.id,
      amount: this.coinToSend,
      type: this.postToSendCoin?.page_object ? TYPE_SEND_COIN.fanpage : TYPE_SEND_COIN.user,
      receiving_object_type: 'POST',
      receiving_object_id: this.postToSendCoin?.id
    };
    const bodySendToFriends = {
      destination: this.userToSendCoin?.id,
      amount: this.coinToSend
    };
    this.isSendToFriends
      ? this.store.dispatch(CoinManagementActions.onSendCoinToUser({ body: bodySendToFriends }))
      : this.store.dispatch(CoinManagementActions.onPostGiftCoin({ body: body }));
    this.isLoading = false;
    this.sendCoinSuccess.emit(true);
    this.closeDialog();
  }

  showAvatarDefault() {
    if (!this.isSendToFriends && this.postToSendCoin.page_object && this.postToSendCoin.page_object.avatar === null) {
      return true;
    } else {
      return false;
    }
  }

  getAvatar() {
    let url = '';
    if (this.isSendToFriends) {
      url = this.userToSendCoin?.avatar_thumbnail_url;
    } else {
      if (this.postToSendCoin.page_object) {
        url = this.postToSendCoin?.page_object?.avatar;
      } else {
        url = this.postToSendCoin?.user_object?.avatar_thumbnail_url;
      }
    }
    return this.commonService.getImageUrl(url);
  }

  getNameObjectToSend() {
    if (this.isSendToFriends) {
      return this.userToSendCoin?.full_name;
    } else {
      if (this.postToSendCoin?.page_object) {
        return this.postToSendCoin?.page_object?.page_name;
      } else {
        return this.postToSendCoin?.user_object?.full_name;
      }
    }
  }

  onKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  showAvailableCoin() {
    if (this.coinToSend) {
      if (this.currCoin - this.coinToSend <= 0) {
        return 0;
      } else return this.currCoin - this.coinToSend;
    } else {
      return this.currCoin;
    }
  }

  hideEmailPartially(): string {
    const email = this.email;
    if (email.includes('@')) {
      const [username, domain] = email.split('@');
      const hiddenUsername = this.hiddenStringPartially(username, 3);
      const domainName = domain.split('.')[0];
      const domainExtension = domain.substring(domain.indexOf('.'));
      const hiddenDomainName = this.hiddenStringPartially(domainName);
      return `${hiddenUsername}@${hiddenDomainName}${domainExtension}`;
    }
    return email;
  }

  hiddenStringPartially(
    text: string,
    maxVisiblePrefixCharacters = 2,
    maxVisibleSuffixCharacters = 2,
    minHiddenCharacters = 2
  ): string {
    const visibleSuffixCharacters = Math.min(
      maxVisibleSuffixCharacters,
      text.length - maxVisiblePrefixCharacters - minHiddenCharacters
    );

    return text.length < minHiddenCharacters + 2
      ? '*'.repeat(text.length)
      : text.length < maxVisiblePrefixCharacters + maxVisibleSuffixCharacters + minHiddenCharacters - 1
      ? text.slice(0, 2) + '*'.repeat(text.length - 2)
      : text.slice(0, maxVisiblePrefixCharacters) +
        '*'.repeat(Math.max(0, text.length - maxVisiblePrefixCharacters - visibleSuffixCharacters)) +
        text.slice(-visibleSuffixCharacters);
  }

  resendCode(): void {
    const sendCodeData = {
      phone_or_email: this.email,
      area_code: '',
      is_check_phone_or_email: false
    };
    this.isLoading = true;
    this.userService.sendOTPCode(sendCodeData).subscribe(res => {
      this.isLoading = false;
    });
  }

  blockedResend(): void {
    this.isBlockedResend = true;
  }
}
