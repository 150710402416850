import { Component } from '@angular/core';

interface Tag {
  id: number;
  name: string;
}

@Component({
  selector: 'discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent {
  tags: Tag[] = [
    { id: 12, name: 'Đà Lạt' },
    { id: 13, name: 'Hà Nội' },
    { id: 14, name: 'Nha Trang' },
    { id: 15, name: 'Huế' },
    { id: 16, name: 'Phú Quốc' },
    { id: 17, name: 'Sapa' },
    { id: 18, name: 'Vinh' },
    { id: 19, name: 'Cần Thơ' },
    { id: 20, name: 'Hồ Chí Minh' }
  ];

  public onButtonClicked(nameValue: string) {
    alert('You selected ' + nameValue);
  }
}
