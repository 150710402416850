import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { ExistMediaRequest, MediaApiModels } from './api.media.models';

const MEDIA_LIBRARY_PATH = '/media/libraries';

export class MediaApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) { }

  getAlbumList(libraryId: string, targetType: number): Observable<MediaApiModels.LibraryResponse> {
    const params = libraryId ? `?target_type=${targetType}&target_id=${libraryId}` : `?target_type=${targetType}`;
    return this.http.get<MediaApiModels.LibraryResponse>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}${params}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  createAlbum(
    albumName: string,
    libraryId: string,
    albumDescription?: string
  ): Observable<MediaApiModels.AlbumResponse> {
    const body = libraryId
      ? {
        name: `${albumName.trim()}`,
        description: `${albumDescription?.trim()}`,
        target_id: `${libraryId}`
      }
      : { name: `${albumName.trim()}`, description: `${albumDescription?.trim()}` };
    return this.http.post<MediaApiModels.AlbumResponse>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateAlbum(albumId: string, body: any, targetType: number): Observable<MediaApiModels.AlbumResponse> {
    return this.http
      .put<MediaApiModels.Album>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/${albumId}?target_type=${targetType}`, body)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteAlbum(albumId: string, libraryId: string, targetType: number): Observable<MediaApiModels.DeleteAlbumResponse> {
    const targetTypePram = `target_type=${targetType}`;
    const targetIdPram = !!libraryId ? `&target_id=${libraryId}` : '';
    const params = `${targetTypePram}${targetIdPram}`;
    return this.http
      .delete<MediaApiModels.DeleteAlbumResponse>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/${albumId}?${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getMediaList(
    albumId: string,
    libraryId: string,
    targetType: number
  ): Observable<MediaApiModels.AlbumWithMediaResponse> {
    const params = libraryId ? `?target_type=${targetType}&target_id=${libraryId}` : `?target_type=${targetType}`;
    return this.http
      .get<MediaApiModels.AlbumWithMediaResponse>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/${albumId}${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getRecentImages(libraryId: string, targetType: number): Observable<MediaApiModels.ImagesResponse> {
    const params = libraryId ? `?target_type=${targetType}&target_id=${libraryId}` : `?target_type=${targetType}`;
    return this.http.get<MediaApiModels.ImagesResponse>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/images${params}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  uploadFilesToAlbum(
    albumId: string,
    files: FormData,
    libraryId: string
  ): Observable<MediaApiModels.UploadedMediaListReponse> {
    const albumIdPrams = albumId ? '&library_id=' + albumId : '';
    const libraryIdPrams = libraryId ? '&target_id=' + libraryId : '';
    return this.http
      .post<MediaApiModels.UploadedMediaListReponse>(
        `${this.apiUrl}${MEDIA_LIBRARY_PATH}/uploadFiles?${albumIdPrams}${libraryIdPrams}`,
        files
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  uploadFileToAlbum(
    albumId: string,
    file: FormData,
    fileDescription: string,
    libraryId: string
  ): Observable<MediaApiModels.UploadedMediaReponse> {
    const namePrams = fileDescription ? '&description=' + fileDescription : '';
    const albumIdPrams = albumId ? '&library_id=' + albumId : '';
    const libraryIdPrams = libraryId ? '&target_id=' + libraryId : '';
    return this.http
      .post<MediaApiModels.UploadedMediaReponse>(
        `${this.apiUrl}${MEDIA_LIBRARY_PATH}/uploadFile?${namePrams}${albumIdPrams}${libraryIdPrams}`,
        file
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  downloadMedia(media: MediaApiModels.Media): Observable<any> {
    return this.http.get(this.apiUrl + media.uris.original, {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteMediaList(mediaListId: string[], albumId: string, libraryId: string, targetType: number): Observable<string[]> {
    const targetTypePram = `target_type=${targetType}`;
    const targetIdPram = !!libraryId ? `&target_id=${libraryId}` : '';
    const params = `${targetTypePram}${targetIdPram}`;
    const body = { files: mediaListId };
    return this.http
      .delete<string[]>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/${albumId}/deleteFiles?${params}`, {
        body: body
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  uploadUserAvatarOrCoverMedia(
    place: 'avatar' | 'cover',
    file: FormData
  ): Observable<MediaApiModels.UploadMediaReponse> {
    return this.http.post<MediaApiModels.UploadMediaReponse>(this.apiUrl + `/media/libraries/${place}`, file);
  }

  uploadUserFrameMedia(file: FormData): Observable<MediaApiModels.UploadMediaReponse> {
    return this.http.post<MediaApiModels.UploadMediaReponse>(this.apiUrl + `/media/libraries/uploadFile`, file);
  }

  updateGroupAvatarOrCover(target_id: string, files: FormData): Observable<any> {
    return this.http.post<MediaApiModels.UploadMediaReponse>(
      this.apiUrl + `/media/file/uploadFile?target_id=${target_id}`,
      files
    );
  }

  uploadGroupAvatarOrCover(
    place: 'avatar' | 'cover',
    file: FormData,
    groupId: string | null
  ): Observable<MediaApiModels.UploadMediaReponse> {
    return this.http.post<MediaApiModels.UploadMediaReponse>(
      this.apiUrl + `/media/libraries/${place}?target_id=${groupId}`,
      file
    );
  }

  getAllLibraries(
    targetId: string,
    targetType: string,
    pageNum: number = 0,
    pageSize: number = 10,
    previewImageSize: number = 5
  ): Observable<any> {
    const url = `${this.apiUrl}${MEDIA_LIBRARY_PATH}/${targetId}/all?pageNum=${pageNum}&pageSize=${pageSize}&previewImageSize=${previewImageSize}&targetType=${targetType}`;
    return this.http.get<any>(url).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getLibraryFiles(id: string, pageNum: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/${id}/files?pageNum=${pageNum}&pageSize=${pageSize}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateExistCover(body: ExistMediaRequest): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/cover/existing-photo`, body)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateExistAvatar(body: ExistMediaRequest): Observable<any> {
    return this.http
      .put<any>(`${this.apiUrl}${MEDIA_LIBRARY_PATH}/avatar/existing-photo`, body)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
