<p-card [styleClass]="'shadow-sm'">
  <ng-template pTemplate="header">
    <div class="pt-4 px-4">
      <h1 class="font-semibold text-lg">{{ 'HOME.DISCOVER' | translate }}</h1>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-wrap pt-3 pb-4 px-4 gap-y-2 gap-x-1.5">
      <tag
        [tagObject]="tag"
        *ngFor="let tag of tags"
        (buttonClicked)="onButtonClicked($event)"></tag>
    </div>
  </ng-template>
</p-card>
