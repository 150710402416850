import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { CheckInAPI } from './api.check-in.model';

export class CheckInApi {
  // TODO: Remove this when we have API get list locations
  fakeUrl = 'https://dummyjson.com/users';
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getLocations(): Observable<CheckInAPI.MultipleDataResponse<CheckInAPI.SelectLocationResponse>> {
    return this.http.get<CheckInAPI.MultipleDataResponse<CheckInAPI.SelectLocationResponse>>(this.fakeUrl).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
