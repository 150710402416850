export enum Features {
  Home = 'home',
  Friend = 'friend',
  Groups = 'groups',
  Posts = 'posts',
  Products = 'products',
  ProductsAdd = 'productsadd',
  Users = 'users',
  Profiles = 'profiles',
  UserInfo = 'userInfo',
  searchGlobal = 'searchGlobal',
  suggestSearch = 'suggestSearch',
  searchPosts = 'searchPosts',
  searchPeoples = 'searchPeoples',
  searchPlaces = 'searchPlaces',
  searchTours = 'searchTours',
  searchGroups = 'searchGroups',
  searchFriends = 'searchFriends',
  newsFeeds = 'newsFeeds',
  accountNoti = 'accountNoti',
  searchHistory = 'searchHistory',
  Stories = 'stories',
  friendStatus = 'friendStatus',
  relationshipStatus = 'relationshipStatus',
  editPost = 'editPost',
  pinPost = 'pinPost',
  BlockUser = 'blockUser',
  PostGroups = 'postGroups',
  CreatePost = 'createPost',
  FanpageFollower = 'fanpageFollower',
  ManageBlockReducer = 'manageBlockReducer',
  Events = 'events',
  DetailPost = 'detailPost'
}

export enum createPostDialogStatusEnum {
  photo = 'photo',
  status = 'status',
  checkin = 'checkin',
  poll = 'poll'
}

export enum AppQueryParam {
  ReturnUrl = 'r'
}
