<div
  *ngIf="friendsSuggested.content?.length !== 0"
  class="py-6 px-4 mt-2 flex gap-2 flex-col bg-palette-base-white rounded">
  <h2 class="font-semibold text-lg text-palette-gray-900">
    {{ 'PERSONAL_PROFILE.WIDGET.PEOPLE_YOU_MAY_KNOW' | translate }}
  </h2>
  <ng-container *ngIf="isFirstLoad && friendsSuggested.content?.length === 0">
    <div class="flex">
      <loading-people-card *ngFor="let item of [].constructor(loadingSkeletons)"></loading-people-card>
    </div>
  </ng-container>
  <p-carousel
    [value]="friendsSuggested.content ?? []"
    [numVisible]="numVisible"
    [numScroll]="2"
    [circular]="false"
    [showIndicators]="false"
    (onPage)="onLoadMorePeople()">
    <ng-template let-people pTemplate="item">
      <people-card
        [totalLoadedData]="friendsSuggested.content ?? []"
        [loading]="loading"
        [customData]="people"
        [type]="CURRENT_SCREEN.NEWS_FEED"
        (newsFeedActionEmit)="handleFriendActions($event)">
      </people-card>
    </ng-template>
  </p-carousel>
</div>
