import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ApiClient } from '@app/lib/api/api-client';
import { EventApiModels, NonAttendingFriendResponseModel } from '@app/lib/api/event/api.event.models';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private api: ApiClient;
  baseURL = environment.baseURL;

  constructor(
    private toastMessageService: ToastMessageService,
    private http: HttpClient
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  handleErrorResponse(res: any): any {
    if (!res.success) {
      this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, res.error.message);
    }
  }

  getEventList(
    ownerId: string,
    type: string,
    search = '',
    page = 0,
    size = 10
  ): Observable<EventApiModels.GetEventResponse> {
    return this.api.event.getEventList(ownerId, type, search, page, size).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  createEvent(eventData: any): Observable<EventApiModels.EventDetailsResponse> {
    return this.api.event.createEvent(eventData.owner_id, eventData).pipe(
      map(res => {
        return res;
      })
    );
  }

  editEvent(eventData: any): Observable<EventApiModels.EventDetailsResponse> {
    return this.api.event.updateEvent(eventData.owner_id, eventData).pipe(
      map(res => {
        return res;
      })
    );
  }

  getEventDetails(eventId: string): Observable<EventApiModels.EventDetails> {
    return this.api.event.getEventDetails(eventId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  updateAttendEvent(eventId: string): Observable<EventApiModels.Event> {
    return this.api.event.updateAttendEvent(eventId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  updateInterestEvent(eventId: string): Observable<EventApiModels.Event> {
    return this.api.event.updateInterestEvent(eventId).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  getFollowingEvents(
    type: string,
    search: string | null,
    attended = false,
    interested = false,
    pageNum = 0,
    pageSize = 10
  ): Observable<EventApiModels.EventListPaging> {
    return this.api.event.getFollowingEvents(type, search, attended, interested, pageNum, pageSize).pipe(
      map((res: any) => {
        return res && res.success ? res.data : {};
      })
    );
  }

  deleteEvent(eventId: string): Observable<boolean> {
    return this.api.event.deleteEvent(eventId).pipe(map((res: boolean) => res));
  }

  inviteFriendToEvent(object_id: string, event_id: string, users_list: string[]): Observable<boolean> {
    return this.api.event.inviteFriendToEvent(object_id, event_id, users_list);
  }

  getNonAttendingFriendList(
    eventId: string,
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    search?: string
  ): Observable<NonAttendingFriendResponseModel> {
    return this.api.event.getNonAttendingFriendList(eventId, pageNum, pageSize, search).pipe(
      map((res: any) => {
        return res && res.success ? res.data : {};
      })
    );
  }
}
