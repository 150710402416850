<div class="w-full flex justify-center">
  <div class="py-5 w-full h-[352px] flex items-center">
    <div class="w-full flex flex-col gap-6 justify-center items-center">
      <div *ngIf="!isArchived" class="w-full flex flex-col gap-6 items-center justify-center">
        <img src="assets/images/inaccessible.png" alt="" class="w-[172px] h-[172px]" />
        <span class="font-semibold text-lg text-palette-gray-900">
          {{ 'COMMON.CONTENT_NOT_AVAILABLE' | translate }}</span>
      </div>
      <div *ngIf="isArchived" class="w-full flex flex-col gap-6 items-center justify-center">
        <img src="assets/images/no-content-archived.png" alt="" class="w-[172px] h-[172px]" />
        <span class="font-semibold text-lg text-palette-gray-900">
          {{ (isStory ? 'STORY.NO_DATA_ARCHIVED' : 'POST.NO_DATA_ARCHIVED') | translate }}</span>
      </div>
      <p-button *ngIf="isShowReturnBtn" [label]="'COMMON.GO_TO_HOME_PAGE' | translate"
        [styleClass]="'rounded-lg py-3 px-5 bg-palette-blue-600 text-palette-base-white'"
        (onClick)="goToHomePage()"></p-button>
    </div>
  </div>
</div>