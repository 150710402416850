import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input() isShowDialog = false;
  @Input() isRedBtn = false;
  @Input() headerName = '';
  @Input() description = '';
  @Input() leftBtnLabel = '';
  @Input() rightBtnLabel = '';
  @Input() widthClass = '';

  @Output() confirm = new EventEmitter();
  @Output() isShowDialogChange = new EventEmitter<boolean>();

  buttonClickedEmit(isOk: boolean) {
    this.confirm.emit(isOk);
  }
}
