<p-dialog
  [header]="'POST.POPUP.PRIVACY.HEADER' | translate"
  [(visible)]="isShowEditPrivacy"
  (onHide)="handleCloseModal()"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  class="custom-dialog text-gray-700">
  <ng-template pTemplate="content">
    <ul class="grid gap-y-2">
      <li *ngFor="let item of privacy_list;let index = index" class="flex gap-3 items-center py-3 px-4 border rounded-lg cursor-pointer"
      [ngClass]="item.isActive ? 'border-palette-blue-600 bg-palette-blue-50' : 'border-palette-gray-300'"
      (click)="item.action(index)"
      >
        <i [ngClass]="item.icon" class="text-xl leading-5 text-palette-gray-500"></i>
        <p class="flex flex-col flex-1" >
          <span 
          class="font-medium"
          [ngClass]="item.isActive ? 'text-palette-blue-600' : 'text-palette-gray-600'"
          >{{item.title | translate}}</span>
          <span class="text-palette-gray-500 font-normal">{{item.description | translate}}</span>
        </p>
        <p-checkbox [ngModel]="item.isActive" [binary]="true"></p-checkbox>
      </li>
    </ul>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        (click)="handleCloseModal()"
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit">
        <p class="text-base font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
      (click)="handleConfirm()"
      class="rounded-lg border border-solid border-palette-blue-600 px-4 h-10 text-white bg-palette-blue-600"
      [ngClass]="{
        'pointer-events-none opacity-80' : (loadingEdit$ | async)
      }"
      >
        {{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
