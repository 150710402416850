import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AccountSettingNotificationsAPI } from '../account-setting-notifications/api.account-setting-notifications.model';
import { ApiClientConfig } from '../api-client.config';

export class AccountSettingNotificationsApi {
  private apiUrl: string = environment.baseURL;
  USER_NOTIFICATIONS = '/notification/usernotifications/';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getAccountNotifications(user_id: string): Observable<AccountSettingNotificationsAPI.Notification[]> {
    return this.http
      .get<AccountSettingNotificationsAPI.GetNotificationsResponse>(
        `${this.apiUrl}${this.USER_NOTIFICATIONS}${user_id}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: AccountSettingNotificationsAPI.GetNotificationsResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  editAccountNotifications(
    user_id: string,
    body: AccountSettingNotificationsAPI.Notification
  ): Observable<AccountSettingNotificationsAPI.Notification> {
    return this.http
      .put<AccountSettingNotificationsAPI.EditNotificationsResponse>(
        `${this.apiUrl}${this.USER_NOTIFICATIONS}${user_id}`,
        body
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: AccountSettingNotificationsAPI.EditNotificationsResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  resetAccountNotifications(user_id: string): Observable<AccountSettingNotificationsAPI.Notification[]> {
    return this.http
      .put<AccountSettingNotificationsAPI.GetNotificationsResponse>(
        `${this.apiUrl}${this.USER_NOTIFICATIONS}${user_id}/reset`,
        {}
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: AccountSettingNotificationsAPI.GetNotificationsResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
