import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { AddressAPI } from '@app/lib/api/address/api.address.models';
import { ApiClient } from '@app/lib/api/api-client';
import { LocationApiModels } from '@app/lib/api/map-location/api.map-location.models';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  baseURL = environment.baseURL;
  private api: ApiClient;

  constructor(
    private toastMessageService: ToastMessageService,
    private http: HttpClient
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  handleErrorResponse(res: any): any {
    if (!res.success) {
      this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, res.error.message);
    }
  }

  searchLocation(
    searchKey: string,
    maxDestination = 3,
    maxPlace = 2,
    isDeleted = false
  ): Observable<LocationApiModels.SearchLocation> {
    return this.api.mapLocation.searchLocation(searchKey, maxDestination, maxPlace, isDeleted).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        if (res.success) {
          return res.data;
        }
        return res;
      })
    );
  }

  searchPlaceByCoordinates(
    latitude: number,
    longitude: number,
    distance = null,
    isPaging = true,
    page = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT
  ): Observable<LocationApiModels.SearchPlacePaging> {
    return this.api.mapLocation.searchPlaceByCoordinates(latitude, longitude, distance, isPaging, page, pageSize).pipe(
      map((res: any) => {
        this.handleErrorResponse(res);
        if (res.success) {
          return res.data;
        }
        return res;
      })
    );
  }

  getCountries(): Observable<AddressAPI.CountriesModelResponse[]> {
    return this.api.addressApi.getCountries().pipe(
      map(res => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  getProvinces(countryId: string): Observable<AddressAPI.ProvinceModelResponse[]> {
    return this.api.addressApi.getProvinces(countryId).pipe(
      map(res => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }

  getDistricts(provinceId: string): Observable<AddressAPI.DistrictsModelResponse[]> {
    return this.api.addressApi.getDistricts(provinceId).pipe(
      map(res => {
        this.handleErrorResponse(res);
        return res.data;
      })
    );
  }
}
