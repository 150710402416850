<p-card class="w-full" [styleClass]="'shadow-sm'">
  <div class="flex flex-col relative" #container>
    <div class="bg-white rounded-md min-h-[217px]" #captureContainer>
      <div
        class="relative rounded-b-lg rounded-t-md bg-gradient-to-r from-[#FEAD34] to-[#FF7041] flex flex-col items-center gap-3 h-[243px]">
        <div class="absolute top-0 left-0">
          <img src="assets/images/blob_right_3.png" />
        </div>
        <div class="absolute top-0 left-0">
          <img src="assets/images/blob_right_2.png" />
        </div>
        <div class="absolute top-[130px] right-0">
          <img src="assets/images/Blob_right.png" />
        </div>
        <div class="absolute top-[108px] right-0">
          <img src="assets/images/blob_right_4.png" />
        </div>
        <div class="flex items-center justify-center">
          <div (click)="navigateToProfile()" class="rounded-full ring-2 ring-white mt-6 relative cursor-pointer">
            <img
              class="z-20 bg-white rounded-full w-[96px] h-[96px]"
              onerror="this.src='assets/images/default_user_avatar.png'"
              [src]="avatarUrl"
              alt="profile-pic" />
            <img
              alt="frame"
              *ngIf="frameUrl"
              onerror="this.style.display = 'none'"
              class="rounded-full absolute top-0 z-50"
              [src]="frameUrl" />
            <div class="avata-icon-fly2 absolute top-0 right-[-5px]">
              <img alt="tier" *ngIf="isLoggedIn" src="assets/icons/tier.svg" />
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center gap-0.5">
          <div class="flex items-center gap-1 w-52 justify-center">
            <div
              class="text-lg font-semibold text-palette-base-white text-center cursor-pointer truncate"
              (click)="navigateToProfile()">
              {{ userInfo && userInfo.full_name }}
            </div>
            <div class="flex justify-center items-center relative w-5 h-5" *ngIf="userInfo?.is_verified_user">
              <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-xl/[20px]"></i>
              <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-lg/[18px]"></i>
            </div>
          </div>
          <div class="flex items-center rounded-full justify-center bg-palette-orange-dark-50 gap-[6px] px-1">
            <img *ngIf="isLoggedIn" class="w-3 h-3" src="assets/icons/badge/pioneer.svg" />
            <div
              *ngIf="isLoggedIn"
              class="text-[10px]/[16px] font-normal text-center text-palette-orange-dark-600 whitespace-nowrap">
              {{ 'COMMON.DEFAULT_BADGE' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div
        [hidden]="selectedReport && !Object.keys(selectedReport).length"
        class="absolute w-[258px] h-auto p-2 bg-palette-base-white shadow-md rounded-lg left-[9px] top-[200px]"
        #child>
        <div *ngIf="isLoggedIn" class="flex justify-between">
          <div class="text-xs font-semibold text-palette-gray-700">{{ dateLabel | translate }}</div>
          <div class="text-xs font-medium text-palette-gray-500" *ngIf="selectedReport">
            {{ selectedReport.start_date | dateFormatPipe }} -
            {{ selectedReport.end_date | dateFormatPipe }}
          </div>
          <div class="text-xs font-medium text-palette-gray-500 flex gap-1" *ngIf="!selectedReport">
            <p-skeleton width="5rem" height="14px"></p-skeleton> -
            <p-skeleton width="5rem" height="14px"></p-skeleton>
          </div>
        </div>
        <div class="grid w-full grid-cols-2 gap-x-3 gap-y-2 mt-2">
          <ng-container *ngFor="let item of listQuickSummary">
            <div *ngIf="selectedReport?.hasOwnProperty(item.value)" class="flex gap-[6px] w-auto py-2">
              <div class="flex justify-center items-center h-8 rounded-full" [ngClass]="item.bgColor">
                <div class="{{ item.bgIcon }}">
                  <i class="{{ item.icon }} m-[8px] text-base"></i>
                </div>
              </div>
              <div>
                <div class="text-xs font-semibold" [ngClass]="item.textColor">{{ item.label | translate }}</div>
                <div class="text-xs font-semibold">
                  {{ selectedReport && selectedReport[item.value] ? selectedReport[item.value] : '0' }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!selectedReport">
            <div class="flex gap-[6px] w-auto py-2 items-center" *ngFor="let item of [].constructor(quickReportTotal)">
              <p-skeleton shape="circle" size="30px" />
              <div class="flex gap-1 flex-col">
                <p-skeleton width="5rem" height="14px"></p-skeleton>
                <p-skeleton width="5rem" height="14px"></p-skeleton>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="pl-[9px] mb-3 flex gap-2 w-full">
      <p-button
        *ngIf="isLoggedIn"
        (onClick)="screenShot()"
        [disabled]="isConverting"
        [styleClass]="
          'text-palette-gray-100 h-9 py-2 w-[214px] flex justify-center items-center space-x-2 bg-palette-gray-100 hover:bg-palette-gray-200 transition-colors border-0'
        ">
        <i
          class="text-xl/5 text-palette-gray-700"
          [ngClass]="isConverting ? 'sctr-icon-loading-02 animate-spin' : 'sctr-icon-share-06'"></i>
        <span class="text-sm font-bold text-palette-gray-700">{{ 'ENTITY.SHARE' | translate }}</span>
      </p-button>
      <p-button
        *ngIf="isLoggedIn"
        (onClick)="openOverlayCustomSummary()"
        [disabled]="isConverting"
        [styleClass]="'p-2 flex justify-center h-9 w-9 items-center bg-palette-gray-100 border-0'">
        <i class="text-xl text-palette-gray-700 sctr-icon-dots-vertical"></i>
      </p-button>
    </div>
    <p-overlay
      [(visible)]="overlayVisible"
      [contentStyleClass]="'px-4 py-2 shadow-2 border-round float-right'"
      [styleClass]="'z-10'">
      <p-button
        (onClick)="openDialogCustomSummary()"
        [styleClass]="
          'flex bg-palette-base-white items-center shadow-md gap-3 border-0 cursor-pointer rounded-lg w-[182px] h-[44px] p-2'
        ">
        <i class="sctr-icon-sliders-04 text-palette-gray-700 text-xl"></i>
        <div class="text-sm text-palette-gray-700 font-normal">{{ 'QUICK_SUMMARY.CUSTOMIZE' | translate }}</div>
      </p-button>
    </p-overlay>
  </div>
</p-card>
