import { Injectable } from '@angular/core';
import { SharedPrivileges } from '@app/lib/constants/privileges';
import { UserInfo } from '@app/shared/models/user';
import { BytesConversionPipe } from '@app/shared/pipes/bytes-conversion.pipe';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {
  hasPrivilege(privileges: any[], privilege: string, exactMatch: boolean = true): boolean {
    if (!privileges?.length) return false;
    if (exactMatch) return privileges?.find((p: string) => p.includes(privilege));
    else return privileges?.find((p: string) => p === privilege);
  }

  hasLargeFileUploadPrivilege(privileges: any[], exactMatch: boolean = true): boolean {
    return this.hasPrivilege(privileges, SharedPrivileges.LARGE_FILE_UPLOAD, exactMatch);
  }

  getPrivilegedUploadLimit(privileges: any[]): number {
    const privilege = privileges.find((p: string) => p.includes(SharedPrivileges.LARGE_FILE_UPLOAD));

    if (privilege) {
      const match = privilege.match(/(\d+)(GB|gb)/);
      if (match) {
        const [_, size, unit] = match;
        const bytesConversionPipe = new BytesConversionPipe();
        return bytesConversionPipe.convertToBytes(parseInt(size, 10), unit.toUpperCase());
      }
    }
    return environment.FILE_SIZE_LIMITS.video;
  }

  generateResumableIdentifier(file: any, userInfo: UserInfo): string {
    const customFileName = this.generateUpdatedFilename(file.name, userInfo.id, 'vn');
    file.fileName = `${file.size}-${customFileName}`;
    return `${file.size}-${customFileName}`;
  }

  generateUpdatedFilename(originalFileName: string, createdBy: string, prefix: 'vn' | 'en') {
    let fileNameRaw = originalFileName.replace(/[^A-Za-z\d]/g, '');

    if (fileNameRaw.length > 30) {
      fileNameRaw = fileNameRaw.slice(-30);
    }

    const dateTime = new Date().toISOString().replace(/[^A-Za-z\d]/g, '');

    const fileBaseName = `${prefix}_${dateTime}_${createdBy}_${fileNameRaw}`;

    const extension = originalFileName.includes('.') ? '.' + originalFileName.split('.').pop() : '';

    const updatedFileName = fileBaseName + this.generateRandomString(10) + extension;

    return updatedFileName;
  }

  generateRandomString(length: number): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }
}
