<p-dialog
  header="{{ 'BOOKMARK.SAVE_TO' | translate }}"
  [(visible)]="showBookMarkCategory"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="!isLoading ? handleCloseModal() : ''"
  [dismissableMask]="true"
  [styleClass]="'w-[384px] round-xl'">
  <div #scrollContainer class="max-h-[212px] overflow-y-auto" (scroll)="loadToScrolled()">
    <div *ngFor="let item of listBookMarkCollection">
      <div class="flex gap-2 mb-4 items-center">
        <p-radioButton name="category" [value]="item.id" [(ngModel)]="selectedCollection"></p-radioButton>
        <label class="font-medium text-sm text-palette-gray-700">{{ item.name | translate }}</label>
      </div>
    </div>
  </div>
  <div class="border-t items-center flex gap-2 border-dashed py-4 border-palette-gray-200">
    <p-radioButton name="category" [value]="'newCollectionOption'" [(ngModel)]="selectedCollection"></p-radioButton>
    <span class="font-medium text-sm text-palette-gray-700">{{ 'BOOKMARK.NEW_COLLECTION' | translate }}</span>
  </div>
  <div *ngIf="selectedCollection === optionNewCollection">
    <label htmlFor="collectionName" class="font-normal text-palette-gray-700 mb-1 text-sm">{{
      'BOOKMARK.COLLECTION_NAME' | translate
    }}</label>
    <span class="ml-1 text-palette-red-600">*</span>
    <input
      pInputText
      placeholder="{{ 'BOOKMARK.ENTER_COLLECTION_NAME' | translate }}"
      class="rounded-lg w-full"
      [(ngModel)]="newCollection"
      required />
  </div>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="handleCloseModal()"
      [styleClass]="
        'rounded-lg border-palette-gray-300 hover:bg-palette-gray-200 w-auto text-palette-gray-900 font-semibold text-sm'
      "
      label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
      styleClass="p-button-text"
      [disabled]="isLoading">
    </p-button>
    <p-button
      [styleClass]="
        'rounded-lg bg-palette-blue-600 hover:bg-palette-blue-700 border-palette-gray-300 w-auto text-palette-base-white font-semibold text-sm'
      "
      (onClick)="addBookMark()"
      [icon]="isLoading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
      styleClass="p-button-text"
      [disabled]="
        (newCollection === '' && selectedCollection === optionNewCollection) || !selectedCollection || isLoading
      ">
    </p-button>
  </ng-template>
</p-dialog>
