import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@app/core/services/translation.service';
import { TAGS } from '@app/shared/constant';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() translatePath: string;
  @Input() tagObject: any;
  @Input() isActive: boolean = false;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  searchTab = TAGS.ALL

  constructor(
    private translateService: TranslationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.searchTab = params['searchTab'];
      const tagArr = Object.values(TAGS);
      if(!tagArr.includes(this.searchTab)){
        this.searchTab = 'All'
      }
    })
   
  }

  translate(tagName: string): string {
    const key = tagName.toUpperCase().replaceAll(' ', '_');
    if (!this.translatePath) {
      return tagName;
    }
    return this.translateService.getTranslation(`${this.translatePath}.${key}`);
  }
}
