import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DetailPostState } from './detail-post.state';

export const detailPostState = createFeatureSelector<DetailPostState>('detailPost');

export const selectDetailPost = createSelector(detailPostState, detailPostState => detailPostState.infoPost);

export const selectComments = createSelector(detailPostState, detailPostState => detailPostState.comments);

export const selectReactionComment = createSelector(detailPostState, detailPostState => detailPostState.reactionComment);

export const selectHidePostId = createSelector(detailPostState, detailPostState => detailPostState.hidePostId);


