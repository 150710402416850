import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
  constructor(
    private translationService: TranslationService
  ) { }
  transform(number: number, args?: any): any {
    if (isNaN(number)) return null;
    if (number === null) return 0;
    if (number === 0) return 0;

    if (number.toString().includes(',')) {
      number = parseFloat(number.toString().replace(/\,/g, ''));
    }

    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0;
    let key = '';

    const powers = [
      { key: this.translationService.getTranslation('NUMBER_ABBREVIATIONS.QUADRILLION_SHORTEN'), value: Math.pow(10, 15) }, // Quadrillion
      { key: this.translationService.getTranslation('NUMBER_ABBREVIATIONS.TRILLION_SHORTEN'), value: Math.pow(10, 12) }, // Trillion
      { key: this.translationService.getTranslation('NUMBER_ABBREVIATIONS.BILLION_SHORTEN'), value: Math.pow(10, 9) }, // Billion
      { key: this.translationService.getTranslation('NUMBER_ABBREVIATIONS.MILLION_SHORTEN'), value: Math.pow(10, 6) }, // Million
      { key: this.translationService.getTranslation('NUMBER_ABBREVIATIONS.THOUSAND_SHORTEN'), value: 1000 } // Thousand
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }
}
