export enum AppConnectMessageType {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
  SUCCESSFUL = 'success',
  CHANGE_LANGUAGE = 'changeLanguage',
  ADD_OWNER_USER_ID = 'addOwnerUserId',
  ADD_OWNER_USER_ID_SUCCESS = 'addOwnerUserIdSuccess',
  ADD_OWNER_USER_ID_FAIL = 'addOwnerUserIdFail'
}

export enum AppConnectEventType {
  MESSAGE = 'message'
}
