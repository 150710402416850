<div class="flex flex-1 flex-col justify-center md:w-80 lg:w-[595px] max-w-[30vw] h-full gap-5">
  <span class="font-bold text-[56px] text-branding-primary-900">
    {{ 'SIGNIN_SIGNUP.WELCOME.WELCOME_TITLE' | translate: { appName: this.appName } }}
  </span>
  <span class="text-3xl text-palette-gray-400">{{ imageUrl.text | translate }}</span>
  <img
    defaultImage
    id="login-img"
    class="max-w-[80%] max-h-[70vh]"
    [src]="imageUrl.src"
    alt="Login"
    (click)="changeImage()" />
</div>
