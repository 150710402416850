import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReactionsService } from '@app/core/services/reactions.service';
import { isCurrentUserFriendLimitReached } from '@app/modules/main/states/users/users.selectors';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared/constant';
import { EmojiList, Post, ReactionType } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';

interface ListReaction {
  reactionType: string;
  pageNum: number;
  pageSize: number;
}

@Component({
  selector: 'dialog-list-reaction',
  templateUrl: './dialog-list-reaction.component.html',
  styleUrls: ['./dialog-list-reaction.component.scss']
})
export class DialogListReactionComponent {
  @Input() visible: boolean;
  @Input() itemsTabMenu: MenuItem[] = [];
  @Input() activeItemTabMenu: MenuItem;
  @Input() userInfo: UserInfo;
  @Input() data: Post;
  @Input() selectedEmoji: any;
  @Output() visibleChange = new EventEmitter<boolean>();

  isFriendLimit$ = this.store.select(isCurrentUserFriendLimitReached);

  reactionUser: {
    [key: string]: {
      id: string;
      full_name: string;
      reaction_type?: string;
      created_by?: UserInfo;
      isFriend?: boolean;
    }[];
  };

  listReaction: ListReaction[] = [
    { reactionType: ReactionType.all, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.love, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.haha, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.wow, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.sad, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT },
    { reactionType: ReactionType.angry, pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT }
  ];

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private reactionsService: ReactionsService,
    private store: Store
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedEmoji'] && this.reactionUser) {
      //Add
      if (changes['selectedEmoji'].currentValue && changes['selectedEmoji'].previousValue === null) {
        const selectedEmoji = changes['selectedEmoji'].currentValue;
        const data = {
          id: this.userInfo.id,
          full_name: this.userInfo.full_name,
          reaction_type: selectedEmoji.type
        };
        if (this.reactionUser[ReactionType.all]) this.reactionUser[ReactionType.all].unshift(data);
        if (this.reactionUser[selectedEmoji.type]) this.reactionUser[selectedEmoji.type].unshift(data);
        return;
      }
      //Remove
      if (changes['selectedEmoji'].currentValue === null && changes['selectedEmoji'].previousValue) {
        const selectedEmoji = changes['selectedEmoji'].previousValue;
        if (this.reactionUser[ReactionType.all]) {
          this.reactionUser[ReactionType.all] = this.reactionUser[ReactionType.all].filter(
            user => user.id !== this.userInfo.id
          );
        }
        if (this.reactionUser[selectedEmoji.type]) {
          this.reactionUser[selectedEmoji.type] = this.reactionUser[selectedEmoji.type].filter(
            user => user.id !== this.userInfo.id
          );
        }
        return;
      }
      //Edit
      if (
        changes['selectedEmoji'].currentValue &&
        changes['selectedEmoji'].previousValue &&
        changes['selectedEmoji'].currentValue !== changes['selectedEmoji'].previousValue
      ) {
        const selectedEmojiCurrent = changes['selectedEmoji'].currentValue;
        const selectedEmojiPrevious = changes['selectedEmoji'].previousValue;
        if (this.reactionUser[ReactionType.all]) {
          this.reactionUser[ReactionType.all] = this.reactionUser[ReactionType.all].map(user => {
            if (user.id === this.userInfo.id) {
              return { ...user, reaction_type: selectedEmojiCurrent.type };
            }
            return user;
          });
        }
        if (this.reactionUser[selectedEmojiCurrent.type]) {
          const data = {
            id: this.userInfo.id,
            full_name: this.userInfo.full_name,
            reaction_type: selectedEmojiCurrent.type
          };
          this.reactionUser[selectedEmojiCurrent.type].unshift(data);
        }
        if (this.reactionUser[selectedEmojiPrevious.type]) {
          this.reactionUser[selectedEmojiPrevious.type] = this.reactionUser[selectedEmojiPrevious.type].filter(
            user => user.id !== this.userInfo.id
          );
        }
      }
    }
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItemTabMenu = event;
    let pageSize = this.listReaction.find(s => event.id === s.reactionType)?.pageSize;
    if (pageSize === PAGE_SIZE_DEFAULT && event.id !== ReactionType.all) {
      this.getReactionsByEntityId(this.activeItemTabMenu.id);
    }
  }

  filterItemToShow(): MenuItem[] {
    return this.itemsTabMenu.filter(item => parseInt(item.label || '0') > 0);
  }

  getIconReaction(type: string) {
    return EmojiList.find(el => el.type === type)?.icon;
  }

  getReactionsByEntityId(type: string = ReactionType.all) {
    let pageNum = this.listReaction.find(s => type === s.reactionType)?.pageNum;
    let pageSize = this.listReaction.find(s => type === s.reactionType)?.pageSize;
    this.reactionsService
      .getReactionsByEntityId(this.data.id, type === ReactionType.all ? '' : type, pageNum, pageSize)
      .subscribe(reactions => {
        const data = reactions.map(el => {
          return {
            id: el.created_by.id,
            full_name: el.created_by.full_name,
            reaction_type: el.reaction_type,
            created_by: el.created_by,
            isFriend: el.created_by.is_friend
          };
        });
        const reactionType = type === '' ? ReactionType.all : type;

        if (this.reactionUser && this.reactionUser.hasOwnProperty(reactionType)) {
          this.reactionUser = {
            ...this.reactionUser,
            [reactionType]: (this.reactionUser[reactionType] || []).concat(data)
          };
        } else {
          this.reactionUser = { ...this.reactionUser, [type]: data };
        }

        this.listReaction.find(s => {
          if (type === s.reactionType) {
            s.pageSize = reactions.length;
          }
        });
      });
  }

  loadMoreReactions(type: string) {
    const container = this.scrollContainer.nativeElement;
    if (container) {
      const scrollHeight = container.scrollHeight;
      const offsetHeight = container.offsetHeight;
      const scrollTop = container.scrollTop;
      let pageSize = this.listReaction.find(s => type === s.reactionType)?.pageSize;

      if (scrollTop + offsetHeight >= scrollHeight - 10) {
        if (pageSize === PAGE_SIZE_DEFAULT) {
          this.listReaction.find(s => {
            if (type === s.reactionType) {
              s.pageNum++;
            }
          });
          this.getReactionsByEntityId(this.activeItemTabMenu.id);
        }
      }
    }
  }

  checkExistKey(key: string) {
    return this.reactionUser?.hasOwnProperty(key);
  }

  closePopupReaction() {
    this.visibleChange.emit(false);
  }

  showDialog() {
    if (!this.reactionUser) {
      this.getReactionsByEntityId();
    }
  }

  handleUpdateStatusFriend(FriendUpdated: any, keyObject: string, index: number) {
    const arrUpdate = [...this.reactionUser[keyObject]];
    arrUpdate[index].created_by = FriendUpdated;
  }
}
