import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedsEngineService {
  private api: ApiClient;
  constructor(private httpClient: HttpClient) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  getFeedsEngine(pageNum?: number, pageSize?: number, isVideo?: boolean) {
    return this.api.feedsEngine.getFeedsEngine(pageNum, pageSize, isVideo);
  }

  postInteractionHub(params: any) {
    return this.api.feedsEngine.postInteractionHub(params);
  }

  adsEnginePanel(pageNum: number, size: number, isPanel: boolean) {
    return this.api.feedsEngine.adsEnginePanel(pageNum, size, isPanel);
  }

  adsEngineSponsored() {
    return this.api.feedsEngine.adsEngineSponsored();
  }

  getAdsBanner(code: string, country: string) {
    return this.api.feedsEngine.getAdsBanner(code, country);
  }
}
