import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  ViewContainerRef
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CustomVideoComponent } from './custom-video.component';

@Injectable()
export class CustomVideoService {
  listComponentRef: Map<string, ComponentRef<CustomVideoComponent>> = new Map<
    string,
    ComponentRef<CustomVideoComponent>
  >();

  componentFactory: ComponentFactory<CustomVideoComponent>;
  listPreviewViewContainerRef: Map<string, ViewContainerRef> = new Map<string, ViewContainerRef>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router
  ) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomVideoComponent);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.listComponentRef.forEach((value, key) => {
          value.destroy();
        });
        this.listComponentRef = new Map<string, ComponentRef<CustomVideoComponent>>();
        this.listPreviewViewContainerRef = new Map<string, ViewContainerRef>();
      }
    });
  }

  getComponentRef(original: string) {
    return this.listComponentRef.get(original);
  }

  attach(viewContainerRef: ViewContainerRef, inputs?: any) {
    const itemOriginal = inputs?.item?.original;
    let componentRef = this.getComponentRef(itemOriginal);

    if (!componentRef || componentRef.hostView.destroyed) {
      componentRef = this.createAndInitializeComponent(inputs, itemOriginal);
      this.listComponentRef.set(itemOriginal, componentRef);
      this.listPreviewViewContainerRef.set(itemOriginal, viewContainerRef);
    }
    viewContainerRef.insert(componentRef.hostView);
  }

  reAttach() {
    this.listPreviewViewContainerRef.forEach((previewViewContainerRef, original) => {
      const componentRef = this.getComponentRef(original);
      if (componentRef) {
        previewViewContainerRef.insert(componentRef.hostView);
      }
    });
  }

  createAndInitializeComponent(inputs: any, itemOriginal: any): ComponentRef<CustomVideoComponent> {
    const componentRef = this.componentFactory.create(this.injector);
    this.listComponentRef.set(itemOriginal, componentRef);

    const propertiesToAssign = [
      'isPreview',
      'item',
      'isCover',
      'isClickedPlay',
      'isAutoPlay',
      'isPostView',
      'isShowScaleIcon',
      'collectVideo',
      'scaleVideo'
    ];
    propertiesToAssign.forEach(property => {
      (componentRef.instance as any)[property] = inputs[property];
    });

    return componentRef;
  }

  detach(viewContainerRef: ViewContainerRef, original: string) {
    const componentRef = this.getComponentRef(original);
    componentRef?.location.nativeElement.querySelector('video').pause();
    if (componentRef) {
      viewContainerRef.detach(viewContainerRef.indexOf(componentRef.hostView));
      this.reAttach();
    }
  }

  updateProperties(original: string, key: string, value: any) {
    const componentRef = this.getComponentRef(original);
    if (componentRef) {
      componentRef?.setInput(key, value);
    }
  }
}
