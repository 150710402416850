import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@app/core/services/group.service';
import { ReportService } from '@app/core/services/report.service';
import { TranslationService } from '@app/core/services/translation.service';
import { CATEGORY_LIST } from '@app/lib/api/page/category-mapping';
import { ACTIONS, OBJECT_TYPE, REPORT_CONTENT } from '@app/shared';

export interface PostPendingProps {
  id: string;
  name: string;
  caption: string;
  avatar: string;
  images: string[];
  time: string;
  object_id: string;
}

export interface PostProps {
  memberName: string;
  memberAvatar: string;
  activeTime: number;
  shortenedCaption: string;
  caption: string;
  images: string[];
  comment: number;
  share: number;
  like: number;
}

interface Category {
  id: string;
  name: string;
  translatedName?: string;
}
export interface Status {
  name: String;
  value: string;
}
interface ParamsSearch {
  text?: string;
  fromDate?: string;
  toDate?: string;
}
@Component({
  selector: 'report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss']
})
export class ReportContentComponent implements OnInit {
  @Input() postPendingList: PostPendingProps[];
  @Input() dataReportContent: any[];
  @Input() fanpageId: string | null;
  @Input() isLoadingReportPost: boolean;
  @Output() onAction = new EventEmitter();
  @Output() approveAll = new EventEmitter();
  @Output() declineAll = new EventEmitter();
  @Output() reportContent = new EventEmitter();
  @Output() searchValue = new EventEmitter();
  @ViewChild('overlayElement', { static: false }) overlayElement: ElementRef;
  type: string;
  captionLength: number = 50;
  limit = 4;
  checked = false;
  value = '';
  selectedPost = 0;
  postItemCheck: boolean | undefined = false;
  listPostSelected: PostPendingProps[] = [];
  dialogVisible: boolean;
  text: string;
  isShowApproveDialog: boolean = false;
  isShowRejectDialog: boolean = false;
  radioReportContent: string;
  textareaValue: string = '';
  textareaValueReject: string = '';
  isTextareaEmpty: boolean = true;
  isTextareaEmptyReject: boolean = true;
  idPost: string | null;
  isApproveAll: boolean = false;
  isDeclineAll: boolean = false;
  isShowQrOverlay: boolean = false;
  selectedCategory: Category | null = null;
  categoryList: Category[] = [];
  status: Status[] = [];
  selectedStatus: Status | null = null;
  isApplyButtonEnabled: boolean = false;
  originalTime: string;
  currentDate = new Date();
  groupId: string | null;
  rangeDates: Date[] | undefined;
  formattedStartDate: string | null = null;
  formattedEndDate: string | null = null;
  valueSearchDescription: string;

  constructor(
    private groupService: GroupService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private TranslationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });
    this.reportService.getReportCategories().subscribe((res: any) => {
      this.categoryList = res.data;
      this.translateCategories();
    });
    this.onLanguageChanged();

    this.getGroupReportContentPost();
  }

  handleClosModal() {
    this.textareaValue = '';
    this.textareaValueReject = '';
    this.isTextareaEmptyReject = true;
    this.isTextareaEmpty = true;
    this.radioReportContent = '';
  }

  updateApplyButtonState() {
    this.isApplyButtonEnabled = !(this.selectedStatus?.name === '' && this.selectedCategory?.name === undefined);
  }

  setTruncatedCaption(caption: string): string {
    const words = caption.split(' ');
    if (words.length > this.captionLength) {
      return words.slice(0, this.captionLength).join(' ') + '...';
    } else {
      return caption;
    }
  }

  handleSearchPendingReportPost() {
    this.searchValue.emit(this.value);
  }
  DeclineAll() {
    this.isDeclineAll = true;
    this.isShowRejectDialog = true;
  }
  ApproveAll() {
    this.isApproveAll = true;
    this.isShowApproveDialog = true;
  }
  onSelectedPost(status: { checked: boolean; post: PostPendingProps }) {
    if (status.checked) {
      this.selectedPost += 1;
      this.listPostSelected.push(status.post);
      this.postItemCheck = undefined;
    } else {
      if (this.selectedPost != 0) this.selectedPost -= 1;
      this.listPostSelected = this.listPostSelected.filter(item => item.id !== status.post.id);
    }
    if (this.selectedPost === 0) {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }
  selectedAllChange(checked: boolean) {
    if (checked) {
      this.postItemCheck = true;
      const data = this.dataReportContent;
      this.listPostSelected = data;
      this.selectedPost = data.length;
    } else {
      this.postItemCheck = false;
      this.selectedPost = 0;
      this.listPostSelected = [];
    }
  }
  onHide() {
    this.dialogVisible = false;
  }
  confirmApprove() {
    this.dialogVisible = false;
    if (this.type === ACTIONS.Decline) {
      this.declineAll.emit({ selected: 'declineAll', listPost: this.listPostSelected });
    } else if (this.type === ACTIONS.Approve) {
      this.approveAll.emit({ selected: 'approveAll', listPost: this.listPostSelected });
    }
    this.checked = false;
    this.selectedPost = 0;
    this.postItemCheck = false;
  }

  handleOnSelect(action: { selected: string; listPost: PostPendingProps[]; id: string }) {
    this.idPost = action.id;
    if (action.selected === ACTIONS.approval) {
      this.isShowApproveDialog = true;
    } else if (action.selected === ACTIONS.decline) {
      this.isShowRejectDialog = true;
    }
  }

  onInputChange() {
    this.isTextareaEmpty = this.textareaValue.trim() === '';
  }

  onInputChangeReject() {
    this.isTextareaEmptyReject = this.textareaValueReject.trim() === '';
  }

  handleApproveReportContent() {
    const postIds = this.isApproveAll ? this.listPostSelected.map(item => item.id) : [this.idPost];

    const param = {
      censorship_reason: this.textareaValue,
      report_history_id: postIds,
      category_type_admin: [],
      status: REPORT_CONTENT.reported_delete
    };

    this.reportContent.emit(param);
    this.isApproveAll = false;
    this.isShowApproveDialog = false;
  }

  handleRejectContent() {
    const postIds = this.isDeclineAll ? this.listPostSelected.map(item => item.id) : [this.idPost];

    const param = {
      censorship_reason: this.textareaValueReject,
      report_history_id: postIds,
      category_type_admin: [],
      status: REPORT_CONTENT.reject
    };

    this.reportContent.emit(param);
    this.isDeclineAll = false;
    this.isShowRejectDialog = false;
  }

  showOverlay() {
    this.isShowQrOverlay = !this.isShowQrOverlay;
  }

  handleApplyFilter() {
    if (this.groupId && this.isApplyButtonEnabled) {
      let params: any = {
        categories: Array.isArray(this.selectedCategory)
          ? this.selectedCategory.map((item: { key: string }) => item.key)
          : [],
        status: Array.isArray(this.selectedStatus)
          ? this.selectedStatus.map((item: { value: any }) => item.value)
          : ['PENDING'],
        objectType: OBJECT_TYPE.post,
        toDate: this.formattedEndDate,
        fromDate: this.formattedStartDate,
        objectParentId: this.groupId
      };

      this.groupService.PostFilterGroupReportCategories(params).subscribe((res: any) => {
        this.dataReportContent = res?.data;
        this.isShowQrOverlay = false;
      });
    }
  }

  handleResetFilter() {
    this.selectedCategory = null;
    this.selectedStatus = null;
    this.isApplyButtonEnabled = false;
    if (this.groupId) {
      this.groupService.getGroupReportContentPost(this.groupId).subscribe((res: any) => {
        this.dataReportContent = res.data;
      });
    }
    this.rangeDates = undefined;
  }
  onDateChange(event: any) {
    if (event && event.length === 2) {
      const [startDate, endDate] = event;
      this.formattedStartDate = startDate;
      this.formattedEndDate = endDate;

      if (this.formattedStartDate && this.formattedEndDate) {
        const params: any = {
          fromDate: this.formattedStartDate,
          toDate: this.formattedEndDate
        };
        if (this.valueSearchDescription) {
          params.text = this.valueSearchDescription;
        }
      }
    }
  }

  translateCategories(): void {
    this.categoryList = this.categoryList.map(category => {
      const translationKey = CATEGORY_LIST.get(category.id);
      if (translationKey) {
        category.translatedName = this.TranslationService.getTranslation(translationKey);
      }
      return category;
    });
    this.status = [
      { name: 'GROUP.SETTING.APPROVE', value: 'DELETE' },
      { name: 'GROUP.SETTING.PENDING', value: 'PENDING' },
      { name: 'GROUP.SETTING.REJECT', value: 'REJECT' }
    ];
    this.status = this.status.map((item: any) => {
      return { ...item, name: this.TranslationService.getTranslation(item.name || '') };
    });
  }

  onLanguageChanged() {
    this.TranslationService.language$.subscribe(() => {
      this.translateCategories();
    });
  }

  getGroupReportContentPost() {
    if (this.groupId) {
      this.groupService.getGroupReportContentPost(this.groupId).subscribe((res: any) => {
        this.dataReportContent = res.data;
      });
    }
  }
}
