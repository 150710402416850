import { UserInfo } from './user';

export interface Media {
  image_url: string;
  video_url: string;
  background_image: string;
}

export interface Story {
  id: string;
  story_privacy: string;
  enable_react?: boolean;
  enable_comment?: boolean;
  enable_message?: boolean;
  total_viewers?: number;
  total_comment?: number;
  user_id: string;
  story_media: Media;
  story_type: string;
  user_object: UserInfo;
  created_at: string;
}

export const StoryAction = {
  report: 'REPORT',
  copy: 'COPY',
  download: 'DOWNLOAD',
  delete: 'DELETE'
}

export const UsersStoryOptions = [
  {
    icon: 'sctr-icon-download-01',
    label: 'STORY.SAVE_THIS_STORY',
    description: null,
    type: StoryAction.download
  },
  {
    icon: 'sctr-icon-alert-square',
    label: 'STORY.REPORT_STORY',
    description: null,
    type: StoryAction.report
  },
  {
    icon: 'sctr-icon-link-03',
    label: 'STORY.COPY_LINK',
    description: 'STORY.STORY_VISIBLE_24_HOURS',
    type: StoryAction.copy
  }
];

export const MyStoryOptions = [
  {
    icon: 'sctr-icon-download-01',
    label: 'STORY.SAVE_THIS_STORY',
    description: null,
    type: StoryAction.download
  },
  {
    icon: 'sctr-icon-link-03',
    label: 'STORY.COPY_LINK',
    description: 'STORY.STORY_VISIBLE_24_HOURS',
    type: StoryAction.copy
  },
  {
    icon: 'sctr-icon-trash-01',
    label: 'STORY.DELETE_STORY',
    description: null,
    type: StoryAction.delete
  }
];
