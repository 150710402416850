import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiClient } from "@app/lib/api/api-client";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getExchangeRateFromUSD(toCurrency: string): Observable<any> {
    return this.api.billing.getExchangeRateFromUSD(toCurrency);
  }
}