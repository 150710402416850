<p-dialog
  [(visible)]="visible"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'w-[588px] h-[476px] py-6 gap-4 bg-palette-base-white'"
  [modal]="true"
  [dismissableMask]="true"
  (onHide)="closePopupReaction()"
  (onShow)="showDialog()">
  <ng-template pTemplate="header">
    <div class="flex justify-between items-center w-full">
      <p-tabMenu
        [model]="filterItemToShow()"
        [activeItem]="activeItemTabMenu"
        (activeItemChange)="onActiveItemChange($event)"
        class="w-full">
        <ng-template pTemplate="item" let-item>
          <img src="{{ item.icon }}" class="w-5 h-5" *ngIf="item.icon; else All" />
          <ng-template #All>
            <span class="text-sm font-semibold">{{ 'POST.ALL' | translate }}</span>
          </ng-template>
          <p-chip
            [label]="item.label"
            [styleClass]="'rounded-full px-2 bg-palette-gray-100 text-xs font-medium'"></p-chip>
        </ng-template>
      </p-tabMenu>
    </div>
  </ng-template>
  <div class="text-palette-gray-700 font-semibold text-sm">{{ activeItemTabMenu.title || '' }}</div>
  <div
    class="flex flex-col gap-4 w-full pl-1 pt-1 h-[368px] overflow-auto overflow-x-hidden"
    #scrollContainer
    (scroll)="loadMoreReactions(activeItemTabMenu.id || '')">
    <ng-container *ngFor="let item of reactionUser | keyvalue">
      <ng-container *ngIf="item.key === activeItemTabMenu.id">
        <div class="flex justify-between last:pb-1" *ngFor="let user of item.value; let index = index">
          <div class="flex items-center">
            <div class="relative">
              <avatar [isSmallThumbnail]="false" [avatarSize]="32" [userInfo]="user.created_by"></avatar>
              <div class="absolute top-5 right-[-3px]">
                <img [src]="user.reaction_type ? getIconReaction(user.reaction_type) : ''" class="w-3 h-3" />
              </div>
            </div>
            <span class="text-palette-gray-700 text-sm font-medium ml-2 px-1 w-[200px] truncate">{{
              user.full_name
            }}</span>
          </div>
          <friend-action-button
            *ngIf="user.created_by?.relationship_status"
            [isFriendLimit]="(isFriendLimit$ | async)!"
            [item]="user.created_by"
            (friendAction)="handleUpdateStatusFriend($event, item.key, index)"></friend-action-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</p-dialog>
