import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '@app/core/services/login.service';
import { environment } from '@env/environment';

@Component({
  selector: 'authen-container',
  templateUrl: './authen-container.component.html',
  styleUrls: ['./authen-container.component.scss']
})
export class AuthenContainerComponent implements OnInit, OnDestroy {
  constructor(private readonly loginService: LoginService) {}
  imageUrl: any;
  appName = environment.APP_NAME;
  changeImageInterval: NodeJS.Timeout;

  ngOnInit(): void {
    this.changeImage();
    this.changeImageInterval = setInterval(() => this.changeImage(), 5000);
  }

  ngOnDestroy() {
    clearInterval(this.changeImageInterval);
  }

  changeImage(): void {
    this.imageUrl = this.loginService.getImageAndText();
  }
}
