import { Directive, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { checkUrl, FILE_TYPE_URL } from '../models/post';

@Directive({
  selector: '[seoInfo]'
})
export class SeoInfoDirective {
  @Input() seoData: any;

  constructor(private metaService: Meta) {}

  ngOnInit(): void {
    if (this.seoData.content && this.seoData.content !== '') {
      this.metaService.updateTag({ name: 'title', content: this.getFirstString(this.seoData.content) });
      this.metaService.updateTag({ name: 'description', content: this.getFirstString(this.seoData.content) });
      this.metaService.updateTag({ property: 'og:description', content: this.getFirstString(this.seoData.content)});
      this.metaService.updateTag({ name: 'twitter:description', content: this.getFirstString(this.seoData.content)});
    }
    if (this.seoData.media_urls?.media.length) {
      this.metaService.updateTag({ name: 'image', content: this.getImageUrl(this.seoData.media_urls.media[0].thumbnail) });
      this.metaService.updateTag({ property: 'og:image', content: this.getImageUrl(this.seoData.media_urls.media[0].thumbnail) });
      this.metaService.updateTag({ property: 'og:image:secure_url', content: this.getImageUrl(this.seoData.media_urls.media[0].thumbnail) });
      this.metaService.updateTag({ name: 'twitter:image', content: this.getImageUrl(this.seoData.media_urls.media[0].thumbnail) });
      this.metaService.updateTag({ property: 'og:image:type', content: 'image/png' });
    }
  }

  getImageUrl(imgId: string) {
    return `${checkUrl(imgId, FILE_TYPE_URL.thumbnail)}.png`;
  }

  getFirstString(description: string): string {
    if (!description) return '';
    if (description.split(' ').length > 10) {
      const wordsArray = description.split(/\s+/);  // Regular expression for splitting by spaces
      return wordsArray.slice(0, 10).join(' ');
    }
    return description;
  }
}
