export const CATEGORY_LIST = new Map<string, string>([
  ['cd2b4aa1-0b2d-4ce1-889d-458dfc6c7721', 'SWIMMING_POOL_AND_HOT_TUB_SERVICE'],
  ['a156c744-b2eb-43ed-88b6-faf0987ea033', 'EVENT_PLANNER'],
  ['ae47cf41-afd8-49db-9baa-f77f9706b274', 'ROBOTICS_COMPANY'],
  ['f6d63e27-1c0f-4268-8f92-7c7730d1ce0f', 'ART_RESTORATION_SERVICE'],
  ['a4f92e2a-84f8-4f09-a796-c878c6e68963', 'HONG_KONG_RESTAURANT'],
  ['4b1dfdfa-7d69-4649-8dd4-af003ec57d98', 'SARDINIAN_RESTAURANT'],
  ['d4dbb9cc-feee-4f0d-9d92-a08ab2c342cf', 'TOUR_AGENCY'],
  ['1ddabcb9-b346-49d2-8457-ca54930c40d4', 'HAWAIIAN_RESTAURANT'],
  ['0713c61d-081b-40af-b24e-45e4a758190b', 'APPAREL_AND_CLOTHING'],
  ['3cf72127-89d2-417e-ace9-18817062eb58', 'AFGHAN_RESTAURANT'],
  ['df278ace-ee39-41da-a691-8f45c2699830', 'URUGUAYAN_RESTAURANT'],
  ['e65d41db-eec5-49f8-aa5c-e8df5bf89881', 'CEMETERY'],
  ['069c98c0-91b5-4bcd-b040-dbeb3f9c032c', 'IMPERIAL_RESTAURANT'],
  ['d4ceec95-c9b3-42d4-b729-c7223ddaf632', 'SCIENCE_WEBSITE'],
  ['5fe09c25-c958-4d87-8dcf-6dc3534426ec', 'WEDDING_VENUE'],
  ['54aa7c7a-287d-4d97-ae11-44bdc03340d5', 'SELF_STORAGE_FACILITY'],
  ['2b67e3f8-4fe7-4ab4-b39a-cf6ab49579f4', 'CARPET_CLEANER'],
  ['75f0829e-7d45-4c75-8913-ee97a29eaed2', 'OCCUPATIONAL_THERAPIST'],
  ['16c5e070-a6ff-4e00-a1cc-cddec70d51d4', 'LOUNGE'],
  ['3849d40c-ce55-4532-9225-61045a44582e', 'RV_REPAIR_SHOP'],
  ['ad87514c-0b82-4748-a494-cb4a2fb8977b', 'DANCE_STUDIO'],
  ['967ac76f-a4a6-47e1-9454-316c91c33cc4', 'SYRIAN_RESTAURANT'],
  ['2e5cb723-561d-4479-a819-6b8fb96ebb57', 'ANIMATION_STUDIO'],
  ['518e4ef7-81f8-4a22-b292-09b9052c794a', 'CHARTER_BUS_SERVICE'],
  ['f09b8e99-519a-4b46-b15f-148ec5e9669c', 'HALAL_RESTAURANT'],
  ['c5924350-4803-4171-bfc0-2bff0aca18b8', 'BENGALI_BANGLADESHI_RESTAURANT'],
  ['9430649e-cd58-4e32-b89d-66ce988a43c7', 'COMMERCIAL_BANK'],
  ['bd7d7b7b-ddbb-497c-a242-8e25fe9df387', 'WASTE_MANAGEMENT_COMPANY'],
  ['e1eb93a0-cfc8-4837-8baf-0f76772baab0', 'HOME_IMPROVEMENT'],
  ['046572c2-9ec2-4029-b148-faa961cdf139', 'TRAILER_RENTAL'],
  ['eebe7d02-72ca-471a-969f-7c8b3ec54f4f', 'ACUPUNCTURIST'],
  ['a6190673-e423-4bb0-854f-64a0fa4dd496', 'ELEVATOR_SERVICE'],
  ['9d138f64-1596-4b3d-b7ff-e93a71772b45', 'ENDODONTIST'],
  ['dc8af00c-e57a-486c-a3c6-1b34fb2ee2d8', 'PET_SITTER'],
  ['e6153788-5b34-4386-a71a-2812153f3cd7', 'BALLROOM'],
  ['329db799-f697-4ed8-8714-855ec6842085', 'ASTROLOGIST_AND_PSYCHIC'],
  ['14c7b8e0-baf1-48c0-86c6-eab6911f9b9d', 'VITAMIN_SUPPLEMENT_SHOP'],
  ['06ff74cd-b9cd-4e05-8cc4-9d796d510a34', 'HOTEL'],
  ['e655cfd8-bf28-4283-b157-2998149671ef', 'PIER'],
  ['f6127ae1-f5a3-4acb-a478-f8a4daf28353', 'TV_MOVIE_AWARD'],
  ['400a9ed2-fb56-461b-a6e0-04c792444917', 'FENCING_CLUB'],
  ['85da2e47-1ba6-418a-8fa7-22888518078e', 'GOAN_RESTAURANT'],
  ['e2854e6c-235f-4e4a-960e-d1f2ec79e046', 'FINANCIAL_AID_SERVICE'],
  ['aeb24012-a7b9-46c1-93c7-2825ed2430af', 'FAIRGROUND'],
  ['ebfe7923-d1ed-40d2-af0f-b63c7ee80178', 'KARNATAKA_RESTAURANT'],
  ['fd471a12-839b-4e67-9b26-add540ca2f0b', 'LOCKSMITH'],
  ['e976f3e7-ab8b-4728-8364-4969a986ccef', 'TAPAS_BAR_AND_RESTAURANT'],
  ['87491bdc-6490-44a6-b1b3-f9032765c681', 'HIGHWAY'],
  ['06faf3f5-7900-4fa6-9469-6fe5f9fb87d0', 'MOBILE_HOME_DEALER'],
  ['43907c80-b9a1-473e-b746-1948b7c8d869', 'TAXIDERMIST'],
  ['1c87c737-6b2b-4b85-b313-e1d07bc138dd', 'BUS_STATION'],
  ['b4699386-0fe8-4dfb-9b78-6a363f0d0481', 'OCEAN'],
  ['4723b0b4-1f6a-4016-9b35-266bd52e80fb', 'BAND'],
  ['1cfa2cf1-3b1d-4221-b51b-bb302da5b575', 'AUTOMOTIVE_CONSULTANT'],
  ['f6b7b2e0-745e-459f-abc9-38cb18da664a', 'SOFTWARE_COMPANY'],
  ['e0fa6bd2-ed82-42ec-b91b-6dd48e7ee8dc', 'PUBLIC_SQUARE_PLAZA'],
  ['86a6d6a0-9427-4fe1-89a8-dedea6ff9050', 'TAX_LAWYER'],
  ['c7fc069c-6276-446b-b46d-f8bea07d1c16', 'MODERN_EUROPEAN_RESTAURANT'],
  ['bb140335-f1f8-4f0a-83eb-742109ce9325', 'JAMAICAN_RESTAURANT'],
  ['2e789938-2ffe-4774-836f-b31f05227dfe', 'EDUCATIONAL_SUPPLY_STORE'],
  ['927b01ea-4e7d-46a7-abb1-e4cf7cbc6039', 'GAMING_VIDEO_CREATOR'],
  ['1a7a5a60-b07b-4d61-9a61-e1fcc2d5fd48', 'COSMETIC_DENTIST'],
  ['1a00621a-c296-455b-a6df-6a6ba89be439', 'HUBEI_RESTAURANT'],
  ['e22b2973-b5e6-41b5-8024-36f5968f4d68', 'ETHNIC_GROCERY_STORE'],
  ['1d4fc425-1b6d-4a8e-a182-fc3e8b80785f', 'FOOTWEAR_STORE'],
  ['7bb0b3be-c650-4a9b-b0b5-cc051c3e468d', 'ANGLICAN_CHURCH'],
  ['12487115-14fe-4f02-9568-7a262c7766b2', 'BUBBLE_TEA_SHOP'],
  ['822ed163-eae2-492b-bea0-6b8a44fdd694', 'PERIODONTIST'],
  ['92cdb7ff-1306-4327-9e08-4db26b6b4d1f', 'TENNIS_COURT'],
  ['601841e9-fb33-4116-8791-e25f7194008e', 'ZHEJIANG_RESTAURANT'],
  ['d0ad94fb-a55b-4ccc-90c8-3269fc9eaee7', 'KENNEL'],
  ['2192d8c3-12ec-49c9-bf64-868edd612e0c', 'EMERGENCY_RESCUE_SERVICE'],
  ['4668a686-3535-47d3-beb6-ea503a897935', 'PAINTBALL_CENTER'],
  ['133d73a4-8940-480b-b3ee-bf45bcfb06f7', 'FOOD_CONSULTANT'],
  ['9b30f980-8de4-45e8-9497-622ccd852328', 'MOTORSPORTS_STORE'],
  ['84d36517-b737-4fd9-9ca5-bf6ba4312b2b', 'SURGICAL_CENTER'],
  ['2d081442-989f-47a7-b2f4-a7caae254089', 'SEPTIC_TANK_SERVICE'],
  ['a9531878-2e78-4651-82a7-d73c66f4544a', 'IN_HOME_SERVICE'],
  ['b6294e2b-8575-4a84-a539-497e267a39e7', 'HUAIYANG_RESTAURANT'],
  ['f9ceb013-6853-435a-870a-cbae9df03fc2', 'GIFT_SHOP'],
  ['3326e814-d695-4ab3-b651-c4aabe1f8973', 'FJORD_LOCH'],
  ['518edf27-3b87-493a-a3cd-a4ce3175a1d6', 'STRUCTURAL_ENGINEER'],
  ['dfe3b49a-ce60-43c4-a62b-e2dc5ef41a17', 'VINYL_SIDING_COMPANY'],
  ['2870639d-491a-4849-9edb-52f0955648f6', 'BRAND'],
  ['1b66c3da-88d7-4595-9c16-df674812d912', 'DESIGNER'],
  ['7ac3fdde-24b0-415d-8cac-a7ed422a9627', 'PODCAST'],
  ['94ff6781-1e16-4798-a7ad-be01736afedb', 'POND'],
  ['0d7e00a7-baf8-4496-882f-1ee51f044583', 'TOWING_SERVICE'],
  ['fa0cf477-4b80-461d-bad3-ddca53e988b2', 'CHURCH_OF_GOD'],
  ['47bedc2b-3d7d-4b91-babb-02af08fd53f9', 'SCUBA_DIVING_CENTER'],
  ['71f716a5-d90d-4523-9db7-aa0b58003ce4', 'GROCERY_STORE'],
  ['24e7a213-9f36-4b3a-9943-a428cf625603', 'CANOE_AND_KAYAK_RENTAL'],
  ['3b94ebfd-6b3e-4312-87fb-1ad4312f0e8a', 'MISSION'],
  ['c5ad32f5-ef98-44a1-b1f9-9b2a71fd266b', 'EDUCATIONAL_RESEARCH_CENTER'],
  ['ef918b1b-3fda-4f13-8de0-520641c6c8fd', 'OKONOMIYAKI_RESTAURANT'],
  ['7a2954b4-91c9-431b-be98-e537a91f41d7', 'MOSQUE'],
  ['df006da6-a76a-4624-a4e0-4024758d6fb4', 'SEWING_AND_ALTERATIONS'],
  ['33040bb9-2706-485e-9b36-251b27600bda', 'SMOOTHIE_AND_JUICE_BAR'],
  ['46d67689-b0ed-4222-958f-b69a589c01f2', 'ENTREPRENEUR'],
  ['341a2061-1292-4670-a39d-525f357e2b77', 'DESIGN_AND_FASHION'],
  ['92032c34-90b9-4b84-86ef-f18850dbc3e6', 'GREEK_RESTAURANT'],
  ['07321ca8-18bc-4364-8e25-af4b0ea43d04', 'CAJUN_AND_CREOLE_RESTAURANT'],
  ['949c97b4-466e-4dfe-b3a6-2a8e44218df8', 'REAL_ESTATE_TITLE_AND_DEVELOPMENT'],
  ['c8381c2c-59d9-43f9-a90c-15dc0aa8ab5b', 'GASTROENTEROLOGIST'],
  ['d879ed86-9c9b-4c8f-9ee8-e18877e4cbe4', 'LIGHTING_STORE'],
  ['bf18a7f1-8644-4a6b-b165-71c8a4ac2634', 'PLAYGROUND'],
  ['04a6900c-6cbd-4040-85e0-ddca86e9d1cc', 'HOUSING_ASSISTANCE_SERVICE'],
  ['728e7c19-8cf6-403b-bc31-0e93fd1277c0', 'ESPORTS_LEAGUE'],
  ['8f9f024c-e1fa-4eac-a998-44ed7cd0db01', 'VOLLEYBALL_COURT'],
  ['5da6b83d-1619-430e-8ef5-f0aa0cac5164', 'MOBILE_HOME_PARK'],
  ['276a4467-c2db-4d71-8ec1-8532dda53be5', 'ENVIRONMENTAL_CONSULTANT'],
  ['ea4ff343-6240-43c3-9da7-8d0a8409e0d6', 'MEDICAL_LAWYER'],
  ['ce5260e5-634a-498b-95f4-c6c02b2c04dd', 'KAISEKI_RESTAURANT'],
  ['b9e0e6be-5e67-4c1d-9d02-f3afd48438c2', 'CAVE'],
  ['182374c6-31af-49dd-9a28-2119f2c49032', 'BAY'],
  ['670e0d67-dbe7-4f4e-8e9d-4f8cc227e379', 'CRUISE_LINE'],
  ['f4f683e2-46be-4cdb-b547-5ab970496806', 'REAL_ESTATE_LAWYER'],
  ['10881273-760b-40f0-8627-5d9bd1f9c7a0', 'ARTS_AND_CRAFTS_STORE'],
  ['a879324f-acb4-44b2-bb20-e2aa00711994', 'SOFTWARE'],
  ['bc7f51dc-4298-40e5-89fd-cca495265876', 'SPORTS_LEAGUE'],
  ['7d0b2ad1-e03d-4a8a-b707-e362796179ca', 'INDONESIAN_RESTAURANT'],
  ['26221f1c-4c65-4ea7-82e8-3c24e8de8f60', 'ROCK_CLIMBING_GYM'],
  ['3f4f3ff6-4c51-473a-9ae6-a5a8a29f2eb3', 'JEWELRY_WATCHES'],
  ['8464a08c-3620-486d-9f92-f9d03d72a4e1', 'MOVIE'],
  ['3ac24854-4700-428d-8ea5-eaa6f1d4fb5a', 'TELECOMMUNICATION_COMPANY'],
  ['6a725bf4-4627-40a9-a230-a420839d9083', 'PET_SUPPLIES'],
  ['e7bc9f0a-ee10-4a47-8c67-60b43eff5427', 'PROCTOLOGIST'],
  ['f400e634-a6ec-4a21-97d5-5cc1e27442cb', 'CARS'],
  ['00bd69eb-873a-4ed9-a5bf-9cc8fbcf578d', 'OSTEOPATHIC_DOCTOR'],
  ['b9403f74-d205-4dba-acdd-1d0eb30e3233', 'PLASTIC_SURGEON'],
  ['775f4037-b8bc-42e2-a321-2a8e8131ae99', 'RESTAURANT_SUPPLY_STORE'],
  ['2dd0a1bb-c712-4d49-9af5-a679181c9cf5', 'SOBA_RESTAURANT'],
  ['cb081ddc-61f0-494d-bde2-2278ab80b72f', 'AUTHOR'],
  ['8291a601-834a-4949-890c-32a5786aef5f', 'NEPHROLOGIST'],
  ['cffca4f8-c710-49b7-87cc-2924df6624a8', 'INTERNET_SERVICE_PROVIDER'],
  ['b6aaed9f-c50c-46f1-82b4-5ed3fbb9d3b8', 'AUTOMOTIVE_REPAIR_SHOP'],
  ['a3e7fdef-02de-4422-ac24-c82fafe2b62d', 'EQUESTRIAN_CENTER'],
  ['f40c97fe-b839-4aaf-98f7-7af09ac1b558', 'FIELD'],
  ['95645715-ff98-49bb-8d1f-9798726c2089', 'PUBLIC_UTILITY_COMPANY'],
  ['315e3106-53e7-4b64-8da0-7ebfcdbb4080', 'FOOD_TRUCK'],
  ['30724bb1-73b9-416d-b96c-50aaa1ba3d02', 'REAL_ESTATE_SERVICE'],
  ['17130376-9663-421a-9178-9f1bb4daf943', 'THAI_RESTAURANT'],
  ['f8ffac9d-1588-4605-9321-a8b303209096', 'RECREATION_SPOT'],
  ['2ebffdd7-fdb2-4091-a275-5c498f11ba20', 'SANDWICH_SHOP'],
  ['b1239a41-1b3a-41e4-8929-4153092fa1b9', 'AUCTION_HOUSE'],
  ['26cf6914-6822-46bc-88fd-f5a814175f85', 'TRANSIT_SYSTEM'],
  ['09e905cf-399b-44b0-a5cb-a21e62427f86', 'INSURANCE_COMPANY'],
  ['67ea9919-2820-4123-aaf5-e761702deda5', 'SCHOOL_SPORTS_TEAM'],
  ['ef981dcb-05b2-4d2c-a45d-569cc1f77609', 'NEW_AMERICAN_RESTAURANT'],
  ['cd75e502-857c-433d-830a-c15544770352', 'NATIONAL_FOREST'],
  ['614924cb-57f2-4120-9740-4d7ffe98c866', 'CANTONESE_RESTAURANT'],
  ['6620f200-87b4-4ee3-b24f-896e2ab2a46c', 'GARAGE_DOOR_SERVICE'],
  ['f58d2c4f-c492-4a91-84a0-ac3205a0a2df', 'NAZARENE_CHURCH'],
  ['9884716e-c48e-4e86-9c07-c1722d72a894', 'BARBECUE_RESTAURANT'],
  ['81290857-1d48-4292-9c63-afb0654ff88e', 'SIGHTSEEING_TOUR_AGENCY'],
  ['1ba83a03-3d54-4c8f-8781-dd4568fab0ad', 'CASTLE'],
  ['a5322026-d833-4adb-91d3-c09bb73e163b', 'REAL_ESTATE_COMPANY'],
  ['1ae1b51e-155c-48b4-9a34-f430fee75d80', 'WEBSITE'],
  ['27ad4cd2-9e4e-48e5-a541-613120f23697', 'E_CIGARETTE_STORE'],
  ['5b29423a-6ea1-4e62-9a3c-cf058b42afd4', 'JANGUH_RESTAURANT'],
  ['d6751f0c-9bee-490c-aa50-fdb14ec19ab9', 'ART_TOUR_AGENCY'],
  ['e9a96b7a-b4fc-4619-8bb7-d682a5c0c6c9', 'AUTOMOTIVE_WINDOW_TINTING_SERVICE'],
  ['fad420e7-55f9-4f70-b41d-7d1892fb35ce', 'SPEAKEASY'],
  ['579ba3ba-8db9-4fe5-b6e6-79d01150bbd8', 'SRI_LANKAN_RESTAURANT'],
  ['15b5a61e-737f-45a2-8e50-65c3d149647b', 'ANIMAL_RESCUE_SERVICE'],
  ['ba6fca2b-a113-4075-b3ab-229f96c4dec0', 'RESIDENCE'],
  ['56f25315-34b6-4152-8e70-a20313bd2924', 'SYMPHONY'],
  ['bfce1077-bc1a-4f30-92ac-bbfc86b5f523', 'JUNKYARD'],
  ['159b4cc7-beff-4802-bc03-baef4fd141eb', 'MEDICAL_AND_HEALTH'],
  ['4b54e70c-917a-47d2-bc56-b62acdfaaafb', 'MINING_COMPANY'],
  ['b2b77ec0-4ed2-47da-8c93-330afae4cdbd', 'AUSTRALIAN_RESTAURANT'],
  ['5d5178e0-942e-4afc-8aa7-3ecda3eedca3', 'PRODUCT_SERVICE'],
  ['28134137-a7fb-4b12-8541-8eb06dcb03d7', 'BRAND_COMPANY_TYPE'],
  ['38dd2363-1622-4e87-ad7a-fb1b9c6134d2', 'MUSIC_LESSONS_AND_INSTRUCTION_SCHOOL'],
  ['384a33f4-43c4-4a03-a66a-65e02f7da1e9', 'REGIONAL_WEBSITE'],
  ['41de1d1e-91c5-44a0-a2b5-a1ff7fedc75a', 'TRAFFIC_SCHOOL'],
  ['d5a4e90b-7e2c-46a8-af29-ceff7bdf9d95', 'FAST_FOOD_RESTAURANT'],
  ['d06ce4d3-6f7c-410a-9ebb-94f10b2e8e8e', 'AVIONICS_SHOP'],
  ['5e9713b9-bc00-4cf2-bb23-ec06d16ccc83', 'GOLF_COURSE_AND_COUNTRY_CLUB'],
  ['3b13d026-d7e0-4644-86ad-3d090030ffb3', 'SURFING_SPOT'],
  ['a46eaadf-e54e-4477-8eae-0d05129fb4b3', 'CREDIT_COUNSELING_SERVICE'],
  ['1a7c3c05-72f7-4eee-af64-7d1657f1b584', 'CHIROPRACTOR'],
  ['dade2635-9fba-4451-97ba-120d021ef583', 'SOUTH_INDIAN_RESTAURANT'],
  ['069bf769-4752-4969-aa96-8adbaa9f6974', 'TILING_SERVICE'],
  ['7e8e8d0d-9947-4834-99df-192cf029804c', 'INSURANCE_AGENT'],
  ['de8b8970-7063-40df-9ad3-4676289ed088', 'JAIN_RESTAURANT'],
  ['5f439d65-ae71-47a3-98f0-d384a35f197e', 'AVIATION_SCHOOL'],
  ['5eefcba9-d294-4fa4-89d8-348b74be3076', 'DATING_SERVICE'],
  ['a56a1683-181b-4a0b-9007-5c22a908f338', 'MATERNITY_CLINIC'],
  ['e7849b6c-c270-4a25-a303-bdc6e317e297', 'JUST_FOR_FUN'],
  ['59c20d3e-8801-41de-b9e7-1c0e1bc35e4c', 'CUPCAKE_SHOP'],
  ['5d874a95-66a3-40c4-be5c-2d37cea69af5', 'SOUTH_TYROLEAN_RESTAURANT'],
  ['d6555800-5b8f-45f4-a3f1-9803b395c933', 'RENTAL_SHOP'],
  ['e821a816-9252-4aff-a315-7d950908c5e3', 'GAS_AND_CHEMICAL_SERVICE'],
  ['9099c877-cb0f-4057-b5f8-26d0e3afc542', 'MUGHALAI_RESTAURANT'],
  ['de422048-9be5-49ab-8ec7-9d8e88262412', 'CATERER'],
  ['7d4b92bd-8859-4209-aec8-24969d888a4f', 'EDITORIAL_OPINION'],
  ['b18c8f5a-3179-406e-9d0f-3aef5f6675b4', 'FINANCE'],
  ['1e639a7e-1932-457e-ac03-938e048ea12e', 'OIL_LUBE_AND_FILTER_SERVICE'],
  ['90ef191b-d658-4941-9604-7764f0e763e1', 'FAMILY_THERAPIST'],
  ['010136ff-dd8b-4db3-972b-ade60aac1431', 'DIALYSIS_CLINIC'],
  ['6906e026-ea1f-497b-82b9-251fb46bb462', 'TOURIST_INFORMATION_CENTER'],
  ['3e902205-7e3f-4a3e-8716-1e8c549cb9b1', 'REAL_ESTATE'],
  ['a5ad9b69-284d-4079-aae3-794d7c54ef44', 'BASEBALL_FIELD'],
  ['5731676a-4b64-44b4-8c43-098f4827cecf', 'FONDUE_RESTAURANT'],
  ['432ee232-0006-463a-a917-af5123ed59c3', 'DENTIST_AND_DENTAL_OFFICE'],
  ['3ce41bd0-8656-49c4-bed9-0aeb05c48d48', 'TEMPURA_RESTAURANT'],
  ['b8031fcc-ec0d-4393-9a10-9c9119030cd5', 'BASKETBALL_COURT'],
  ['bfbf627b-0ff1-4446-a5de-31d0612b1584', 'GRAPHIC_DESIGNER'],
  ['c8ae6044-fabf-4145-9882-9b4be44446d8', 'RODEO'],
  ['6cc072e4-60b7-4425-b284-cb69afa3e055', 'RECRUITER'],
  ['329acddb-c2b8-4d8a-875c-3b7c4b053e84', 'SCIENTIST'],
  ['99de2cf0-327a-470f-aa06-b804678fa890', 'BUNSIK_RESTAURANT'],
  ['18446035-f860-437b-8ef1-5aa03dfe14c6', 'BUS_LINE'],
  ['3a1139c0-3865-49f9-bf9f-936b7fbf2511', 'SHOE_REPAIR_SHOP'],
  ['90daba23-3816-4fbe-ab87-962695bfce46', 'METAL_PLATING_SERVICE_COMPANY'],
  ['5adf4c33-b653-4f6b-810a-4cba41b37771', 'SIKH_TEMPLE'],
  ['39f1ccce-bf0a-48dc-b8a2-6e0d57c44850', 'PARKING_GARAGE_LOT'],
  ['c66cdc53-73ee-4102-8868-f497cb2faaa3', 'BUSINESS_CENTER'],
  ['437d1864-ca27-4536-884b-dc72bd9b9496', 'BARTENDING_SERVICE'],
  ['7d6ca3df-830b-4b0a-b4ff-d4e690c93043', 'LIMO_SERVICE'],
  ['9c3c7d71-9070-4039-927b-2fac647489da', 'ALBUM'],
  ['443e94a1-aad5-41bc-accb-65f52018a354', 'ORTHOPEDIST'],
  ['ea65b2b3-df38-4394-bcc3-a3f5cf9b56fd', 'NURSING_SCHOOL'],
  ['8efed4e8-3c16-4abb-8db9-588679a13365', 'TV_SEASON'],
  ['84a6cc2e-8dc2-4bab-9e9f-c7688793a6f9', 'SUBCITY'],
  ['d010afc6-2c2f-46a2-8eb8-0e36c9d2294b', 'CARNIVAL_SUPPLY_STORE'],
  ['b7201eed-40b3-4139-9d6c-a12c8073fcc4', 'BOWLING_ALLEY'],
  ['c7e8bd79-3156-4631-8a26-e28b3ea16911', 'NAIL_SALON'],
  ['9ef5c5a2-5316-4a70-9e00-00a60763fa03', 'SPORTSWEAR_STORE'],
  ['9ea7c998-c9a7-4079-b8eb-70549fc06a4f', 'PEDIATRIC_DENTIST'],
  ['5ea1a472-73b0-411d-9aa6-49fc29213bd8', 'BLINDS_AND_CURTAINS_STORE'],
  ['43be8c59-2c61-48c8-98e6-e32c997c3a1f', 'LANDSCAPE_COMPANY'],
  ['bf536c22-ae95-4516-aea9-f8b9197d7db9', 'GAME_PUBLISHER'],
  ['87e38308-9b3f-4587-845a-9709c99e34b6', 'FITNESS_BOOT_CAMP'],
  ['bb762924-ee7f-4e98-aac6-6dbc04d61d77', 'DONBURI_RESTAURANT'],
  ['bbd2939c-1436-4d7c-994e-92b03c36bedc', 'ARBORETUM'],
  ['89b16c23-949c-4c5b-8008-c5a855bd2cb6', 'ANTIQUE_STORE'],
  ['a3284ef3-cece-4985-8050-15029067a268', 'TV_SHOW'],
  ['6b686e5f-c530-4f7c-988d-3774efcc69ad', 'DISC_GOLF_COURSE'],
  ['51d7c9b1-8c5d-4527-a6cd-1b8199e4b133', 'CONVENT_AND_MONASTERY'],
  ['315afb53-7941-4db5-940a-9f8ead9b7c7a', 'COMMUNITY_COLLEGE'],
  ['5ec09fa4-2a39-4322-9a52-65e15ad15420', 'FILIPINO_RESTAURANT'],
  ['7f0b8dcc-4ed0-44ab-83d0-9127326fa1b4', 'TREE_CUTTING_SERVICE'],
  ['ae5a701f-9e73-4042-b45e-e410073d1ce0', 'FURNITURE_STORE'],
  ['4d33bbf6-e1ab-405e-885b-2bf87c07c4bb', 'FASHION_MODEL'],
  ['36c36287-a9a0-4ce6-aba2-32f23c80e17a', 'TEETH_WHITENING_SERVICE'],
  ['28ff8709-094f-4d99-a102-3e69d863b288', 'HOMEBREW_SUPPLY_STORE'],
  ['6b069385-ad5e-4baf-8a6c-b190feb8db1c', 'APPLIANCE_STORE'],
  ['aaadbd4a-4d5c-4f37-8517-cd86d9f508fc', 'RAILWAY_STATION'],
  ['d8c627ec-36eb-4f9f-beab-2fca00cb0ff9', 'AUTOMOTIVE_MANUFACTURER'],
  ['0fdf9f0f-ffec-4be6-93ac-31320871eeea', 'PARK'],
  ['aba26cc1-6dab-4195-bebc-9989c4635614', 'ENGINEERING_SERVICE'],
  ['21f0dc79-be95-4704-9f48-da5e4a85ad91', 'FENCE_AND_GATE_CONTRACTOR'],
  ['f98bbb66-535a-409f-b904-d9209e27e254', 'PAVING_AND_ASPHALT_SERVICE'],
  ['fb1d5e4b-653c-42f1-adfa-58d9d501cbe9', 'ROSE_GARDEN'],
  ['dc1eea74-a88f-4b99-b3d3-b4dba5d70077', 'HARMONIZED_PAGE'],
  ['9a42207a-7d9f-4186-aa16-4f46756a6ac2', 'AMATEUR_PHOTOGRAPHER'],
  ['357dcdf3-976a-4051-a0b9-eb0fb72f4189', 'BAGS_AND_LUGGAGE_STORE'],
  ['3d0fece5-deb8-4c50-8b12-6901ea0202ef', 'MOOD'],
  ['85d5765d-3d8e-4011-8ce0-db89a8296393', 'ARTS_AND_HUMANITIES_WEBSITE'],
  ['3b1c9805-21d2-4f9f-84ae-e17d22392575', 'MEAT_WHOLESALER'],
  ['31435317-857c-4d39-b682-f5fa7e33413c', 'NONDENOMINATIONAL_CHURCH'],
  ['053833fe-fefa-45c3-ac96-a5d32f737be0', 'JAVANESE_RESTAURANT'],
  ['c3e35494-e664-4c32-aa1c-625b33273b00', 'CLOTHING_COMPANY'],
  ['49b99d07-963b-4823-97e8-6bbb955ca511', 'CHURCH_OF_JESUS_CHRIST_OF_LATTER_DAY_SAINTS'],
  ['dab16297-684d-47a5-bd18-c01c0dce6c26', 'CHAAT_PLACE'],
  ['81abbf7a-1047-4fe5-8102-6628b6d229e3', 'FISHING_STORE'],
  ['41056611-504d-4318-b975-c0d2b584f668', 'EMERGENCY_ROADSIDE_SERVICE'],
  ['5fa39809-b4c0-4a6a-aa50-46b07e71e087', 'CHICKEN_JOINT'],
  ['f5c0fd9d-ee19-4d76-8bb3-e7651fb49f73', 'PHO_RESTAURANT'],
  ['36ec1055-48b7-408e-9a90-74781748ca35', 'MUSIC_PRODUCTION_STUDIO'],
  ['6a60525b-35ee-4805-9f23-3cba908f8f57', 'AIRPORT_TERMINAL'],
  ['75f9dfe3-79af-455f-abe0-cb89217b9c12', 'MUSICAL_INSTRUMENT'],
  ['ddc9afa3-8f04-440d-9093-433f9d1ab2ff', 'HALFWAY_HOUSE'],
  ['b098db59-a1bb-42f9-9661-67421d20896a', 'CONSTRUCTION_COMPANY'],
  ['bc9d360b-51a2-4eff-bf0b-32ddbc05b043', 'DIGITAL_CREATOR'],
  ['64f04158-166a-42b5-93ea-bba67e4abf1b', 'ARMED_FORCES'],
  ['fda74438-1e3c-4dc6-a714-01339f2804aa', 'BATTING_CAGE'],
  ['f0219e4c-eafb-4851-b537-61658952e897', 'HEALTHCARE_ADMINISTRATOR'],
  ['ebe8bd9b-e47c-4553-8f5c-fe849069974e', 'ART_SCHOOL'],
  ['d04b28ab-d0b5-4c21-957a-7f3ef3fee0d2', 'BOAT_RENTAL'],
  ['abb60c8f-9027-40ac-a6ad-0c2a87ba71d1', 'BAGS_LUGGAGE'],
  ['7b888417-962d-498d-9b85-e8cf74354a5b', 'SAFETY_AND_FIRST_AID_SERVICE'],
  ['84ad2083-4e59-4d47-bdc3-ddc6b7a11266', 'WINE_BEER_AND_SPIRITS_STORE'],
  ['6c95b134-361c-4436-b7e6-81534f708622', 'GASTROPUB'],
  ['bdc423e1-e9d5-4236-b059-0e255a296fc5', 'MORTGAGE_BROKERS'],
  ['4ef82899-9e51-4a77-a32f-2aa2c58a034b', 'GARDENER'],
  ['f55dbc97-2322-4b5f-a764-e10067f2d137', 'SQUASH_COURT'],
  ['c8de1c62-bf13-4dc1-b5d3-3d48e821a305', 'SHOPPING_SERVICE'],
  ['89a6a022-f3e1-43ea-8882-83b0e3ab9f23', 'HAIR_SALON'],
  ['2f8c9777-be6c-4122-a647-966fac45a58a', 'CHILD_CARE_SERVICE'],
  ['fbf7e74e-4974-49c6-b458-1df1a705c002', 'PHARMACEUTICAL_COMPANY'],
  ['13ff105c-0483-4b3c-942d-197a0172232c', 'STATE'],
  ['8f0c7d7c-5b8d-4b0b-b92c-5bfd67110ad3', 'PALACE'],
  ['ccdb2ada-9edc-4fb0-b396-911954360876', 'MAGAZINE'],
  ['ab787f65-bfe7-45ee-9b59-6e7b0b17e30e', 'SHANXI_RESTAURANT'],
  ['d9ab94f0-90c3-4c03-8ee8-edd471bf671d', 'SEVENTH_DAY_ADVENTIST_CHURCH'],
  ['9a5ed6f8-8095-4f60-8ea6-fe66d6249976', 'LOAN_SERVICE'],
  ['22ef6d28-1bfa-4e77-8202-6da7da51fa93', 'HEATING_VENTILATING_AND_AIR_CONDITIONING_SERVICE'],
  ['469b4ab1-8758-4b11-a25d-950c9ead836a', 'TEXTILE_COMPANY'],
  ['e41fc761-9055-40fc-8999-1a418f432b80', 'COLOMBIAN_RESTAURANT'],
  ['8b46fff5-fe38-4edc-a30b-48bb79c75b83', 'LANGUAGE_SCHOOL'],
  ['e64ccfdd-9ddb-49fe-84fe-970b96522ba1', 'ROLLER_SKATING_RINK'],
  ['4c6b37e0-8cd6-4e71-8a58-1dc74db33b97', 'PRINTING_SERVICE'],
  ['e6ee36e0-6209-4eb4-b9fc-8b5d97cfa10f', 'TEENS_AND_KIDS_WEBSITE'],
  ['4373aeac-6594-4996-a5c2-2698eb738746', 'CHARITY_ORGANIZATION'],
  ['b9d9d159-35e4-4020-80bb-a284a9cb9885', 'AIRPORT'],
  ['7c2692ce-6743-45bc-bcdf-2b7da011f860', 'INVESTMENT_MANAGEMENT_COMPANY'],
  ['0f93bcdb-8a82-472f-8026-a6d153403fe1', 'PLASTIC_MANUFACTURER'],
  ['34420cbc-cc44-4cc3-9718-d8f9b346a390', 'TRENTINO_ALTO_ADIGE_RESTAURANT'],
  ['fcfe8980-6ba2-4c16-940f-7733fadbbd2f', 'SPORTS_AND_RECREATION'],
  ['06968ba6-6a5c-4916-98c0-9af10cc270ec', 'WHISKY_BAR'],
  ['cf645ad3-0fc3-434b-b678-007704e3a91f', 'INDIAN_CHINESE_RESTAURANT'],
  ['c9c855cf-5bb2-4f03-8d9a-c495b04bf93e', 'CARDIOLOGIST'],
  ['70fe9d72-475b-4940-87a4-eb9c17622b63', 'SECURITY_GUARD_SERVICE'],
  ['a479a929-fcb4-4d0e-a929-803b88d014cb', 'HIKING_TRAIL'],
  ['1f0a91b7-d187-46cf-bf8c-5e2c8271bd52', 'REAL_ESTATE_DEVELOPER'],
  ['ecdd7749-c081-4302-9262-e6b83811a62d', 'BOOKSTORE'],
  ['b889a224-a142-43d6-adad-629876b9c3f1', 'BEAUTY_STORE'],
  ['33bc0eff-f8c8-43ee-87cb-b517e5a014bc', 'PHYSICAL_THERAPIST'],
  ['d08057fc-f291-4740-a70b-7de5e156512c', 'INDEPENDENT_BOOKSTORE'],
  ['ebedc4b6-8d6a-4092-92fb-aa4cca5bcde1', 'UDUPI_RESTAURANT'],
  ['95808ae4-b6f9-4dea-8412-aef392e73daa', 'TIKI_BAR'],
  ['74929815-7797-4265-bd13-39275a1a5f28', 'HOME_SECURITY_COMPANY'],
  ['0af7c4b8-c8f3-45c3-9ada-7b1936e0438f', 'PET_CAFE'],
  ['4de69cac-6aa5-4f3e-a915-fbc4e110457e', 'SURF_SHOP'],
  ['77d94c4f-a5fd-415e-816a-3ae05d3f0d0f', 'AUTOMATION_SERVICE'],
  ['5274e205-76af-4fb7-a258-02f17d210724', 'THEATRICAL_PRODUCTIONS'],
  ['459b0015-a23a-458e-9acd-b744caf3bf2b', 'MALPRACTICE_LAWYER'],
  ['90673fc7-17a5-47ce-b055-b9930966ac30', 'MASSAGE_SCHOOL'],
  ['d40cd068-27e0-4ba8-bf14-84400e23fe5b', 'EVENT_SPACE'],
  ['b4af56ab-1a03-4534-84f0-95005da12e8a', 'GYMNASTICS_CENTER'],
  ['e6253566-0a53-4902-b187-d456e549fdce', 'RAJASTHANI_RESTAURANT'],
  ['5bd122bf-d3df-4360-9fbc-f42fe13ee918', 'FOOD_AND_BEVERAGE'],
  ['2260a81f-18ec-4e1b-a9b5-a3d8c07a4e2a', 'HOUSING_AND_HOMELESS_SHELTER'],
  ['7023b012-a534-4eee-b281-2ab2f044af5d', 'UDON_RESTAURANT'],
  ['41ea6ff5-5d3b-4fed-b1f5-f7b083ef31b2', 'EMILIA_ROMAGNA_RESTAURANT'],
  ['221b4bdc-bedf-426b-8a0b-3be107d40e91', 'FLIGHT_SCHOOL'],
  ['f28f5d11-c236-4668-b7d0-764319467b61', 'ARCHERY_RANGE'],
  ['8c7455e2-8462-4b4d-8910-697beb896606', 'WORK_PROJECT'],
  ['82ed3aa0-a9d9-4714-87d9-f93adeb7ce41', 'RESERVOIR'],
  ['772fbead-8970-4064-8fbf-90fde4d9313d', 'COCKTAIL_BAR'],
  ['82d1113a-7646-4071-8f70-e15b9569fc48', 'SWIMWEAR_STORE'],
  ['26ebe2c5-6d83-4526-a74b-2db1242d4115', 'PIEDMONT_RESTAURANT'],
  ['f4cbf966-96fd-4de1-ab3b-e5786267741f', 'APARTMENT_AND_CONDO_BUILDING'],
  ['dfd39c63-94dc-4c3d-a404-7b0f19bf1e30', 'KUSHIKATSU_RESTAURANT'],
  ['ddd7a666-da4e-4e57-9bd6-10d736bbe573', 'BADMINTON_COURT'],
  ['33cee68a-cd45-4911-a516-7650aefe17c6', 'PERUVIAN_RESTAURANT'],
  ['2bed22fc-1003-44ce-aaa4-f27f2ccab7ee', 'AQUATIC_PET_STORE'],
  ['f6a8cbe4-47c2-45ab-9416-6edc32cd5428', 'ORCHESTRA'],
  ['887fd547-3828-4121-af13-300163523d08', 'WRITER'],
  ['3c68254a-2654-44e6-b0ad-9706f5b40455', 'MUSICIAN'],
  ['0ad23cc9-6bef-4b97-91ed-8b1bf224a7d0', 'SUPERMARKET'],
  ['5d2c914f-95c1-4dbc-8bb4-f15947c3a137', 'HUNAN_RESTAURANT'],
  ['b367b0a5-b6a3-4036-8a02-24f819592bb4', 'METAL_AND_STEEL_COMPANY'],
  ['e4c6baab-1dd4-466f-b429-e289bc975e4f', 'WINE_SPIRITS'],
  ['616d9d03-fa7a-42d4-b657-f6f6d53d02fd', 'UROLOGIST'],
  ['812af58e-df27-47b5-9229-83c64e530799', 'BULGARIAN_RESTAURANT'],
  ['e9c0521d-8469-459d-893f-fd6757f3f445', 'PET_BREEDER'],
  ['fea457a0-28ed-4fae-948d-e94233d8dbb3', 'TEPPANYAKI_RESTAURANT'],
  ['3cc8e59d-c410-476f-93d9-86ab12cc0552', 'BUDDHIST_TEMPLE'],
  ['4418a65b-05a0-4863-97e2-0c7957e3b401', 'AFRICAN_METHODIST_EPISCOPAL_CHURCH'],
  ['b3012809-6bb3-40f2-ad8a-809d9f515dda', 'GAS_STATION'],
  ['c7e1c79a-e211-464f-8954-f3841ab641de', 'AUDIOLOGIST'],
  ['d43c7ca9-284e-4c2f-8123-f55626bad2a6', 'WHOLESALE_AND_SUPPLY_STORE'],
  ['2554282d-ea60-4fe7-9d98-60ef8b3c59be', 'INDUSTRIAL_COMPANY'],
  ['a8155013-7904-44ee-98e7-3828b4987948', 'UKRAINIAN_RESTAURANT'],
  ['56177e09-12d0-48ed-8d54-9cefc0f94459', 'LASIK_LASER_EYE_SURGEON'],
  ['c440be68-fcf7-4a68-853d-ad55b7b46846', 'BICYCLE_SHOP'],
  ['091e0281-376a-407f-8859-90c072f3c6aa', 'RUGBY_PITCH'],
  ['0a2c109e-d49b-4217-a326-22f61068a05d', 'COMMUNITY'],
  ['8919714e-4c51-4ba7-9559-1961f309e744', 'INN'],
  ['76b93dc1-2d97-46dd-b132-bbdffc89f202', 'MACANESE_RESTAURANT'],
  ['211baf1f-6288-4f8c-a58f-4f13c5c62a85', 'NEUROLOGIST'],
  ['2de201e7-bf78-4400-83b4-5e66289c451a', 'MOBILE_PHONE_SHOP'],
  ['a0893464-08d1-4b92-8019-72a158a3b141', 'SKIN_CARE_SERVICE'],
  ['4d231d2b-d346-4a9e-9390-6786201f3b8f', 'PRESCHOOL'],
  ['c562a434-877c-4a8f-9495-4306d51391b9', 'BUFFET_RESTAURANT'],
  ['43f83bb2-6bf3-4bc2-b0bd-45d9bf5e1d35', 'EPISCOPAL_CHURCH'],
  ['c9aedaa9-1be4-4414-9b1b-8682106a1f56', 'COLLECTION_AGENCY'],
  ['0ebf9c22-b20f-49e8-a6ca-97232c97c62a', 'ARTICLE'],
  ['b58bc483-7757-4b8e-a6ca-f6fcfae03466', 'PUERTO_RICAN_RESTAURANT'],
  ['a8f43034-8543-4135-80e1-273501765c2b', 'PRODUCER'],
  ['0e9bccc9-cce1-4acb-a290-009d19d1bb91', 'SCHOOL_SPORTS_LEAGUE'],
  ['1c39fb72-cf46-4785-8ba9-70e8fbcbb668', 'TACO_RESTAURANT'],
  ['2b7a4a8f-c057-4cce-80b1-0f6d652eef30', 'GEORGIAN_RESTAURANT'],
  ['31720138-936c-416f-afba-4dd23892a42c', 'MUSICAL_GENRE'],
  ['9613b340-8833-43f8-ae3d-292159e7545b', 'RAMEN_RESTAURANT'],
  ['c86edd5e-b046-423c-bb3f-46cc98a7454c', 'PERSONAL_CHEF'],
  ['01ff6bfe-db2a-4aaf-8783-cf36eef08d2f', 'TRADE_SCHOOL'],
  ['78dcef12-706a-4fcd-8440-19096844fc39', 'INTERNET_CAFE'],
  ['fa891738-ba48-478d-8a2e-0991f68601f4', 'WOMEN_S_CLOTHING_STORE'],
  ['40a7abdf-e6fd-419c-8972-fdfe8febceb6', 'PUBLIC_TOILET'],
  ['8d7b455a-2ae5-41e0-a9c5-7c464dae57cc', 'SUGARING_SERVICE'],
  ['ceb8f6c9-6455-4e3a-b4f3-daaf956d1f1f', 'PROPERTY_MANAGEMENT_COMPANY'],
  ['6a869b89-4aff-48f0-abbd-94067dacbfdb', 'FAN_PAGE'],
  ['9f68e6d7-116b-43c9-a5ef-132b00adcea0', 'COMPUTER_STORE'],
  ['23a4d884-7e92-4938-8b96-ed74a992f256', 'CASH_ADVANCE_SERVICE'],
  ['116a1616-525e-47ba-97ae-e8c0ca25faf8', 'MONGOLIAN_RESTAURANT'],
  ['ed833384-cd06-4418-81a2-18bbb509c361', 'GENERAL_LITIGATION'],
  ['a5c0a6f9-f1d9-4b50-97a4-205913811054', 'MATERNITY_AND_NURSING_CLOTHING_STORE'],
  ['59df0379-c866-4114-aeac-1aa064ac1f72', 'MANUFACTURER_SUPPLIER'],
  ['f49f779c-028e-4cf8-a111-78be3f5b8412', 'ARGENTINIAN_RESTAURANT'],
  ['1bc8aea5-11f4-42db-83a3-d2752e3e7756', 'TRAVEL_AND_TRANSPORTATION'],
  ['5b537dc7-fbb5-48e9-beac-e39bb1e1f142', 'PERFORMING_ARTS'],
  ['8d2f3395-bfee-465e-a704-def0fc9aefbd', 'PATIO_GARDEN'],
  ['f6c0b93e-cd27-45a6-8698-c498e1a77542', 'PROPERTY_LAWYER'],
  ['01afa787-91f5-4f67-8a5c-3d557a520a2b', 'GLASS_MANUFACTURER'],
  ['6149bcdb-95ad-4bc5-95ce-58370a957bf1', 'CULTURAL_CENTER'],
  ['cd5f0a06-2f46-444b-96f0-67ec4ca6b82f', 'GUKBAP_RESTAURANT'],
  ['61024f78-4759-47e7-a126-534a33b1cd1a', 'CONTINENTAL_REGION'],
  ['f195e61a-854a-42ba-be65-87075a46a063', 'NOT_A_BUSINESS'],
  ['b54af55a-a069-435c-9547-f23e0ecd66b1', 'UMBRIAN_RESTAURANT'],
  ['8a1b6a10-afda-4115-ab77-1ea488151735', 'HOT_DOG_JOINT'],
  ['9b0fa1aa-e8c4-4d3c-b9fe-e6ad2d8e25dd', 'HOME_MOVER'],
  ['ec63577b-ed5e-44a4-96bf-56be11b230ed', 'SHIPPING_SUPPLY_AND_SERVICE'],
  ['85c52986-880b-4ee3-aa4b-d70d090e51a0', 'EMERGENCY_ROOM'],
  ['c6fa434b-302d-49bd-acb0-cefe7c98b2d8', 'NIGERIAN_RESTAURANT'],
  ['2d49cba2-0885-4e60-8958-8293aff51c73', 'INDEPENDENT_CHURCH'],
  ['54577193-bd80-47b8-afb5-03c652d65a2f', 'GLASS_BLOWER'],
  ['8aa6d9d1-30c3-426e-a135-01fcb44d719a', 'FITNESS_VENUE'],
  ['45019c8d-3244-4b7b-8691-d32733fba312', 'VINTAGE_STORE'],
  ['20d0dff3-d22b-4b81-b1eb-eb293276bca0', 'COMPUTERS_BRAND_'],
  ['2e41a0fa-5184-4381-b52b-be2730ea33aa', 'ASIAN_FUSION_RESTAURANT'],
  ['75d1b393-2343-4c3c-b23d-0d19eb7cf2aa', 'RETIREMENT_AND_ASSISTED_LIVING_FACILITY'],
  ['a49017d8-453c-4514-b6dc-9d18f3b53031', 'NOTARY_PUBLIC'],
  ['addf1817-e10a-4105-816e-f39f2f36c634', 'CHAMPAGNE_BAR'],
  ['1ecc1f10-0f94-4ba3-a2fd-a9466c476530', 'NEUROSURGEON'],
  ['50a0ab4c-df71-4cd8-9924-6648e5b7928f', 'SCHOOL_FUNDRAISER'],
  ['6b7eded5-98f9-4bc7-9cac-265c77502945', 'APPLIANCE_MANUFACTURER'],
  ['0de6130a-1953-4a29-b0a1-35f5d280a845', 'CREDIT_UNION'],
  ['d50c01ff-58ca-4356-98ae-58ae83af52ce', 'SAMGYETANG_RESTAURANT'],
  ['6734c15a-aac0-4674-88b2-2f487faa1064', 'TOBACCO_COMPANY'],
  ['5627dac8-b894-4589-9b21-6caf33481d52', 'ALCOHOL_ADDICTION_TREATMENT_CENTER'],
  ['731b5d59-c0df-433a-8123-b4036c69ba1e', 'LASER_TAG_CENTER'],
  ['b188486c-037e-4a6c-8910-618f243fdf44', 'BAR'],
  ['0af05b69-72a6-49a8-9c51-6eae1f9d1869', 'YOSHOKU_RESTAURANT'],
  ['dc7406b1-66d9-45d4-92e2-9e4bb8c43fa9', 'SHANGHAINESE_RESTAURANT'],
  ['9b811154-7e3c-4bb0-8484-36c8614c369f', 'FABRIC_STORE'],
  ['e3ce168d-00c9-4fc8-9aea-bf4415ed1c4e', 'COMMERCIAL_AND_INDUSTRIAL_EQUIPMENT_SUPPLIER'],
  ['8f9362ed-ab47-47a0-83e3-e03984b4b408', 'HAINAN_RESTAURANT'],
  ['748e4da8-b19a-4a85-92fe-6e2521c04251', 'SURGERIES'],
  ['c2193a22-f2e9-4014-8a54-302eff7c8f3f', 'COOKING_SCHOOL'],
  ['2fc4fff4-56c8-4998-a204-b923803fae3f', 'HAITIAN_RESTAURANT'],
  ['05768a90-5ae2-4008-b2b9-90c86a209152', 'PARTY_AND_ENTERTAINMENT_SERVICE'],
  ['4b8cee31-2cf3-4916-9a18-28af0f6e5a93', 'RELIGIOUS_PLACE_OF_WORSHIP'],
  ['6669593b-2e94-4d0d-9c99-644612e1f800', 'HOME_AND_GARDEN_STORE'],
  ['92cd819c-ee54-460f-b46c-718ee38a6c99', 'BOSSAM_JOKBAL_RESTAURANT'],
  ['d2136076-0c89-4a35-b078-7aaff7fd6361', 'FORESTRY_SERVICE'],
  ['4a52c551-7390-4c34-bf9a-a3da3f368aed', 'KURDISH_RESTAURANT'],
  ['793ff788-00b6-4e47-b1d1-b2ccdcee73bf', 'NATUROPATH'],
  ['d17b603d-0835-4225-92e9-a4b1a872b64f', 'MOVIE_GENRE'],
  ['c1378a5f-122c-4b03-a1a4-25245baa84af', 'CZECH_RESTAURANT'],
  ['8eac6c4d-1f79-4370-b17e-d7de60ea197b', 'PUGLIA_RESTAURANT'],
  ['9002d99f-4826-4e62-add8-21798731099c', 'EDUCATIONAL_CONSULTANT'],
  ['7bb628bc-a7ab-413e-9fab-77f2186b961a', 'PUB'],
  ['af186edb-59b6-4dd3-90f6-abb31a65d8a4', 'CONCRETE_CONTRACTOR'],
  ['270b1be4-c32e-49cc-a9f2-b96a4367c374', 'MONJAYAKI_RESTAURANT'],
  ['c903f722-31aa-4a1d-8445-b8f53a10a541', 'EXOTIC_CAR_RENTAL'],
  ['99499382-3dd4-48ff-a2c0-e911b65794c6', 'KITCHEN_AND_BATH_CONTRACTOR'],
  ['95481568-0718-44a4-b593-b1a6b3662246', 'BRAZILIAN_RESTAURANT'],
  ['6ef74c34-aedd-489a-a8ea-01f3eab619ec', 'DJ'],
  ['24b4e6a3-03ae-4cf7-95ce-0e0f7b272e86', 'RV_PARK'],
  ['7eefbb20-e9ea-42ce-b999-b8369a4430b8', 'ELECTRONICS_STORE'],
  ['a8f9423b-194b-46e2-bcc4-0e44fd49bf0a', 'FULL_GOSPEL_CHURCH'],
  ['ca12e86c-19cb-4944-978e-17d11672f53f', 'AZERBAIJANI_RESTAURANT'],
  ['22a15013-ce7b-47f2-bd5e-b3e16f4795d1', 'ACEHNESE_RESTAURANT'],
  ['3a00c89e-1e4d-4eed-a1e2-242a5cec0966', 'DOG_TRAINER'],
  ['88c8c0df-0f71-4d30-b8d4-154adb6cf127', 'CHOIR'],
  ['b23ce2fe-2fa9-4897-837a-ffa108016cc4', 'TAKOYAKI_RESTAURANT'],
  ['91d70a4d-db8b-462e-a81a-149868c28534', 'COUNTRY_CLUB_CLUBHOUSE'],
  ['3568c787-6df7-4b41-acb1-e2651fe858cc', 'STATELY_HOME'],
  ['4b57daa6-530e-4358-99de-c6c51c39e0e8', 'CAUSE'],
  ['cabeb02e-ea1c-497d-b8f7-47e1a37f72a0', 'ABORTION_SERVICE'],
  ['98a7eafd-1a59-4225-9698-abfbca538f86', 'COTTAGE'],
  ['93c948ca-32fa-446c-a664-f7094170e231', 'INTERIOR_DESIGN_STUDIO'],
  ['f4f1e1c8-fb2f-4e1e-a0c3-fa69785f8478', 'ITALIAN_RESTAURANT'],
  ['1531ae8c-f0d4-419f-be43-171589cf60f8', 'CHILD_PROTECTIVE_SERVICE'],
  ['0e1dde78-69df-47a2-9038-e43ba12521bc', 'PASSPORT_AND_VISA_SERVICE'],
  ['a6334605-8a16-429b-a864-09ea6ab9a70d', 'OUTDOOR_RECREATION'],
  ['ad9b7709-19ac-4dfe-89a7-66eced02b82b', 'KOSHER_RESTAURANT'],
  ['fdf85cd9-a09a-4065-be29-e9f82f8700c4', 'OBSTETRICIAN_GYNECOLOGIST_OBGYN_'],
  ['f7003a73-75a6-481e-bd4d-ff3b144dfe34', 'SEASONAL_STORE'],
  ['53fd0062-2327-4df1-b837-6849940734a2', 'STATUE_AND_FOUNTAIN'],
  ['0ea8d6a5-adbc-48a8-bb1f-fece9d69416d', 'PADDLEBOARDING_CENTER'],
  ['20fce43c-89b2-4537-8d62-be0ac4560de0', 'MANAGEMENT_SERVICE'],
  ['df086651-1615-4b20-820e-5751fe5b0bfc', 'CITY'],
  ['a938121e-5466-4dd4-8196-3815ce5f9c80', 'BANK'],
  ['6dd34284-8164-492d-be02-f64b4b616161', 'TOY_STORE'],
  ['734ff3ac-d03d-41f5-9609-782061d13047', 'SECRETARIAL_SERVICE'],
  ['80b0ab55-623e-4cf3-8617-e348783f5658', 'GUN_STORE'],
  ['0293a70f-a171-4e75-940e-d9ffe8958fc5', 'GAY_BAR'],
  ['79284c13-d24e-4411-978d-aca5e4fb9c31', 'LAKE'],
  ['5601041f-b5d6-44d9-8e08-2dc90fb4da04', 'VISUAL_ARTS'],
  ['4af08af5-9b34-4f9e-9e9b-6a49503aa156', 'BOTANICAL_GARDEN'],
  ['8e9e2cb4-10bd-40a8-a69d-a31fa96841d0', 'LITERARY_ARTS'],
  ['0cd7b258-a486-4aca-be03-85aeb3d99f59', 'TAX_PREPARATION_SERVICE'],
  ['7b3fecf4-15de-4335-998a-f8e6e3f94b3c', 'SURGEON'],
  ['47ee4180-315e-4165-a085-b110904965a2', 'ABRUZZO_RESTAURANT'],
  ['250c5228-df4f-4009-9c31-3a48e8aa00a1', 'NURSING_HOME'],
  ['9df13272-c3f9-4abb-bf79-055bc7f6f6ec', 'SCOOTER_RENTAL'],
  ['231ebfee-77a5-449f-a8a8-6ba5aaab526e', 'PORTABLE_TOILET_RENTALS'],
  ['13bbc0c9-c5f5-415f-9d4f-c906ff648c80', 'LIGURIAN_RESTAURANT'],
  ['57a77d94-be09-49d3-8af5-e31b5c5d9462', 'HYDERABADI_RESTAURANT'],
  ['6172b8ba-c9cf-4963-98a4-a5f81e76a7c9', 'SCIENCE'],
  ['8d8affd2-0797-41f9-bf45-0d8873a11c68', 'FOOD_BANK'],
  ['4632a02e-e2f2-4caa-be0e-6ce09227de69', 'GLUTEN_FREE_RESTAURANT'],
  ['10034d99-5aea-4e4a-b5bb-79297b32cf6b', 'INDIAN_RESTAURANT'],
  ['4e190d26-455e-4dde-9387-1c698a4823dc', 'HARBOR'],
  ['4e397f1d-a917-4e9a-b51b-770c8b67f577', 'MASSAGE_THERAPIST'],
  ['9d64e740-dbb1-4cb6-ba2c-06b5dcaebc49', 'VENDING_MACHINE_SALES_AND_SERVICE'],
  ['e89dac5d-c983-4b50-b94e-aed2870afc3f', 'FIREWORKS_RETAILER'],
  ['cb7f2b63-65ea-4c2b-b639-6072360e32b8', 'TATAR_RESTAURANT'],
  ['57cf5002-6090-4ed4-9b48-3152d7d45e77', 'FESTIVAL'],
  ['6b947e0a-47c5-49fe-8cec-8b082dc60a67', 'DISEASES'],
  ['7a0537f4-3c5e-4da3-9c92-ccb8e045c89e', 'HOCKEY_ARENA'],
  ['9f396cbd-7f90-4a52-9c32-547852139a31', 'PERFORMING_ARTS_SCHOOL'],
  ['c1980b94-aeff-4b33-a724-c8d553276431', 'BEIJING_RESTAURANT'],
  ['ded02043-3d63-4cab-9f50-1381bae54270', 'AVIATION_REPAIR_STATION'],
  ['2164388d-f5b6-4738-97ad-0f2daeccce1a', 'SNORKELING_SPOT'],
  ['eea7f7b7-ea2c-4e55-ba91-06be99a0ab9c', 'LARGE_GEO_AREA'],
  ['3c23f55f-11ed-4477-96ab-24fcd7d2481b', 'CONVENTION_CENTER'],
  ['b3ccb0cf-4d01-4da5-b201-3e0e01e0367b', 'FORESTRY_AND_LOGGING'],
  ['6d149428-369c-4a90-a42e-1b683317c4ea', 'MOVING_AND_STORAGE_SERVICE'],
  ['1272426b-de14-48e7-9073-b0bdbdbe87c1', 'UNIFORM_SUPPLIER'],
  ['55b04225-9fcc-4f31-be21-7552d8b3c9c1', 'POKE_RESTAURANT'],
  ['4b40b3c3-5d30-4516-8598-5e7fd567b38a', 'CARGO_AND_FREIGHT_COMPANY'],
  ['bc237339-431e-43de-aed9-0c63f0e30b2e', 'EDUCATION_WEBSITE'],
  ['04dfb8ca-61b9-498c-9e2f-9575f6821a5e', 'CHEF'],
  ['2dede5b2-02ee-4357-85bf-e88b155ae702', 'DUTY_FREE_SHOP'],
  ['fe8ef69e-8a5c-4d42-8c1a-caee7d11642b', 'ASSEMBLIES_OF_GOD'],
  ['cc82d95c-7bce-4c7d-99ce-2d4a0756357f', 'BETAWINESE_RESTAURANT'],
  ['a594ff96-b6a6-492a-a8ea-77c46bf888f8', 'NEWS_AND_MEDIA_WEBSITE'],
  ['3e7e8876-ac05-4bbb-83f8-dc29b60aa59e', 'FORT'],
  ['798ca7f1-7b14-4a0f-b17c-1998ce81adbd', 'TEX_MEX_RESTAURANT'],
  ['2f97050a-743f-4be9-9fe9-254d5303a38f', 'TELEVISION_SERVICE_PROVIDER'],
  ['542f153d-ae5c-40df-b492-938eda38489b', 'EXCHANGE_PROGRAM'],
  ['1162f95a-c0d9-406d-b174-bf39a6ffde8b', 'DOG_DAY_CARE_CENTER'],
  ['9239e3c9-67d1-4e26-8b21-307fbfac9bb2', 'FRIULI_VENEZIA_GIULIA_RESTAURANT'],
  ['d1c94813-4975-4cf0-9280-9935dfbdd8e8', 'REAL_ESTATE_AGENT'],
  ['146bdf97-6cd7-4903-82ba-495759cc0c9a', 'PEST_CONTROL_SERVICE'],
  ['2625ec30-1399-4068-b687-9130539725ee', 'PROFILE'],
  ['2ced3e11-9796-4794-8d7e-ed90a44cdaa6', 'SONG'],
  ['5106c00a-5372-4b6a-9227-983eba269fc3', 'BEACH_RESORT'],
  ['e48df375-6243-4870-8bfc-458a5871aced', 'MEDICAL_SPA'],
  ['7f1232ad-038f-4c11-b4f8-d322c865b5cd', 'AIRPORT_LOUNGE'],
  ['950ef7ab-52e2-4167-9cf9-82ec93c5de8e', 'JUVENILE_LAWYER'],
  ['9de98811-d1b3-436d-9839-73bf25db4e7b', 'PUBLIC_AND_GOVERNMENT_SERVICE'],
  ['0eb7edc5-f710-4d0b-b78f-f4e6511b3aaf', 'WIG_STORE'],
  ['58eda1ef-25af-46f2-8266-9161e9ddcf27', 'ONCOLOGIST'],
  ['510ba6b7-1c41-4654-ac56-1060e8af1da8', 'NIGHT_MARKET'],
  ['d94bccd8-6731-4fed-abd8-440254d3598e', 'HOTEL_RESORT'],
  ['609adb8a-9d5e-428b-9aa0-826d7fefcdf3', 'CONTRACT_LAWYER'],
  ['b79d476b-1ebc-4970-8d2a-b0a14a5b90e8', 'POP_UP_SHOP'],
  ['d5dba6e6-5f92-4827-9c2b-98c122903513', 'MEDITATION_CENTER'],
  ['9357c00d-80b0-42be-99a7-560d0e710732', 'SOCIETY_AND_CULTURE_WEBSITE'],
  ['1b6c7d3c-7465-4c8a-a79a-474291f7e662', 'PRODUCT_TYPE'],
  ['173dfe5f-1414-4915-87c9-80f3083b0b98', 'LABOR_UNION'],
  ['5f7e2f05-38b2-46aa-8066-5c3bb927bd9b', 'EDITOR'],
  ['826bf1a3-b110-4118-a245-cf508cb3d48a', 'TAI_CHI_STUDIO'],
  ['7b3760e6-8557-4699-987e-980af7a26198', 'TROPHIES_AND_ENGRAVING_SHOP'],
  ['17120bb0-c95f-4aad-abaf-9e16218f2c55', 'FLYBOARDING_CENTER'],
  ['ca428a53-bd3d-4739-b3bb-128656f44bf7', 'AUSTRIAN_RESTAURANT'],
  ['e406c4b0-d4fd-4ffb-9059-e95836223002', 'RELIGIOUS_SCHOOL'],
  ['e6a7cd4a-af66-4d81-970f-612eea34d60f', 'ACCOUNTANT'],
  ['93b659b1-1d73-47ab-8be1-fc93487ebd4a', 'MOVIE_TELEVISION_STUDIO'],
  ['af9118d7-180f-4983-90ab-0f756b6377d5', 'LANDLORD_AND_TENANT_LAWYER'],
  ['35c11d2a-1b58-47ed-a0d1-093a76ce436b', 'DECK_AND_PATIO_BUILDER'],
  ['df17237d-5662-4f4c-a2d5-7035c19ab700', 'WATER_HEATER_INSTALLATION_AND_REPAIR_SERVICE'],
  ['2f4a8fe8-c796-47ff-80aa-e233aff49939', 'RENT_TO_OWN_STORE'],
  ['4b1d65e4-1189-43be-850d-e28a184180e6', 'BRIDGE'],
  ['85127575-d3c5-4e97-a74e-9f95492371b1', 'BAGS_AND_LUGGAGE_COMPANY'],
  ['a12a0d77-4ff0-43e2-8a0c-9115d4d4abda', 'PEDIATRICIAN'],
  ['5af8b4b9-4115-4ae5-8f4f-fa8b4514549f', 'SOUTHERN_RESTAURANT'],
  ['8e6b0762-7836-4282-915c-8f635077dfab', 'CATHOLIC_CHURCH'],
  ['11cbe17f-c85a-4edb-9a22-62306d9cd6f8', 'DISTILLERY'],
  ['0515252e-14b7-44cf-9e52-15601ea56f42', 'TIRE_DEALER_AND_REPAIR_SHOP'],
  ['944915c0-b8ba-4fe6-bab6-7db84fbf5854', 'COUNSELOR'],
  ['fea97327-991c-4a47-a1d0-334fc5b351e2', 'CITY_INFRASTRUCTURE'],
  ['6cd92c40-8d2f-499c-94cb-c47c09def944', 'ESTATE_PLANNING_LAWYER'],
  ['1e9875a7-6d4c-42ec-b19d-eb24ad4aa79e', 'MEDICAL_SCHOOL'],
  ['905114dd-922b-4139-b444-224ef26edf30', 'WRITING_SERVICE'],
  ['fea2348b-66f6-4998-8705-d976c605f75a', 'VETERINARIAN'],
  ['066b1148-7be8-4b01-ad8b-ee0704a39697', 'PLUMBING_SERVICE'],
  ['7e1bf4f0-76e8-4212-9e2b-20cf4ed90034', 'SALVADORAN_RESTAURANT'],
  ['89ac886b-b530-4c0e-8ffa-b26241bc00e8', 'SMOG_EMISSIONS_CHECK_STATION'],
  ['0bfcf732-a094-4a36-b9e2-6c16ad150b90', 'CONSULTING_AGENCY'],
  ['7878a574-cda8-414e-b6ca-56555157c313', 'MATTRESS_STORE'],
  ['009717d8-e8eb-442d-9fc6-1e83c1f4e180', 'ELEMENTARY_SCHOOL'],
  ['43b51a18-3dc9-499d-ac24-ebbb2b7dfea5', 'ESPORTS_TEAM'],
  ['1766e4be-6457-4d60-9a6f-cbc82a87fecb', 'CUISINE'],
  ['a73026dc-e92e-4dce-b978-cfb207504c52', 'WEATHER_STATION'],
  ['ff62f672-2dc6-4e9c-82ee-dff3e95b97f4', 'METRO_AREA'],
  ['45efc5f6-cfb6-43ff-a431-97620c725dc2', 'CAMPUS_BUILDING'],
  ['b36775b0-bace-45fa-9a7a-bd1be63eda8c', 'DANCE_SCHOOL'],
  ['4364ca2c-5b4c-4ed2-9582-ebc09f9c4a96', 'PUBLIC_GARDEN'],
  ['9d5a7af3-57c6-411d-a213-f50fcbeb636f', 'COLLEGE_AND_UNIVERSITY'],
  ['8bcd18f1-7d06-436f-8c77-60ce14f8158f', 'BALINESE_RESTAURANT'],
  ['64a69a31-5db3-4c90-9600-64747c193ec0', 'INSURANCE_BROKER'],
  ['64c2ca47-b7d5-400b-b25f-6b1473887570', 'UNIVERSITY_NCES_'],
  ['23b9f504-6891-442b-bdc3-d56c108c2e01', 'SHOW'],
  ['6f64ff5e-700a-41b7-b4c3-81921f810921', 'AUTOMATED_TELLER_MACHINE_ATM_'],
  ['73e75c60-8288-4c09-9b1c-3ee9ad12944f', 'ENVIRONMENTAL_CONSERVATION_ORGANIZATION'],
  ['5821b628-3844-4ab2-8925-3e93c7c608d6', 'HELIPORT'],
  ['46a06206-178b-4d56-bff9-d000cd902f4d', 'NEWS_PERSONALITY'],
  ['928cc7d7-84b5-4d19-95b2-16cc71255870', 'TRANSPORTATION_SERVICE'],
  ['782bff9e-466e-4fc6-ac31-0cb462eb5604', 'COACH'],
  ['c4987f65-dccf-47d5-b938-3f93c1af09fe', 'DOG_WALKER'],
  ['14313266-9cbb-4b76-8283-6361576054d5', 'GARDEN_CENTER'],
  ['ee4517f5-da9f-4671-8c06-f622727a82fe', 'WATER_UTILITY_COMPANY'],
  ['70a663fb-f3c5-43e3-9cbc-949a7563e38c', 'ISRAELI_RESTAURANT'],
  ['fdb2feb6-58e2-4dea-b2f9-be25a1fca9f0', 'HOLINESS_CHURCH'],
  ['97f8b201-9bcc-4a52-b0c7-19ec9e428767', 'TRANSIT_HUB'],
  ['b40d4917-44b5-44a3-9597-ef87abacb562', 'TV'],
  ['d62a68b5-50c1-4c50-9f03-3fd9db213db2', 'FRANCHISE_BROKER'],
  ['828e6a0d-34b8-4219-8330-26f2b78b0535', 'CHRISTIAN_SCIENCE_CHURCH'],
  ['c130f2f6-dea3-4113-bf4c-8a6795195c37', 'LIBRARY'],
  ['253f1359-3561-4ca1-bf54-5f286955f82a', 'DMV'],
  ['ba04caf4-3f5d-4c8c-9218-7feb40ed14d5', 'REPRODUCTIVE_SERVICE'],
  ['dd1e6164-427d-46f5-a9e9-76d0cfc42b8a', 'COLLEGE_UNIVERSITY_BOOKSTORE'],
  ['a109c93d-52df-49f4-b266-d8dfe3bfe918', 'HOSPICE'],
  ['0954ccab-0a3b-42ba-8685-c21c837a57a4', 'GYM_PHYSICAL_FITNESS_CENTER'],
  ['da97fdf2-e6f6-4b23-95ef-0879e132134e', 'REAL_ESTATE_APPRAISER'],
  ['cff2029c-6ef4-41e8-a3cb-b95ecac85b8b', 'VEGETARIAN_VEGAN_RESTAURANT'],
  ['b7f6857f-35ad-48b7-86ca-299d261fad04', 'RIDESHARE_SERVICE'],
  ['795dea14-db33-4167-b9a3-0ce0d70ac48a', 'SUBNEIGHBORHOOD'],
  ['cda59d15-8e3e-4ee1-95ba-ffd3963f510f', 'IBERIAN_RESTAURANT'],
  ['a5e1bf8c-f2ad-4889-ab16-8f9b8cc5c008', 'MONUMENT'],
  ['43f7f85c-52c3-4b5b-96fe-6ecd30f47d53', 'NONPROFIT_ORGANIZATION'],
  ['f0a9a1f2-dc58-4dd6-acea-abae774ffcd8', 'BAR_AND_GRILL'],
  ['4bbc6b17-190f-433c-a71e-4aa8244252a7', 'DORM'],
  ['b27b4bee-8910-45ce-b305-cd67a64f24c4', 'ECO_TOUR_AGENCY'],
  ['3b281c0e-f88f-4716-8f74-0a866885aaf2', 'PORT'],
  ['cd84eb93-408a-4e4d-8791-a20e7ffeaed9', 'WHOLESALE_GROCER'],
  ['ae67cc03-68bf-4664-9033-f506dbf6c57b', 'INVENTORY_CONTROL_SERVICE'],
  ['1adc2886-f6a4-4a43-9ef5-09c5db4665fa', 'SUKIYAKI_RESTAURANT'],
  ['112b2362-c8f6-4061-9f83-5203b9cdc848', 'HORSE_RIDING_SCHOOL'],
  ['3232fa23-7285-4abb-b9b5-a8b4cfd58a22', 'DHABA_RESTAURANT'],
  ['d4a7a19e-a746-4ea0-88ee-a06e422f3fc4', 'MOROCCAN_RESTAURANT'],
  ['4cf56bb6-0ec9-41e0-b718-2496d829ba45', 'ECUADORIAN_RESTAURANT'],
  ['ddf10b6f-f521-487d-b8ce-9a8314e5dee2', 'SWIMMING_POOL_CLEANER'],
  ['c3f57869-421a-4ef1-b895-fee932581527', 'BOTTLED_WATER_COMPANY'],
  ['3b8647eb-b643-4fbf-984a-df7612048a8a', 'UNIVERSITY_STATUS'],
  ['c55b3e42-77b8-4150-848e-c34d845bfb78', 'ADDICTION_TREATMENT_CENTER'],
  ['340c3dbf-d412-4fc0-a2c4-da39616ece66', 'NUTRITIONIST'],
  ['45768344-3494-4a87-9cdc-7ed0e9310f97', 'AUTOMOTIVE_GLASS_SERVICE'],
  ['bf195324-c748-4581-a403-a6999dda5169', 'OCCUPATIONAL_SAFETY_AND_HEALTH_SERVICE'],
  ['24596f72-4149-4f9e-b730-e7a06fb569d5', 'FAMILY_STYLE_RESTAURANT'],
  ['9b722dec-099c-4cf7-a9a7-92dba7ed5768', 'HEALTH_FOOD_STORE'],
  ['32c5cbd5-2347-47c6-8e75-757010df06b0', 'LODGE'],
  ['9fc0aa60-3d3f-4874-9aad-51dfaf2b8ba9', 'TICKET_SALES'],
  ['f052c116-e716-4e1b-b3d6-bde2c8c8e3bf', 'STREET'],
  ['1fee93e8-5777-4f97-8ef6-e1ad2384f414', 'GERONTOLOGIST'],
  ['50a48d5b-4b95-4fad-b0d5-cf32cc4bc983', 'CHINESE_RESTAURANT'],
  ['e72210dd-acba-423b-b0e7-6c8218c68644', 'DRUG_ADDICTION_TREATMENT_CENTER'],
  ['ea7f07a6-3c5f-4ab2-a6fd-73fb0d80a5e0', 'SKI_AND_SNOWBOARD_SHOP'],
  ['c85e1757-d89b-409c-b6e8-d92fb0f50293', 'CARPENTER'],
  ['6113e3a4-01a4-4ae9-b1f5-09c452694bc4', 'YOUTH_ORGANIZATION'],
  ['10456fa2-a76a-42fc-b5a0-47907020dd08', 'RECREATION_CENTER'],
  ['9cb8fee9-3d3c-4033-a261-b0448cc7e091', 'CABINET_AND_COUNTERTOP_STORE'],
  ['bdff8a40-f559-4f69-9cc0-1f1700d49a58', 'LAWYER_AND_LAW_FIRM'],
  ['167f70f9-bac4-4d1e-8fa4-97ef5228f88d', 'LUGGAGE_SERVICE'],
  ['ddf8db83-7530-471d-acb8-58d5390e4ff4', 'FINANCIAL_PLANNER'],
  ['dddbfe74-29fe-4fd1-a572-b3956940671b', 'HORSEBACK_RIDING_CENTER'],
  ['66833dd8-41aa-442d-908b-200a73473d69', 'SPORTING_GOODS_STORE'],
  ['9759d4d3-fdf4-4d53-b557-4d35d48102dc', 'FERTILITY_DOCTOR'],
  ['e4b46d16-bf06-4169-b21d-7358e785ad6c', 'LOMBARD_RESTAURANT'],
  ['053547fa-d8cc-4169-a5d0-880caa1ec8cd', 'BIG_BOX_RETAILER'],
  ['e7d427a6-f655-447a-9d59-946500584d70', 'METAL_FABRICATOR'],
  ['19864f1f-cac7-4b2b-9082-281042418f10', 'HOME_THEATER_STORE'],
  ['f39f86e3-e941-4217-9835-44c21254734a', 'MEDIA_NEWS_COMPANY'],
  ['bc9f62c2-2cfe-47a4-8c6e-03c047ce7d83', 'RADIO_STATION'],
  ['eaa8f18f-5ab9-4de4-b41d-3edfdc8c7d73', 'SOUVENIR_SHOP'],
  ['ad87cc56-8113-4af4-99e1-dc3501aae49a', 'WAXING_SERVICE'],
  ['814a24ef-42da-4bf4-897e-be62de415a21', 'BABY_GOODS_KIDS_GOODS'],
  ['7e8a133d-db3b-4c7f-881e-397849f9703b', 'BOAT_TOUR_AGENCY'],
  ['bde885d5-790b-409b-bea7-55bea2318eaf', 'ONSEN'],
  ['c7b72ba1-d740-48f1-8f39-bd0cc284c1b3', 'BOLIVIAN_RESTAURANT'],
  ['0cbdcc8f-c330-4759-bd05-dbfc306c2844', 'EPISODE'],
  ['536c6317-7012-413d-9ecb-6cc7e34432ae', 'SPORTS_SEASON'],
  ['7b0b451b-28bc-4efb-aeea-36d803b02a74', 'SURVEYOR'],
  ['d1260a93-12a9-4f9a-8219-9b5a1c88c4be', 'DANCER'],
  ['f63adc48-3e15-4395-8ba9-847f273c491a', 'SYNAGOGUE'],
  ['0069cd13-b39c-47a1-934c-2819bcdd97fa', 'TRANSLATOR'],
  ['59d612ef-c143-4a05-a58a-64d089c3bedb', 'ELECTRONICS'],
  ['610b9c2f-e6cf-4c1b-afbb-2337443c4269', 'PARTY_SUPPLY_AND_RENTAL_SHOP'],
  ['67d13d19-91cb-4a6c-b43a-1b7d21de4b55', 'PODIATRIST'],
  ['f7383565-caa1-48f7-9470-ddee51c585d6', 'PILATES_STUDIO'],
  ['67cde33b-1953-4d0a-a50a-7b60a517fadd', 'SORORITY_AND_FRATERNITY'],
  ['b23313fc-228e-4c88-bb5d-b5bb0a68b811', 'BOUTIQUE_STORE'],
  ['4040dcfc-e24c-4404-a33f-290df4e844c3', 'MASSAGE_SERVICE'],
  ['568ef067-6282-4377-b3c2-7aa186a7eb7f', 'PET_ADOPTION_SERVICE'],
  ['c5d81778-eff8-419f-8da9-032a9fdc0c43', 'RIVER'],
  ['f4486f79-9d85-4cae-ae02-9b370322786e', 'MANCHU_RESTAURANT'],
  ['66c88835-7fa7-495a-871a-4479f86fd266', 'BUSINESS_AND_ECONOMY_WEBSITE'],
  ['5be244fc-7956-4e2f-8691-7369bc780170', 'NEPALESE_RESTAURANT'],
  ['7b30b9a8-c4be-48b7-9683-b0c69f246020', 'AUTOMOTIVE_SHIPPING_SERVICE'],
  ['5efca0d4-cbb5-4677-b6a9-2d8fe902fe7d', 'KITCHEN_COOKING'],
  ['7d9c1acf-4d37-4617-a464-628774b7fe50', 'BUSINESS_CONSULTANT'],
  ['daaaff77-c779-4db4-96dd-ad707a6f874c', 'MUSICAL_INSTRUMENT_STORE'],
  ['1169df9b-bb2f-47f5-9538-7588cd1d43b6', 'CAMBODIAN_RESTAURANT'],
  ['a4934625-4b9a-4488-8fcd-b6d01a314052', 'SHOPPING_DISTRICT'],
  ['3d8a95cb-278a-4a40-afb6-31dc341b8df2', 'BOOKS_AND_MAGAZINES'],
  ['5a5623e0-1f00-4791-92df-60ab5c5b7f73', 'MEXICAN_RESTAURANT'],
  ['6f198d11-6240-4a97-baef-a320eecd031c', 'PROMENADE'],
  ['434a54f9-a41c-4075-ac0f-2cb77df38799', 'CURRENCY_EXCHANGE'],
  ['5383b28d-3af9-447f-831a-a781bf616804', 'LEGAL'],
  ['a984935a-761c-42b2-9f60-5b1163c38c7c', 'GUJARATI_RESTAURANT'],
  ['94584fbb-e85d-496c-9fc4-1216b4edab4e', 'UNAGI_RESTAURANT'],
  ['5fbe9c6c-1c85-4ca9-aed7-56a2648c9643', 'GOLF_INSTRUCTOR'],
  ['c664baa1-71a7-42d5-9598-0fa446093a45', 'EVENT_VIDEOGRAPHER'],
  ['6538cee2-d6a1-4131-b0b0-2546f9782f12', 'CLOTHING_STORE'],
  ['ca9e5224-8a6f-4c17-af58-c068bc28fea3', 'TV_CHANNEL'],
  ['4ff7c519-bbab-4018-8a04-d4f69e1d4f29', 'SAXON_RESTAURANT'],
  ['3f12f368-0275-48b6-b9d9-bff846d50726', 'BAKERY'],
  ['518f76f1-e651-47e5-965f-b0f4a5f0f901', 'ELECTRICIAN'],
  ['6ebc762c-b514-4575-b607-40f32eeeabce', 'FURNITURE_REPAIR_AND_UPHOLSTERY_SERVICE'],
  ['7e3b15c0-8ca2-4bd5-bafc-afe0a39f0661', 'TATTOO_AND_PIERCING_SHOP'],
  ['4f46305c-187b-42cb-a2f3-bb1f2b4b4246', 'PHARMACEUTICALS'],
  ['938881e3-41cf-4b29-af8c-e5ba646227c1', 'BELIZEAN_RESTAURANT'],
  ['b799b1e1-e56c-4a86-b30b-01bf505b2fe9', 'SALAD_BAR'],
  ['8555db88-f1b3-46af-af9a-e6487b874ca2', 'BUILDING_MATERIAL_STORE'],
  ['9b9921a1-215a-42a6-8b00-3692845f87f6', 'SOCCER_STADIUM'],
  ['cff9dab1-71e1-45e3-8f0f-2dd49a584481', 'COMPUTER_REPAIR_SERVICE'],
  ['0ed2656b-548a-4f5a-8a8b-8f440225cdaf', 'HESSIAN_RESTAURANT'],
  ['789d27ff-3856-4707-b4a9-9c6d43ad91b6', 'HOME'],
  ['864db06e-af56-4614-abe7-aef314181a75', 'XINJIANG_RESTAURANT'],
  ['53007c41-afa0-49a5-87ee-0f50ea2eaaa7', 'BED_AND_BREAKFAST'],
  ['251447fd-757e-4944-8a8a-765f32925a19', 'WEB_DESIGNER'],
  ['41f66912-44c7-4250-9e4c-38fa7a40e453', 'ARMENIAN_RESTAURANT'],
  ['34d1cbde-e8f2-46bc-83cb-d0bff0a4845d', 'BABYSITTER'],
  ['0690cab5-da65-49f5-aff4-03359b7f2c5d', 'HARDWARE_STORE'],
  ['f267bc58-4775-46c1-8a76-d15ee2ea2837', 'BANKRUPTCY_LAWYER'],
  ['8f2d2f6a-86a7-417a-9864-3ab9af190921', 'BOOK'],
  ['cd64bb81-96a6-4500-bc5d-4bdc4503ad81', 'MACHINE_SHOP'],
  ['0a32625d-ca16-4028-9c72-d8ac9c9661af', 'KASHMIRI_RESTAURANT'],
  ['183b0684-aa3f-407c-943d-aa9c5129c087', 'DAY_SPA'],
  ['59e3b232-99ff-46a9-a0b8-502d1b73e5ec', 'MEDICAL_RESEARCH_CENTER'],
  ['acb39c53-475e-4ad3-9c84-15491b71cc0e', 'FOOD_TOUR_AGENCY'],
  ['9f281bca-869d-4919-ab30-2c62e0d66b08', 'FUJIAN_RESTAURANT'],
  ['fd3715a8-0f15-43ad-8f53-98dd4c95537c', 'NEWSSTAND'],
  ['18610db4-c02b-4b5a-9c15-c22acf53e58a', 'MEDIA_RESTORATION_SERVICE'],
  ['0dc58151-0897-4da7-a859-5bca9414ff12', 'MARTIAL_ARTS_SCHOOL'],
  ['e16ede28-5c45-4580-b209-cb9f1eb6eefe', 'AIRPORT_SHUTTLE_SERVICE'],
  ['ba64376f-1e29-4146-8322-a71f03bc68fd', 'FLEA_MARKET'],
  ['901dea92-04f3-4eff-9829-a18acbb0f878', 'SUSHI_RESTAURANT'],
  ['2fbeed1d-21a0-4a8b-a7fb-50850b5ccb8e', 'COLLECTIBLES_STORE'],
  ['1042f8a4-58f2-4dfc-bf03-a812b2f9e27a', 'RECREATION_AND_SPORTS_WEBSITE'],
  ['47ed8a1e-e28f-4e29-8c33-341d313ef96f', 'COMMERCIAL_REAL_ESTATE_AGENCY'],
  ['f0091140-9da9-4bab-8952-89f39c6af5de', 'MAGICIAN'],
  ['31c2e471-83d0-47a2-90c9-cfbca66f1411', 'MUSIC_VIDEO'],
  ['a6da9c7a-af2c-494d-a169-84da76b64424', 'HOUSE_SITTER'],
  ['ae745d9c-9fca-4420-949e-d60db55a8ff8', 'PHOTO_BOOTH_SERVICE'],
  ['ea607096-4e9f-4100-9e6e-d3d98fcbe570', 'FAMILY_DOCTOR'],
  ['004ad37d-bf91-4c55-a3e2-c49e4c6e3869', 'VOLCANO'],
  ['39bc453c-a4f6-4a91-80d3-59bb8eb33f54', 'ONE_TIME_TV_PROGRAM'],
  ['f928ac32-8fc0-4207-8a98-784605ccbb1d', 'CONTINENTAL_RESTAURANT'],
  ['358276f6-ee5e-4100-89db-4b1983afdd63', 'SENEGALESE_RESTAURANT'],
  ['ff1c0724-bb05-440c-a486-500099b657df', 'LANGUAGE'],
  ['613ffcf5-4441-45fe-bdf6-79979dc25c84', 'PUBLIC_SWIMMING_POOL'],
  ['d7a4bcdd-7cf0-4ad2-939e-c7925ad775fa', 'PORTABLE_BUILDING_SERVICE'],
  ['5f4e9f69-5992-4e33-90b6-2bc5d677d0c2', 'MOVIE_AND_MUSIC_STORE'],
  ['fa9f7ee0-3e91-45c6-8b9d-aae16660188a', 'COMPUTER_COMPANY'],
  ['4860bd81-d574-472a-8b72-ff4390d31744', 'OUTDOOR_AND_SPORTING_GOODS_COMPANY'],
  ['adc37655-f54b-4158-9397-1bd34e62e9ff', 'PORTUGUESE_RESTAURANT'],
  ['0df5ec19-b570-43e3-bf91-d2fc43985475', 'SPIRITUAL_LEADER'],
  ['969e3437-3035-4860-8d09-23c943910d73', 'AIRCRAFT_MANUFACTURER'],
  ['3ccfe9e5-2290-4d33-b9eb-008e58297eb8', 'ART'],
  ['410eb25b-238f-4b54-a63b-32b538532b2c', 'WINERY_VINEYARD'],
  ['f662fe57-f2a8-410a-af85-52751993b087', 'HIMALAYAN_RESTAURANT'],
  ['e06dcaf1-3e88-4c1e-b593-7820fb0920c8', 'BEAUTY_SUPPLY_STORE'],
  ['e7521c1c-c035-438b-b2d9-cac1c2af6f04', 'LASER_HAIR_REMOVAL_SERVICE'],
  ['e3ae0712-c629-4fbb-987f-735270835a4a', 'RACQUETBALL_COURT'],
  ['cbee3d14-85ff-4a25-aedc-e9955175d17a', 'ENTERTAINMENT_WEBSITE'],
  ['275174e9-be06-46d1-b504-ac483ffe9496', 'CONTINENT'],
  ['a1904735-b23e-4d22-b68b-c80bfc9c9116', 'AIRPORT_GATE'],
  ['b9e2726d-6011-40d6-8282-b8afccb1683b', 'LIGHTHOUSE'],
  ['8b0a458a-d32d-440d-bd8e-c0ad71622f84', 'BASEBALL_STADIUM'],
  ['3cbd9087-0569-4cf8-abca-b90c966d3fd6', 'TV_GENRE'],
  ['c0b7d3c6-969c-43c1-87ea-371364828a0c', 'CITY'],
  ['a03098aa-dca9-404c-86a8-b636814447b3', 'HOME_WINDOW_SERVICE'],
  ['184537d7-2d81-4d84-a818-0f7eae5974a8', 'RV_RENTAL'],
  ['091f80d9-346e-4e17-af7d-533dde2a043b', 'REGION'],
  ['563758dc-6171-47f2-962b-bdb37f769b97', 'QUAY'],
  ['1a3aed59-be23-4721-9663-9a1fe791d043', 'AUTOMOTIVE_RESTORATION_SERVICE'],
  ['acdfd0c2-6a43-495a-98f6-039c9f4b1005', 'BUS_TOUR_AGENCY'],
  ['2d358fe2-f86f-4d63-bc76-1d29bf46914d', 'GRANITE_AND_MARBLE_SUPPLIER'],
  ['1c47ef69-b8f1-4b88-b019-c2003d923946', 'EDUCATION'],
  ['f73332da-2a3c-483c-818a-4ed14a28e735', 'SLOVAKIAN_RESTAURANT'],
  ['e2abb11f-4e5d-4468-9adb-01680bb6ec0d', 'CAR_RENTAL'],
  ['8d455c47-2855-4f67-82b3-2dced5fc831e', 'SEAFOOD_RESTAURANT'],
  ['9538c766-e83d-4cfa-ae14-55a34a8ae58f', 'SOCCER_FIELD'],
  ['745b3e35-330d-4908-93c9-af742a51e8ed', 'CATALAN_RESTAURANT'],
  ['9935f038-8dfb-40c3-ab00-842d77356e28', 'BRITISH_RESTAURANT'],
  ['26dcf427-495d-42ba-86af-6809d8ccb0ca', 'HONDURAN_RESTAURANT'],
  ['dd0d76cc-605a-44fa-8f75-7ad2c0faada3', 'DEFENSE_COMPANY'],
  ['545cb360-a085-4110-aa99-523ba49b767d', 'KERALA_RESTAURANT'],
  ['920008ea-d13b-4c7f-8333-83f5c56d7b73', 'SPORTS_PROMOTER'],
  ['c714826c-edb3-4b58-b827-449399c2bbbe', 'MINIATURE_GOLF_COURSE'],
  ['96111117-0113-47f8-a48c-a50ebba27ed6', 'LEGAL_SERVICE'],
  ['61b10a4f-0daf-4f74-9205-0719da567f95', 'SOUTHWESTERN_RESTAURANT'],
  ['1b7e651e-9fdb-48c5-9534-9659516480b7', 'INTEREST'],
  ['d23ba1e5-4eed-4c1e-af0b-b9f4626a3cf3', 'WELL_WATER_DRILLING_SERVICE'],
  ['cd1ff526-e86b-4b8b-b3a0-8ef3c2e7e8d6', 'NURSERIES_AND_GARDENING_STORE'],
  ['d993ab6b-fed9-4f1a-9123-c7873562d69c', 'ANESTHESIOLOGIST'],
  ['fe9d518b-113c-4ad1-8323-e500bad596dc', 'SPECIALTY_SCHOOL'],
  ['06586268-8bca-4f38-8952-d647cb9c3869', 'KEBAB_SHOP'],
  ['99ac1e1d-970a-4f81-83bf-cf20a9ea9843', 'ADULT_ENTERTAINMENT_SERVICE'],
  ['2dcc807f-abfc-45f9-961b-c1cba4cf39d6', 'SKATEBOARD_PARK'],
  ['28104dea-208e-4c4b-ac59-237c8c9c2083', 'LOBBYIST'],
  ['71c17d80-8cf5-46b1-9357-266b6f6a82b2', 'HOME_INSPECTOR'],
  ['d1806b26-076a-4581-9672-795cfd25a521', 'MEDICAL_SERVICE'],
  ['d2672859-d605-4887-a4ef-64870367a49c', 'PRIVATE_PLANE_CHARTER'],
  ['bd5a5b55-86b6-4f64-9300-6c9730bd2fe2', 'HEALTH_BEAUTY'],
  ['f8567dec-f7d5-4d98-a525-af8faead4af5', 'DAY_CARE'],
  ['198eb380-c997-44d7-a072-ca96b67d0816', 'SUNGLASSES_AND_EYEWEAR_STORE'],
  ['15ccdf37-1c2f-4e4e-945f-9769f337e5e0', 'ACADEMIC_CAMP'],
  ['a9c164e8-c70e-413d-a076-5a2d7c96f08a', 'ORGANIC_GROCERY_STORE'],
  ['e61a5d6d-835b-4cc5-a59c-f40a4b676eb1', 'HAIR_EXTENSIONS_SERVICE'],
  ['1a751f21-3305-49ed-b4e5-f3bbba067584', 'BAPTIST_CHURCH'],
  ['7ab4c0e0-292e-4fb5-93c3-fcdb50e13184', 'HOTEL_SERVICES_COMPANY'],
  ['7132d9c5-492a-4fd4-b451-e846462f5a01', 'GUN_RANGE'],
  ['e291a0ed-16a3-4b75-9aac-65c607e36712', 'VIDEO_GAME_STORE'],
  ['8fd38445-7d2e-4759-b0b0-8483d1f73f1c', 'MOTORCYCLE_REPAIR_SHOP'],
  ['e84ef5a7-3606-4878-81b8-ea0f010bc73f', 'ANIMAL_SHELTER'],
  ['937c13f1-b9be-478a-bbb7-ebe2dae2c3d2', 'WEDDING_PLANNING_SERVICE'],
  ['6341a478-46b3-4798-b2f6-ed56c881df67', 'TAMILIAN_RESTAURANT'],
  ['0fe3fc61-8aef-45c4-b56c-4e13fece89ce', 'UTTAR_PRADESH_RESTAURANT'],
  ['f533bc87-0fc3-44eb-807b-971648dd4d81', 'POSTAL_CODE'],
  ['1fbc45bc-545d-4772-96c9-ead3def413d5', 'BOARD_GAME'],
  ['845d1dc0-65dc-4e27-8b57-e67ade805c20', 'COSMETICS_STORE'],
  ['9ea9fbd4-ad3f-4ecd-b0ce-93d2aea828cf', 'YUNNAN_RESTAURANT'],
  ['c95e1941-9ced-4549-b22d-e364d550b78e', 'ANHUI_RESTAURANT'],
  ['9ba020d1-cc34-4b05-9f0d-96f6fd64952e', 'POLISH_RESTAURANT'],
  ['75381bb7-a8e5-4bda-88c7-1bd4305be5aa', 'NEWSPAPER'],
  ['2353d357-599f-4943-bd00-c57ac5533a1e', 'YOGA_STUDIO'],
  ['7bdd7a76-17f2-457f-b46c-f172c62ef8d9', 'MARINE_SUPPLY_STORE'],
  ['56a20290-a05d-4f03-93be-ebf4d649e175', 'MOVIE_CHARACTER'],
  ['7948d466-7ea5-40c7-8869-00d89095674c', 'ICE_CREAM_SHOP'],
  ['45ab912f-3f0b-41ad-81a3-604a564b5955', 'CHIMNEY_SWEEPER'],
  ['e99db72b-26fd-474c-b9b2-91c3006f1a46', 'SOLAR_ENERGY_SERVICE'],
  ['b3fe2b65-2dc8-44d1-8944-6e3871b05e3a', 'NEIGHBORHOOD'],
  ['36648f2a-5119-454a-86f1-bdbe26abae8f', 'AMATEUR_SPORTS_TEAM'],
  ['2f7e634d-b109-4567-b698-dcf84e9b156b', 'ARABIAN_RESTAURANT'],
  ['6290e796-364f-4e8e-857a-6e74b9169de4', 'ADOPTION_SERVICE'],
  ['61456862-9c3f-49da-9c40-7ab1a4d22ae2', 'SWISS_RESTAURANT'],
  ['a05f44c1-4333-4e1e-8d58-04c8ae7774ba', 'CONGRESSIONAL_DISTRICT'],
  ['fec0f9b0-0387-4d22-b5c5-4cbe8b6bbd91', 'HENAN_RESTAURANT'],
  ['ce45ab8d-a109-4aad-ae13-cdff1c274eb5', 'GENERAL_DENTIST'],
  ['18362e8d-dab5-4d30-a226-5ec8e1f27ee7', 'SHANDONG_RESTAURANT'],
  ['51fe7e89-ee72-4c66-8771-73037f69def3', 'CORPORATE_LAWYER'],
  ['23fa641f-6d70-4483-960a-00ec97cf3aee', 'BICYCLE_REPAIR_SERVICE'],
  ['29c08458-d594-4f2a-ae69-b32b93e2ec66', 'LIVE_AND_RAW_FOOD_RESTAURANT'],
  ['22f5dd43-5773-4c62-b442-a64a388bfd26', 'OFFICE_SUPPLIES'],
  ['b8116148-ba1e-43a0-81bc-44f03c6495e5', 'HEALTH_AND_WELLNESS_WEBSITE'],
  ['01a53c9e-ecfe-41a7-bb23-dc8980b3da18', 'NON_GOVERNMENTAL_ORGANIZATION_NGO_'],
  ['6bac93bb-48a4-4e5d-aade-b4a6d39eeccf', 'BOOK_AND_MAGAZINE_DISTRIBUTOR'],
  ['d7401ca0-ba87-4bb1-bf84-c42f61e8a6c9', 'CHILEAN_RESTAURANT'],
  ['84de05d8-b706-4c3b-b221-47c96de57005', 'BASQUE_RESTAURANT'],
  ['8a3e8c2e-29ea-4b68-b897-4f93ed1a303f', 'DISCOUNT_STORE'],
  ['c38834e8-5832-4161-91db-334bb99569c1', 'BUTCHER_SHOP'],
  ['25ca57e0-f848-404b-a059-c034d681e011', 'DUI_LAWYER'],
  ['80eeb8d2-5761-4109-a5c4-f807a611c442', 'PUBLISHER'],
  ['243bb653-e4ec-416f-aa1d-1e97acfbf83e', 'MEDICAL_EQUIPMENT_SUPPLIER'],
  ['51cfc2aa-808e-4ea5-ac4d-09803783a759', 'GLASS_AND_MIRROR_SHOP'],
  ['84761900-0f9f-4321-ac7e-3109b7494117', 'FOOTBALL_STADIUM'],
  ['79ccbae3-fe9c-4d05-9568-9794461afea6', 'THREADING_SERVICE'],
  ['8025cf34-0e0e-4d8a-a56a-1b4f0b1e8437', 'FIRE_PROTECTION_SERVICE'],
  ['fe4b4e1d-e6fd-4c43-9596-62fc548225d0', 'DISABILITY_SERVICE'],
  ['35e24f7b-5bd7-4443-bbab-e275d2bc646d', 'ASTROLOGIST'],
  ['68cdcfe7-2e45-44c8-aaff-bf5907ba5c26', 'BASILICATA_RESTAURANT'],
  ['accbdb5b-b0ce-41ab-8b73-ed1672519681', 'WOMEN_S_HEALTH_CLINIC'],
  ['e03b6055-163b-4b65-9b32-42ba682cf9ab', 'CAPE'],
  ['771ed1bc-ccf6-4b16-ad37-388a89972e08', 'COLOR'],
  ['9c388573-1360-4b92-916c-f7e13b6e4bf5', 'NATIONAL_PARK'],
  ['44c0d31a-bb9d-4468-bebd-70f7bcd4e1e8', 'PRIVATE_INVESTIGATOR'],
  ['4d1aaa4f-fcd9-41df-b9df-732d10bf2276', 'NON_BUSINESS_PLACES'],
  ['b7963159-e403-42af-8ec5-4e82f701ec38', 'LOGGING_CONTRACTOR'],
  ['e829cd71-d7e5-4759-a103-d5a6a9842d38', 'TUSCAN_RESTAURANT'],
  ['fda6d97c-c43b-479d-a356-b1f469b8f112', 'BOAT_SAILING_INSTRUCTOR'],
  ['34980621-7e39-4e42-b8db-9adc6a75d30b', 'PADANGNESE_RESTAURANT'],
  ['f135bb66-bb8f-43e2-be4c-77676a39c7f2', 'AUTOMOTIVE_SERVICE'],
  ['726515e4-64d9-4336-b672-27176f27940a', 'CR_PERIE'],
  ['42a0a9cc-4dc4-405f-87f5-219ed079953a', 'CRICKET_GROUND'],
  ['b15fcb49-f3e3-4828-ba2d-4e3ea3011a33', 'BARTENDING_SCHOOL'],
  ['44155339-7787-4e27-8c2a-d30572904a9e', 'MARINE_SERVICE_STATION'],
  ['efc3c884-6dc8-414c-b46d-8cadb8641a31', 'STEAKHOUSE'],
  ['ca2b6d19-53a6-4001-87a1-77aa4bb2ffa4', 'HOTEL_BAR'],
  ['a68eeb56-6ccd-4bd0-8fe8-99b0a7222104', 'MEDICAL_CANNABIS_DISPENSARY'],
  ['7f3cc039-50bb-4429-b73b-baea894310f3', 'TV_NETWORK'],
  ['837e2643-1cc8-49e2-9833-a3d00d416613', 'PUBLIC_SCHOOL'],
  ['3c26d903-8ca7-467f-a985-45bb2dd6277a', 'SPORTS_AND_FITNESS_INSTRUCTION'],
  ['51620b06-afc2-4538-887b-712a0d4a26f8', 'OUTLET_STORE'],
  ['6a717011-bfab-4e2a-a6b5-a7b2b7f498bf', 'BIKE_TRAIL'],
  ['98b1fff6-9844-440c-856e-80e03ca0d890', 'MARRIAGE_THERAPIST'],
  ['0ccfc2e5-2c0e-42dd-a79e-02d3a2deaa21', 'SPORTS_CLUB'],
  ['f998a73e-0673-4cb5-b378-5ed3f12ffaac', 'SOCIAL_CLUB'],
  ['ac8650b3-c61d-471d-a211-2c3f2b96f658', 'RETAIL_BANK'],
  ['ea9a218d-a6ee-4d75-86a4-0021ca1632d5', 'AMATEUR_SPORTS_LEAGUE'],
  ['3cf43f86-4909-46ef-8148-c8ca4aee23f2', 'HOT_POT_RESTAURANT'],
  ['802632d1-0955-4372-bb05-3e5f2cbeffd9', 'ADDICTION_SERVICE'],
  ['103adb19-f48e-40e5-b99d-c6e52c956dc6', 'SOCIAL_SERVICE'],
  ['48b883d5-0c20-4f19-93e0-5e0a4dc57d7b', 'LAUNDROMAT'],
  ['b4adc6c5-1c3b-4b3c-aec7-788ace734d97', 'CRUISE_AGENCY'],
  ['817b1552-cd68-48e6-ae52-21b11ff93f88', 'LEBANESE_RESTAURANT'],
  ['453b4b9e-90f6-4839-9aab-9ec3e759064b', 'CALABRIAN_RESTAURANT'],
  ['640cf151-7c5f-44da-bd0e-eefa1e2291e9', 'PROCESS_SERVICE'],
  ['14e08082-9ced-483b-8e5c-15061e0f8f0a', 'RAILROAD_COMPANY'],
  ['24ad4739-64b8-45cb-a6a7-b166dfea539f', 'GLASS_SERVICE'],
  ['3cccfebc-123a-4008-a370-92aa09ffb0e3', 'HOUSE_PAINTING'],
  ['d862643f-f61a-461d-a71d-36d649a38b1d', 'SINGAPOREAN_RESTAURANT'],
  ['5da54797-8222-4ac3-b561-30d65123995b', 'INTELLECTUAL_PROPERTY_LAWYER'],
  ['a63a1217-8604-425c-ae2a-d66ef5bd3cb2', 'BELGIAN_RESTAURANT'],
  ['d5e1b12b-b86e-4c73-ab1d-a3a4203b0406', 'SPEECH_PATHOLOGIST'],
  ['6f4af274-5e60-458a-83bb-9f148b95b441', 'COMMERCIAL_AND_INDUSTRIAL'],
  ['5720d38a-eecc-4b09-b6d2-2e41978bb138', 'COSMETOLOGY_SCHOOL'],
  ['bc6961fb-19ff-4bef-b833-3a99d53dcb3d', 'COUNTRY_REGION'],
  ['f06378d5-ccf2-4ec0-8451-a47ce94c9028', 'SEAPLANE_BASE'],
  ['d67e9536-92fb-4d2c-81b3-47620c86b25f', 'ROOFING_SERVICE'],
  ['3dc3beaa-7997-4eb6-a903-f87537a5bb70', 'COSTUME_SHOP'],
  ['e1b7e93f-ec46-4f75-b3b8-f520ead08710', 'COMEDIAN'],
  ['fc975132-7468-46c2-8b30-b7a87c9af8d7', 'FUNERAL_SERVICE_AND_CEMETERY'],
  ['95534cf3-ec88-4b84-8eaa-c5b6c6891c0a', 'OFFICE_EQUIPMENT_STORE'],
  ['7567fb68-51f6-4f62-be34-ede619c1a470', 'COMIC_BOOKSTORE'],
  ['3d3c5ef5-ed82-4a3b-9628-b79c4e4c14e6', 'HEDGE_FUND'],
  ['84ec3e7f-fe2d-4c17-9296-34b2ee5b5686', 'WEIGHT_LOSS_CENTER'],
  ['e12e790a-26a8-43e5-ba07-959344defd6f', 'MOLECULAR_GASTRONOMY_RESTAURANT'],
  ['aff32969-0625-47b4-a638-6c9de4e5ba25', 'EVANGELICAL_CHURCH'],
  ['a8a3d7cd-ee8e-4ce3-9f32-a97d089c6114', 'ALTERNATIVE_AND_HOLISTIC_HEALTH_SERVICE'],
  ['79b2448a-e832-415f-8ca4-7cb9ea6cbcd6', 'RUGBY_STADIUM'],
  ['17f2e3f8-5ed9-497f-9835-218a0b9d9658', 'AUTO_DETAILING_SERVICE'],
  ['104df7af-50cc-40c3-9d05-c191036635a4', 'DIM_SUM_RESTAURANT'],
  ['b5ea5677-e2c1-4e48-adb2-0a296f63558e', 'PROSTHODONTIST'],
  ['ed8cba2d-33c7-432b-8158-70d9c94e2ff9', 'TOOLS_EQUIPMENT'],
  ['abf470fd-1211-4aa3-b905-b66efd27a512', 'HOME_STAGING_SERVICE'],
  ['ab6d04a8-eee7-46e1-a89a-f27ca3f1dfef', 'RADIOLOGIST'],
  ['57f4bb4e-4022-4415-a0ca-9fc8613716fe', 'THERAPIST'],
  ['6f79a6de-51aa-4fda-9e2a-6f929af6acc6', 'RAFTING_KAYAKING_CENTER'],
  ['292a8944-13e0-496d-8fb7-0acfe15762b4', 'TRUCK_REPAIR_SHOP'],
  ['54d251b3-52da-4cba-8e12-e11bcaa07e78', 'BAGEL_SHOP'],
  ['eed64984-c048-4430-b489-12856ae377a6', 'REAL_ESTATE_INVESTMENT_FIRM'],
  ['e82db2d4-5419-4ee4-8b9d-8b702f44fb49', 'FISHING_CHARTER'],
  ['b4b1000f-596f-4637-8cac-8cebc6d3ffb6', 'SMALL_GEO_AREA'],
  ['6b4d724f-7a54-48e5-9203-ef8b29682eb9', 'FOODSERVICE_DISTRIBUTOR'],
  ['848b34f4-7329-4178-9552-ce2678254ca0', 'FITNESS_TRAINER'],
  ['96b97e19-3461-4137-9676-42ae17cbcf71', 'RECYCLING_CENTER'],
  ['9cd61b85-70c3-43fc-bcd4-606e087ca04a', 'BURGER_RESTAURANT'],
  ['b7734710-f91e-4eeb-b104-a3430ed61d12', 'COMMUNITY_CENTER'],
  ['46dceac9-5e5f-48dd-861a-9f4ac3b6e697', 'HOUSEHOLD_SUPPLIES'],
  ['05f5f64c-2ecb-47f5-9f2f-5b36f82c5134', 'TRUCK_RENTAL'],
  ['05a46713-f2af-439a-8487-7325c13ec939', 'DRIVE_IN_RESTAURANT'],
  ['57063626-6293-4662-b72f-f50bd5e517b6', 'SOUTH_AFRICAN_RESTAURANT'],
  ['ed3ea7fe-7ae5-4283-8573-ffea337fdd39', 'BEAUTY_SALON'],
  ['3428afc3-d3b4-440c-a46a-07b3fea015fc', 'LUTHERAN_CHURCH'],
  ['989a6759-56bf-4649-908f-831e99b9882b', 'TIANJIN_RESTAURANT'],
  ['5b888fb2-27f7-4a2a-821c-7caa7932ffb9', 'AFRICAN_RESTAURANT'],
  ['040c2c37-1c51-42e0-bc56-64946194f881', 'VACATION_HOME_RENTAL'],
  ['1b919150-6086-42a4-b2d4-7dac815ee12f', 'LOCAL_AND_TRAVEL_WEBSITE'],
  ['0a14f554-25bd-4660-99bb-e8fa5ad49e17', 'RESTAURANT'],
  ['4c536b07-0a53-4e55-a32d-effdb0923922', 'IMAGE_CONSULTANT'],
  ['ea1b67ec-bd70-441a-be0f-0ed93bba65d8', 'HAIR_REMOVAL_SERVICE'],
  ['1c0034e9-0c76-4c7f-98e7-9b3ec1e2f760', 'TOPIC'],
  ['120d77c4-adcd-400d-9f74-e1654a92684c', 'COMMUNITY_SERVICE'],
  ['16a4feae-41a0-40b1-8ee0-ed33d061f77c', 'ENVIRONMENTAL_SERVICE'],
  ['dba6efbc-58b6-4072-8562-ee001146aa5c', 'PLAYLIST'],
  ['91f23320-93e7-4e1b-933f-26b04a43f95c', 'ATV_RENTAL'],
  ['2192833b-d9b9-4530-bd5e-c508b7335b85', 'DEPARTMENT_STORE'],
  ['a8d4a133-1082-4eb5-b60f-592b91b8a213', 'LANDMARK_AND_HISTORICAL_PLACE'],
  ['a87a2c95-4ba0-4bce-9c5e-898b50766b71', 'HOT_AIR_BALLOON_TOUR_AGENCY'],
  ['4d05cfcb-10c5-4d42-841a-6b336ac614d2', 'ATHLETE'],
  ['c8903fda-51c1-40d3-87fc-0fb6b5b5363d', 'AIDS_RESOURCE_CENTER'],
  ['7426f9b0-9624-4241-bf5d-85acc59e930c', 'MIDDLE_SCHOOL'],
  ['793a59c8-fce7-4192-b5db-5543c4b309c0', 'FLORIST'],
  ['8b67032f-9ae8-45d4-990a-eb8386e1587b', 'JAPANESE_RESTAURANT'],
  ['a1292f90-da6d-42fa-87e2-225f1e17f82f', 'SPORTS_BAR'],
  ['8aa383da-b922-4439-ae4c-32eddcf3a87a', 'MENNONITE_CHURCH'],
  ['225a844e-dd40-4e0a-9dd9-fb3e3479739c', 'SPA'],
  ['3c2b86ef-b9d4-43cb-8f3a-ced4cf77187c', 'ENERGY_COMPANY'],
  ['f93d3881-b732-4653-a38e-1661b9c97d75', 'SCREEN_PRINTING_AND_EMBROIDERY'],
  ['5276a450-25cb-4cb1-ac51-f8d0b4a5ab32', 'PAKISTANI_RESTAURANT'],
  ['cc49528c-78e2-4cf4-b512-784c526351c9', 'ATV_RECREATION_PARK'],
  ['6fa1f350-1799-4f7d-8648-126a76e68897', 'BEACH'],
  ['88b93a24-44ae-4cdf-a37c-fe9e5311eefb', 'TENNIS_STADIUM'],
  ['24fb3a4a-b95a-4140-bf24-32189ec7a4bb', 'ICE_SKATING_RINK'],
  ['7c790719-0a9f-47e6-8964-403612abbf87', 'HANDYMAN'],
  ['782b1b0a-d856-4442-b635-85be79b57ac7', 'DEMOLITION_AND_EXCAVATION_COMPANY'],
  ['cc1be6d2-4fa8-4617-8f14-20738a469e61', 'CABIN'],
  ['3feff076-4c15-4a16-9d2d-1584c73e56c9', 'ELECTRIC_UTILITY_PROVIDER'],
  ['f3692938-dc7c-4426-ab19-5015582a06da', 'THEME_RESTAURANT'],
  ['e4b4e502-1958-4e85-96ca-52ce69adc17f', 'PSYCHOTHERAPIST'],
  ['7938f57d-56fb-4704-9863-14f55e31cd80', 'SPEECH_THERAPIST'],
  ['31ffe275-cafd-42fd-a1d9-23f6848593c1', 'STATE_PARK'],
  ['5d373aed-645a-475c-8988-d63671203f0d', 'MEDIUM_GEO_AREA'],
  ['bbd79730-55b3-4185-9095-0caf6f1aa1b5', 'AUTOMOTIVE_REGISTRATION_CENTER'],
  ['ea0080aa-f6a7-4f46-bc18-e44a779aeec5', 'COMMUNITY_GARDEN'],
  ['2cae9622-c727-4cc1-9831-d718cf241c6d', 'MUSICIAN_BAND'],
  ['2b255cec-1b10-432a-ad47-ec27cbbbeb32', 'SAKE_BAR'],
  ['faf0ef5f-58fb-49ad-b1c0-a28517a24d1b', 'PLASTIC_FABRICATOR'],
  ['143c79f0-1be9-4485-9185-880500d10ec3', 'WINE_BAR'],
  ['d76c0c69-b12b-47e5-bad4-6da40ddc8871', 'SPORTS'],
  ['4f5e534c-6486-4cc1-ab52-70d6d52c52f6', 'SANDBLASTING_SERVICE'],
  ['c5472ca7-8130-4802-97f3-4e15464f456d', 'OPHTHALMOLOGIST'],
  ['75e652e2-5879-48f7-b60f-600edb011e53', 'TRACK_STADIUM'],
  ['835736e5-1438-4d83-890e-bbd223bf9fbe', 'GEOGRAPHICAL_PLACE'],
  ['f9c4d067-3631-480b-949b-7deec5c599ad', 'TONKATSU_RESTAURANT'],
  ['3e7cf1fd-28f1-4378-986d-9169c88795a5', 'EVENT_PHOTOGRAPHER'],
  ['4f92eb0a-7761-4e40-8408-41e4e8439c26', 'CRISIS_PREVENTION_CENTER'],
  ['a7d6fa4a-054b-4438-be75-9ad97e0068d5', 'TRAIN_STATION'],
  ['e736d87e-0d02-49e3-818a-daac6dd969b2', 'KINGDOM_HALL'],
  ['da112c10-be17-40d5-8215-3bf353e3b41b', 'SPORT_PSYCHOLOGIST'],
  ['ad8b7bc6-f79e-45ed-94dd-f2332e9f740c', 'RELIGIOUS_ORGANIZATION'],
  ['c3e00593-e352-49c7-b759-ad172bb321f8', 'INTERNET_LAWYER'],
  ['b246d3ac-043f-41d4-af70-38c611c6a6f3', 'MEDIA'],
  ['ceb0f7d2-46b8-437b-81c5-8899aa047bdc', 'APOSTOLIC_CHURCH'],
  ['67c952b0-fc96-4e37-b382-4cb98f88d8c0', 'PERSONAL_INJURY_LAWYER'],
  ['50246157-9e38-4edd-9fac-047ababcc2e3', 'WORK_POSITION'],
  ['9ab44751-7d9c-4d4b-ab55-8c59cde7ada9', 'INFORMATION_TECHNOLOGY_COMPANY'],
  ['acc7298f-6e87-42d6-b17a-ad6f92613ab5', 'NATURE_PRESERVE'],
  ['554ac9d3-75a8-4867-94f7-962f79a5fe03', 'ENDOCRINOLOGIST'],
  ['2fd9d03e-5b39-43f1-94e9-1c0667029953', 'DIVORCE_AND_FAMILY_LAWYER'],
  ['f6e5a3bd-0a66-4086-a2e4-85aa242f340c', 'RELIGIOUS_BOOKSTORE'],
  ['94a2ee9d-a5c6-4413-9cec-1ecadf082da6', 'NORTH_INDIAN_RESTAURANT'],
  ['5e958806-634b-474e-a644-fee317522e5d', 'SUBWAY_STATION'],
  ['95638dae-67ff-4fdd-8d85-fc8f7a9851e6', 'TAIWANESE_RESTAURANT'],
  ['0c42958c-c826-4e7c-8d9f-6a2a426e0cac', 'INTERNET_COMPANY'],
  ['d2861549-ada6-45b2-97a9-67e6954a783c', 'SKI_RESORT'],
  ['ff8af4b7-54c2-46db-8276-b081faaa08c4', 'HANG_GLIDING_CENTER'],
  ['0c752566-c97e-42bd-aac8-d433e95d633d', 'TRAVEL_AGENCY'],
  ['4c03e084-b025-4e14-ad34-81bc5f1abee9', 'FASHION_DESIGNER'],
  ['4a0878ae-27cb-46c0-ac1f-14a9476504f2', 'HEALTH_FOOD_RESTAURANT'],
  ['a4345a61-39e8-4637-8d78-bbff011d1ed0', 'CRIMINAL_LAWYER'],
  ['84e15649-4617-40f8-9ca5-ce259f528b3c', 'NATIONALITY'],
  ['4842d826-a940-432b-8474-d41724984a8f', 'JANITORIAL_SERVICE'],
  ['ce73fd9f-0e23-4fef-a00b-601c8c5c7b73', 'IRANI_RESTAURANT'],
  ['36099288-698e-4d30-a407-6d18bcd2b7f6', 'CAREER_COUNSELOR'],
  ['0df553ec-c5cb-4bb6-ad65-cbae9643d5f2', 'SEX_THERAPIST'],
  ['bd8b8c6a-25d6-4b15-aa19-9864f263e279', 'PAINTING_LESSONS'],
  ['8e21dae0-0f00-4fbb-a1d4-113b54a043c1', 'YAKITORI_RESTAURANT'],
  ['0844e918-2615-4de7-8e42-e36cac8422c2', 'SKYDIVING_CENTER'],
  ['25f1f847-9fb5-41c7-b242-be5feaa57fd7', 'FOOD_STAND'],
  ['ed072ace-3339-4220-8ddb-26a285920445', 'YAKINIKU_RESTAURANT'],
  ['7afc93fe-23ce-4e8e-84ab-13cb5bfa01ff', 'TAXI_SERVICE'],
  ['76fee586-90ad-4239-974d-f8497bc0aadc', 'HIGH_SCHOOL'],
  ['98d3e084-8ba9-4f0b-a502-ef5a7f8574b5', 'WAGASHI_RESTAURANT'],
  ['69153c72-da05-4835-b40d-3ae154246340', 'SCUBA_INSTRUCTOR'],
  ['b6231a43-da64-4a35-9623-de5371d0d653', 'MEDITERRANEAN_RESTAURANT'],
  ['f4262416-a869-4747-8b81-e740255e4c4b', 'CULTURAL_GIFTS_STORE'],
  ['3782a388-bc1d-4b58-be93-fd59cd56132e', 'INVESTMENT_BANK'],
  ['85091daf-212d-499b-bf87-376fb7a83dca', 'PET_CEMETERY'],
  ['cd6e3ac1-4667-4408-a865-4c4deb609813', 'FINANCIAL_CONSULTANT'],
  ['1d753a30-7904-461c-adbf-1a84ff8a173c', 'PRIVATE_SCHOOL'],
  ['ce33dd56-6e0b-445b-8830-658aab3a98f7', 'COUNTY'],
  ['8dbd789c-9fe7-42b3-a2c3-7884cffa4e78', 'PANAMANIAN_RESTAURANT'],
  ['6b78578d-680e-44dc-add7-fb84c4fcd3ec', 'HISTORICAL_TOUR_AGENCY'],
  ['864da756-8102-4607-ac04-af892e36a66f', 'OTOLARYNGOLOGIST_ENT_'],
  ['4d85e9c3-d973-4897-84a1-d5b19d5e1a19', 'CHEMICAL_COMPANY'],
  ['5ee0853d-8fc3-4114-b79f-25cff94b7f43', 'BREWERY'],
  ['a096ce32-d2ce-456b-a646-b5951fd7b2fb', 'MENTAL_HEALTH_SERVICE'],
  ['0ad6b1fb-b29d-4a0e-bff8-6a524ae82625', 'HORSE_DRAWN_CARRIAGE_SERVICE'],
  ['fca1b606-4173-4787-a753-6d8ef20819ce', 'SHOPPING_MALL'],
  ['f60ded7d-c64c-46cc-9ca9-b3d5f62236db', 'MAKEUP_ARTIST'],
  ['2a75e10f-1901-4bb9-b03e-ec5496217225', 'UZBEK_RESTAURANT'],
  ['f0811a5c-e55e-4484-99ed-47e5eff56eb2', 'DESSERT_SHOP'],
  ['1e6916d5-bd58-47b4-9d1e-2639cec5c3e2', 'APP_PAGE'],
  ['486ed6eb-61d1-4b4d-9554-9a921e235bb4', 'MEN_S_CLOTHING_STORE'],
  ['42071019-f8e4-4936-a359-236cd9baa980', 'SHREDDING_SERVICE'],
  ['62041fac-39f8-455d-8aba-ffd7dcdb3982', 'BEAUTY_SUPPLIER'],
  ['8889c7f7-5247-4441-8b4e-119fc473faaf', 'PET_GROOMER'],
  ['5292fd85-e271-45a6-aa54-6b68749ba788', 'HOSTEL'],
  ['d491982c-095a-441f-947b-f1abe5af2887', 'PSYCHIATRIST'],
  ['8de7d8f9-4870-422e-9e71-54d54e23c6ef', 'PALATINE_RESTAURANT'],
  ['0df20015-02ec-40ea-9f98-f5bbdc4f90b2', 'TRAVEL_SERVICE'],
  ['d6f1ad99-d5be-4379-95c1-fe0773c6068c', 'SOCIAL_MEDIA_COMPANY'],
  ['e407b380-1123-446d-b3d6-c684d13e5b60', 'BARBER_SHOP'],
  ['f6e060e2-3d0f-42e9-b631-03ad5b2fa214', 'MEETING_ROOM'],
  ['dbf3a5e2-4140-4278-bfa0-b1f48c5d0af2', 'BREAKFAST_AND_BRUNCH_RESTAURANT'],
  ['9c151ecd-71de-4cb6-b271-f3fabac41e65', 'BEER_BAR'],
  ['68d37fdf-aa57-4ed3-832b-e1d8f1f34a32', 'TRAVEL_COMPANY'],
  ['955a4f1d-c17b-416e-ad2c-d0ccc711118c', 'BOAT_SERVICE'],
  ['a26339de-557f-49c8-9fdd-30cbc597978b', 'CONTRACTOR'],
  ['94cbd06b-5369-4ee9-95df-c05aebff2ce1', 'TUTOR_TEACHER'],
  ['2787d429-a58c-4403-ae91-53fc7ff225b1', 'ARCHITECTURAL_TOUR_AGENCY'],
  ['c0d7ffdf-9d9c-4662-a5bc-49a74c7315b8', 'BOAT_FERRY_COMPANY'],
  ['58598295-cde9-4109-9b28-764b00d92fee', 'JEWELRY_AND_WATCHES_COMPANY'],
  ['2f9b9fda-e834-40b3-8774-2c0acb59b5e9', 'SUNDANESE_RESTAURANT'],
  ['56a6bb32-bb49-4ba6-b74d-65755c6be958', 'ARCHITECTURAL_DESIGNER'],
  ['ce554174-7f6a-4de9-aebb-c58421a73ceb', 'DELI'],
  ['7c01bc17-8e20-4055-85f6-5985dede2689', 'PULMONOLOGIST'],
  ['4f281279-e858-4843-9c79-45ee69a956b7', 'RECORD_LABEL'],
  ['b80f6d9a-9e50-459c-babc-57ed1053b953', 'WATER_TREATMENT_SERVICE'],
  ['e571e33b-6190-4340-b832-8cca4c3e7ea0', 'APPAREL_DISTRIBUTOR'],
  ['7a18bc1d-79a8-4e38-86e2-25d1109dc4a5', 'EUROPEAN_RESTAURANT'],
  ['76a0a385-9360-40b0-a833-361129fec0ff', 'PETROLEUM_SERVICE'],
  ['57032ff3-aa8a-4d31-8906-0725cba55cbf', 'PHARMACY_DRUGSTORE'],
  ['443ea69f-0ece-45fe-b6ff-d1a8db0da207', 'SERVICE_APARTMENTS'],
  ['b12a0f76-5a8e-4b25-a16e-8e7a4c797079', 'MATTRESS_WHOLESALER'],
  ['875c0487-5f9e-4721-a9a6-135a369cb4d2', 'IRISH_RESTAURANT'],
  ['5957e0fc-2cb9-4a1b-82bb-0d7bbdf1bf66', 'GUTTER_CLEANING_SERVICE'],
  ['19211e4a-873c-46d8-b342-32ef835844e5', 'CHRISTIAN_CHURCH'],
  ['31f82f8f-96b1-401e-98b0-62025389899b', 'CAMERA_STORE'],
  ['ad7d3cab-dfbb-4f12-938f-2ff7e6453bd2', 'BURMESE_RESTAURANT'],
  ['2cd0b6c3-2d91-428d-9507-ad6a77b3d567', 'GAMES_TOYS'],
  ['f27a2879-5410-478c-8c98-5436a69c59c5', 'LOCAL_SERVICE'],
  ['00413132-2522-462a-87ce-638ea3ec3b83', 'FOOD_WHOLESALER'],
  ['5a92eacf-6b10-49b5-b63b-73b8028c526d', 'CABLE_AND_SATELLITE_COMPANY'],
  ['40a4eb9b-6828-4dd3-a0f1-cadf0f49ab93', 'CAR_STEREO_STORE'],
  ['c5a903fa-019e-492a-af73-b5e92e4471c7', 'MEDICAL_EQUIPMENT_MANUFACTURER'],
  ['20f7d75c-9def-492a-8558-a63ce0534e37', 'GLACIER'],
  ['1d84ff8f-e56b-406c-8aa4-5e5eb162949d', 'PUBLIC_FIGURE'],
  ['26c69fd1-f480-4bb4-bc43-813aa980ed1f', 'PRESBYTERIAN_CHURCH'],
  ['e15a752f-331d-4493-94cf-da4f8c6afc9b', 'AUTOMOTIVE_STORAGE_FACILITY'],
  ['f0aab7f7-9ec2-49fd-a9dc-974cf887e797', 'CAFE'],
  ['afb91844-5776-42fd-9fe2-f04125dd546a', 'PARSI_RESTAURANT'],
  ['cea1a8f0-5c2b-4571-8841-e3cfb89b2ff0', 'DONGBEI_RESTAURANT'],
  ['d488f78c-5cdd-4031-9419-27520e71d8b5', 'E_COMMERCE_WEBSITE'],
  ['dbcd700d-6a17-4acd-b1d7-cdeab3bab0df', 'JIANGSU_RESTAURANT'],
  ['2398a326-a0a0-4ff5-8403-85e9f4726beb', 'FRENCH_RESTAURANT'],
  ['30692438-b215-4cf4-812e-aebc8d4b34e1', 'VIDEO'],
  ['34a6d24b-5c46-4c8d-a4f2-bd892e99854f', 'RETAIL_COMPANY'],
  ['72f349b9-bd03-4f4b-a534-daeab6c0e26d', 'JOURNALIST'],
  ['cafeb26e-a2cb-4865-809d-6aa322e2b9d1', 'DOG_PARK'],
  ['d314bf23-c492-49a8-a72a-55f1dca536fd', 'HOSPITALITY_SERVICE'],
  ['adab8240-f049-4606-8c6e-0e115e493db7', 'MUSIC_CHART'],
  ['6331c651-f360-4cbe-95f5-541eb880f4c5', 'RELIGIOUS_CENTER'],
  ['a2c4c30a-f035-414d-bce9-9c923df6750f', 'MAHARASHTRIAN_RESTAURANT'],
  ['c34d983e-5be9-4432-bc31-4813df9071d0', 'SCHOOL_TRANSPORTATION_SERVICE'],
  ['72d4cd93-2bf4-405b-87ac-31487aa9ee7f', 'HOME_HEALTH_CARE_SERVICE'],
  ['4513e606-e539-4a2b-87f1-ce5f9616bd7a', 'DOG_BREEDER'],
  ['1294e6a1-fe3c-42d6-b33a-90d86d4d9f76', 'PREGNANCY_CARE_CENTER'],
  ['f89a4bae-2291-48e0-afb9-26def7c6ed5e', 'CARIBBEAN_RESTAURANT'],
  ['aaf4ed27-9199-40a2-ad54-d571fc37d900', 'FOOD_WEBSITE'],
  ['8d1b0c88-0dd0-40ca-936b-8aaafbad071c', 'DINER'],
  ['a527a000-acf0-4efa-818f-d3066bf2bfdf', 'GO_KART_TRACK'],
  ['f3cd2077-38e6-4bb7-ae51-9ae9d1d7b483', 'NABE_RESTAURANT'],
  ['b091ea8f-86ec-4715-a509-cc411dfcfff7', 'THRIFT_AND_CONSIGNMENT_STORE'],
  ['e3729b2e-98e3-4d69-bc78-422e2ad45006', 'MOUNTAIN'],
  ['39812008-b2a6-416a-8565-855f72628b3a', 'EASTERN_EUROPEAN_RESTAURANT'],
  ['c98c3ae2-001c-48c5-a7aa-1b00db33e218', 'PEDICAB_SERVICE'],
  ['a46360db-0529-4cf6-81cb-5c2c4e65a2a6', 'SHABU_SHABU_RESTAURANT'],
  ['89806296-4b6d-44e5-9204-59c6367d11d4', 'VILLAGE'],
  ['a2a6fed7-8bb4-4713-81f3-d44e58b9ac63', 'CAR_WASH'],
  ['be6e03e9-a287-4bbe-9d8e-5678d2ed042b', 'ALLERGIST'],
  ['57af1de1-9bf0-4792-9813-ae8d46ef1a8d', 'CONVENIENCE_STORE'],
  ['5463ddbe-c8cf-4e55-879c-f6b69ee7a5ad', 'TURKISH_RESTAURANT'],
  ['f225847c-7fc0-4c4e-a041-953cfdb32515', 'SOUP_RESTAURANT'],
  ['6dbe9dc8-1c77-45c9-926f-37dcf178db7c', 'POWDER_COATING_SERVICE'],
  ['4df7bb73-fa50-4551-9298-744f9ca4ed03', 'LOCALITY'],
  ['d9e4c7ce-adef-4389-99e4-f34834674692', 'MUSIC_AWARD'],
  ['1c5f4a11-cf19-4c33-b5c0-10e5160ad563', 'CAMPGROUND'],
  ['d60048f4-3b1a-4609-b812-4f82fe420dd7', 'BUSINESS_SUPPLY_SERVICE'],
  ['463e58f2-752b-4772-b6d5-bb53735602a1', 'CONGREGATIONAL_CHURCH'],
  ['464eabfb-5082-4692-9421-562a3a6f3c09', 'THEATRICAL_PLAY'],
  ['e900919d-2788-4456-af90-478774a19edf', 'BALLOONPORT'],
  ['98ace1c4-621a-4503-be8c-2da95c15874c', 'REFLEXOLOGIST'],
  ['3726fdcb-740c-4f87-9755-56477fd44d51', 'AROMATHERAPY_SERVICE'],
  ['a9e9062c-933c-4ec7-a1c8-b01eec753ef4', 'IMMIGRATION_LAWYER'],
  ['8009df43-82d5-4e72-bb25-875b296d22be', 'AUTOMOTIVE_WHEEL_POLISHING_SERVICE'],
  ['a253e673-0d4a-4568-a4ca-f5566136abb5', 'SPORTS_AND_RECREATION_VENUE'],
  ['e27f4954-eb8b-4d1b-a860-41f37f79800a', 'SCULPTURE_GARDEN'],
  ['a0ac2d86-fd88-42f7-8988-368a1d24a45e', 'DOCTOR'],
  ['0421361c-ffc5-4f91-b3aa-96af49d11091', 'CHOCOLATE_SHOP'],
  ['e34f51a0-18fb-470c-9eb1-fa7f50d79c6b', 'EASTERN_ORTHODOX_CHURCH'],
  ['d6913cbb-672c-45b3-8643-46b27a82eca2', 'LIGHT_RAIL_STATION'],
  ['849d8b6b-ef63-4344-9896-24bcdec69e91', 'SOUL_FOOD_RESTAURANT'],
  ['7795aac0-c265-4185-a87a-3bd3b4cc12f4', 'ORAL_SURGEON'],
  ['f877e97b-fe02-4246-a08e-b698c0d47334', 'HINDU_TEMPLE'],
  ['0a7c180a-025d-4b40-aa66-c4d673ae8279', 'BOOK_GENRE'],
  ['83e96c42-ddb9-42d1-95de-be7ceea407b8', 'HOSPITAL'],
  ['16bdd215-49d2-4f80-a58e-582128efd1af', 'FRANCHISING_SERVICE'],
  ['7f8917a4-20ba-4268-8045-e152a881e1f3', 'PHONE_TABLET'],
  ['f15fc0f9-c735-469f-9190-5701edc26d9e', 'HOBBY_STORE'],
  ['03dcc8c5-1e24-4e2a-84df-1102c0bf2251', 'LATIN_AMERICAN_RESTAURANT'],
  ['a2f8931a-ada0-478c-84a6-071e2f1afb36', 'FURNITURE'],
  ['8b3b5ee6-3df4-4e72-a6a2-2c3b123a3efc', 'SPECIALTY_GROCERY_STORE'],
  ['f6eff56b-4c7c-4d69-831e-68dcf27ef052', 'COMPUTER_TRAINING_SCHOOL'],
  ['ce235ff2-4ada-4814-a8e6-63039ab9e028', 'PSYCHOLOGIST'],
  ['bc6665ac-9d68-49af-88e0-5420a6f1f464', 'SHOPPING_AND_RETAIL'],
  ['37c9018e-214b-4b35-bab3-40a317c565b0', 'ADDICTION_RESOURCES_CENTER'],
  ['23176a5a-46d5-497f-a767-bc4ef05fa1f0', 'SCIENCE_TECHNOLOGY_AND_ENGINEERING'],
  ['c21484d1-3b0c-47e1-b11a-043ad86c5132', 'REPTILE_PET_STORE'],
  ['91df5164-5fb7-4a9f-bf92-a243fdf5b394', 'BASKETBALL_STADIUM'],
  ['dc24f258-621f-4539-ad64-c60ed328e5a1', 'BRIDAL_SHOP'],
  ['7499ba3b-1535-4f7e-986c-353a2d3096d6', 'PICNIC_GROUND'],
  ['d038fe87-d779-4106-a6dd-1b9458621392', 'FISH_MARKET'],
  ['a3c6712a-fb0a-48d9-8def-b8480db7cf2c', 'SCOTTISH_RESTAURANT'],
  ['58051b0e-ad7f-494f-a43a-1e4177045be0', 'GENEALOGIST'],
  ['84c84613-176d-4bd9-9e08-b49614c2a83c', 'VENEZUELAN_RESTAURANT'],
  ['05ba7e85-6fb1-401f-9d16-11ae5e33dbda', 'PET_STORE'],
  ['d460ccd3-9005-4af9-85ac-250fd9bb8aad', 'BROADCASTING_AND_MEDIA_PRODUCTION_COMPANY'],
  ['363afab6-719e-469c-9138-5a3ba611ba9a', 'CHARISMATIC_CHURCH'],
  ['c91fa4a0-7ad6-40dd-a301-9a0d9cfa4b9d', 'MAID_AND_BUTLER'],
  ['16b94264-de50-4d61-ae76-7acfa779c81d', 'OPTICIAN'],
  ['87f17229-a3ab-46e4-9873-d2758be2f230', 'TOBACCO_CESSATION_TREATMENT_CENTER'],
  ['fc03bad6-a2d1-40ee-a992-d560f21bc914', 'BIOTECHNOLOGY_COMPANY'],
  ['fbb46283-6a71-4d3f-817a-14001fbf0b13', 'ARCHERY_SHOP'],
  ['4ad86dd6-8927-4118-88e4-ec4d1e890fa0', 'FINANCIAL_SERVICE'],
  ['ebac862c-b5a4-4adf-ae3d-a1e070fabb24', 'VIDEO_GAME'],
  ['19fc41eb-73f5-4b0f-bd0e-f6e00eeb60ed', 'DESIGNATED_MARKET_AREA'],
  ['e53bf7c4-c98a-431c-8a6e-47f894cff0d3', 'BOROUGH'],
  ['d63766a9-5bfa-42fc-b78b-25c368f600bd', 'MATTRESS_MANUFACTURER'],
  ['1398513f-a563-4c4b-9075-a8f8e93ffc78', 'BABY_AND_CHILDREN_S_CLOTHING_STORE'],
  ['4a5127da-4a6d-4237-9231-ee7af4cc2a36', 'BOXING_STUDIO'],
  ['5bc49249-c138-421e-9110-29fe947f73f7', 'MOLISE_RESTAURANT'],
  ['27477c21-06a0-471a-89df-aaea54a95eb1', 'KOREAN_RESTAURANT'],
  ['2ecdfd72-67a9-4aeb-bf36-cacd83244b3d', 'ETHIOPIAN_RESTAURANT'],
  ['eea21e65-81ba-4cc4-a457-2acdafcc8ae3', 'ESCROW_SERVICE'],
  ['5049bb21-7752-4ba6-983d-27af62d9b192', 'HOTEL_AND_LODGING'],
  ['8ef5414a-70b8-4d0c-b476-b8e676990231', 'PERSONAL_COACH'],
  ['4f9f9f58-5acd-422f-af8d-84356c3a2a6c', 'PIZZA_PLACE'],
  ['066763b7-1f55-434d-a508-7ebf3ade8e3f', 'GUATEMALAN_RESTAURANT'],
  ['1153c376-6f51-4aee-9d85-102f5acd8924', 'AUTOMOTIVE_PARTS_STORE'],
  ['81a4a5df-5d64-4ff0-b376-77b7e26382a4', 'PERSIAN_IRANIAN_RESTAURANT'],
  ['3c513633-7711-44fc-af7e-5f93435a79dc', 'HOME_GOODS_STORE'],
  ['b8c99260-55dc-4494-830a-478e2324b965', 'BAIL_BONDSMEN'],
  ['2c0c7c3b-02cc-4112-933c-06442b9ecfa0', 'EVENT'],
  ['c169855c-d073-4b49-b975-56451ce837b8', 'MARINA'],
  ['4ad05be2-c6ad-468b-bf57-14c24c58d93c', 'SOLAR_ENERGY_COMPANY'],
  ['61c31235-bc08-4bfa-b3cb-9fa412335b30', 'BLOGGER'],
  ['47cb5b35-d9b1-4db0-b294-e46d09a00e3c', 'HOCKEY_FIELD_RINK'],
  ['72a0dc60-2930-4df0-81bf-64123bfa9b2f', 'MODELING_AGENCY'],
  ['e9e6f29b-d132-40f7-b5a2-5bb91060b026', 'COMMERCIAL_EQUIPMENT'],
  ['7b80e0d8-5cda-469a-a7c4-7ae7edb0aeee', 'HOT_SPRING'],
  ['dfde0787-3393-452b-8e51-9f80cc1f8906', 'JEWELRY_AND_WATCHES_STORE'],
  ['c3b8f099-aa57-4aeb-8f2e-0ff9fcd40321', 'EGYPTIAN_RESTAURANT'],
  ['fceb074a-b8e7-474d-9230-0121f73d4c9e', 'FAMILY_MEDICINE_PRACTICE'],
  ['a6ffe666-fe7b-45d3-b996-13b0832cc054', 'CYCLING_STUDIO'],
  ['f7e591fc-d058-4181-9d5f-a389badfe1b9', 'BROKERAGE_FIRM'],
  ['6e5bd815-cc43-479e-9de4-8f6023b197b0', 'SWABIAN_RESTAURANT'],
  ['64d92fe7-7f84-4373-a429-cc97fbefb341', 'SHAVED_ICE_SHOP'],
  ['fe855fcc-63af-4f9e-b416-e61c693462ee', 'SICILIAN_RESTAURANT'],
  ['269eac37-4365-486c-bcb8-9426e5b1f88f', 'PARAGUAYAN_RESTAURANT'],
  ['f6d82674-32b2-4c7e-a904-af14881ca3b7', 'APPLIANCES'],
  ['0ace6257-f318-4b57-b15b-5bb199d5cb42', 'STORAGE_FACILITY'],
  ['8349cc56-b33d-42db-9e9b-e5b64dd73a4c', 'HAT_STORE'],
  ['59d4ad5a-1c7a-4660-b20a-4a6f67c3eb0b', 'SATIRE_PARODY'],
  ['3b748202-708e-4d15-a971-70e016a9eca4', 'CAMERA_PHOTO'],
  ['2762dcca-0a8d-406c-b811-6c8ac9c76c4c', 'AEROSPACE_COMPANY'],
  ['221dbc3a-b6fc-44aa-81e0-b7f477e42cb5', 'SENIOR_CENTER'],
  ['9455161c-8377-45e2-bf70-d2034922b39e', 'BEAUTY_COSMETIC_AND_PERSONAL_CARE'],
  ['065666bb-46cc-441d-b59d-f943fd7d5b90', 'REFERENCE_WEBSITE'],
  ['dfe8b93a-6818-4fb5-b9d0-4a3e9e748f8d', 'AWADHI_RESTAURANT'],
  ['1f1f2280-47a6-454c-baf4-e83bc71e3bc7', 'AIRLINE_COMPANY'],
  ['04607370-68d6-43f5-8ac4-d41da43a074a', 'OTHER'],
  ['18f96dc7-18da-4b60-a213-1fde5d73ddaa', 'ROMANIAN_RESTAURANT'],
  ['765c2aa2-39d0-4d3d-a023-8ee7cb3a69a1', 'FILM_DIRECTOR'],
  ['b961144f-958b-4336-b24c-32b09e33e244', 'DOMINICAN_RESTAURANT'],
  ['2b163994-2b31-4306-8872-511d5a63c0cc', 'TELEVISION_REPAIR_SERVICE'],
  ['57641e6f-8cd8-4445-8c52-4b565ee09eb0', 'MUSIC'],
  ['7cdc1dc4-5097-4cd5-bbcc-4f145fe457ea', 'BLOOD_BANK'],
  ['2e63c6f0-7852-4f73-bfd2-2c96a6dbe616', 'PROFESSIONAL_SPORTS_TEAM'],
  ['997855f2-e984-4c46-89a6-e2948f0c09dd', 'SZECHUAN_SICHUAN_RESTAURANT'],
  ['964ab5d8-4e18-427c-aa70-c3e6da171e88', 'COFFEE_SHOP'],
  ['fe6be74f-4d7f-47fa-9429-55595c3c269c', 'KIDS_ENTERTAINMENT_SERVICE'],
  ['c2d1e645-09fd-4af3-8ecc-f5f3cafef8ef', 'TRANSIT_STOP'],
  ['a281a03e-ceb2-4f62-9be0-6e8faa44fb56', 'AOSTA_RESTAURANT'],
  ['8464ad9a-d3bd-4668-9b85-731338a20491', 'LINGERIE_AND_UNDERWEAR_STORE'],
  ['184d86ca-c8e7-4b4e-b25b-e61679eab0fc', 'AUDIO_VISUAL_EQUIPMENT_STORE'],
  ['edbbb75f-b345-425d-abbf-6b076ffade61', 'MOUNTAIN_BIKING_SHOP'],
  ['d75ac9e1-4924-447d-9422-7778c566c28f', 'PROFESSIONAL_SPORTS_LEAGUE'],
  ['b84ffd8c-acc3-40d9-bc58-9c46a1ab2373', 'MILITARY_LAWYER'],
  ['84e169c4-8af4-45a0-ae06-a901f84072db', 'DIAGNOSTIC_CENTER'],
  ['399205d7-1c54-4fe8-8501-8a5172cf78e7', 'ARCHAEOLOGICAL_SERVICE'],
  ['c1fe7ad7-72c8-4c71-b436-2049dcfceaa1', 'VIDEO_CREATOR'],
  ['b9d384a6-540e-443f-96d5-ebf3ed384772', 'MOTORCYCLE_MANUFACTURER'],
  ['e0e7e3e0-7cf9-4225-bf8b-4713df7a4ae1', 'RESTAURANT_WHOLESALER'],
  ['943d671f-f4b7-416b-abd2-511bc5b8b9de', 'PENTECOSTAL_CHURCH'],
  ['f52911e8-93ff-4865-a5d9-e384b080e924', 'IRISH_PUB'],
  ['4cb6f5ee-87e1-4454-afba-34218cdca514', 'DAMAGE_RESTORATION_SERVICE'],
  ['7b2a1b69-94b7-49c0-afbb-9ec4948efc60', 'MEDICAL_DEVICE_COMPANY'],
  ['83039723-679b-4a0a-a2a6-82b55d1afa13', 'BOTTLED_WATER_SUPPLIER'],
  ['315272d7-8bcd-4c9b-a5b7-2d5853355d07', 'VITAMINS_SUPPLEMENTS'],
  ['f5f34152-0e5a-4082-bd50-c34c146e4506', 'MIDDLE_EASTERN_RESTAURANT'],
  ['3b18b0f4-ca9a-4f94-b5ba-7a53f456f308', 'EMPLOYMENT_AGENCY'],
  ['f7b89b9f-aecd-4e13-80b1-32f480481cb6', 'CLEANING_SERVICE'],
  ['07eb12c6-c558-42c2-bbd3-3f60f6e47845', 'HEALTH_SPA'],
  ['f97d5113-ce0b-497e-9c71-2902a1ddf2d4', 'MEDICAL_CENTER'],
  ['b3e8a70c-d0aa-4d1f-a145-712d8c1ff6e0', 'FISHING_SPOT'],
  ['43ae7320-196e-4d6e-a846-30bf747ade6c', 'CHURCH_OF_CHRIST'],
  ['2285fa2d-557d-428c-8ec2-b90d4d10cae1', 'TOUR_GUIDE'],
  ['f2d96712-bec1-4c81-bdc4-b2c513f9b184', 'CONCERT_TOUR'],
  ['59bc288b-1359-498b-bd95-04cbf660badc', 'MOTOR_VEHICLE_COMPANY'],
  ['0e96ada0-06e5-4b60-9fc4-5b3fcf35e0da', 'GELATO_SHOP'],
  ['46ac9fbe-8bc8-4740-bc99-8de2d20a7ce0', 'PAWN_SHOP'],
  ['baa75bff-23b5-4123-b7f4-6befb1b226c9', 'DRY_CLEANER'],
  ['88d57d34-722d-4446-810b-ffd674c6e1d1', 'TRINIDADIAN_RESTAURANT'],
  ['657bc021-ddd8-44b4-b662-d4872e4ebabd', 'AMERICAN_RESTAURANT'],
  ['2dfc4f78-aa2a-4b7c-af40-cce8863e5b94', 'ELECTION'],
  ['5f4651b5-94a6-4b5b-91b0-1b1f2bc9a9c0', 'WHEEL_AND_RIM_REPAIR_SERVICE'],
  ['e368b942-9bc5-4488-afdb-5993e5025f0b', 'JET_SKI_RENTAL'],
  ['3884ad89-412a-4504-bccf-d942504aba59', 'GERMAN_RESTAURANT'],
  ['ca3ec1bf-b7c1-4af8-8ed1-3a9a8b0a959c', 'POLYNESIAN_RESTAURANT'],
  ['df22b7ea-099f-4613-a316-a93629c5cb06', 'INTERDENOMINATIONAL_CHURCH'],
  ['5e7ca016-d4dc-4a1c-bc46-06708911acfc', 'DERMATOLOGIST'],
  ['8fd41d0b-52cc-4ec2-8266-740a3d6729ca', 'LUMBER_YARD'],
  ['b259a2aa-10a5-4fbf-b1ab-099eedee0220', 'DONUT_SHOP'],
  ['0df28252-3e7f-42f2-84c3-a706bbc1f6e8', 'SCANDINAVIAN_RESTAURANT'],
  ['6aed9bd5-855f-4105-9d74-87b95afd8ae6', 'ISLAND'],
  ['774d06ec-b3a8-4624-b187-d76468b091ea', 'HOOKAH_LOUNGE'],
  ['a9924305-7b96-4c32-99fb-f1ac739d30f8', 'SHOOTING_HUNTING_RANGE'],
  ['c21b2318-f5e4-475c-b7e6-b11a46da70bf', 'CANAL'],
  ['de902e3d-710b-4542-9097-768d96b50928', 'HAIR_REPLACEMENT_SERVICE'],
  ['3d1ac0fe-7424-40bf-89fe-3807f97a4671', 'METHODIST_CHURCH'],
  ['85e93edc-70d0-487e-87a4-90b5ec2a7856', 'WATERFALL'],
  ['3627e7cf-1166-4763-a71b-1f580faaabe6', 'BIKE_RENTAL'],
  ['160e4405-f2b8-4a19-9969-380f65b0d755', 'AWNING_SUPPLIER'],
  ['951003fa-8d43-4122-a158-ce091fccb96d', 'INDO_CHINESE_RESTAURANT'],
  ['f083c46f-a698-4a9e-9059-aa4148b7935f', 'SPORTS_EVENT'],
  ['d71e20ee-ccd7-4f21-bffd-043e89eacd2a', 'BANK_EQUIPMENT_AND_SERVICE'],
  ['f5005c41-363c-417d-93b3-8b954820a717', 'MEDICAL_LAB'],
  ['f69ee7c3-37b9-46fc-bfe9-9679a7bd2d26', 'ANDHRA_RESTAURANT'],
  ['60f636ba-2264-4413-9f96-f40b88814234', 'RUSSIAN_RESTAURANT'],
  ['936164f9-b0e4-404d-b065-ea39c2f2920c', 'LIVERY_STABLE'],
  ['0b5fd240-c129-4134-a8ee-79d6952ccb45', 'MANADONESE_RESTAURANT'],
  ['1fece5c5-dd94-4aa2-8871-55eb0a3666fc', 'TEA_ROOM'],
  ['5fc54d08-2fad-4cba-b48c-0eac6f333ec8', 'MALAYSIAN_RESTAURANT'],
  ['c5c34b72-be28-40ac-9849-47d54d2043ea', 'ASIAN_RESTAURANT'],
  ['860ac2f9-ffa0-460b-b80b-d05786ffebd3', 'NOODLE_HOUSE'],
  ['c3120b41-c22d-428b-99cc-07b24fcc64ea', 'TOBACCO_STORE'],
  ['e10ffc1b-fd58-4b20-828f-cdfb460e7e84', 'PROVINCE'],
  ['5e901752-633b-4ead-9a36-a369fe51583b', 'JUNIOR_HIGH_SCHOOL'],
  ['af0c48e4-49e8-4ddc-a085-e4c1aa65bb05', 'ACTOR'],
  ['0846fce3-d57d-4f18-bdb1-ed28e3da1078', 'SPORTS_TEAM'],
  ['7ae49bea-996c-4cb5-bfed-998956b9b4d1', 'AUTOMOTIVE_CUSTOMIZATION_SHOP'],
  ['eaa4f29b-ad8b-4481-9c6b-90fbaafbce19', 'NEAPOLITAN_RESTAURANT'],
  ['6142f2cc-ee16-4599-8b46-2549fa71d52b', 'CARPET_AND_FLOORING_STORE'],
  ['12634f31-e1bb-4020-8fcf-493160c2a63d', 'ORTHODONTIST'],
  ['30a9bbbd-ca7f-48d9-8d4e-cc8831b0d087', 'BUILDING_MATERIALS'],
  ['c88b217c-d3b1-4a21-82b3-adb5e7955dc6', 'REFRIGERATION_SERVICE'],
  ['1e2d1419-3ac4-49b9-9047-f2c27e6bd6a5', 'COSTA_RICAN_RESTAURANT'],
  ['972f1665-f5a2-4881-a529-b99c7e4a0983', 'DRIVING_SCHOOL'],
  ['4ece499d-67d8-40e7-8909-c0a80f354301', 'MOVING_SUPPLY_STORE'],
  ['77b63562-5d00-4d8e-af32-1133ee4c4226', 'TOWNSHIP'],
  ['f8352c77-6af7-40c7-830b-e89cb28c2751', 'NANNY'],
  ['7d5fdf5d-8526-4e30-ae5b-a9157dafc1e2', 'GUIZHOU_RESTAURANT'],
  ['ea671a25-bd0c-4c7e-a815-1e4fe2907b22', 'SPANISH_RESTAURANT'],
  ['3a1b2b7c-c9d9-4d45-a8e6-75681e91ab99', 'TIME_ZONE'],
  ['c8676443-e2be-4240-ab15-2918d849cfdf', 'NURSING_AGENCY'],
  ['2975f281-300c-4bb9-923c-549846b23b1e', 'ACCESSORIES'],
  ['e271bbc1-7737-462a-b3a1-e9fcf4533a8c', 'AUTOMOTIVE_STORE'],
  ['5383794f-d0c5-471b-b5b1-ef38f17bbc3b', 'PUNJABI_RESTAURANT'],
  ['b533cd80-689e-44d6-afc9-9fe0a0e4e52f', 'STD_TESTING_CENTER'],
  ['46c9b2ee-4f9b-4a4b-a037-1208ca07f517', 'COMFORT_FOOD_RESTAURANT'],
  ['9eccb4ba-37aa-4af4-a78c-f38f272f3b19', 'WHOLESALE_BAKERY'],
  ['b066e3c1-f766-4cb1-babd-327ef2a62d70', 'PHOTOGRAPHER'],
  ['24ce9fc4-c173-4626-8d09-57c7e010c375', 'LABOR_AND_EMPLOYMENT_LAWYER'],
  ['07228238-d26f-4527-b2f7-3975ae35d442', 'LOTTERY_RETAILER'],
  ['c55da2a0-fc46-4edc-9e95-a1b197168e30', 'MOTIVATIONAL_SPEAKER'],
  ['5c700592-eb36-4335-a5aa-e05a84d45546', 'BAVARIAN_RESTAURANT'],
  ['68674b4a-8761-4662-8703-c53b33ab805e', 'PUBLIC_SERVICE'],
  ['140a35c0-2bef-4f39-9657-244f85873173', 'RHEUMATOLOGIST'],
  ['faede7b0-d3ba-44c8-a85d-c78a4316c167', 'CLOTHING_BRAND_'],
  ['97717c73-ea83-4a83-ac44-de27f24521ef', 'FRUIT_AND_VEGETABLE_STORE'],
  ['39d95906-341f-4af3-972b-b1bab4ecdbbe', 'FIRST_AID_CLASS'],
  ['a79d3e81-0d55-4f00-be05-239438b4b631', 'DESERT'],
  ['0af764df-93da-4ce4-b6a0-8c1a3d324bc3', 'NICARAGUAN_RESTAURANT'],
  ['7298a510-cee5-42ac-a9b8-30c5aecb915b', 'SIGNS_AND_BANNER_SERVICE'],
  ['8eeea91b-15f9-417f-9e13-7bc1c4e0bde5', 'GEO_ENTITY'],
  ['675b1214-c9d2-419d-a9e4-2edf64276ace', 'PERFORMANCE_ART'],
  ['345da47f-6dcb-46d1-af61-1218258cce98', 'DIVE_BAR'],
  ['4f5cbe2a-9ba4-4c72-9819-a8b265d18103', 'HOME_DECOR'],
  ['f6c9b6bb-9967-42e1-9031-89f795c91f6f', 'FISH_AND_CHIPS_RESTAURANT'],
  ['3b50c63b-e38f-40b8-9505-f189123a09b2', 'LAW_ENFORCEMENT_AGENCY'],
  ['bc065deb-7fa4-4567-a554-af6255c27ff1', 'PET_SERVICE'],
  ['2f6ae600-8d70-41cb-8176-6e2460339089', 'JEWELRY_WHOLESALER'],
  ['d3ac37e7-b6e3-4ffa-868f-dc00688bf547', 'KITEBOARDING_CENTER'],
  ['6b4631d7-cb17-4525-a7bc-5c61a40f787c', 'MASONRY_CONTRACTOR'],
  ['b37d1709-a7e8-4f49-a758-459a463fd8c4', 'TV_AND_MOVIES'],
  ['b02ea11b-f8d6-48e8-9f85-a6b961d6177d', 'ELECTRONICS_COMPANY'],
  ['8acbcb81-2c46-45fc-b449-70a1beed1001', 'WINDOW_INSTALLATION_SERVICE'],
  ['26d64006-3119-48e7-8ba6-1a645a237686', 'PLASTIC_COMPANY'],
  ['a14f4baf-b384-4e06-9e9f-acdbf578c2bd', 'FARMERS_MARKET'],
  ['03ddc4c1-3775-4a1b-a3bc-eb8885c6401e', 'MEDICAL_SUPPLY_STORE'],
  ['79faca6c-ad33-4301-bdcd-7bc0f5a27680', 'ROMAN_RESTAURANT'],
  ['eeb6f0cc-c4e4-45d2-9ee4-cb9aa769d471', 'ARTIST'],
  ['898af9de-3330-4e24-adfa-0eaece88d3db', 'SKI_AND_SNOWBOARD_SCHOOL'],
  ['03c60b93-299b-4368-967c-0b2fd3561fb4', 'AUTOMOTIVE_LEASING_SERVICE'],
  ['30959dca-afef-46b4-9575-fb2097adaac9', 'MOTEL'],
  ['dd9dc533-05c9-4d8a-a8f5-604c5f91d419', 'FITNESS_MODEL'],
  ['acec6f52-657a-482c-aaf3-b1141713ecc1', 'BADEN_RESTAURANT'],
  ['2493a8f6-0967-4f26-9c87-a6d7a1bb6607', 'DRIVING_RANGE'],
  ['e68762f3-066e-4b6d-8cba-b16112bcca10', 'PERSONAL_BLOG'],
  ['dbb38c7e-2fc6-4fc4-aec5-8204fe2999be', 'CAFETERIA'],
  ['fcceda2a-a6b8-4c90-a6bb-f7906feaf90a', 'BUSINESS_SERVICE'],
  ['30b58ec7-960e-4bde-98d1-9e7b2a90d332', 'AUTOMOTIVE_BODY_SHOP'],
  ['51abe707-69ca-459d-8879-bbe342e76b40', 'HOME_AND_GARDEN_WEBSITE'],
  ['e1965c60-b2ce-435d-975d-34f199be0c77', 'VENETIAN_RESTAURANT'],
  ['8f6dde79-e6d0-4bcc-960d-d1d6db4b12f8', 'METAL_SUPPLIER'],
  ['068fe280-e3b8-449d-8961-ddbcb1cdc999', 'CHURCH'],
  ['493d7838-51ab-4bd4-ae3e-e8e74d2f8ca3', 'TANNING_SALON'],
  ['d505cf08-47ca-4d0f-97ed-2adefb63adeb', 'COURSE'],
  ['3d1db657-5cf3-4a84-8957-ea985d876272', 'GEOLOGIC_SERVICE'],
  ['2e5cecd0-9416-42cc-9adf-fbebc21551ce', 'BEER_GARDEN'],
  ['3709b604-74d5-405f-beb5-1dd85467e1f5', 'STADIUM_ARENA_AND_SPORTS_VENUE'],
  ['19bd2fef-bd31-4608-90ae-6df27d1c19e3', 'TEST_PREPARATION_CENTER'],
  ['8dba3fb7-2acc-4b66-9cd2-e0dd1c8472de', 'OPTOMETRIST'],
  ['dbe4e9c8-996c-40d7-85a8-f48a46476c60', 'GAMER'],
  ['e855e8d4-9c27-43b2-b74f-e4ba255be66d', 'BOOK_SERIES'],
  ['99f737b7-f424-4193-aff2-38ecf646926f', 'CHEESE_SHOP'],
  ['7c99e6cf-53c1-491c-8384-846923b55a11', 'SKATE_SHOP'],
  ['6fcc803b-d3cc-4128-97d9-a664592db94b', 'SCHOOL'],
  ['3726cefe-0168-43ad-9b54-512e8f3f700a', 'MARCHE_RESTAURANT'],
  ['e1a6a96d-bb83-4351-a647-71ff71f943c4', 'JIANGXI_RESTAURANT'],
  ['6d43e774-6c8d-430b-82a1-4598de746e8f', 'SHAANXI_RESTAURANT'],
  ['7e93c34d-8f7c-4e35-817d-07431a3a159d', 'INTERGOVERNMENTAL_ORGANIZATION'],
  ['e30497b4-d4be-4de1-be32-ac93686970b4', 'FROZEN_YOGURT_SHOP'],
  ['fc29d015-34fe-4db2-9e01-20bbd482971f', 'ORTHOTICS_AND_PROSTHETICS_SERVICE'],
  ['4c483d5e-6369-487a-940b-1b93d4253ed1', 'CANADIAN_RESTAURANT'],
  ['f6ddabb6-58b7-4026-b1f8-a05d11996536', 'OUTDOOR_EQUIPMENT_STORE'],
  ['ec06d241-07e1-4b5e-8e58-1e8dc0dfcfe6', 'WORK_STATUS'],
  ['b1d77c5c-97ec-46b0-ad67-0e2c5798b44e', 'PRIVATE_MEMBERS_CLUB'],
  ['f78c3f6c-06af-49df-897b-a80daad0639f', 'APPLIANCE_REPAIR_SERVICE'],
  ['5724fd12-d70f-4cfe-bdd1-d917c76c3166', 'PHOTOGRAPHY_VIDEOGRAPHY'],
  ['08f4ad5b-be3f-4e2f-a18a-0993aebf376b', 'COMPUTERS_AND_INTERNET_WEBSITE'],
  ['1fe1f22d-add3-4f3c-9ee4-6c5ae9b063e5', 'PARTY_ENTERTAINMENT_SERVICE'],
  ['7ae93cc5-d5a8-4bf3-b929-827f8f36f609', 'SWIMMING_INSTRUCTOR'],
  ['1ee44eb0-0d59-4c78-ac04-cdbcda74bdbc', 'ENTERTAINMENT_LAWYER'],
  ['140c203f-837c-4338-bc5a-3ebb16e52b5a', 'VIETNAMESE_RESTAURANT'],
  ['25fbaa31-b5c6-4a3c-ac1f-f93c8d28b830', 'INTERNIST_INTERNAL_MEDICINE_'],
  ['10d9df06-7d37-4493-b7cd-8e72c039eee2', 'FOOD_DELIVERY_SERVICE'],
  ['4b003c8b-b69f-4bd3-afcc-6c0b6aa9170b', 'PERSONAL_ASSISTANT'],
  ['bda289dd-2fa5-4135-a67d-615dbd739cad', 'DIVING_SPOT'],
  ['2b5032f8-494d-42d1-af13-769cbb2bd9fb', 'BELARUSIAN_RESTAURANT'],
  ['3178a153-36c3-4240-9d16-6f1ba1207446', 'HORSE_TRAINER'],
  ['6c64a37d-024a-4997-a3f6-dbb382dbd88f', 'INVESTING_SERVICE'],
  ['6489c5f8-67da-4457-98f2-82ec484f46d4', 'COMMUNITY_ORGANIZATION'],
  ['4bc1317e-0ad6-484d-9a21-5222e975ab65', 'SEWER_SERVICE'],
  ['c5bc1fba-bbc7-461e-ba39-397a07879c73', 'DOSA_RESTAURANT'],
  ['5e459530-f315-4275-ae7d-15d6523be18e', 'CHETTINAD_RESTAURANT'],
  ['c028a076-78f9-4021-90f6-5192072434f1', 'HUNGARIAN_RESTAURANT'],
  ['40d060c8-4dfb-49bf-b7d1-6fcba791b5e3', 'AIRLINE_INDUSTRY_SERVICE'],
  ['9ac8e306-4d72-423f-b3b7-3765aae77ca7', 'CUBAN_RESTAURANT'],
  ['6303209a-2667-4331-a065-e1946b55ea29', 'FIREPLACE_STORE'],
  ['8bb0fca7-32ff-4922-ad63-b012a2b563f2', 'CANDY_STORE'],
  ['28b65492-e136-49c4-ad37-eb56047ddd89', 'ROCK_CLIMBING_SPOT'],
  ['652c158d-a2b9-480c-8181-f623a822d14d', 'TALENT_AGENT'],
  ['edf35de0-7953-11ee-9b05-fb976e81f20f', 'REPORT.NUDITY'],
  ['edf35de1-7953-11ee-9b05-fb976e81f20f', 'REPORT.VIOLENCE'],
  ['edf35de2-7953-11ee-9b05-fb976e81f20f', 'REPORT.HARASSMENT'],
  ['edf35de3-7953-11ee-9b05-fb976e81f20f', 'REPORT.ILLEGAL'],
  ['edf35de4-7953-11ee-9b05-fb976e81f20f', 'REPORT.SUICIDE_OR_SELF_INJURY'],
  ['09054c60-7954-11ee-9b05-fb976e81f20f', 'REPORT.SCAM'],
  ['09054c61-7954-11ee-9b05-fb976e81f20f', 'REPORT.FALSE_INFORMATION'],
  ['09054c62-7954-11ee-9b05-fb976e81f20f', 'REPORT.SPAM'],
  ['09054c63-7954-11ee-9b05-fb976e81f20f', 'REPORT.HATE_SPEECH'],
  ['29ae7c80-8399-11ee-8840-213f1d5d7192', 'REPORT.OTHER']
]);
