import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '@app/core/services/utility.service';
import { environment } from '@env/environment';

@Component({
  selector: 'post-content-preview',
  templateUrl: './post-content-preview.component.html',
  styleUrls: ['./post-content-preview.component.scss']
})
export class PostContentPreviewComponent implements OnInit {
  @Input() loading = false;
  @Input() isCreatingPost = false;
  @Input() commentUI = false;
  @Input() data: any;
  @Output() removePreview = new EventEmitter();
  domainLink = '';
  isImageLoaded = false;
  isImageFailed = false;
  isVerticalImg = false;

  constructor(
    private utilityService: UtilityService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.processPreviewData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['data']) {
      this.processPreviewData();
    }
  }

  processPreviewData(): void {
    if (this.data) {
      this.checkOrientation(this.getThumbnailURL(this.data.image));
      if (this.data.link) {
        this.domainLink = this.utilityService.extractDomain(this.data.link || '');
      }
    }
  }

  checkOrientation(url: string): Promise<boolean> {
    return new Promise(() => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.isVerticalImg = img.width <= img.height;
      };
      img.onerror = () => {
        this.isVerticalImg = true;
      };
    });
  }

  navigatePostOriginal() {
    window.open(this.data.link, '_blank');
  }

  getThumbnailURL(url: string): string {
    if (url?.indexOf('/storage/files/web/') !== -1) {
      return `${environment.baseURL}${url}`;
    }
    return this.commonService.getImageUrl(url);
  }

  onClickRemovePreview(url: string): void {
    this.removePreview.emit(url);
  }
}
