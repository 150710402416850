import { Component } from '@angular/core';

@Component({
  selector: 'system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss']
})
export class SystemNotificationComponent {

}
