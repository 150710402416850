import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ClaimedRewardModel,
  CoinSentModel,
  MyCoinModel,
  QuestModel,
  SentCoinToFriendModel
} from './../../../../../../lib/api/coin-exchange/api.coin.model';

export const CoinManagementActions = createActionGroup({
  source: 'Coin',
  events: {
    'On Get My Coin': emptyProps(),
    'On Get Quest': props<{ pageNum: number; pageSize: number }>(),
    'On Post Gift Coin': props<{ body: any }>(),
    'On Send Coin To User': props<{ body: any }>(),
    'On Claim Reward': props<{ questId: string; body: any }>()
  }
});
export const CoinManagementApiActions = createActionGroup({
  source: 'Coin Api',
  events: {
    'On Get My Coin Success': props<{
      data: MyCoinModel;
    }>(),
    'On Get My Coin Fail': props<{ message: string }>(),
    'On Get Quest Success': props<{
      data: QuestModel[];
    }>(),
    'On Get Quest Fail': props<{ message: string }>(),
    'On Post Gift Coin Success': props<{ data: CoinSentModel }>(),
    'On Post Gift Coin Fail': props<{ message: string }>(),
    'On Send Coin To User Success': props<{ data: SentCoinToFriendModel }>(),
    'On Send Coin To User Fail': props<{ message: string }>(),
    'On Claim Reward Success': props<{ data: ClaimedRewardModel }>(),
    'On Claim Reward Fail': props<{ message: any }>()
  }
});
