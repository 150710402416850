<div *ngIf="!loadingConfig.rounded && !peopleCardVertical && !loadingShareGroup && !isHistoryCoin && !rectSkeleton">
  <div class="flex">
    <div class="flex mb-2">
      <div class="mr-2">
        <p-skeleton size="4rem" borderRadius=""></p-skeleton>
      </div>
      <div class="flex items-center">
        <div class="flex flex-col">
          <p-skeleton width="8rem" height="1rem" class="mb-1"></p-skeleton>
          <p-skeleton width="4rem" height="0.75rem"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loadingConfig.rounded && !peopleCardVertical && !loadingShareGroup" class="flex flex-col">
  <div class="flex mb-2">
    <div class="mr-2">
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
    </div>
    <div class="flex items-center">
      <p-skeleton width="8rem" height="1rem" borderRadius="6px"></p-skeleton>
    </div>
  </div>
</div>

<div *ngIf="peopleCardVertical && !loadingShareGroup" class="flex flex-col">
  <div class="flex justify-center items-center flex-col gap-3 mb-2">
    <div class="mr-2">
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
    </div>
    <div class="flex justify-center items-center">
      <p-skeleton width="8.2rem" height="1rem" borderRadius="6px"></p-skeleton>
    </div>
    <div class="flex justify-center items-center">
      <p-skeleton width="4rem" height="1rem" borderRadius="6px"></p-skeleton>
    </div>
  </div>
</div>

<div *ngIf="isHistoryCoin">
  <div class="flex">
    <div class="flex mb-2">
      <div class="mr-2">
        <p-skeleton width="15rem" height="1rem" borderRadius=""></p-skeleton>
      </div>
      <div class="flex w-full items-center">
        <div class="flex w-full flex-col">
          <p-skeleton width="30rem" height="1rem" class="mb-1"></p-skeleton>
          <p-skeleton width="12rem" height="1rem"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="rectSkeleton">
  <p-skeleton [width]="rectSkeletonWidth" [height]="rectSkeletonHeight" [borderRadius]="rectSkeletonRounded"></p-skeleton>
</div>

