import { Component, ViewEncapsulation } from '@angular/core';
import { Language } from '@app/shared/components/language-setting/language-setting.component';
import { LANGUAGES } from '@app/shared/constant';
import { TranslationService } from '@app/core/services/translation.service';
import { AddressService } from '@app/core/services/address.service';

@Component({
  selector: 'select-language',
  templateUrl: './select-language.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent {
  optionLanguage: Language[] = LANGUAGES;
  selectedLanguage: Language;

  constructor(
    private addressService: AddressService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.initLanguageOptions();
  }

  initLanguageOptions() {
    const initLang = this.translationService.initAnonymousLanguage();
    this.selectedLanguage = LANGUAGES.find(language => language.value === initLang.toLowerCase()) || ({} as Language);
    this.addressService.getSupportedLanguages().subscribe(supportedList => {
      this.optionLanguage = LANGUAGES.filter(language => {
        return supportedList.some(supportedLanguage => supportedLanguage.language_code === language.value);
      });
    });
  }

  onChangeLanguage(language: Language): void {
    this.translationService.setAnonymousLanguage(language.value);
  }
}
