import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenContainerComponent } from '@app/modules/login/component/authen-container/authen-container.component';
import { SelectLanguageComponent } from '@app/modules/login/component/select-language/select-language.component';
import { ThirdPartySignUpModule } from '@app/modules/login/component/thirdparty-sign-up/thirdparty-sign-up.module';
import { SentRequestPopUpComponent } from '@app/modules/main/account-settings/account-settings-request-verification/sent-request-pop-up/sent-request-pop-up.component';
import { HostComponent } from '@app/modules/main/fanpage/components/fanpage-tabs/fanpage-events-tab/component-host/host-component.component';
import { EventDetailComponent } from '@app/modules/main/fanpage/components/fanpage-tabs/fanpage-events-tab/event-detail/event-detail.component';
import { ModalCreateEventComponent } from '@app/modules/main/fanpage/components/fanpage-tabs/fanpage-events-tab/modal-create-event/modal-create-event.component';
import { TimePickerComponent } from '@app/modules/main/fanpage/components/fanpage-tabs/fanpage-events-tab/time-picker/time-picker.component';
import { GroupEventsComponent } from '@app/modules/main/group/components/group-info/group-events/group-events.component';
import { EventsTabComponent } from '@app/modules/main/group/components/group-info/group-events/tab-events-upcoming/events-tab.component';
import { DialogConfirmComponent } from '@app/modules/main/group/components/group-tab/group-settings/components/dialog-confirm/dialog-confirm.component';
import { LimitImageBoxComponent } from '@app/modules/main/group/components/group-tab/group-settings/components/limit-image-box/limit-image-box.component';
import { MemberItemComponent } from '@app/modules/main/group/components/group-tab/group-settings/components/member-item/member-item.component';
import { PostItemComponent } from '@app/modules/main/group/components/group-tab/group-settings/components/post-item/post-item.component';
import { PostReportComponent } from '@app/modules/main/group/components/group-tab/group-settings/components/post-report/post-report.component';
import { GeneralInformationComponent } from '@app/modules/main/group/components/group-tab/group-settings/general-information/general-information.component';
import { GroupRulesComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-rules/group-rules.component';
import { GroupSettingsComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-settings.component';
import { GroupMemberStatsComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-statistics/group-member-stats/group-member-stats.component';
import { GroupPostListComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-statistics/group-post-list/group-post-list.component';
import { GroupStatisticInsightsComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-statistics/group-statistic-insights/group-statistic-insights.component';
import { GroupStatisticsComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-statistics/group-statistics.component';
import { GroupTopPostComponent } from '@app/modules/main/group/components/group-tab/group-settings/group-statistics/group-top-post/group-top-post.component';
import { MemberRequestsComponent } from '@app/modules/main/group/components/group-tab/group-settings/member-requests/member-requests.component';
import { GroupPendingPostsComponent } from '@app/modules/main/group/components/group-tab/group-settings/pending-posts/group-pending-posts.component';
import { ReportContentComponent } from '@app/modules/main/group/components/group-tab/group-settings/report-content/report-content.component';
import { ManageUserCardComponent } from '@app/modules/main/personal-profile/components/personal-profile-friends/manage-user-card/manage-user-card.component';
import { PersonalProfileNoFriendsComponent } from '@app/modules/main/personal-profile/components/personal-profile-friends/personal-profile-no-friends/personal-profile-no-friends.component';
import { AutoCompleteComponent } from '@app/modules/main/personal-profile/components/personal-profile-groups/components/auto-complete/auto-complete.component';
import { FilterPipes } from '@app/modules/main/personal-profile/components/personal-profile-groups/components/auto-complete/filter.pipe';
import { SearchNotFoundComponent } from '@app/modules/main/search/search-not-found/search-not-found.component';
import { followStatus } from '@app/modules/main/states/friend/friend.reducers';
import { ReportEffects } from '@app/modules/main/states/report/report.effects';
import { ReportReducer } from '@app/modules/main/states/report/report.reducers';
import { GalleriaComponent } from '@app/shared/components/galleria-component/galleria.component';
import { EditPrivacyComponent } from '@app/shared/components/popup-post/edit-privacy/edit-privacy.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PostContentComponent } from '@shared/components/post/post-content/post-content.component';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { DateFormatPipe, DatetimeFormatterModule } from '@soctrip-common/datetime-formatter';
import { ReportLibModule } from '@soctrip-common/report-popup';
import { MentionModule } from 'angular-mentions';
import { SpeedTestModule } from 'ng-speed-test';
import { ClipboardModule } from 'ngx-clipboard';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TreeSelectModule } from 'primeng/treeselect';
import { AdsBannerComponent } from './components/ads-banner/ads-banner.component';
import { AuthDialogComponent } from './components/auth-dialog/auth-dialog.component';
import { OtpInputComponent } from './components/auth-dialog/component/otp-input/otp-input.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeIconComponent } from './components/badge-icon/badge-icon.component';
import { BookMarkCategoryComponent } from './components/book-mark-categroy/book-mark-category.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CoinGiftingComponent } from './components/coin-gifting/coin-gifting-component';
import { CoinHistoryComponent } from './components/coin-history/coin-history.component';
import { CoinSendingComponent } from './components/coin-sending/coin-sending.component';
import { CommentEmojiComponent } from './components/comment-emoji/comment-emoji.component';
import { CommentFormComponent } from './components/comment-form/comment-form.component';
import { CommentContentComponent } from './components/comment-tree/comment-content/comment-content.component';
import { CommentTreeComponent } from './components/comment-tree/comment-tree.component';
import { CommentComponent } from './components/comment/comment.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ContentNotAvailableComponent } from './components/content-not-available/content-not-available.component';
import { CreateGroupFanpageComponent } from './components/create-group-fanpage/create-group-fanpage.component';
import { CreatePostDialogComponent } from './components/create-post-dialog/create-post-dialog.component';
import { ListThemeComponent } from './components/create-post-dialog/post-dialog/list-theme/list-theme.component';
import { PostDialogPollComponent } from './components/create-post-dialog/post-dialog/post-dialog-poll/post-dialog-poll.component';
import { CustomStatusComponent } from './components/create-post-dialog/post-dialog/post-dialog-tag-status/custom-status/custom-status.component';
import { PostDialogTagStatusComponent } from './components/create-post-dialog/post-dialog/post-dialog-tag-status/post-dialog-tag-status.component';
import { StatusIconsPipe } from './components/create-post-dialog/post-dialog/post-dialog-tag-status/status-icons.pipe';
import { PostDialogComponent } from './components/create-post-dialog/post-dialog/post-dialog.component';
import { PreviewContentLinkComponent } from './components/create-post-dialog/post-dialog/preview-content-link/preview-content-link.component';
import { TextareaPostComponent } from './components/create-post-dialog/post-dialog/textarea-post/textarea-post.component';
import { CustomVideoComponent } from './components/custom-video/custom-video.component';
import { CustomVideoDirective } from './components/custom-video/custom-video.directive';
import { CustomVideoService } from './components/custom-video/custom-video.service';
import { DialogListReactionComponent } from './components/dialog-list-reaction/dialog-list-reaction.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { TagComponent } from './components/discover/tag/tag.component';
import { EventInviteDialogComponent } from './components/event-invite-dialog/event-invite-dialog.component';
import { FriendActionButtonComponent } from './components/friend-action-button/friend-action-button.component';
import { HighlightsLibraryComponent } from './components/highlights-library/highlights-library.component';
import { IconPopupComponent } from './components/icon-popup/icon-popup.component';
import { InviteFriendComponent } from './components/invite-friend/invite-friend.component';
import { InviteFriendsDialogComponent } from './components/invite-friends-dialog/invite-friends-dialog.component';
import { LanguageSettingComponent } from './components/language-setting/language-setting.component';
import { LoadingSkeletonComponent } from './components/loading-skeleton/loading-skeleton.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ManageCoinsDialogComponent } from './components/manage-coins-dialog/manage-coins-dialog.component';
import { CoinEffects } from './components/manage-coins-dialog/store/effect';
import { CoinReducer } from './components/manage-coins-dialog/store/reducer';
import { MediaComponent } from './components/media/media.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OtpFormComponent } from './components/otp-form/otp-form.component';
import { OtpTwofaFormComponent } from './components/otp-twofa-form/otp-twofa-form.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { LoadingPeopleCardComponent } from './components/people-card/loading-people-card/loading-people-card.component';
import { PeopleCardComponent } from './components/people-card/people-card.component';
import { PeopleYouMayKnowComponent } from './components/people-you-may-know/people-you-may-know.component';
import { PhotoViewComponent } from './components/photo-view/photo-view.component';

import { DownloadAppDialogComponent } from '@app/shared/components/download-app-dialog/download-app-dialog.component';
import player from 'lottie-web';
import { LottieModule, provideLottieOptions } from 'ngx-lottie';
import { PostDialogPollAnswersComponent } from './components/create-post-dialog/post-dialog/post-dialog-poll/post-dialog-poll-answers/post-dialog-poll-answers.component';
import { PostDialogReducer } from './components/create-post-dialog/store/post-dialog.reducers';
import { DownloadAppPopupComponent } from './components/download-app-popup/download-app-popup.component';
import { PopupLoginComponent } from './components/popup-login/popup-login.component';
import { PostContentLinkComponent } from './components/post/post-content/post-content-link/post-content-link.component';
import { PostContentPollQrCodeComponent } from './components/post/post-content/post-content-poll-qr-code/post-content-poll-qr-code.component';
import { PollDetailsDialogComponent } from './components/post/post-content/post-content-poll/poll-details-dialog/poll-details-dialog.component';
import { PostContentPollLeaderComponent } from './components/post/post-content/post-content-poll/post-content-poll-leader/post-content-poll-leader.component';
import { PostContentPollComponent } from './components/post/post-content/post-content-poll/post-content-poll.component';
import { PostContentPreviewComponent } from './components/post/post-content/post-content-preview/post-content-preview.component';
import { PostFooterComponent } from './components/post/post-footer/post-footer.component';
import { PostHeaderComponent } from './components/post/post-header/post-header.component';
import { PostComponent } from './components/post/post.component';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { ProgressPostComponent } from './components/progress-post/progress-post.component';
import { ProgressToastComponent } from './components/progress-toast/progress-toast.component';
import { QuickAccessPopupComponent } from './components/quick-access-popup/quick-access-popup.component';
import { QuickAccessComponent } from './components/quick-access/quick-access.component';
import { QuickSummaryPopupComponent } from './components/quick-summary-popup/quick-summary-popup.component';
import { ReviewPostFooterComponent } from './components/review-pending-posts/components/review-post-footer/review-post-footer.component';
import { ReviewPendingPostsComponent } from './components/review-pending-posts/review-pending-posts.component';
import { ShareDialogComponent } from './components/share-dialog/share-dialog.component';
import { ShareOnPlatformsComponent } from './components/share-on-platforms/share-on-platforms.component';
import { AgeGenderChartComponent } from './components/statistics/components/age-gender-chart/age-gender-chart.component';
import { CompareDateRangesComponent } from './components/statistics/components/compare-date-ranges/compare-date-ranges.component';
import { DateRangeCalenderComponent } from './components/statistics/components/date-range-calender/date-range-calender.component';
import { LineChartComponent } from './components/statistics/components/line-chart/line-chart.component';
import { LocationChartComponent } from './components/statistics/components/location-chart/location-chart.component';
import { StatisticsOverviewComponent } from './components/statistics/components/statistics-overview/statistics-overview.component';
import { StatsPostListComponent } from './components/statistics/components/stats-post-list/stats-post-list.component';
import { TopPerformingPostComponent } from './components/statistics/components/top-performing-post/top-performing-post.component';
import { DialogViewersComponent } from './components/stories/dialog-viewers/dialog-viewers.component';
import { SideStoriesComponent } from './components/stories/side-stories/side-stories.component';
import { StoriesComponent } from './components/stories/stories.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { TransferOwnerDialogComponent } from './components/transfer-owner-dialog/transfer-owner-dialog.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CheckAuthenticationDirective } from './directives/check-authentication.directive';
import { DefaultImageDirective } from './directives/default-image.directive';
import { OtpPasteDirective } from './directives/otp-paste.directive';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { ScrollDirective } from './directives/scroll.directive';
import { SeoInfoDirective } from './directives/seo-info.directive';
import { AbbreviateNamePipe } from './pipes/abbreviate-name.pipe';
import { BytesConversionPipe } from './pipes/bytes-conversion.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CountdownFormatPipe } from './pipes/countdown-format.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { CustomDateFormatter } from './pipes/date-formatter.pipe';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { EpochConverterPipe } from './pipes/epoch-converter.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GetDatePipe } from './pipes/getdatetime.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { RandomNumberInRangePipe } from './pipes/random-number-in-range.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { RoundDecimalNumberPipe } from './pipes/rounded-decimal-number.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { SortByInteractionsPipe } from './pipes/sort-by-interactions.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { FullNameFormatPipe } from './pipes/userFullName.pipe';
import { SystemNotificationComponent } from './system-notification/system-notification.component';

const sharedComponents = [
  PeopleYouMayKnowComponent,
  PostComponent,
  PostHeaderComponent,
  SearchNotFoundComponent,
  PostContentComponent,
  PostFooterComponent,
  IconPopupComponent,
  AvatarComponent,
  QuickAccessComponent,
  DiscoverComponent,
  TagComponent,
  UserProfileComponent,
  CommentComponent,
  CommentFormComponent,
  CoinGiftingComponent,
  CoinSendingComponent,
  PostDialogComponent,
  CreatePostDialogComponent,
  AuthenContainerComponent,
  GroupSettingsComponent,
  GroupPendingPostsComponent,
  PostItemComponent,
  DialogConfirmComponent,
  LimitImageBoxComponent,
  CoinSendingComponent,
  ShareDialogComponent,
  CommentTreeComponent,
  MemberRequestsComponent,
  ModalComponent,
  BadgeIconComponent,
  LoadingSkeletonComponent,
  MemberItemComponent,
  CommentContentComponent,
  ReportContentComponent,
  PostReportComponent,
  CustomVideoComponent,
  ToastMessageComponent,
  GeneralInformationComponent,
  NotificationsComponent,
  GroupRulesComponent,
  HighlightsLibraryComponent,
  GroupEventsComponent,
  EventsTabComponent,
  ModalCreateEventComponent,
  HostComponent,
  AutoCompleteComponent,
  HostComponent,
  ProgressPostComponent,
  EventDetailComponent,
  StoriesComponent,
  DialogViewersComponent,
  GalleriaComponent,
  TimePickerComponent,
  MediaComponent,
  LanguageSettingComponent,
  ConfirmDialogComponent,
  QuickSummaryPopupComponent,
  PostContentLinkComponent,
  PostContentPreviewComponent,
  EditPrivacyComponent,
  OtpFormComponent,
  InviteFriendsDialogComponent,
  CalendarComponent,
  StatisticsOverviewComponent,
  GroupStatisticsComponent,
  GroupStatisticInsightsComponent,
  LineChartComponent,
  DateRangeCalenderComponent,
  CompareDateRangesComponent,
  TopPerformingPostComponent,
  GroupTopPostComponent,
  StatsPostListComponent,
  GroupPostListComponent,
  AgeGenderChartComponent,
  GroupMemberStatsComponent,
  LocationChartComponent,
  PaginatorComponent,
  ReviewPendingPostsComponent,
  ReviewPostFooterComponent,
  DialogListReactionComponent,
  AuthDialogComponent,
  ContentNotAvailableComponent,
  OtpInputComponent,
  TransferOwnerDialogComponent,
  QuickAccessPopupComponent,
  PeopleCardComponent,
  LoadingSpinnerComponent,
  FriendActionButtonComponent,
  AdsBannerComponent,
  ManageUserCardComponent,
  OtpTwofaFormComponent,
  ProgressToastComponent,
  PreviewContentLinkComponent,
  ProfileAvatarComponent,
  ShareOnPlatformsComponent,
  PopupLoginComponent,
  PhotoViewComponent,
  ShareOnPlatformsComponent,
  SentRequestPopUpComponent,
  PersonalProfileNoFriendsComponent,
  SystemNotificationComponent,
  CoinHistoryComponent,
  SelectLanguageComponent,
  CreateGroupFanpageComponent,
  EventInviteDialogComponent,
  PollDetailsDialogComponent,
  PostContentPollComponent,
  ManageCoinsDialogComponent,
  DownloadAppDialogComponent,
  DownloadAppPopupComponent
];

const sharedDirectives = [
  OutsideClickDirective,
  InviteFriendComponent,
  OtpPasteDirective,
  ScrollDirective,
  CustomVideoDirective,
  DefaultImageDirective,
  CheckAuthenticationDirective,
  SeoInfoDirective
];
const sharedPipes = [
  CountdownFormatPipe,
  TruncateStringPipe,
  RelativeTimePipe,
  CustomDatePipe,
  RelativeTimePipe,
  NumberFormatPipe,
  SortByInteractionsPipe,
  SortPipe,
  SlugifyPipe,
  BytesConversionPipe,
  FilterPipe,
  FullNameFormatPipe,
  FilterPipes,
  PhonePipe,
  CapitalizePipe,
  StatusIconsPipe,
  ShortNumberPipe,
  EpochConverterPipe,
  DurationFormatPipe,
  AbbreviateNamePipe,
  CustomDateFormatter,
  GetDatePipe,
  RoundDecimalNumberPipe
];
const commonModules = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, ReportLibModule];
const primeNgModules = [
  AccordionModule,
  MenubarModule,
  ButtonModule,
  CalendarModule,
  TableModule,
  TabMenuModule,
  TabViewModule,
  MenuModule,
  DropdownModule,
  CardModule,
  InputTextModule,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  DynamicDialogModule,
  DropdownModule,
  GalleriaModule,
  InputTextareaModule,
  CardModule,
  DividerModule,
  CarouselModule,
  DialogModule,
  SliderModule,
  ConfirmPopupModule,
  PasswordModule,
  ProgressSpinnerModule,
  ToastModule,
  PasswordModule,
  FileUploadModule,
  OverlayModule,
  ListboxModule,
  SidebarModule,
  SkeletonModule,
  ChipModule,
  AvatarGroupModule,
  AvatarModule,
  AutoCompleteModule,
  EditorModule,
  OverlayPanelModule,
  PanelMenuModule,
  MultiSelectModule,
  InputSwitchModule,
  BreadcrumbModule,
  RatingModule,
  ConfirmDialogModule,
  MentionModule,
  SpeedTestModule,
  BadgeModule,
  ScrollPanelModule,
  TreeSelectModule,
  PickerModule,
  DragDropModule,
  ChartModule,
  PaginatorModule,
  ClipboardModule,
  ThirdPartySignUpModule,
  TimelineModule
];

const ngrx = [
  StoreModule.forFeature('unFollowStatus', followStatus),
  StoreModule.forFeature('report', ReportReducer),
  StoreModule.forFeature('coinManagement', CoinReducer),
  StoreModule.forFeature('postDialog', PostDialogReducer),
  EffectsModule.forFeature([ReportEffects, CoinEffects])
];

@NgModule({
  declarations: [
    sharedComponents,
    sharedDirectives,
    sharedPipes,
    ToastMessageComponent,
    PostDialogTagStatusComponent,
    CommentEmojiComponent,
    BookMarkCategoryComponent,
    TextareaPostComponent,
    SideStoriesComponent,
    CustomStatusComponent,
    ListThemeComponent,
    LoadingPeopleCardComponent,
    RandomNumberInRangePipe,
    PostDialogPollComponent,
    PostContentPollLeaderComponent,
    PostContentPollQrCodeComponent,
    PostDialogPollAnswersComponent
  ],
  imports: [commonModules, primeNgModules, ngrx, CurrencyConversionPipe, DatetimeFormatterModule, LottieModule],
  providers: [
    ConfirmationService,
    CustomVideoService,
    DatePipe,
    DateFormatPipe,
    GetDatePipe,
    provideLottieOptions({
      player: () => player
    })
  ],
  exports: [commonModules, primeNgModules, sharedComponents, sharedDirectives, sharedPipes]
})
export class SharedModule {}
