import { Collection } from '@app/lib/api/bookmark/api.bookmark.model';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { PostRequest } from '@app/lib/api/post/api.post.model';
import { Post } from '@app/shared/models/post';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const NewsFeedPostsActions = createActionGroup({
  source: 'News Feed Posts',
  events: {
    'On Load News Feed Posts': props<{ pageNum: number; pageSize: number }>(),
    'On Load Feeds Engine Posts': props<{ pageNum: number; pageSize: number; isVideo?: boolean }>(),
    'On Create News Feed Post': props<{ newsFeed: PostRequest; isShare: boolean; sharingPost?: Post }>(),
    'On Delete News Feed Post': props<{ postId: string; index: number }>(),
    'On Get Bookmark Bloom Filter': emptyProps(),
    'On Get Follow User Bloom Filter': emptyProps(),
    'On Get Follow Fanpage Bloom Filter': emptyProps(),
    'On Get List Friend Bloom Filter': props<{ userId: string }>(),
    'On Reset News Feed': emptyProps(),
    'On Get Bookmark Collection': props<{ pageNum: number; pageSize: number }>(),
    'On Load More Bookmark Collection': props<{ pageNum: number; pageSize: number }>(),
    'On Archived News Feed Post': props<{ postId: string; isArchived: boolean; index: number }>(),
    'On Get Comment List': props<{ postIdList: string[] }>(),
    'On Hide Post': props<{ postId: string }>()
  }
});

export const NewsFeedPostsApiActions = createActionGroup({
  source: 'News Feed Posts Api',
  events: {
    'On Load News Feed Posts Success': props<{ newsFeeds: Post[]; isOutOfFeedsEngine: boolean }>(),
    'On Load News Feed Posts Fail': props<{ message: string }>(),
    'On Create News Feed Post Success': props<{ message: string; newsFeeds: Post[] }>(),
    'On Upload And Create Post Success': props<{ post: Post }>(),
    'On Create News Feed Post Fail': props<{ message: string }>(),
    'On Delete News Feed Posts Success': props<{ index: number }>(),
    'On Delete News Feed Posts Fail': props<{ message: string }>(),
    'On Reset Delete News Feed Posts': emptyProps(),
    'On Get Bookmark Bloom Filter Success': props<{ bloomFilter: string }>(),
    'On Get Bookmark Bloom Filter Fail': props<{ message: string }>(),
    'On Get Follow User Bloom Filter Success': props<{ followUserBloomFilter: string }>(),
    'On Get Follow User Bloom Filter Fail': props<{ message: string }>(),
    'On Get Follow Fanpage Bloom Filter Success': props<{ followFanpageBloomFilter: string }>(),
    'On Get Follow Fanpage Bloom Filter Fail': props<{ message: string }>(),
    'On Get List Friend Bloom Filter Success': props<{ listFriendBloomFilter: string }>(),
    'On Get List Friend Bloom Filter Fail': props<{ message: string }>(),
    'On Get Bookmark Collection Success': props<{ bookMarkCollection: Collection[] }>(),
    'On Get Bookmark Collection Fail': props<{ message: string }>(),
    'On Archived News Feed Posts Success': props<{ index: number }>(),
    'On Archived News Feed Posts Fail': props<{ message: string }>(),
    'On Load More Bookmark Collection Success': props<{ loadMoreBookMarkCollection: any }>(),
    'On Get Comment List Success': props<{ commentList: Comment[] }>()
  }
});
