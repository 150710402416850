import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { PixelAnalyticsService } from '@app/core/services/pixel-analytics.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { UserAPI } from '@app/lib/api/user/api.user.model';
import { SIGNIN_METHOD, USER_STATUS } from '@app/shared';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'thirdparty-sign-up',
  templateUrl: './thirdparty-sign-up.component.html',
  styleUrls: ['./thirdparty-sign-up.component.scss']
})
export class ThirdPartySignUpComponent implements OnInit {
  @Output() moveToVerificationChanged = new EventEmitter();
  @Output() userInformation = new EventEmitter();
  registrationId: string;
  credentials: AuthAPI.SignIn;
  userProfile: string;
  userStatus: string;
  deviceInfo: AuthAPI.DeviceInfo;

  constructor(
    private socAuthenService: SocialAuthService,
    private readonly userService: UserService,
    private translateService: TranslateService,
    private router: Router,
    private readonly authService: AuthService,
    private pixelAnalyticsService: PixelAnalyticsService
  ) { }

  ngOnInit(): void {
    this.resetInforInit();
    this.authService.getDeviceInfo().subscribe(data => {
      this.deviceInfo = {
        ...data,
        device_type: 'W'
      };
    });
    this.authService.detectWindows11().subscribe(data => {
      if (data) {
        this.deviceInfo.device = data;
      }
    });
    this.subcribeAuthen();
  }

  isBeingLogin: boolean = false;
  user: UserAPI.SocialUser;
  errorMessage = '';

  subcribeAuthen(): void {
    this.socAuthenService.authState.subscribe((user: any) => {
      if (user != undefined && user != null) {
        user = {
          ...user,
          ...this.deviceInfo
        };
        this.user = user;
        this.onLoginSubmit();
      }
    });
  }

  facebookSignin() {
    this.socAuthenService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  onLoginSubmit(): void {
    this.isBeingLogin = true;
    this.userService.thirdPartySignUp({ ...this.user, language: this.translateService.currentLang }).subscribe(
      response => {
        if (response.success) {
          const isEnabledAuth = response.data?.user?.is_enabled_auth;
          this.userStatus = response.data?.user?.status;
          this.userProfile = JSON.stringify(response.data.user);
          this.credentials.password = '';

          localStorage.setItem(environment.TOKEN_KEY, JSON.stringify({ accessToken: response.data.access_token }));
          const deviceSignInInfo: AuthAPI.DeviceInfo = {
            device_id: response?.data?.device_id || '',
            device: response?.data?.device || '',
            device_type: response?.data?.device_type || '',
            city: response?.data?.city || '',
            country: response?.data?.country || '',
            city_code: this.deviceInfo?.city_code || '',
            regions: this.deviceInfo?.regions || '',
            country_code: this.deviceInfo?.country_code || '',
            currency: this.deviceInfo?.currency || '',
            language_code: this.deviceInfo?.language_code || ''
          };
          localStorage.setItem(
            environment.REFRESH_TOKEN_KEY,
            JSON.stringify({ refreshToken: response.data.refresh_token })
          );
          localStorage.setItem('device_sign_in', JSON.stringify(deviceSignInInfo || {}));
          localStorage.setItem('signin_method', SIGNIN_METHOD.GOOGLE);

          if (isEnabledAuth) {
            this.moveToVerificationChanged.emit(true);
            this.userInformation.emit({
              credentials: this.credentials,
              userProfile: this.userProfile,
              userStatus: this.userStatus
            });
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
          } else {
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
            this.loginSuccess();
          }
        } else {
          this.errorMessage = response.error.message;
        }
      },
      error => {
        if (error.hasOwnProperty('error')) {
          this.errorMessage = error.error.message;
        }
        localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
      }
    );
    this.pixelAnalyticsService.onSignIn('Google');
  }

  loginSuccess() {
    let returnUrl = this.authService.getReturnUrl();
    localStorage.setItem(
      'is_scheduled_deleted',
      JSON.stringify({ is_scheduled_deleted: this.userStatus === USER_STATUS.SCHEDULED_DELETED })
    );
    localStorage.setItem('credentials', JSON.stringify(this.credentials));
    localStorage.setItem(environment.USER_PROFILE, this.userProfile);
    if (returnUrl != '') window.location.href = returnUrl;
    else this.router.navigate(['/']);
  }

  resetInforInit() {
    this.userStatus = '';
    this.userProfile = '';
    this.credentials = {
      phone_or_email: '',
      password: '',
      area_code: '',
      language: localStorage.getItem('unauthLang') || '',
      device: ''
    };
  }
}
