<div class="flex flex-col gap-2 md:gap-4 bg-palette-gray-200 md:bg-transparent">
  <ng-container *ngTemplateOutlet="statisticOverview"> </ng-container>
  <ng-container *ngTemplateOutlet="statisticInsights"> </ng-container>
  <ng-container *ngTemplateOutlet="statisticMembers"> </ng-container>
  <ng-container *ngTemplateOutlet="topPerformingPost"> </ng-container>
  <ng-container *ngTemplateOutlet="postList"> </ng-container>
</div>

<ng-template #statisticOverview>
  <div class="statistic-card border-b border-x-0 border-t-0 md:border p-4">
    <statistics-overview [totalsOverviewData]="totalsOverviewData"></statistics-overview>
  </div>
</ng-template>

<ng-template #statisticInsights>
  <div class="statistic-card border-y border-x-0 md:border p-4">
    <group-statistic-insights [groupId]="groupId"></group-statistic-insights>
  </div>
</ng-template>

<ng-template #statisticMembers>
  <div class="statistic-card border-y border-x-0 md:border p-4">
    <group-member-stats [groupId]="groupId"></group-member-stats>
  </div>
</ng-template>

<ng-template #topPerformingPost>
  <div class="statistic-card border-y border-x-0 md:border p-4">
    <group-top-post [groupId]="groupId"></group-top-post>
  </div>
</ng-template>

<ng-template #postList>
  <div class="statistic-card border-b-0 border-x-0 border-t md:border">
    <group-post-list [groupId]="groupId"></group-post-list>
  </div>
</ng-template>
