import { HttpClient } from '@angular/common/http';
import { MediaApi } from '@app/lib/api/media/api.media';
import { StorageApi } from '@app/lib/api/storage/api.storage';
import { AccountSettingNotificationsApi } from './account-setting-notifications/api.account-setting-notifications';
import { ActivityLogApi } from './activity-log/api.activity-log';
import { AddressApi } from './address/api.address';
import { AdvertisingApi } from './advertising/api.advertising';
import { ApiClientConfig } from './api-client.config';
import { AuthApi } from './auth/api.auth';
import { BillingApi } from './billing/api.billing';
import { BookmarkApi } from './bookmark/api.bookmark';
import { BusinessAccountApi } from './business-account/api.business-account';
import { CheckInApi } from './check-in/api.check-in';
import { CoinApi } from './coin-exchange/api.coin';
import { CommentsApi } from './comments/api.comments';
import { EventApi } from './event/api.event';
import { FeedsEngineApi } from './feeds-engine/api.feed-engine';
import { FollowerApi } from './follower/api.follower';
import { FriendApi } from './friend/api.friend';
import { GroupApi } from './group/api.group';
import { HashtagApi } from './hashtag/api.hashtag';
import { MapLocationApi } from './map-location/api.map-location';
import { PageApi } from './page/api.page';
import { PollApi } from './poll/api.poll';
import { PostApi } from './post/api.post';
import { ThemeApi } from './post/theme/api.theme';
import { ReactionsApi } from './reactions/api.reactions';
import { ReportApi } from './report/api.report';
import { SearchApi } from './search/api.search';
import { ShareApi } from './share/api.share';
import { StatisticsApi } from './statistics/api.statistics';
import { StockApi } from './stocks/api.stocks';
import { StoriesApi } from './stories/api.stories';
import { SystemApi } from './system/api.system';
import { TourBuilderApi } from './tour-builder/api.tour-builder';
import { TranslateApi } from './translate/api.translate';
import { UserBehaviorApi } from './user-behavior/api.user-behavior';
import { UserProfileApi } from './user-profile/api.user-profile';
import { UserApi } from './user/api.user';
import { VerificationRequestApi } from './verification-request/api.verification-request';
import { WalletApi } from './wallet/api.wallet';

export class ApiClient {
  readonly auth: AuthApi;
  readonly advertising: AdvertisingApi;
  readonly activityLog: ActivityLogApi;
  readonly user: UserApi;
  readonly media: MediaApi;
  readonly storage: StorageApi;
  readonly share: ShareApi;
  readonly group: GroupApi;
  readonly report: ReportApi;
  readonly stories: StoriesApi;
  readonly feedsEngine: FeedsEngineApi;
  readonly search: SearchApi;
  readonly userProfile: UserProfileApi;
  readonly post: PostApi;
  readonly comments: CommentsApi;
  readonly reactions: ReactionsApi;
  readonly follower: FollowerApi;
  readonly page: PageApi;
  readonly event: EventApi;
  readonly friend: FriendApi;
  readonly accountSettingNotifications: AccountSettingNotificationsApi;
  readonly bookmark: BookmarkApi;
  readonly checkIn: CheckInApi;
  readonly mapLocation: MapLocationApi;
  readonly statistics: StatisticsApi;
  readonly verificationRequest: VerificationRequestApi;
  readonly addressApi: AddressApi;
  readonly tourBuilder: TourBuilderApi;
  readonly stock: StockApi;
  readonly userBehavior: UserBehaviorApi;
  readonly theme: ThemeApi;
  readonly translate: TranslateApi;
  readonly businessAccount: BusinessAccountApi;
  readonly coinManagement: CoinApi;
  readonly wallet: WalletApi;
  readonly billing: BillingApi;
  readonly hashtag: HashtagApi;
  readonly system: SystemApi;
  readonly poll: PollApi;

  constructor(
    private http: HttpClient,
    config: ApiClientConfig
  ) {
    this.auth = new AuthApi(this.http, config);
    this.advertising = new AdvertisingApi(this.http, config);
    this.user = new UserApi(this.http, config);
    this.media = new MediaApi(this.http, config);
    this.storage = new StorageApi(this.http, config);
    this.share = new ShareApi(this.http, config);
    this.group = new GroupApi(this.http, config);
    this.report = new ReportApi(this.http);
    this.stories = new StoriesApi(this.http, config);
    this.feedsEngine = new FeedsEngineApi(this.http);
    this.search = new SearchApi(this.http, config);
    this.userProfile = new UserProfileApi(this.http, config);
    this.post = new PostApi(this.http, config);
    this.comments = new CommentsApi(this.http, config);
    this.reactions = new ReactionsApi(this.http, config);
    this.follower = new FollowerApi(this.http, config);
    this.page = new PageApi(this.http, config);
    this.event = new EventApi(this.http, config);
    this.friend = new FriendApi(this.http, config);
    this.accountSettingNotifications = new AccountSettingNotificationsApi(this.http, config);
    this.bookmark = new BookmarkApi(this.http, config);
    this.checkIn = new CheckInApi(this.http, config);
    this.mapLocation = new MapLocationApi(this.http, config);
    this.statistics = new StatisticsApi(this.http, config);
    this.verificationRequest = new VerificationRequestApi(this.http, config);
    this.addressApi = new AddressApi(this.http, config);
    this.tourBuilder = new TourBuilderApi(this.http, config);
    this.stock = new StockApi(this.http, config);
    this.userBehavior = new UserBehaviorApi(this.http, config);
    this.theme = new ThemeApi(this.http, config);
    this.translate = new TranslateApi(this.http, config);
    this.businessAccount = new BusinessAccountApi(this.http, config);
    this.coinManagement = new CoinApi(this.http, config);
    this.wallet = new WalletApi(this.http, config);
    this.activityLog = new ActivityLogApi(this.http, config);
    this.billing = new BillingApi(this.http, config);
    this.hashtag = new HashtagApi(this.http, config);
    this.system = new SystemApi(this.http, config);
    this.poll = new PollApi(this.http, config);
  }
}
