<div class="flex flex-col gap-2">
  <h1 class="text-sm md:text-base font-semibold text-palette-gray-700">
    {{ 'STATISTIC.LOCATION.TITLE' | translate }}
  </h1>
  <div *ngIf="!isLoading; else loadingState" class="flex flex-col w-full">
    <ng-container *ngFor="let location of locationData; last as last; index as index">
      <ng-container
        *ngIf="index < maxDisplayedItems || isViewFullList"
        [ngTemplateOutlet]="locationItem"
        [ngTemplateOutletContext]="{
          location: location,
          isShowBorder: !last || (!isViewFullList && locationData.length > maxDisplayedItems)
        }"></ng-container>
    </ng-container>
  </div>
  <span
    *ngIf="locationData.length > maxDisplayedItems"
    (click)="isViewFullList = !isViewFullList"
    class="cursor-pointer text-sm font-semibold {{
      isViewFullList ? 'text-palette-gray-600' : 'text-palette-blue-600'
    }} text-palette-blue-600 h-7 pt-[6px]"
    >{{ 'COMMON.' + (isViewFullList ? 'VIEW_LESS' : 'VIEW_MORE') | translate }}</span
  >
</div>

<ng-template #locationItem let-location="location" let-isShowBorder="isShowBorder">
  <div
    *ngIf="locationData.length"
    class="w-full flex flex-col md:flex-row items-center gap-2 text-palette-gray-700 {{
      isShowBorder ? 'border-b py-2' : 'border-none pt-2'
    }} border-palette-gray-200">
    <div class="flex items-center gap-2 w-full md:min-w-[240px] md:w-[240px] truncate" [title]="location.address">
      <div class="rounded-full min-w-[16px] w-4 h-4 bg-palette-gray-200">
        <img
          *ngIf="location.countryCode.length === 2"
          [src]="'./assets/icons/' + location.countryCode + '.svg'"
          alt=""
          class="rounded-full min-w-[16px] w-4 h-4" />
      </div>
      <span class="text-palette-gray-700 truncate">{{
        location.countryCode === 'Other'
          ? ('COMMON.OTHER' | translate)
          : location.countryCode
          ? location.address
          : ('STATISTIC.LOCATION.LOCATION_UNKNOWN' | translate)
      }}</span>
    </div>
    <div class="w-full md:grow flex justify-between items-center">
      <div class="w-full h-full flex items-center gap-2">
        <div
          class="h-2 rounded-full bg-palette-teal-500"
          [ngStyle]="{ 'width.%': getPercent(location.userCount) }"></div>
        <span class="text-xs" title="{{ getPercent(location.userCount, 4) }}%"
          >{{ getPercent(location.userCount) }}%</span
        >
      </div>
      <div class="min-w-[140px] w-[140px] h-5 md:h-6 flex justify-end font-medium text-sm md:text-base">
        <span
          title="{{ location.userCount }} {{
            'STATISTIC.LOCATION.' + (location.userCount > 1 ? 'USERS' : 'USER') | translate
          }}"
          >{{ location.userCount | shortNumber }}
          {{ 'STATISTIC.LOCATION.' + (location.userCount > 1 ? 'USERS' : 'USER') | translate }}</span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingState>
  <div class="flex flex-col w-full">
    <ng-container *ngFor="let _ of [].constructor(4)">
      <div class="flex flex-col gap-2 items-center md:flex-row py-2 w-full">
        <div class="flex items-center gap-2 w-full md:min-w-[240px] md:w-[240px]">
          <p-skeleton shape="circle" size="16px"></p-skeleton>
          <p-skeleton width="55px" height="20px"></p-skeleton>
        </div>
        <div class="w-full md:grow flex justify-between items-center">
          <div class="w-full h-full flex items-center gap-2">
            <p-skeleton width="270px" height="8px" borderRadius="8px"></p-skeleton>
            <p-skeleton width="30px" height="8px"></p-skeleton>
          </div>
          <div class="min-w-[140px] w-[140px] h-5 md:h-6 flex justify-end">
            <p-skeleton width="60px" height="24px"></p-skeleton>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
