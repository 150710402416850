<div class="flex pl-12 pr-[3.25rem] items-center flex-row" [ngClass]="leaderBoard?.top3 ? 'justify-between' : 'gap-12'">
  <div *ngIf="leaderBoard?.top2 as top2" class="flex flex-col items-center">
    <div class="relative">
      <img class="absolute -top-[29px] left-[14px]" src="./assets/icons/crown_2.svg" />
      <img class="w-[88px] h-[88px] rounded-full" [src]="validUrl(top2?.image || '')" defaultImage />
      <img class="absolute -bottom-[14px] left-[14px]" src="./assets/icons/ribbon_2.svg" />
    </div>
    <div class="relative">
      <span
        [pTooltip]="top2.name"
        tooltipPosition="top"
        class="w-[110px] whitespace-break-spaces line-clamp-3 text-palette-base-white text-xl text-center mt-5 font-normal">
        {{ top2.name }}
      </span>
      <span class="absolute text-palette-base-white w-full text-xl -left-[5px] -bottom-12 font-medium text-center">
        <div *ngIf="data.voting_type === votingType.NORMAL">
          {{ (top2.votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE') | translate: { number: top2.votes } }}
        </div>
        <span *ngIf="data.voting_type === votingType.COINS" class="flex gap-[1px] items-center justify-center">
          <img src="/assets/icons/coin-with-star.svg" class="w-5 h-5" />
          <span class="text-palette-base-white text-xl font-medium">{{ top2.value }}</span>
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="leaderBoard?.top1 as top1" class="flex flex-col items-center">
    <div class="relative bottom-20">
      <img class="absolute top-[-65px] left-[20px]" src="./assets/icons/crown_1.svg" />
      <img class="w-[128px] h-[128px] rounded-full" [src]="validUrl(top1?.image || '')" defaultImage />
      <img class="absolute -bottom-3 left-[14px]" src="./assets/icons/ribbon_1.svg" />
    </div>
    <div class="relative bottom-20">
      <span
        [pTooltip]="top1.name"
        tooltipPosition="top"
        class="text-[#FFC95E] w-[110px] text-xl mt-5 font-normal text-center truncate whitespace-break-spaces line-clamp-3"
        >{{ top1.name }}</span
      >
      <span class="absolute text-palette-base-white w-full text-xl -left-[5px] -bottom-12 font-medium text-center">
        <div *ngIf="data.voting_type === votingType.NORMAL">
          {{ (top1.votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE') | translate: { number: top1.votes } }}
        </div>
        <span *ngIf="data.voting_type === votingType.COINS" class="flex gap-[1px] items-center justify-center">
          <img src="/assets/icons/coin-with-star.svg" class="w-5 h-5" />
          <span class="text-[#FFC95E] text-xl font-medium">{{ top1.value }}</span>
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="leaderBoard?.top3 as top3" class="flex flex-col items-center">
    <div class="relative">
      <img class="absolute -top-[25px] left-[21px]" src="./assets/icons/crown_3.svg" />
      <img class="w-[88px] h-[88px] rounded-full" [src]="validUrl(top3?.image || '')" defaultImage />
      <img class="absolute -bottom-[14px] left-[14px]" src="./assets/icons/ribbon_3.svg" />
    </div>
    <div class="relative">
      <span
        [pTooltip]="top3.name"
        tooltipPosition="top"
        class="w-[110px] whitespace-break-spaces line-clamp-3 text-palette-base-white text-xl mt-5 text-center font-normal">
        {{ top3.name }}
      </span>
      <span class="absolute text-palette-base-white w-full text-xl -left-[5px] -bottom-12 font-medium text-center">
        <div *ngIf="data.voting_type === votingType.NORMAL">
          {{ (top3.votes > 1 ? 'POLL.NUMBER_VOTES' : 'POLL.NUMBER_VOTE') | translate: { number: top3.votes } }}
        </div>
        <span *ngIf="data.voting_type === votingType.COINS" class="flex gap-[1px] items-center justify-center">
          <img src="/assets/icons/coin-with-star.svg" class="w-5 h-5" />
          <span class="text-palette-base-white text-xl font-medium">{{ top3.value }}</span>
        </span>
      </span>
    </div>
  </div>
</div>
