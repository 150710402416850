import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable, catchError, map, timeout } from "rxjs";
import { ApiClientConfig } from "../api-client.config";

export class BillingApi {
  private apiUrl = environment.baseURL;
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getExchangeRateFromUSD(toCurrency: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/billing/exchange-rate/USD/${toCurrency}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }
}
