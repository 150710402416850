import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';

@Component({
  selector: 'comment-emoji',
  templateUrl: './comment-emoji.component.html',
  styleUrls: ['./comment-emoji.component.scss']
})
export class CommentEmojiComponent {
  @Input() isShowEmoji = false;
  @Input() styleClass = '';
  @Output() selectEmoji = new EventEmitter();
  translate: any;
  i18n = {};

  constructor(private translateService: TranslationService) {}

  ngOnInit(): void {
    if (!localStorage.getItem('emoji-mart.frequently')) {
      localStorage.setItem('emoji-mart.frequently', '{}');
    }
    this.translate = this.translateService.getTranslation('EMOJI');
    this.i18n = {
      categories: {
        recent: this.translate.RECENT,
        people: this.translate.PEOPLE,
        nature: this.translate.NATURE,
        foods: this.translate.FOODS,
        activity: this.translate.ACTIVITY,
        places: this.translate.PLACES,
        objects: this.translate.OBJECTS,
        symbols: this.translate.SYMBOLS,
        flags: this.translate.FLAGS
      }
    };
  }

  onSelectEmoji(event: any): void {
    this.selectEmoji.emit(event);
  }
}
