import { HttpClient, HttpParams } from '@angular/common/http';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { PageAPI } from './api.page.model';

export class PageApi {
  private apiUrl = environment.baseURL;

  REVIEW = '/review';
  ACCEPT = '/accept';
  DECLINE = '/decline';
  ROLE = '/role/page';
  STATUS = '/status';
  PAGE = '/page/pages';
  POST = '/page/posts/';
  POSTS = '/posts/posts';
  CATEGORY = '/category';
  OVERVIEW = '/overview';
  PROMOTE = '/promote';
  MEMBER = '/page/members/';
  ADMIN = '/admins';
  NO_ADMIN = '/no-admins';
  ICON_IMAGE = '/icon-image';
  COVER_IMAGE = '/cover-image';
  CONTACT_INFO = '/contact-info';
  USER_CREATED_PAGES = '/user-created-pages';
  USER_FOLLOWED_PAGES = '/user-followed-pages';
  ROLE_REMOVE = '/role-remove';
  SWITCH_INTO_PAGE_ROLE = '/switch-into-page-role';
  INTEREST_SUGGESTIONS_PAGE = '/interest-suggestions-page';
  AUTO_APPROVE_POST = '/auto-approval';
  ME_ROLE = '/me/role';
  ALL = '/all';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  createPage(data: PageAPI.CreatePage): Observable<PageAPI.CreatePageResponse> {
    return this.http.post<PageAPI.CreatePageResponse>(`${this.apiUrl}${this.PAGE}`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFanpageRole(fanpageId: string | null): Observable<PageAPI.PageRoleResponse> {
    return this.http.get<PageAPI.PageRoleResponse>(`${this.apiUrl}${this.MEMBER}/${fanpageId}${this.ME_ROLE}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFanpageInfo(fanpageId: string | null): Observable<PageAPI.PageInfoResponse> {
    return this.http.get<PageAPI.PageInfoResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  switchIntoPageRole(fanpageId: string | null): Observable<PageAPI.PageInfoResponse> {
    return this.http
      .get<PageAPI.PageInfoResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.SWITCH_INTO_PAGE_ROLE}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageOverview(fanpageId: string | null): Observable<PageAPI.PageOverviewResponse> {
    return this.http.get<PageAPI.PageOverviewResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.OVERVIEW}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateFanpageOverview(
    fanpageId: string | null,
    data: PageAPI.PageOverview
  ): Observable<PageAPI.PageOverviewResponse> {
    return this.http
      .put<PageAPI.PageOverviewResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.OVERVIEW}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageContactInfo(fanpageId: string | null): Observable<PageAPI.PageContactInfoResponse> {
    return this.http
      .get<PageAPI.PageContactInfoResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.CONTACT_INFO}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateFanpageContactInfo(
    fanpageId: string | null,
    data: PageAPI.PageContactInfo
  ): Observable<PageAPI.PageContactInfoResponse> {
    return this.http
      .put<PageAPI.PageContactInfoResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.CONTACT_INFO}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  createFanpagePost(post: PageAPI.PageCreatePost): Observable<PageAPI.PageCreatePostResponse> {
    return this.http.post<PageAPI.PageCreatePostResponse>(`${this.apiUrl}${this.POSTS}`, post).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  searchPendingPosts(fanpageId: string | null, textSearch: string): Observable<PageAPI.PagePostResponse> {
    return this.http
      .get<PageAPI.PagePostResponse>(
        `${this.apiUrl}${this.POST}${fanpageId}/search-pending-post?searchingText=${textSearch}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  approvePendingPost(fanpageId: string | null, postIdsList: string[]): Observable<PageAPI.PagePostResponse> {
    return this.http
      .put<PageAPI.PagePostResponse>(`${this.apiUrl}${this.POST}/${fanpageId}${this.ACCEPT}`, { postIdsList })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  declinePendingPost(fanpageId: string | null, postIdsList: string[]): Observable<PageAPI.PagePostResponse> {
    return this.http
      .put<PageAPI.PagePostResponse>(`${this.apiUrl}${this.POST}/${fanpageId}${this.DECLINE}`, { postIdsList })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deletePage(fanpageId: string | null, body: PageAPI.PageStatus): Observable<PageAPI.PageResponse> {
    return this.http.delete<PageAPI.PageResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}`, { body }).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFollowerRoles(fanpageId: string | null): Observable<PageAPI.PageMemberInfoResponse> {
    return this.http.get<PageAPI.PageMemberInfoResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.ROLE}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateFanpageStatus(fanpageId: string | null, data: PageAPI.PageStatus): Observable<PageAPI.PageResponse> {
    return this.http.put<PageAPI.PageResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.STATUS}`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updatePageAvatar(data: PageAPI.PageImage, fanpageId: string | null): Observable<PageAPI.PageResponse> {
    return this.http.put<PageAPI.PageResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.ICON_IMAGE}`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updatePageCoverImage(data: PageAPI.PageImage, fanpageId: string | null): Observable<PageAPI.PageResponse> {
    return this.http.put<PageAPI.PageResponse>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.COVER_IMAGE}`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  removeUserRole(fanpageId: string | null, data: PageAPI.UpdateUserRole): Observable<PageAPI.UpdateUserResponse> {
    return this.http
      .put<PageAPI.UpdateUserResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}${this.ROLE_REMOVE}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  promoteUser(fanpageId: string | null, data: PageAPI.UpdateUserRole): Observable<PageAPI.UpdateUserResponse> {
    return this.http
      .put<PageAPI.UpdateUserResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}${this.PROMOTE}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageCategory(
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    textSearch?: string | null
  ): Observable<PageAPI.PageCategoryResponse> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '');

    return this.http.get<PageAPI.PageCategoryResponse>(`${this.apiUrl}${this.PAGE}${this.CATEGORY}`, { params }).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getMyFanpage(pageNum: number, pageSize: number, textSearch?: string | null): Observable<PageAPI.MyPageResponse> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '');

    return this.http
      .get<PageAPI.MyPageResponse>(`${this.apiUrl}${this.PAGE}${this.USER_CREATED_PAGES}`, { params })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpagesWithAdmins(pageSize: number, pageNum: number): Observable<PageAPI.MyPageResponse> {
    const baseUrl = `${this.apiUrl}${this.PAGE}${this.ADMIN}?pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.http.get<PageAPI.MyPageResponse>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFanpagesWithNoAdmins(pageSize: number, pageNum: number): Observable<PageAPI.MyPageResponse> {
    const baseUrl = `${this.apiUrl}${this.PAGE}${this.NO_ADMIN}?pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.http.get<PageAPI.MyPageResponse>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFollowingFanpage(
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    textSearch?: string | null
  ): Observable<PageAPI.MyPageResponse> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '');

    return this.http
      .get<PageAPI.MyPageResponse>(`${this.apiUrl}${this.PAGE}${this.USER_FOLLOWED_PAGES}`, { params })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageSuggestion(
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    textSearch?: string | null
  ): Observable<PageAPI.MyPageResponse> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('textSearch', textSearch ?? '');

    return this.http
      .get<PageAPI.MyPageResponse>(`${this.apiUrl}${this.PAGE}${this.INTEREST_SUGGESTIONS_PAGE}`, { params })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  autoApprovePost(fanpageId: string, isEnable: boolean): Observable<PageAPI.AutoApprovePostState> {
    const body = {
      auto_approval: isEnable
    };
    return this.http
      .put<PageAPI.AutoApprovePostState>(`${this.apiUrl}${this.PAGE}/${fanpageId}${this.AUTO_APPROVE_POST}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  transferFanpageOwnership(
    fanpageId: string,
    userId: string,
    newRole: string
  ): Observable<PageAPI.TransferOwnershipResponse> {
    const body = {
      user_id: userId,
      role: newRole
    };
    return this.http
      .put<PageAPI.TransferOwnershipResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}/change-owner`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  cancelTransferOwnershipRequest(fanpageId: string): Observable<PageAPI.TransferOwnershipResponse> {
    return this.http
      .put<PageAPI.TransferOwnershipResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}/transfer/cancel`, {})
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  acceptTransferOwnershipRequest(fanpageId: string): Observable<PageAPI.TransferOwnershipResponse> {
    return this.http
      .put<PageAPI.TransferOwnershipResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}/transfer/accept`, {})
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  declineTransferOwnershipRequest(fanpageId: string): Observable<PageAPI.TransferOwnershipResponse> {
    return this.http
      .put<PageAPI.TransferOwnershipResponse>(`${this.apiUrl}${this.MEMBER}${fanpageId}/transfer/decline`, {})
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageRoleInvitations(): Observable<PageAPI.FanpageRoleInvitationResponse> {
    return this.http.get<PageAPI.FanpageRoleInvitationResponse>(`${this.apiUrl}${this.MEMBER}me/invitation`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getAllCategories(): Observable<PageAPI.PageCategoryResponse> {
    return this.http.get<PageAPI.PageCategoryResponse>(`${this.apiUrl}${this.PAGE}${this.CATEGORY}${this.ALL}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
