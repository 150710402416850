import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DATASET_POSITION, LINE_CHART_COLORS } from '@app/shared/constant';
import { StatisticLineChartData } from '../../statistics.models';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input() lineChartData: StatisticLineChartData;
  @Input() isLowerBetter = false;
  @Input() border = true;
  @Input() padding = true;
  @Input() height = "400px";
  @Input() datasetPosition = DATASET_POSITION.bottom;
  datasetsPosition = DATASET_POSITION;
  data: any = { labels: [], datasets: [] };
  options: any = {};
  dailyAverages: number;
  sumOfDataLine1: number;
  sumOfDataLine2: number;
  xAxisLabels: string[] = [];
  yAxisLabelType: string | undefined;
  pointLabels: string[] = [];
  plugins: any[] = [];

  ngOnInit(): void {
    this.loadPlugins();
    if (this.lineChartData) {
      this.loadChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['lineChartData']) {
      if (this.lineChartData) {
        this.loadChart();
      }
    }
  }

  loadChart(): void {
    if (this.lineChartData.pointLabels) {
      this.pointLabels = this.lineChartData.pointLabels;
    }
    if (this.lineChartData.xAxisLabels) {
      this.xAxisLabels = this.lineChartData.xAxisLabels;
    }
    this.yAxisLabelType = this.lineChartData.yAxisLabelType
    this.loadOptions(this.xAxisLabels, this.yAxisLabelType);
    this.loadData();
  }

  loadOptions(labels: string[], yAxisLabelType: string | undefined): void {
    function getHideTickSteps(totalItems: number): number {
      if (totalItems <= 7) {
        return 1;
      } else if (totalItems <= 14) {
        return 2;
      } else if (totalItems <= 19) {
        return 3;
      } else if (totalItems <= 25) {
        return 4;
      } else if (totalItems <= 30) {
        return 5;
      } else {
        return 6;
      }
    }

    function getLabelForValue(val: any, index: number): string {
      let hideTickSteps = getHideTickSteps(labels.length);
      return index > 0 && (index + 1) % hideTickSteps === 0 ? labels[val] : '';
    }

    function getLabelForYValue(val: any): string {
      return yAxisLabelType ? yAxisLabelType.replace(/\bnum\b/g, val) : val;
    }

    const getOrCreateTooltip = (chart: any) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('tickPanel');
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        const tooltipBodyRootEl = document.createElement('div');
        tooltipBodyRootEl.classList.add('tooltipBodyRoot');
        tooltipEl.appendChild(tooltipBodyRootEl);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    };

    const externalTooltipHandler = (context: any) => {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipTickHeight = 12;
      const tooltipWidth = 240;
      const parrentPadding = 24;
      const arrowSize = 6;
      const tooltipEl = getOrCreateTooltip(chart);
      const {
        scales: { y }
      } = chart;
      const dataPoints = tooltip.dataPoints;
      const yCoor1 = y.getPixelForValue(tooltip.dataPoints[0].parsed.y);
      const yCoor2 = y.getPixelForValue(tooltip.dataPoints[1].parsed.y);
      const { offsetLeft: positionX } = chart.canvas;
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      // Set tooltip body
      if (tooltip.body) {
        const toolTipBodyEl = document.createElement('div');
        toolTipBodyEl.classList.add('toolTipBody');
        dataPoints.forEach((dataPoint: any, index: number) => {
          //Create tooltip tick detail
          const toolTipItemEl = document.createElement('div');
          toolTipItemEl.classList.add('toolTipItem');
          const colors = tooltip.labelColors[index];
          const dotLabelEl = document.createElement('div');
          dotLabelEl.classList.add('dotLabel');
          const tickDotEl = document.createElement('div');
          tickDotEl.style.background = colors.backgroundColor;
          tickDotEl.style.borderColor = colors.borderColor;
          tickDotEl.classList.add('tickDot');
          const toolTipLabelEl = document.createElement('span');
          toolTipLabelEl.classList.add('toolTipLabel');
          const labelText = [];
          labelText.push(dataPoint.label.includes('|') ? dataPoint.label.substring(0, dataPoint.label.lastIndexOf('|')) : dataPoint.label);
          labelText.push(dataPoint.label.includes('|') ? dataPoint.label.substring(dataPoint.label.lastIndexOf('|') + 1): dataPoint.label);
          const toolTipLabelTextEl = document.createTextNode(labelText[index]);
          toolTipLabelEl.appendChild(toolTipLabelTextEl);
          dotLabelEl.appendChild(tickDotEl);
          dotLabelEl.appendChild(toolTipLabelEl);
          const toolTipDataEl = document.createElement('span');
          toolTipDataEl.classList.add('toolTipData');
          const toolTipDataTextEl = document.createTextNode(dataPoint.raw);
          toolTipDataEl.appendChild(toolTipDataTextEl);
          toolTipItemEl.appendChild(dotLabelEl);
          toolTipItemEl.appendChild(toolTipDataEl);
          const dividerBarEl = document.createElement('div');
          dividerBarEl.classList.add('dividerBar');
          if (index) {
            toolTipBodyEl.appendChild(dividerBarEl);
          }
          toolTipBodyEl.appendChild(toolTipItemEl);
        });
        const tooltipBodyRootEl = tooltipEl.querySelector('div');
        // Remove old children
        while (tooltipBodyRootEl.firstChild) {
          tooltipBodyRootEl.firstChild.remove();
        }
        //Create tooltip arrow
        const tooltipArrowEl = document.createElement('div');
        tooltipArrowEl.classList.add('tooltipArrow');
        const tooltipArrowLeftNormal = tooltipWidth / 2 - arrowSize - 1;
        const tooltipArrowLeft =
          tooltip.caretX < tooltipWidth / 2 - parrentPadding
            ? tooltip.caretX - arrowSize + parrentPadding
            : tooltip.caretX > chart.width - tooltipWidth / 2 + parrentPadding
            ? tooltip.caretX - chart.width + tooltipWidth - arrowSize - parrentPadding
            : tooltipArrowLeftNormal;
        tooltipArrowEl.style.left = tooltipArrowLeft + 'px';
        const tooltipArrowBorderEl = document.createElement('div');
        tooltipArrowBorderEl.classList.add('arrowDownBorder');
        const tooltipArrowSolidEl = document.createElement('div');
        tooltipArrowSolidEl.classList.add('arrowDownSolid');
        tooltipArrowBorderEl.appendChild(tooltipArrowSolidEl);
        tooltipArrowEl.appendChild(tooltipArrowBorderEl);
        // Add new children
        tooltipBodyRootEl.appendChild(toolTipBodyEl);
        tooltipBodyRootEl.appendChild(tooltipArrowEl);
      }
      // Display, position
      const tooltipCaretX =
        tooltip.caretX < tooltipWidth / 2 - parrentPadding
          ? tooltipWidth / 2 - parrentPadding
          : tooltip.caretX > chart.width - tooltipWidth / 2 + parrentPadding
          ? chart.width - tooltipWidth / 2 + parrentPadding
          : tooltip.caretX;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltipCaretX + 'px';
      tooltipEl.style.top = Math.min(yCoor1, yCoor2) - tooltip.height - tooltipTickHeight + 'px';
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 1.2,
      elements: { point: { hoverBorderWidth: 2, hoverRadius: 3 } },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          external: externalTooltipHandler
        }
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      scales: {
        x: {
          ticks: {
            color: LINE_CHART_COLORS.gray500,
            callback(val: any, index: number): any {
              return getLabelForValue(val, index);
            },
            autoSkip: false,
            maxRotation: 0
          },
          grid: {
            display: false,
            drawTicks: true,
            tickColor: 'transparent'
          },
          border: {
            width: 2,
            color: '#D1D1D1'
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            maxTicksLimit: 7,
            precision: 0,
            color: LINE_CHART_COLORS.gray500,
            callback(val: any): any {
              return getLabelForYValue(val);
            },
          },
          grid: {
            color: '#EAECF0',
            drawTicks: true,
            tickColor: 'transparent'
          },
          border: {
            display: false
          },
          grace: '5%'
        }
      }
    };
  }

  loadPlugins(): void {
    const hoverLine = {
      id: 'hoverLine',
      afterDatasetsDraw(chart: any, arg: any, plugins: any) {
        const {
          ctx,
          tooltip,
          chartArea: { bottom },
          scales: { x, y }
        } = chart;
        if (tooltip._active.length > 0) {
          const xCoor = x.getPixelForValue(tooltip.dataPoints[0].dataIndex);
          const yCoor1 = y.getPixelForValue(tooltip.dataPoints[0].parsed.y);
          const yCoor2 = y.getPixelForValue(tooltip.dataPoints[1].parsed.y);
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#D0D5DD';
          ctx.setLineDash([4, 4]);
          ctx.moveTo(xCoor, Math.min(yCoor1, yCoor2));
          ctx.lineTo(xCoor, bottom);
          ctx.stroke();
          ctx.closePath();
          ctx.setLineDash([]);
        }
      }
    };
    this.plugins.push(hoverLine);
  }

  loadData(): void {
    let prepareData: any = { labels: [], datasets: [] };
    prepareData.labels = this.pointLabels;
    if (this.lineChartData.datasets && this.lineChartData.datasets.length) {
      const lastDataIndex = this.lineChartData.datasets.length - 1;
      if (this.lineChartData.datasets.length > 1) {
        this.sumOfDataLine1 = this.lineChartData.datasets[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        this.sumOfDataLine2 = this.lineChartData.datasets[lastDataIndex].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        if (this.sumOfDataLine1 + this.sumOfDataLine2) {
          this.dailyAverages = this.sumOfDataLine1 / this.sumOfDataLine2 - 1;
        } else {
          this.dailyAverages = 0;
        }
      }
      for (let dataset of this.lineChartData.datasets) {
        const data = {
          label: dataset.label,
          data: dataset.data,
          fill: dataset.fill ? true : false,
          backgroundColor: dataset.backgroundColorRGB !== 'none' ? (context: any) => {
            if (!context.chart.chartArea) {
              return;
            }
            const { ctx } = context.chart;
            const gradientBg = ctx.createLinearGradient(0, 25, 0, 300);
            gradientBg.addColorStop(0, `rgba(${dataset.backgroundColorRGB}, 0.3)`);
            gradientBg.addColorStop(0.1, `rgba(${dataset.backgroundColorRGB}, 0.1)`);
            gradientBg.addColorStop(0.5, `rgba(${dataset.backgroundColorRGB}, 0)`);
            return gradientBg;
          } : "#FFFFFF",
          tension: dataset.tension || 0.4,
          borderColor: dataset.lineColor,
          borderDash: dataset.lineDashed || [],
          pointRadius: 0,
          borderWidth: 2
        };
        prepareData.datasets.push(data);
      }
    }
    this.data = prepareData;
  }

  isNaNNumber(number: number): boolean {
    return Number.isNaN(number);
  }
}
