import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { FollowerApiModels } from '@app/lib/api/follower/api.follower.model';
import { GenderData } from '../../statistics.models';

const AGE_LABELS = ['18-24', '25-34', '35-44', '45-54', '55-64'];

@Component({
  selector: 'age-gender-chart',
  templateUrl: './age-gender-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./age-gender-chart.component.scss']
})
export class AgeGenderChartComponent {
  @Input() ageAndGenderData: FollowerApiModels.AgeAndGenderStats;
  @Input() isLoading = false;

  data: any = { labels: [], datasets: [] };
  options: any = {};
  malePercent = 0;
  femalePercent = 0;
  otherGenderPercent = 0;

  constructor(private TranslationService: TranslationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['ageAndGenderData'].currentValue) {
      this.loadChart();
    }
  }

  isHasData(): boolean {
    return this.ageAndGenderData && Object.keys(this.ageAndGenderData).length > 0;
  }

  loadChart(): void {
    if (this.isHasData()) {
      this.loadOptions();
      this.loadData(this.ageAndGenderData);
    }
  }

  loadOptions(): void {
    const getOrCreateTooltip = (chart: any) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('tickPanelAgeGenderChart');
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .3s ease';
        const tooltipBodyRootEl = document.createElement('div');
        tooltipBodyRootEl.classList.add('tooltipBodyRootAgeGenderChart');
        tooltipEl.appendChild(tooltipBodyRootEl);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    };

    const externalTooltipHandler = (context: any) => {
      // Tooltip Element
      const { chart, tooltip } = context;
      const tooltipTickHeight = 7;
      const tooltipWidth = 168;
      const tooltipHeight = 100;
      const parrentPadding = 16;
      const arrowSize = 6;
      const tooltipEl = getOrCreateTooltip(chart);
      const {
        scales: { y }
      } = chart;
      const dataPoints = tooltip.dataPoints;
      const yCoor2 = y.getPixelForValue(dataPoints[1].parsed.y);
      const { offsetLeft: positionX } = chart.canvas;
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }
      // Set tooltip body
      if (tooltip.body) {
        const toolTipBodyEl = document.createElement('div');
        toolTipBodyEl.classList.add('toolTipBodyAgeGenderChart');
        dataPoints.forEach((dataPoint: any, index: number) => {
          //Create tooltip tick detail
          const toolTipItemEl = document.createElement('div');
          toolTipItemEl.classList.add('toolTipItemsAgeGenderChart');
          const colors = tooltip.labelColors[index];
          const dotLabelEl = document.createElement('div');
          dotLabelEl.classList.add('dotLabelAgeGenderChart');
          const tickDotEl = document.createElement('div');
          tickDotEl.style.background = colors.backgroundColor;
          tickDotEl.classList.add('tickDotAgeGenderChart');
          const toolTipLabelEl = document.createElement('span');
          toolTipLabelEl.classList.add('toolTipLabelAgeGenderChart');
          const toolTipLabelTextEl = document.createTextNode(
            this.TranslationService.getTranslation(`COMMON.GENDER.${dataPoint.dataset.label}` || '')
          );
          toolTipLabelEl.appendChild(toolTipLabelTextEl);
          dotLabelEl.appendChild(tickDotEl);
          dotLabelEl.appendChild(toolTipLabelEl);
          const toolTipDataEl = document.createElement('span');
          toolTipDataEl.classList.add('toolTipDataAgeGenderChart');
          const toolTipDataTextEl = document.createTextNode(dataPoint.raw);
          toolTipDataEl.appendChild(toolTipDataTextEl);
          toolTipItemEl.appendChild(dotLabelEl);
          toolTipItemEl.appendChild(toolTipDataEl);
          toolTipBodyEl.appendChild(toolTipItemEl);
        });
        const tooltipBodyRootEl = tooltipEl.querySelector('div');
        // Remove old children
        while (tooltipBodyRootEl.firstChild) {
          tooltipBodyRootEl.firstChild.remove();
        }
        //Create tooltip arrow
        const tooltipArrowEl = document.createElement('div');
        tooltipArrowEl.classList.add('tooltipArrow');
        const tooltipArrowLeftNormal = tooltipWidth / 2 - arrowSize - 1;
        const tooltipArrowLeft =
          tooltip.caretX < tooltipWidth / 2 - parrentPadding
            ? tooltip.caretX - arrowSize + parrentPadding
            : tooltip.caretX > chart.width - tooltipWidth / 2 + parrentPadding
            ? tooltip.caretX - chart.width + tooltipWidth - arrowSize - parrentPadding
            : tooltipArrowLeftNormal;
        tooltipArrowEl.style.left = tooltipArrowLeft + 'px';
        const tooltipArrowBorderEl = document.createElement('div');
        tooltipArrowBorderEl.classList.add('arrowDownBorder');
        const tooltipArrowSolidEl = document.createElement('div');
        tooltipArrowSolidEl.classList.add('arrowDownSolid');
        tooltipArrowBorderEl.appendChild(tooltipArrowSolidEl);
        tooltipArrowEl.appendChild(tooltipArrowBorderEl);
        // Add new children
        tooltipBodyRootEl.appendChild(toolTipBodyEl);
        tooltipBodyRootEl.appendChild(tooltipArrowEl);
      }
      // Display, position
      const tooltipCaretX =
        tooltip.caretX < tooltipWidth / 2 - parrentPadding
          ? tooltipWidth / 2 - parrentPadding
          : tooltip.caretX > chart.width - tooltipWidth / 2 + parrentPadding
          ? chart.width - tooltipWidth / 2 + parrentPadding
          : tooltip.caretX;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltipCaretX + 'px';
      tooltipEl.style.top = yCoor2 - tooltipHeight - tooltipTickHeight + 'px';
    };

    this.options = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          external: externalTooltipHandler
        }
      },
      interaction: {
        mode: 'index',
        axis: 'x',
        intersect: false
      },
      scales: {
        x: {
          ticks: {
            color: '#475467',
            autoSkip: false,
            maxRotation: 0
          },
          grid: {
            display: false,
            drawTicks: true,
            tickColor: 'transparent'
          },
          border: {
            width: 2,
            color: '#D1D1D1'
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            maxTicksLimit: 7,
            precision: 0,
            color: '#344054'
          },
          grid: {
            color: '#EAECF0',
            drawTicks: true,
            tickColor: 'transparent'
          },
          border: {
            display: false
          },
          grace: '5%'
        }
      }
    };
  }

  loadData(ageAndGenderData: FollowerApiModels.AgeAndGenderStats): void {
    const labels = AGE_LABELS.concat(this.TranslationService.getTranslation('COMMON.GENDER.OTHER'));
    let prepareData: any = { labels: labels, datasets: [] };
    let maleData = [];
    let femaleData = [];
    let othersData = [];
    let rawData: GenderData[] = this.processRawData(ageAndGenderData);
    for (let item of rawData) {
      maleData.push(item.MALE);
      femaleData.push(item.FEMALE);
      othersData.push(item.OTHER);
    }
    const totalMale = maleData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalFemale = femaleData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const totalOtherGender = othersData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const total = totalMale + totalFemale + totalOtherGender;
    this.malePercent = total > 0 ? totalMale / total : 0;
    this.femalePercent = total > 0 ? totalFemale / total : 0;
    this.otherGenderPercent = total > 0 ? totalOtherGender / total : 0;
    const datasets = [
      { label: 'MALE', data: maleData, backgroundColor: '#7CD4FD' },
      { label: 'FEMALE', data: femaleData, backgroundColor: '#C3B5FD' },
      { label: 'OTHER', data: othersData, backgroundColor: '#F7B27A' }
    ];
    for (let dataset of datasets) {
      const data = {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: dataset.backgroundColor,
        hoverBackgroundColor: dataset.backgroundColor,
        borderRadius: 8,
        categoryPercentage: 0.7
      };
      prepareData.datasets.push(data);
    }
    this.data = prepareData;
  }

  processRawData(ageAndGenderData: FollowerApiModels.AgeAndGenderStats): GenderData[] {
    const processedData = {
      age18to24: ageAndGenderData['18-24'],
      age25to34: ageAndGenderData['25-34'],
      age35to44: ageAndGenderData['35-44'],
      age45to54: ageAndGenderData['45-54'],
      age55to64: ageAndGenderData['55-64'],
      others: {
        MALE: ageAndGenderData['65+'].MALE + ageAndGenderData.Other.MALE,
        FEMALE: ageAndGenderData['65+'].FEMALE + ageAndGenderData.Other.FEMALE,
        OTHER: ageAndGenderData['65+'].OTHER + ageAndGenderData.Other.OTHER
      }
    };
    return Object.values(processedData);
  }
}
