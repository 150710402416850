import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PostsService } from '@app/core/services/posts.service';
import { QuickReportModel, QuickReportRequest } from '@app/shared/models/post';
import { ListQuickSummary, TimeRange } from '@app/shared/models/quick-summary';

@Component({
  selector: 'quick-summary-popup',
  templateUrl: './quick-summary-popup.component.html',
  styleUrls: ['./quick-summary-popup.component.scss'],
})
export class QuickSummaryPopupComponent {
  listQuickSummary = ListQuickSummary;
  timeRange = TimeRange;
  selectedTimeRange: string;
  visibleListTimeRange = false;
  customReport: QuickReportRequest;
  @Input() selectedReport: QuickReportModel;
  @Input() dialogVisible = false;
  @Output() dialogVisibleChange = new EventEmitter<boolean>();
  @Output() selectedReportChange = new EventEmitter<QuickReportModel>();

  constructor( private postsService: PostsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['selectedReport'] && changes['selectedReport'].currentValue) {
      this.initData();
    }
  }

  onHide(): void {
    this.dialogVisibleChange.emit(false);
  }

  showListTimeRange(): void {
    this.visibleListTimeRange = !this.visibleListTimeRange;
  }

  initData(): void {
    this.customReport = {
      reactions: this.isPropertyChecked("reactions"),
      comments: this.isPropertyChecked("comments"),
      shares: this.isPropertyChecked("shares"),
      orders: this.isPropertyChecked("orders"),
      coins: this.isPropertyChecked("coins"),
      new_friends: this.isPropertyChecked("new_friends"),
      view_profile: this.isPropertyChecked("view_profile"),
      mentioned: this.isPropertyChecked("mentioned"),
      time_range_type: this.selectedReport.time_range_type,
    };    
  }

  isPropertyChecked(property: string): boolean {
    return Object.keys(this.selectedReport).some(key => property.toLowerCase().includes(key.toLowerCase()));
  }
  
  cancel(): void {
    this.dialogVisible = false;
  }
  
  applySelection() {
    this.postsService.putQuickReport(this.customReport).subscribe((res: QuickReportModel) => {
      this.selectedReport = res;
      const trueCount = Object.values(this.customReport).filter(value => value === true).length;
      localStorage.setItem('quickReportTotal', JSON.stringify(trueCount));
      this.selectedReportChange.emit(this.selectedReport);
    });
    this.dialogVisible = false;
  }
}
