import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FanPages, Group } from '@app/modules/main/states/search/search.state';
import { FriendList } from '@app/shared/models/friend';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, retry, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { SearchAPI } from './api.search.model';

export class SearchApi {
  private apiUrl: string = environment.baseURL;
  pageSize = 10;
  TOUR_TYPE = 'Commercial';
  STATUS = 'Active%7CPublished';
  SIZE = 3;
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getGlobalSearch(searchText: string): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchGlobalResponse>> {
    const queryParams = new HttpParams().set('text', searchText);
    const apiGlobalSearch = `${this.apiUrl}/socialsearch/global-search-v2`;
    return this.http
      .get<SearchAPI.SingleDataResponse<SearchAPI.SearchGlobalResponse>>(apiGlobalSearch, { params: queryParams })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getGlobalSearchPost(searchText: string): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchGlobalResponse>> {
    const queryParams = new HttpParams().set('text', searchText);
    const apiGlobalSearch = `${this.apiUrl}/socialsearch/global-search-post`;
    return this.http
      .get<SearchAPI.SingleDataResponse<SearchAPI.SearchGlobalResponse>>(apiGlobalSearch, { params: queryParams })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSuggestSearch(searchText: string): Observable<
    SearchAPI.SingleDataResponse<{
      result: SearchAPI.SearchSuggestionResult[];
    }>
  > {
    const userProfile = JSON.parse(localStorage.getItem('user_profile') || 'null');
    const apiGlobalSearch = `${this.apiUrl}/socialsearch/suggest`;
    return this.http
      .get<
        SearchAPI.SingleDataResponse<{
          result: SearchAPI.SearchSuggestionResult[];
        }>
      >(apiGlobalSearch, {
        params: { text: searchText, country_code: userProfile?.country_code, language: userProfile?.language }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSearchPlaces(searchText: string): Observable<any> {
    const queryParams = new HttpParams().set('keyword', searchText).set('number_of_results', 3);
    const apiGlobalSearch = `${this.apiUrl}/map-location/searching/suggestion`;
    return this.http.get<any>(apiGlobalSearch, { params: queryParams }).pipe(
      retry(1),
      map(res => {
        let { data } = res;
        data?.data.filter((item: any) => item.main_text.toLowerCase());
        return data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSearchHistory(paging_state = ''): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchHistoryResponse>> {
    let queryParams = new HttpParams();
    let apiSearchHistory = `${this.apiUrl}/socialsearch/history`;
    if (paging_state) {
      queryParams = queryParams.set('paging_state', paging_state);
    }
    return this.http
      .get<SearchAPI.SingleDataResponse<SearchAPI.SearchHistoryResponse>>(apiSearchHistory, { params: queryParams })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  createSearchHistory(
    data: SearchAPI.RequestSearchHistory
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>> {
    const apiSearchHistory = `${this.apiUrl}/socialsearch/history`;
    return this.http.post<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>>(apiSearchHistory, data).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteSearchHistory(
    data: SearchAPI.RequestDeleteSearchHistory
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>> {
    const { text_search, item_type } = data;
    const apiSearchHistory = `${this.apiUrl}/socialsearch/history`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .delete<SearchAPI.SingleDataResponse<SearchAPI.ItemSearchHistory>>(apiSearchHistory, {
        headers: headers,
        body: {
          text_search,
          item_type
        }
      })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteAllSearchHistory(): Observable<SearchAPI.SingleDataResponse<{}>> {
    const apiSearchHistory = `${this.apiUrl}/socialsearch/history/all`;
    return this.http.delete<SearchAPI.SingleDataResponse<{}>>(apiSearchHistory).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSearchTours(searchText: string): Observable<any> {
    const userProfile = JSON.parse(localStorage.getItem('user_profile') || '{}');
    const apiGlobalSearch = `${this.apiUrl}/travel-assistant-searching/tour/search/v3?page=0&size=${
      this.SIZE
    }&filter=${encodeURIComponent(
      `title@=${searchText},country_code==${userProfile?.country_code ? userProfile?.country_code + '|' : ''}WL`
    )}`;
    return this.http.get<any>(apiGlobalSearch).pipe(
      retry(1),
      map(res => {
        res.data.data.filter((tour: any) => tour.title.toLowerCase() || tour.places.toLowerCase());
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSearchUsers(
    searchText: string,
    pageNumber: number
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<FriendList>>> {
    const apiUserSearch = `${this.apiUrl}/socialsearch/user/search?textSearch=${encodeURI(
      searchText
    )}&pageNum=${pageNumber}&pageSize=${this.pageSize}`;
    return this.http.get<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<FriendList>>>(apiUserSearch).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSearchPost(
    searchText: string,
    pageNumber: number
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchPostResponse>> {
    const queryParams = new HttpParams()
      .set('text', searchText)
      .set('page', pageNumber)
      .set('page_size', this.pageSize);
    const apiPostSearch = `${this.apiUrl}/posts/posts/search`;
    return this.http
      .get<SearchAPI.SingleDataResponse<SearchAPI.SearchPostResponse>>(apiPostSearch, { params: queryParams })
      .pipe(
        timeout(this.config.responseTimeout),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSearchGroup(
    searchText: string,
    pageNumber: number
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<Group>>> {
    const apiUserSearch = `${this.apiUrl}/socialsearch/group/search?textSearch=${encodeURI(
      searchText
    )}&pageNum=${pageNumber}`;
    return this.http.get<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<Group>>>(apiUserSearch).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getSearchFanpage(
    searchText: string,
    pageNumber: number
  ): Observable<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<FanPages>>> {
    const apiUserSearch = `${this.apiUrl}/socialsearch/page/search?textSearch=${encodeURI(
      searchText
    )}&pageNum=${pageNumber}`;
    return this.http.get<SearchAPI.SingleDataResponse<SearchAPI.SearchResponse<FanPages>>>(apiUserSearch).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
