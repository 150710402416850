import { HttpClient } from '@angular/common/http';
import { PutLeaveGroup } from '@app/modules/main/group/components/group-info/group-events/tab-events-upcoming/events-tab.component';
import { PostIdsList } from '@app/modules/main/group/components/group-tab/group-settings/member-requests/member-requests.component';
import { GroupPost } from '@app/modules/main/group/components/group-tab/group-timeline/group-timeline.component';
import {
  UploadAvatarModel,
  UploadBannerModel
} from '@app/modules/main/group/components/group-tab/group-view/group-view.component';
import { MEMBER_STATUS, PAGE_NUM_DEFAULT } from '@app/shared/constant';
import { GroupDetailModel, ModalGroupAbout } from '@app/shared/models/group.model';
import { environment } from '@env/environment';
import { Observable, catchError, map, retry, throwError, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { FollowerApiModels } from '../follower/api.follower.model';
import { FRIENDS_API_ENDPOINTS } from '../friend/api.friend.constants';
import { PageAPI } from '../page/api.page.model';
import { PAGE_SIZE_DEFAULT } from './../../../shared/constant';
import {
  GROUP_API_ENDPOINTS,
  GROUP_CONTROLLER_API_ENDPOINTS,
  MEMBER_GROUP_API_ENDPOINTS,
  POST_GROUP_API_ENDPOINTS
} from './api.group.constants';
import {
  GroupAllRequestJoin,
  GroupProfileInfo,
  PageAPIGroup,
  PostPending,
  SearchUserInfoResponse,
  UpdateMemberAccept,
  UpdateMemberBan,
  UpdateMemberDecline,
  UserRoleRes,
  getDetailGroup,
  getMemberGroup,
  getPendingGroupMember,
  modalUserInfoMember,
  moduleGroupFriends
} from './api.group.model';

export class GroupApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}

  // get info detail group
  getGroupDetail = (groupId: string, admin: boolean) =>
    this.httpClient.get<getDetailGroup>(
      `${this.apiUrl}${admin ? POST_GROUP_API_ENDPOINTS.GROUP_ADMIN : POST_GROUP_API_ENDPOINTS.GROUP_GROUPS}${groupId}${
        GROUP_API_ENDPOINTS.GET_INFO
      }`
    );

  //get role
  getGroupRole = (groupId: string) =>
    this.httpClient.get<UserRoleRes>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_MEMBERS + groupId + GROUP_API_ENDPOINTS.GET_ROLE
    );

  // get member group
  getMembers = (groupId: string, pageNum = PAGE_NUM_DEFAULT, pageSize = PAGE_SIZE_DEFAULT) =>
    this.httpClient
      .get<getMemberGroup>(
        this.apiUrl +
          MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS +
          groupId +
          MEMBER_GROUP_API_ENDPOINTS.GET_MEMBER +
          `?pageNum=${pageNum}&pageSize=${pageSize}`
      )
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );

  // get member pending group
  getPendingMembers = (groupId: string) =>
    this.httpClient.get<getPendingGroupMember>(
      this.apiUrl + MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS + groupId + MEMBER_GROUP_API_ENDPOINTS.GET_PENDING_MEMBER
    );

  // put member accept group
  putMemberAccepted(groupId: string, postIdsList: PostIdsList) {
    return this.httpClient.put<UpdateMemberAccept>(
      this.apiUrl + MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS + groupId + MEMBER_GROUP_API_ENDPOINTS.GET_ACCEPT_MEMBER,
      postIdsList
    );
  }

  // put member decline group
  putMemberDeclined(groupId: string, postIdsList: PostIdsList) {
    return this.httpClient.put<UpdateMemberDecline>(
      this.apiUrl + MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS + groupId + MEMBER_GROUP_API_ENDPOINTS.GET_DECLINE_MEMBER,
      postIdsList
    );
  }

  // put member ban group
  putMemberBanned(groupId: string, user_id: string) {
    return this.httpClient.post<UpdateMemberBan>(
      this.apiUrl +
        MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS +
        groupId +
        '/' +
        user_id +
        MEMBER_GROUP_API_ENDPOINTS.GET_BAN_MEMBER,
      user_id
    );
  }

  getGroupAboutDetail = (groupId: string) =>
    this.httpClient.get<ModalGroupAbout>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + groupId + GROUP_API_ENDPOINTS.GET_ABOUT
    );

  putGroupAboutDetail = (groupId: string, payload: any) =>
    this.httpClient.put<ModalGroupAbout>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + groupId + GROUP_API_ENDPOINTS.GET_ABOUT,
      payload
    );

  getGroupContactDetail = (groupId: string) =>
    this.httpClient.get<ModalGroupAbout>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + groupId + GROUP_API_ENDPOINTS.GET_CONTACT
    );

  putGroupContactDetail = (groupId: string, payload: any) =>
    this.httpClient.put<ModalGroupAbout>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + groupId + GROUP_API_ENDPOINTS.GET_CONTACT,
      payload
    );

  // tạo bài pots group
  postGroupCreate = (newsPostGroup: any) =>
    this.httpClient.post<any>(this.apiUrl + POST_GROUP_API_ENDPOINTS.POSTS_POSTS, newsPostGroup);

  // upload Avatar group
  uploadAvatar = (param: UploadAvatarModel) =>
    this.httpClient.put<any>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + GROUP_API_ENDPOINTS.UPLOAD_AVATAR_GROUP,
      param
    );

  // upload Avatar group
  uploadBanner = (param: UploadBannerModel) =>
    this.httpClient.put<any>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_GROUPS + param.group_id + GROUP_API_ENDPOINTS.UPLOAD_BANNER_GROUP,
      {
        image_uri: param.cover_image_uri
      }
    );

  // get member group
  getUserInfoMember = (id: string) =>
    this.httpClient.get<modalUserInfoMember>(this.apiUrl + MEMBER_GROUP_API_ENDPOINTS.GET_INFO_MEMBERS + id);

  // get report post group
  getGroupReportContent = (param: any) => {
    const apiReportContent = `${this.apiUrl}/report/report/get-by-object-parent-id?objectParentId=${param.objectParentId}&approver=${param.adminGroup}`;
    return this.httpClient.get<any>(apiReportContent);
  };

  // upload Avatar group
  uploadGroupAvatar(param: UploadAvatarModel, groupId: any) {
    const apiUploadAvatar = `${this.apiUrl}/group/groups/${groupId}/icon`;
    return this.httpClient.put<any>(apiUploadAvatar, param);
  }

  getGroupMemberPendingPost(groupId: string): Observable<any[]> {
    let apiReportGroup = `${this.apiUrl}/report/report/get-by-object-id?pageNum=0&pageSize=10&objectId=${groupId}&sortByDate=DESCENDING`;
    return this.httpClient.get<any>(apiReportGroup);
  }

  getGroupAllRequestInvitedUsers = (groupId: string | null) =>
    this.httpClient.get<any>(
      this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_MEMBERS + groupId + GROUP_API_ENDPOINTS.ALL_REQUEST
    );

  getPendingPost(groupId: string | null, params: GroupPost): Observable<PostPending[]> {
    let apiPendingPost = `${this.apiUrl}/posts/posts/group/pending?pageNum=${params.pageNum}&pageSize=${params.pageSize}&groupId=${groupId}&sortByDate=${params.sortByDate}`;
    return this.httpClient.get<PostPending[]>(apiPendingPost).pipe(
      retry(1),
      map((res: any) => {
        return res.data.data;
      }),
      catchError(this.handleError)
    );
  }

  manageByPostIds(groupID: string, action: 'decline' | 'accept', ids: string[]) {
    return this.httpClient.put(this.apiUrl + POST_GROUP_API_ENDPOINTS.GROUP_POSTS + groupID + '/review/' + action, {
      post_ids_list: ids
    });
  }
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  updateGroupInfo(groupInfo: GroupDetailModel): Observable<GroupDetailModel> {
    return this.httpClient.put<GroupDetailModel>(this.apiUrl + `/group/groups`, groupInfo).pipe(
      retry(1),
      map((res: any) => res.data),
      catchError(this.handleError)
    );
  }

  banUser(user_id: string, group_id: string | null) {
    let param = {};
    return this.httpClient.post(`${this.apiUrl}/group/members/${group_id}/${user_id}/ban`, param).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateUserRole(user_id: string, role: string, group_id: string | null) {
    return this.httpClient.put(`${this.apiUrl}/group/members/${group_id}/promote`, { user_id, role }).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getInviteFriendList(groupId: string | null, page = 0) {
    const params = 'page=' + page;
    return this.httpClient
      .get(`${this.apiUrl}${MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS}${groupId}/users/invitation/friends?${params}`)
      .pipe(
        map((res: any) => res.data),
        catchError(error => {
          throw error.error;
        })
      );
  }

  inviteFriendsToGroup(friendList: any, groupId: string | null) {
    return this.httpClient
      .put(`${this.apiUrl}${MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS}${groupId}/users/invitation/invite`, {
        user_list: friendList
      })
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getGroupRule(groupId: string | null) {
    const endpoint = `${this.apiUrl}${POST_GROUP_API_ENDPOINTS.GROUP_GROUPS}${groupId}${GROUP_API_ENDPOINTS.RULE}`;
    return this.httpClient.get(endpoint).pipe(
      map((res: any) => res.data),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editGroupRule(groupRule: string, groupId: string | null) {
    const endpoint = `${this.apiUrl}${POST_GROUP_API_ENDPOINTS.GROUP_GROUPS}/${groupId}${GROUP_API_ENDPOINTS.RULE}`;
    return this.httpClient
      .put(endpoint, {
        content: groupRule
      })
      .pipe(
        map((res: any) => res.data),
        catchError(error => {
          throw error.error;
        })
      );
  }

  PostFilterGroupReportCategories(params: any) {
    return this.httpClient.post<any>(`${this.apiUrl}/report/report/filter?pageNum=0&pageSize=10`, params);
  }

  postReportGroup(param: any) {
    return this.httpClient.post<any>(`${this.apiUrl}/report/report`, param);
  }

  groupRemoveRoleMember(user_id: string, group_id: string | null) {
    return this.httpClient.put(`${this.apiUrl}/group/members/${group_id}/${user_id}/role/remove`, {}).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  groupRemoveMember(param: any, group_id: string | null) {
    return this.httpClient
      .delete(`${this.apiUrl}/group/members/${group_id}/users/remove`, {
        headers: {
          'Content-Type': 'application/json'
        },
        body: param
      })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  //invite admin as mod
  groupInviteRole(param: any, group_id: string | null) {
    let params = {};
    return this.httpClient
      .put(`${this.apiUrl}/group/members/${group_id}/${param.user_id}/role/promote?role=${param.role}`, params)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  //invite admin as mod
  groupTransferOwner(param: any, group_id: string | null) {
    const params = {};
    return this.httpClient
      .put(`${this.apiUrl}/group/groups/${group_id}/${param.user_id}/transfer?role=${param.role}`, params)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  groupAcceptRoleInvitation(group_id: string | null) {
    const params = {};
    return this.httpClient.put(`${this.apiUrl}/group/members/${group_id}/me/role/accept`, params).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  groupDeclineRoleInvitation(group_id: string | null) {
    const params = {};
    return this.httpClient.put(`${this.apiUrl}/group/members/${group_id}/me/role/decline`, params).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  PostGroupPendingSearch(group_id: string | null, searchingText: string) {
    return this.httpClient
      .get(`${this.apiUrl}/group/posts/${group_id}/search-pending-post?searchingText=${searchingText}`)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getGroupRules(groupRule: string | null, groupId: string | null) {
    const endpoint = `${this.apiUrl}${POST_GROUP_API_ENDPOINTS.GROUP_GROUPS}/${groupId}${GROUP_API_ENDPOINTS.RULE}`;
    return this.httpClient
      .put(endpoint, {
        content: groupRule
      })
      .pipe(
        map((res: any) => res),
        catchError(error => {
          throw error.error;
        })
      );
  }

  //confirm password
  postConfirmPassWord(confirm_password: string) {
    let param = {
      confirm_password: confirm_password
    };
    return this.httpClient.post(`${this.apiUrl}/user/confirm-password`, param).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getGroupDiscover(
    pageSize = PAGE_SIZE_DEFAULT,
    pageNum = PAGE_NUM_DEFAULT,
    textSearch?: string | null
  ): Observable<PageAPIGroup.MyPageResponseDiscover> {
    const baseUrl = `${
      this.apiUrl
    }/group/groups/suggestions?pageSize=${pageSize}&pageNum=${pageNum}&textSearch=${encodeURI(textSearch || '')}`;
    return this.httpClient.get<PageAPIGroup.MyPageResponseDiscover>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  searchInviteFriendList(groupId: string | null, keyword: string, page = 0) {
    const params = 'text=' + keyword + '&page=' + page;
    return this.httpClient
      .get(
        `${this.apiUrl}${MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS}${groupId}${MEMBER_GROUP_API_ENDPOINTS.SEARCH_INVITE_MEMBERS}?${params}`
      )
      .pipe(
        map((res: any) => res.data),
        catchError(error => {
          throw error.error;
        })
      );
  }

  groupReportContent(param: any) {
    return this.httpClient.post(`${this.apiUrl}/report/report/search?pageNum=0`, param).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  // cancel request to join group
  putGroupCancelRequestPromote(groupId: string | null, id: any) {
    const param = {};
    return this.httpClient.put<any>(
      this.apiUrl +
        POST_GROUP_API_ENDPOINTS.GROUP_MEMBERS +
        groupId +
        '/' +
        id +
        GROUP_API_ENDPOINTS.CANCEL_REQUEST_PROMOTE_ROLE,
      param
    );
  }

  putGroupCancelRequestInvitedUsers(groupId: string | null, user_id: string) {
    const param = {};
    return this.httpClient.put<any>(
      this.apiUrl +
        POST_GROUP_API_ENDPOINTS.GROUP_MEMBERS +
        groupId +
        '/' +
        user_id +
        GROUP_API_ENDPOINTS.CANCEL_REQUEST_INVITED,
      param
    );
  }

  postGroupRejectPending(params: any[]): Observable<any[]> {
    let apiGroupReject = `${this.apiUrl}/report/report`;
    return this.httpClient.put<any>(apiGroupReject, params);
  }

  getGroup(
    pageSize = PAGE_SIZE_DEFAULT,
    pageNum = PAGE_NUM_DEFAULT,
    textSearch?: string | null
  ): Observable<GroupProfileInfo[] | any> {
    const baseUrl =
      `${this.apiUrl}` +
      GROUP_API_ENDPOINTS.MY_GROUP +
      `?pageNum=${pageNum}&size=${pageSize}&searchText=${encodeURI(textSearch || '')}`;
    return this.httpClient.get<GroupProfileInfo[] | any>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getGroupsWithAdmins(pageSize: number, pageNum: number): Observable<GroupProfileInfo[] | any> {
    return this.httpClient.get(
      this.apiUrl + GROUP_API_ENDPOINTS.ADMIN_GROUP + `?pageNum=${pageNum}&pageSize=${pageSize}`
    );
  }

  getGroupsWithNoAdmins(pageSize: number, pageNum: number): Observable<GroupProfileInfo[] | any> {
    return this.httpClient.get(
      this.apiUrl + GROUP_API_ENDPOINTS.NO_ADMIN_GROUP + `?pageNum=${pageNum}&pageSize=${pageSize}`
    );
  }

  createGroup(group: GroupProfileInfo) {
    return this.httpClient.post(this.apiUrl + GROUP_API_ENDPOINTS.POST_GROUP, group);
  }

  //List all request to join group
  getAllGroupRequestJoin(pageSize = PAGE_SIZE_DEFAULT, pageNum = PAGE_NUM_DEFAULT, textSearch?: string | null) {
    const baseUrl =
      `${this.apiUrl}` +
      GROUP_CONTROLLER_API_ENDPOINTS.GET_GROUP_REQUEST +
      `?pageNum=${pageNum}&size=${pageSize}&searchText=${encodeURI(textSearch || '')}`;
    return this.httpClient.get<GroupAllRequestJoin>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  //List all group to join
  getAllGroupToJoin(pageSize = PAGE_SIZE_DEFAULT, pageNum = PAGE_NUM_DEFAULT, textSearch?: string | null) {
    const baseUrl =
      `${this.apiUrl}` +
      GROUP_CONTROLLER_API_ENDPOINTS.GET_GROUP_TO_JOIN +
      `?pageNum=${pageNum}&size=${pageSize}&searchText=${encodeURI(textSearch || '')}`;
    return this.httpClient.get<GroupAllRequestJoin>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  // put member decline group
  putCancelRequestGroup(groupId: string) {
    const requestData = {};
    return this.httpClient.put<any>(`${this.apiUrl}/group/members/${groupId}/me/request/cancel`, requestData);
  }

  // put leave group
  putLeaveGroup(groupId: PutLeaveGroup) {
    const requestData = {};
    return this.httpClient.put<any>(`${this.apiUrl}/group/groups/${groupId.group_id}/leave`, requestData);
  }

  //List all group pending invites
  getAllGroupPendingInvites(pageSize = PAGE_SIZE_DEFAULT, pageNum = PAGE_NUM_DEFAULT, textSearch?: string | null) {
    const baseUrl =
      `${this.apiUrl}` +
      GROUP_CONTROLLER_API_ENDPOINTS.GET_INVITED_GROUP +
      `?searchText=${encodeURI(textSearch || '')}&pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.httpClient.get<GroupAllRequestJoin>(baseUrl).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  //List all friends
  getAllFriends() {
    return this.httpClient.get<moduleGroupFriends>(this.apiUrl + FRIENDS_API_ENDPOINTS.GET_ALL_FRIENDS);
  }

  // put leave group
  putAcceptToJoinGroup(groupId: string) {
    const requestData = {};
    return this.httpClient.put<any>(`${this.apiUrl}/group/members/${groupId}/me/invitation/accept`, requestData);
  }

  // put leave group
  putDeclineToJoinGroup(groupId: string) {
    const requestData = {};
    return this.httpClient.put<any>(`${this.apiUrl}/group/members/${groupId}/me/invitation/decline`, requestData);
  }

  // leave group owner
  groupLeave(group_ids: any) {
    const requestData = {};
    return this.httpClient.put<any>(`${this.apiUrl}/group/groups/${group_ids}/leave`, requestData);
  }

  // leave group owner
  groupJoin(group_ids: any) {
    const requestData = {};
    return this.httpClient.post<any>(`${this.apiUrl}/group/members/${group_ids}/request`, requestData);
  }

  //search all friends
  searchAllFriends(value: string) {
    return this.httpClient.get<moduleGroupFriends>(`${this.apiUrl}/friend/search?text=${value}&page=0 `);
  }

  putMeRemoveRole(group_id: string | null) {
    return this.httpClient.put(`${this.apiUrl}/group/members/${group_id}/me/role/leave`, {}).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  censorshipPost(postListIds: string[], censorship_status: boolean) {
    return this.httpClient.post(`${this.apiUrl}/posts/posts/censorship`, {
      posts: postListIds,
      censorship_status: censorship_status
    });
  }

  getGroupAgeAndGenderStats(groupId: string): Observable<FollowerApiModels.AgeAndGenderStatsResponse> {
    const params = `group_id=${groupId}`;
    return this.httpClient
      .get<FollowerApiModels.AgeAndGenderStatsResponse>(
        `${this.apiUrl}${GROUP_CONTROLLER_API_ENDPOINTS.AGE_AND_GENDER_STATS}?${params}`
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  searchGroupMembers(
    groupId: string,
    searchKey: string,
    pageNum = 0,
    pageSize = 10,
    userStatus = MEMBER_STATUS.ACCEPTED
  ): Observable<SearchUserInfoResponse> {
    const params = `textSearch=${searchKey}&pageNum=${pageNum}&pageSize=${pageSize}&userStatus=${userStatus}`;
    return this.httpClient
      .get<SearchUserInfoResponse>(
        `${this.apiUrl}${MEMBER_GROUP_API_ENDPOINTS.GROUP_MEMBERS}${groupId}${MEMBER_GROUP_API_ENDPOINTS.SEARCH_MEMBERS}?${params}`
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  cancelTransferOwnershipRequest(groupId: string): Observable<PageAPI.TransferOwnershipResponse> {
    return this.httpClient
      .put<PageAPI.TransferOwnershipResponse>(
        `${this.apiUrl}${POST_GROUP_API_ENDPOINTS.GROUP_GROUPS}${groupId}/transfer/cancel`,
        {}
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
