<div class="h-full md:h-fit md:mb-4 flex flex-col gap-2 bg-transparent md:bg-gray-200">
  <p-card
    class="remove-default-padding-pcard"
    styleClass="p-4 rounded-md shadow-none bg-white md:shadow-sm md:border border-palette-gray-200">
    <div class="flex justify-between">
      <span class="text-lg font-semibold flex pb-4 text-palette-gray-900">{{
        'GROUP.SETTING.GROUP_RULES' | translate
      }}</span>
      <i *ngIf="!isShowEditRule" class="pi pi-pencil cursor-pointer" (click)="handleEditRule()"></i>
    </div>
    <div class="mt-2 flex flex-col gap-4" *ngIf="!isShowEditRule">
      <div class="w-full" *ngIf="groupRule && !isLoadingSkeleton">
        <div class="flex flex-col gap-2 no-editor">
          <p-editor [readonly]="true" [(ngModel)]="groupRule" [style]="{ height: 'auto', width: 'auto' }"></p-editor>
        </div>
      </div>
      <span *ngIf="!groupRule" class="text-sm font-normal text-palette-gray-500">{{
        'GROUP.USE_COMMUNITY_RULES_AND_MEMBERSHIP_QUESTIONS' | translate
      }}</span>
    </div>
    <ng-container *ngIf="isLoadingSkeleton">
      <li class="flex items-center">
        <div style="flex: 1">
          <p-skeleton width="100%" styleClass="mb-2" />
          <p-skeleton width="50%" />
        </div>
      </li>
    </ng-container>
    <p-editor
      *ngIf="isShowEditRule"
      [placeholder]="'GROUP.USE_COMMUNITY_RULES_AND_MEMBERSHIP_QUESTIONS' | translate"
      [(ngModel)]="groupRule"
      [style]="{ height: '320px', width: '866px' }"></p-editor>
    <p-footer class="w-full md:flex md:justify-end p-0">
      <div class="md:flex gap-3 w-full md:w-auto" *ngIf="isShowEditRule">
        <p-button
          (click)="handleGroupRuleCancel(); onBackToGroupSettings()"
          styleClass="w-full md:w-auto bg-white text-black border border-gray-300"
          label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"></p-button>
        <p-button
          (click)="handleGroupRuleSave()"
          styleClass="w-full md:w-auto bg-blue-500 text-white"
          label="{{ 'COMMON.BUTTON_ACTION.SAVE' | translate }}"></p-button>
      </div>
    </p-footer>
  </p-card>
</div>
