import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filters',
  pure: false
})
export class FilterPipes implements PipeTransform {

  transform(items: any[], field: string, value: any, operator: 'equal' | 'contains'): any[] {
    if (!items) return []

    switch(operator) {
      case 'equal':
        return items.filter(i => i[field] === value)
      case 'contains':
        return items.filter(i => (i[field] ? i[field].toLowerCase() : '').indexOf(value.toLowerCase()) >= 0)
      default:
        return items;
    }
    
  }

}
