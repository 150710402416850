import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { StockProductsInfo, StockProductsResponse } from './api.stocks.module';

export class StockApi {
  private apiUrl: string = environment.baseURL;
  STOCK = '/stock';
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}

  getStockProductWithSponsort(searchArray: string[], pageNum: number, pageSize: number): Observable<StockProductsInfo> {
    let querySearch = '';
    searchArray.forEach(text => (querySearch += `&historyKeywords=${encodeURIComponent(text)}`));
    const url = `${this.STOCK}/products/search/sponsors/social?pageNum=${pageNum}&pageSize=${pageSize + querySearch}`;
    return this.httpClient.get<StockProductsResponse>(`${this.apiUrl}${url}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: StockProductsResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
