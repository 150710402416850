import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { StorageApiModels } from '@app/lib/api/storage/api.storage.models';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastMessageService } from './toast-message.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class StoriesService {
  private api: ApiClient;
  deleteAllMyStory: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    public readonly httpClient: HttpClient,
    private toastMessageService: ToastMessageService,
    private translateService: TranslationService
  ) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  getStories = (pageNum?: number, pageSize?: number) => this.api.stories.getStories(pageNum, pageSize);

  deleteStory = (storyId: string) => this.api.stories.deleteStory(storyId);

  deleteStories = (storyIds: string[]) => this.api.stories.deleteStories(storyIds);

  getStoriesByUserId = (userId: string, pageSize = 10, pageNum = 0) =>
    this.api.stories.getStoriesByUserId(userId, pageNum, pageSize);

  getStoriesById = (storyId: string, isModerate: boolean) => this.api.stories.getStoriesById(storyId, isModerate);

  downloadStory = (requestBody: StorageApiModels.DownloadRequestBody) => {
    this.api.storage.downloadFile(requestBody);
    this.toastMessageService.addToastMessage(
      TOAST_MESSAGE_SEVERITY_LEVELS.success,
      this.translateService.getTranslation('STORY.SAVE_STORY_SUCCESSFULLY')
    );
  };

  getStoriesArchived = (pageNum?: number, pageSize?: number) => this.api.stories.getStoriesArchived(pageNum, pageSize);
}
