import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesConversion'
})
export class BytesConversionPipe implements PipeTransform {
  transform(value: number, precision?: any): any {
    return this.convertFromBytes(value, isNaN(precision) ? 2 : precision);
  }

  convertFromBytes(bytes: number, precision?: any) {
    const units = ['B', 'kB', 'MB', 'GB', 'TB'];
    if (bytes === undefined || isNaN(bytes) || !isFinite(bytes) || bytes === 0) {
      return '0 Bytes';
    }

    const number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
  }

  convertToBytes(bytes: number, unit: string) {
    const byteConversionFactors: { [key: string]: number } = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024
    };

    const unitBytes = byteConversionFactors[unit];
    if (!unitBytes) {
      throw new Error('Invalid unit. Supported units are B, kB, MB, GB, and TB.');
    }

    const convertedSize = bytes * unitBytes;
    return convertedSize;
  }
}
