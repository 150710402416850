<div class="flex px-0.5" *ngIf="!isEdit">
  <div class="relative self-start">
    <avatar
      [avatarSize]="isChild ? 32 : 36"
      [userInfo]="commentData.created_by"
      [isDisableNavigate]="!allowNavigation"></avatar>
  </div>
  <div class="ml-2 w-full flex flex-col gap-2">
    <div
      class="flex flex-col rounded-lg text-sm max-w-full min-w-[220px] px-4 overflowWrap"
      [ngClass]="{
        'w-fit': !isWidthFull,
        'bg-palette-gray-100': !isLiveView && !isReferCommentId,
        'bg-palette-blue-100': isReferCommentId && !isModerateStory,
        'pb-2': isLiveView,
        'py-2': !isLiveView
      }">
      <div class="text-palette-gray-900 font-medium whitespace-nowrap flex justify-between">
        <div class="flex gap-2 items-center">
          <div
            class="text-xs font-semibold cursor-pointer flex items-center max-w-[8rem]"
            [ngClass]="isLiveView ? 'text-palette-base-white' : 'text-palette-gray-700'">
            <span
              *ngIf="allowNavigation"
              class="truncate"
              [routerLink]="isModerateStory ? null : ['/personal-profile', commentData.created_by.id]"
              >{{ commentData.created_by.full_name }}</span
            >
            <span *ngIf="!allowNavigation" class="truncate">{{ commentData.created_by.full_name }}</span>
            <div
              class="flex justify-center items-center relative w-4 h-4 ml-0.5"
              *ngIf="commentData.created_by && commentData.created_by.is_verified_user">
              <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-base/[16px]"></i>
              <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-sm/[14px]"></i>
            </div>
          </div>
          <span
            class="text-xs font-normal"
            [ngClass]="isLiveView ? 'text-palette-base-white opacity-50' : 'text-palette-gray-500'"
            >&#x2022;</span
          >
          <p
            class="hover:underline cursor-pointer text-xs font-normal"
            [ngClass]="isLiveView ? 'text-palette-base-white opacity-50' : 'text-palette-gray-500'"
            [pTooltip]="(commentData.created_at | dateFormatPipe: true) || ''"
            [tooltipStyleClass]="'max-w-none'">
            {{ getFromNow() }}
          </p>
        </div>
        <p-menu
          #menu
          [styleClass]="'font-medium text-palette-gray-700'"
          [model]="commentOptions"
          [popup]="true"
          (onShow)="showItemMenuComment()"
          [appendTo]="appendTo ? appendTo : 'body'">
        </p-menu>
        <i
          *ngIf="!isModerateStory"
          checkAuthentication
          (openLoginPopup)="isOpenPopupLogin = $event"
          class="sctr-icon-dots-horizontal text-base/none rounded-full hover:bg-slate-200 p-0.5 cursor-pointer"
          [ngClass]="isLiveView ? 'text-palette-gray-500' : ''"
          (click)="!isModerateStory && !isOpenPopupLogin ? menu.toggle($event) : null"></i>
      </div>

      <ng-container
        [ngTemplateOutlet]="commentContent"
        [ngTemplateOutletContext]="{
          isViewMore: !isShowAllContent && contentHeight > MAX_TRUNCATED_TEXT_HEIGHT_COMMENT,
          isTranslate: isTranslate
        }">
      </ng-container>

      <!-- </div> -->
      <div *ngIf="commentData && commentData.preview_data" class="my-1">
        <post-content-preview [data]="commentData.preview_data" [commentUI]="true"> </post-content-preview>
      </div>
    </div>

    <div
      class="w-full grid gap-2 grid-cols-5"
      *ngIf="!isLivestream && commentData.medias && commentData.medias.length > 0">
      <div
        *ngFor="let item of commentData.medias.slice(0, 5); index as i"
        class="rounded-lg relative cursor-pointer"
        (click)="showImage(i)">
        <ng-container *ngIf="item.type.startsWith('image/')">
          <ng-template [ngIf]="isShowImage" [ngIfElse]="loading">
            <div class="lessons-list">
              <img
                defaultImage
                [src]="createMediaUrl(item.id)"
                alt="{{ item.type }}"
                class="rounded-lg object-cover w-full h-full" />
            </div>
          </ng-template>
          <ng-template #loading>
            <p-skeleton class="rounded-lg object-cover w-full h-full" height="100px"></p-skeleton>
          </ng-template>
        </ng-container>
        <ng-container>
          <div class="relative">
            <div (mouseenter)="showIconPlay = true" (mouseleave)="showIconPlay = false">
              <video
                *ngIf="item.type.startsWith('video/')"
                class="rounded-lg object-cover w-full h-full"
                [src]="createMediaUrl(item.id, FILE_TYPE_URL.streamVideo)"></video>
              <i
                *ngIf="showIconPlay"
                class="sctr-icon-solid-play-circle text-5xl text-palette-base-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></i>
            </div>
          </div>
        </ng-container>
        <div
          *ngIf="commentData.medias.length > 5 && i === 4"
          class="absolute w-full h-full flex items-center justify-center top-0 left-0 bg-palette-base-black bg-opacity-30 rounded-lg">
          <div class="bg-opacity-30 bg-palette-base-white rounded-full p-1 w-7 h-7 text-center flex justify-center">
            <span class="text-sm font-normal text-palette-base-white">+{{ commentData.medias.length - 5 }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-2 text-xs items-center relative" *ngIf="!isLivestream && !isLiveView && !isModerateStory">
      <p
        class="font-semibold text-sm cursor-pointer z-[9]"
        (mouseenter)="showHideOverlayIcons(true)"
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event"
        (mouseleave)="showHideOverlayIcons(false)"
        [ngStyle]="{ 'color': selectedEmoji?.color ? selectedEmoji?.color : '' }"
        (click)="selectedEmoji && !isOpenPopupLogin ? executedEmojiChanged() : executedEmojiChanged(emojiList[0].type)">
        {{ (selectedEmoji?.label ? selectedEmoji?.label : 'POST.LOVE') | translate }}
      </p>
      <div class="contents">
        <div class="{{ overlayVisible ? 'w-[296px] absolute h-11 -top-6' : 'absolute' }} z-[8]">
          <icon-popup
            [isCommentPopup]="true"
            (emojiChanged)="executedEmojiChanged($event)"
            (closePopup)="showHideOverlayIcons($event)"
            [selectedEmoji]="selectedEmoji"
            [overlayVisible]="overlayVisible"></icon-popup>
        </div>
      </div>
      <div class="flex items-center" *ngIf="totalReactions > 0">
        <div
          *ngFor="let item of reactionList; let i = index"
          class="relative -mr-1.5 cursor-pointer"
          [ngClass]="getZIndexReaction(i)">
          <img
            defaultImage
            src="{{ item.icon }}"
            class="w-4"
            [pTooltip]="getReactedUsers(item.type)"
            [tooltipPosition]="'top'"
            [escape]="false"
            (mouseover)="getReactionsByEntityId(item.type)" />
        </div>
        <span
          class="ml-3 text-sm font-normal text-palette-gray-500 hover:underline cursor-pointer"
          [pTooltip]="getReactedUsers()"
          [tooltipPosition]="'top'"
          [escape]="false"
          (mouseover)="getReactionsByEntityId()"
          >{{ totalReactions }}</span
        >
      </div>
      <ng-container *ngIf="!hideReply">
        <span class="text-palette-gray-500 text-xs font-normal">&#x2022;</span>
        <p
          checkAuthentication
          (openLoginPopup)="isOpenPopupLogin = $event"
          class="hover:underline font-semibold text-sm text-palette-gray-600 cursor-pointer z-[9]"
          (click)="showCommentForm()">
          {{ 'COMMENT.REPLY' | translate }}
        </p>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="isEdit">
  <comment-form
    [isStory]="isStory"
    [loginUser]="commentData.created_by"
    [isEditComment]="isEdit"
    (isEditCommentChange)="isEditCommentChange($event)"
    (valueComment)="editComment($event)"
    [previewData]="commentData.preview_data"
    [contentComment]="formattedContent()"
    [mediaComment]="commentData.medias"
    [messageRanges]="commentData.message_ranges ? commentData.message_ranges : []">
  </comment-form>
</div>

<photo-view
  *ngIf="displayMedia"
  [isComment]="true"
  [activeIndex]="activeIndex"
  [post]="commentData"
  [mediaList]="mediaList"
  [(isShowView)]="displayMedia">
</photo-view>

<ng-template #commentContent let-isViewMore="isViewMore">
  <ng-template [ngIf]="isTranslate" [ngIfElse]="notTranslate">
    <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
      <div
        class="whitespace-pre-wrap comment-content text-sm font-normal emoji {{
          isViewMore ? 'truncated-text' : 'block'
        }}"
        [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
        [innerHTML]="translateContent() + (isViewMore ? ' ...' : '')"></div>
    </ng-template>

    <ng-template #loadingTranslate>
      <div class="flex flex-col justify-center">
        <p-skeleton width="100px" height="12px"></p-skeleton>
        <p-skeleton class="my-1" width="200px" height="10px"></p-skeleton>
        <p-skeleton width="50px" height="12px"></p-skeleton>
      </div>
    </ng-template>
  </ng-template>
  <ng-template #notTranslate>
    <div
      class="whitespace-pre-wrap comment-content text-sm font-normal emoji {{
        isViewMore ? 'truncated-text' : 'block'
      }}"
      [ngClass]="isLiveView ? 'text-palette-base-white opacity-80' : 'text-palette-gray-900'"
      [innerHTML]="formattedContent() + (isViewMore ? ' ...' : '')"></div>
  </ng-template>

  <div>
    <div class="float-left">
      <ng-template [ngIf]="isTranslate" [ngIfElse]="viewTrans">
        <ng-container *ngIf="formattedContent()">
          <ng-template [ngIf]="translateContent()" [ngIfElse]="loadingTranslate">
            <div class="comment-action" (click)="isTranslate = false">
              {{ 'COMMON.SEE_ORIGINAL' | translate }}
            </div>
          </ng-template>
          <ng-template #loadingTranslate>
            <div class="font-semibold w-fit text-palette-gray-700 text-sm mt-1">
              {{ 'COMMON.TRANSLATING' | translate }}...
            </div>
          </ng-template>
        </ng-container>
      </ng-template>
      <ng-template #viewTrans>
        <ng-container *ngIf="isShowTranslateButton(commentData)">
          <div class="comment-action" (click)="onTranslateText()">
            {{ 'COMMON.VIEW_TRANSLATION' | translate }}
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="float-right">
      <ng-template [ngIf]="isViewMore" [ngIfElse]="viewLess">
        <div class="comment-action self-end" (click)="onExpandText()">
          {{ 'COMMON.VIEW_MORE' | translate }}
        </div>
      </ng-template>
      <ng-template #viewLess>
        <div
          *ngIf="(isShowAllContent || contentHeight > MAX_TRUNCATED_TEXT_HEIGHT_COMMENT) && !isModerateStory"
          class="comment-action self-end"
          (click)="onExpandText()">
          {{ 'COMMON.VIEW_LESS' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>

<p-dialog
  [appendTo]="'body'"
  header="{{ 'FRIEND.BLOCK_USER' | translate }}"
  [(visible)]="isShowBlockUser"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  styleClass="w-[380px] rounded-xl block-comment"
  [modal]="true">
  <span
    class="text-base font-normal text-palette-gray-600"
    [innerHTML]="'FRIEND.BLOCK_CONFIRM' | translate: { userName: commentData.created_by.full_name }"></span>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}"
        (click)="confirmBlockUser()"
        class="bg-palette-red-600 hover:bg-palette-red-700 border-palette-rose-700"></button>
      <button
        pButton
        label="{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}"
        (click)="cancelBlockUser()"
        class="text-palette-gray-700 bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200"></button>
    </div>
  </ng-template>
</p-dialog>
