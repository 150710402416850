import { Injectable } from '@angular/core';
import { CoinManagementService } from '@app/core/services/coin-management.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import * as CoinTypes from './actions';

@Injectable()
export class CoinEffects {
  constructor(
    private actions$: Actions,
    private coinService: CoinManagementService
  ) {}

  getAvailableCoinAmounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoinTypes.getAvailableCoinAmounts),
      switchMap(() =>
        this.coinService.getAvailableCoinAmounts().pipe(
          switchMap(response => {
            return of(CoinTypes.getAvailableCoinAmountsSuccess({ availableCoinAmounts: response }));
          })
        )
      )
    )
  );
}
