import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckInService } from '@app/core/services/check-in.service';
import { SearchService } from '@app/core/services/search.service';
import { CheckInAPI } from '@app/lib/api/check-in/api.check-in.model';
import { FanPages } from '@app/modules/main/states/search/search.state';
import { BehaviorSubject, Subscription, debounceTime } from 'rxjs';

interface Status {
  status: string;
}
@Component({
  selector: 'post-dialog-tag-status',
  templateUrl: './post-dialog-tag-status.component.html',
  styleUrls: ['./post-dialog-tag-status.component.scss']
})
export class PostDialogTagStatusComponent {
  @Input() selectedStatus: string = '';
  @Input() selectedLocation?: CheckInAPI.CheckInResponse;
  @Output() selectedLocationEmit = new EventEmitter();
  @Input() isCheckIn: boolean;
  @Output() selected = new EventEmitter();

  inputValue: string = '';
  searchEmoji = '';
  // CheckIn variable
  searchText = new BehaviorSubject<string>('');
  private searchSubscription: Subscription;
  locations?: CheckInAPI.LocationSelection[];
  totalPages: number = 0;
  currentPage: number = 0;
  loadingMoreLocation: boolean = false;
  isEditting = false;

  constructor(
    private checkInService: CheckInService,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    if (this.isCheckIn) {
      // TODO: Call api get list suggest address
      // this.checkInService.getLocations().subscribe(res => {});
    }

    this.searchSubscription = this.searchText.pipe(debounceTime(500)).subscribe((searchText: string) => {
      this.totalPages = 0;
      this.currentPage = 0;
      if (!searchText) {
        this.locations = undefined;
        this.loadingMoreLocation = false;
        return;
      }
      this.loadingMoreLocation = true;
      this.searchService.getSearchFanpage(searchText, this.currentPage).subscribe({
        next: res => {
          this.locations = this.handleSetSelectedLocation(res.data.fanpageList, this.selectedLocation);
          this.totalPages = res.data.totalPages;
          this.currentPage = res.data.currentPage;
          this.loadingMoreLocation = false;
        }
      });
    });
  }

  onInputChange(searchText: string) {
    this.searchText.next(searchText);
  }

  onSearchEmoji(searchText: string) {
    this.searchEmoji = searchText;
  }

  handleSetSelectedLocation(
    locations: FanPages[],
    selectedLocation?: CheckInAPI.CheckInResponse
  ): CheckInAPI.LocationSelection[] {
    return locations.map(loc => {
      const isPageId = !!selectedLocation && loc.page_id === selectedLocation.object_id;
      const newLoc = { isActive: false, name: loc.page_name, id: loc.page_id };
      return {
        ...newLoc,
        isActive: isPageId
      };
    });
  }

  onSelect = (item: Status) => {
    this.selectedStatus = this.selectedStatus === item.status ? '' : item.status;
    const data: { selectedStatus: string; isEditting: boolean } = {
      selectedStatus: this.selectedStatus,
      isEditting: true
    };
    this.selected.emit(data);
  };

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  handleLoadMoreFanpage(): void {
    const searchTextObservable: string = this.searchText.getValue();
    if (this.currentPage < this.totalPages) {
      this.loadingMoreLocation = true;
      this.searchService.getSearchFanpage(searchTextObservable, this.currentPage + 1).subscribe({
        next: res => {
          if (this.locations)
            this.locations = [
              ...this.locations,
              ...this.handleSetSelectedLocation(res.data.fanpageList, this.selectedLocation)
            ];
          this.totalPages = res.data.totalPages;
          this.currentPage = res.data.currentPage;
          this.loadingMoreLocation = false;
        }
      });
    }
  }
}
