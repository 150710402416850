<invite-friends-dialog
  [title]="'COMMON.INVITE_FRIENDS'"
  [(isVisible)]="isVisible"
  [isLoading]="isLoadingFriends"
  [friendList]="inviteFriendList"
  [invitingFriends]="invitingFriends"
  [friendCheckboxes]="friendCheckboxes"
  [isLoadingMore]="isLoadingMoreFriends"
  (onShow)="onShowDialog()"
  (loadMore)="loadMoreFriends()"
  (onSearchFriends)="onSearchFriends($event)"
  (isVisibleChange)="onVisibleChange($event)"
  (isConfirmedSendInvites)="onSendInviteToFriends($event)"></invite-friends-dialog>
