import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, retry, timeout } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApiClientConfig } from '../api-client.config';
import { TourBuilder, TourBuilderResponse } from './api.tour-builder.models';

export class TourBuilderApi {
  private apiUrl = environment.baseURL;
  PATH = '/tour-builder';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig) { }

  getTopPlace(): Observable<TourBuilder[]> {
    return this.http
      .get<TourBuilderResponse>(`${this.apiUrl}${this.PATH}/place-visits/top-place-with-most-related-tour`)
      .pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map((res) => {
          return res.data.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
