<div class="relative bg-palette-gray-800 rounded-2xl w-[42vh] max-w-[345px]"
[style.height]="'calc(95vh - 165px - 20vh)'">
  <img *ngIf="dataStory.story_type === mediaType.image" 
    class="absolute w-full h-full rounded-2xl object-cover opacity-30" 
    [src]="validUrl()" defaultImage />
  <video *ngIf="dataStory.story_type === mediaType.video" [autoplay]="false"
    [src]="validUrl()" 
    class="absolute w-full h-full rounded-2xl object-cover opacity-30"></video>
  <div class="absolute w-full h-full flex flex-col justify-center items-center">
    <avatar [isDisableNavigate]="true" [isSmallThumbnail]="true"
      [userInfo]="dataStory.user_object"></avatar>
    <div class="text-palette-gray-300 text-lg flex justify-center items-center gap-1 max-w-[95%]">
      <span class="truncate">{{ dataStory.user_object.full_name }}</span>
      <div class="flex justify-center items-center relative w-[30px] h-[18px]" *ngIf="dataStory.user_object && dataStory.user_object.is_verified_user">
        <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
        <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/[16px]"></i>
      </div>
    </div>
    <div class="text-palette-gray-300 text-sm mr-1.5">
      {{ getFromNow(dataStory?.created_at) }}
    </div>
  </div>
</div>