<div
  class="bg-palette-base-white rounded-md md:rounded-xl flex flex-col gap-4 md:gap-6"
  [ngClass]="{ 'border-palette-gray-200 border': border, 'p-4 md:p-6': padding }"
  [ngStyle]="{ 'height': height }">
  <ng-container *ngIf="lineChartData; else loadingSkeleton">
    <div class="flex flex-col gap-1" *ngIf="lineChartData.title">
      <p class="text-palette-gray-500 font-medium">{{ 'STATISTIC.TITLES.' + lineChartData.title | translate }}</p>
      <div class="flex items-center gap-2">
        <span class="text-3xl text-palette-gray-900 font-semibold" title="{{ sumOfDataLine1 }}">{{
          sumOfDataLine1 | shortNumber
        }}</span>
        <ng-container
          [ngTemplateOutlet]="averagePercentage"
          [ngTemplateOutletContext]="{
            dailyAverages: dailyAverages
          }"></ng-container>
      </div>
    </div>
    <div class="flex flex-col md:flex-row gap-1 md:gap-3 items-center" *ngIf="datasetPosition === datasetsPosition.top">
      <ng-container *ngFor="let dataset of lineChartData.datasets">
        <div class="flex items-center">
          <ng-container *ngIf="!dataset.lineDashed?.length; else borderDashed">
            <div class="w-3 h-[2px] rounded-full {{ 'bg-[' + dataset.lineColor + ']' }}"></div>
          </ng-container>
          <ng-template #borderDashed>
            <div class="w-3 h-[2px] flex gap-[1px]">
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
            </div>
          </ng-template>
          <div class="py-1 px-2 flex gap-2 text-xs text-palette-gray-700">{{ dataset.label }}</div>
        </div>
      </ng-container>
    </div>
    <p-chart type="line" [data]="data" [options]="options" height="214px" [plugins]="plugins"></p-chart>
    <div
      class="flex flex-col md:flex-row gap-1 md:gap-3 justify-end items-center"
      *ngIf="datasetPosition === datasetsPosition.bottom">
      <ng-container *ngFor="let dataset of lineChartData.datasets">
        <div class="flex items-center">
          <ng-container *ngIf="!dataset.lineDashed?.length; else borderDashed">
            <div class="w-3 h-[2px] rounded-full {{ 'bg-[' + dataset.lineColor + ']' }}"></div>
          </ng-container>
          <ng-template #borderDashed>
            <div class="w-3 h-[2px] flex gap-[1px]">
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
              <div class="w-[3px] rounded-sm" [ngStyle]="{ 'background-color': dataset.lineColor }"></div>
            </div>
          </ng-template>
          <div class="py-1 px-2 flex gap-2 text-xs text-palette-gray-700">{{ dataset.label }}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #averagePercentage let-dailyAverages="dailyAverages">
  <div class="text-xs font-medium" *ngIf="!isNaNNumber(dailyAverages)">
    <span
      *ngIf="dailyAverages > -0.005 && dailyAverages < 0.005; else dailyAverageChanged"
      class="rounded-full px-2 h-5 flex items-center bg-palette-gray-100 text-palette-gray-700"
      >0%</span
    >
    <ng-template #dailyAverageChanged>
      <span
        class=" rounded-full px-1 h-5 flex items-center {{
          dailyAverages > 0 === isLowerBetter
            ? ' bg-palette-red-50 text-palette-red-600'
            : ' bg-palette-emerald-50 text-palette-emerald-600'
        }}"
        title="{{ sumOfDataLine2 ? (dailyAverages | percent: '1.0-5') : (sumOfDataLine1 | shortNumber) }}">
        <span
          class="text-base leading-4 align-middle mr-1 {{
            dailyAverages > 0 ? 'sctr-icon-arrow-up' : 'sctr-icon-arrow-down'
          }}"></span>
        <span class="mr-1">{{
          sumOfDataLine2 ? (dailyAverages | percent: '1.0-0') : (sumOfDataLine1 | shortNumber)
        }}</span>
      </span>
    </ng-template>
  </div>
</ng-template>

<ng-template #loadingSkeleton>
  <div class="bg-palette-base-white rounded-md md:rounded-xl flex flex-col gap-4 md:gap-6 p-4 md:p-6">
    <div class="flex items-center gap-2">
      <p-skeleton width="6rem" height="2.5rem" borderRadius="4px" />
      <p-skeleton width="2rem" height="1.5rem" borderRadius="4px" />
    </div>

    <div class="flex flex-col md:flex-row gap-1 md:gap-3 items-center">
      <div class="flex items-center">
        <p-skeleton width="3rem" height="0.5rem" borderRadius="2px" styleClass="mr-2" />
        <p-skeleton width="4rem" height="1.5rem" borderRadius="4px" />
      </div>
      <div class="flex items-center">
        <p-skeleton width="3rem" height="0.5rem" borderRadius="2px" styleClass="mr-2" />
        <p-skeleton width="4rem" height="1.5rem" borderRadius="4px" />
      </div>
    </div>
    <p-skeleton width="100%" height="214px" borderRadius="4px" />
  </div>
</ng-template>
