export const countryCode = [
  { name: 'China', code: '+86', icon: 'assets/icons/CN.svg' },
  { name: 'France', code: '+33', icon: 'assets/icons/FR.svg' },
  { name: 'Japan', code: '+81', icon: 'assets/icons/JP.svg' },
  { name: 'Korea', code: '+82', icon: 'assets/icons/KR.svg' },
  { name: 'Russia', code: '+7', icon: 'assets/icons/RU.svg' },
  { name: 'United Kingdom', code: '+44', icon: 'assets/icons/GB.svg' },
  { name: 'United States', code: '+1', icon: 'assets/icons/US.svg' },
  { name: 'Viet Nam', code: '+84', icon: 'assets/icons/VN.svg' }
];
