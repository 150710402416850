import { Component } from '@angular/core';
import { CoinManagementService } from '@app/core/services/coin-management.service';
import { TranslationService } from '@app/core/services/translation.service';
import { TransactionDetailModel } from '@app/lib/api/coin-exchange/api.coin.model';
import * as moment from 'moment';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, TRANSACTION_TYPE, TYPE_SEND_COIN, USER_STATUS } from './../../constant';

@Component({
  selector: 'coin-history',
  templateUrl: './coin-history.component.html',
  styleUrls: ['./coin-history.component.scss']
})
export class CoinHistoryComponent {
  pageNum = PAGE_NUM_DEFAULT;
  pageSize = PAGE_SIZE_DEFAULT;
  transactionType = TRANSACTION_TYPE;
  loadingData = false;
  loadingMoreData = false;
  monthGroups: { month: string; items: TransactionDetailModel[] }[] = [];
  constructor(
    private coinManagementService: CoinManagementService,
    private translateService: TranslationService
  ) { }

  ngOnInit() {
    this.getData(true);
    window.addEventListener('scroll', this.onWindowScroll);
  }

  getData(isInit = false) {
    if (isInit) this.loadingData = true;
    this.coinManagementService.getTransactionHistory(this.pageNum, this.pageSize).subscribe(res => {
      this.groupByMonth(res.content);
      if (res.last === true) {
        window.removeEventListener('scroll', this.onWindowScroll);
      }
      this.loadingData = false;
      this.loadingMoreData = false;
    });
  }

  groupByMonth(transactionData: TransactionDetailModel[]) {
    const groups: { [month: string]: TransactionDetailModel[] } = {};
    transactionData.forEach(item => {
      const month = new Date(item.created_at).toLocaleString('en-US', { month: 'long', year: 'numeric' });
      if (!groups[month]) {
        groups[month] = [];
      }
      groups[month].push(item);
    });
    if (this.monthGroups.length) {
      Object.keys(groups).forEach(month => {
        if (this.monthGroups.some(group => group.month === month)) {
          this.monthGroups.find(group => group.month === month)?.items.push(...groups[month]);
        } else {
          this.monthGroups.push({ month, items: groups[month] });
        }
      });
    } else {
      this.monthGroups = Object.keys(groups).map(month => ({ month, items: groups[month] }));
    }
  }

  convertToCustomFormat(inputDateString: string): string {
    const inputDate = moment(inputDateString);
    const formattedDateString = inputDate.format('DD/MM/YYYY, HH:mm ');
    return formattedDateString;
  }

  generateTitleTransaction(item: TransactionDetailModel) {
    switch (item.transaction_type) {
      case TRANSACTION_TYPE.sendPost:
        return `XU.SEND_XU_TO_POS_OF`;
      case TRANSACTION_TYPE.send:
        return 'XU.SEND_XU_TO';
      case TRANSACTION_TYPE.received:
        return 'XU.RECEIVED_XU_FROM';
      case TRANSACTION_TYPE.claim:
        if (item.des_info.info.type === 2) {
          return 'XU.RECEIVED_XU_FROM_POSTS';
        } else {
          return 'XU.RECEIVED_XU_FROM_INVITED_FRIEND';
        }
      case TRANSACTION_TYPE.firstOrder:
        return 'XU.FIRST_ORDER';
      case TRANSACTION_TYPE.newUserFirstOrder:
        return 'XU.NEW_USER_FIRST_ORDER';
      default:
        return '';
    }
  }

  generateIconTransaction(item: TransactionDetailModel) {
    if (item.transaction_type === TRANSACTION_TYPE.sendPost) {
      return 'assets/icons/send_coin.svg';
    } else if (item.transaction_type === TRANSACTION_TYPE.send) {
      return 'assets/icons/send_coin.svg';
    } else if (item.transaction_type === TRANSACTION_TYPE.received) {
      return 'assets/icons/received_coin_user.svg';
    } else if (item.transaction_type === TRANSACTION_TYPE.claim) {
      if (item.des_info.info.type === 2) {
        return 'assets/icons/claim_post.svg';
      } else {
        return 'assets/icons/invited_friend.svg';
      }
    } else return '';
  }

  checkTransactionType(type: string) {
    const increaseXUTypeList = [this.transactionType.received, this.transactionType.receivedPost, this.transactionType.claim, this.transactionType.deposit, this.transactionType.firstOrder, this.transactionType.newUserFirstOrder];
    return increaseXUTypeList.includes(type);
  }

  onWindowScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.loadingMoreData) {
      if (!this.loadingData) {
        this.pageNum++;
        this.loadingMoreData = true;
        this.getData();
      }
    }
  };

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  generateNameObject(info: any) {
    if (info.des_info?.info?.page_name || info.des_info?.info?.status === USER_STATUS.ACTIVE) {
      if (info.des_info.object_type === TYPE_SEND_COIN.fanpage) {
        return info.des_info?.info?.page_name;
      } else {
        return info.des_info?.info?.full_name;
      }
    } else if (info.des_info?.info?.status === USER_STATUS.INACTIVE) {
      return this.translateService.getTranslation('XU.ACCOUNT_DEACTIVATED');
    }
    if (info.transaction_type === this.transactionType.deposit) {
      return this.translateService.getTranslation('XU.DEPOSIT');
    }
  }

  translateMonth(month: string): string {
    const splitted = month.split(' ');
    const getMonth = splitted[0];
    const getYear = splitted[1];
    const uppercase = getMonth.substring(0, 3).toUpperCase();
    return `${this.translateService.getTranslation(`COMMON.DATE_TIME.MONTH.${uppercase}`)} ${getYear}` || '';
  }
}
