<div>
  <div class="post-header rounded-md boder border-solid border-gray-200 bg-palette-base-white mb-2">
    <div class="border-b border-solid border-gray-200 flex flex-row justify-between p-4">
      <p class="text-gray-900 font-semibold text-lg">
        {{ 'GROUP.SETTING.REPORTED_CONTENTS' | translate }}
      </p>
      <div class="flex flex-row gap-3">
        <div class="relative filter-content">
          <button
            class="h-10 rounded-lg border border-solid border-gray-300 py-2.5 px-4 flex flex-row gap-2 items-center"
            (click)="showOverlay()">
            <p class="pr-4 text-gray-700 font-semibold text-sm">
              <span class="sctr-icon-filter-lines text-base"></span>
              {{ 'GROUP.SETTING.FILTER' | translate }}
            </p>
          </button>
          <p-overlay
            [(visible)]="isShowQrOverlay"
            [contentStyleClass]="'border-round absolute top-2 right-0 w-fit h-fit'"
            [appendTo]="overlayElement">
            <div
              *ngIf="isShowQrOverlay"
              class="p-6 border-round bg-palette-base-white rounded-lg drop-shadow-md w-max custom-overlay">
              <div class="w-full flex flex-col gap-4">
                <div class="flex gap-4">
                  <div
                    class="w-full mr-3 h-fit flex flex-col text-start rounded-lg border-none bg-palette-base-white text-palette-gray-700">
                    <span class="flex pb-[6px] text-branding-gray-700 text-sm font-medium">{{
                      'GROUP.SETTING.CATEGORY_OF_REPORT' | translate
                    }}</span>
                    <div class="flex w-[208px]">
                      <p-multiSelect
                        class="w-full"
                        [styleClass]="'max-w-[208px] min-w-[208px]'"
                        placeholder="{{ 'GROUP.SETTING.SELECT_CATEGORY' | translate }}"
                        [options]="categoryList"
                        [(ngModel)]="selectedCategory"
                        optionLabel="translatedName"
                        [filter]="false">
                        <ng-template let-item pTemplate="item">
                          <span class="text-sm overflow" [title]="item.translatedName">{{ item.translatedName }}</span>
                        </ng-template>
                      </p-multiSelect>
                    </div>
                  </div>
                  <div
                    class="w-full h-fit flex flex-col text-start rounded-lg border-none bg-palette-base-white text-palette-gray-700">
                    <span class="flex pb-[6px] text-branding-gray-700 text-sm font-medium">{{
                      'GROUP.SETTING.STATUS' | translate
                    }}</span>
                    <div class="flex w-[208px]">
                      <p-multiSelect
                        class="w-full"
                        [styleClass]="'max-w-[208px] min-w-[208px]'"
                        placeholder="{{ 'GROUP.SETTING.SELECT_STATUS' | translate }}"
                        [options]="status"
                        [(ngModel)]="selectedStatus"
                        optionLabel="name"
                        [filter]="false"
                        (ngModelChange)="updateApplyButtonState()">
                        <ng-template let-item pTemplate="item">
                          <span class="text-sm">{{ item.name | translate }}</span>
                        </ng-template>
                      </p-multiSelect>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <span class="flex pb-[6px] text-branding-gray-700 text-sm font-medium">{{
                    'GROUP.SETTING.DATE' | translate
                  }}</span>
                  <div class="w-full h-[45px]">
                    <div class="flex w-full relative calendar-event">
                      <p-calendar
                        id="calendar"
                        class="w-full custom-calendar"
                        inputStyleClass="pl-11"
                        [(ngModel)]="rangeDates"
                        selectionMode="range"
                        [numberOfMonths]="1"
                        [appendTo]="overlayElement"
                        styleClass="w-full"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        (ngModelChange)="onDateChange($event)"></p-calendar>
                      <span class="cursor-pointer absolute left-[14px] top-[7px]"
                        ><i class="sctr-icon-calendar text-lg"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex justify-end pt-8 gap-3">
                <p-button
                  class="t-declineButton border rounded-lg"
                  styleClass=" hover:bg-white border-0 p-4 h-10 text-sm font-semibold"
                  label="{{ 'GROUP.SETTING.RESET' | translate }}"
                  (onClick)="handleResetFilter()"></p-button>
                <p-button
                  (click)="handleApplyFilter()"
                  [disabled]="!isApplyButtonEnabled"
                  styleClass="save-btn w-full h-11 px-[18px] py-[10px] rounded-lg font-semibold text-base text-white border border-branding-primary-600 bg-branding-primary-600 hover:bg-branding-primary-700"
                  label="{{ 'GROUP.SETTING.APPLY' | translate }}"></p-button>
              </div>
            </div>
          </p-overlay>
        </div>
      </div>
    </div>
    <div class="action flex flex-row justify-between items-center p-4">
      <div class="flex flex-row gap-4">
        <p-checkbox
          class="h-5 w-5"
          [(ngModel)]="checked"
          [binary]="true"
          inputId="selected"
          checkboxIcon="pi pi-minus"
          (onChange)="selectedAllChange(checked)"></p-checkbox>
        <label class="text-gray-600 font-medium text-sm" for="selected"
          >{{ 'GROUP.SETTING.SELECTED' | translate }} ({{ selectedPost }})</label
        >
      </div>
      <div class="flex flex-row gap-2">
        <p-button
          class="t-declineButton"
          styleClass=" hover:bg-white border-0 p-4 h-10 text-sm font-semibold"
          icon="pi pi-times"
          label="{{ 'GROUP.SETTING.REJECT_ALL' | translate }}"
          (onClick)="DeclineAll()"
          [disabled]="selectedPost === 0"></p-button>
        <p-button
          class="t-acceptButton"
          styleClass="p-4 h-10 rounded-lg  text-sm font-semibold"
          icon="pi pi-check"
          label="{{ 'GROUP.SETTING.APPROVE_ALL' | translate }}"
          (onClick)="ApproveAll()"
          [disabled]="selectedPost === 0"></p-button>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-4">
    <div *ngFor="let post of dataReportContent">
      <post-report
        [selectedPost]="selectedPost"
        [post]="post"
        (onAction)="handleOnSelect($event)"
        (selectedChange)="onSelectedPost($event)"
        [checked]="postItemCheck"></post-report>
    </div>
  </div>
</div>
<dialog-confirm [visible]="dialogVisible" (hideDialog)="onHide()" [header]="type">
  <div class="flex flex-col gap-1">
    <p class="text-sm font-normal text-gray-600">
      {{ text }}
    </p>
  </div>
  <div class="flex flex-row gap-3 justify-end pt-8">
    <button
      class="flex items-center h-11 px-2.5 text-base text-gray-700 font-semibold rounded-lg border border-solid border-gray-300"
      (click)="onHide()">
      {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
    </button>
    <button
      class="flex items-center h-11 bg-[#1570EF] text-white px-2.5 text-base font-semibold rounded-lg border border-solid border-[#1570EF]"
      (click)="confirmApprove()">
      {{ 'GROUP.SETTING.APPROVE' | translate }}
    </button>
  </div>
</dialog-confirm>

<p-dialog
  [(visible)]="isShowApproveDialog"
  [modal]="true"
  [style]="{ width: '600px' }"
  [draggable]="false"
  [closable]="false"
  (onHide)="handleClosModal()"
  [resizable]="false">
  <div class="flex flex-col pt-5 pb-8">
    <div class="flex flex-col gap-1 pb-5">
      <div class="flex justify-between">
        <span class="font-semibold text-lg text-palette-gray-900">{{
          'GROUP.SETTING.APPROVE_REQUEST' | translate
        }}</span>
        <div class="cursor-pointer flex items-center" (click)="isShowApproveDialog = false">
          <i class="sctr-icon-x-close text-gray-500"></i>
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <span class="text-palette-gray-600 text-sm font-normal">{{
          'GROUP.SETTING.ARE_YOU_SURE_YOU_WANT' | translate
        }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col gap-1.5">
        <span class="text-palette-gray-700 font-medium text-sm"
          >{{ 'GROUP.SETTING.APPROVAL_REASON' | translate }}<span class="ml-2 text-system-error-600">*</span></span
        >
        <textarea
          class="max-h-[128px] h-full rounded-lg"
          placeholder="{{ 'GROUP.SETTING.PLEASE_ENTER_APPROVAL_REASON' | translate }}"
          rows="5"
          cols="30"
          pInputTextarea
          [(ngModel)]="textareaValue"
          (input)="onInputChange()"
          required
          [autoResize]="true"></textarea>
      </div>
    </div>
  </div>
  <div class="flex justify-end">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-palette-base-white"
        type="submit"
        (click)="isShowApproveDialog = false">
        <p class="text-base font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-palette-base-white"
        type="submit"
        [ngClass]="{ 'bg-branding-primary-200': isTextareaEmpty, 'bg-branding-primary-600': !isTextareaEmpty }"
        [disabled]="isTextareaEmpty"
        (click)="handleApproveReportContent()">
        {{ 'GROUP.SETTING.APPROVE' | translate }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="isShowRejectDialog"
  [modal]="true"
  [style]="{ width: '600px' }"
  [draggable]="false"
  [closable]="false"
  (onHide)="handleClosModal()"
  [resizable]="false">
  <div class="flex flex-col pt-5 pb-8">
    <div class="flex flex-col gap-1 pb-5">
      <div class="flex justify-between">
        <span class="text-palette-gray-900 font-semibold text-lg">{{
          'GROUP.SETTING.REJECT_REQUEST' | translate
        }}</span>
        <div class="cursor-pointer flex items-center" (click)="isShowRejectDialog = false">
          <i class="sctr-icon-x-close text-gray-500"></i>
        </div>
      </div>
      <span class="flex text-palette-gray-600 text-sm font-normal">{{
        'GROUP.SETTING.ARE_YOU_SURE_YOU_WANT_TO_REJECT' | translate
      }}</span>
    </div>
    <div class="flex flex-col gap-1.5">
      <span class="text-palette-gray-700 font-medium text-sm"
        >{{ 'GROUP.SETTING.REJECT_REASON' | translate }}<span class="ml-2 text-system-error-600">*</span></span
      >
      <textarea
        class="max-h-[128px] h-full rounded-lg"
        placeholder="{{ 'GROUP.SETTING.PLEASE_ENTER_A_REJECT_REASON' | translate }}"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="textareaValueReject"
        (input)="onInputChangeReject()"
        required
        [autoResize]="true"></textarea>
    </div>
  </div>
  <div class="flex justify-end">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-palette-base-white"
        type="submit"
        (click)="isShowRejectDialog = false">
        <p class="text-base font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-palette-base-white"
        type="submit"
        [ngClass]="{
          'bg-system-error-600': !isTextareaEmptyReject,
          'bg-system-error-200': isTextareaEmptyReject
        }"
        [disabled]="isTextareaEmptyReject"
        (click)="handleRejectContent()">
        {{ 'GROUP.SETTING.REJECT' | translate }}
      </button>
    </div>
  </div>
</p-dialog>
