<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="avatarType.ringWithBorder">
    <div class="circle-avatar {{ rounded }} {{ size.widthHeight }} {{ size.ringPadding }} bg-palette-base-white">
      <div
        class="circle-avatar {{ rounded }} {{ size === avatarSize.small ? 'p-0.5' : 'p-[3px]' }} w-full h-full {{
          primaryRingColor
        }}">
        <ng-container [ngTemplateOutlet]="avatarImage"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="avatarType.ring">
    <div class="circle-avatar {{ rounded }} {{ size.widthHeight }}">
      <div class="circle-avatar {{ rounded }} {{ size.ringPadding }} w-full h-full {{ primaryRingColor }}">
        <ng-container [ngTemplateOutlet]="avatarImage"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="[avatarType.grayBorder, avatarType.whiteBorder].includes(type)">
  <div class="circle-avatar {{ rounded }} {{ size.widthHeight }}">
    <ng-container [ngTemplateOutlet]="avatarImage"></ng-container>
  </div>
</ng-container>

<ng-template #avatarImage>
  <div
    class="circle-avatar {{ rounded }} {{ size.ringPadding }} w-full h-full {{
      type === avatarType.grayBorder ? 'bg-palette-gray-200' : 'bg-palette-base-white'
    }} relative">
    <ng-container *ngIf="!!thumbnailURL; else initialsAvatar">
      <img
        class="w-full h-full {{ rounded }}"
        [src]="getThumbnailURL()"
        alt="Avatar"
        (error)="handleImageError($event)" />
    </ng-container>
    <ng-template #initialsAvatar>
      <p-avatar
        class="w-full h-full flex justify-center items-center"
        styleClass="w-full h-full {{ initialsAvatarColor }} {{ size.initialsAvatar }} {{
          initialsAvatarCursorPointer ? 'cursor-pointer' : 'cursor-default'
        }}  select-none"
        [shape]="rounded === 'rounded-full' ? 'circle' : ''"
        [label]="name | abbreviate"></p-avatar>
    </ng-template>
    <div
      *ngIf="clickable"
      class="absolute top-0 right-0 w-full h-full cursor-pointer hover:bg-palette-gray-900/10 transition-colors"
      (click)="onCLickAvatar()"></div>
  </div>
</ng-template>
