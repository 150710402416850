import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { modalMemberGroupPending } from '@app/lib/api/group/api.group.model';
import { environment } from '@env/environment';

@Component({
  selector: 'member-item',
  templateUrl: './member-item.component.html',
  styleUrls: ['./member-item.component.scss']
})
export class MemberItemComponent {
  @Input() data: modalMemberGroupPending | null;
  @Input() selectedMember: number;
  @Output() approveMember = new EventEmitter();
  @Output() banMember = new EventEmitter();
  @Output() declineMember = new EventEmitter();
  @Output() onClickCheckItem = new EventEmitter();
  @Input() checked: boolean | undefined = false;
  @Input() isLoadingActionItem: boolean;
  checkBoxValue: boolean;
  baseUrl: string = environment.baseURL;
  isShowBlockDialog: boolean = false;
  isShowDeclineDialog: boolean = false;
  isShowAcceptDialog: boolean = false;
  user_id: string | null = null;

  constructor(
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkBoxValue = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checked']) {
      if (this.checked !== undefined) {
        this.checkBoxValue = this.checked;
      }
    }
  }

  handleBanMember(user_id: string | null = null) {
    this.user_id = user_id;
    this.isShowBlockDialog = true;
  }

  banUser() {
    this.banMember.emit(this.user_id);
  }

  handleDeclineMember(user_id: string | null = null) {
    this.user_id = user_id;
    this.isShowDeclineDialog = true;
  }

  declineUser() {
    this.declineMember.emit(this.user_id);
  }

  handleApproveMember(user_id: string | null = null) {
    this.user_id = user_id;
    this.isShowAcceptDialog = true;
  }

  acceptUser() {
    this.approveMember.emit(this.user_id);
  }

  onSelectedChange(checked: boolean, data: any) {
    const prams = {
      checked: checked,
      data: data
    };
    this.onClickCheckItem.emit(prams);
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  navigateToProfile(item: any): void {
    const userId = item.user_id || '';
    const username = item.username || '';
    this.router.navigate([`/personal-profile/${username || userId}`]);
  }
}
