import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DeletedAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isDeletedAccount =
      JSON.parse(localStorage.getItem('is_scheduled_deleted') ?? '[]')?.is_scheduled_deleted || false;

    const isUndeleteRoute = state.url.startsWith('/undelete-account');
    const returnUrl = this.authService.getReturnUrl();
    const navigationExtras: NavigationExtras = { queryParams: { r: returnUrl } };

    if (isUndeleteRoute) {
      if (returnUrl && isDeletedAccount) {
        return true;
      } else if (!isDeletedAccount) {
        this.router.navigate(['/']);
        return false;
      }
    } else if (isDeletedAccount) {
      this.router.navigate(['/undelete-account'], returnUrl ? navigationExtras : {});
    }

    return true;
  }
}
