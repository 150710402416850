import { HttpClient } from '@angular/common/http';
import { ParamsSearch } from '@app/modules/main/advertisement/component/advertisement-create/component/ads-manager/dialog-select-post/dialog-select-post.component';
import { GroupPost } from '@app/modules/main/group/components/group-tab/group-timeline/group-timeline.component';
import { SORT } from '@app/shared/constant';
import { Post, QuickReportRequest } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { isUndefined, omitBy } from 'lodash-es';
import { Observable, catchError, map, retry, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { PostPending } from '../group/api.group.model';
import {
  ArchiveResponse,
  DeletePostResponse,
  DetailPost,
  PinPostResponse,
  PostArrayResponse,
  PostListPaging,
  PostListResponse,
  PostRequest,
  PostResponse,
  QuickReportResponse,
  TopPerfPostResponse
} from './api.post.model';

export class PostApi {
  private apiUrl = environment.baseURL;
  POST = '/posts/posts';
  POST_STATS = {
    user: '/posts/stats/user',
    group: '/posts/stats/group',
    fanpage: '/posts/stats/page'
  };
  ADS = '/ads';
  QUICK_REPORT = '/quick-report';
  LINK_PREVIEW = '/link-preview';
  DETAIL = '/detail';
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getPostsCurrentUser(pageNum: number, pageSize: number, sortByDate: string): Observable<Post[]> {
    return this.http
      .get<PostArrayResponse>(
        `${this.apiUrl}${this.POST}/me?pageNum=${pageNum}&pageSize=${pageSize}&sortByDate=${sortByDate}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res.data;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  getPostsByUser(
    userId: string,
    pageNum: number,
    pageSize: number,
    isFriend: boolean,
    sortByDate: string
  ): Observable<Post[]> {
    return this.http
      .get<PostArrayResponse>(
        `${this.apiUrl}${this.POST}/user?pageNum=${pageNum}&pageSize=${pageSize}&userId=${userId}&isFriend=${isFriend}&sortByDate=${sortByDate}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res.data;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  getPostById(id: string): Observable<PostResponse> {
    return this.http.get<PostResponse>(`${this.apiUrl}${this.POST}/detail/${id}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  createPost(post: PostRequest): Observable<PostResponse> {
    return this.http.post<PostResponse>(`${this.apiUrl}${this.POST}`, post).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  createPostNewAlbum(post: PostRequest): Observable<Post> {
    return this.http.post<PostResponse>(`${this.apiUrl}${this.POST}/album`, post).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  sharePost(post: PostRequest): Observable<PostResponse> {
    return this.http.post<PostResponse>(`${this.apiUrl}${this.POST}/share`, post).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  updatePost(post: PostRequest, id: string): Observable<Post> {
    post = omitBy(post, isUndefined);
    return this.http.put<PostResponse>(`${this.apiUrl}${this.POST}/${id}`, post).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  updateSharedPost(post: PostRequest, id: string): Observable<Post> {
    post = omitBy(post, isUndefined);
    return this.http.put<PostResponse>(`${this.apiUrl}${this.POST}/update-share/${id}`, post).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getPosts(pageNum: number, pageSize: number): Observable<Post[]> {
    return this.http
      .get<PostArrayResponse>(`${this.apiUrl}${this.POST}/newsfeeds?pageNum=${pageNum}&pageSize=${pageSize}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res.data;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  deletePost(id: string): Observable<boolean> {
    return this.http.delete<DeletePostResponse>(`${this.apiUrl}${this.POST}/${id}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.success;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getQuickReport() {
    return this.http.get<QuickReportResponse>(`${this.apiUrl}${this.QUICK_REPORT}/quick-report`).pipe(
      map((res: QuickReportResponse) => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getLinkPreview(url: string) {
    return this.http
      .get<QuickReportResponse>(`${this.apiUrl}${this.LINK_PREVIEW}/link-previews/link-preview-by-lib?url=${url}`)
      .pipe(
        map((res: QuickReportResponse) => {
          return res;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  putQuickReport(data: QuickReportRequest) {
    return this.http.put<QuickReportResponse>(`${this.apiUrl}${this.QUICK_REPORT}/quick-report`, data).pipe(
      map((res: QuickReportResponse) => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getGroupViewPost(groupId: string, params: GroupPost) {
    let apiViewPostGroup = `${this.apiUrl}/posts/posts/group?pageNum=${params.pageNum}&pageSize=${params.pageSize}&groupId=${groupId}&sortByDate=${params.sortByDate}`;
    return this.http.get<PostPending[]>(apiViewPostGroup).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getGroupReportContentPost(groupId: string): Observable<any[]> {
    let apiReportGroup = `${this.apiUrl}/report/report?pageNum=0&pageSize=10&object_parent_id=${groupId}&objectType=POST&sortByDate=DESCENDING`;
    return this.http.get<any>(apiReportGroup);
  }

  getGroupTopPerfPost(groupId: string): Observable<TopPerfPostResponse> {
    return this.http.get<TopPerfPostResponse>(`${this.apiUrl}${this.POST_STATS.group}/${groupId}/top-performance`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getFanpageTopPerfPost(fanpageId: string): Observable<TopPerfPostResponse> {
    return this.http
      .get<TopPerfPostResponse>(`${this.apiUrl}${this.POST_STATS.fanpage}/${fanpageId}/top-performance`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  getFanpagePostList(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc,
    excludeAds: boolean
  ): Observable<PostListResponse> {
    const params = `pageId=${fanpageId}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}&excludeAds=${excludeAds}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/page?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getFanpageStatsPostList(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListResponse> {
    const params = `pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST_STATS.fanpage}/${fanpageId}?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getFanpagePendingPosts(
    fanpageId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListResponse> {
    const params = `pageId=${fanpageId}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/page/pending?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  searchFanpagePostList(
    fanpageId: string,
    status: string[],
    searchKey: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.asc
  ): Observable<PostListResponse> {
    const params = `pageId=${fanpageId}&status=${status}&text=${searchKey}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/page/search?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getFanpageDetailPost(postId: string): Observable<DetailPost> {
    return this.http.get<DetailPost>(`${this.apiUrl}${this.POST}${this.DETAIL}/${postId}`).pipe(
      map((res: DetailPost) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getGroupPostList(groupId: string, pageSize = 10, pageNum = 0, sortByDate = SORT.desc): Observable<PostListResponse> {
    const params = `groupId=${groupId}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/group?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getGroupStatsPostList(
    groupId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListResponse> {
    const params = `pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST_STATS.group}/${groupId}?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getGroupPendingPosts(
    groupId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListResponse> {
    const params = `groupId=${groupId}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/group/pending?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  searchGroupPostList(
    groupId: string,
    status: string[],
    searchKey: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.asc
  ): Observable<PostListResponse> {
    const params = `groupId=${groupId}&status=${status}&text=${encodeURI(
      searchKey || ''
    )}&pageSize=${pageSize}&pageNum=${pageNum}&sortByDate=${sortByDate}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}/group/search?${params}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  reviewPendingPosts(objectID: string, postIDs: string[], isApproved: boolean): Observable<PostListResponse> {
    const body = {
      posts: postIDs,
      censorship_status: isApproved
    };
    return this.http.post<PostListResponse>(`${this.apiUrl}${this.POST}/censorship/${objectID}`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getFanpageSearchDetailPost(
    fanpageId: string,
    params: ParamsSearch,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc,
    status: string[]
  ): Observable<PostListResponse> {
    let search = '';
    search += this.convert(params.text, 'text');
    search += this.convert(params.fromDate, 'fromDate');
    search += this.convert(params.toDate, 'toDate');
    const apiSearch = `/page/search?pageNum=${pageNum}&pageSize=${pageSize}&pageId=${fanpageId}&status=${status}&sortByDate=${sortByDate}&${search}`;
    return this.http.get<PostListResponse>(`${this.apiUrl}${this.POST}${apiSearch}`);
  }

  convert(param: any, name: string) {
    return param ? `${name}=${param}&` : '';
  }

  updateAdsPost(adsId: string, id: string): Observable<Post> {
    const ads_id = {
      ads_id: adsId
    };
    return this.http.put<PostResponse>(`${this.apiUrl}${this.POST}${this.ADS}/${id}`, ads_id).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getPinnedOwnerPost(): Observable<PostArrayResponse> {
    const apiUrl = '/me/pin';
    return this.http.get<PostArrayResponse>(`${this.apiUrl}${this.POST}${apiUrl}`);
  }

  getPinedPostUser(data: { userId: string; isFriend: boolean }): Observable<PostArrayResponse> {
    const { userId, isFriend } = data;
    const apiUrl = `/user/pin?userId=${userId}&isFriend=${isFriend}`;
    return this.http.get<PostArrayResponse>(`${this.apiUrl}${this.POST}${apiUrl}`);
  }

  editPostPin(data: { postId: string; isPinned: boolean }): Observable<PinPostResponse> {
    const { postId, isPinned } = data;
    const apiUrl = `/pin/${postId}?isPinned=${isPinned}`;
    return this.http.put<PinPostResponse>(`${this.apiUrl}${this.POST}${apiUrl}`, {});
  }

  createArchivePost(postId: string[], isArchive: boolean): Observable<ArchiveResponse> {
    const data = {
      posts: postId,
      is_archive: isArchive
    };
    const apiUrl = `/archive`;
    return this.http.put<ArchiveResponse>(`${this.apiUrl}${this.POST}${apiUrl}`, data);
  }

  getArchivedPosts(pageNum: number, pageSize: number, sortByDate = SORT.desc): Observable<PostArrayResponse> {
    const url = `/me/archive?pageNum=${pageNum}&pageSize=${pageSize}&sortByDate=${sortByDate}`;
    return this.http.get<PostArrayResponse>(`${this.apiUrl}${this.POST}${url}`);
  }

  getPendingTaggedPosts(pageNum: number, pageSize: number, sortByDate = SORT.desc): Observable<PostListPaging> {
    const url = `/me/pending-tagged?pageNum=${pageNum}&pageSize=${pageSize}&sortByDate=${sortByDate}`;
    return this.http.get<PostListPaging>(`${this.apiUrl}${this.POST}${url}`);
  }

  removeTaggedPosts(postIDs: string[], isApproved: boolean): Observable<Post[]> {
    const body = {
      post_ids: postIDs,
      is_approve: isApproved
    };
    return this.http.put<PostListResponse>(`${this.apiUrl}${this.POST}/approve-or-decline-tags`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
