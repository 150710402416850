import { Component, Input } from '@angular/core';
import { StatisticTotalOverview } from '../../statistics.models';

@Component({
  selector: 'statistics-overview',
  templateUrl: './statistics-overview.component.html',
  styleUrls: ['./statistics-overview.component.scss']
})
export class StatisticsOverviewComponent {
  @Input() totalsOverviewData: StatisticTotalOverview[];

  isNumber(value: any): boolean {
    return !isNaN(value);
  }
}
