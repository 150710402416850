import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@app/core/services/group.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { getGroupRule } from '@app/modules/main/group/store/actions';
import { GroupInterfaceState } from '@app/modules/main/group/store/reducer';
import { selectGroupRule } from '@app/modules/main/group/store/selectors';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { Store, select } from '@ngrx/store';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'group-rules',
  templateUrl: './group-rules.component.html',
  styleUrls: ['./group-rules.component.scss']
})
export class GroupRulesComponent implements OnInit {
  @Output() isBackToGroupSettings = new EventEmitter();
  groupRule: string;
  groupId: string | null;
  isShowEditRule: boolean = false;
  isLoadingSkeleton = false;

  constructor(
    private store: Store<GroupInterfaceState>,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private TranslationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private imageCompress: NgxImageCompressService
  ) {
    this.getGroupRule();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });
    if (this.groupId) {
      this.store.dispatch(getGroupRule({ groupId: this.groupId }));
    }
  }

  getGroupRule(): void {
    this.isLoadingSkeleton = true;
    this.store.pipe(select(selectGroupRule)).subscribe(res => {
      this.groupRule = res?.content;
      this.isLoadingSkeleton = false;
    });
  }

  handleGroupRuleCancel() {
    this.isShowEditRule = false;
  }

  handleGroupRuleSave() {
    this.compressImage(this.groupRule).then(compressedGroupRule => {
      this.groupService.getGroupRules(compressedGroupRule, this.groupId).subscribe(item => {
        if (item.success) {
          this.groupRule = item?.data?.content;
          this.isShowEditRule = false;
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.success,
            this.TranslationService.getTranslation('GROUP.MESSAGE.SUCCESS_UPDATE_RULES')
          );
        } else {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            this.TranslationService.getTranslation('ERROR.GROUP.ERROR_UPDATE_RULES')
          );
        }
      });
    });
  }

  onBackToGroupSettings(): void {
    this.isBackToGroupSettings.emit(true);
  }

  handleEditRule() {
    this.isShowEditRule = true;
  }

  async compressImage(groupRule: string): Promise<string> {
    const imgTag = /<img[^>]+src="([^">]+)"/g;
    let match;
    let compressedGroupRule = groupRule;

    while ((match = imgTag.exec(groupRule)) !== null) {
      const imageDataUrl = match[1];
      const compressedImage = await this.imageCompress.compressFile(imageDataUrl, -1, 50, 50);
      compressedGroupRule = compressedGroupRule.replace(imageDataUrl, compressedImage);
    }

    return compressedGroupRule;
  }
}
