import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MAX_CAPTION_LENGTH, MAX_POST_LENGTH } from '@app/shared';
import { environment } from '@env/environment';
import { PostPendingProps } from '../../pending-posts/group-pending-posts.component';

@Component({
  selector: 'post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss']
})
export class PostItemComponent {
  @Input() checked: boolean | undefined = false;
  @Input() post: any;
  @Input() selectedPost: any;
  @Output() onAction = new EventEmitter();
  @Output() selectedChange = new EventEmitter();
  baseUrl: string = environment.baseURL;
  captionLength = 50;
  showFullText = false;
  MAX_POST_LENGTH = MAX_POST_LENGTH;
  MAX_CAPTION_LENGTH = MAX_CAPTION_LENGTH;
  checkBoxValue: boolean;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checked']) {
      if (this.checked !== undefined) {
        this.checkBoxValue = this.checked;
      }
    }
  }
  ngOnInit() {
    this.checkBoxValue = false;
  }
  onSelect(selected: string, listPost: PostPendingProps[]) {
    let action = { selected, listPost };
    this.onAction.emit(action);
  }

  setTruncatedCaption(caption: string): string {
    const words = caption.split(' ');
    if (words.length > this.captionLength) {
      return words.slice(0, this.captionLength).join(' ') + '...';
    } else {
      return caption;
    }
  }
  onSelectedChange(checked: boolean, post: PostPendingProps) {
    let status: { checked: boolean; post: PostPendingProps } = { checked: checked, post: post };
    this.selectedChange.emit(status);
  }

  onExpandText() {
    this.showFullText = !this.showFullText;
  }
}
