import { PostRequest } from '@app/lib/api/post/api.post.model';
import { Post } from '@app/shared/models/post';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserPostsActions = createActionGroup({
  source: 'User Posts',
  events: {
    'On Load Current User Posts': props<{
      pageNum: number;
      pageSize: number;
      sortByDate: string;
    }>(),
    'On Load User Posts': props<{
      userId: string;
      pageNum: number;
      pageSize: number;
      isFriend: boolean;
      sortByDate: string;
      isMyProfile: boolean;
    }>(),
    'On Load Current User More Posts': props<{
      pageNum: number;
      pageSize: number;
      sortByDate: string;
    }>(),
    'On Load More Posts': props<{
      userId: string;
      pageNum: number;
      pageSize: number;
      isFriend: boolean;
      sortByDate: string;
      isMyProfile: boolean;
    }>(),

    'On Load Pin Post': props<{ postId: string; isPinned: true; post: Post; index: number }>(),
    'On Load Unpin Post': props<{ postId: string; isPinned: false }>(),
    'On Get Pined Post Own': emptyProps(),
    'On Get Pined Post User': props<{ userId: string; isFriend: boolean }>(),

    'On User Create Post': props<{ post: PostRequest; isShare: boolean; sharingPost?: Post }>(),
    'On User Update Own Post': props<{ post: PostRequest; id: string; isPinnedPost: boolean }>(),
    'On User Update Share Post': props<{ post: PostRequest; id: string; isPinnedPost: boolean }>(),
    'On User Delete Post': props<{ id: any; isPinned: boolean }>(),
    'On User Archived Post': props<{ postId: string; isArchived: true }>(),
    'On Get Theme Post': emptyProps()
  }
});

export const UserPostsApiActions = createActionGroup({
  source: 'User Posts Api',
  events: {
    'On Load User Posts Success': props<{ posts: Post[]; isMyProfile: boolean }>(),
    'On Load More Posts Success': props<{ posts: Post[]; isMyProfile: boolean }>(),
    'On Load User Posts Fail': props<{ message: string }>(),
    'On User Create Post Success': props<{ message?: string; posts: Post[] }>(),
    'On User Upload And Create Post Success': props<{ post: Post }>(),
    'On User Create Post Fail': props<{ message: string }>(),
    'On Delete User Posts Success': props<{ posts: Post[] }>(),
    'On Delete User Posts Fail': props<{ message: string }>(),
    'On Update Post Success': props<{ message: string; post: Post }>(),
    'On Update Pined Post Success': props<{ message: string; post: Post }>(),
    'On Edit User Post Success': props<{ id: string; key: string; value: string }>(),
    'On Update Post Fail': props<{ message: string }>(),
    'On Reset User Posts': emptyProps(),
    'On Get Theme Post Success': props<{ theme: any }>(),
    'On Get Theme Post Fail': props<{ message: string }>(),

    'On Load Pin Post Success': props<{ index: number; post: Post }>(),
    'On Load Pin Post Fail': props<{ message: string }>(),
    'On Load Unpin Post Success': emptyProps(),
    'On Load Unpin Post Fail': props<{ message: string }>(),
    'On Load Get Pined Post Success': props<{ post: any }>(),
    'On Load Get Pined Post Fail': props<{ message: string }>(),
    'On Load User Delete Pined Post Success': props<{ message: string }>(),
    'On Reset Pin Post': emptyProps(),
    'On Archived User Posts Success': props<{ message: string; posts: Post[]; result: string }>(),
    'On Archived User Posts Fail': props<{ message: string }>()
  }
});
