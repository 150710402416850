import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiClientConfig } from '../api-client.config';
import { LocationApiModels } from './api.map-location.models';
import { Observable, catchError, map, timeout } from 'rxjs';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared/constant';

const MAP_LOCATION_PATH = {
  place: '/map-location/place',
  searching: '/map-location/searching'
};

export class MapLocationApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  searchLocation(
    searchKey: string,
    maxDestination = 3,
    maxPlace = 2,
    isDeleted = false
  ): Observable<LocationApiModels.SearchLocationResponse> {
    let queryParams = new HttpParams()
      .set('q', searchKey)
      .set('total_result_destination', maxDestination)
      .set('total_result_place', maxPlace)
      .set('is_deleted', isDeleted);
    return this.http
      .get<LocationApiModels.SearchLocationResponse>(`${this.apiUrl}${MAP_LOCATION_PATH.searching}/location`, {
        params: queryParams
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  searchPlaceByCoordinates(
    latitude: number,
    longitude: number,
    distance: number | null = 50,
    isPaging = true,
    page = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT
  ): Observable<LocationApiModels.SearchPlacePagingResponse> {
    const pagingParam = isPaging ? `&is_paging=true&page=${page}&page_size=${pageSize}` : '&is_paging=false';
    const distanceParam = distance ? `&distance=${distance}` : '';
    const params = `lat=${latitude}&lon=${longitude}${pagingParam}${distanceParam}`;
    return this.http
      .get<LocationApiModels.SearchPlacePagingResponse>(
        `${this.apiUrl}${MAP_LOCATION_PATH.searching}/associated-by-lat-lon?${params}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
