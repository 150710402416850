import { Pipe, PipeTransform } from '@angular/core';
import { DAY_TIME_UNITS } from '../constant';

const TIME_CONVERT_TO_MILLISECONDS = {
  milisecond: 1,
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 86400000
};

@Pipe({
  name: 'epochConverter'
})
export class EpochConverterPipe implements PipeTransform {
  transform(value: number | Date, fromUnit = DAY_TIME_UNITS.day, toUnit = DAY_TIME_UNITS.milisecond): any {
    if (typeof value === 'number') {
      const fromValue = this.convertToMiliseconds(value, fromUnit);
      return this.convertFromMiliseconds(fromValue, toUnit);
    } else {
      return value.getTime();
    }
  }

  convertToMiliseconds(value: number, fromUnit = DAY_TIME_UNITS.day): number {
    switch (fromUnit) {
      case DAY_TIME_UNITS.day:
        return value * TIME_CONVERT_TO_MILLISECONDS.day;
      case DAY_TIME_UNITS.hour:
        return value * TIME_CONVERT_TO_MILLISECONDS.hour;
      case DAY_TIME_UNITS.minute:
        return value * TIME_CONVERT_TO_MILLISECONDS.minute;
      case DAY_TIME_UNITS.second:
        return value * TIME_CONVERT_TO_MILLISECONDS.second;
      case DAY_TIME_UNITS.milisecond:
        return value * TIME_CONVERT_TO_MILLISECONDS.milisecond;
      default:
        return NaN;
    }
  }

  convertFromMiliseconds(milisecond: number, toUnit = DAY_TIME_UNITS.day): number {
    switch (toUnit) {
      case DAY_TIME_UNITS.day:
        return milisecond / TIME_CONVERT_TO_MILLISECONDS.day;
      case DAY_TIME_UNITS.hour:
        return milisecond / TIME_CONVERT_TO_MILLISECONDS.hour;
      case DAY_TIME_UNITS.minute:
        return milisecond / TIME_CONVERT_TO_MILLISECONDS.minute;
      case DAY_TIME_UNITS.second:
        return milisecond / TIME_CONVERT_TO_MILLISECONDS.second;
      case DAY_TIME_UNITS.milisecond:
        return milisecond / TIME_CONVERT_TO_MILLISECONDS.milisecond;
      default:
        return NaN;
    }
  }
}
