import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { VERIFICATION_TYPE } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sent-request-pop-up',
  templateUrl: './sent-request-pop-up.component.html',
  styleUrls: ['./sent-request-pop-up.component.scss']
})
export class SentRequestPopUpComponent {
  requestVerificationType = VERIFICATION_TYPE;
  baseUrl = environment.baseURL;
  @Input() sentRequest: boolean;
  @Input() fanpageName: string;
  @Input() category: string;
  @Input() requestType: string;
  @Input() fanpageAvatar: string;
  @Output() sentRequestChange = new EventEmitter();
  @Output() goBack = new EventEmitter();
  userInfo$ = this.store.select(selectUserInfo);
  constructor(
    private store: Store,
    private commonService: CommonService
  ) {}

  onHideDialog() {
    this.goBack.emit();
    this.sentRequestChange.emit(false);
  }

  validUrl(urlString: string) {
    return this.commonService.getImageUrl(urlString);
  }

  sendRequestDone() {
    this.sentRequestChange.emit(false);
    this.goBack.emit();
  }
}
