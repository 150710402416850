<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [styleClass]="'max-xs:h-[80%] w-[350px] xs:w-[430px] md:w-[650px]'"
  [dismissableMask]="true"
  (onHide)="closeDialog()">
  <ng-template pTemplate="header">
    <span class="text-lg font-semibold text-palette-gray-800">{{ 'XU.INVITE_FRIEND' | translate }}</span>
  </ng-template>
  <ng-template pTemplate="body">
    <div class="flex flex-col gap-3">
      <!-- <div class="py-3 px-6">
        <span class="text-base font-semibold">{{ 'COIN.INVITE_NOW' | translate }}</span>
        <div class="flex gap-3 overflow-x-auto py-1.5">
          <div
            class="relative flex rounded-md border gap-2 border-palette-orange-600 h-auto p-3 w-[274px] bg-palette-orange-dark-50"
            *ngFor="let contents of ContentInviteFriends; let i = index">
            <div class="w-60 flex flex-col justify-center gap-1">
              <p class="text-sm font-semibold text-palette-gray-700">
                {{ contents.title }}
              </p>
              <span class="text-xs font-medium text-palette-gray-400"
                >{{ 'COIN.EXPIRED_DAY' | translate }} {{ contents.day }}</span
              >
            </div>
            <div class="border-dashed border"></div>
            <div class="flex w-16 items-center justify-center">
              <img defaultImage [src]="contents.src" alt="" />
            </div>
            <div class="half-circle-top"></div>
            <div class="half-circle-bottom"></div>
          </div>
        </div>
      </div> -->

      <div class="xs:flex bg-invite-content p-3 gap-3 rounded mx-6">
        <div class="px-3 rounded-md bg-white">
          <div #qrCodeStyle class="flex justify-center qr-content"></div>
          <p class="text-base font-semibold text-center pb-[10px] whitespace-nowrap">{{ 'XU.SCAN_QR_CODE' | translate }}</p>
        </div>
        <div class="flex flex-col gap-1.5 w-full max-xs:mt-3">
          <p class="text-base font-semibold">{{ 'XU.INVITE_LINK' | translate }}</p>
          <div class="p-inputgroup">
            <input pInputText [(ngModel)]="invitedLink" class="text-ellipsi" [disabled]="true" />
            <button
              pButton
              class="bg-palette-base-white px-3 border-[#ced4da] border-l-0"
              (click)="handleCopyClick(invitedLink)">
              <i class="sctr-icon-copy-01 text-palette-orange-600 text-xl"></i>
            </button>
          </div>
          <p class="text-base font-semibold">{{ 'XU.REFERRAL_CODE' | translate }}</p>
          <div class="p-inputgroup">
            <input pInputText [(ngModel)]="invitedCode" [disabled]="true" />
            <button pButton class="bg-white px-3 border-[#ced4da] border-l-0" (click)="handleCopyClick(invitedCode)">
              <i class="sctr-icon-copy-01 text-palette-orange-600 text-xl"></i>
            </button>
          </div>
          <!-- TODO: Show it when there is an API that supports platform sharing-->
          <!-- <div class="md:flex items-center gap-0.5">
            <span class="pb-0 text-sm font-medium">{{ 'COIN.OR_BY_SHARE' | translate }}</span>
            <div class="flex max-md:gap-[10px]">
              <img src="assets/icons/fb.svg" alt="" class="md:p-[10px]" />
              <img src="assets/icons/Social icon.svg" alt="" class="md:p-[10px]" />
              <img src="assets/icons/iter.png" alt="" class="md:p-[10px]" />
              <img src="assets/icons/send.svg" alt="" class="md:p-[10px]" />
            </div>
          </div> -->
        </div>
      </div>

      <div class="py-3 px-6 flex flex-col gap-1.5">
        <span class="text-base font-semibold text-palette-gray-700">{{
          'XU.INSTRUCTION_FOR_NEW_MEMBER' | translate
        }}</span>
        <div class="md:grid md:grid-cols-2 gap-3">
          <div
            class="rounded-md bg-gradient-to-r from-blue-100 to-amber-100 p-3 flex gap-1.5 max-xs:mb-3 max-md:mb-3"
            *ngFor="let items of Instruction; let index = index">
            <div class="flex justify-between w-full">
              <div>
                <p class="text-xl font-semibold text-palette-gray-700">{{ items.step | translate: {index: index + 1} }}</p>
                <p class="text-sm font-medium text-palette-gray-500">
                  {{ items.content | translate: { appName: this.appName } }}
                </p>
              </div>
              <img defaultImage [src]="items.src" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="pt-8">
      <p-button
        styleClass="p-button-outlined h-11 border-palette-gray-300 text-palette-gray-700"
        (onClick)="closeDialog()"
        >{{ 'COMMON.BUTTON_ACTION.CLOSE' | translate }}</p-button
      >
    </div>
  </ng-template>
</p-dialog>
