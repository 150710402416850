import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { EventApiModels } from '@app/lib/api/event/api.event.models';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import { selectFanpageRole } from '@app/modules/main/fanpage/store/selector';
import { selectRoleGroup } from '@app/modules/main/group/store/selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';

export interface PutLeaveGroup {
  group_id: string;
}
@Component({
  selector: 'events-tab',
  templateUrl: './events-tab.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./events-tab.component.scss']
})
export class EventsTabComponent implements OnInit {
  @Input() currentTab: string;
  @Input() eventsActions: MenuItem[] | any;
  @Input() fanPageIsShow: boolean = false;
  @Input() dataGroupEvents: EventApiModels.EventDetails[];
  @Input() dataFanPageDetail: any;
  @Input() isShowUpcomingEvents: boolean = false;
  @Input() isHiddenAction: boolean = false;
  @Input() isShowEventHomeFanPage: boolean = false;
  @Input() userRole: string | undefined;
  @Output() actionDropDow = new EventEmitter();
  @Output() actionInterest = new EventEmitter();
  @Output() isEventsClicked = new EventEmitter();
  @Output() actionAttend = new EventEmitter();
  @Output() actionHeart = new EventEmitter();
  @Output() navigateDetail = new EventEmitter();

  fanpageRole$ = this.store.select(selectFanpageRole);
  groupRole$ = this.store.select(selectRoleGroup);

  baseUrl = environment.baseURL;
  filterApplied = false;
  translateCommonResult = this.translationService.getTranslation('COMMON.BUTTON_ACTION');
  translatedStartMonth: any;
  translatedEndMonth: any;

  constructor(
    private fanpageService: FanpageService,
    private store: Store,
    private translationService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataGroupEvents'] && changes['dataGroupEvents'].currentValue) {
      this.filterData();
      this.filterApplied = true;
    }
  }

  ngOnInit(): void {
    this.onLanguageChanged();
  }

  onLanguageChanged() {
    this.translationService.language$.subscribe(() => {
      if (this.dataGroupEvents && this.dataGroupEvents.length > 0) {
        this.translateEventMonths();
      }
    });
  }

  loadEventImg(avatar: string): string {
    return this.baseUrl + this.fanpageService.addMediaExtension(avatar);
  }

  handleEventsActions(event: any, item: any) {
    let param = {
      event: event.target.innerText,
      eventId: item.id,
      selectedButton: this.currentTab
    };
    this.actionDropDow.emit(param);
  }

  onClickMenuButton(selectedEvent: any): void {
    let currentRole = '';
    const roleObservable = this.fanPageIsShow ? this.fanpageRole$ : this.groupRole$;
    roleObservable.subscribe((data: any) => {
      currentRole = data?.user_role;
    });
    const updatedRoleList = [...this.fanpageService.defaultFanpageRoles, ...[{ role: 'MOD', priority: 2 }]];
    const ownerEventPriority = updatedRoleList.find(item => item.role === selectedEvent.userRole)?.priority ?? 1;
    const currentUserPriority = updatedRoleList.find(item => item.role === currentRole)?.priority ?? 1;
    const currentId = JSON.parse(localStorage.getItem('user_profile') || '')?.id;
    this.eventsActions.forEach((item: any) => {
      if (item.label.includes(this.translateCommonResult.DELETE)) {
        item.disabled = currentUserPriority < ownerEventPriority && currentId !== selectedEvent.user_id;
      }
    });
  }

  handleInterest(id: any) {
    this.actionInterest.emit(id);
  }

  onSwitchingEventsTab() {
    this.isEventsClicked.emit(true);
  }

  emitActionAttend(id: any, event: Event) {
    event.stopPropagation();
    this.actionAttend.emit(id);
  }

  emitActionHeart(id: any, event: Event) {
    event.stopPropagation();
    this.actionHeart.emit(id);
  }
  handleEventNavigateDetail(id: any) {
    let param = {
      eventDetailId: id
    };
    this.navigateDetail.emit(param);
  }

  filterData() {
    this.dataGroupEvents = this.dataGroupEvents?.filter(event => {
      return event.user_role !== null || event.privacy !== 2;
    });
  }

  translateEventMonths(): void {
    this.dataGroupEvents.forEach(event => {
      const startMonth = new Intl.DateTimeFormat('en', { month: 'long' })
        .format(new Date(event.start_date_time))
        .toUpperCase()
        .substring(0, 3);

      const endMonth = new Intl.DateTimeFormat('en', { month: 'long' })
        .format(new Date(event.end_date_time))
        .toUpperCase()
        .substring(0, 3);
      this.translatedStartMonth = this.translationService.getTranslation(`COMMON.DATE_TIME.MONTH.${startMonth}`);
      this.translatedEndMonth = this.translationService.getTranslation(`COMMON.DATE_TIME.MONTH.${endMonth}`);
    });
  }
}
