import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { MediaService } from '@app/core/services/media.service';
import { ADS_CATEGORY, PLAY_VIDEO_EVENT_TIMES, VIDEO_INTERACTION_TYPES } from '@app/lib/api/client/api.client.constant';
import { FILE_EXTENSION, VIDEO_WIDTH_TO_SHOW_CONTROLLER, VIDEO_WIDTH_TO_SHOW_PROGRESS } from '@app/shared/constant';
import { FILE_TYPE_URL, checkUrl } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

interface Document extends HTMLDocument {
  mozCancelFullScreen: () => void;
  webkitExitFullscreen: () => void;
  mozFullScreenElement: () => void;
  webkitFullscreenElement: () => void;
}

@Component({
  selector: 'custom-video',
  templateUrl: './custom-video.component.html',
  styleUrls: ['./custom-video.component.scss']
})
export class CustomVideoComponent implements AfterViewInit, AfterViewChecked, OnDestroy, OnChanges {
  @Input() item: any;
  @Input() isPreview: boolean;
  @Input() isSquareContainer = false;
  @Input() isUsingAspect = false;
  @Input() isCover = false;
  @Input() showOnHover = true;
  @Input() isShareView = false;
  @Input() isAutoPlay = false;
  @Input() isPostView = false;
  @Input() isShowScaleIcon  = false;
  @Output() isClickedPlay = new EventEmitter();
  @Output() isHoverVideo = new EventEmitter();
  @Output() currentTimeClick = new EventEmitter();
  @Output() scaleVideo = new EventEmitter();
  @ViewChild('video') video: ElementRef;
  @ViewChild('videoContainer') private videoContainer: ElementRef;
  @ViewChild('progressBar') progressBar: ElementRef;
  @ViewChild('progressFilled') progress: ElementRef;
  @ViewChild('loadedData') loadedData: ElementRef;
  @ViewChild('snapshotsRef') snapshotsRef: ElementRef;
  visiblePlayingPoint = false;
  currentTime: any;
  duration: any;
  currentWidth = 0;

  videoWidthToShowController = VIDEO_WIDTH_TO_SHOW_CONTROLLER;

  hostUrl = environment.baseURL;
  videoElement: HTMLVideoElement;
  isMuted = true;
  pauseByClick = false;
  isWaiting = false;
  showVolumeSlider: boolean;
  volume = 0;
  sub: Subscription;
  isShowProgress = false;
  isIOSDevice = false;

  snapshotsTime = 0;
  playVideoStartTime: number;
  playVideoDuration = 0;
  isVideoPlaying = false;
  isBriefViewed = false;
  viewFullscreen = false;

  constructor(
    private el: ElementRef,
    private changeRef: ChangeDetectorRef,
    private mediaService: MediaService,
    private analyticsService: AnalyticsService
  ) {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
      this.isIOSDevice = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isPostView'] && this.isMuted === true) {
      this.isMuted = false;
      this.volume = 1;
    } else {
      this.isMuted = JSON.parse(localStorage.getItem('muted-state') || 'true');
      this.volume = this.mediaService.volumeState;
    }
    if (this.video) this.video.nativeElement.volume = this.volume;
  }

  ngAfterViewChecked(): void {
    this.currentWidth = this.videoContainer.nativeElement.offsetWidth;
    this.isShowVideoProgress();
    const html = document.getElementsByTagName('html')[0];
    if (
      !document.fullscreenElement &&
      !(document as Document).webkitFullscreenElement &&
      html.style.scrollbarGutter !== 'stable'
    ) {
      html.style.scrollbarGutter = 'stable';
    } else if (
      (document.fullscreenElement || (document as Document).webkitFullscreenElement) &&
      html.style.scrollbarGutter === 'stable'
    ) {
      html.style.scrollbarGutter = 'auto';
    }
    this.mediaService.isUpdateVolume = this.showVolumeSlider;
  }

  getVideoProgress() {
    const { currentTime, duration, buffered, offsetWidth } = this.videoElement;
    const roundedCurrentTime = currentTime;
    const roundedDuration = duration;
    this.currentTime = this.formatTime(currentTime);

    if (buffered.length > 0) {
      const loaded = (buffered.end(0) / roundedDuration) * 100;
      this.loadedData.nativeElement.style.width = `${loaded}%`;
    }
    return (roundedCurrentTime / roundedDuration) * 100;
  }

  ngAfterViewInit(): void {
    const checkVideoIsLoading = () => {      
      this.videoElement.readyState >= 2 ? (this.isWaiting = false) : (this.isWaiting = true);
    };
    this.videoElement = this.video.nativeElement;
    const progressBar = this.progressBar.nativeElement;
    const progress = this.progress.nativeElement;
    const loadedData = this.loadedData.nativeElement;

    progress.style.width = '0%';
    loadedData.style.width = '0%';

    this.videoElement.addEventListener('loadedmetadata', () => {
      this.currentTime = this.formatTime(this.videoElement.currentTime);
      this.duration = this.formatTime(this.videoElement.duration);
    });

    this.videoElement.addEventListener('loadstart', () => {
      checkVideoIsLoading();
    });

    this.videoElement.addEventListener('canplay', () => {
      checkVideoIsLoading();
    });

    this.videoElement.addEventListener('timeupdate', () => {
      const watchedProgress = this.getVideoProgress();
      progress.style.width = `${watchedProgress}%`;
      if (watchedProgress === 100 && this.isAutoPlay && !this.mediaService.isScaleVideo) {
        document.querySelectorAll('.custom-video').forEach((el: any, i: number) => {
          if (this.videoElement === document.querySelectorAll('.custom-video')[i - 1]) {
            const headerOffset = 200;
            const elementPosition = el.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - headerOffset;
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        });
      }
    });

    progressBar.addEventListener('click', (e: any) => {
      const watchedProgress = this.getVideoProgress();
      progress.style.width = `${watchedProgress}%`;
      const progressTime = (e.offsetX / progressBar.offsetWidth) * this.videoElement.duration;
      this.videoElement.currentTime = progressTime;
      this.isClickedPlay.emit(true);
    });

    if (this.showOnHover) {
      const controlEls = this.el.nativeElement.querySelectorAll('.controls-container');
      controlEls.forEach((el: any) => {
        el.style.visibility = 'hidden';
      });

      this.videoContainer.nativeElement.addEventListener('mouseover', () => {
        controlEls.forEach((el: any) => {
          el.style.visibility = 'visible';
        });
      });

      this.videoContainer.nativeElement.addEventListener('mouseout', () => {
        controlEls.forEach((el: any) => {
          el.style.visibility = 'hidden';
        });
      });
    }

    this.isMuted = JSON.parse(localStorage.getItem('muted-state') || 'true');
    this.sub = this.mediaService.volumeValue.subscribe(volume => {
      this.mediaService.volumeState = volume;
      this.volume = volume;
      this.video.nativeElement.volume = this.volume;
    });
    setTimeout(() => {
      this.isScrolledIntoView();
    });
    this.changeRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  get totalViews() {
    return this.item && this.item.total_views && !isNaN(this.item.total_views) ? this.item.total_views : 0;
  }

  formatTime(time: number) {
    let minutes: any = Math.floor(time / 60);
    minutes = minutes >= 10 ? minutes : `0${minutes}`;
    let seconds: any = Math.floor(time - minutes * 60);
    seconds = seconds >= 10 ? seconds : `0${seconds}`;
    return `${minutes}:${seconds}`;
  }

  playVideoEvent(isPlaying: boolean, videoId: string, videoDuration: number): void {
    this.isVideoPlaying = isPlaying;
    if (videoId && !['blob', 'video'].includes(videoId)) {
      if (isPlaying) {
        this.playVideoStartTime = Date.now();
        this.analyticsService.pushToBuffer(videoId, ADS_CATEGORY.video, VIDEO_INTERACTION_TYPES.play);
        setTimeout(() => {
          if (!this.isBriefViewed && isPlaying) {
            if (
              this.playVideoDuration + (Date.now() - this.playVideoStartTime) >=
              PLAY_VIDEO_EVENT_TIMES.brief.totalTime
            ) {
              this.analyticsService.pushPlayVideoToBuffer(videoId, PLAY_VIDEO_EVENT_TIMES.brief.action);
              this.isBriefViewed = true;
            }
          }
        }, PLAY_VIDEO_EVENT_TIMES.brief.totalTime);
      } else {
        this.analyticsService.pushToBuffer(videoId, ADS_CATEGORY.video, VIDEO_INTERACTION_TYPES.pause);
        this.playVideoDuration = this.analyticsService.handlePlayVideoEvent(
          this.playVideoStartTime,
          this.playVideoDuration,
          videoId,
          videoDuration
        );
      }
    }
  }

  playPause() {
    if (this.videoElement.paused) {
      this.videoElement.play();
      this.pauseByClick = false;
    } else {
      this.videoElement.pause();
      this.pauseByClick = true;
    }
    this.pauseAllVideo(this.videoElement);
    this.isClickedPlay.emit(true);
  }

  onFullscreen(event: string) {
    if (!document.fullscreenElement && !(document as Document).webkitFullscreenElement) {
      this.openFullscreen();
      this.analyticsService.pushToBuffer(event, ADS_CATEGORY.video, VIDEO_INTERACTION_TYPES.viewFullScreen);
    } else {
      this.closeFullscreen();
    }
    this.isClickedPlay.emit(true);
  }

  openFullscreen() {
    this.viewFullscreen = true;
    if (this.videoContainer.nativeElement.requestFullscreen) {
      this.videoContainer.nativeElement.requestFullscreen();
    } else if (this.videoContainer.nativeElement.webkitRequestFullscreen) {
      /* Safari */
      this.videoContainer.nativeElement.webkitRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    this.viewFullscreen = false;
    if ((document as Document).exitFullscreen) {
      (document as Document).exitFullscreen();
    }
  }

  onMuted() {
    this.isMuted = !this.isMuted;
    document.querySelectorAll('.custom-video').forEach((el: any) => {
      el.muted = this.isMuted;
    });
    if (!this.isPostView) {
      localStorage.setItem('muted-state', JSON.stringify(this.isMuted));
      if (this.isMuted) {
        this.mediaService.previousVolume = this.volume;
      }
      this.volume = this.isMuted ? 0 : this.mediaService.previousVolume ? this.mediaService.previousVolume : 1;
      this.mediaService.volumeValue.next(this.volume);
      this.isClickedPlay.emit(true);
    }
  }

  validUrl(urlString: string, isUpload: boolean | undefined) {
    return isUpload
      ? urlString
      : this.isPreview
      ? checkUrl(urlString, FILE_TYPE_URL.streamVideo) + FILE_EXTENSION.video
      : checkUrl(urlString, FILE_TYPE_URL.streamVideo);
  }

  playVideo(videoEl: HTMLVideoElement) {
    videoEl.addEventListener('loadeddata', () => {
      videoEl.play();
    });

    if (videoEl.readyState >= 2) {
      videoEl.play();
    }
  }

  pauseVideo(videoEl: HTMLVideoElement) {
    if (!videoEl.paused) {
      videoEl.pause();
    }
  }

  videoDistanceFromMiddleScreen(el: HTMLVideoElement) {
    const middleWindow = window.innerHeight / 2;
    const videoRect = el.getBoundingClientRect();
    const middleVideo = (videoRect.top + videoRect.bottom) / 2;
    const videoDistanceFromMiddleScreen =
      middleVideo >= middleWindow ? middleVideo - middleWindow : middleWindow - middleVideo;
    return videoDistanceFromMiddleScreen;
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.mediaService.isScaleVideo) return;
    const distanceList = Array.from(document.querySelectorAll('.custom-video')).map((el: any) => {
      return this.videoDistanceFromMiddleScreen(el);
    });
    const minDistance = Math.min(...distanceList);

    if (this.videoElement) {
      let curPlayingVideo: HTMLVideoElement = this.videoElement;
      document.querySelectorAll('.custom-video').forEach((el: any) => {
        if (!el.paused) {
          const curPlayingVideoDistance = this.videoDistanceFromMiddleScreen(el);
          if (minDistance === curPlayingVideoDistance) curPlayingVideo = el;
          return;
        }
      });

      const windowHeight = window.innerHeight;
      const currentVideoRect = this.videoElement.getBoundingClientRect();
      const curVideoDistanceFromMiddleScreen = this.videoDistanceFromMiddleScreen(this.videoElement);
      const middleCurVideo = (currentVideoRect.top + currentVideoRect.bottom) / 2;
      if (
        !(middleCurVideo >= 0 && middleCurVideo <= windowHeight && !this.pauseByClick) ||
        !(
          curVideoDistanceFromMiddleScreen === minDistance &&
          curPlayingVideo === this.videoElement &&
          !this.pauseByClick
        )
      ) {
        this.removeVideoSnapshots(this.snapshotsRef.nativeElement);
        this.pauseVideo(this.videoElement);
      } else {
        const watchedProgress = this.getVideoProgress();
        this.createVideoSnapshots();
        if (watchedProgress !== 100 || !this.isAutoPlay) this.playVideo(this.videoElement);
      }
    }
  }

  pauseAllVideo(exceptEl?: HTMLVideoElement) {
    document.querySelectorAll('.custom-video').forEach((el: any) => {
      if (exceptEl && exceptEl === el) return;
      if (!el.paused) this.pauseVideo(el);
    });
  }

  updateTime(isMinus: boolean) {
    const timeChange = isMinus ? -10 : 10;
    const updatedTime = Math.min(Math.max(this.videoElement.currentTime + timeChange, 0), this.videoElement.duration);
    this.videoElement.currentTime = updatedTime;
    this.isClickedPlay.emit(true);
  }

  updateVolume() {
    this.isClickedPlay.emit(true);
    this.isMuted = this.volume === 0 ? true : false;
    if (!this.isPostView) {
      this.mediaService.volumeValue.next(this.volume);
      const muted = localStorage.getItem('muted-state');
      if (muted && JSON.parse(muted) !== this.isMuted) {
        localStorage.setItem('muted-state', JSON.stringify(this.isMuted));
      }
    }
    document.querySelectorAll('.custom-video').forEach((el: any) => {
      el.volume = this.volume;
      el.muted = this.isMuted;
    });
    
  }

  isShowVideoProgress() {
    if (this.currentWidth > VIDEO_WIDTH_TO_SHOW_PROGRESS) {
      this.isShowProgress = true;
    } else {
      this.isShowProgress = false;
    }
    this.changeRef.detectChanges();
  }

  onSliderHover(e: any) {
    let snapshotsTime = Number((e.offsetX / this.progressBar.nativeElement.offsetWidth) * this.videoElement.duration);
    if (snapshotsTime < 0) {
      snapshotsTime = 0;
    }
    this.snapshotsTime = snapshotsTime;
    this.snapshotsRef.nativeElement.style.left = `${e.offsetX - 64}px`;
    const video = this.snapshotsRef.nativeElement.querySelector('#videoSnapshots') as HTMLVideoElement;
    if (video) {
      video.currentTime = snapshotsTime;
    } else {
      this.createVideoSnapshots();
    }
  }

  removeVideoSnapshots(el: any) {
    const video = el.querySelector('#videoSnapshots') as HTMLVideoElement;
    if (video) {
      video.remove();
    }
  }

  createVideoSnapshots() {
    const exitVideoSnapshots = this.snapshotsRef.nativeElement.querySelector('#videoSnapshots');
    if (exitVideoSnapshots) return;
    const videoNew = document.createElement('video');
    videoNew.src = this.videoElement.src;
    videoNew.autoplay = false;
    videoNew.muted = true;
    videoNew.id = 'videoSnapshots';
    videoNew.classList.add('w-full', 'h-full');
    const box = this.snapshotsRef.nativeElement.querySelector('#box-view');
    box?.appendChild(videoNew);
  }

  caculateTime(secs: number) {
    if (!secs) return '';
    const hour = Math.floor(secs / 3600);
    const returneHours = hour < 10 ? `0${hour}` : hour;
    const minutes = Math.floor((secs / 60) % 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    if (Number(returneHours) > 0) {
      return `${returneHours}:${returnedMinutes}:${returnedSeconds}`;
    } else if (Number(returnedMinutes) > 0) {
      return `${returnedMinutes}:${returnedSeconds}`;
    } else {
      return `0:${returnedSeconds}`;
    }
  }

  onClickVideo() {
    if (this.isPostView) {
      this.playPause();
    } else {
      this.currentTimeClick.emit(this.currentTime);
    }
  }

  handleScaleVideo() {
    this.isClickedPlay.emit(true);
    this.scaleVideo.emit({...this.item, isExistScale: this.mediaService.isScaleVideo});
  }
}
