import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GroupService } from '@app/core/services/group.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { GroupProfileInfo } from '@app/lib/api/group/api.group.model';
import { PostRequest } from '@app/lib/api/post/api.post.model';
import { GroupInterfaceState } from '@app/modules/main/group/store/reducer';
import {
  getALLGroupToJoin,
  getMyGroup
} from '@app/modules/main/personal-profile/components/personal-profile-groups/store/actions';
import {
  selectGroup,
  selectGroupAllToJoin,
  selectIsLoadingJoined,
  selectLoadingMyGroup
} from '@app/modules/main/personal-profile/components/personal-profile-groups/store/selectors';
import { NewsFeedPostsActions } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.actions';
import { selectNewsFeedLoading } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { UserPostsActions } from '@app/modules/main/states/user-posts/user-posts.actions';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  PAGE_SIZE_DEFAULT,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import { Post, SHARE_TYPE } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { PostDialogComponent } from '../create-post-dialog/post-dialog/post-dialog.component';

@Component({
  selector: 'share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit {
  @Input() visible = false;
  @Input() visiblePostDialog = false;
  @Input() sharedPost: Post;
  @Input() isPostInProfile = false;
  @Input() shareType: number;
  @Input() sharePostType = '';
  @Input() friendList: FriendList[];
  @Input() isWatchScreen = false;
  @Output() visibleChange = new EventEmitter();
  @Output() visiblePostDialogChange = new EventEmitter();
  @Output() shareTypeChange = new EventEmitter();
  hostUrl = environment.baseURL;
  shareTypeList = SHARE_TYPE;
  sharedToGroupInfo: GroupProfileInfo;
  userInfo$ = this.store.select(selectUserInfo);
  loading$ = this.store.select(selectNewsFeedLoading);
  searchResults: GroupProfileInfo[] = [];
  searchText = '';
  listAllGroup: GroupProfileInfo[] = [];
  isLoadingMyGroup = false;
  isLoadingGroupJoined = false;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('postDialog') postDialog: PostDialogComponent;

  constructor(
    private store: Store<GroupInterfaceState>,
    private groupService: GroupService,
    private postsService: PostsService,
    private toastMessageService: ToastMessageService,
    private translateService: TranslationService
  ) {}

  ngOnInit() {
    this.store.select(selectLoadingMyGroup).subscribe((loading: boolean) => {
      this.isLoadingMyGroup = loading;
    });
    this.store.select(selectIsLoadingJoined).subscribe((loading: boolean) => {
      this.isLoadingGroupJoined = loading;
    });
    this.store.select(selectGroup).subscribe((item: any) => {
      if (item && item.length > 0) {
        this.groupService.pageSizeMyGroup = item?.length;
        this.listAllGroup = this.listAllGroup.concat(item);
      }
    });
    this.store.select(selectGroupAllToJoin).subscribe((item: any) => {
      if (item && item.data) {
        this.groupService.pageSizeGroupJoined = item.data?.length;
        this.listAllGroup = this.listAllGroup.concat(item.data);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['visible'] && changes['visible'].currentValue) ||
      (changes['visiblePostDialog'] && changes['visiblePostDialog'].currentValue)
    ) {
      if (this.postDialog) this.postDialog.shareType = this.shareType;
    }
  }

  loadMore() {
    const container = this.scrollContainer.nativeElement;
    if (container) {
      const scrollHeight = container.scrollHeight;
      const offsetHeight = container.offsetHeight;
      const scrollTop = container.scrollTop;
      if (scrollTop + offsetHeight >= scrollHeight - 50) {
        if (this.groupService.pageSizeMyGroup >= PAGE_SIZE_DEFAULT && !this.isLoadingMyGroup) {
          this.groupService.pageNumMyGroup++;
          this.store.dispatch(
            getMyGroup({ pageSize: PAGE_SIZE_DEFAULT, pageNum: this.groupService.pageNumMyGroup, textSearch: '' })
          );
        }
        if (this.groupService.pageSizeGroupJoined >= PAGE_SIZE_DEFAULT && !this.isLoadingGroupJoined) {
          this.groupService.pageNumGroupJoined++;
          this.store.dispatch(
            getALLGroupToJoin({
              pageSize: PAGE_SIZE_DEFAULT,
              pageNum: this.groupService.pageNumGroupJoined,
              textSearch: ''
            })
          );
        }
      }
    }
  }

  onHideDialog() {
    this.searchText = '';
    this.search();
    if (!this.sharedToGroupInfo) {
      this.postDialog.shareType = undefined;
    }
    this.visibleChange.emit(false);
  }

  onShareTypeChange(newShareType: any) {
    this.shareTypeChange.emit(newShareType);
  }

  sharePost(event: Event) {
    const dataToShare: PostRequest = {
      ...event,
      group_id: this.shareType === this.shareTypeList.group ? this.sharedToGroupInfo.group_id : '',
      parent_id: this.sharedPost.id
    };
    if (this.isWatchScreen || (this.isPostInProfile && this.shareType === this.shareTypeList.group)) {
      this.postsService.sharePost(dataToShare, this.sharedPost).subscribe({
        next: res => {
          if (res?.success) {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.success,
              this.translateService.getTranslation('POST.SHARED_SUCCESSFULLY'),
              TOAST_MESSAGE_LIFETIME_DURATIONS.short
            );
          }
        },
        error: error => {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            this.translateService.getTranslation('ERROR.POST.LOAD_POST_DETAILS_FAILED')
          );
        }
      });
    } else {
      this.isPostInProfile
        ? this.store.dispatch(
            UserPostsActions.onUserCreatePost({ post: dataToShare, isShare: true, sharingPost: this.sharedPost })
          )
        : this.store.dispatch(
            NewsFeedPostsActions.onCreateNewsFeedPost({
              newsFeed: dataToShare,
              isShare: true,
              sharingPost: this.sharedPost
            })
          );
    }
  }

  handleShareGroup(group: GroupProfileInfo) {
    this.shareType = 2;
    this.sharedToGroupInfo = group;
    this.visible = false;
    this.visiblePostDialog = true;
  }

  search() {
    this.searchResults = this.listAllGroup.filter(group => {
      return group.group_name?.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }
}
