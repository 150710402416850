import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupInterfaceState } from './reducer';

//post in group
const feature = createFeatureSelector<GroupInterfaceState>('postGroups');
export const selectPostGroup = createSelector(feature, state => state.postGroups);
export const selectMemberGroup = createSelector(feature, state => state?.dataMemberGroup);
export const selectMemberPendingGroup = createSelector(feature, state => state.dataMemberPendingGroup);
export const selectInfoDetailGroup = createSelector(feature, state => state.infoDetailGroup);
export const selectRoleGroup = createSelector(feature, state => state.roleGroup);
export const selectLoadingUserRole = createSelector(feature, state => state.loadingUserRole);
export const selectGroupDetail = createSelector(feature, state => state.groupDetail);
export const selectLoadingGroupDetail = createSelector(feature, state => state.loadingGroupDetail);
export const selectViewPostGroup = createSelector(feature, state => state.dataGetViewPost);
export const selectPendingPost = createSelector(feature, state => state.posts);
export const selectGetPendingPost = createSelector(feature, state => state.getPostGroup);
export const selectIsLoading = createSelector(feature, state => state.isLoading);
export const selectUploadAvatarGroup = createSelector(feature, state => state.newAvatarData);
export const selectUploadBannerGroup = createSelector(feature, state => state.newBannerData);
export const selectGroupRule = createSelector(feature, state => state.groupRule);
export const selectGroupLoggedUserRule = createSelector(feature, state => state.roleGroup?.user_role);
export const selectGroupPrivacy = createSelector(feature, state => state.infoDetailGroup?.data.privacy);
export const selectPinedPost = createSelector(feature, state => state.pinnedPost);
export const selectIsLoadingGroupRule = createSelector(feature, state => state.isLoadingGroupRule);
export const selectIsLoadingViewPost = createSelector(feature, state => state.isLoadingViewPost);
export const selectCanLoadMoreMember = createSelector(feature, state => state.canLoadMoreMember);
export const selectIsLoadingMembers = createSelector(feature, state => state.isLoadingMembers);
