import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaService } from '@app/core/services/media.service';
import { PollService } from '@app/core/services/poll.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { StorageApiModels } from '@app/lib/api/storage/api.storage.models';
import { updateNewFanpagePost } from '@app/modules/main/fanpage/store/actions';
import { loadCreateGroupPostSuccess } from '@app/modules/main/group/store/actions';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { checkUrl, FILE_TYPE_URL, POLL_STATUS } from '@app/shared/models/post';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { PostDialogActions } from '../../store/post-dialog.actions';
import { PostDialogPollAnswersComponent } from './post-dialog-poll-answers/post-dialog-poll-answers.component';

const pollModeOptions = [
  { type: 'Default', value: 'DEFAULT', label: 'POLL.DEFAULT' },
  { type: 'Presentation', value: 'PRESENTATION', label: 'POLL.PRESENTATION' }
];

const votingTypeOptions = [
  { type: 'Normal', value: 'NORMAL', label: 'POLL.NORMAL' },
  { type: 'Vote by coins', value: 'COINS', label: 'POLL.VOTE_BY_COINS' }
];

const pollTypeOptions = [
  { type: 'Single choice', value: 'SINGLE', label: 'POLL.SINGLE_CHOICE' },
  { type: 'Multiple choice', value: 'MULTIPLE', label: 'POLL.MULTIPLE_CHOICE' }
];

@Component({
  selector: 'post-dialog-poll',
  templateUrl: './post-dialog-poll.component.html',
  styleUrls: ['./post-dialog-poll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostDialogPollComponent implements OnChanges {
  @Input() editData: any;
  @Input() fanpageInfo: any;
  @Input() groupId: string;
  @Input() visiblePoll = false;
  @Input() groupPrivacy: string;
  @Output() visiblePollChange = new EventEmitter();
  isSubmitting = false;
  isEdit = false;
  pollStatus = POLL_STATUS;
  display_privacies: string[] = [];
  limit_vote_times: { duration: number; votes: number } | null;

  formPollQuestion: FormGroup;

  backgroundUrl: string | ArrayBuffer | null = null;
  backgroundId: string;
  uploadedBackgroudName: string | null = null;
  backgroundFile: File | null = null;

  startDate: Date | null;
  endDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  minStartDate: Date;
  invalidDate: Date = new Date(3000, 0, 1);

  startTimePickerVisibility = false;
  endTimePickerVisibility = false;
  isShowErrorTime = false;
  isShowErrorEndTime = false;
  isEndTimeBeforeStartTime = false;
  checkedLimitVoteTimes = false;
  checkedPollDetails = false;
  checkedResultPercents = false;

  pollModeOptions: { type: string; value: string }[] = pollModeOptions;
  votingTypeOptions: { type: string; value: string }[] = votingTypeOptions;
  pollTypeOptions: { type: string; value: string }[] = pollTypeOptions;

  @ViewChild('pollAnswers', { static: false }) private pollAnswers: PostDialogPollAnswersComponent;

  constructor(
    private fb: FormBuilder,
    private mediaService: MediaService,
    private toastMessageService: ToastMessageService,
    private pollService: PollService,
    private translateService: TranslationService,
    private postsService: PostsService,
    private store: Store
  ) {
    this.minStartDate = new Date();
    this.endDate = null;
  }

  get descriptionLength(): number {
    return this.formPollQuestion.get('description')?.value?.length || 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const visible = changes['visiblePoll']?.currentValue;

    if (visible) {
      if (!this.editData) {
        this.isShowErrorTime = false;
        this.isShowErrorEndTime = false;
        this.isEndTimeBeforeStartTime = false;
        (this.startDate = null), (this.startTime = null), (this.endDate = null), (this.endTime = null);
        this.formPollQuestion = new FormGroup({
          title: new FormControl('', [Validators.required]),
          description: new FormControl(''),
          poll_mode: new FormControl({ type: 'Default', value: 'DEFAULT', label: 'POLL.DEFAULT' }),
          voting_type: new FormControl({ type: 'Normal', value: 'NORMAL', label: 'POLL.NORMAL' }),
          poll_type: new FormControl({ type: 'Single choice', value: 'SINGLE', label: 'POLL.SINGLE_CHOICE' }),
          duration: new FormControl(0),
          votes: new FormControl(0)
        });

        return;
      }
      this.handleEditData(this.editData);
    }
  }

  handleEditData(poll_object: any) {
    this.isEdit = true;
    const findOption = (options: any[], value: string, defaultIndex: number = 0) => {
      return options.find(option => option.value === value) || options[defaultIndex];
    };

    if (poll_object?.display_privacies?.length) {
      this.display_privacies = poll_object?.display_privacies;
      this.checkedPollDetails = poll_object?.display_privacies?.includes('ACTIVITIES');
      this.checkedResultPercents = poll_object?.display_privacies?.includes('RESULT_PERCENTS');
    }

    const { duration = 0, votes = 0 } = poll_object?.limit_vote_times || {};
    this.checkedLimitVoteTimes = duration !== 0 || votes !== 0;

    const pollModeType = findOption(pollModeOptions, poll_object?.poll_mode);
    const votingType = findOption(votingTypeOptions, poll_object?.voting_type);
    const pollType = findOption(pollTypeOptions, poll_object?.poll_type);

    this.formPollQuestion = new FormGroup({
      title: new FormControl(poll_object?.title || '', [Validators.required]),
      description: new FormControl(poll_object?.description || ''),
      poll_mode: new FormControl({ value: pollModeType, disabled: true }),
      voting_type: new FormControl(votingType),
      poll_type: new FormControl(pollType),
      duration: new FormControl(this.convertTime(poll_object?.limit_vote_times?.duration, 's') || 0),
      votes: new FormControl(poll_object?.limit_vote_times?.votes || 0)
    });

    this.backgroundId = poll_object?.background_image;
    this.backgroundUrl = poll_object?.background_image?.length
      ? checkUrl(poll_object?.background_image, FILE_TYPE_URL.thumbnail)
      : '';

    if (poll_object.status === this.pollStatus.ONGOING) {
      this.formPollQuestion.get('voting_type')?.disable();
      this.formPollQuestion.get('poll_type')?.disable();
    }

    this.startDate = poll_object?.start_date ? new Date(poll_object.start_date.split('T')[0]) : null;
    this.startTime = poll_object?.start_date ? new Date(poll_object.start_date) : null;
    this.endDate = poll_object?.end_date ? new Date(poll_object.end_date.split('T')[0]) : null;
    this.endTime = poll_object?.end_date ? new Date(poll_object.end_date) : null;

    this.checkErrorTwoDate();
  }

  createAnswer(): FormGroup {
    let params: { [key: string]: FormControl } = {
      name: new FormControl('', Validators.required)
    };

    if (this.isEdit && this.editData?.status === this.pollStatus.ONGOING) {
      params['block'] = new FormControl(false);
    }

    return this.fb.group(params);
  }

  handleUploadBackground(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const maxSizeInBytes = 5 * 1024 * 1024;
      const file = input.files[0];
      if (file.size > maxSizeInBytes) {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          'ERROR.LIBRARY.EXCEED_FILE_SIZE_LIMIT'
        );
        return;
      }
      this.backgroundFile = file;
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.backgroundUrl = reader.result;
          this.uploadedBackgroudName = file.name;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeBackgroundImage(): void {
    this.backgroundId = '';
    this.backgroundUrl = null;
    this.uploadedBackgroudName = null;
    this.backgroundFile = null;
  }

  triggerUpload(): void {
    const fileInput = document.getElementById('file') as HTMLInputElement;
    fileInput.click();
  }

  handleCheckDatePickerStartDate(event: any) {
    this.startDate = event;
    this.checkErrorTwoDate();
  }

  handleCheckDatePickerEndDate(event: any) {
    this.endDate = event;
    this.checkErrorTwoDate();
  }

  checkErrorTwoDate() {
    if (this.startDate && this.endDate && this.startTime && this.endTime) {
      const currentDate = new Date();
      const startDateTime = new Date(this.startDate);
      const endDateTime = new Date(this.endDate);

      startDateTime.setHours(this.startTime.getHours(), this.startTime.getMinutes(), 0, 0);
      endDateTime.setHours(this.endTime.getHours(), this.endTime.getMinutes(), 0, 0);

      this.isEndTimeBeforeStartTime = startDateTime >= endDateTime;

      if (!this.isEndTimeBeforeStartTime) {
        this.isShowErrorTime = this.isShowErrorEndTime = false;

        if (
          this.startDate.toDateString() === currentDate.toDateString() &&
          !(this.isEdit && this.editData.status === this.pollStatus.ONGOING)
        ) {
          this.isShowErrorTime = startDateTime <= currentDate;
        }

        if (this.endDate.toDateString() === currentDate.toDateString()) {
          this.isShowErrorEndTime = endDateTime <= currentDate;
        }
      }
    }
  }

  onVisibleTimePickerChange(visible: boolean, index: number): void {
    if (visible) {
      if (index === 1) {
        this.endTimePickerVisibility = false;
      } else {
        this.startTimePickerVisibility = false;
      }
    }
  }

  handleStartTime(event: Date): void {
    this.startTime = event;
    this.checkErrorTwoDate();
  }

  handleEndTime(event: Date): void {
    this.endTime = event;
    this.checkErrorTwoDate();
  }

  getTime() {
    const formatStartDate = moment(this.startDate).format('YYYY-MM-DD');
    const formatStartTime = moment(this.startTime).format('HH:mm:ss');
    const start_date = `${formatStartDate}T${formatStartTime}`;

    const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD');
    const formattedEndTime = moment(this.endTime).format('HH:mm:ss');
    const end_date = `${formattedEndDate}T${formattedEndTime}`;

    return { start_date, end_date };
  }

  onChangeLimitVote(event: any) {
    this.checkedLimitVoteTimes = event.checked;
  }

  onChangePollDetails(event: any, type: 'detail' | 'result') {
    const updateDisplayPrivacies = (checked: boolean, privacyType: string) => {
      if (checked && !this.display_privacies?.includes(privacyType)) {
        this.display_privacies.push(privacyType);
        return;
      }
      this.display_privacies = this.display_privacies.filter(item => item !== privacyType);
    };

    switch (type) {
      case 'detail': {
        this.checkedPollDetails = event.checked;
        updateDisplayPrivacies(event.checked, 'ACTIVITIES');
        break;
      }
      case 'result': {
        this.checkedResultPercents = event.checked;
        updateDisplayPrivacies(event.checked, 'RESULT_PERCENTS');
        break;
      }
    }
  }

  onHideDialog(): void {
    this.visiblePollChange.emit(false);
    this.formPollQuestion.reset();
    this.backgroundFile = null;
    this.backgroundUrl = null;
    this.startTimePickerVisibility = false;
    this.endTimePickerVisibility = false;
    this.isEdit = false;
    this.pollAnswers?.hideDialog();
  }

  isPresentation(): boolean {
    return this.formPollQuestion.get('poll_mode')?.value?.value === 'PRESENTATION';
  }

  shouldDisabledSubmitBtn(): boolean {
    return (
      this.isSubmitting ||
      !this.formPollQuestion.valid ||
      this.pollAnswers?.isValid ||
      (!this.isPresentation() && !this.startDate) ||
      (!this.isPresentation() && !this.endDate) ||
      (!this.isPresentation() && !this.startTime) ||
      (!this.isPresentation() && !this.endTime) ||
      (!this.isPresentation() && this.isEndTimeBeforeStartTime) ||
      (this.isPresentation() && !this.backgroundUrl) ||
      this.isShowErrorTime ||
      this.isShowErrorEndTime
    );
  }

  convertTime(time: number, type: 'hrs' | 's') {
    if (type === 's') return time / (60 * 60);
    if (type === 'hrs') return time * 60 * 60;
    return;
  }

  disabledForm() {
    this.formPollQuestion.get('title')?.disable();
    this.formPollQuestion.get('description')?.disable();
    this.formPollQuestion.get('duration')?.disable();
    this.formPollQuestion.get('votes')?.disable();
    this.pollAnswers?.disableForm();
  }

  async submit(): Promise<void> {
    this.isSubmitting = true;
    this.disabledForm();
    const time = this.getTime();

    try {
      let backgroundIdImage = null;
      if (this.backgroundFile) {
        backgroundIdImage = await this.onUploadFile(this.backgroundFile);
      }
      const uploadAnswerPromises = this.pollAnswers?.uploadedFiles.map(file => this.onUploadFile(file));
      const uploadedFiles = await Promise.all(uploadAnswerPromises);

      const answers = this.pollAnswers?.value
        .map((item: any, index: number) => {
          const image = uploadedFiles[index]?.id || this.pollAnswers?.uploadedImagesId[index] || '';
          const signature = uploadedFiles[index]?.signature || '';
          const answer_id = item.answerId || '';
          return item.name?.length ? { name: item.name, image, answer_id, signature } : null;
        })
        .filter((item: any) => item !== null);

      const { title, description, voting_type, poll_mode, duration, poll_type, votes } =
        this.formPollQuestion.getRawValue();

      let params: any;

      if (this.isEdit) {
        let editedAnswers: any[] = [];
        answers.forEach((el: any, index: number) => {
          if (this.isEditedAnswer(el, index)) {
            if (el.answer_id === '') delete el.answer_id;
            editedAnswers.push(el);
          }
        });

        const currentUTCStartDate = this.isPresentation() ? null : new Date(time.start_date).toISOString();
        const currentUTCEndDate = this.isPresentation() ? null : new Date(time.end_date)?.toISOString();

        const limit_times = {
          duration: this.convertTime(duration, 'hrs'),
          votes
        };

        if (title !== this.editData.title) params = { ...params, title };
        if (description !== this.editData.description) params = { ...params, description };
        if (this.editData.status !== this.pollStatus.ONGOING || currentUTCStartDate !== this.editData.start_date)
          params = { ...params, start_date: currentUTCStartDate };
        if (currentUTCEndDate !== this.editData.end_date && !this.isPresentation())
          params = { ...params, end_date: currentUTCEndDate };
        if (this.editData.status !== this.pollStatus.ONGOING && voting_type.value !== this.editData.voting_type)
          params = { ...params, voting_type: voting_type.value };
        if (this.limit_vote_times !== this.editData.limit_vote_times && this.checkedLimitVoteTimes)
          params = { ...params, limit_vote_times: limit_times };
        if (this.display_privacies !== this.editData.display_privacies)
          params = { ...params, display_privacies: this.display_privacies };

        params = {
          ...params,
          background_image: backgroundIdImage ? backgroundIdImage.id : this.backgroundId,
          background_image_signature: backgroundIdImage ? backgroundIdImage.signature : ''
        };
        if (editedAnswers.length) params = { ...params, answers: editedAnswers };
      } else {
        const background_image = backgroundIdImage ? backgroundIdImage.id : '';
        const background_image_signature = backgroundIdImage ? backgroundIdImage.signature : '';
        const limit_times = {
          duration: this.convertTime(duration, 'hrs') || 0,
          votes
        };

        this.limit_vote_times = this.checkedLimitVoteTimes ? limit_times : null;

        params = {
          title,
          description,
          voting_type: voting_type.value,
          background_image,
          background_image_signature,
          object_id: this.groupId ? this.groupId : this.fanpageInfo.page_id,
          object_type: this.groupId ? 'GROUP' : 'PAGE',
          answers,
          poll_type: this.isPresentation() ? 'MULTIPLE' : poll_type.value,
          limit_vote_times: this.limit_vote_times,
          display_privacies: this.display_privacies,
          poll_mode: poll_mode.value
        };

        if (!this.isPresentation()) {
          params = {
            ...params,
            start_date: new Date(time.start_date).toISOString(),
            end_date: new Date(time.end_date).toISOString()
          };
        }
      }

      const res = this.isEdit
        ? await this.pollService.editPoll(params, this.editData.id).toPromise()
        : await this.pollService.createPoll(params).toPromise();

      if (res && this.isEdit) {
        this.store.dispatch(PostDialogActions.onGetPollId({ poll_id: this.editData.id }));
      }

      if (res && !this.isEdit) {
        const post: any = {
          title: '',
          content: '',
          post_type: 'POLL',
          post_privacy: this.groupPrivacy ? this.groupPrivacy : 'PUBLIC',
          poll_id: res.data.id
        };
        this.groupId ? (post.group_id = this.groupId) : (post.page_id = this.fanpageInfo.page_id);
        this.postsService.createPost(post).subscribe(res => {
          if (res.success) {
            if (this.groupId) {
              this.store.dispatch(loadCreateGroupPostSuccess({ post: res.data }));
            } else {
              this.store.dispatch(updateNewFanpagePost({ post: res.data }));
            }
          }
        });
        this.toastMessageService.addToastMessage(
          'success',
          this.translateService.getTranslation('POLL.CREATE_POLL_SUCCESS')
        );
      }
    } catch (error) {
      this.toastMessageService.addToastMessage('error', this.translateService.getTranslation('POLL.CREATE_POLL_FAIL'));
    } finally {
      this.isSubmitting = false;
      this.visiblePoll = false;
      this.visiblePollChange.emit(false);
    }
  }

  isEditedAnswer(editedAnswer: any, i: number): boolean {
    const pollResult = this.editData.poll_result[i];
    if (!pollResult) return true;
    const { answer_id, name, image } = pollResult;
    const isIdMatched = answer_id === editedAnswer.answer_id;
    const isContentChanged = name !== editedAnswer.name || image !== editedAnswer.image;

    return isIdMatched && isContentChanged;
  }

  async onUploadFile(file: any): Promise<any> {
    const fileName = file && file.name ? file.name : '';
    const formData: FormData = new FormData();
    formData.append('files', file, fileName);
    const res = await this.mediaService.uploadFile(formData).toPromise();
    if (res && res.success && res.data && res.data[0]) {
      const uploadedFile: StorageApiModels.UploadedFile = res.data[0];
      return uploadedFile;
    } else {
      this.toastMessageService.addToastMessage('error', 'ERROR.COMMON.FILE_UPLOAD_FAILED');
    }
    return null;
  }
}
