import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HashtagService {
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getSuggestHashTag = (searchText?: string, limit = 10) => this.api.hashtag.getSuggestHashTag(searchText, limit);
}
