import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PixelAnalyticsService {
  gtag: Function;
  public initialize() {
    // dynamically add analytics scripts to document head
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.GA_CODE}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.GA_CODE}');`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
    if (environment.production) {
      try {
        const dataLayerScript = document.createElement('script');
        dataLayerScript.innerHTML = `
        !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1234063450918609');
        fbq('track', 'PageView');`;
        document.body.appendChild(dataLayerScript);

        const noscript = document.createElement('noscript');
        const img = document.createElement('img');
        img.height = 1;
        img.width = 1;
        img.style.display = 'none';
        img.src = 'https://www.facebook.com/tr?id=1234063450918609&ev=PageView&noscript=1';

        noscript.appendChild(img);
        document.body.appendChild(noscript);
      } catch (e) {
        console.error('Error adding Facebook Analytics', e);
      }
    }
  }

  onSignUp() {
    this.gtag('event', 'sign_up', {
      'method': 'Email'
    });
  }

  onSignIn(method: 'Soctrip' | 'Google') {
    this.gtag('event', 'login', {
      'method': method
    });
  }
}