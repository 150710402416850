import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../../api-client.config';

export class ThemeApi {
  private apiUrl: string = environment.baseURL;
  THEME_POST = '/posts/themes';
  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}
  getThemes(): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiUrl}${this.THEME_POST}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res.data;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

}
