import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[defaultImage]'
})
export class DefaultImageDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    // Set the default image if the specified image fails to load
    this.renderer.listen(this.el.nativeElement, 'error', () => {
      this.renderer.setAttribute(this.el.nativeElement, 'src', '../../../assets/images/thumbnail.png');
    });
  }
}