import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, retry, timeout } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApiClientConfig } from '../api-client.config';
import {
  DeleteStoryResponse,
  StoriesArchive,
  StoriesArchiveResponse,
  Story,
  StoryResponse
} from './api.stories.models';

export class StoriesApi {
  private apiUrl = environment.baseURL;
  STORIES_PATH = '/story/story';
  STORIES_PATH_DETAIL = '/story/story/detail';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getStories(pageNum?: number, pageSize?: number): Observable<Story[]> {
    const url = `${this.apiUrl}${this.STORIES_PATH}/stories?pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.http.get<StoryResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      retry(1),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getStoriesByUserId(userId: string, pageNum: number, pageSize: number): Observable<Story[]> {
    const url = `${this.apiUrl}${this.STORIES_PATH}/user/${userId}?pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.http.get<StoryResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.data.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getStoriesById(storyId: string, isModerate = false): Observable<Story> {
    const url = `${this.apiUrl}${isModerate ? this.STORIES_PATH_DETAIL : this.STORIES_PATH}/${storyId}`;
    return this.http.get<StoryResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteStory(storyId: string): Observable<boolean> {
    return this.http.delete<DeleteStoryResponse>(`${this.apiUrl}${this.STORIES_PATH}/${storyId}`).pipe(
      map(res => {
        return res.success;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteStories(storyIds: string[]): Observable<boolean> {
    return this.http
      .delete<DeleteStoryResponse>(`${this.apiUrl}${this.STORIES_PATH}`, { body: { ids: storyIds } })
      .pipe(
        map(res => {
          return res.success;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getStoriesArchived(pageNum?: number, pageSize?: number): Observable<StoriesArchive> {
    const url = `${this.apiUrl}${this.STORIES_PATH}/archive/me?pageNum=${pageNum}&pageSize=${pageSize}`;
    return this.http.get<StoriesArchiveResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      retry(1),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
