<div class="flex justify-between gap-3" style="overflow-wrap: anywhere">
  <a [href]="navigateToProfile()" class="flex gap-2">
    <img
      [ngClass]="cardConfig.style.roundedFull ? 'rounded-full' : 'rounded'"
      class="justify-center cursor-pointer {{ cardConfig.style.height }} {{ cardConfig.style.width }}"
      *ngIf="avatarUrl"
      onerror="this.src='assets/images/default_user_avatar.png'"
      [src]="avatarUrl" />
    <div class="flex flex-col justify-center overflow-hidden">
      <div class="flex items-end">
        <p
          class="font-bold cursor-pointer pr-2"
          [ngClass]="getTextClass(userData?.full_name)"
          title="{{ userData?.full_name }}">
          {{ userData?.full_name }}
          <span
            class="inline-flex translate-y-[3px] justify-center items-center relative w-[15px] h-[18px] mx-0.5"
            *ngIf="userData.is_verified_user">
            <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
            <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/4"></i>
          </span>
        </p>
      </div>
      <small *ngIf="!cardConfig.hideFriend">{{ userData?.number_of_friend }} {{ 'ENTITY.FRIENDS' | translate }}</small>
    </div>
  </a>
  <div *ngIf="!isModerate" class="flex items-center justify-center">
    <ng-content select="[user-card-button]"></ng-content>
  </div>
</div>
