import { Injectable } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { TOAST_MESSAGE_LIFETIME_DURATIONS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  constructor(
    private messageService: MessageService,
    private translationService: TranslationService
  ) {}

  addToastMessage(
    severity: string,
    detail: string,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    if (detail) {
      this.messageService.add({
        severity: severity,
        detail: isTranslate ? this.translationService.getTranslation(detail) : detail,
        life: lifeTime
      });
    }
  }

  addToastMessageDev(
    severity: string,
    detail: string,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    if (!environment.production && detail) {
      this.messageService.add({
        severity: severity,
        detail: isTranslate ? this.translationService.getTranslation(detail) : detail,
        life: lifeTime
      });
    }
  }

  addToastMessageApiResponse(
    isSuccess: boolean,
    successDetail: string,
    failureDetail = '',
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    const severity = isSuccess ? TOAST_MESSAGE_SEVERITY_LEVELS.success : TOAST_MESSAGE_SEVERITY_LEVELS.error;
    const detail = isSuccess ? successDetail : failureDetail;
    this.addToastMessage(severity, detail, lifeTime, isTranslate);
  }

  addMessageWithTranslateParams(
    severity: string,
    key: string,
    params: any,
    lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium,
    isTranslate = true
  ): void {
    const detail = this.translationService.getTranslationWithParams(key, params);
    this.addToastMessage(severity, detail, lifeTime, isTranslate);
  }

  clearAllToastMessages(key?: string): void {
    this.messageService.clear(key);
  }
}
