import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressAPI } from '@app/lib/api/address/api.address.models';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private api: ApiClient;
  private supportedLanguages: AddressAPI.RegionalLanguages[];

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getCountries(validCountry = false): Observable<AddressAPI.Country[]> {
    return this.api.addressApi.getCountryList(validCountry).pipe(
      map((res: AddressAPI.GetCountryListRes) => {
        return res && res.success && res.data ? res.data : [];
      })
    );
  }

  getRegions(countryCode: string): Observable<AddressAPI.Region[]> {
    return this.api.addressApi.getRegionList(countryCode).pipe(
      map((res: AddressAPI.GetRegionListRes) => {
        return res && res.success && res.data ? res.data : [];
      })
    );
  }

  getCities(region: string): Observable<AddressAPI.City[]> {
    return this.api.addressApi.getCityList(region).pipe(
      map((res: AddressAPI.GetCityListRes) => {
        return res && res.success && res.data ? res.data : [];
      })
    );
  }

  getSupportedLanguages(): Observable<AddressAPI.RegionalLanguages[]> {
    if (this.supportedLanguages && this.supportedLanguages.length) {
      return of(this.supportedLanguages);
    }
    return this.api.addressApi.getSupportedLanguages().pipe(
      map((res: AddressAPI.GetRegionalLanguages) => {
        this.supportedLanguages = res && res.success && res.data ? res.data : [];
        return this.supportedLanguages;
      })
    );
  }

  updateSelectedLanguages(data: AddressAPI.CurrencyAndLanguages) {
    return this.api.addressApi.updateSelectedLanguages(data).pipe(
      map((res: AddressAPI.SetCurrencyLanguages) => {
        return res && res.success && res.data ? res.data : false;
      })
    );
  }
}
