import { FriendStateEnum, FriendStateKeyEnum } from '@app/core/enums/makeFriend.enum';
import { FriendAPI } from '@app/lib/api/friend/api.friend.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FriendActions = createActionGroup({
  source: 'Friend Actions',
  events: {
    'On Get Relationship State': props<{ userId: string }>(),
    'On Make Friend State': props<{
      userId: string;
      idToFind?: FriendStateKeyEnum;
      stateItem?: FriendStateEnum;
    }>(),
    'On Cancel Request Friend State': props<{
      requestId: string;
      idToFind?: FriendStateKeyEnum;
      relationshipId?: string;
      stateItem?: FriendStateEnum;
    }>(),
    'On Accept Request Friend State': props<{
      requestId: string;
      idToFind?: FriendStateKeyEnum;
      relationshipId?: string;
      stateItem?: FriendStateEnum;
    }>(),
    'On Decline Request Friend State': props<{ requestId: string }>(),
    'On Unfriend State': props<{ userId: string }>(),
    'Handle Friend Request In Search View': props<{ userId: string; typeAction: string }>(),
    'On Get List Friend State': props<{ userId?: string; pageNum?: number; pageSize?: number }>(),
    'On Reset List Friend State': props<{ userId?: string; pageNum?: number; pageSize?: number }>(),
    'On Get List Friend Widget State': props<{ userId?: string; pageNum?: number; pageSize?: number }>(),
    'On Get Friends Send Request State': props<{ pageSize: number; pageNum: number, searchText: string }>(),
    'On Load More Friends Send Request State': props<{ pageNum: number; pageSize: number, searchText: string }>(),
    'On Get Friends Request Receive State': props<{ pageNum: number; pageSize: number, searchText: string }>(),
    'On Load More Friends Request Receive State': props<{ pageNum: number; pageSize: number, searchText: string }>(),
    'On Search Friends State': props<{ searchText: string; pageNum?: number; pageSize?: number }>(),
    'On Load More Search Friends State': props<{ searchText: string; pageNum?: number; pageSize?: number }>(),
    'On Get Suggest Friends State': props<{ pageNum: number; pageSize: number; searchText?: string }>(),
    'On Check Relationship State': props<{ userId: string }>(),
    'On Delete Friends State By Index': props<{ item: FriendStateEnum; index: number }>(),
    'On Delete Friends State By Id': props<{ item: FriendStateEnum; id: string }>(),
    'On Update Friends State By Id': props<{
      idToFind: FriendStateKeyEnum;
      valueId: string;
      stateItem: FriendStateEnum;
      loadingStatus: boolean;
      currentRelationValue?: string;
      updateRelationValue?: FriendAPI.FriendStatus;
    }>(),
    'On Reset Friend State': props<{ item: FriendStateEnum }>(),

    'On Get User Follower State': props<{
      typeService: string;
      typeId: string;
      textSearch: string;
      pageNum: number;
      pageSize: number;
      userStatus: string;
    }>(),
    'On Get User Following State': props<{
      pageNum: number;
      pageSize: number;
      typeService: string;
      textSearch: string;
    }>(),
    'On UnFollow User State': props<{
      typeService: string;
      typeId: string;
    }>(),
    'On Remove User Follower State': props<{
      typeService: string;
      typeId: string;
      userId: string;
    }>(),
    'On Follow User State': props<{
      typeService: string;
      typeId: string;
    }>(),
    'On Checking Following State': props<{
      userId: string;
      userProfileId: string;
    }>(),
    'On Following': emptyProps(),
    'On Unfollow': emptyProps(),
    'Update Following Status': props<{ isFollowing: boolean }>()
  }
});

export const FriendApiActions = createActionGroup({
  source: 'Friend Api',
  events: {
    'On Get Friend Relationship State Success': props<{ friendStatus: FriendAPI.FriendStatus }>(),
    'On Get Friend Relationship State Fail': props<{ error: any }>(),

    'On Get List Search Friend State Success': props<{ friendList: FriendAPI.SearchFriend }>(),
    'On Get List Search Friend State Fail': props<{ error: any }>(),

    'On Get List Friend State Success': props<{ friendList: FriendAPI.Friend }>(),
    'On Get List Friend State Fail': props<{ error: any }>(),

    'On Get List Friend Widget State Success': props<{ friendWidgetList: FriendAPI.Friend }>(),
    'On Get List Friend Widget State Fail': props<{ error: any }>(),

    'On Get Friends Send Request State Success': props<{ sendRequest: FriendAPI.Friend }>(),
    'On Get Friends Send Request State Fail': props<{ error: any }>(),

    'On Get Friends Request Receive State Success': props<{ receiveRequest: FriendAPI.Friend }>(),
    'On Get Friends Request Receive State Fail': props<{ error: any }>(),

    'On Get Suggest Friends State Success': props<{ suggestFriends: FriendAPI.Friend }>(),
    'On Get Suggest Friends State Fail': props<{ error: any }>(),

    'On Check Relationship Success': props<{ relationshipStatus: FriendAPI.Relationship }>(),
    'On Check Relationship Fail': props<{ error: any }>(),
    'On Reset Relationship Status': emptyProps(),

    'On Get User Follower State Success': props<{ userFollower: FriendAPI.UserFollow }>(),
    'On Get User Follower State Fail': props<{ error: any }>(),

    'On Get User Following State Success': props<{ userFollowing: FriendAPI.UserFollow }>(),
    'On Get User Following State Fail': props<{ error: any }>(),

    'On Follow User Success': props<{ followUser: FriendAPI.Relationship }>(),
    'On Follow User Fail': props<{ error: any }>(),

    'On Check Following Success': props<{ isFollowing: FriendAPI.Relationship }>(),
    'On Check Following Fail': props<{ error: any }>(),

    'On Update Item Friend List': props<{ friendStatus: string; index: number }>(),
    'On Load More Friends Send Request State Success': props<{ data: FriendAPI.Friend }>(),
    'On Load More Friends Send Request State Fail': props<{ error: any }>(),
    'On Load More Friends Request Receive State Success': props<{ data: FriendAPI.Friend }>(),
    'On Load More Friends Request Receive State Fail': props<{ error: any }>(),

    //Update Friends States
    'On Update Friends State By Action': props<{
      itemUpdated: any;
      currentTab: string;
      indexUpdate: number;
    }>()
  }
});
