import { HttpClient, HttpParams } from '@angular/common/http';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared';
import { environment } from '@env/environment';
import { Observable, catchError, map, retry, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { EventApiModels, NonAttendingFriendResponseModel } from './api.event.models';

const EVENTS_API_PATH = '/event/events';
const INVITE = '/users-to-invite';

export class EventApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getEventList(
    ownerId: string,
    type: string,
    search = '',
    page = 0,
    size = 10
  ): Observable<EventApiModels.GetEventResponse> {
    const params = 'type=' + type + '&search=' + search + '&pageNum=' + page + '&size=' + size;
    return this.http.get<EventApiModels.GetEventResponse>(`${this.apiUrl}${EVENTS_API_PATH}/${ownerId}?${params}`).pipe(
      timeout(this.config.responseTimeout),
      retry(1),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  createEvent(
    object_id: string,
    eventBody: EventApiModels.CreateEventRequest
  ): Observable<EventApiModels.EventDetailsResponse> {
    const body = eventBody;
    return this.http
      .post<EventApiModels.EventDetailsResponse>(`${this.apiUrl}${EVENTS_API_PATH}/${object_id}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getEventDetails(eventId: string): Observable<EventApiModels.EventDetailsResponse> {
    return this.http
      .get<EventApiModels.EventDetailsResponse>(`${this.apiUrl}${EVENTS_API_PATH}/detail/${eventId}`)
      .pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateAttendEvent(eventId: string): Observable<EventApiModels.InteractEventResponse> {
    const body = {};
    return this.http
      .put<EventApiModels.InteractEventResponse>(`${this.apiUrl}${EVENTS_API_PATH}/${eventId}/attend`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateInterestEvent(eventId: string): Observable<EventApiModels.InteractEventResponse> {
    const body = {};
    return this.http
      .put<EventApiModels.InteractEventResponse>(`${this.apiUrl}${EVENTS_API_PATH}/${eventId}/interest`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateEvent(
    object_id: string,
    eventBody: EventApiModels.CreateEventRequest
  ): Observable<EventApiModels.EventDetailsResponse> {
    const body = eventBody;
    return this.http
      .put<EventApiModels.EventDetailsResponse>(`${this.apiUrl}${EVENTS_API_PATH}/${object_id}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFollowingEvents(
    type: string,
    searchText: string | null,
    attended = false,
    interested = false,
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT
  ): Observable<EventApiModels.EventListPagingResponse> {
    const queryParams: any = {
      pageNum,
      pageSize
    };
    if (searchText) {
      queryParams['searchText'] = searchText;
    }
    if (attended !== false) {
      queryParams['attended'] = attended;
    }
    if (interested !== false) {
      queryParams['interested'] = interested;
    }
    const params = `${Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`;
    return this.http
      .get<EventApiModels.EventListPagingResponse>(`${this.apiUrl}${EVENTS_API_PATH}/me/following/${type}?${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteEvent(eventId: string): Observable<boolean> {
    return this.http.delete<any>(`${this.apiUrl}${EVENTS_API_PATH}/${eventId}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.success;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getNonAttendingFriendList(
    eventId: string,
    pageNum = PAGE_NUM_DEFAULT,
    pageSize = PAGE_SIZE_DEFAULT,
    search?: string | null
  ): Observable<NonAttendingFriendResponseModel> {
    const params = new HttpParams()
      .set('pageNum', pageNum)
      .set('pageSize', pageSize)
      .set('search', search ?? '');

    return this.http
      .get<NonAttendingFriendResponseModel>(`${this.apiUrl}${EVENTS_API_PATH}/${eventId}${INVITE}`, { params })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  inviteFriendToEvent(objectId: string, eventId: string, users_list: string[]): Observable<boolean> {
    const endpoint = `${this.apiUrl}${EVENTS_API_PATH}/${objectId}/invite`;
    return this.http.post<boolean>(endpoint, { event_id: eventId, users_list }).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.success;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
