import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { KEYBOARD_EVENT_CODES } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { selectHighlightsLibrary, selectLibraryLoadingHighlightsLibrary } from '../library/stores/library.selector';
import { LibraryState } from '../library/stores/library.state';

@Component({
  selector: 'highlights-library',
  templateUrl: './highlights-library.component.html',
  styleUrls: ['./highlights-library.component.scss']
})
export class HighlightsLibraryComponent {
  @Input() libraryTitle: string;
  @Output() isNavigateToLibrary = new EventEmitter();
  @Output() uploadFiles = new EventEmitter();
  @Output() isViewHighlightMedia = new EventEmitter<boolean>();

  highlightsLibrary$ = this.store.select(selectHighlightsLibrary) || [];
  loadingHighlightsLibrary$ = this.store.select(selectLibraryLoadingHighlightsLibrary) || false;
  baseURL = environment.baseURL;
  isViewMedia = false;
  galleriaActiveIndex: number;
  maxHighlightMediaItems = 6;

  constructor(private store: Store<LibraryState>) {}

  onSwitchingLibraryTab(): void {
    this.isNavigateToLibrary.emit(true);
  }

  onNewFileSelected(event: any): void {
    this.uploadFiles.emit(event.target.files);
  }

  checkFileType(value: string, typeCheck: string): boolean {
    return value.indexOf(typeCheck) > -1;
  }

  //TODO: Use media view component
  onDisplayGalleria(index: number): void {
    this.isViewMedia = true;
    this.galleriaActiveIndex = index;
    this.isViewHighlightMedia.emit(this.isViewMedia);
  }

  onVisibleChange(value: boolean): void {
    this.isViewHighlightMedia.emit(value);
  }

  @HostListener('window:keyup', ['$event']) keyUp(e: KeyboardEvent): void {
    this.highlightsLibrary$.subscribe(response => {
      if (this.isViewMedia) {
        if (e.code === KEYBOARD_EVENT_CODES.ArrowLeft) {
          if (this.galleriaActiveIndex === 0) this.galleriaActiveIndex = response.length - 1;
          else this.galleriaActiveIndex--;
        }
        if (e.code === KEYBOARD_EVENT_CODES.ArrowRight) {
          if (this.galleriaActiveIndex === response.length - 1) this.galleriaActiveIndex = 0;
          else this.galleriaActiveIndex++;
        }
        if (e.code === KEYBOARD_EVENT_CODES.Escape) {
          this.isViewMedia = false;
          this.onVisibleChange(false);
        }
        if ([KEYBOARD_EVENT_CODES.Space, KEYBOARD_EVENT_CODES.Enter].includes(e.code)) {
          if (document.getElementById('videoGalleria')) {
            const elementVideoGalleria = <HTMLVideoElement>document.getElementById('videoGalleria');
            if (elementVideoGalleria.paused) {
              elementVideoGalleria.play();
            } else {
              elementVideoGalleria?.pause();
            }
          }
        }
        if (e.code === KEYBOARD_EVENT_CODES.ArrowUp) {
          if (document.getElementById('videoGalleria')) {
            const elementVideoGalleria = <HTMLVideoElement>document.getElementById('videoGalleria');
            if (elementVideoGalleria.volume < 1) {
              elementVideoGalleria.volume = elementVideoGalleria.volume + 0.1;
            }
          }
        }
        if (e.code === KEYBOARD_EVENT_CODES.ArrowDown) {
          if (document.getElementById('videoGalleria')) {
            const elementVideoGalleria = <HTMLVideoElement>document.getElementById('videoGalleria');
            if (elementVideoGalleria.volume > 0) {
              elementVideoGalleria.volume = elementVideoGalleria.volume - 0.1;
            }
          }
        }
      }
    });
  }
}
