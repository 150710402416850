<div class="event-tab" [ngClass]="{ 'md:grid-cols-1': isShowUpcomingEvents }" *ngIf="dataGroupEvents">
  <div
    class="flex gap-4 flex-col shadow-sm border border-palette-gray-200 rounded-md bg-white"
    *ngFor="let item of dataGroupEvents">
    <div class="flex items-center px-4" *ngIf="!isShowUpcomingEvents">
      <div class="flex justify-between w-full pt-4" [ngClass]="{ 'pb-2.5': isHiddenAction }">
        <span class="font-medium text-sm uppercase text-palette-gray-700"
          >{{ item.start_date_time | date: 'd' }} {{ translatedStartMonth }} {{ item.start_date_time | date: 'y' }}
          {{ 'EVENT.AT' | translate }} {{ item.start_date_time | date: 'h:mm a' }} -
          {{ item.end_date_time | date: 'd' }} {{ translatedEndMonth }} {{ item.end_date_time | date: 'y' }}
          {{ 'EVENT.AT' | translate }} {{ item.end_date_time | date: 'h:mm a' }}</span
        >
        <p-menu
          #menu
          [model]="eventsActions"
          [popup]="true"
          class="custom-menu"
          (click)="handleEventsActions($event, item)"></p-menu>
        <button
          type="button"
          (click)="menu.toggle($event)"
          (click)="onClickMenuButton(item)"
          class="relative h-[30px] rounded-lg p-1.5 gap-[8px] bg-white hover:bg-palette-gray-200 flex items-center justify-center focus:bg-palette-gray-200 ring-branding-primary-300 ring-offset-2">
          <i class="sctr-icon-dots-vertical font-semibold text-lg"></i>
        </button>
      </div>
    </div>
    <div class="flex items-center justify-between px-4 pt-4" *ngIf="isShowUpcomingEvents">
      <h3 class="text-lg font-semibold text-palette-gray-700">{{ 'EVENT.UPCOMING_EVENTS' | translate }}</h3>
      <i class="sctr-icon-chevron-right text-xl cursor-pointer" (click)="onSwitchingEventsTab()"></i>
    </div>
    <div
      class="flex gap-3 px-4 pb-4 cursor-pointer"
      (click)="handleEventNavigateDetail(item.id)"
      *ngIf="!isShowUpcomingEvents">
      <img class="max-w-[120px] object-cover w-full h-[120px] rounded-lg" [src]="loadEventImg(item.avatar)" alt="" />
      <div class="flex flex-col gap-1 w-full">
        <p-chip
          label="{{ (item.privacy === 1 ? 'COMMON.PRIVACY.PUBLIC' : 'COMMON.PRIVACY.PRIVATE') | translate }}"></p-chip>
        <span class="text-base text-palette-gray-700 font-semibold trim-text-title">{{ item.name }}</span>
        <div class="flex">
          <span class="font-normal text-sm text-palette-gray-500"
            >{{ item.number_interested }} {{ 'EVENT.INTERESTED' | translate }}</span
          >
          <i class="pi pi-circle-fill mx-1 flex items-center text-[5px] text-palette-gray-500"></i>
          <span class="font-normal text-sm text-palette-gray-500" *ngIf="item.number_attendees <= 1"
            >{{ item.number_attendees }} {{ 'EVENT.ATTENDEE' | translate }}</span
          >
          <span class="font-normal text-sm text-palette-gray-500" *ngIf="item.number_attendees > 1"
            >{{ item.number_attendees }} {{ 'EVENT.ATTENDEES' | translate }}</span
          >
        </div>
        <div *ngIf="!isHiddenAction">
          <div class="flex items-end justify-end gap-3 pt-1">
            <p-button
              *ngIf="!item.is_attended"
              styleClass="py-2 px-3.5 gap-2 h-[36px] bg-branding-primary-600 rounded-lg text-palette-base-white custom-btn-event"
              (click)="emitActionAttend(item.id, $event)">
              <i class="sctr-icon-calendar-check-01 font-semibold text-lg"></i>
              <span class="font-semibold text-sm">{{ 'EVENT.ATTEND' | translate }}</span>
            </p-button>
            <p-button
              *ngIf="item.is_attended"
              styleClass="love-btn py-2 px-3.5 gap-2 h-[36px]"
              (click)="emitActionAttend(item.id, $event)">
              <i class="sctr-icon-calendar-check-01 font-semibold text-lg text-palette-gray-700"></i>
              <span class="font-semibold text-sm text-palette-gray-700">{{ 'EVENT.ATTENDED' | translate }}</span>
            </p-button>
            <p-button
              *ngIf="!item.is_interested"
              styleClass="love-btn p-2 w-[36px] h-[36px] custom-btn-event flex justify-center items-center"
              (click)="emitActionHeart(item.id, $event)">
              <i class="sctr-icon-heart font-semibold text-lg text-palette-gray-700"></i>
            </p-button>
            <p-button
              *ngIf="item.is_interested"
              styleClass="love-btn p-2 w-[36px] h-[36px] custom-btn-event flex justify-center items-center"
              (click)="emitActionHeart(item.id, $event)">
              <i class="pi pi-heart-fill"></i>
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-3 px-4 pb-4" *ngIf="isShowUpcomingEvents && !isShowEventHomeFanPage">
      <img class="w-[120px] h-[120px] rounded-lg object-cover" [src]="loadEventImg(item.avatar)" alt="" />
      <div class="flex flex-col gap-1 w-full">
        <span class="font-normal text-sm text-palette-gray-500">{{ item?.start_date_time | date: 'EEEE' }}</span>
        <span class="text-base text-palette-gray-700 font-semibold trim-text-title">{{ item?.name }}</span>
        <div class="flex">
          <span class="font-normal text-sm text-palette-gray-500"
            >{{ item?.start_date_time | date: 'h:mm a' }} - {{ item?.end_date_time | date: 'h:mm a' }}</span
          >
          <i class="pi pi-circle-fill mx-1 flex items-center text-[5px] text-palette-gray-500"></i>
          <span class="font-normal text-sm text-palette-gray-500">{{ item?.start_date_time | date: 'MMM d' }}</span>
        </div>
        <div class="flex items-end justify-end">
          <p-button
            *ngIf="item.is_interested"
            class="interest-btn custom-btn-event"
            icon="pi pi-heart-fill"
            label="{{ 'EVENT.INTERESTED' | translate }}"
            (click)="emitActionHeart(item.id, $event)"></p-button>
          <p-button
            *ngIf="!item.is_interested"
            class="interest-btn"
            icon="pi pi-heart"
            label="{{ 'EVENT.INTEREST' | translate }}"
            (click)="emitActionHeart(item.id, $event)"></p-button>
        </div>
      </div>
    </div>
    <div class="flex gap-3 px-4 pb-4" *ngIf="isShowUpcomingEvents && isShowEventHomeFanPage">
      <img class="w-[60px] h-[60px] object-cover rounded-lg" [src]="loadEventImg(item.avatar)" alt="" />
      <div class="flex flex-col gap-1 w-full">
        <span class="font-normal text-xs text-palette-gray-500">{{ item?.start_date_time | date: 'EEEE' }}</span>
        <span class="text-sm text-palette-gray-900 font-semibold trim-text-title">{{ item?.name }}</span>
        <div class="flex">
          <span class="font-normal text-xs text-palette-gray-500"
            >{{ item?.start_date_time | date: 'h:mm a' }} - {{ item?.end_date_time | date: 'h:mm a' }}</span
          >
          <i class="pi pi-circle-fill mx-1 flex items-center text-[5px] text-palette-gray-500"></i>
          <span class="font-normal text-xs text-palette-gray-500">{{ item?.start_date_time | date: 'MMM d' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
