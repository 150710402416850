export enum RelationshipStatusEnum {
  FriendShip = 'FRIENDSHIP',
  SentRequest = 'SENT_REQUEST',
  ReceivedRequest = 'RECEIVED_REQUEST',
  MySelf = 'MYSELF',
  UnKnow = 'UNKNOWN',
  Loading = 'LOADING',
  Following = 'FOLLOWING'
}

export enum FriendTabs {
  Friend = 'FRIEND',
  Following = 'FOLLOWING',
  FriendRequest = 'FRIEND_REQUEST',
  SendRequest = 'SEND_REQUEST',
  Follower = 'FOLLOWER'
}
export enum FriendStateEnum {
  receiveRequest = 'receiveRequest',
  sendRequest = 'sendRequest',
  friendList = 'friendList',
  suggestFriends = 'suggestFriends',
  friendWidgetList = 'friendWidgetList',
  userFollowing = 'userFollowing',
  userFollower = 'userFollower'
}

export enum FriendStateKeyEnum {
  fenInvitationId = 'friend_invitation_id',
  id = 'id'
}
