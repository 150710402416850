import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { DeletedAuthGuard } from './core/guards/deleted-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'login',
    loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('@modules/sign-up/sign-up.module').then(m => m.SignUpModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'app-connect',
    loadComponent: () => import('@modules/app-connect/app-connect.component').then(m => m.AppConnectComponent)
  },
  {
    path: 'payment-status',
    loadChildren: () => import('@modules/payment-status/payment-status.module').then(m => m.PaymentStatusModule),
    canActivate: [DeletedAuthGuard]
  },
  {
    path: '**',
    loadChildren: () => import('@modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    title: 'Page Not Found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
