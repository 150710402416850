import { TimeoutError } from 'rxjs';

export const REQUEST_TIMEOUT = 'Request timeout';

export const errorHandler = (error: { error: { code: number; message: string } }) => {
  if (error instanceof TimeoutError) {
    throw new Error(REQUEST_TIMEOUT);
  } else {
    throw error?.error;
  }
};
