import { Pipe, PipeTransform } from '@angular/core';
import { POST_TABLE_STYLE } from '../constant';

@Pipe({
  name: 'abbreviate'
})
export class AbbreviateNamePipe implements PipeTransform {
  transform(value: string, type?: string): string {
    if (!value || !value.trim()) {
      return type && type === POST_TABLE_STYLE.fanpage ? 'FP' : 'G';
    }

    const words = value.trim().split(' ');
    const emojiRegex =
      /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}]/u;

    if (emojiRegex.test(value)) {
      return `${words[0][0].toLocaleUpperCase()}${words[0][1].toLocaleUpperCase()}`;
    } else if (words.length === 1) {
      return words[0][0].toLocaleUpperCase();
    } else if (words.length > 1) {
      return `${this.getFirstCharacter(words[0])}${this.getFirstCharacter(words[1])}`;
    } else {
      return '';
    }
  }

  getFirstCharacter(str: string): string {
    // Get the code point of the first character
    let codePoint = str.codePointAt(0) || 0;
    // Convert the code point to a string
    const firstChar = String.fromCodePoint(codePoint);
    return firstChar.toLocaleUpperCase();
  }
}
