import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { loadingEditPost } from '@app/modules/main/states/edit-post/edit-post.selectors';
import {
  PRIVACY,
  TOAST_MESSAGE_LIFETIME_DURATIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { Post } from '@app/shared/models/post';
import { Store } from '@ngrx/store';
interface ListPrivacy {
  icon: string;
  title: string;
  description: string;
  isActive: boolean;
  action: (index: number) => void;
  typePost: string;
}

@Component({
  selector: 'edit-privacy',
  templateUrl: './edit-privacy.component.html',
  styleUrls: ['./edit-privacy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPrivacyComponent {
  @Input() isShowEditPrivacy = false;
  @Input() post_privacy: string;
  @Input() isEditPostSuccess: boolean;
  @Input() isEditPostFail: boolean;
  @Input() postUpdated: Post | {};
  @Output() closeModal = new EventEmitter();
  @Output() emitPrivacy = new EventEmitter();
  loadingEdit$ = this.store.select(loadingEditPost);

  constructor(
    private store: Store,
    private TranslationService: TranslationService,
    private toastMessageService: ToastMessageService
  ) { }

  privacy_list: ListPrivacy[] = [
    {
      icon: 'sctr-icon-globe-04',
      title: 'POST.POPUP.PRIVACY.CONTENT.PUBLIC.TITLE',
      description: 'POST.POPUP.PRIVACY.CONTENT.PUBLIC.DESCRIPTION',
      isActive: false,
      action: (index: number) => {
        this.handleEditPrivacy(index);
      },
      typePost: PRIVACY.public
    },
    {
      icon: 'sctr-icon-users-01',
      title: 'POST.POPUP.PRIVACY.CONTENT.FRIEND.TITLE',
      description: 'POST.POPUP.PRIVACY.CONTENT.FRIEND.DESCRIPTION',
      isActive: false,
      action: (index: number) => {
        this.handleEditPrivacy(index);
      },
      typePost: PRIVACY.friends
    },
    {
      icon: 'sctr-icon-lock-03',
      title: 'POST.POPUP.PRIVACY.CONTENT.PRIVATE.TITLE',
      description: 'POST.POPUP.PRIVACY.CONTENT.PRIVATE.DESCRIPTION',
      isActive: false,
      action: (index: number) => {
        this.handleEditPrivacy(index);
      },
      typePost: PRIVACY.private
    }
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['post_privacy']) {
      this.privacy_list.forEach(element => {
        if (this.post_privacy === element.typePost) {
          element.isActive = true;
        }
      });
    }

    if (changes['isEditPostSuccess'] && changes['isEditPostSuccess'].currentValue) {
      this.closeModal.emit({
        isClose: false,
        key: 'post_privacy',
        value: this.post_privacy,
        postUpdated: this.postUpdated
      });
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.success,
        this.TranslationService.getTranslation('POST.POPUP.PRIVACY.CONTENT.EDIT.SUCCESS'),
        TOAST_MESSAGE_LIFETIME_DURATIONS.short
      );
    }

    if (changes['isEditPostFail'] && changes['isEditPostFail'].currentValue) {
      this.closeModal.emit({
        isClose: false
      });
      this.toastMessageService.addToastMessage(
        TOAST_MESSAGE_SEVERITY_LEVELS.error,
        this.TranslationService.getTranslation('POST.POPUP.PRIVACY.CONTENT.EDIT.FAIL'),
        TOAST_MESSAGE_LIFETIME_DURATIONS.short
      );
    }
  }

  handleEditPrivacy(index: number) {
    this.privacy_list = this.privacy_list.map(privacy => {
      return {
        ...privacy,
        isActive: false
      };
    });
    this.privacy_list[index].isActive = true;
    this.post_privacy = this.privacy_list[index].typePost;
  }

  handleCloseModal() {
    this.closeModal.emit({
      isClose: false
    });
  }

  handleConfirm() {
    const itemIsSelected = this.privacy_list.find(privacy => privacy.isActive);
    this.emitPrivacy.emit(itemIsSelected?.typePost);
  }
}
