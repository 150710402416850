import {
  GroupAllRequestJoin,
  GroupProfileInfo,
  modalCancelRequestGroup,
  modalLeaveGroup
} from '@app/lib/api/group/api.group.model';
import { createAction, emptyProps, props } from '@ngrx/store';
import { PutLeaveGroup } from '../components/groups-joined/groups-joined.component';

export enum groupProfileTypes {
  getMyGroup = '[Group] my group',
  getMyGroupSuccess = '[Group] my group success',
  getMyGroupFailed = '[Group] my group failure',

  createGroup = '[Group] create new group',
  createGroupSuccess = '[Group] create new group success',
  createGroupFailure = '[Group] create new group failed',

  getMember = '[member] fetch',
  getMemberSuccess = '[Member] fetch success',
  fetchMemberFailure = '[Member] fetch failure',

  //List all request to join group
  getALLGroupRequest = '[Group] get all group request',
  getALLGroupRequestSuccess = '[Group] get all group request success',
  getALLGroupRequestFailure = '[Group] get all group request failed',

  //List all group to join
  getALLGroupToJoin = '[Group] get all group to join',
  getALLGroupToJoinSuccess = '[Group] get all group to join success',
  getALLGroupToJoinFailure = '[Group] get all group to join failed',

  //Cancel request group
  putGroupCanRequest = '[Group] put cancel request group',
  putGroupCanRequestSuccess = '[Group] put cancel request group success',
  putGroupCanRequestFailure = '[Group] put cancel request group failed',

  //Cancel request group
  putLeaveGroup = '[Group] put leave group',
  putLeaveGroupSuccess = '[Group] put leave group success',
  putLeaveGroupFailure = '[Group] put leave group failed',

  // get group pending invites
  getGroupPendingInvites = '[Group] pending invites',
  getGroupPendingInvitesSuccess = '[Group] pending invites success',
  getGroupPendingInvitesFailure = '[Group] pending invites failure',

  // get friends
  getGroupFriends = '[Group] friends',
  getGroupFriendsSuccess = '[Group] friends success',
  getGroupFriendsFailure = '[Group] friends failure',

  //Accept to join group
  putGroupAcceptToJoin = '[Group] put accept to join group',
  putGroupAcceptToJoinSuccess = '[Group] put accept to join group success',
  putGroupAcceptToJoinFailure = '[Group] put accept to join group failed',

  //Decline to join group
  putGroupDeclineToJoin = '[Group] put decline to join group',
  putGroupDeclineToJoinSuccess = '[Group] put decline to join group success',
  putGroupDeclineToJoinFailure = '[Group] put decline to join group failed',

  //get group discover
  getGroupDiscover = '[Group] group discover',
  getGroupDiscoverSuccess = '[Group] group discover success',
  getGroupDiscoverFailed = '[Group] group discover failed',

  //resetAllDataGroup
  resetDataGroup = '[Group] reset Data Group'
}
export const getMyGroup = createAction(
  groupProfileTypes.getMyGroup,
  props<{ pageSize: number; pageNum: number; textSearch: string | null }>()
);
export const getMyGroupSuccess = createAction(groupProfileTypes.getMyGroupSuccess, props<{ dataMyGroups: any }>());
export const getMyGroupFailed = createAction(groupProfileTypes.getMyGroupFailed, props<{ message: string }>());

export const createGroup = createAction(groupProfileTypes.createGroup, props<{ group: GroupProfileInfo }>());
export const createGroupSuccess = createAction(
  groupProfileTypes.createGroupSuccess,
  props<{ group: GroupProfileInfo }>()
);
export const createGroupFailure = createAction(groupProfileTypes.createGroupFailure, props<{ message: string }>());

//List all request to join group
export const getALLGroupRequest = createAction(
  groupProfileTypes.getALLGroupRequest,
  props<{ pageSize: number; pageNum: number; textSearch: string | null }>()
);
export const getALLGroupRequestSuccess = createAction(
  groupProfileTypes.getALLGroupRequestSuccess,
  props<{ getAllGroup: GroupAllRequestJoin }>()
);
export const getALLGroupRequestFailure = createAction(
  groupProfileTypes.getALLGroupRequestFailure,
  props<{ message: string }>()
);

//List all group to join
export const getALLGroupToJoin = createAction(
  groupProfileTypes.getALLGroupToJoin,
  props<{ pageSize: number; pageNum: number; textSearch: string | null }>()
);
export const getALLGroupToJoinSuccess = createAction(
  groupProfileTypes.getALLGroupToJoinSuccess,
  props<{ getAllGroupToJoin: GroupAllRequestJoin }>()
);
export const getALLGroupToJoinFailure = createAction(
  groupProfileTypes.getALLGroupToJoinFailure,
  props<{ message: string }>()
);

//Cancel request group
export const putGroupCanRequest = createAction(groupProfileTypes.putGroupCanRequest, props<{ groupId: string }>());
export const putGroupCanRequestSuccess = createAction(
  groupProfileTypes.putGroupCanRequestSuccess,
  props<{ id: string; cancelRequestGroup: modalCancelRequestGroup }>()
);
export const putGroupCanRequestFailure = createAction(
  groupProfileTypes.putGroupCanRequestFailure,
  props<{ message: string }>()
);

//Cancel request group
export const putLeaveGroup = createAction(groupProfileTypes.putLeaveGroup, props<{ groupId: PutLeaveGroup }>());
export const putLeaveGroupSuccess = createAction(
  groupProfileTypes.putLeaveGroupSuccess,
  props<{ id: string; leaveGroup: modalLeaveGroup }>()
);
export const putLeaveGroupFailure = createAction(groupProfileTypes.putLeaveGroupFailure, props<{ message: string }>());

// get group pending invites
export const getGroupPendingInvites = createAction(
  groupProfileTypes.getGroupPendingInvites,
  props<{ pageSize: number; pageNum: number; textSearch: string | null }>()
);
export const getGroupPendingInvitesSuccess = createAction(
  groupProfileTypes.getGroupPendingInvitesSuccess,
  props<{ getAllGroupPending: GroupAllRequestJoin }>()
);
export const getGroupPendingInvitesFailure = createAction(
  groupProfileTypes.getGroupPendingInvitesFailure,
  props<{ message: string }>()
);

// get friends
export const getGroupFriends = createAction(groupProfileTypes.getGroupFriends, emptyProps);
export const getGroupFriendsSuccess = createAction(
  groupProfileTypes.getGroupFriendsSuccess,
  props<{ getAllFriends: any }>()
);
export const getGroupFriendsFailure = createAction(
  groupProfileTypes.getGroupFriendsFailure,
  props<{ message: string }>()
);

//Accept to join group
export const putGroupAcceptToJoin = createAction(groupProfileTypes.putGroupAcceptToJoin, props<{ groupId: string }>());
export const putGroupAcceptToJoinSuccess = createAction(
  groupProfileTypes.putGroupAcceptToJoinSuccess,
  props<{ id: string; acceptGroup: modalLeaveGroup }>()
);
export const putGroupAcceptToJoinFailure = createAction(
  groupProfileTypes.putGroupAcceptToJoinFailure,
  props<{ message: string }>()
);

//Decline to join group
export const putGroupDeclineToJoin = createAction(
  groupProfileTypes.putGroupDeclineToJoin,
  props<{ groupId: string }>()
);
export const putGroupDeclineToJoinSuccess = createAction(
  groupProfileTypes.putGroupDeclineToJoinSuccess,
  props<{ id: string; declineGroup: modalLeaveGroup }>()
);
export const putGroupDeclineToJoinFailure = createAction(
  groupProfileTypes.putGroupDeclineToJoinFailure,
  props<{ message: string }>()
);

// get group discover
export const getGroupDiscover = createAction(
  groupProfileTypes.getGroupDiscover,
  props<{ pageSize: number; pageNum: number; textSearch: string | null }>()
);
export const getGroupDiscoverSuccess = createAction(
  groupProfileTypes.getGroupDiscoverSuccess,
  props<{ getAllGroupDiscover: any }>()
);
export const getGroupDiscoverFailed = createAction(
  groupProfileTypes.getGroupPendingInvitesFailure,
  props<{ message: string }>()
);

export const resetDataGroup = createAction(groupProfileTypes.resetDataGroup, emptyProps);
