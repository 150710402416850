import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, map, retry, throwError } from 'rxjs';
import { RelationshipStatusEnum } from '../enums/makeFriend.enum';

@Injectable({
  providedIn: 'root'
})
export class FriendService {
  private api: ApiClient;
  apiUrl = environment.baseURL;
  selectedTab = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getRelationshipStatus(userId: string) {
    return this.api.friend.getRelationshipStatus(userId).pipe(
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      })
    );
  }

  postFriendRequest(userId: string) {
    return this.api.friend.postFriendRequest(userId, {}).pipe(
      map((res: any) => {
        if (res.success) {
          res.data = { ...res.data, status: RelationshipStatusEnum.SentRequest };
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  putCancelFriendRequest(requestId: string) {
    return this.api.friend.putCancelFriendRequest(requestId, {}).pipe(
      map((res: any) => {
        if (res.success) {
          res.data = { status: RelationshipStatusEnum.UnKnow };
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  putAcceptFriendRequest(requestId: string) {
    return this.api.friend.putAcceptFriendRequest(requestId, {}).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          res.data = { status: RelationshipStatusEnum.FriendShip };
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  putDeclineFriendRequest(requestId: string) {
    return this.api.friend.putDeclineFriendRequest(requestId, {}).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          res.data = { status: RelationshipStatusEnum.UnKnow };
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  deleteFriend(userId: string) {
    return this.api.friend.deleteFriend(userId).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          res.data = { status: RelationshipStatusEnum.UnKnow };
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getFriend(pageNum = 0, pageSize = 10, userId?: string) {
    return this.api.friend.getFriend(pageNum, pageSize, userId).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getSendFriendRequest(pageNum: number, pageSize: number, searchText: string) {
    return this.api.friend.getSendFriendRequest(pageNum, pageSize, searchText).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getFriendRequestReceive(pageNum: number, pageSize: number, searchText: string) {
    return this.api.friend.getFriendRequestReceive(pageNum, pageSize, searchText).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getSearchFriends(searchText: string, pageSize?: number, pageNum?: number) {
    return this.api.friend.getSearchFriends(searchText, pageSize, pageNum).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getSearchFriendsV2(
    searchText: string,
    pageSize?: number,
    pageNum?: number,
    isMentionEnabled = false,
    isTagEnabled = false
  ) {
    return this.api.friend.getSearchFriendsV2(searchText, pageSize, pageNum, isMentionEnabled, isTagEnabled).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  getOrSearchFriend(searchText: string, pageSize?: number, pageNum?: number) {
    if (searchText && (searchText || '').trim().length) {
      return this.getSearchFriends(searchText, pageSize, pageNum);
    } else {
      return this.getFriend(pageNum, pageSize);
    }
  }

  getOrSearchFriendV2(
    searchText: string,
    pageSize?: number,
    pageNum?: number,
    is_mention_enabled?: boolean,
    is_tag_enabled?: boolean
  ) {
    return this.getSearchFriendsV2(searchText, pageSize, pageNum, is_mention_enabled, is_tag_enabled);
  }

  getSuggestFriends(pageNum: number, pageSize: number, searchText?: string) {
    const url = this.apiUrl + `/friend/suggest-friends?text=${searchText ?? ''}&page=${pageNum}&size=${pageSize}`;
    return this.http.get<any>(url, {}).pipe(
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  checkFriendship(friendId: string) {
    return this.api.friend.isFriend(friendId).pipe(
      retry(1),
      map((res: any) => {
        if (res.success) {
          return res;
        } else {
          throw new Error(JSON.stringify(res.error));
        }
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.message;
    } else if (error && error.status) {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    } else if (typeof error === 'string') {
      // If the error is a string
      errorMessage = error;
    } else if (error instanceof Error) {
      // If the error is an instance of the Error class
      errorMessage = JSON.parse(error.message).message;
    } else {
      // Fallback for other cases
      errorMessage = `There is something wrong.\nPlease try again later `;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  onNavigateFriendTabs(tab: string) {
    this.selectedTab.next(tab);
  }
}
