<p-dialog [modal]="true" [draggable]="false" [resizable]="false" [(visible)]="isShowDialog"
  [style]="{ width: widthClass ? widthClass : '588px' }" (onHide)="isShowDialogChange.emit(false)"
  [styleClass]="'border-0 rounded-xl'" [dismissableMask]="true">
  <ng-template pTemplate="header">
    <span class="text-lg font-semibold text-palette-gray-900">{{ headerName ? headerName :
      ('LIVESTREAM.DELETE_RECORD' | translate) }}</span>
  </ng-template>
  <p class="font-normal text-sm text-palette-gray-700">{{description ? description :
    ('LIVESTREAM.DELETE_RECORD_DESCRIPTION' | translate)
    }}</p>
  <div class="pt-6 flex justify-end gap-3">
    <p-button [label]="leftBtnLabel ? leftBtnLabel : ('COMMON.BUTTON_ACTION.CANCEL' | translate)"
      styleClass="px-4 py-2.5 rounded-lg p-button-secondary p-button-text border-palette-gray-300 text-palette-gray-900"
      (onClick)="buttonClickedEmit(false)">
    </p-button>
    <p-button [label]="rightBtnLabel ? rightBtnLabel : ('COMMON.BUTTON_ACTION.SAVE' | translate)"
      [styleClass]="'rounded-lg px-4 py-2.5 ' + (isRedBtn ? 'bg-palette-red-600 border-palette-red-600' : 'bg-palette-blue-600 border-palette-blue-600')"
      (onClick)="buttonClickedEmit(true)">
    </p-button>
  </div>
</p-dialog>