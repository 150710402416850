<p-dialog
  [(visible)]="visible"
  [modal]="true"
  (onHide)="visibleChange.emit(false)"
  [dismissableMask]="true"
  [draggable]="false"
  [styleClass]="'w-fit round-xl'">
  <ng-template pTemplate="header"> {{ 'POLL.POLL_QR_CODE' | translate }} </ng-template>
  <p-skeleton
    *ngIf="loadingQrCode"
    class="flex justify-center qr-content px-5"
    width="475px"
    height="475px"></p-skeleton>
  <div #qrCodeCanvas class="flex justify-center qr-content px-5"></div>
  <ng-template pTemplate="footer">
    <div class="flex w-full flex-row">
      <button
        pButton
        [disabled]="loadingQrCode"
        class="w-full font-semibold flex justify-center text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200 py-2 active"
        (click)="downloadQR('svg')">
        {{ 'POLL.DOWNLOAD_SVG' | translate }}
      </button>
      <button
        pButton
        [disabled]="loadingQrCode"
        class="w-full font-semibold flex justify-center text-palette-base-white bg-palette-blue-600 border-palette-blue-300 py-2 active"
        (click)="downloadQR('png')">
        {{ 'POLL.DOWNLOAD_PNG' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>
