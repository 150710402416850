<ul
  class="search-box-scroll list-none h-[328px] auto-rows-max overflow-auto"
  [ngClass]="{
    '-mr-[10px] -ml-3 my-0': isCheckIn,
    '-mx-2 grid grid-cols-2 md:grid-cols-3 gap-2 p-2 pb-0': !isCheckIn,
    'hidden': !isCheckIn && !(listItems | filter: searchText : 'label').length
  }"
  scroll
  (scrolledToBottom)="handleLoadMoreFanpage()">
  <ng-container *ngIf="!isCheckIn">
    <li
      class="cursor-pointer h-11 p-2 gap-2 hover:bg-palette-gray-100 flex items-center rounded-md"
      [ngClass]="{ 'bg-palette-gray-100 hover:bg-palette-gray-200': selectedStatus === item.status }"
      *ngFor="let item of listItems | filter: searchText : 'label'"
      (click)="onSelect(item)">
      <span class="p-1 emoji">{{ item.status | statusIcons }}</span>
      <span>{{ item.label }}</span>
      <span
        [ngClass]="{
          'sctr-icon-check last-of-type:ml-auto text-branding-primary-600': selectedStatus === item.status
        }"></span>
    </li>
  </ng-container>

  <ng-container *ngIf="isCheckIn">
    <li
      (click)="unSelectLocation()"
      class="flex cursor-pointer p-[10px] my-[2px] mx-[6px] bg-palette-gray-50 items-center justify-between"
      *ngIf="(!locations || locations?.length === 0) && selectedLocation">
      <span class="text-palette-blue-600 max-w-[90%] break-words">{{ selectedLocation.object_data_check_in?.page_name }}</span>
      <i class="sctr-icon-check text-palette-blue-600 text-xl leading-5"></i>
    </li>

    <li
      class="flex cursor-pointer p-[10px] my-[2px] mx-[6px] hover:bg-palette-gray-50 items-center justify-between"
      *ngFor="let item of locations"
      [ngClass]="{
        'bg-palette-gray-50': selectedLocation && item.id === selectedLocation.object_id
      }"
      (click)="onSelectLocation(item)">
      <span class="max-w-[90%] break-words">{{ item.name }}</span>
      <i *ngIf="item.isActive" class="sctr-icon-check text-palette-blue-600 text-xl leading-5"></i>
    </li>

    <ng-container
        *ngIf="loadingMoreLocation"
        class="search-box-scroll list-none -mr-[10px] -ml-3 my-0 auto-rows-max h-[350px] py-3 overflow-auto">
        <li
          class="flex flex-col justify-start cursor-pointer p-[10px] my-[2px] mx-[6px] hover:bg-palette-gray-50 items-start">
          <loading-skeleton rounded></loading-skeleton>
        </li>
        <li
          class="flex flex-col justify-start cursor-pointer p-[10px] my-[2px] mx-[6px] hover:bg-palette-gray-50 items-start">
          <loading-skeleton rounded></loading-skeleton>
        </li>
        <li
          class="flex flex-col justify-start cursor-pointer p-[10px] my-[2px] mx-[6px] hover:bg-palette-gray-50 items-start">
          <loading-skeleton rounded></loading-skeleton>
        </li>
      </ng-container>
  </ng-container>
  <ng-container *ngIf="listItems && !(listItems | filter: inputValue : 'status')?.length || locations && !locations?.length">
    <div class="col-span-3 mt-14">
      <search-not-found [message]="'COMMON.SEARCH_NOT_FOUND'" />
    </div>
  </ng-container>
</ul>

<div
  *ngIf="!isCheckIn && !(listItems | filter: searchText : 'label').length"
  class="w-full h-[328px] flex items-center justify-center">
  <div class="flex flex-col items-center gap-4 py-6">
    <div
      class="w-14 h-14 bg-branding-primary-100 border-8 border-branding-primary-50 rounded-full flex items-center justify-center">
      <i class="sctr-icon-search-lg text-2xl/6 text-branding-primary-600"></i>
    </div>
    <span class="text-lg font-semibold text-palette-gray-900">{{ 'COMMON.NO_RESULTS_FOUND' | translate }}</span>
  </div>
</div>
