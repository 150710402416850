import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { EpochConverterPipe } from '@app/shared/pipes/epoch-converter.pipe';

@Component({
  selector: 'date-range-calender',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './date-range-calender.component.html',
  styleUrls: ['./date-range-calender.component.scss']
})
export class DateRangeCalenderComponent implements OnInit {
  @Input() rangeDates: Date[] = [];
  @Input() disabledDates: Date[] = [];
  @Input() dateRangesCount = 0;
  @Input() appendTo: string = '';
  @Input() disabled = false;
  @Input() showClear = false;
  @Input() rightElement = false;
  @Output() selectedDateRanges = new EventEmitter();
  epochConverter = new EpochConverterPipe();
  isValidRanges = true;
  dateLimit: Date;
  maxDateLimit: Date;
  resetDateRanges: Date[] = [];

  constructor(private translationService: TranslationService) {
    this.translationService.language$.subscribe(() => {
      const oldDateRanges = [...this.rangeDates];
      this.rangeDates = [];
      this.rangeDates = oldDateRanges;
    });
  }

  ngOnInit(): void {
    const today = new Date();
    this.maxDateLimit = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.dateLimit = this.maxDateLimit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['dateRangesCount']) {
        this.checkSelectedDateRanges();
      }
    }
  }

  checkSelectedDateRanges(): void {
    if (this.dateRangesCount > 0 && this.rangeDates[1]) {
      const expectedDateRangesCount =
        (Number(this.rangeDates[1]) - Number(this.rangeDates[0])) / this.epochConverter.convertToMiliseconds(1) + 1;
      this.isValidRanges = expectedDateRangesCount === this.dateRangesCount;
      if (this.isValidRanges) {
        this.selectedDateRanges.emit(this.rangeDates);
      }
    }
  }

  onSelectedDate(selectedDate: Date): void {
    if (this.rangeDates[0]) {
      if (this.rangeDates[1] === null) {
        if (this.dateRangesCount) {
          const dateRangeLimit = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() + this.dateRangesCount - 1
          );
          this.rangeDates[1] = dateRangeLimit;
          this.isValidRanges = true;
        } else {
          const nextYear = new Date(
            selectedDate.getFullYear() + 1,
            selectedDate.getMonth(),
            selectedDate.getDate() - 1
          );
          this.dateLimit = nextYear < this.maxDateLimit ? nextYear : this.maxDateLimit;
        }
      }
    }
  }

  onCancelSelectDate(): void {
    this.rangeDates = this.resetDateRanges;
    this.checkSelectedDateRanges();
  }

  onApplyChanges(): void {
    if (this.rangeDates[1]) {
      this.selectedDateRanges.emit(this.rangeDates);
      this.resetDateRanges = this.rangeDates;
    }
  }

  onClickedClear(event: any): void {
    event.preventDefault();
    this.rangeDates = [];
    this.selectedDateRanges.emit(this.rangeDates);
  }
}
