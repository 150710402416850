<modal #qrCanvas [showDialog]="isShowShareDialog" (emitOnCloseDialog)="onCloseDialog()" [dialogConfig]="dialogConfig">
  <ng-container modal-header>
    <div class="flex justify-center items-center">
      <p class="text-base font-semibold">{{ 'COMMON.SHARE' | translate }}</p>
    </div>
  </ng-container>
  <ng-container modal-content>
    <div class="flex gap-3 mb-6 w-[22rem]">
      <img
        *ngFor="let platform of platforms"
        src="{{ platform.icon }}"
        alt="share on {{ platform.name }}"
        title="{{ platform.name }}"
        height="50px"
        width="50px"
        class="cursor-pointer"
        (click)="shareOnPlatforms(platform.name)" />
    </div>
    <div class="p-inputgroup">
      <input
        id="shareURI"
        type="text"
        pInputText
        class="rounded-l-lg shadow-none text-sm"
        [value]="shareURI"
        onclick="this.select();" />
      <button
        class="p-inputgroup-addon rounded-r-lg cursor-pointer bg-palette-base-white"
        title="{{ 'COMMON.COPY_LINK' | translate }}"
        (click)="copyContent()">
        <span class="sctr-icon-check-done-02" *ngIf="copied; else copyIcon"></span>
        <ng-template #copyIcon>
          <span class="sctr-icon-copy-02"></span>
        </ng-template>
      </button>
    </div>
  </ng-container>
</modal>
