import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoinState } from './state';

export const CoinManagement = createFeatureSelector<CoinState>('coin');

export const selectQuestList = createSelector(CoinManagement, coinManagement => coinManagement.questList);

export const selectMyCoin = createSelector(CoinManagement, coinManagement => {
  return coinManagement.myCoin;
});
export const selectClaimingState = createSelector(CoinManagement, coinManagement => coinManagement.claimingReward);
