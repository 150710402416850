import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import {
  BloomFilter,
  BookMarkCategory,
  BookMarkCollection,
  Bookmark,
  BookmarkRequest,
  IsExistBookmark
} from '@app/lib/api/bookmark/api.bookmark.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookmarksService {
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  createBookmark(body: BookmarkRequest): Observable<Bookmark> {
    return this.api.bookmark.createBookmark(body);
  }

  getBloomFilter(): Observable<BloomFilter> {
    return this.api.bookmark.getBloomFilter();
  }

  getFollowBloomFilter(follow: string): Observable<any> {
    return this.api.bookmark.getFollowBloomFilter(follow);
  }

  getListFriendBloomFilter(userId: string): Observable<any> {
    return this.api.bookmark.getListFriendBloomFilter(userId);
  }

  checkExistBookmark(postId: string): Observable<IsExistBookmark> {
    return this.api.bookmark.checkExistBookmark(postId);
  }

  deleteBookmark(postId: string): Observable<boolean> {
    return this.api.bookmark.deleteBookmark(postId);
  }

  createCategory(nameCategory: string): Observable<BookMarkCategory> {
    return this.api.bookmark.createCategory(nameCategory);
  }

  getBookMarkCollection(pageNum: number, pageSize: number): Observable<BookMarkCollection> {
    return this.api.bookmark.getBookMarkCollection(pageNum, pageSize);
  }
}
