import { Component, EventEmitter, Output } from '@angular/core';
import { EpochConverterPipe } from '@app/shared/pipes/epoch-converter.pipe';

@Component({
  selector: 'compare-date-ranges',
  templateUrl: './compare-date-ranges.component.html',
  styleUrls: ['./compare-date-ranges.component.scss']
})
export class CompareDateRangesComponent {
  @Output() compareDateRanges = new EventEmitter();
  dateRanges1: Date[] = [];
  dateRanges2: Date[] = [];
  dateRangesCount = 0;
  disabledDates: Date[] = [];
  epochConverter = new EpochConverterPipe();

  ngOnInit(): void {
    this.loadDefaultDateRanges();
    this.compareDateRanges.emit({ dateRanges1: this.dateRanges1, dateRanges2: this.dateRanges2 });
  }

  loadDefaultDateRanges(): void {
    const today = new Date();
    const currMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.dateRanges1.push(currMonthStartDate);
    this.dateRanges1.push(currMonthEndDate);
    this.onDateRanges1Updated(this.dateRanges1);
    const dateRanges2EndDate = new Date(
      currMonthStartDate.getFullYear(),
      currMonthStartDate.getMonth(),
      currMonthStartDate.getDate() - 1
    );
    const dateRanges2StartDate = new Date(
      dateRanges2EndDate.getFullYear(),
      dateRanges2EndDate.getMonth(),
      dateRanges2EndDate.getDate() - this.dateRangesCount + 1
    );
    this.dateRanges2.push(dateRanges2StartDate);
    this.dateRanges2.push(dateRanges2EndDate);
  }

  onDateRanges1Updated(dateRanges: Date[]): void {
    const dateRangesCount =
      (Number(dateRanges[1]) - Number(dateRanges[0])) / this.epochConverter.convertToMiliseconds(1) + 1;
    this.dateRangesCount = dateRangesCount;
    this.disabledDates = [];
    for (let i = 0; i < dateRangesCount + dateRangesCount - 1; i++) {
      this.disabledDates.push(
        new Date(
          dateRanges[0].getFullYear(),
          dateRanges[0].getMonth(),
          dateRanges[0].getDate() - dateRangesCount + i + 100
        )
      );
    }
  }

  onSelectedDateRanges1(selectedDateRanges: Date[]): void {
    this.dateRanges1 = selectedDateRanges;
    this.onDateRanges1Updated(this.dateRanges1);
  }

  onSelectedDateRanges2(selectedDateRanges: Date[]): void {
    this.dateRanges2 = selectedDateRanges;
    this.compareDateRanges.emit({ dateRanges1: this.dateRanges1, dateRanges2: this.dateRanges2 });
  }
}
