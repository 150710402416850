export class FAN_PAGES_API_ENDPOINTS {
  public static GET_FAN_PAGES_FOLLOWING: string = '/page/pages/user-followed-pages';
  public static GET_FAN_PAGES_MY_FAN_PAGES: string = '/page/pages/user-created-pages';
  public static CATEGORY: string = '/page/pages/category';
  public static POST_FAN_PAGES_CREATE: string = '/page/pages';
  public static POST_EVENTS_CREATE: string = '/event/events';
  public static GET_EVENTS_DETAIL: string = '/event/events/detail/';
  public static GET_FAN_PAGES_OVERVIEW: string = '/overview';
  public static GET_FAN_PAGES_CONTACT: string = '/contact-info';
  public static GET_FAN_PAGES: string = '/page/pages/';
  public static GET_FOLLOWER: string = '/follower/followers/';
  public static GET_FOLLOWING: string = '/follower/followers/following';
  public static GET_MEMBER: string = '/page/members';
  public static POST_FANPAGES: string = '/page/posts';
  public static PUT_DECLINE_INVITED: string = '/decline-invited';
  public static PUT_ACCEPT_INVITED: string = '/accept-invited';
  public static PUT_BLOCK_FOLLOWERS: string = '/block-members';
  public static PUT_UNBLOCK_FOLLOWERS: string = '/unblock-members';
  public static GET_SEARCH_FOLLOWERS: string = '/search-and-get-member-followers';
  public static GET_SEARCH_BLOCKERS: string = '/search-and-get-member-blocked';
}
