import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-people-card',
  templateUrl: './loading-people-card.component.html',
  styleUrls: ['./loading-people-card.component.scss']
})
export class LoadingPeopleCardComponent {
  @Input() profileCoin = false
}
