import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { CommentsService } from '@app/core/services/comments.service';
import { ReactionsService } from '@app/core/services/reactions.service';
import { ReportService } from '@app/core/services/report.service';
import { StoriesService } from '@app/core/services/stories.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION, ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { getReportCategories } from '@app/modules/main/states/report/report.actions';
import { selectReportCategories } from '@app/modules/main/states/report/report.selectors';
import { StoryActions } from '@app/modules/main/states/stories/stories.actions';
import {
  FILE_EXTENSION,
  MAX_QTY_REACTIONS_STORAGE,
  MEDIA_TYPE,
  OBJECT_TYPE,
  PRIVACY_OPTIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { ENTITY_TYPE, EmojiList, FILE_TYPE_URL, MyReaction, ReactionInfo, checkUrl } from '@app/shared/models/post';
import { MyStoryOptions, Story, StoryAction, UsersStoryOptions } from '@app/shared/models/story';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs';
import { getFromNow } from '../../models/post';
import { CommentFormComponent } from '../comment-form/comment-form.component';

const actionType = {
  next: 'next',
  previous: 'previous'
};

@Component({
  selector: 'stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss'],
  animations: [
    trigger('animateLeft', [
      state('in', style({ opacity: 1, left: '-16px', bottom: '-16px' })),
      state('out', style({ opacity: 1, left: '-42px', bottom: '250px' })),
      transition(
        'void => *',
        animate(
          '1500ms ease-in',
          keyframes([
            style({ left: '-16px', bottom: '-16px', transform: 'translate(0)', offset: 0 }),
            style({ left: '-72px', bottom: '100px', transform: 'translate(0)', offset: 0.5 }),
            style({ left: '-16px', bottom: '200px', transform: 'translate(0)', offset: 0.75 }),
            style({ left: '-42px', bottom: '250px', transform: 'translate(0)', offset: 1 })
          ])
        )
      )
    ]),
    trigger('animateRight', [
      state('in', style({ opacity: 1, left: '-16px', bottom: '-16px' })),
      state('out', style({ opacity: 1, left: '20px', bottom: '250px' })),
      transition(
        'void => *',
        animate(
          '1500ms ease-in',
          keyframes([
            style({ left: '-16px', bottom: '-16px', transform: 'translate(0)', offset: 0 }),
            style({ left: '40px', bottom: '100px', transform: 'translate(0)', offset: 0.5 }),
            style({ left: '-16px', bottom: '200px', transform: 'translate(0)', offset: 0.75 }),
            style({ left: '20px', bottom: '250px', transform: 'translate(0)', offset: 1 })
          ])
        )
      )
    ]),
    trigger('animateCenter', [
      state('in', style({ opacity: 1, left: '-16px', bottom: '-16px' })),
      state('out', style({ opacity: 1, left: '-16px', bottom: '200px' })),
      transition(
        'void => *',
        animate(
          '1000ms ease',
          keyframes([
            style({ left: '-16px', bottom: '-16px', transform: 'translate(0)', offset: 0 }),
            style({ left: '-16px', bottom: '200px', transform: 'translate(0)', offset: 1 })
          ])
        )
      )
    ])
  ]
})
export class StoriesComponent {
  @Input() listStories: Story[] = [];
  @Input() visible = false;
  @Input() userInfo: UserInfo;
  @Input() story: Story;
  @Input() isViewArchive = false;
  @Input() totalStoriesArchived = 0;
  @Output() visibleChange = new EventEmitter();
  @Output() loadMoreStoriesArchived = new EventEmitter();
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  @ViewChild(CommentFormComponent, { static: false }) commentFormComponent: CommentFormComponent;

  reportCategories$ = this.store.pipe(select(selectReportCategories));

  transferStory = 0;
  transferContent = 0;
  progressValueSaved = 0;
  progressValues = 0;
  pageSize = 10;
  indexTabDialogViewers = 0;
  visibleViewers = false;
  isAnimating = false;
  overlayVisible = false;
  overlayIconVisible = false;
  checked = false;
  isLandscapeImg = false;
  isDeleteStory = false;
  configVideo = {
    isMuted: false,
    isPlay: true,
    isAutoPlay: true
  };
  isShowReport = false;
  isDetailStory = false;
  storiesPrivacy = '';
  durationOfVideo = 0;
  totalCommentsAndReplies: number;
  animationFrameId: number;
  userStoryOptions = UsersStoryOptions;
  myStoryOptions = MyStoryOptions;
  allStories: Story[] = [];
  currentStories: Story[] = [];
  comments: Comment[] = [];
  comment: Comment;
  timeOutData: ReturnType<typeof setTimeout>;
  listener: () => void;
  mediaType = MEDIA_TYPE;
  fileType = FILE_TYPE_URL;
  actionType = actionType;
  getFromNow = getFromNow;
  appName = environment.APP_NAME;
  urlStory = '';
  StoryAction = StoryAction;
  selectedEmoji: any;
  emojiList = EmojiList;
  totalReactions = 0;
  isIconAnimated = false;
  randomAnimateType = {
    left: 0,
    center: 1,
    right: 2
  };
  isModerateStory = false;
  deletedIds: string[] = [];
  isLoadingReport = false;
  animateIcons: { id: number; animate: number; emojiIcon: string }[] = [];
  private idCounter = 0;

  constructor(
    private renderer: Renderer2,
    private store: Store,
    private commentsService: CommentsService,
    private storiesService: StoriesService,
    private analyticsService: AnalyticsService,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private translateService: TranslationService,
    private reactionsService: ReactionsService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.listener = this.renderer.listen('document', 'visibilitychange', () => {
      this.checkPageVisibility();
    });
    this.isModerateStory = this.route.snapshot.queryParams['admin'] === 'true';
    if (this.isModerateStory) {
      const commentId = this.route.snapshot.queryParamMap.get('comment-id') || '';
      if (commentId) {
        this.showHideDialogViewers(1);
        const commentId = this.route.snapshot.queryParamMap.get('comment-id') || '';
        if (commentId) {
          this.commentsService.getCommentObjectById(commentId).subscribe(comment => {
            this.comment = comment;
          });
        }
      }
    }
    this.reportCategories$.pipe(filter(res => !res)).subscribe(() => {
      this.store.dispatch(getReportCategories());
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.selectedEmoji = null;
    const storyId = this.route.snapshot.paramMap.get('id');
    if (!storyId) {
      this.allStories = [];
      for (const story of this.listStories) {
        if (story.user_id === this.userInfo.id && !this.isViewArchive) {
          this.allStories.unshift(story);
        } else {
          this.allStories.push(story);
        }
      }
      if (changes['story'] && changes['story'].currentValue) {
        if (this.isViewArchive) {
          this.myStoryOptions = MyStoryOptions.filter(item => item.type !== StoryAction.copy);
          this.transferStory = this.allStories.findIndex(story => story.id === this.story.id);
          if (
            this.transferStory + 1 >= this.allStories.length - 1 &&
            this.allStories.length < this.totalStoriesArchived
          ) {
            this.loadMoreStoriesArchived.emit();
          }
        } else {
          this.transferStory = this.allStories.findIndex(story => story.user_id === this.story.user_id);
        }
        this.currentStories = [...(await this.getCurrentStories())];
        this.updateStoryPrivacy();
        this.setLayoutForImage();
        this.getComments();
        this.getCurrentReaction();
        this.runProgressBar();
      }
    } else {
      this.isDetailStory = true;
      this.allStories = this.currentStories = [...this.listStories];
      if (this.allStories.length > 0) {
        this.updateStoryPrivacy();
        this.setLayoutForImage();
        this.getComments();
        this.getCurrentReaction();
        this.runProgressBar();
      }
    }
  }

  private checkPageVisibility() {
    if (document.hidden) {
      this.clearAnimate();
      this.progressValueSaved = this.progressValues;
      this.playPauseMedia(false);
    } else if (!this.isAnimating && !this.overlayVisible && !this.visibleViewers) {
      if (this.configVideo.isPlay) {
        this.playPauseMedia(true);
      }
    }
  }

  // ------------- handle run ProgressBar ------------
  checkProgressRun(progress: number) {
    if (this.isAnimating) {
      this.progressValues = progress + this.progressValueSaved;
    } else {
      this.progressValueSaved = progress + this.progressValueSaved;
    }
  }

  runProgressBar() {
    if (this.getStory()?.story_media.video_url && !this.durationOfVideo) {
      this.handleVideoStories();
    } else {
      let duration = 5000; // duration of image
      if (this.getStory()?.story_type === MEDIA_TYPE.video) {
        duration = this.durationOfVideo * 1000; // parse to milisecond
      } else if (this.getStory()?.story_type === MEDIA_TYPE.image && this.getStory()?.story_media?.video_url) {
        duration = 15000;
      }
      const animate = () => {
        const startTime = Date.now();
        const updateProgress = () => {
          const currentTime = Date.now();
          const elapsedTime = currentTime - startTime;
          const progress = (elapsedTime / duration) * 100;
          this.checkProgressRun(progress);
          if (!this.isAnimating) return;
          if (this.progressValues < 100) {
            this.animationFrameId = requestAnimationFrame(updateProgress);
          } else {
            this.onTransferStory(this.actionType.next);
          }
        };
        updateProgress();
      };
      this.isAnimating = true;
      animate();
    }
  }
  // ------------- end handle run ProgressBar ------------

  // ------------- handle transfer a story ------------
  handleStory() {
    this.analyticsService.pushToBuffer(this.urlStory, ADS_CATEGORY.story, ADS_ACTION.view);
    this.durationOfVideo = 0;
    this.configVideo.isPlay = true;
    this.configVideo.isAutoPlay = false;
    if (this.visibleViewers && !this.isModerateStory) {
      this.visibleViewers = false;
    }
    // reset comment form
    if (this.commentFormComponent) {
      this.commentFormComponent.resetTextarea();
    }
    if (
      this.isViewArchive &&
      this.transferStory + 1 >= this.allStories.length - 1 &&
      this.allStories.length < this.totalStoriesArchived
    ) {
      this.loadMoreStoriesArchived.emit();
    }
    this.selectedEmoji = null;
    this.clearAnimate();
    this.resetValueProgress();
    this.setLayoutForImage();
    this.updateStoryPrivacy();
    this.getComments();
    this.getCurrentReaction();
    this.runProgressBar();
  }

  async onTransferStory(action: string) {
    if (action === this.actionType.next) {
      if (this.transferContent < this.currentStories.length - 1) {
        this.transferContent++;
      } else if (this.transferStory < this.allStories.length - 1) {
        this.transferStory++;
        this.currentStories = [...(await this.getCurrentStories())];
      } else if (!this.isModerateStory) {
        this.goBack();
        return;
      }
    } else if (this.transferContent !== 0) {
      this.transferContent--;
    } else if (this.transferStory !== 0) {
      this.transferStory--;
      this.currentStories = [...(await this.getCurrentStories())];
    }
    this.handleStory();
  }

  async onTransferStoryUser(action: string) {
    if (action === this.actionType.next) {
      this.transferStory++;
    } else {
      this.transferStory--;
    }
    this.currentStories = [...(await this.getCurrentStories())];
    this.handleStory();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.isAnimating) {
      if (event.key === 'ArrowRight') {
        this.onTransferStory(this.actionType.next);
      } else if (event.key === 'ArrowLeft') {
        this.onTransferStory(this.actionType.previous);
      }
    }
  }

  updateStoryPrivacy() {
    // transfer privacy of current story
    this.storiesPrivacy = PRIVACY_OPTIONS.filter(el => el.privacy === this.getStory()?.story_privacy)
      .map(el => el.icon)
      .join('');
  }

  clearAnimate() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  resetValueProgress() {
    this.progressValues = 0;
    this.progressValueSaved = 0;
  }

  async getCurrentStories(userId = this.allStories[this.transferStory].user_id) {
    this.transferContent = 0;
    let data: Story[] = [];
    if (this.isViewArchive) {
      data = [
        await this.storiesService
          .getStoriesById(this.allStories[this.transferStory].id, this.isModerateStory)
          .toPromise()
      ] as Story[];
    } else {
      data = (await this.storiesService.getStoriesByUserId(userId, 10).toPromise()) as Story[];
    }
    return data;
  }

  getStory(indexContent = this.transferContent) {
    if (this.currentStories.length === 0) return;
    return this.currentStories[indexContent];
  }
  // ------------- end handle transfer a story ------------

  // ------------- handle action of options ------------
  showHideStoryOption(visible: boolean) {
    if (visible && this.visibleViewers) {
      this.visibleViewers = false;
    }
    this.overlayVisible = visible;
    if (this.configVideo.isPlay) {
      this.playPauseMedia(!this.overlayVisible);
    }
  }

  showHideDialogViewers(tabIndex: number, visible = true) {
    if (this.visibleViewers === visible) return;
    this.visibleViewers = visible;
    this.indexTabDialogViewers = tabIndex;
    if (this.configVideo.isPlay) {
      this.playPauseMedia(!this.visibleViewers);
    }
  }

  toggleImageSelection(index: number) {
    this.durationOfVideo = 0;
    this.configVideo.isAutoPlay = false;
    this.transferContent = index;
    this.clearAnimate();
    this.setLayoutForImage();
    this.resetValueProgress();
    this.getComments();
    this.getCurrentReaction();
  }

  onListItemClick(event: any) {
    this.overlayVisible = false;
    const selectedOption = event.value;
    switch (selectedOption.type) {
      case StoryAction.report:
        this.isShowReport = true;
        break;
      case StoryAction.download:
        const downloadBody = {
          files:
            (this.getStory()?.story_type === MEDIA_TYPE.image
              ? this.getStory()?.story_media?.image_url
              : this.getStory()?.story_media?.video_url) || '',
          name: ''
        };

        this.storiesService.downloadStory(downloadBody);
        if (this.configVideo.isPlay) {
          this.playPauseMedia(!this.overlayVisible);
        }
        break;
      case StoryAction.copy:
        navigator.clipboard.writeText(`${environment.SOCIAL_APP_URL}/story/${this.getStory()?.id}`);
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, 'DROPDOWN_SHARE.LINK_COPIED');
        if (this.configVideo.isPlay) {
          this.playPauseMedia(!this.overlayVisible);
        }
        break;
      case StoryAction.delete:
        this.isDeleteStory = true;
        break;
      default:
        break;
    }
  }

  confirmDeleteDialog(isOk: boolean): void {
    this.isDeleteStory = false;
    if (isOk) {
      const id = this.getStory()?.id;
      if (!id) return;
      this.storiesService.deleteStory(id).subscribe(async res => {
        if (!res) return;
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.translateService.getTranslation('STORY.DELETE_STORY_SUCCESSFULLY')
        );
        if (this.isDetailStory) {
          this.router.navigateByUrl('/');
          return;
        }
        if (!this.isViewArchive) {
          if (this.allStories[0].id === id && this.currentStories.length > 1) {
            this.allStories[0] = { ...this.currentStories[1], user_object: this.allStories[0].user_object };
            this.store.dispatch(StoryActions.onLoadYourStory({ stories: this.allStories }));
          }
          this.currentStories.splice(this.transferContent, 1);
          if (this.currentStories.length === 0) {
            this.storiesService.deleteAllMyStory.next(id);
            this.allStories.splice(0, 1);
            if (this.transferStory <= this.allStories.length - 1) {
              this.currentStories = [...(await this.getCurrentStories())];
            } else {
              this.goBack();
              return;
            }
          } else if (
            this.transferContent > this.currentStories.length - 1 &&
            this.transferStory < this.allStories.length - 1
          ) {
            this.transferStory++;
            this.currentStories = [...(await this.getCurrentStories())];
          } else if (
            this.transferContent > this.currentStories.length - 1 &&
            this.transferStory >= this.allStories.length - 1
          ) {
            this.goBack();
            return;
          }
        } else {
          const index = this.allStories.findIndex(myStory => myStory.id === id);
          this.allStories.splice(index, 1);
          if (this.transferStory > this.allStories.length - 1) {
            this.goBack();
            return;
          }
          this.deletedIds.push(id);
          this.currentStories = [...(await this.getCurrentStories())];
        }
        this.handleStory();
      });
    } else {
      if (this.configVideo.isPlay) {
        this.playPauseMedia(!this.overlayVisible);
      }
    }
  }

  onClickPlayPauseBtn(value: boolean) {
    this.analyticsService.pushToBuffer(this.urlStory, ADS_CATEGORY.story, !value ? ADS_ACTION.pause : ADS_ACTION.play);
    this.configVideo.isPlay = value;
    this.playPauseMedia(this.configVideo.isPlay);
    if (value && this.visibleViewers) {
      this.visibleViewers = false;
    }
  }
  // ------------- end handle action of options ------------

  // --------handle comment------------
  getComments() {
    const story = this.getStory();
    if (story) {
      this.totalCommentsAndReplies = story.total_comment || 0;
      this.commentsService.getCommentsByPostId(story.id, 0, this.pageSize).subscribe((res: any) => {
        if (res) {
          this.comments = res.reverse();
        }
      });
    }
  }

  addComment(data: any) {
    const param = {
      object_id: this.getStory()?.id,
      object_type: ENTITY_TYPE.story,
      parent_id: this.getStory()?.id,
      content: data.content,
      message_ranges: data.messageRanges,
      medias: data.medias,
      preview_data: data.preview_data
    };
    this.commentsService.postComment(param).subscribe(res => {
      if (res) {
        this.comments.push(res);
        this.totalCommentsAndReplies++;
        this.playPauseMedia(this.configVideo.isPlay);
      }
    });
  }
  // --------end handle comment------------

  // --------end handle media------------
  handleVideoStories() {
    if (this.getStory()?.story_media.video_url && !this.durationOfVideo) {
      this.configVideo.isAutoPlay = true;
      this.getVideoDuration();
      setTimeout(() => {
        if (this.durationOfVideo) {
          this.configVideo.isMuted = false;
          this.clearAnimate();
          this.runProgressBar();
        }
      }, 500);
    }
  }

  getVideoDuration() {
    const video = document.createElement('video');
    video.src = this.validUrl(this.getStory()?.story_media.video_url || '', FILE_TYPE_URL.streamVideo);
    video.addEventListener('loadedmetadata', () => {
      this.durationOfVideo = video.duration;
    });
  }

  playPauseMedia(isAnimating: boolean) {
    if (this.isAnimating === isAnimating) return;
    this.isAnimating = isAnimating;
    if (this.isAnimating) {
      this.runProgressBar();
    }
    if (this.getStory()?.story_media.video_url) {
      if (this.videoPlayer && this.videoPlayer.nativeElement && !this.isAnimating) {
        this.videoPlayer.nativeElement.pause();
      } else {
        this.videoPlayer.nativeElement.play();
      }
    }
  }

  checkLandscapeImg(item: Story) {
    const type = item.story_type;
    const storyMedia = item.story_media;
    return new Promise(resolve => {
      if (type === MEDIA_TYPE.image && storyMedia && storyMedia.image_url) {
        const img = new Image();
        img.src = this.validUrl(storyMedia.image_url, FILE_TYPE_URL.thumbnail);
        img.onload = () => resolve(img.width > img.height);
      } else if (type === MEDIA_TYPE.video && storyMedia && storyMedia.video_url !== null) {
        const video = document.createElement('video');
        video.src = this.validUrl(storyMedia.video_url, FILE_TYPE_URL.streamVideo);
        video.onloadeddata = () => resolve(video.videoWidth > video.videoHeight);
      } else {
        resolve(false);
      }
    });
  }

  async setLayoutForImage() {
    const data = this.getStory();
    if (!data) return;
    await this.checkLandscapeImg(data).then((res: any) => {
      this.isLandscapeImg = res;
    });
  }

  validUrl(urlString: string, style: string) {
    this.urlStory = urlString;
    const fileExtension = style === this.fileType.streamVideo ? FILE_EXTENSION.video : FILE_EXTENSION.image;
    return checkUrl(urlString, style) + fileExtension;
  }
  // --------end handle media------------

  showHideOverlayIcons(flag: boolean) {
    if (!flag) {
      this.timeOutData = setTimeout(() => {
        this.overlayIconVisible = flag;
      }, 500);
    } else {
      clearTimeout(this.timeOutData);
      this.overlayIconVisible = flag;
    }
    if (this.configVideo.isPlay) {
      this.playPauseMedia(!flag);
    }
  }

  goBack() {
    if (this.isDetailStory) {
      this.router.navigateByUrl('/');
      return;
    }
    this.listener();
    this.visibleChange.emit(false);
    if (this.isViewArchive) {
      this.loadMoreStoriesArchived.emit({ deletedIds: this.deletedIds });
    }
    document.querySelectorAll('body')[0].style.overflowY = 'auto';
  }

  getReportTarget(): string {
    return this.translateService.getTranslation('COMMON.STORY').toLowerCase();
  }

  handleCloseModal(event: any) {
    this.isShowReport = event;
    if (this.configVideo.isPlay) {
      this.playPauseMedia(!this.overlayVisible);
    }
  }

  handlePostReport(event: any) {
    let param = {
      object_id: this.getStory()?.id,
      object_type: OBJECT_TYPE.story,
      category_type: event.category_type,
      report_reason: event.report_reason,
      object_parent_id: this.allStories[this.transferStory].user_object.id,
      object_parent_type: 'USER',
      key_search: this.allStories[this.transferStory].user_object?.username,
      owner_id: this.allStories[this.transferStory].user_object?.id,
      city_code: this.allStories[this.transferStory].user_object?.city_code
    };

    this.reportService.createReportPost(param).subscribe(res => {
      if (res.success) {
        this.isShowReport = false;
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.success,
          this.translateService.getTranslation('STORY.REPORT_STORY_SUCCESSFULLY')
        );
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          this.translateService.getTranslation('STORY.REPORT_STORY_FAILED')
        );
      }
      this.isLoadingReport = false;
    });
  }

  executedEmojiChanged(type: string) {
    if (type !== 'VIEW') {
      const animate = Math.floor(Math.random() * Object.keys(this.randomAnimateType).length);
      this.getSelectedEmoji(type);
      const newIcon = {
        id: this.idCounter++,
        animate: animate,
        emojiIcon: this.selectedEmoji.icon || this.emojiList[0].icon
      };
      this.overlayIconVisible = false;
      this.animateIcons.push(newIcon);
      setTimeout(
        () => {
          this.animateIcons = this.animateIcons.filter(icon => icon.id !== newIcon.id);
        },
        animate === this.randomAnimateType.left || animate === this.randomAnimateType.right ? 1500 : 1000
      );
      if (this.configVideo.isPlay) {
        this.playPauseMedia(true);
      }
    }
    const myReactionsStorage = localStorage.getItem('myReactions');
    if (myReactionsStorage && type) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      this.overlayVisible = false;
      const param = {
        entity_id: this.getStory()?.id,
        entity_type: ENTITY_TYPE.story,
        reaction_type: type
      };
      this.reactionsService.postReactions(param).subscribe((res: ReactionInfo) => {
        if (res) {
          const reactStorage = myReactionsInfo.reactionList.find(
            (el: MyReaction) => el.entity_id === this.getStory()?.id
          );
          if (!reactStorage) {
            // Add new reaction
            myReactionsInfo.reactionList.push({
              id: res.id,
              entity_id: this.getStory()?.id,
              reaction_type: type,
              updated_at: res.updated_at
            });
            if (type !== 'VIEW') {
              this.totalReactions += 1;
            }
            this.getSelectedEmoji(res.reaction_type);
          } else {
            // Update reaction
            myReactionsInfo.reactionList.map((el: MyReaction) => {
              if (el.id === res.id) {
                el.reaction_type = type;
              }
            });
            this.getSelectedEmoji(res.reaction_type);
          }
          localStorage.removeItem('myReactions');
          localStorage.setItem('myReactions', JSON.stringify(myReactionsInfo));
        }
      });
    }
  }

  getSelectedEmoji(type: string) {
    this.selectedEmoji = type !== 'VIEW' ? this.emojiList.find(el => el.type === type) : null;
  }

  getCurrentReaction() {
    const myReactionsStorage = localStorage.getItem('myReactions');
    if (myReactionsStorage) {
      const myReactionsInfo = JSON.parse(myReactionsStorage);
      const oldestTime = new Date(myReactionsInfo.oldestTime);
      const createdDate = new Date(this.getStory()?.created_at || '');
      // Check if postedDate < oldestTime and if localStorage contain all reactions
      if (createdDate < oldestTime && myReactionsInfo.reactionList.length >= MAX_QTY_REACTIONS_STORAGE) {
        this.reactionsService.getMyReactionsByEntityId(this.getStory()?.id || '').subscribe(res => {
          if (res) {
            this.getSelectedEmoji(res.reaction_type);
          } else if (this.getStory()?.user_id !== this.userInfo.id) {
            this.executedEmojiChanged('VIEW');
          }
        });
      } else {
        const reactData = myReactionsInfo.reactionList.find((el: MyReaction) => el.entity_id === this.getStory()?.id);
        if (reactData) {
          this.getSelectedEmoji(reactData.reaction_type);
        } else if (this.getStory()?.user_id !== this.userInfo.id) {
          this.executedEmojiChanged('VIEW');
        }
      }
    }
  }

  navigateToProfile(user: any) {
    if (!user) return;
    return ['/personal-profile', user.username || user.id];
  }

  handleCommentForm(isFocus: boolean) {
    if (this.configVideo.isPlay) {
      this.playPauseMedia(!isFocus);
    }
  }
}
