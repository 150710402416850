<div class="flex flex-col gap-4 text-palette-gray-900">
  <div>
    <span class="text-xl font-semibold">{{ 'STATISTIC.OVERVIEW' | translate }}</span>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <ng-container *ngFor="let item of totalsOverviewData">
      <ng-container
        [ngTemplateOutlet]="totalStatistic"
        [ngTemplateOutletContext]="{
          cardItem: item
        }"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #totalStatistic let-cardItem="cardItem" let-totalValue="totalValue">
  <div
    class="h-[94px] w-full rounded-md border border-palette-gray-200 p-4 gap-1 flex justify-between font-semibold text-palette-gray-900 bg-gradient-to-r {{
      cardItem.backgroundClass
    }}">
    <div class="flex flex-col gap-1 grow truncate">
      <span class="text-sm text-palette-gray-700 truncate" title="{{ cardItem.title | translate }}">{{
        cardItem.title | translate
      }}</span>
      <span
        *ngIf="isNumber(cardItem.totalValue); else loadingTemplate"
        class="text-3xl"
        title="{{ cardItem.totalValue }}">
        {{ cardItem.totalValue | shortNumber }}
      </span>
      <ng-template #loadingTemplate>
        <div class="h-9 flex items-center">
          <p-skeleton width="5rem" height="30px" />
        </div>
      </ng-template>
    </div>
    <img defaultImage [src]="cardItem.iconSrc" alt="Total icon" />
  </div>
</ng-template>
