import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { environment } from '@env/environment';

interface manageUserCard {
  hideFriend?: boolean;
  style: {
    roundedFull?: boolean;
    height: string;
    width: string;
    lineClamp?: string;
  };
}

@Component({
  selector: 'manage-user-card',
  templateUrl: './manage-user-card.component.html',
  styleUrls: ['./manage-user-card.component.scss']
})
export class ManageUserCardComponent implements OnInit {
  @Input() userData: any;
  @Input() cardConfig: manageUserCard = {
    hideFriend: false,
    style: {
      roundedFull: false,
      height: 'h-[80px] ',
      width: ' w-[80px]',
      lineClamp: 'line-clamp-3'
    }
  };
  baseUrl = environment.baseURL;
  avatarUrl: string;
  isModerate = false;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.avatarUrl = this.commonService.getImageUrl(this.userData && this.userData.avatar_thumbnail_url);
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
  }

  navigateToProfile() {
    if (this.isModerate) return;
    const userId = this.userData.id || '';
    if (!userId) return;
    return `/personal-profile/${userId}`;
  }

  getTextClass(name: string) {
    const firstWord = name.split(' ')[0];
    return firstWord.length > 10 ? 'line-clamp-1' : this.cardConfig.style.lineClamp;
  }
}
