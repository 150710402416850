import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { ADS_ACTION } from '@app/lib/api/client/api.client.constant';
import {
  PinPostResponse,
  PostArrayResponse,
  PostListPaging,
  PostRequest,
  PostResponse
} from '@app/lib/api/post/api.post.model';
import { CreatePostApiActions } from '@app/modules/main/states/create-post/create-post.actions';
import { POST_OPTIONS } from '@app/shared/constant';
import { DROPDOWN_POST, Post, PostAction, QuickReportModel, QuickReportRequest } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, map, retry, throwError } from 'rxjs';
import { AnalyticsService } from './analytics.service';

const POSTS_PATH = '/posts/posts';
@Injectable({
  providedIn: 'root'
})
export class PostsService {
  apiUrl = environment.baseURL;
  followDataId = {
    user_id: {
      follow: [],
      unfollow: []
    },
    page_id: {
      follow: [],
      unfollow: []
    }
  };
  reloadPosts = new BehaviorSubject(false);
  private api: ApiClient;
  followSubject = new BehaviorSubject<any>(this.followDataId);

  constructor(
    private http: HttpClient,
    private analyticsService: AnalyticsService,
    private store: Store
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getPostsCurrentUser(pageNum: number, pageSize: number, sortByDate: string): Observable<Post[]> {
    return this.api.post.getPostsCurrentUser(pageNum, pageSize, sortByDate);
  }

  getTheme(): Observable<any> {
    return this.api.theme.getThemes();
  }

  getPostsByUser(
    userId: string,
    pageNum: number,
    pageSize: number,
    isFriend: boolean,
    sortByDate: string
  ): Observable<Post[]> {
    return this.api.post.getPostsByUser(userId, pageNum, pageSize, isFriend, sortByDate);
  }

  getPostById(id: string): Observable<PostResponse> {
    return this.api.post.getPostById(id);
  }

  getPreviewLink(url: string): Observable<any> {
    return this.api.post.getLinkPreview(url);
  }

  createPost(post: PostRequest): Observable<PostResponse> {
    return this.api.post.createPost(post).pipe(
      map((res: PostResponse) => {
        //Hide process bar when created post success
        this.store.dispatch(CreatePostApiActions.onSetLoadingCreatePost({ isLoadingCreatePost: true }));
        return res;
      })
    );
  }

  createPostNewAlbum(post: PostRequest): Observable<Post> {
    return this.api.post.createPostNewAlbum(post);
  }

  sharePost(post: PostRequest, sharingPost?: Post): Observable<PostResponse> {
    return this.api.post.sharePost(post).pipe(
      map((res: any) => {
        sharingPost && this.analyticsService.pushPostAction(sharingPost, ADS_ACTION.share);
        return res;
      })
    );
  }

  updatePost(post: PostRequest, id: string): Observable<Post> {
    return this.api.post.updatePost(post, id);
  }

  updateSharedPost(post: PostRequest, id: string): Observable<Post> {
    return this.api.post.updateSharedPost(post, id);
  }

  getPosts(pageNum: number, pageSize: number): Observable<Post[]> {
    return this.api.post.getPosts(pageNum, pageSize);
  }

  getFriendTags(): Observable<any[]> {
    return this.http.get<Post[]>(this.apiUrl + '/friend/friends').pipe(
      retry(1),
      map((res: any) => {
        return res.data.content;
      }),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  deletePost(id: string): Observable<boolean> {
    return this.api.post.deletePost(id);
  }

  getQuickReport(): Observable<QuickReportModel> {
    return this.api.post.getQuickReport();
  }

  putQuickReport(data: QuickReportRequest): Observable<QuickReportModel> {
    return this.api.post.putQuickReport(data);
  }

  reviewPendingPosts(objectID: string, postIDs: string[], isApproved: boolean): Observable<any> {
    return this.api.post.reviewPendingPosts(objectID, postIDs, isApproved).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }

  updateAdsPost(adsId: string, id: string): Observable<Post> {
    return this.api.post.updateAdsPost(adsId, id);
  }

  getPinnedOwnerPost() {
    return this.api.post.getPinnedOwnerPost().pipe(
      map((res: PostArrayResponse) => {
        return res;
      })
    );
  }

  getPinedPostUser(userId: string, isFriend: boolean) {
    return this.api.post
      .getPinedPostUser({
        userId,
        isFriend
      })
      .pipe(
        map((res: PostArrayResponse) => {
          return res;
        })
      );
  }

  editPostPin(postId: string, isPinned: boolean) {
    return this.api.post.editPostPin({ postId, isPinned }).pipe(
      map((res: PinPostResponse) => {
        return res.success;
      })
    );
  }

  createArchivePost(postId: string[], isArchive: boolean) {
    return this.api.post.createArchivePost(postId, isArchive).pipe(
      map((res: PinPostResponse) => {
        return res.success;
      })
    );
  }

  getArchivedPosts(pageNum: number, pageSize: number, sortByDate?: string): Observable<Post[]> {
    return this.api.post.getArchivedPosts(pageNum, pageSize, sortByDate).pipe(
      map((res: PostArrayResponse) => {
        return res.data;
      })
    );
  }

  getPendingTaggedPosts(pageNum: number, pageSize: number, sortByDate?: string): Observable<PostListPaging> {
    return this.api.post.getPendingTaggedPosts(pageNum, pageSize, sortByDate).pipe(
      map((res: PostListPaging) => {
        return res;
      })
    );
  }

  removeTaggedPosts(postIDs: string[], isApproved: boolean): Observable<Post[]> {
    return this.api.post.removeTaggedPosts(postIDs, isApproved).pipe(
      map((res: any) => {
        return res.success;
      })
    );
  }

  handleCheckTypeActionPost: (
    typeCheck: number,
    userPost: string,
    userLogin: string,
    isBookmark: boolean,
    isAds: boolean,
    isPinned: boolean,
    isFollow: boolean,
    isArchive: boolean,
    isPostView: boolean,
    isFriendTagged: boolean,
    postType: string
  ) => PostAction[] | undefined = (
    typeCheck,
    userPost,
    userLogin,
    isBookmark,
    isAds,
    isPinned,
    isFollow,
    isArchive,
    isPostView,
    isFriendTagged,
    postType
  ) => {
    const bookmarkOption = isBookmark ? POST_OPTIONS.unSave : POST_OPTIONS.save;
    const actionFollow = isFollow ? POST_OPTIONS.unfollow : POST_OPTIONS.follow;
    const newFeedFollowOption = userPost !== userLogin ? [actionFollow] : [];
    const hideOption = isAds ? POST_OPTIONS.hideAd : POST_OPTIONS.hide;
    const hideArchive = !isArchive ? POST_OPTIONS.archive : undefined;
    switch (typeCheck) {
      case DROPDOWN_POST.default:
        const actions: PostAction[] = [
          bookmarkOption,
          POST_OPTIONS.hide,
          ...(isPostView || userPost !== userLogin ? [] : [POST_OPTIONS.edit]),
          POST_OPTIONS.report,
          ...newFeedFollowOption,
          ...(isFriendTagged ? [POST_OPTIONS.removeTag] : [])
        ];
        return actions;
      case DROPDOWN_POST.timeline:
        return [
          ...(isPostView ? [] : [POST_OPTIONS.pin]),
          bookmarkOption,
          ...(isPostView || ['COVER', 'AVATAR'].includes(postType) ? [] : [POST_OPTIONS.edit]),
          ...(isPostView ? [] : [POST_OPTIONS.editPrivacy]),
          ...(hideArchive ? [hideArchive] : []),
          ...(['COVER', 'AVATAR'].includes(postType) ? [] : [POST_OPTIONS.delete])
        ];
      case DROPDOWN_POST.groupAdmin:
        return [bookmarkOption, hideOption, POST_OPTIONS.report, POST_OPTIONS.delete];
      case DROPDOWN_POST.groupAdminPostOwner:
        return [bookmarkOption, hideOption, POST_OPTIONS.edit, POST_OPTIONS.delete];
      case DROPDOWN_POST.groupMember:
        return [bookmarkOption, POST_OPTIONS.report];
      case DROPDOWN_POST.groupMemberPostOwner:
        return [bookmarkOption, POST_OPTIONS.edit, POST_OPTIONS.delete];
      case DROPDOWN_POST.pinedPost:
        if (isFriendTagged)
          return [
            POST_OPTIONS.unpin,
            bookmarkOption,
            hideOption,
            POST_OPTIONS.report,
            ...newFeedFollowOption,
            POST_OPTIONS.removeTag
          ];

        return [
          POST_OPTIONS.unpin,
          bookmarkOption,
          POST_OPTIONS.edit,
          POST_OPTIONS.editPrivacy,
          POST_OPTIONS.archive,
          POST_OPTIONS.delete
        ];
      case DROPDOWN_POST.post_owner:
        return [
          bookmarkOption,
          ...(isPostView || ['COVER', 'AVATAR'].includes(postType) ? [] : [POST_OPTIONS.edit]),
          POST_OPTIONS.editPrivacy,
          ...(hideArchive ? [hideArchive] : []),
          ...(['COVER', 'AVATAR'].includes(postType) ? [] : [POST_OPTIONS.delete])
        ];
      case DROPDOWN_POST.fanpageAdminOwner:
        return [bookmarkOption, POST_OPTIONS.edit, POST_OPTIONS.delete];
      case DROPDOWN_POST.fanpageEditorAndPostAuthor:
        return [bookmarkOption, POST_OPTIONS.edit];
      case DROPDOWN_POST.fanpageEditorNotPostAuthor:
        return [bookmarkOption, POST_OPTIONS.report];
      case DROPDOWN_POST.fanpageBasicOptions:
        return [bookmarkOption, POST_OPTIONS.report];
      case DROPDOWN_POST.download:
        return [POST_OPTIONS.download];
      case DROPDOWN_POST.fanpagePostInSearch:
        return [bookmarkOption, POST_OPTIONS.edit, POST_OPTIONS.delete];
      case DROPDOWN_POST.postTagged:
        return [
          ...(isPostView ? [] : [POST_OPTIONS.pin]),
          bookmarkOption,
          hideOption,
          POST_OPTIONS.report,
          ...newFeedFollowOption,
          ...(isFriendTagged ? [POST_OPTIONS.removeTag] : [])
        ];
      default:
        break;
    }
    return;
  };
}
