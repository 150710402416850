import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  CategoryModel,
  CategoryResponse,
  PageModel,
  PageOwnerRes,
  VerificationApiModels,
  VerificationRequestBusinessInfo,
  VerificationRequestBusinessModel,
  VerificationRequestBusinessResponse,
  VerificationRequestProfileInfo,
  VerificationRequestProfileInfoResponse,
  VerificationRequestProfileModel,
  VerificationRequestProfileResponse
} from './api.verification-request.models';
import { VERIFICATION_TYPE } from '@app/shared/constant';

export class VerificationRequestApi {
  private apiUrl = environment.baseURL;
  VERIFICATION_REQUEST = '/verification/verification';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getAllCategory(type: string): Observable<CategoryModel[]> {
    const url = `${this.apiUrl}/verification/category/get-all?type=${type}`;
    return this.http.get<CategoryResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postVerificationRequestProfile(body: any): Observable<VerificationRequestProfileModel> {
    return this.http
      .post<VerificationRequestProfileResponse>(`${this.apiUrl}${this.VERIFICATION_REQUEST}/profile`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: VerificationRequestProfileResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getVerificationRequestProfile(userId: string): Observable<VerificationRequestProfileInfo[]> {
    const url = `${this.apiUrl}${this.VERIFICATION_REQUEST}/profile/current/${userId}`;
    return this.http.get<VerificationRequestProfileInfoResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postVerificationRequestBusiness(body: any): Observable<VerificationRequestBusinessModel> {
    return this.http
      .post<VerificationRequestBusinessResponse>(`${this.apiUrl}${this.VERIFICATION_REQUEST}/business`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: VerificationRequestBusinessResponse) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getVerificationRequestBusiness(userId: string): Observable<VerificationRequestBusinessInfo[]> {
    return this.http
      .get<VerificationRequestBusinessInfo>(
        `${this.apiUrl}${this.VERIFICATION_REQUEST}/business/current/${userId}?object_type=BUSINESS_PAGE`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: VerificationRequestBusinessInfo) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getPageOwner(): Observable<PageModel[]> {
    return this.http.get<PageOwnerRes>(`${this.apiUrl}/page/pages/owned`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getBusinessAccountVerificationRequest(
    accountId: string,
    objectType = VERIFICATION_TYPE.businessAccount
  ): Observable<VerificationApiModels.CurrentBusinessVerificationRes> {
    return this.http
      .get<VerificationApiModels.CurrentBusinessVerificationRes>(
        `${this.apiUrl}${this.VERIFICATION_REQUEST}/business/current/account/${accountId}?object_type=${objectType}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getBusinessVerificationDetails(id: string): Observable<VerificationApiModels.BusinessVerificationDataRes> {
    return this.http
      .get<VerificationApiModels.BusinessVerificationDataRes>(
        `${this.apiUrl}${this.VERIFICATION_REQUEST}/business/${id}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
