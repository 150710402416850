import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, catchError, map, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  apiUrl = environment.baseURL;
  currentMessage = new BehaviorSubject<any>(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient
  ) {
    this.angularFireMessaging.messages.subscribe(_messaging => {});
  }
  registerToken(token: any) {
    const url = this.apiUrl + '/notification/notifications/tokens/register';
    return this.http
      .post<any>(url, {
        token
      })
      .pipe(
        retry(1),
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe({
      next: token => {
        token && this.registerToken(token).subscribe();
      },
      error: error => {
        console.log('There was an error in retrieving token from server: ' + error);
      }
    });
  }

  receiveMessage(): Observable<any> {
    return this.currentMessage.asObservable();
  }

  startListeningForMessages() {
    this.angularFireMessaging.messages.subscribe(payload => {
      this.currentMessage.next(payload);
    });
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
