import { Component, OnInit } from '@angular/core';
import { selectInfoDetailGroup } from '@app/modules/main/group/store/selectors';
import { StatisticTotalCardUI, StatisticTotalOverview } from '@app/shared/components/statistics/statistics.models';
import { STATISTIC_TOTAL_CARD_ITEMS } from '@app/shared/constant';
import { Store, select } from '@ngrx/store';

@Component({
  selector: 'group-statistics',
  templateUrl: './group-statistics.component.html',
  styleUrls: ['./group-statistics.component.scss']
})
export class GroupStatisticsComponent implements OnInit {
  totalsOverviewData: StatisticTotalOverview[] = [];
  membersCardItem: StatisticTotalCardUI = STATISTIC_TOTAL_CARD_ITEMS.members;
  postsCardItem: StatisticTotalCardUI = STATISTIC_TOTAL_CARD_ITEMS.posts;
  interactionsCardItem: StatisticTotalCardUI = STATISTIC_TOTAL_CARD_ITEMS.interactions;
  totalMembers = NaN;
  totalPosts = NaN;
  totalInteractions = NaN;
  groupId: string | null;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.pipe(select(selectInfoDetailGroup)).subscribe(res => {
      if (res) {
        this.totalMembers = res.data.number_of_members;
        this.totalPosts = res.data.number_of_posts;
        this.totalInteractions = res.data.number_of_interactions;
        this.groupId = res.data.group_id;
      }
      this.loadOverviewData();
    });
  }

  loadOverviewData(): void {
    const membersCard = Object.assign(this.membersCardItem, { totalValue: this.totalMembers });
    const postsCard = Object.assign(this.postsCardItem, { totalValue: this.totalPosts });
    const interactionsCard = Object.assign(this.interactionsCardItem, { totalValue: this.totalInteractions });
    const totalsOverviewData = [];
    totalsOverviewData.push(membersCard, postsCard, interactionsCard);
    this.totalsOverviewData = totalsOverviewData;
  }
}
