import { actionsPost } from '@shared/models/post';
import { DotType, DrawType, ErrorCorrectionLevel, Mode } from 'qr-code-styling';

export const AUTH_METHOD = {
  phone: 'phone',
  email: 'email'
};

export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  PENDING_OWNER: 'PENDING_OWNER',
  MOD: 'MOD',
  USER: 'USER',
  EDITOR: 'EDITOR',
  ADVERTISER: 'ADVERTISER',
  ANALYST: 'ANALYST'
};

export const MEMBER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  BLOCK: 'BLOCK'
};

export const USER_STATUS = {
  SCHEDULED_DELETED: 'SCHEDULED_DELETED',
  DELETED: 'DELETED',
  INACTIVE: 'INACTIVE',
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE'
};

export const PAGE_STATUS = {
  NEW: 'NEW',
  EDITED: 'EDITED',
  INACTIVE: 'INACTIVE',
  WARNING: 'WARNING',
  BLOCKED: 'BLOCKED',
  DELETED: 'DELETED',
  HIDE: 'HIDE',
  PENDING_REVIEW: 'PENDING_REVIEW'
};

export const GROUP_OF_FANPAGE_STATUSES = {
  active: [PAGE_STATUS.NEW, PAGE_STATUS.EDITED, PAGE_STATUS.WARNING],
  inactive: [PAGE_STATUS.INACTIVE],
  blocked: [PAGE_STATUS.BLOCKED],
  deleted: [PAGE_STATUS.DELETED],
  pending: [PAGE_STATUS.PENDING_REVIEW]
};

export const GROUP_STATUSES = {
  active: [PAGE_STATUS.NEW, PAGE_STATUS.EDITED, PAGE_STATUS.WARNING],
  blocked: [PAGE_STATUS.BLOCKED],
  deleted: [PAGE_STATUS.DELETED],
  pending: [PAGE_STATUS.PENDING_REVIEW]
};

export const TAGS = {
  ALL: 'All',
  PEOPLE: 'People',
  GROUP: 'Group',
  FANPAGE: 'Fanpage',
  POST: 'Post',
  PLACE: 'Place',
  TOUR: 'Tour'
};

export const CHECK_IN = {
  LOCATION: 'LOCATION',
  FANPAGE: 'FANPAGE'
};
export const BG_THEME = {
  BIGBEN: 'bg-big-ben',
  PARIS: 'bg-paris',
  NEWYORK: 'bg-new-york',
  PIZZA: 'bg-pizza',
  VIETNAM: 'bg-vietnam'
};

export const SEARCH_TAGS = [
  { id: 0, name: TAGS.ALL },
  { id: 1, name: TAGS.PEOPLE },
  { id: 2, name: TAGS.GROUP },
  { id: 3, name: TAGS.FANPAGE },
  { id: 4, name: TAGS.POST },
  { id: 5, name: TAGS.PLACE },
  { id: 6, name: TAGS.TOUR }
];
export interface TemplatePost {
  id: string;
  name: string;
}

export const TYPE_ITEM_SEARCH = {
  GROUP: 'GROUP',
  USER: 'USER',
  PAGE: 'PAGE',
  SEARCH_HINT: 'SEARCH_HINT',
  HASH_TAG: 'HASH_TAG',
  PLACES: 'PLACES',
  TOURS: 'TOURS'
};

export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];
export const USER_ROLE = 'USER';

export const MAX_POST_LENGTH = 260;
export const MAX_CAPTION_LENGTH = 80;
export const MAX_TRUNCATED_TEXT_HEIGHT = 120;
export const MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = 40;
export const MAX_LINES = 5;

export const MAX_RESOLUTION_OF_POST = 703;
export const MAX_SCREEN_CAROUSEL = 768;

export const TOAST_ERROR_MESSAGE_LIFETIME = 5000;

export const DISCOVER_VISIBLE_NORMAL = 5.3;
export const STORY_VISIBLE_NORMAL = 5.3;
export const VISIBLE_SMALL_SCREEN = 3.3;
export const PADDING_SCREEN_CAROUSEL = 24;
export const CAROUSEL_ITEM_WIDTH = 108;

export const PADDING_SCREEN_CAROUSEL_PEOPLE_CARD = 12;
export const CAROUSEL_ITEM_WIDTH_PEOPLE_CARD = 166;

export const VIDEO_WIDTH_TO_SHOW_CONTROLLER = 792;
export const VIDEO_WIDTH_TO_SHOW_PROGRESS = 300;

export const ACTIONS = {
  declineAll: 'declineAll',
  approveAll: 'approveAll',
  approve: 'approve',
  accept: 'accept',
  decline: 'decline',
  approval: 'approval',
  Decline: 'Decline',
  Approve: 'Approve'
};

export const TYPE_TAB_VIEW_ITEMS = {
  TIMELINE: {
    TITLE: 'Timeline',
    INDEX: 0
  },
  FRIENDS: {
    TITLE: 'Friends',
    INDEX: 1
  },
  GROUPS: {
    TITLE: 'Groups',
    INDEX: 2
  },
  FANPAGES: {
    TITLE: 'Fanpages',
    INDEX: 3
  },
  LIBRARY: {
    TITLE: 'Library',
    INDEX: 4
  },
  // JOURNEY : {
  //   TITLE: 'Journey',
  //   INDEX: 5
  // },
  XU: {
    TITLE: 'XU',
    INDEX: 5
  },
  ABOUT: {
    TITLE: 'About',
    INDEX: 6 // Increase "ABOUT" index when tab "JOURNEY" or "COIN" is active
  }
};

export const FRIEND_ACTIONS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
  CANCEL: 'cancel'
};

export const TAB_VIEW_ITEMS_LOGGED_USER = [
  { label: TYPE_TAB_VIEW_ITEMS.TIMELINE.TITLE, index: TYPE_TAB_VIEW_ITEMS.TIMELINE.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.FRIENDS.TITLE, index: TYPE_TAB_VIEW_ITEMS.FRIENDS.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.GROUPS.TITLE, index: TYPE_TAB_VIEW_ITEMS.GROUPS.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.FANPAGES.TITLE, index: TYPE_TAB_VIEW_ITEMS.FANPAGES.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE, index: TYPE_TAB_VIEW_ITEMS.LIBRARY.INDEX },
  // { label: TYPE_TAB_VIEW_ITEMS.JOURNEY.TITLE , index: TYPE_TAB_VIEW_ITEMS.JOURNEY.INDEX},
  // TODO reopen when integrate api done
  { label: TYPE_TAB_VIEW_ITEMS.XU.TITLE, index: TYPE_TAB_VIEW_ITEMS.XU.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.ABOUT.TITLE, index: TYPE_TAB_VIEW_ITEMS.ABOUT.INDEX }
];

export const TAB_VIEW_ITEMS = [
  { label: TYPE_TAB_VIEW_ITEMS.TIMELINE.TITLE, index: TYPE_TAB_VIEW_ITEMS.TIMELINE.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE, index: TYPE_TAB_VIEW_ITEMS.LIBRARY.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.ABOUT.TITLE, index: TYPE_TAB_VIEW_ITEMS.ABOUT.INDEX }
];

export const FRIENDS_TAB_VIEW_ITEMS = [
  { label: TYPE_TAB_VIEW_ITEMS.TIMELINE.TITLE, index: TYPE_TAB_VIEW_ITEMS.TIMELINE.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.FRIENDS.TITLE, index: TYPE_TAB_VIEW_ITEMS.FRIENDS.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE, index: TYPE_TAB_VIEW_ITEMS.LIBRARY.INDEX },
  { label: TYPE_TAB_VIEW_ITEMS.ABOUT.TITLE, index: TYPE_TAB_VIEW_ITEMS.ABOUT.INDEX }
];

export const TYPE_ADDRESS = {
  LOCATION: 'location',
  HOMETOWN: 'hometown'
};

export const CURRENT_PAGE = {
  HOME: 'HOME',
  TIMELINE: 'TIMELINE',
  FANPAGE: 'FANPAGE',
  GROUP: 'GROUP'
};

export const PROGRESS_UPDATE_INTERVAL = 500;

export const TIMEOUT_DELAY = 1000;

export const TYPES = {
  post: 'post',
  member: 'member',
  my_group: 'My group',
  about: 'about',
  contact: 'contact'
};

export const RULES = {
  rule_name: 'rule_name',
  rule_content: 'rule_content'
};

export const GROUP_PRIVACY_CONTEXTS = {
  public: { label: 'PUBLIC', value: 'Public', icon: 'sctr-icon-globe-01' },
  private: { label: 'PRIVATE', value: 'Private', icon: 'sctr-icon-solid-lock-02' }
};

export const PRIVACIES = {
  private: 'Private',
  public: 'Public',
  privacy: 'privacy',
  friends: 'Friends'
};

export const KEYBOARD_EVENT_CODES = {
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Escape: 'Escape',
  Space: 'Space',
  Enter: 'Enter'
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: ['image/avif', 'image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/bmp', 'image/webp', 'image/tiff'],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime'
  ],
  pdf: ['application/pdf']
};

export const MAXIMUM_UPLOAD_COUNT = 20;

export const DEFAULT_ALBUM_NAMES = {
  ImageAlbums: ['Your Photos', 'Profile Photos', 'Cover Photos', 'Images'],
  VideoAlbums: ['Videos']
};

export const FANPAGES = {
  turn_on_notification: 'Turn on notification',
  copy_link: 'Copy link',
  report: 'Report',
  block: 'Block',
  view: 'View',
  viewDiscoveryPage: 'ViewDiscoveryPage',
  switchIntoPageRole: 'switchIntoPageRole',
  hide: 'Hide',
  unfollow: 'Unfollow'
};

export const REPORT_CONTENT = {
  reported_hide: 'HIDE',
  reported_delete: 'DELETE',
  reported_warning: 'WARNING',
  reject: 'REJECT'
};

export const GROUP_PAGES = {
  turn_on_notification: 'Turn on notification',
  un_follow: 'Unfollow group',
  leave_group: 'Leave group',
  cancel_request: 'Cancel request',
  accept: 'Accept',
  decline: 'Decline',
  edit_role: 'Edit role',
  remove: 'Remove',
  edit: 'Edit',
  copy_link: 'Copy link',
  share_to_feed: 'Share to feed',
  share_to_group: 'Share to group',
  promote: 'Promote',
  invite_friends: 'Invite friends',
  report: 'Report',
  delete: 'Delete'
};

export const PRIVACY = {
  public: 'PUBLIC',
  private: 'PRIVATE',
  friends: 'FRIENDS',
  friendExcept: 'FRIEND_EXCEPT',
  groupMembers: 'GROUP_MEMBERS',
  dontAllow: 'NONE'
};

export const NotificationType = {
  RequestNewFriend: 'request_new_friend',
  TagYouInPost: 'tag_you_in_post',
  TagYouInComment: 'tag_you_in_comment',
  UploadEvent: 'upload_event',
  NewPost: 'new_post',
  ReactOnPost: 'react_on_post',
  Event: 'event',
  Livestream: 'livestream',
  Memory: 'memory',
  Gift: 'gift'
};
export const AllowedTypes = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'video/mp4',
  'video/webm',
  'video/mpeg'
];

export const ALLOWED_NON_VIDEO_TYPES = AllowedTypes.filter(type => !type.startsWith('video'));

export const PRIVACY_OPTIONS = [
  { privacy: PRIVACY.public, label: 'PRIVACY.PUBLIC', icon: 'sctr-icon-globe-04' },
  { privacy: PRIVACY.private, label: 'PRIVACY.PRIVATE', icon: 'sctr-icon-lock-03' },
  { privacy: PRIVACY.friends, label: 'PRIVACY.FRIENDS', icon: 'sctr-icon-users-01' }
];

export const GROUP_TYPE = {
  theCraftyClub: 'TheCraftyClub',
  fitnessFanatics: 'FitnessFanatics',
  travelAddictsUnited: 'TravelAddictsUnited'
};

export const PAGE_TYPE = {
  cookingConnoisseurs: 'CookingConnoisseurs'
};

export const WHO_CAN_COMMENT = {
  everyone: 'Everyone',
  friends: 'Friends',
  followers: 'Followers',
  groupMembers: 'GroupMembers'
};

export const POST_LIVE = {
  feed: 'USER',
  group: 'GROUP',
  page: 'FANPAGE'
};

export const MEDIA_TYPE = {
  image: 'IMAGE',
  video: 'VIDEO'
};

export const FILE_TYPES = {
  image: 'image',
  video: 'video',
  application: 'application'
};

export const LAYOUT_TYPE = {
  vertical: 'VERTICAL',
  horizontal: 'HORIZONTAL',
  sidelong: 'SIDELONG',
  central: 'CENTRAL'
};

export const POST_TYPE = {
  normal: 'NORMAL',
  share: 'SHARE',
  album: 'ALBUM',
  link: 'LINK',
  poll: 'POLL',
  user: 'USER',
  livestream: 'LIVESTREAM',
  suggestion: 'SUGGESTION',
  ads: 'ADS',
  avatar: 'AVATAR',
  cover: 'COVER'
};

export const MAX_QTY_REACTIONS_STORAGE = 1000;
export const PAGE_SIZE_DEFAULT = 10;
export const PAGE_NUM_DEFAULT = 0;

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const EVENTS = {
  upcoming: 'upcoming',
  current: 'current',
  past: 'past'
};

export const ASPECT_RATIO = {
  landscapeCover: 6 / 2,
  landscapeBannerGroup: 599 / 135,
  landscapeEvent: 584 / 205
};

export const APPROVER_TYPE = {
  superAdmin: 'SUPERADMIN',
  adminGroup: 'ADMINGROUP',
  adminPage: 'ADMINPAGE'
};

export const OBJECT_TYPE = {
  post: 'POST',
  user: 'USERPROFILE',
  group: 'GROUP',
  page: 'PAGE',
  tour: 'TOUR',
  rating: 'RATING',
  comment: 'COMMENT',
  food: 'FOOD',
  hotel: 'HOTEL',
  all: 'ALL',
  story: 'STORY',
  fanpage: 'FANPAGE',
  ads: 'ADS',
  livestream: 'LIVESTREAM'
};

export const MENU_ITEM_POST_HEADER = {
  savePost: 1,
  hidePost: 2,
  editPost: 3,
  report: 4,
  turnOnNotification: 5,
  unFollow: 6
};

export const HEADER_TOP_BAR_MENU = [
  { label: 'Watching', icon: 'sctr-icon-play-circle' },
  { label: 'Booking', icon: 'sctr-icon-calendar' },
  { label: 'Food', icon: 'assets/icons/food-topbar.svg' },
  { label: 'Shopping', icon: 'assets/icons/shopping-topbar.svg' }
];

export const TAB_EVENTS = {
  upcoming: 'Upcoming',
  current: 'Current',
  past: 'Past'
};

export const DISTANCE_FROM_BOTTOM_TO_LAZY_LOAD = 6000;

export const STORAGE_FILES = '/storage/files/';

export const STORAGE_TYPE = {
  thumbnail: 'thumbnail',
  streamVideo: 'stream-video'
};

export const FILE_EXTENSION = {
  image: '.webp',
  video: '.webm',
  mp4: '.mp4'
};

export const POST_REVIEW_ACTIONS = {
  approve: 'approve',
  decline: 'decline',
  approveAll: 'approveAll',
  declineAll: 'declineAll'
};

export const POST_REVIEW_CONTEXTS = {
  approve: {
    title: 'POST_REVIEW.CONFIRMATIONS.APPROVE_POST',
    message: 'POST_REVIEW.CONFIRMATIONS.APPROVE_SELECTED_POST',
    buttonTitle: 'POST_REVIEW.APPROVE',
    buttonClass: 'border-branding-primary-600 bg-branding-primary-600 text-white hover:bg-branding-primary-700'
  },
  decline: {
    title: 'POST_REVIEW.CONFIRMATIONS.DECLINE_POST',
    message: 'POST_REVIEW.CONFIRMATIONS.DECLINE_SELECTED_POST',
    buttonTitle: 'COMMON.BUTTON_ACTION.DECLINE',
    buttonClass: 'border-system-error-600 bg-system-error-600 text-white hover:bg-system-error-700'
  },
  approveAll: {
    title: 'POST_REVIEW.CONFIRMATIONS.APPROVE_SELECTED_POSTS',
    message: 'POST_REVIEW.CONFIRMATIONS.APPROVE_ALL_SELECTED_POSTS',
    buttonTitle: 'POST_REVIEW.APPROVE_ALL',
    buttonClass: 'border-branding-primary-600 bg-branding-primary-600 text-white hover:bg-branding-primary-700'
  },
  declineAll: {
    title: 'POST_REVIEW.CONFIRMATIONS.DECLINE_SELECTED_POSTS',
    message: 'POST_REVIEW.CONFIRMATIONS.DECLINE_ALL_SELECTED_POSTS',
    buttonTitle: 'POST_REVIEW.DECLINE_ALL',
    buttonClass: 'border-system-error-600 bg-system-error-600 text-white hover:bg-system-error-700'
  }
};

export const SEARCH_DEBOUNCE_TIME = 500;

export const TYPE_DEBOUNCE_TIME = 300;

export const STATISTIC_TOTAL_CARD_ITEMS = {
  members: {
    title: 'STATISTIC.TITLES.TOTAL_MEMBERS',
    iconSrc: './assets/icons/analysis/Analysis_Member.svg',
    backgroundClass: 'from-[#FCFEFF] to-[#ECF9FF]'
  },
  posts: {
    title: 'STATISTIC.TITLES.TOTAL_POSTS',
    iconSrc: './assets/icons/analysis/Analysis_Post.svg',
    backgroundClass: 'from-[#FFFFFC] to-[#FFFBEC]'
  },
  interactions: {
    title: 'STATISTIC.TITLES.TOTAL_INTERACTIONS',
    iconSrc: './assets/icons/analysis/Analysis_Heart.svg',
    backgroundClass: 'from-[#FFFDFC] to-[#FFF1EC]'
  },
  followers: {
    title: 'STATISTIC.TITLES.TOTAL_FOLLOWERS',
    iconSrc: './assets/icons/analysis/Analysis_Heart.svg',
    backgroundClass: 'from-[#FFFDFC] to-[#FFF1EC]'
  },
  coins: {
    title: 'STATISTIC.TITLES.TOTAL_COINS',
    iconSrc: './assets/icons/analysis/Analysis_Gift.svg',
    backgroundClass: 'from-[#F8FCFF] to-[#EAF6FF]'
  },
  fanBadges: {
    title: 'STATISTIC.TITLES.TOTAL_FAN_BADGES',
    iconSrc: './assets/icons/analysis/Analysis_Star.svg',
    backgroundClass: 'from-[#FFFCF8] to-[#FFF4E0]'
  }
};

export const INTERNET_SPEED_TIMEOUT = 10000;
export const MIN_INTERNET_SPEED_LEVEL = {
  Poor: 0.5,
  Fair: 1,
  Good: 2,
  Excellent: 4
};
export const INTERNET_SPEED_LEVEL = {
  Disconnect: 0,
  Poor: 1,
  Fair: 2,
  Good: 3,
  Excellent: 4
};
export const GENDER = {
  all: 'All',
  male: 'Male',
  Female: 'Female',
  other: 'Other'
};

export const LINE_CHART_BACKGROUND_COLOR_RGB = {
  blue: '0,112,245',
  orange: '255,156,102'
};

export const LINE_CHART_COLORS = {
  line: {
    blue: '#1570EF',
    orange: '#FF9C66',
    state: '#98A2B3'
  },
  gray500: '#667085'
};

export const DATASET_POSITION = {
  top: 'top',
  bottom: 'bottom'
};

export const STATISTIC_LINE_CHART_TITLES = {
  newPost: 'NEW_POST',
  interactions: 'INTERACTIONS',
  newMembers: 'NEW_MEMBERS',
  userLeaveGroup: 'USER_LEAVE_GROUP',
  postReach: 'POST_REACH',
  pageVisits: 'PAGE_VISITS',
  newFollows: 'NEW_FOLLOWERS',
  pageUnfollows: 'UNFOLLOWERS'
};

export const POST_ENGAGEMENT = {
  checkin: 'CHECK_IN',
  impressions: 'IMPRESSIONS',
  clicks: 'CLICKS',
  reactions: 'REACTIONS',
  likes: 'LIKES',
  comments: 'COMMENTS'
};

export const tabTypeChart = {
  reaches: 'REACHES',
  impressions: 'IMPRESSIONS'
};

export const STATISTIC_LINE_CHART_MIN_DAY_DATA_SCALE = 42;

export const SCHEDULE_DATE = {
  monday: 'MONDAY',
  tuesday: 'TUESDAY',
  wednesday: 'WEDNESDAY',
  thursday: 'THURSDAY',
  friday: 'FRIDAY',
  saturday: 'SATURDAY',
  sunday: 'SUNDAY'
};

export const ADS_CAMPAIGN = {
  search: 'Reach',
  traffic: 'Traffic',
  video_views: 'Video views',
  community_interaction: 'Community Interaction'
};

export const DAY_TIME_UNITS = {
  milisecond: 'milisecond',
  second: 'second',
  minute: 'minute',
  hour: 'hour',
  day: 'day'
};

export const TIME_UNITS = {
  ...DAY_TIME_UNITS,
  week: 'week',
  month: 'month',
  year: 'year',
  decade: 'decade',
  century: 'century'
};

export const SORT = {
  asc: 'ASCENDING',
  desc: 'DESCENDING'
};

export const POST_TABLE_STYLE = {
  group: 'group',
  fanpage: 'fanpage'
};

export const CAMPAIGN_STATUS = {
  draft: 'DRAFT',
  all: 'ALL',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  upcoming: 'UPCOMING',
  inreview: 'INREVIEW',
  rejected: 'REJECTED',
  completed: 'COMPLETED',
  suspended: 'SUSPENDED'
};

export const CAMPAIGN_LIST_TYPE = {
  campaigns: 'CAMPAIGNS',
  adGroups: 'AD_GROUPS',
  ads: 'ADS'
};

export const CONTENT_REQUEST_VERIFICATION = {
  authenticity: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.AUTHENTICITY',
  uniqueness: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.UNIQUENESS',
  notability: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.NOTABILITY',
  completeness: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.COMPLETENESS',
  compliance: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.COMPLIANCE'
};

export const VERIFICATION_TYPE = {
  profile: 'PROFILE',
  business: 'BUSINESS',
  businessAccount: 'BUSINESS_ACCOUNT'
};

export const CURRENT_SCREEN = {
  NEWS_FEED: 'NEWS_FEED',
  PROFILE_TIMELINE: 'PROFILE_TIMELINE'
};

export const VERIFICATION_STATUS = {
  approved: 'APPROVED',
  pending: 'PENDING',
  rejected: 'REJECTED'
};

export const VERIFICATION_TYPE_INFO = [
  { type: VERIFICATION_TYPE.profile, label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.PROFILE' },
  { type: VERIFICATION_TYPE.business, label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.BUSINESS' }
];

export const VERIFICATION_STATUS_INFO = [
  { status: VERIFICATION_STATUS.approved, label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.APPROVED' },
  { status: VERIFICATION_STATUS.pending, label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.PENDING' },
  { status: VERIFICATION_STATUS.rejected, label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.REJECTED' }
];

export const MIN_PROFILE_ATTACHMENTS = 3;
export const MIN_BUSINESS_ATTACHMENTS = 2;
export const ADS_NAME_CHAR_LIMIT = 30;

export const CAMPAIGN_OBJECTIVES = {
  search: 'REACH',
  traffic: 'TRAFFIC',
  video_views: 'VIDEO_VIEWS',
  community_interaction: 'COMMUNITY_INTERACTION'
};

export const ADS_GROUP = {
  advanced: 'ADVANCED',
  manual: 'MANUAL',
  feeds: 'FEEDS',
  story: 'STORY',
  video_ads: 'VIDEO_ADS',
  search: 'SEARCH',
  panel_1: 'DISPLAY_PANEL_1',
  panel_2: 'DISPLAY_PANEL_2',
  sponsored_by: 'SPONSORED_BY',
  daily: 'DAILY',
  lifetime: 'LIFETIME',
  age_min: 13,
  age_max: 66,
  max_age_limit: 65
};

export const ADS_CREATE = {
  business: 'BUSINESS',
  fanpage: 'FANPAGE',
  none: 'NONE',
  book_now: 'BOOK_NOW',
  contact_us: 'CONTACT_US',
  register_form: 'REGISTER_FORM',
  other: 'OTHER',
  new: 'NEW',
  use: 'USE'
};

export const CREATE_ADS_PREVIEW = {
  FEEDS: {
    TITLE: 'Feeds',
    INDEX: 0,
    NAME: 'FEEDS'
  },
  SEARCH: {
    TITLE: 'Search',
    INDEX: 1,
    NAME: 'SEARCH'
  },
  STORY: {
    TITLE: 'Story',
    INDEX: 2,
    NAME: 'STORY'
  },
  PANEL_1: {
    TITLE: 'Display panel 1',
    INDEX: 3,
    NAME: 'DISPLAY_PANEL_1'
  },
  PANEL_2: {
    TITLE: 'Display panel 2',
    INDEX: 4,
    NAME: 'DISPLAY_PANEL_2'
  },
  SPONSORED: {
    TITLE: 'Sponsored',
    INDEX: 5,
    NAME: 'SPONSORED_BY'
  },
  VIDEO_ADS: {
    TITLE: 'Video ads',
    INDEX: 6,
    NAME: 'VIDEO_ADS'
  }
};

export const TIMELINE_OBJECTS = {
  personalProfile: 'PERSONAL_PROFILE',
  group: 'GROUP',
  fanpage: 'FANPAGE',
  timeline: 'TIMELINE'
};

export const PAGE_SIZE_OPTIONS = [
  { label: '10', size: 10 },
  { label: '30', size: 30 },
  { label: '50', size: 50 }
];

export const LANGUAGES = [
  {
    value: 'en',
    nativeCountryName: 'United States',
    icon: 'assets/icons/US.svg',
    label: 'HEADER.LANGUAGES.EN'
  },
  {
    value: 'ja',
    nativeCountryName: '日本',
    icon: 'assets/icons/JP.svg',
    label: 'HEADER.LANGUAGES.JA'
  },
  {
    value: 'vi',
    nativeCountryName: 'Việt Nam',
    icon: 'assets/icons/VN.svg',
    label: 'HEADER.LANGUAGES.VI'
  },
  {
    value: 'zh',
    nativeCountryName: '中国',
    icon: 'assets/icons/CN.svg',
    label: 'HEADER.LANGUAGES.ZH'
  }
];

//Set 10 followers for testing and then update this number
export const HIGH_FOLLOWERS_FANPAGE = 10;
export const HIGH_FOLLOWERS_PROFILE = 1000;

export const POST_STATUSES = {
  none: 'NONE',
  new: 'NEW',
  pending: 'PENDING',
  edited: 'EDITED',
  warning: 'WARNING',
  deleted: 'DELETED'
};

export const GROUP_OF_POST_STATUSES = {
  timeline: [POST_STATUSES.new, POST_STATUSES.edited],
  statistic: [POST_STATUSES.new, POST_STATUSES.edited],
  pending: [POST_STATUSES.pending]
};

export const ADS_STATUS = {
  feeds: 'FEEDS',
  stories: 'STORIES',
  panels: 'PANELS'
};

export const ONE_BYTE_DEFAULT = 1024;
export const SIZE_IN_BYTE_DEFAULT = 5;
export const VERIFICATION_ATTACHMENT_LIMIT_PROFILE = 6;
export const VERIFICATION_ATTACHMENT_LIMIT_BUSINESS = 5;

export const GROUP_VIEW_TABS = {
  timeline: { label: 'Timeline', index: 0 },
  members: { label: 'Members', index: 1 },
  library: { label: 'Library', index: 2 },
  events: { label: 'Events', index: 3 },
  about: { label: 'About', index: 4 },
  settings: { label: 'Settings', index: 5 }
};

export const ACCOUNT_SETTING_KEY = {
  account: 'account',
  notifications: 'notifications',
  privacy: 'privacy',
  security: 'security',
  verification: 'verification'
};

export const ACCOUNT_SETTING_ACTION = {
  delete: 'DELETE_ACCOUNT',
  deactivate: 'DEACTIVATE_ACCOUNT'
};

export const ACCOUNT_SETTING_ACTION_PAGE = {
  default: 'DEFAULT',
  manage_owner: 'MANAGE_OWNER',
  manage_admin: 'MANAGE_ADMIN',
  manage_no_admin: 'MANAGE_NO_ADMIN',
  reason: 'REASON',
  about: 'ABOUT',
  device_management: 'DEVICE_MANAGEMENT'
};

export const ACCOUNT_PRIVACY_ACTION_PAGE = {
  default: 'DEFAULT',
  blocking: 'BLOCKING',
  findAndContact: 'FIND_AND_CONTACT',
  postAndStories: 'POST_AND_STORIES',
  followersAndProfile: 'FOLLOWER_AND_PROFILE',
  tagsAndMentions: 'TAGS_AND_MENTIONS'
};

export const ACCOUNT_SECURITY_ACTION_PAGE = {
  default: 'DEFAULT',
  change_password: 'CHANGE_PASSWORD',
  two_fa: 'TWO_FA'
};

export const ACCOUNT_SETTING_TABS = [
  { icon: 'sctr-icon-user-circle', label: 'ACCOUNT_SETTING.ACCOUNT_SETTING_LABEL', value: ACCOUNT_SETTING_KEY.account },
  { icon: 'sctr-icon-bell-01', label: 'ACCOUNT_SETTING.NOTIFICATIONS_LABEL', value: ACCOUNT_SETTING_KEY.notifications },
  { icon: 'sctr-icon-shield-tick1', label: 'COMMON.PRIVACY.PRIVACY', value: ACCOUNT_SETTING_KEY.privacy },
  { icon: 'sctr-icon-lock-02', label: 'ACCOUNT_SETTING.SECURITY', value: ACCOUNT_SETTING_KEY.security },
  {
    icon: 'sctr-icon-check-circle',
    label: 'ACCOUNT_SETTING.REQUEST_VERIFICATION.LABEL',
    value: ACCOUNT_SETTING_KEY.verification
  }
];

export const ACTIVITIES_KEY = {
  activities_history: 'activities-history',
  posts_archive: 'post-archive',
  stories_archive: 'story-archive',
  review_timeline: 'review-timeline'
};

export const ACTIVITIES_TABS = [
  { icon: 'sctr-icon-server-04 text-lg', label: 'ACTIVITY_LOG.TITLE', value: ACTIVITIES_KEY.activities_history },
  { icon: 'sctr-icon-archive text-lg', label: 'COMMON.POSTS_ARCHIVE', value: ACTIVITIES_KEY.posts_archive },
  { icon: 'sctr-icon-clock-stopwatch text-lg', label: 'COMMON.STORIES_ARCHIVE', value: ACTIVITIES_KEY.stories_archive },
  { icon: 'sctr-icon-dotpoints-01 text-lg', label: 'REVIEW_TIMELINE.TITLE', value: ACTIVITIES_KEY.review_timeline }
];

export const FRIEND_RELATIONSHIP = {
  friend: 'FRIENDSHIP',
  sentRequest: 'SENT_REQUEST',
  receivedRequest: 'RECEIVED_REQUEST',
  notFriend: 'UNKNOWN',
  myself: 'MYSELF'
};

export const LIBRARY_TARGET_TYPES = {
  user: 1,
  group: 2,
  fanpage: 3
};

export const SOCIAL = 'Social';

// TODO: Not final version yet, to be confirmed and updated
export const CALL_TO_ACTION = {
  book_now: 'Book now',
  contact_us: 'Contact us',
  register_form: 'Register form'
};

export const ALLOWED_ADS_MEDIA_FORMAT = {
  image: ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],
  video: ['video/mp4']
};

export const SKIP_TO_NEXT_PREVIOUS = {
  next: 'next',
  previous: 'previous'
};

export const CREATE_POST_TYPES = {
  createPost: { id: 'createPost', title: 'POST.POPUP.CREATE.TITLE' },
  editPost: { id: 'editPost', title: 'POST.POPUP.EDIT_POST.TITLE' },
  shareAlbum: { id: 'shareAlbum', title: 'POST.POPUP.SHARE_ALBUM.TITLE' }
};

export const PERMISSION_DENIED = 4003;

export const POST_OPTIONS = {
  pin: {
    icon: 'sctr-icon-pin-02',
    label: 'POST.PIN_POST',
    action: actionsPost.pin_post
  },
  unpin: {
    icon: 'sctr-icon-pin-02',
    label: 'POST.UNPIN_POST',
    action: actionsPost.unpin
  },
  save: {
    icon: 'sctr-icon-bookmark',
    label: 'POST.SAVE_POST',
    action: actionsPost.bookmark
  },
  unSave: {
    icon: 'sctr-icon-bookmark-minus',
    label: 'POST.UNSAVE_POST',
    action: actionsPost.bookmark
  },
  hide: {
    icon: 'sctr-icon-x-square',
    label: 'POST.HIDE_POST',
    action: actionsPost.hide
  },
  hideAd: {
    icon: 'sctr-icon-x-square',
    label: 'POST.HIDE_AD',
    action: actionsPost.hide
  },
  unfollow: {
    icon: 'sctr-icon-user-x-01',
    label: 'COMMON.UNFOLLOW',
    action: actionsPost.unfollow
  },
  follow: {
    icon: 'sctr-icon-user-plus-011',
    label: 'COMMON.FOLLOW',
    action: actionsPost.follow
  },
  edit: {
    icon: 'sctr-icon-edit-05',
    label: 'POST.EDIT_POST',
    action: actionsPost.edit
  },
  editPrivacy: {
    icon: 'sctr-icon-lock-02',
    label: 'POST.EDIT_PRIVACY',
    action: actionsPost.edit_privacy
  },
  archive: {
    icon: 'sctr-icon-archive',
    label: 'POST.ARCHIVE_POST',
    action: actionsPost.archive
  },
  report: {
    icon: 'sctr-icon-alert-circle',
    label: 'COMMON.REPORT',
    action: actionsPost.report
  },
  delete: {
    icon: 'sctr-icon-trash-01',
    label: 'POST.DELETE_POST',
    action: actionsPost.delete
  },
  download: {
    icon: 'sctr-icon-download-01',
    label: 'POST.DOWNLOAD',
    action: actionsPost.download_image_or_video
  },
  removeTag: {
    icon: 'sctr-icon-bookmark-x',
    label: 'POST.REMOVE_TAG',
    action: actionsPost.remove_tag
  }
};

export const OTP_EXPIRATION_TIME = 90;

export const FULL_NAME_LIMIT = 50;

export const USERNAME_LIMIT = 24;

export const DEFAULT_COLLECTION = 'My Collections';

export const DEFAULT_MAX_LENGTH_INPUT_FIELDS = {
  text: 255,
  email: 254,
  password: 20,
  taxCode: 30,
  businessCode: 100,
  address: 255,
  website: 255
};

export const REGION_TYPES = {
  country: 1,
  region: 2,
  city: 3
};

export const WALLET_TRANSACTION_TYPES = {
  all: 'ALL',
  soctripPayment: 'SOCTRIP_PAYMENT',
  adjustment: 'ADJUSTMENT',
  orderRefund: 'ORDER_REFUND',
  withdrawals: 'WITHDRAWALS',
  orderIncome: 'ORDER_INCOME'
};

export const LIVESTREAM_STATUS = {
  upComing: 'UPCOMING',
  onGoing: 'ONGOING',
  ended: 'ENDED'
};

export const AVATAR_TYPE = {
  ringWithBorder: 'RING_WITH_BORDER',
  ring: 'RING',
  whiteBorder: 'WHITE_BORDER',
  grayBorder: 'GRAY_BORDER'
};

export const AVATAR_SIZE = {
  desktopProfile: {
    widthHeight: 'w-[160px] h-[160px]',
    ringPadding: 'p-1',
    initialsAvatar: 'text-6xl font-bold'
  },
  mobileProfile: {
    widthHeight: 'w-[140px] h-[140px]',
    ringPadding: 'p-[3px]',
    initialsAvatar: 'text-6xl font-bold'
  },
  small: {
    widthHeight: 'w-[46px] h-[46px]',
    ringPadding: 'p-[2px]',
    initialsAvatar: 'text-lg font-bold'
  },
  tiny: {
    widthHeight: 'w-6 h-6',
    ringPadding: 'p-[1px]',
    initialsAvatar: 'text-xs font-bold'
  },
  fanpageList: {
    widthHeight: 'w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24',
    ringPadding: 'p-[1px]',
    initialsAvatar: 'text-lg font-bold'
  }
};

export const TIME_UNIT_LOGIN = {
  Hour: 'H',
  Day: 'D',
  Minute: 'MM'
};

export const QUANTITY_FRIENDS_TO_SHOW = 5;

export const INPUTS_PROFILE_PRIVACY = {
  dateOfBirth: 'date_of_birth',
  bio: 'bio',
  email: 'email',
  phone: 'phone_number',
  currentLocation: 'address',
  homeTown: 'home_town',
  education: 'educations',
  workPlace: 'work_places',
  relationship: 'relationship',
  hobbies: 'hobbies'
};

export const PRIVACIES_PROFILE = [
  { label: 'PRIVACY.PUBLIC', icon: 'sctr-icon-globe-04', value: PRIVACIES.public },
  { label: 'PRIVACY.FRIENDS', icon: 'sctr-icon-users-01', value: PRIVACIES.friends },
  { label: 'PRIVACY.PRIVATE', icon: 'sctr-icon-lock-02', value: PRIVACIES.private }
];

export const QUEST_ACTION = {
  get: 'Get',
  claim: 'Claim'
};

export const PENALTY_POINT = {
  green: 7.0,
  yellow: 8
};

export const TYPE_SEND_COIN = {
  fanpage: 'FANPAGE',
  user: 'USER'
};

export const TYPE_SERVICE = {
  fanpage: 'page',
  user: 'user'
};

export const PLATFORMS = [
  {
    name: 'Mail',
    icon: '/assets/icons/mail-inbox-black-outline.svg'
  },
  {
    name: 'Facebook',
    icon: '/assets/icons/facebook-logo.svg'
  },
  {
    name: 'Twitter',
    icon: '/assets/icons/twitter-x-logo-black-round.svg'
  }
];

export const QR_CODE_STYLING = {
  width: 230,
  height: 230,
  type: 'svg' as DrawType,
  image: '/assets/images/soctrip-branding/soctrip-logo-blue.svg',
  margin: 6,
  qrOptions: {
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.5,
    margin: 5,
    crossOrigin: 'anonymous'
  },
  dotsOptions: {
    color: '#000',
    type: 'extra-rounded' as DotType
  },
  backgroundOptions: {
    color: '#fff'
  },
  cornersSquareOptions: {
    color: '#000'
  },
  cornersDotOptions: {
    color: '#000'
  }
};

export const ACTIVITY_LOG_ITEM: any[] = [
  {
    'name': 'commented',
    'icon': 'sctr-icon-message-dots-square',
    'color': 'bg-palette-blue-50',
    'color-icon': 'text-palette-blue-600',
    'valueFilter': 'COMMENT'
  },
  {
    'name': 'shared',
    'icon': 'sctr-icon-repeat-01',
    'color': 'bg-palette-emerald-50',
    'color-icon': 'text-palette-emerald-600',
    'valueFilter': 'SHARE'
  },
  {
    'name': 'created',
    'icon': 'sctr-icon-flag-03',
    'color': 'bg-palette-blue-light-50',
    'color-icon': 'text-palette-blue-light-500',
    'valueFilter': 'CREATED'
  },
  {
    'name': 'reported',
    'icon': 'sctr-icon-alert-triangle',
    'color': 'bg-[#EAECF5]',
    'color-icon': 'text-[#363F72]',
    'valueFilter': 'REPORT'
  },
  {
    'name': 'sent coins',
    'icon': 'sctr-icon-coins-hand',
    'color': 'bg-palette-amber-50',
    'color-icon': 'text-palette-amber-500',
    'valueFilter': 'SENT_GIFT'
  },
  {
    'name': 'reacted',
    'icon': 'sctr-icon-heart',
    'color': 'bg-palette-pink-50',
    'color-icon': 'text-palette-pink-600',
    'valueFilter': 'REACTED'
  },
  {
    'name': 'updated',
    'icon': 'sctr-icon-edit-01',
    'color': 'bg-palette-blue-light-50',
    'color-icon': 'text-palette-blue-light-500',
    'valueFilter': 'UPDATED'
  },
  {
    'name': 'replied',
    'icon': 'sctr-icon-corner-up-left',
    'color': 'bg-palette-blue-50',
    'color-icon': 'text-palette-blue-600',
    'valueFilter': 'REPLIED'
  },
  {
    'name': 'follow',
    'icon': 'sctr-icon-user-plus-01',
    'color': 'bg-palette-pink-50',
    'color-icon': 'text-palette-pink-600',
    'valueFilter': 'FOLLOW'
  },
  {
    'name': 'visited',
    'icon': 'sctr-icon-eye',
    'color': 'bg-palette-blue-light-50',
    'color-icon': 'text-palette-blue-light-500',
    'valueFilter': 'VISITED'
  },
  {
    'name': 'searched',
    'icon': 'sctr-icon-search-lg',
    'color': 'bg-palette-emerald-50',
    'color-icon': 'text-palette-emerald-600',
    'valueFilter': 'SEARCHED'
  },
  {
    'name': 'deleted',
    'icon': 'sctr-icon-alert-triangle',
    'color': 'bg-[#EAECF5]',
    'color-icon': 'text-[#363F72]',
    'valueFilter': 'DELETED'
  }
];

export const FRIEND_LIMIT = 1000;
export const FOLLOWERS_LIMIT = 10000;
export const CHARACTER_TRANSLATION_LIMIT = 5000;

export const HOBBIES_KEY_LIST = [
  'READING',
  'MUSIC',
  'SHOPPING',
  'TRAVELING',
  'HIKING',
  'CYCLING',
  'EXERCISING',
  'COOKING',
  'WALKING',
  'BAKING',
  'GARDENING',
  'DRAWING',
  'PAINTING',
  'BLOGGING',
  'DANCING',
  'SKATEBOARDING',
  'SINGING',
  'INSTRUMENTS',
  'LANGUAGES',
  'COLLECTING',
  'GAMING',
  'CRAFTING',
  'SEWING',
  'KNITTING',
  'BOARDGAMES',
  'WRITING',
  'FISHING',
  'PHOTOGRAPHY',
  'SKYDIVING',
  'SKATING',
  'SKIING',
  'SURFING',
  'OTHERS'
];

export const HELP_CENTER_KEY = {
  DELETE_USER: 'delete-user',
  TERM: 'term',
  PRIVACY: 'privacy',
  ABOUT_US: 'about-us',
  DISCLAIMER: 'disclaimer',
  COOKIE: 'cookie',
  LICENSES: 'licenses',
  SEND_US_FEEDBACK: 'send-us-feedback',
  OPERATION_RULE: 'operation-rule'
};

export const FRIEND_LIMITED_OF_USER = 2000;

export const TRANSACTION_TYPE = {
  sendPost: 'SENDPOST',
  send: 'SEND',
  receivedPost: 'RECEIVEDPOST',
  received: 'RECEIVED',
  claim: 'CLAIM',
  none: 'NONE',
  deposit: 'DEPOSIT',
  firstOrder: 'FIRST_ORDER',
  newUserFirstOrder: 'NEW_USER_FIRST_ORDER'
};

export const OTP_REQUIRED_STATUS = 69004;

export const SIGNIN_METHOD = {
  SOCTRIP: 'SOCTRIP',
  GOOGLE: 'GOOGLE'
};

export const BREAKPOINTS = {
  xl: 1200,
  lg: 918,
  md: 768,
  xs: 450
};

export const DEFAULT_QUICK_REPORT_TOTAL = 4;

export const FANPAGE_NAME_MAX_LENGTH = 50;
export const FANPAGE_NAME_MIN_LENGTH = 2;
export const VALID_USER_NAME_LENGTH = 5;
export const GROUP_NAME_MIN_LENGTH = 1;

export const timeZone: any = {
  VN: 'Asia/Ho_Chi_Minh',
  US: 'America/New_York',
  FR: 'Europe/Paris',
  JP: 'Asia/Tokyo',
  DE: 'Europe/Berlin'
}

export const bannerCode = {
  popUpBanner: 'SOCIAL_POPUP_BANNERS',
  topBanner: 'SOCIAL_TOP_BANNERS',
  stickyBanner: 'SOCIAL_STICKY_BANNERS',
  topRightBanner: 'SOCIAL_TOPRIGHT_BANNERS',
  sponsorBanner: 'SOCIAL_SPONSORS_BANNERS'
}