import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { LoginService } from '@app/core/services/login.service';
import { ROLES, SEARCH_DEBOUNCE_TIME } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Subject, debounceTime } from 'rxjs';

interface UserSelect {
  userInfo: any;
  isSelected: boolean;
}

@Component({
  selector: 'transfer-owner-dialog',
  templateUrl: './transfer-owner-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./transfer-owner-dialog.component.scss']
})
export class TransferOwnerDialogComponent {
  @Input() isVisible: boolean = false;
  @Input() userList: any[] = [];
  @Input() userRoles: any[] = [];
  @Input() isLoadingSearch = false;
  @Output() isVisibleChange = new EventEmitter();
  @Output() transferData = new EventEmitter();
  @Output() searchUsers = new EventEmitter();
  search = new Subject<string>();
  roleLabels = [ROLES.ADMIN, ROLES.EDITOR, ROLES.ADVERTISER, ROLES.ANALYST, ROLES.MOD];
  baseUrl: string = environment.baseURL;
  userSelectList: UserSelect[] = [];
  selectedUser: any = null;
  showSelectUserDialog = false;
  isSelectedNewRole = false;
  isSelectedUser = false;
  newRole: any = null;
  countdown = 5;

  constructor(
    public readonly loginService: LoginService,
    private commonService: CommonService
  ) {
    this.search.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(value => {
      this.searchUsers.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userList']) {
      if (this.userList) {
        this.prepareTransferOwnership(this.userList);
        this.resetValues();
      }
    }
  }

  resetValues(): void {
    this.selectedUser = null;
    this.showSelectUserDialog = false;
    this.isSelectedNewRole = false;
    this.isSelectedUser = false;
    this.newRole = null;
    this.countdown = 5;
  }

  prepareTransferOwnership(userList: any[]): void {
    const userSelectList: UserSelect[] = [];
    userList.forEach(userInfo => userSelectList.push({ userInfo: userInfo, isSelected: false }));
    this.userSelectList = userSelectList;
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  onCloseDialog(): void {
    this.resetValues();
    this.isVisibleChange.emit(false);
  }

  onClickedContinueBtn(): void {
    if (!this.isSelectedUser) {
      this.isSelectedUser = true;
    } else {
      this.isSelectedNewRole = true;
      this.startCountDown();
    }
  }

  onClickedConfirmBtn(): void {
    const transferData = { userInfo: this.selectedUser, newRole: this.newRole.value };
    this.transferData.emit(transferData);
    this.isVisibleChange.emit(false);
  }

  onSearch(keyword: string): void {
    this.search.next(keyword);
  }

  onSelectedItem(userSelect: any): void {
    const isChecked = !userSelect.isSelected;
    this.selectedUser = isChecked ? userSelect.userInfo : null;
    this.userSelectList = this.userSelectList.map(user =>
      user.userInfo === userSelect.userInfo ? { ...user, isSelected: isChecked } : { ...user, isSelected: false }
    );
  }

  startCountDown(): void {
    this.loginService.startCountDown(5).subscribe(value => {
      this.countdown = value;
    });
  }
}
