import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent {
  @Input() header: string;
  @Input() title: string;
  @Input() visible: boolean;
  @Output() hideDialog = new EventEmitter();
  position: string;
  dialogStype: object;
  ngOnInit() {
    this.setDialogStyle();
    window.addEventListener('resize', this.onResize.bind(this));
  }
  onHide() {
    this.hideDialog.emit();
  }
  setDialogStyle() {
    if (window.innerWidth >= 768) {
      this.position = 'center';
      this.dialogStype = { width: '480px' };
    } else {
      this.position = 'bottom';
      this.dialogStype = { width: '100%', top: '82px', height: '100%', margin: '0px' };
    }
  }
  onResize(event: any) {
    this.setDialogStyle();
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }
}
