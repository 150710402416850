import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, retry, throwError, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { StorageApiModels } from './api.storage.models';

const STORAGE_FILE_PATH = '/storage/files';

export class StorageApi {
  baseURL = environment.baseURL;

  constructor(
    private http: HttpClient,
    public config: ApiClientConfig
  ) {}

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  updateMediaDescription(mediaId: string, description: string): Observable<StorageApiModels.FileInfoModelResponse> {
    const body = { description: `${description}` };
    return this.http
      .put<StorageApiModels.FileInfoModelResponse>(`${this.baseURL}${STORAGE_FILE_PATH}/${mediaId}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        retry(1),
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  uploadFile(formData: FormData) {
    return this.http.post<any>(`${this.baseURL}${STORAGE_FILE_PATH}/upload-files`, formData);
  }

  uploadFilePanel(blob: Blob): any {
    const formData: FormData = new FormData();
    formData.append('files', blob, 'filename.png'); 
    return this.http.post<any>(`${this.baseURL}${STORAGE_FILE_PATH}/upload-files`, formData);
  }

  downloadFiles(requestBody: StorageApiModels.DownloadRequestBody): Observable<any> {
    return this.http.post(`${this.baseURL}${STORAGE_FILE_PATH}/download-zip`, requestBody, {
      responseType: 'blob' as 'json',
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadFile(requestBody: StorageApiModels.DownloadRequestBody) {
    this.http
      .get(`${this.baseURL}${STORAGE_FILE_PATH}/download/${requestBody.files}`, {
        responseType: 'blob' as 'json'
      })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });
        const fileURL = URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = fileURL;
        a.download = requestBody.files.toString();
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      });
  }
}
