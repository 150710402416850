<p-card [styleClass]="'shadow-sm'">
  <ng-template pTemplate="header">
    <div class="pt-4 px-4 flex w-full items-center justify-between">
      <h1 class="font-semibold text-lg">{{ quickAccess.title | translate }}</h1>
      <i
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event"
        class="sctr-icon-credit-card-edit text-sm cursor-pointer"
        (click)="openCustomQuickAccessDialog()"></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ul class="pb-2">
      <a
        href="{{ !isOpenPopupLogin || access.index === 6 ? access.href : '#' }}"
        checkAuthentication
        (openLoginPopup)="access.index === 6 ? (isOpenPopupLogin = false) : (isOpenPopupLogin = $event)"
        *ngFor="let access of quickAccess.accesses; let i = index"
        class="flex py-2.5 px-4 hover:bg-slate-100 cursor-pointer gap-2 items-center"
        (click)="access.action()">
        <div
          *ngIf="!['ENTITY.GROUPS', 'ENTITY.LIVESTREAMS', 'ENTITY.ADS_CENTER'].includes(access.name); else imgTag"
          class="{{ access.color }}">
          <i class="{{ access.icon }} text-xl"></i>
        </div>
        <ng-template #imgTag>
          <img [src]="access.image" class="w-5 h-5" />
        </ng-template>
        <a class="font-medium text-base capitalize">
          {{ access.name | translate }}
        </a>
      </a>
    </ul>
  </ng-template>
</p-card>

<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>
