import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Breakpoint } from '@shared/enum/breakpoint-enum';
import { DialogConfig } from '@shared/models/dialog';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges, OnInit {
  @Input() showDialog = false;
  @Input() dialogConfig: DialogConfig;
  @Input() template: any;
  @Output() emitOnCloseDialog = new EventEmitter();

  header: string;
  styleClass: string;
  closeFromOutSide: boolean;
  resizable: boolean;
  scrollable: boolean;
  displayCloseButton: boolean;
  innerWidth: number;
  position: string;
  keepCenter: boolean;

  isMobile = false;
  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const screenWidth = (event.target as Window).innerWidth;
    this.identifyBreakpoint(screenWidth);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dialogConfig']) {
      this.closeFromOutSide = this.dialogConfig?.closeFromOutSide;
      this.resizable = this.dialogConfig?.resizable;
      this.displayCloseButton = this.dialogConfig?.displayCloseButton;
      this.styleClass = this.dialogConfig?.styleClass;
    }
  }

  ngOnInit() {
    this.identifyBreakpoint(window.innerWidth);
  }

  identifyBreakpoint(screenWidth: number) {
    if (this.keepCenter || screenWidth >= Breakpoint.MD) {
      this.position = 'center';
    } else {
      this.position = 'bottom';
    }
  }

  closeDialog() {
    this.showDialog = false;
    this.emitOnCloseDialog.emit(false);
  }

  closeFromOutside() {
    if (this.dialogConfig.closeFromOutSide) {
      this.showDialog = false;
    }
  }
}
