<p-card class="post-item" styleClass="shadow-sm border border-gray-200 w-[708px]" *ngIf="post">
  <div class="flex flex-col">
    <div class="flex justify-between px-4 pb-3">
      <div class="flex items-center gap-3">
        <div class="relative">
          <img [src]="baseUrl + '/storage/files/thumbnail/' + post.avatar" alt="" class="t-avatar h-10 w-10 rounded-full" />
          <img
            src="./../../../../../../../../assets/icons/Account-Tier.svg"
            class="absolute top-0 right-0"
            style="
              height: 16px;
              width: 16px;
              padding: 2px;
              background-color: white;
              box-shadow: 0px 1px 2px 0px #1018280d;
              border-radius: 100px;
            " />
        </div>

        <span class="flex flex-col gap-1">
          <span class="text-base font-semibold text-gray-700">{{ post.name }}</span>
          <span class="text-xs text-gray-500 flex flex-row gap-2">
            <img
              src="../../../../../../../../assets/icons/global.svg"
              style="height: 16px; width: 16px" />
            <p class="font-medium" style="font-size: 12px; line-height: 18px">
              {{ post.time }}
            </p>
          </span>
        </span>
      </div>
    </div>
    <div class="px-4 pb-3" *ngIf="post.caption">
      <ng-container *ngIf="!showFullText && post.caption.length > MAX_POST_LENGTH; else elseDiv">
        <div
          class="block whitespace-pre-wrap text-sm font-normal"
          [style]="{ color: '#101828' }"
          [innerHTML]="(post.caption | slice: 0 : MAX_POST_LENGTH) + ' ...'"></div>
        <div
          class="cursor-pointer font-semibold text-sm w-fit"
          [style]="{ color: '#667085' }"
          (click)="onExpandText()">
          {{ 'COMMON.SHOW_MORE' | translate }}
        </div>
      </ng-container>
      <ng-template #elseDiv>
        <div
          class="block whitespace-pre-wrap text-sm font-normal"
          [style]="{ color: '#101828' }"
          [innerHTML]="post.caption"></div>
        <div
          *ngIf="showFullText || post.caption.length > MAX_CAPTION_LENGTH "
          class="cursor-pointer hover:underline font-semibold text-sm w-fit"
          [style]="{ color: '#667085' }"
          (click)="onExpandText()">
          {{ 'COMMON.SHOW_LESS' | translate }}
        </div>
      </ng-template>
    </div>
    <limit-image-box [images]="post.images" [limit]="4"></limit-image-box>
    <div class="mt-2 px-4 flex justify-between">
      <p-checkbox
        [(ngModel)]="checkBoxValue"
        [binary]="true"
        inputId="binary"
        (onChange)="onSelectedChange(checkBoxValue, post)"></p-checkbox>
        <div class="flex flex-row gap-3">
          <p-button
          [disabled]="selectedPost >= 2"
          styleClass="general-btn p-4 h-10 rounded-lg boder border-solid border-gray-300 text-sm font-semibold text-gray-700"
          icon="pi pi-times"
          label="Decline"
          (onClick)="onSelect('decline', [post])"></p-button>
        <p-button
          styleClass="edit-btn p-4 h-10 rounded-lg boder border-solid border-sky-600 text-sm font-semibold text-white"
          icon="pi pi-check"
          label="Approve"
          [disabled]="selectedPost >= 2"
          (onClick)="onSelect('approval', [post])"></p-button>
      </div>
    </div>
  </div>
</p-card>

<ng-template #truncatedText>
  <p>
    <span
      class="mt-2"
      [class.mb-2]="setTruncatedCaption(post.caption).length > post.caption.length">
      {{ setTruncatedCaption(post.caption) }}
    </span>
  </p>

  <a
    (click)="showFullText = true"
    class="text-sm font-semibold text-gray-500 no-underline"
    *ngIf="setTruncatedCaption(post.caption).length < post.caption.length"
    >{{ 'COMMON.VIEW_MORE' | translate }}</a
  >
</ng-template>
