import { Component, Input, SimpleChanges } from '@angular/core';
import { GroupService } from '@app/core/services/group.service';
import { TranslationService } from '@app/core/services/translation.service';
import { StatsApiModels } from '@app/lib/api/statistics/api.statistics.models';
import { StatisticLineChartData } from '@app/shared/components/statistics/statistics.models';
import {
  LINE_CHART_BACKGROUND_COLOR_RGB,
  LINE_CHART_COLORS,
  STATISTIC_LINE_CHART_MIN_DAY_DATA_SCALE,
  STATISTIC_LINE_CHART_TITLES
} from '@app/shared/constant';
import { EpochConverterPipe } from '@app/shared/pipes/epoch-converter.pipe';
import { DateFormatPipe } from '@soctrip-common/datetime-formatter';

interface GroupLineChartData {
  newPosts: number[];
  interactions: number[];
  join: number[];
  leave: number[];
}

@Component({
  selector: 'group-statistic-insights',
  templateUrl: './group-statistic-insights.component.html',
  styleUrls: ['./group-statistic-insights.component.scss']
})
export class GroupStatisticInsightsComponent {
  @Input() groupId: string | null;
  newPostChartData: StatisticLineChartData;
  interactionsChartData: StatisticLineChartData;
  newMembersChartData: StatisticLineChartData;
  userLeaveGroupChartData: StatisticLineChartData;
  dateRanges: any;
  epochConverter = new EpochConverterPipe();
  lineChartData1: GroupLineChartData;
  lineChartData2: GroupLineChartData;
  dateRanges1: Date[] = [];
  dateRanges2: Date[] = [];

  constructor(
    private groupService: GroupService,
    private dateFormatPipe: DateFormatPipe,
    private translationService: TranslationService
  ) {
    this.translationService.language$.subscribe(() => {
      this.loadAllStatsLineChartData();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['groupId']) {
      if (this.dateRanges) {
        this.getAndLoadStatsInsights(this.dateRanges.dateRanges1, this.dateRanges.dateRanges2);
      }
    }
  }

  loadStatisticLineChartData(
    title: string,
    data1: number[],
    data2: number[],
    dateRanges1: Date[],
    dateRanges2: Date[]
  ): StatisticLineChartData {
    const dateRanges1String =
      this.dateFormatPipe.transform(dateRanges1[0]) + ' - ' + this.dateFormatPipe.transform(dateRanges1[1]);
    const dateRanges2String =
      this.dateFormatPipe.transform(dateRanges2[0]) + ' - ' + this.dateFormatPipe.transform(dateRanges2[1]);
    const dateRangesCount = this.countDateRanges(dateRanges1);
    let pointLabels = [];
    let xAxisLabels = [];
    if (dateRangesCount < STATISTIC_LINE_CHART_MIN_DAY_DATA_SCALE) {
      for (let i = 0; i < dateRangesCount; i++) {
        const date1 = new Date(dateRanges1[0].getFullYear(), dateRanges1[0].getMonth(), dateRanges1[0].getDate() + i);
        const date2 = new Date(dateRanges2[0].getFullYear(), dateRanges2[0].getMonth(), dateRanges2[0].getDate() + i);
        const pointLabel = this.dateFormatPipe.transform(date1) + ' | ' + this.dateFormatPipe.transform(date2);
        const xAxisLabel = i < 9 ? '0' + (i + 1).toString() : (i + 1).toString();
        pointLabels.push(pointLabel);
        xAxisLabels.push(xAxisLabel);
      }
    } else {
      for (let i = 0; i < data1.length; i++) {
        const xAxisLabel = i < 9 ? '0' + (i + 1).toString() : (i + 1).toString();
        pointLabels.push(`${xAxisLabel} (1) | ${xAxisLabel} (2)`);
        xAxisLabels.push(xAxisLabel);
      }
    }

    let lineChartData: StatisticLineChartData = {
      title: title,
      pointLabels: pointLabels,
      xAxisLabels: xAxisLabels,
      datasets: [
        {
          label: dateRanges1String,
          data: data1,
          lineColor: LINE_CHART_COLORS.line.blue,
          fill: true,
          backgroundColorRGB: LINE_CHART_BACKGROUND_COLOR_RGB.blue
        },
        {
          label: dateRanges2String,
          data: data2,
          lineColor: LINE_CHART_COLORS.line.orange,
          lineDashed: [5, 5],
          fill: true,
          backgroundColorRGB: LINE_CHART_BACKGROUND_COLOR_RGB.orange
        }
      ]
    };
    return lineChartData;
  }

  scaleData(fullData: number[]): number[] {
    const dataLength = fullData.length;
    if (dataLength < STATISTIC_LINE_CHART_MIN_DAY_DATA_SCALE) {
      return fullData;
    } else {
      const dataLength = fullData.length;
      const scaleTime = Math.floor(dataLength / 32) + 1;
      const scaledData: number[] = [];
      for (let i = 0; i < dataLength; i++) {
        if (!(i % scaleTime)) {
          let sumData = 0;
          for (let j = 0; j < scaleTime; j++) {
            if (i + j < dataLength) {
              sumData = sumData + fullData[i + j];
            }
          }
          scaledData.push(sumData);
        }
      }
      return scaledData;
    }
  }

  countDateRanges(dateRanges: Date[]): number {
    return (Number(dateRanges[1]) - Number(dateRanges[0])) / this.epochConverter.convertToMiliseconds(1) + 1;
  }

  onDateRangesChanged(dateRanges: any): void {
    this.dateRanges = dateRanges;
    this.getAndLoadStatsInsights(dateRanges.dateRanges1, dateRanges.dateRanges2);
  }

  getAndLoadStatsInsights(dateRanges1: Date[], dateRanges2: Date[]): void {
    const groupId = this.groupId ? this.groupId : '';
    if (this.groupId) {
      this.groupService.getGroupStatsInsights(groupId, dateRanges1[0].getTime(), dateRanges1[1].getTime()).subscribe({
        next: res1 => {
          const data1: StatsApiModels.GroupStatsInsights[] = res1;
          this.groupService
            .getGroupStatsInsights(groupId, dateRanges2[0].getTime(), dateRanges2[1].getTime())
            .subscribe({
              next: res2 => {
                const data2: StatsApiModels.GroupStatsInsights[] = res2;
                this.dateRanges1 = dateRanges1;
                this.dateRanges2 = dateRanges2;
                this.lineChartData1 = this.fillMemberRangesData(data1, dateRanges1);
                this.lineChartData2 = this.fillMemberRangesData(data2, dateRanges2);
                this.loadAllStatsLineChartData();
              }
            });
        }
      });
    }
  }

  loadAllStatsLineChartData(): void {
    if (
      this.lineChartData1 &&
      this.lineChartData2 &&
      this.dateRanges1 &&
      this.dateRanges2 &&
      this.dateRanges1.length &&
      this.dateRanges2.length
    ) {
      this.newPostChartData = this.loadStatisticLineChartData(
        STATISTIC_LINE_CHART_TITLES.newPost,
        this.scaleData(this.lineChartData1.newPosts),
        this.scaleData(this.lineChartData2.newPosts),
        this.dateRanges1,
        this.dateRanges2
      );
      this.interactionsChartData = this.loadStatisticLineChartData(
        STATISTIC_LINE_CHART_TITLES.interactions,
        this.scaleData(this.lineChartData1.interactions),
        this.scaleData(this.lineChartData2.interactions),
        this.dateRanges1,
        this.dateRanges2
      );
      this.newMembersChartData = this.loadStatisticLineChartData(
        STATISTIC_LINE_CHART_TITLES.newMembers,
        this.scaleData(this.lineChartData1.join),
        this.scaleData(this.lineChartData2.join),
        this.dateRanges1,
        this.dateRanges2
      );
      this.userLeaveGroupChartData = this.loadStatisticLineChartData(
        STATISTIC_LINE_CHART_TITLES.userLeaveGroup,
        this.scaleData(this.lineChartData1.leave),
        this.scaleData(this.lineChartData2.leave),
        this.dateRanges1,
        this.dateRanges2
      );
    }
  }

  fillMemberRangesData(res: StatsApiModels.GroupStatsInsights[], dateRanges: Date[]): GroupLineChartData {
    const dateCount = this.countDateRanges(dateRanges);
    const startDateUnix = dateRanges[0].getTime();
    let data = res;
    let newPosts = [];
    let interactions = [];
    let join = [];
    let leave = [];
    for (let i = 0; i < dateCount; i++) {
      const dateUnix = startDateUnix + this.epochConverter.convertToMiliseconds(i);
      if (data.length) {
        if (data[0].date_time === dateUnix) {
          newPosts.push(data[0].number_post_new);
          interactions.push(data[0].number_interactions);
          join.push(data[0].number_join);
          leave.push(data[0].number_leave);
          data.shift();
          continue;
        }
      }
      newPosts.push(0);
      interactions.push(0);
      join.push(0);
      leave.push(0);
    }
    return { newPosts: newPosts, interactions: interactions, join: join, leave: leave };
  }
}
