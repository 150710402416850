import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ReportCategoryResponse } from './api.report.model';

export class ReportApi {
  private apiUrl: string = environment.baseURL;
  GET_REPORT_CATEGORY = '/report/report-category/get-all';
  REPORT_REPORT = '/report/report';
  constructor(public readonly httpClient: HttpClient) {}

  getReportCategories() {
    return this.httpClient.get<ReportCategoryResponse>(`${this.apiUrl}${this.GET_REPORT_CATEGORY}`);
  }

  createReportPost(param: any) {
    return this.httpClient.post<any>(`${this.apiUrl}${this.REPORT_REPORT}`, param);
  }
}
