import { HttpClient } from '@angular/common/http';
import { SOCIAL } from '@app/shared';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { UserBehavior, UserBehaviorResponse } from './api.user-behavior.module';

export class UserBehaviorApi {
  private apiUrl: string = environment.baseURL;
  USER_BEHAVIOR = '/user-behavior';
  SOCIAL = SOCIAL.toLocaleUpperCase();

  constructor(
    public readonly httpClient: HttpClient,
    public config: ApiClientConfig
  ) {}

  getUserBehaviors(): Observable<UserBehavior> {
    const url = `${this.USER_BEHAVIOR}/my-behavior?type=${this.SOCIAL}`;
    return this.httpClient.get<UserBehaviorResponse>(`${this.apiUrl}${url}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: UserBehaviorResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  setUserBehaviors(keyword: string): Observable<UserBehavior> {
    const data: UserBehavior = {
      type: this.SOCIAL,
      keywords: [keyword]
    };
    const url = `${this.USER_BEHAVIOR}/my-behavior`;
    return this.httpClient.post<UserBehaviorResponse>(`${this.apiUrl}${url}`, data).pipe(
      timeout(this.config.responseTimeout),
      map((res: UserBehaviorResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
