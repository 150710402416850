<div class="flex flex-col text-palette-gray-900">
  <div
    class="px-4 pt-4 md:pb-4 {{
      postListPaging && postListPaging.data && postListPaging.data.length ? 'pb-0' : 'pb-4'
    }}">
    <span class="text-xl font-semibold">{{ cardTitle | translate }}</span>
  </div>
  <ng-container *ngIf="!(isLoading && !postListPaging); else loadingSkeleton">
    <ng-container *ngIf="postListPaging && postListPaging.data && postListPaging.data.length; else noPost">
      <div class="hidden md:block">
        <ng-container [ngTemplateOutlet]="postTableDesktop"></ng-container>
      </div>
      <div class="block md:hidden">
        <ng-container [ngTemplateOutlet]="postTableMobile"></ng-container>
      </div>
      <div class="px-4 py-4 w-full rounded-b-lg">
        <ng-container [ngTemplateOutlet]="paginator"> </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingSkeleton>
  <ul class="space-y-4 p-4 list-none">
    <ng-container *ngFor="let item of [].constructor(7)">
      <li class="flex items-center">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
        <div style="flex: 1">
          <p-skeleton width="w-full" styleClass="mb-2" />
        </div>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template #noPost>
  <div class="flex flex-col gap-2 md:gap-4 w-full justify-center items-center py-0 md:py-4 mb-4">
    <img src="./assets/images/Illustration.svg" alt="No post" class="w-28 md:w-32" />
    <span class="text-sm md:text-base text-palette-gray-600 font-semibold">{{
      'STATISTIC.POST_LIST.NO_POST' | translate
    }}</span>
  </div>
</ng-template>

<ng-template #postTableDesktop>
  <div class="flex flex-col">
    <table class="relative">
      <tr class="h-10 border-b border-palette-gray-200 bg-palette-gray-100">
        <ng-container *ngFor="let header of postListHeader; first as first">
          <th>
            <span
              class="text-sm font-medium text-palette-gray-700 {{
                first ? 'flex justify-start items-center h-full pl-4' : ''
              }}">
              {{ header | translate }}
            </span>
          </th>
        </ng-container>
      </tr>
      <ng-container *ngIf="!isLoading; else loadingState">
        <ng-container *ngFor="let post of postListPaging.data">
          <tr class="h-16 border-b border-palette-gray-200" (click)="onClickedPost(post)">
            <td class="max-w-[212px] p-3">
              <ng-container
                [ngTemplateOutlet]="postCell"
                [ngTemplateOutletContext]="{
                  post: post,
                  postStyle: tableStyle
                }"></ng-container>
            </td>
            <td class="post-table-cell-text" *ngIf="tableStyle === POST_TABLE_STYLE.group">
              {{ post.posted_date | dateFormatPipe }}
            </td>
            <td class="p-3">
              <div class="h-full flex items-center justify-center">
                <ng-container
                  [ngTemplateOutlet]="postStatus"
                  [ngTemplateOutletContext]="{
                    statusContext: POST_STATUS_CONTEXTS.active.value.includes(post.status)
                      ? POST_STATUS_CONTEXTS.active
                      : POST_STATUS_CONTEXTS.warning
                  }"></ng-container>
              </div>
            </td>
            <td
              title="{{ post.total_reaches ? post.total_reaches : 0 }}"
              class="post-table-cell-text"
              *ngIf="tableStyle === POST_TABLE_STYLE.fanpage">
              {{ post.total_reaches ? (post.total_reaches | shortNumber) : 0 }}
            </td>
            <td title="{{ post.total_reactions ? post.total_reactions : 0 }}" class="post-table-cell-text">
              {{ post.total_reactions ? (post.total_reactions | shortNumber) : 0 }}
            </td>
            <td title="{{ post.total_comments ? post.total_comments : 0 }}" class="post-table-cell-text">
              {{ post.total_comments ? (post.total_comments | shortNumber) : 0 }}
            </td>
            <td title="{{ post.total_shares ? post.total_shares : 0 }}" class="post-table-cell-text">
              {{ post.total_shares ? (post.total_shares | shortNumber) : 0 }}
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</ng-template>

<ng-template #postTableMobile>
  <div class="flex flex-col">
    <div class="flex flex-col relative">
      <ng-container *ngIf="!isLoading; else loadingState">
        <ng-container *ngFor="let post of postListPaging.data">
          <div class="w-full flex flex-col gap-2 px-4 pt-3" (click)="onClickedPost(post)">
            <div class="flex gap-4 justify-between">
              <ng-container
                [ngTemplateOutlet]="postCell"
                [ngTemplateOutletContext]="{
                  post: post,
                  postStyle: tableStyle
                }"></ng-container>
              <ng-container
                [ngTemplateOutlet]="postStatus"
                [ngTemplateOutletContext]="{
                  statusContext: POST_STATUS_CONTEXTS.active.value.includes(post.status)
                    ? POST_STATUS_CONTEXTS.active
                    : POST_STATUS_CONTEXTS.warning
                }"></ng-container>
            </div>
            <div class="w-full flex">
              <ng-container
                *ngIf="tableStyle === POST_TABLE_STYLE.fanpage"
                [ngTemplateOutlet]="postStatsMobile"
                [ngTemplateOutletContext]="{
                  value: post.total_reaches ? (post.total_reaches | shortNumber) : 0,
                  header: 'ENTITY.REACHES',
                  styleClass: 'border-r'
                }"></ng-container>
              <ng-container
                *ngIf="tableStyle === POST_TABLE_STYLE.group"
                [ngTemplateOutlet]="postStatsMobile"
                [ngTemplateOutletContext]="{
                  value: post.posted_date | dateFormatPipe,
                  header: 'COMMON.DATE_TIME.DATE',
                  styleClass: 'border-r'
                }"></ng-container>
              <ng-container
                [ngTemplateOutlet]="postStatsMobile"
                [ngTemplateOutletContext]="{
                  value: post.total_reactions ? (post.total_reactions | shortNumber) : 0,
                  header: 'ENTITY.REACTIONS',
                  styleClass: 'border-r'
                }"></ng-container>
              <ng-container
                [ngTemplateOutlet]="postStatsMobile"
                [ngTemplateOutletContext]="{
                  value: post.total_comments ? (post.total_comments | shortNumber) : 0,
                  header: 'ENTITY.COMMENTS',
                  styleClass: 'border-r'
                }"></ng-container>
              <ng-container
                [ngTemplateOutlet]="postStatsMobile"
                [ngTemplateOutletContext]="{
                  value: post.total_shares ? (post.total_shares | shortNumber) : 0,
                  header: 'ENTITY.SHARES',
                  styleClass: 'border-r'
                }"></ng-container>
            </div>
            <div class="w-full border-b border-palette-gray-200 pt-1"></div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #loadingState>
  <ng-container *ngFor="let _ of [].constructor(4)">
    <tr class="h-10 border-b border-palette-gray-200 bg-palette-gray-100">
      <ng-container *ngFor="let _ of [].constructor(6); let isFirst = first">
        <td [ngClass]="{ 'p-3': !isFirst }">
          <div class="flex justify-center">
            <p-skeleton width="50px"></p-skeleton>
          </div>
        </td>
      </ng-container>
    </tr>
  </ng-container>
</ng-template>

<ng-template #postStatsMobile let-value="value" let-header="header" let-styleClass="styleClass">
  <div
    class="grow text-sm font-normal text-palette-gray-600 text-center align-middle gap-1  border-palette-gray-200 border-dashed flex flex-col {{
      styleClass
    }}">
    <span class="text-xs font-medium">{{ header | translate }}</span>
    <span>
      {{ value }}
    </span>
  </div>
</ng-template>

<ng-template #postCell let-post="post" let-postStyle="postStyle">
  <ng-container *ngIf="postStyle === POST_TABLE_STYLE.group; else postCellFanpage">
    <div class="h-full flex gap-2 items-center justify-start truncate">
      <div class="min-w-[48px] h-10 flex justify-center">
        <img
          class="w-10 h-10 rounded-full object-cover border border-palette-gray-100"
          [src]="getAvatarThumbnail(post) || './assets/images/post-thumbnail-small.svg'"
          alt="" />
      </div>
      <div class="flex flex-col justify-center text-sm truncate">
        <span
          class="truncate text-palette-gray-900 md:text-palette-gray-700 font-semibold leading-5 emoji"
          title="{{ post.content }}"
          >{{ post.content }}</span
        >
        <span class="text-xs md:text-sm text-palette-gray-600 md:text-palette-gray-500 leading-5">{{
          post.user_object.full_name
        }}</span>
      </div>
    </div>
  </ng-container>
  <ng-template #postCellFanpage>
    <div class="h-full flex gap-2 items-center justify-start truncate">
      <div class="min-w-[48px] h-10 flex justify-center">
        <img
          class="w-10 h-10 rounded-full object-cover border border-palette-gray-100"
          [src]="getPostThumbnail(post) || './assets/images/post-thumbnail-small.svg'"
          alt="" />
      </div>
      <div class="flex flex-col justify-center text-sm truncate">
        <span
          class="truncate text-palette-gray-900 md:text-palette-gray-700 font-semibold leading-5 emoji"
          title="{{ post.content }}"
          >{{ post.content }}</span
        >
        <span class="text-xs md:text-sm text-palette-gray-600 md:text-palette-gray-500 leading-5">{{
          post.posted_date | dateFormatPipe
        }}</span>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #postStatus let-statusContext="statusContext">
  <div class="h-5 min-w-fit">
    <span class="rounded-full flex w-fit items-center leading-5 px-[2px] mx-1 {{ statusContext.styleClass }}"
      ><span class="pl-1 text-[8px] align-middle">●</span
      ><span class="px-1 text-xs align-middle">{{ 'STATUS.' + statusContext.label | translate }}</span>
    </span>
  </div>
</ng-template>

<ng-template #paginator>
  <paginator
    [paging]="postListPaging"
    (pageSize)="onChangedPageSize($event)"
    (pagingDetail)="onPageChange($event)"></paginator>
</ng-template>
