<div
  *ngIf="overlayVisible"
  class="w-auto h-full absolute bg-white items-center border-solid shadow-sm rounded-full flex z-[6]"
  [ngClass]="{
    'bottom-6 -left-4 comment-box': isCommentPopup, 
    'bottom-[32px] -left-[116px] story-box': isStoryPopup,
    'bottom-[90px] post-box': !isCommentPopup && !isStoryPopup}"
  (mouseenter)="handleMouseEvent(true)"
  (mouseleave)="handleMouseEvent(false)">
  <div *ngFor="let item of emojiList">
    <img 
      *ngIf="selectedEmoji?.type === item.type"
      class="mx-1 duration-300 hover:-translate-y-2 hover:scale-110  cursor-pointer"
      [ngClass]="isStoryPopup ? 'w-12  hover:w-12' : 'w-7 hover:w-10'"
      src="{{ item.selectedIcon }}"
      alt=""
      (click)="selectEmoji(item)" />
    <img
      *ngIf="selectedEmoji?.type !== item.type"
      class="mx-1 duration-300 hover:-translate-y-2 hover:scale-110 hover:w-10 cursor-pointer"
      src="{{ item.largeIcon }}"
      [ngClass]="isStoryPopup ? 'w-12  hover:w-12' : 'w-7 hover:w-10'"
      alt=""
      (click)="selectEmoji(item)" />
  </div>
</div>
