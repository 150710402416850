<div
  class="flex justify-center bg-white flex-col p-4 gap-4 rounded-md"
  [ngClass]="{
    'w-full': isGroupEventDetail,
    'md:w-[888px] w-[450px]': !isGroupEventDetail
  }"
  [ngClass]="{ 'hidden': !dataEventDetail }">
  <div>
    <img
      [src]="loadEventImg(dataEventDetail?.avatar || '')"
      alt=""
      class="max-h-[410px] h-[100%] w-full object-cover" />
    <div class="flex flex-col p-4 gap-2 event-title">
      <p-chip
        class="md:hidden flex justify-center"
        *ngIf="dataEventDetail?.privacy === 1"
        label="{{ 'COMMON.PRIVACY.PUBLIC' | translate }}"></p-chip>
      <p-chip
        class="md:hidden flex justify-center"
        *ngIf="dataEventDetail?.privacy === 2"
        label="{{ 'COMMON.PRIVACY.PRIVATE' | translate }}"></p-chip>
      <span class="font-medium text-sm uppercase text-palette-gray-500 flex justify-center md:justify-start"
        >{{ dataEventDetail?.start_date_time | date: 'd' }} {{ translatedStartMonth }}
        {{ dataEventDetail?.start_date_time | date: 'y' }} {{ 'EVENT.AT' | translate }}
        {{ dataEventDetail?.start_date_time | date: 'h:mm a' }} - {{ dataEventDetail?.end_date_time | date: 'd' }}
        {{ translatedEndMonth }} {{ dataEventDetail?.end_date_time | date: 'y' }} {{ 'EVENT.AT' | translate }}
        {{ dataEventDetail?.end_date_time | date: 'h:mm a' }}</span
      >
      <span class="text-palette-gray-900 text-2xl font-semibold flex justify-center md:justify-start">{{
        dataEventDetail?.name
      }}</span>
      <p-chip
        class="hidden md:flex"
        *ngIf="dataEventDetail?.privacy === 1"
        label="{{ 'COMMON.PRIVACY.PUBLIC' | translate }}"></p-chip>
      <p-chip
        class="hidden md:flex"
        *ngIf="dataEventDetail?.privacy === 2"
        label="{{ 'COMMON.PRIVACY.PRIVATE' | translate }}"></p-chip>
    </div>
    <div class="flex gap-3 justify-center md:justify-start header-event" *ngIf="today < dataEventDetail?.end_date_time">
      <div class="flex gap-3" *ngIf="isModeratorView">
        <p-button (click)="onInviteFriend()" class="event-invite-btn custom-btn-event">
          <i class="sctr-icon-plus text-lg text-palette-base-white"></i>
          <span class="text-sm pl-2">{{ 'EVENT.INVITE' | translate }}</span>
        </p-button>
        <p-button
          styleClass="general-btn flex flex-row gap-2 h-[36px] font-semibold text-palette-gray-700 hover:bg-palette-base-white border border-inherit custom-btn-event"
          (click)="visible = true">
          <i class="text-lg sctr-icon-edit-03"></i>
          <span class="text-sm">{{ 'COMMON.BUTTON_ACTION.EDIT' | translate }}</span>
        </p-button>
      </div>

      <div class="flex gap-3" *ngIf="!isModeratorView">
        <p-button
          *ngIf="!dataEventDetail?.is_attended"
          styleClass="py-2 px-3.5 gap-2 h-[36px] bg-branding-primary-600 rounded-lg text-palette-base-white custom-btn-event"
          (click)="handleActionAttend(dataEventDetail?.id)">
          <i class="sctr-icon-calendar-check-01 font-semibold text-lg"></i>
          <span class="font-semibold text-sm">{{ 'EVENT.ATTEND' | translate }}</span>
        </p-button>
        <p-button
          *ngIf="dataEventDetail?.is_attended"
          styleClass="love-btn py-2 px-3.5 gap-2 h-9 border-palette-gray-300"
          (click)="handleActionAttend(dataEventDetail?.id)">
          <i class="sctr-icon-calendar-check-01 font-semibold text-lg text-palette-gray-700"></i>
          <span class="font-semibold text-sm text-palette-gray-700">{{ 'EVENT.ATTENDED' | translate }}</span>
        </p-button>
        <p-button
          (click)="handleInterest(dataEventDetail?.id)"
          styleClass="general-btn flex flex-row gap-2 h-[36px] font-semibold text-palette-gray-700 hover:bg-palette-base-white border border-inherit custom-btn-event">
          <i *ngIf="!dataEventDetail?.is_interested" class="text-lg sctr-icon-heart text-palette-gray-700"></i>
          <i *ngIf="dataEventDetail?.is_interested" class="pi pi-heart-fill"></i>
          <span *ngIf="dataEventDetail?.is_interested" class="text-sm">{{ 'EVENT.INTERESTED' | translate }}</span>
          <span *ngIf="!dataEventDetail?.is_interested" class="text-sm">{{ 'EVENT.INTEREST' | translate }}</span>
        </p-button>
      </div>
      <div class="relative">
        <p-button
          styleClass="general-btn h-[36px] w-10 focus:bg-palette-gray-200 ring-branding-primary-300 ring-offset-2 hover:bg-palette-gray-200 border border-inherit custom-btn-event"
          icon="sctr-icon-dots-horizontal text-lg text-palette-gray-700"
          (click)="isShowQrOverlay = !isShowQrOverlay"></p-button>
        <p-overlay
          [(visible)]="isShowQrOverlay"
          [contentStyleClass]="'border-round absolute top-2 right-0 w-fit h-fit'">
          <div class="px-4 py-1 bg-white rounded-lg drop-shadow-md w-max">
            <button
              *ngFor="let item of isModeratorView ? ownerOptions : userOptions"
              class="w-full h-fit flex text-start py-3 px-3 my-2 hover:bg-gray-200 border-none bg-white text-palette-gray-700"
              pButton
              (click)="item.action()"
              icon="{{ item.icon }}"
              label="{{ item.label | translate }}"></button>
          </div>
        </p-overlay>
      </div>
    </div>
  </div>
  <div class="gap-x-3 grid grid-cols-4">
    <div
      class="truncate flex flex-col p-3 rounded border border-slate-300"
      style="background-image: url('/assets/images/frames/Metric-item-of-event.png'); background-size: 100%">
      <div class="flex justify-between">
        <span class="text-palette-gray-700 text-2xl font-medium">{{ dataEventDetail?.number_responses }}</span>
        <div class="bg-system-error-300 py-1 px-2 rounded-full hidden md:flex items-center">
          <i class="sctr-icon-cursor-box text-white"></i>
        </div>
      </div>
      <span class="text-palette-gray-500 text-xs font-medium">{{ 'EVENT.RESPONSES' | translate }}</span>
    </div>
    <div class="truncate flex flex-col p-3 rounded border border-slate-300">
      <div class="flex justify-between">
        <span class="text-palette-gray-700 text-2xl font-medium">{{ dataEventDetail?.number_interested }}</span>
        <div class="bg-palette-gray-200 py-1 px-2 rounded-full hidden md:flex items-center">
          <i class="sctr-icon-heart"></i>
        </div>
      </div>
      <span class="text-palette-gray-500 text-xs font-medium">{{ 'EVENT.INTERESTED' | translate }}</span>
    </div>
    <div class="truncate flex flex-col p-3 rounded border border-slate-300">
      <div class="flex justify-between">
        <span class="text-palette-gray-700 text-2xl font-medium">{{ dataEventDetail?.number_attendees }}</span>
        <div class="bg-palette-gray-200 py-1 px-2 rounded-full hidden md:flex items-center">
          <i class="sctr-icon-calendar-check-01"></i>
        </div>
      </div>
      <span class="text-palette-gray-500 text-xs font-medium" *ngIf="dataEventDetail?.number_attendees <= 1">{{
        'EVENT.ATTENDEE' | translate
      }}</span>
      <span class="text-palette-gray-500 text-xs font-medium" *ngIf="dataEventDetail?.number_attendees > 1">{{
        'EVENT.ATTENDEES' | translate
      }}</span>
    </div>
    <div class="truncate flex flex-col p-3 rounded border border-slate-300">
      <div class="flex justify-between">
        <span class="text-palette-gray-700 text-2xl font-medium">{{ dataEventDetail?.number_hosts }}</span>
        <div class="bg-palette-gray-200 py-1 px-2 rounded-full hidden md:flex items-center">
          <i class="sctr-icon-user-01"></i>
        </div>
      </div>
      <span class="text-palette-gray-500 text-xs font-medium">{{ 'EVENT.HOST' | translate }}</span>
    </div>
  </div>
  <div class="hidden md:flex" *ngIf="dataEventDetail?.address && dataEventDetail?.address?.address">
    <span class="text-palette-gray-800 font-semibold text-base">{{ 'COMMON.ADDRESS' | translate }}</span>
  </div>
  <div class="hidden md:flex" *ngIf="dataEventDetail?.link">
    <span class="text-palette-gray-800 font-semibold text-base">{{ 'EVENT.LINK' | translate }}</span>
  </div>
  <div class="flex gap-3 items-center" *ngIf="dataEventDetail?.address && dataEventDetail?.address?.address">
    <div class="flex bg-palette-gray-100 p-2.5 rounded-full">
      <i class="sctr-icon-marker-pin-02"></i>
    </div>
    <div class="flex flex-col">
      <span class="font-medium text-sm text-palette-gray-900">{{ dataEventDetail?.address?.address }}</span>
    </div>
  </div>
  <div class="flex gap-3 items-center" *ngIf="dataEventDetail?.link">
    <div class="flex bg-palette-gray-100 p-2.5 rounded-full">
      <i class="sctr-icon-link-03"></i>
    </div>
    <div class="flex flex-col">
      <a
        [href]="dataEventDetail?.link"
        target="_blank"
        class="font-medium text-sm text-branding-primary-700 underline cursor-pointer"
        >{{ dataEventDetail?.link }}</a
      >
    </div>
  </div>
  <div class="flex flex-col gap-2">
    <span class="text-palette-gray-800 md:text-base font-semibold text-lg">{{
      'EVENT.ABOUT_THIS_EVENT' | translate
    }}</span>
    <div #eventDetail>
      <ng-container *ngIf="!isExpanded && contentHeight > MAX_TRUNCATED_TEXT_HEIGHT; else elseDiv">
        <div
          class="whitespace-pre-wrap event-detail event-detail-desc truncated-text text-palette-gray-700 text-sm"
          [innerHTML]="dataEventDetail?.description + ' ...'"></div>
        <div
          class="text-branding-primary-700 font-semibold text-sm w-fit cursor-pointer toggle-button"
          (click)="isExpanded = !isExpanded">
          {{ 'COMMON.VIEW_MORE' | translate }}
        </div>
      </ng-container>
      <ng-template #elseDiv>
        <div
          class="block whitespace-pre-wrap event-detail event-detail-desc break-words text-palette-gray-700 text-sm font-normal"
          [innerHTML]="dataEventDetail?.description"></div>
        <div
          *ngIf="isExpanded"
          class="break-words text-branding-primary-700 font-semibold text-sm w-fit cursor-pointer toggle-button"
          (click)="isExpanded = !isExpanded">
          {{ 'COMMON.VIEW_LESS' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div
  *ngIf="dataEventDetail"
  [ngClass]="{
    'grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-1': dataEventDetail?.privacy === 2 || dataEventDetail?.privacy === 3,
    'w-full': isGroupEventDetail,
    'md:w-[888px] w-[450px]': !isGroupEventDetail
  }">
  <div class="flex bg-white flex-col gap-4 p-4 rounded-md mt-2">
    <span class="text-palette-gray-800 font-semibold text-base"
      >{{ 'EVENT.HOST' | translate }} ({{ dataEventDetail?.hosts_info.length }})</span
    >
    <div class="flex flex-col gap-4">
      <div class="flex justify-between" *ngFor="let item of dataEventDetail?.hosts_info">
        <div class="flex gap-2.5 items-center cursor-pointer" (click)="navigateToProfile(item)">
          <img
            class="h-[32px] w-[32px] rounded-full object-cover"
            [src]="getImgUrl(item.user_info.avatar_thumbnail_url)"
            alt="Avatar Icon" />
          <div class="flex flex-col">
            <span class="text-palette-gray-700 font-semibold text-sm break-words">{{ item.user_info.full_name }}</span>
            <!-- <span class="text-xs font-normal text-palette-gray-600 break-words">Mexican Restaurant</span> -->
          </div>
        </div>
        <!--TODO: Hide this option following request from ticket SN-7207-->
        <!-- <p-button
          styleClass="general-btn flex flex-row gap-2 h-[28px] w-fit items-center justify-center font-semibold text-palette-gray-700 hover:bg-palette-base-white border border-inherit">
          <span class="text-sm text-palette-gray-700 font-semibold">{{ 'COMMON.FOLLOW' | translate }}</span>
        </p-button> -->
      </div>
    </div>
  </div>
  <div
    class="flex bg-white flex-col gap-4 p-4 rounded-md mt-2"
    *ngIf="dataEventDetail?.privacy === 2 || dataEventDetail?.privacy === 3">
    <span class="text-palette-gray-800 font-semibold text-base"
      >{{ 'EVENT.GUEST' | translate }} ({{ dataEventDetail?.guests_info.length }})</span
    >
    <div class="flex flex-col gap-4">
      <div class="flex justify-between" *ngFor="let item of dataEventDetail?.guests_info">
        <div class="flex items-center gap-2.5 cursor-pointer" (click)="navigateToProfile(item)">
          <img
            class="h-[32px] w-[32px] rounded-full object-cover"
            [src]="getImgUrl(item.user_info.avatar_thumbnail_url)"
            alt="Avatar Icon" />
          <div class="flex flex-col">
            <span class="text-palette-gray-700 font-semibold text-sm break-words">{{ item.user_info.full_name }}</span>
            <!-- <span class="text-xs font-normal text-palette-gray-600 break-words">Mexican Restaurant</span> -->
          </div>
        </div>
        <p-button
          styleClass="general-btn flex flex-row gap-2 h-[28px] w-[68px] items-center justify-center font-semibold text-palette-gray-700 hover:bg-palette-base-white border border-inherit">
          <span class="text-sm text-palette-gray-700 font-semibold">{{ 'COMMON.FOLLOW' | translate }}</span>
        </p-button>
      </div>
    </div>
    <!-- <div class="flex flex-col">
      <div class="flex justify-between">
        <div class="flex gap-2.5 items-center gap-2.5">
          <img class="h-[32px] w-[32px] object-cover" src="assets/images/avatar-may-know-1.png" alt="Avatar Icon" />
          <div class="flex flex-col">
            <span class="text-palette-gray-700 font-semibold text-sm break-words">Agave Saigon</span>
            <span class="text-xs font-normal text-palette-gray-600 break-words">Mexican Restaurant</span>
          </div>
        </div>
        <p-button
          styleClass="general-btn flex flex-row gap-2 h-[28px] w-[68px] items-center justify-center font-semibold text-palette-gray-700 hover:bg-palette-base-white border border-inherit">
          <span class="text-sm text-palette-gray-700 font-semibold">Follow</span>
        </p-button>
      </div>
    </div> -->
  </div>
</div>

<div class="w-full mt-3 flex justify-end" [ngClass]="{ 'md:w-full w-full': isGroupEvent }">
  <p-button
    *ngIf="dataEventDetail"
    styleClass="general-btn flex flex-row gap-2 h-13 font-semibold text-palette-gray-700 bg-palette-base-white border border-inherit custom-btn-event"
    (click)="onBackToEventList()">
    <i class="text-lg sctr-icon-chevron-left"></i>
    <span class="text-sm">{{ 'EVENT.BACK_TO_EVENT_LIST' | translate }}</span>
  </p-button>
</div>

<create-event
  [visible]="visible"
  [isSubmitting]="isSubmitting"
  [eventMembers]="memberList"
  [allFieldsFilled]="allFieldsFilled"
  [oldDataEvent]="dataEventDetail"
  (actionCloseModal)="visible = $event"
  (handleEdit)="handleEditEvent($event)"></create-event>

<event-invite-dialog [(isVisible)]="showInviteFriendsDialog" [eventId]="dataEventDetail?.id" [objectId]="objectId">
</event-invite-dialog>
