<p-toast
  position="bottom-left"
  key="progressPercent"
  (onClose)="onClose()"
  [baseZIndex]="5000"
  [breakpoints]="{ '768px': { width: '100%', right: '0', left: '0' } }"
  styleClass="px-4 md:px-0">
  <ng-template let-message pTemplate="message">
    <div class="w-full h-full flex items-center gap-3 pr-2">
      <i class="sctr-icon-download-cloud-02 text-palette-gray-700 text-2xl"></i>
      <div class="grow h-full flex flex-col gap-0.5 text-sm">
        <span>{{ 'LIBRARY.FILE_DOWNLOADING' | translate }}...</span>
        <div class="grow h-full flex gap-2 items-center">
          <p-progressBar
            [value]="progress"
            [showValue]="false"
            styleClass="h-2 w-[220px] md:w-64"
            color="text-palette-gray-700"></p-progressBar>
          <span class="min-w-fit font-semibold toast-content">{{ progress }}%</span>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
