import { Component, Input, SimpleChanges } from '@angular/core';
import { GroupService } from '@app/core/services/group.service';
import { PostListPaging } from '@app/lib/api/post/api.post.model';
import { POST_TABLE_STYLE, SORT } from '@app/shared/constant';

@Component({
  selector: 'group-post-list',
  templateUrl: './group-post-list.component.html',
  styleUrls: ['./group-post-list.component.scss']
})
export class GroupPostListComponent {
  @Input() groupId: string | null;
  groupPostList: PostListPaging;
  POST_TABLE_STYLE = POST_TABLE_STYLE;
  isLoading = true;

  constructor(private groupService: GroupService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['groupId']) {
      this.getGroupPostList();
    }
  }

  getGroupPostList(pageSize = 10, pageNum = 0, sortByDate = SORT.desc): void {
    if (this.groupId) {
      this.isLoading = true;
      this.groupService.getGroupStatsPostList(this.groupId, pageSize, pageNum, sortByDate).subscribe({
        next: (res: PostListPaging) => {
          if (res) {
            this.groupPostList = res;
            this.isLoading = false;
          }
        }
      });
    }
  }

  onPageChange(event: any): void {
    const pageNum = event.page;
    const pageSize = event.rows;
    this.getGroupPostList(pageSize, pageNum);
  }
}
