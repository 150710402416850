import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@env/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    const session = sessionStorage.getItem(environment.TOKEN_KEY);
    const isLoginRoute = state.url.startsWith('/login');
    const isSignUpRoute = state.url.startsWith('/sign-up');
    const returnUrl = this.authService.getReturnUrl();
    const navigationExtras: NavigationExtras = { queryParams: { r: returnUrl } };

    if (session) return true;
    if (isLoginRoute || isSignUpRoute) {
      if (returnUrl && !isLoggedIn) {
        return true;
      } else if (isLoggedIn) {
        this.router.navigate(['']);
        return false;
      }
    } else if (!isLoggedIn) {
      this.router.navigate(['/login'], returnUrl ? navigationExtras : {});
      return false;
    }

    return true;
  }
}
