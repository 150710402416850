import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { FollowerService } from '@app/core/services/follower.service';
import { GroupService } from '@app/core/services/group.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { PRIVACIES, TAGS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';

export interface CustomData {
  id: string;
  name: string;
  avatarUrl: string;
  type: TypeItem;
  description?: string;
  privacy?: string;
  number_of_members?: number;
  followers?: number;
  number_of_mutual_friend?: number;
  view?: number;
  pending_request?: boolean;
  is_verified_user?: boolean;
  is_verified_page?: boolean;
}

interface TypeItem {
  name: string;
  role: string | null;
}
@Component({
  selector: 'badge-icon',
  templateUrl: './badge-icon.component.html',
  styleUrls: ['./badge-icon.component.scss']
})
export class BadgeIconComponent {
  @Input() customData: CustomData;
  @Input() userAvatar: any;
  @Input() disableNavigate = false;
  @Output() updateItemSearchResult = new EventEmitter();
  tags = TAGS;
  PRIVACIES = PRIVACIES;
  isLoadingFlow = false;
  isLoadingJoin = false;

  constructor(
    private router: Router,
    private followerService: FollowerService,
    private groupService: GroupService,
    private toastMessageService: ToastMessageService,
    private commonService: CommonService
  ) { }

  handleGetUrl() {
    if (this.customData.type.name !== TAGS.PLACE) {
      return environment.baseURL + this.customData.avatarUrl;
    }
    return this.customData.avatarUrl;
  }

  handleNavigate() {
    if (this.disableNavigate) return;
    let url = '';
    switch (this.customData.type.name) {
      case TAGS.PEOPLE:
        url = `/personal-profile/${this.customData.id}`;
        break;
      case TAGS.GROUP:
        url = `/group/${this.customData.id}`;
        break;
      case TAGS.FANPAGE:
        url = `/fanpage/${this.commonService.convertToSnakeCaseString(this.customData.name)}/${this.customData.id}`;
        break;
    }
    this.router.navigateByUrl(url);
  }

  isPeopleItem() {
    return this.customData.type.name === TAGS.PEOPLE;
  }

  handleGenerateStatus() {
    switch (this.customData.type.name) {
      case TAGS.FANPAGE:
        return this.customData.type.role ? 'Following' : 'Follow';
      case TAGS.GROUP:
        return this.customData.type.role ? 'Joined' : this.customData.pending_request ? 'Cancel requests' : 'Join';
      default:
        return;
    }
  }

  handleActionCTAButton(type: TypeItem, event: Event) {
    event.stopPropagation();
    if (!type.role || this.customData.pending_request || type.role === 'FOLLOWER') {
      switch (type.name) {
        case this.tags.FANPAGE:
          this.isLoadingFlow = true;
          const followAction = this.customData.type.role === null ? 'follow' : 'unfollow';
          const serviceMethod = followAction === 'follow' ? 'followFanpage' : 'unFollowFanpage';
          const successMessage =
            followAction === 'follow' ? 'FANPAGE.FOLLOW_FANPAGE_SUCCESSFULLY' : 'FANPAGE.UNFOLLOW_FANPAGE_SUCCESSFULLY';
          const errorMessage =
            followAction === 'follow' ? 'ERROR.FANPAGE.FOLLOW_FANPAGE_FAILED' : 'ERROR.FANPAGE.UNFOLLOW_FANPAGE_FAILED';
          this.followerService[serviceMethod]('page', this.customData.id).subscribe(res => {
            if (res.data) {
              const dataUpdate = {
                typeEdit: TAGS.FANPAGE,
                role: followAction === 'follow' ? 'FOLLOWER' : null,
                followers: this.customData.followers
                  ? followAction === 'follow'
                    ? this.customData.followers + 1
                    : this.customData.followers - 1
                  : 1
              };
              this.updateItemSearchResult.emit(dataUpdate);
              this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, successMessage);
            } else {
              this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, errorMessage);
            }
            this.isLoadingFlow = false;
          });
          break;
        case this.tags.GROUP:
          this.isLoadingJoin = true;
          if (!this.customData.pending_request) {
            this.groupService.groupJoin(this.customData.id).subscribe(res => {
              if (res.success) {
                const dataUpdate = {
                  typeEdit: TAGS.GROUP,
                  pending_request: true
                };
                this.updateItemSearchResult.emit(dataUpdate);
                this.isLoadingJoin = false;
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.success,
                  'GROUP.MESSAGE.SENT_GROUP_REQUEST_SUCCESSFULLY'
                );
              } else {
                this.isLoadingJoin = false;
                this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, res.error.message);
              }
            });
          } else {
            this.groupService.putCancelRequestGroup(this.customData.id).subscribe((res: any) => {
              if (res.success) {
                const dataUpdate = {
                  typeEdit: TAGS.GROUP,
                  pending_request: false
                };
                this.isLoadingJoin = false;
                this.updateItemSearchResult.emit(dataUpdate);
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.success,
                  'GROUP.MESSAGE.CANCEL_GROUP_REQUEST_SUCCESSFULLY'
                );
              } else {
                this.isLoadingJoin = false;
                this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, res.error.message);
              }
            });
          }
          break;
        default:
          break;
      }
    }
  }
}
