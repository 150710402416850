import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';

export class FriendApiTemp {
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getMethod(url: string): Observable<any> {
    return this.http.get(url).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  postMethod(url: string, body: any): Observable<any> {
    return this.http.post(url, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  putMethod(url: string, body: any): Observable<any> {
    return this.http.put(url, body).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteMethod(url: string): Observable<any> {
    return this.http.delete(url).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
