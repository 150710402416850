import { createAction, emptyProps, props } from '@ngrx/store';

export enum CoinTypes {
  getAvailableCoinAmounts = '[Coins] get available coin amount',
  getAvailableCoinAmountsSuccess = '[Coins] get available coin amount success'
}

export const getAvailableCoinAmounts = createAction(CoinTypes.getAvailableCoinAmounts, emptyProps);

export const getAvailableCoinAmountsSuccess = createAction(
  CoinTypes.getAvailableCoinAmountsSuccess,
  props<{ availableCoinAmounts: any }>()
);
