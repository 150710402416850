import { HttpClient } from '@angular/common/http';
import { ActionType, ActivityLog } from '@app/shared/models/activity-log';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { ActivityLogParams } from './api.activity-log.model';

export class ActivityLogApi {
  private apiUrl: string = environment.baseURL;

  ACTIVITY_LOG = '/activity-log';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getActivityLog(params: ActivityLogParams): Observable<ActivityLog[]> {
    const endpoint = `${this.apiUrl}${this.ACTIVITY_LOG}/me`;
    return this.http
      .get<ActivityLog[]>(endpoint, {
        params: {
          ...params
        }
      })
      .pipe(
        retry(1),
        map((res: any) => {
          return res.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getActionTypes(): Observable<ActionType[]> {
    const endpoint = `${this.apiUrl}${this.ACTIVITY_LOG}/action-types`;
    return this.http.get<ActivityLog[]>(endpoint).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
