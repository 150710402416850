import { EventApiModels } from '@app/lib/api/event/api.event.models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventService } from '@app/core/services/event.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT } from '@app/shared/constant';

@Component({
  selector: 'event-invite-dialog',
  templateUrl: './event-invite-dialog.component.html',
  styleUrls: ['./event-invite-dialog.component.scss']
})
export class EventInviteDialogComponent {
  @Input() isVisible = false;
  @Input() eventId = '';
  @Input() objectId = '';
  @Output() isVisibleChange = new EventEmitter();
  @Output() inviteResults = new EventEmitter();

  inviteFriendList: EventApiModels.NonAttendingFriendInfo[];
  friendCheckboxes: boolean[];
  isLoadingFriends = false;
  invitingFriends = false;
  friendListPageNum = 0;
  friendListLastPage = false;
  searchFriendKeyword = '';
  isLoadingMoreFriends = false;

  constructor(
    private eventService: EventService,
    private toastMessageService: ToastMessageService
  ) {}

  onVisibleChange(event: any): void {
    this.isVisibleChange.emit(event);
  }

  onShowDialog(): void {
    if (this.inviteFriendList && this.inviteFriendList.length) {
      this.friendCheckboxes = new Array(this.inviteFriendList.length).fill(false);
    } else {
      this.friendListPageNum = 0;
      if (this.eventId) {
        this.isLoadingFriends = true;
        setTimeout(() => {
          this.getFriendList();
        }, 200);
      }
    }
  }

  getFriendList(): void {
    this.eventService.getNonAttendingFriendList(this.eventId).subscribe((res: any) => {
      if (res && res.data) {
        this.isLoadingFriends = false;
        this.inviteFriendList = res.data;
        this.friendListLastPage = res.data.length < PAGE_SIZE_DEFAULT;
        this.friendCheckboxes = new Array(res.data.length).fill(false);
      }
    });
  }

  onSearchFriends(data: { searchKeyword: string; selectedFriends: any[] }): void {
    this.searchFriendKeyword = data.searchKeyword;
    this.friendListPageNum = 0;
    this.friendListLastPage = false;
    const userIdList = data.selectedFriends.map(item => item.id);
    this.isLoadingFriends = true;
    this.eventService
      .getNonAttendingFriendList(this.eventId, PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, data.searchKeyword)
      .subscribe((res: any) => {
        if (res && res.data) {
          this.isLoadingFriends = false;
          this.inviteFriendList = res.data;
          let friendCheckboxesInit = new Array(res.data.length).fill(false);
          res.data.forEach((item: any, index: number) => {
            friendCheckboxesInit[index] = userIdList.includes(item.id);
          });
          this.friendCheckboxes = friendCheckboxesInit;
        }
      });
  }

  loadMoreFriends(): void {
    if (!this.isLoadingMoreFriends && !this.friendListLastPage && !this.isLoadingFriends) {
      this.isLoadingMoreFriends = true;
      this.eventService
        .getNonAttendingFriendList(
          this.eventId,
          this.friendListPageNum + 1,
          PAGE_SIZE_DEFAULT,
          this.searchFriendKeyword
        )
        .subscribe((res: any) => {
          if (res && res.data) {
            this.friendListPageNum++;
            this.isLoadingMoreFriends = false;
            this.friendListLastPage = res.data.length < PAGE_SIZE_DEFAULT;
            this.inviteFriendList = [...this.inviteFriendList, ...res.data];
            this.friendCheckboxes = [...this.friendCheckboxes, ...new Array(res.data.length).fill(false)];
          }
        });
    }
  }

  onSendInviteToFriends(selectedFriends: any[]): void {
    if (this.objectId && this.eventId) {
      const userIdList = selectedFriends.map(item => item.id);
      this.invitingFriends = true;
      this.eventService.inviteFriendToEvent(this.objectId, this.eventId, userIdList).subscribe(success => {
        this.invitingFriends = false;
        this.toastMessageService.addToastMessageApiResponse(
          success,
          'EVENT.MESSAGE.INVITE_FRIEND_SUCCESSFULLY',
          'EVENT.MESSAGE.INVITE_FRIEND_FAILED'
        );
        if (success) {
          this.isVisible = false;
          this.isVisibleChange.emit(false);
          this.inviteFriendList = [];
          this.friendCheckboxes = new Array(this.inviteFriendList.length).fill(false);
        } else {
        }
      });
    }
  }
}
