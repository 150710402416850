<p-dialog *ngIf="!isViewArchive" [draggable]="false" [resizable]="false" [styleClass]="isModerateStory? 'w-full custom-style-view-comment': 'w-full h-4/5'" [class]="activeIndex === 1 ? 'padding-footer': 'no-padding'" position="bottom"
  [header]="myStory ? ('STORY.YOUR_STORY' | translate) : ('ENTITY.COMMENTS' | translate)" [(visible)]="visibleViewers"
  (onHide)="onHide()">
  <ng-container *ngIf="myStory" [ngTemplateOutlet]="myStoryTemplate"></ng-container>
  <ng-container *ngIf="!myStory" [ngTemplateOutlet]="userStory"></ng-container>
  <ng-template *ngIf="!isModerateStory" pTemplate="footer">
    <comment-form *ngIf="activeIndex !== 0 || !myStory" [isStory]="true" [loginUser]="userInfo$ | async"
      (valueComment)="addComment($event)"></comment-form>
  </ng-template>
</p-dialog>
<!-- Case show view -->
<div class="w-full h-full" *ngIf="isViewArchive">
  <p class="text-lg font-semibold text-palette-gray-900 p-4">{{'STORY.STORIES_DETAILS' | translate}}</p>
  <ng-container [ngTemplateOutlet]="myStoryTemplate"></ng-container>
</div>
<!-- My story template -->
<ng-template #myStoryTemplate>
  <div class="flex gap-2 max-w-full overflow-auto px-4 pb-4 cursor-pointer" *ngIf="!isViewArchive">
    <div *ngFor="let item of storiesByUser; let i = index">
      <div class="relative w-[100px] h-40 rounded-2xl"
      [ngStyle]="{ 'background-image': item.story_media.background_image ? 'url(' + validUrl(item.story_media.background_image || '', fileType.thumbnail) + ')' : 'none',
      'background-size': 'cover',
      'background-position': 'center',
      'background-color': !item.story_media.background_image ? 'gray' : 'bg-transparent' }"

      [ngClass]="{
        'border-2 border-solid border-palette-blue-600 p-0.5': i === transferContent
      }" (click)="toggleImageSelection(i)">
        <img defaultImage *ngIf="item.story_type === mediaType.image" class="object-cover w-full h-full rounded-2xl" [src]="validUrl(item.story_media.image_url || '' , fileType.thumbnail)" />
        <video *ngIf="item.story_type === mediaType.video" [autoplay]="false"
          [src]="validUrl(item.story_media.video_url || '' , fileType.streamVideo)" class="h-full w-full rounded-2xl object-cover"></video>
      </div>
    </div>
  </div>
  <p-tabView class="relative" [(activeIndex)]="activeIndex" [ngClass]="{'max-h-tabview': !isViewArchive, 'max-h-tabview-archive': isViewArchive}">
    <p-tabPanel [header]="'STORY.VIEWERS' | translate">
      <span class="font-medium text-sm text-palette-gray-700">{{ listViewer.length || 0 }} {{
        (listViewer.length || 0) > 1 ? ('STORY.VIEWERS' | translate | lowercase) : ('STORY.VIEWER'
        | translate | lowercase) }}</span>
      <div *ngIf="listViewer.length">
        <div *ngFor="let item of listViewer">
          <div class="mt-3 flex justify-between items-center">
            <div class="flex items-center gap-2">
              <avatar
                [avatarSize]="38"
                [userInfo]="item.created_by"></avatar>
              <span class="text-sm font-medium text-palette-gray-700">{{ item.created_by.full_name }}</span>
              <div class="flex justify-center items-center relative w-[18px] h-[18px]"
                *ngIf="item.created_by.is_verified_user && item.created_by.is_verified_user">
                <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
                <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/[16px]"></i>
              </div>
            </div>
            <img *ngIf="item.reaction_type !== null && item.reaction_type !== 'VIEW'" class="w-[18px] h-[18px]" [src]="getEmojiIcon(item.reaction_type)"
              alt="" />
            </div>
          </div>
          <ng-container *ngIf="loading">
            <div *ngFor="let _ of [].constructor(2)">
              <div class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="2.5rem" styleClass="mr-2" />
                  <div style="flex: 1">
                    <p-skeleton width="100%" height="2.5rem" styleClass="mb-2" />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel [header]="'ENTITY.COMMENTS' | translate">
      <span class="font-medium text-sm text-palette-gray-700">{{totalCommentsAndReplies}} {{ totalCommentsAndReplies >
        1 ? ('ENTITY.COMMENTS' | translate | lowercase) :
        ('ENTITY.COMMENT' | translate | lowercase) }}</span>
      <div *ngFor="let comment of comments; let i = index">
        <comment-tree *ngIf="!isCommentHidden(comment.id)" [isWidthFull]="true" (deletedCommentId)="deleteComment($event)"
          (increaseTotal)="onIncreaseTotal($event)" [loginUser]="userInfo$ | async" [comment]="comment"
          [postOwnerId]="storiesByUser[0].user_id" [isStory]="true"></comment-tree>
        <ng-container *ngIf="loading">
          <div *ngFor="let _ of [].constructor(2)">
            <div class="mb-3">
              <div class="flex">
                <p-skeleton shape="circle" size="2.5rem" styleClass="mr-2" />
                <div style="flex: 1">
                  <p-skeleton width="100%" height="2.5rem" styleClass="mb-2" />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-template>
<!-- User story template -->
<ng-template #userStory>
  <div *ngIf="!isModerateStory" class="px-4">
    <span class="font-medium text-sm text-palette-gray-700">{{totalCommentsAndReplies}} {{ totalCommentsAndReplies >
      1 ? ('ENTITY.COMMENTS' | translate | lowercase) :
      ('ENTITY.COMMENT' | translate | lowercase) }}</span>
    <div *ngFor="let comment of comments; let i = index">
      <comment-tree *ngIf="!isCommentHidden(comment.id)" [isWidthFull]="true" (deletedCommentId)="deleteComment($event)"
        [storyId]="storiesByUser[transferContent].id"
        (increaseTotal)="onIncreaseTotal($event)" [loginUser]="userInfo$ | async" [comment]="comment"
        [postOwnerId]="storiesByUser[0].user_id" [isStory]="true"></comment-tree>
    </div>
  </div>
  <div *ngIf="isModerateStory && comment" class="my-3 mx-4 p-2 rounded-lg border border-palette-red-600 max-h-min">
    <comment-tree [isWidthFull]="true"
      [comment]="comment"
      [postOwnerId]="storiesByUser[0].user_id" [isStory]="true"></comment-tree>
  </div>
</ng-template>
