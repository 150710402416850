import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { TOAST_MESSAGE_LIFETIME_DURATIONS, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import QRCodeStyling from 'qr-code-styling';

@Component({
  selector: 'invite-friend',
  templateUrl: './invite-friend.component.html',
  styleUrls: ['./invite-friend.component.scss']
})
export class InviteFriendComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @ViewChild('qrCodeStyle') qrCodeStyle: ElementRef;
  invitedLink: string;
  invitedCode = 'FBD114GHV';
  qrCode: any;
  appName = environment.APP_NAME;
  ContentInviteFriends: any[] = [
    {
      title: 'Voucher Buy 1 Get 1 at Addidas Store on TMA Traveller',
      src: '../../../../assets/images/Img.svg',
      day: '30/6/2025'
    },
    {
      title: 'Voucher Buy 1 Get 1 at Addidas Store on TMA Traveller',
      src: '../../../../assets/images/Img.svg',
      day: '30/6/2025'
    },
    {
      title: 'Voucher Buy 1 Get 1 at Addidas Store on TMA Traveller',
      src: '../../../../assets/images/Img.svg',
      day: '30/6/2025'
    },
    {
      title: 'Voucher Buy 1 Get 1 at Addidas Store on TMA Traveller',
      src: '../../../../assets/images/Img.svg',
      day: '30/6/2025'
    },
    {
      title: 'Voucher Buy 1 Get 1 at Addidas Store on TMA Traveller',
      src: '../../../../assets/images/Img.svg',
      day: '30/6/2025'
    }
  ];
  Instruction: any[] = [
    {
      step: this.TranslationService.getTranslationWithParams('XU.STEP_NUMBER', { index: 1 }),
      src: '../../../../assets/images/Illu.png',
      content: 'XU.DOWLOAD_OR_ACCESS'
    },
    {
      step: this.TranslationService.getTranslationWithParams('XU.STEP_NUMBER', { index: 2 }),
      src: '../../../../assets/images/Illu2.png',
      content: 'XU.SIGN_UP_NEW_ACCOUNT'
    },
    {
      step: this.TranslationService.getTranslationWithParams('XU.STEP_NUMBER', { index: 3 }),
      src: '../../../../assets/images/Illu3.png',
      content: 'XU.INPUT_INVITE_CODE'
    },
    {
      step: this.TranslationService.getTranslationWithParams('XU.STEP_NUMBER', { index: 4 }),
      src: '../../../../assets/images/Illu4.png',
      content: 'XU.RECEIVE_YOUR_COIN'
    }
  ];
  constructor(
    private toastMessageService: ToastMessageService,
    private TranslationService: TranslationService,
    private store: Store
  ) {}
  ngOnInit() {
    let urlStore = `${environment.SOCIAL_APP_URL}/sign-up`;
    this.store.select(selectUserInfo).subscribe(res => {
      (this.invitedCode = res.invite_code),
        (this.invitedLink = environment.SOCIAL_APP_URL + `/sign-up?invite-code=${res.invite_code}`);
    });
    this.qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      type: 'svg',
      data: urlStore,
      image: '/assets/images/soctrip-branding/soctrip-logo-blue.svg',
      margin: 6,
      qrOptions: {
        mode: 'Byte',
        errorCorrectionLevel: 'H'
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.7,
        margin: 5,
        crossOrigin: 'anonymous'
      },
      dotsOptions: {
        color: '#000',
        type: 'extra-rounded'
      },
      backgroundOptions: {
        color: '#fff'
      },
      cornersSquareOptions: {
        color: '#000'
      },
      cornersDotOptions: {
        color: '#000'
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['visible']) {
      setTimeout(() => {
        this.qrCode.append(this.qrCodeStyle?.nativeElement);
      }, 0);
    }
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  handleCopyClick(val: string) {
    navigator.clipboard.writeText(val);
    this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, 'COMMON.COPIED_TO_CLIPBOARD', TOAST_MESSAGE_LIFETIME_DURATIONS.short);
  }
}
