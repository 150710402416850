import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CommonService } from '@app/core/services/common.service';
import { LocationService } from '@app/core/services/location.service';
import { MediaService } from '@app/core/services/media.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { EventApiModels } from '@app/lib/api/event/api.event.models';
import { LocationApiModels } from '@app/lib/api/map-location/api.map-location.models';
import { GroupInterfaceState } from '@app/modules/main/group/store/reducer';
import { ItemsAutoComplete } from '@app/modules/main/personal-profile/components/personal-profile-groups/components/auto-complete/auto-complete.component';
import { ASPECT_RATIO, SEARCH_DEBOUNCE_TIME, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { AutoComplete } from 'primeng/autocomplete';
import { Dropdown } from 'primeng/dropdown';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'create-event',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './modal-create-event.component.html',
  styleUrls: ['./modal-create-event.component.scss']
})
export class ModalCreateEventComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() isSubmitting = false;
  @Input() allFieldsFilled: boolean = false;
  @Input() eventMembers: any[];
  @Input() groupPrivacy: string | undefined;
  @Input() isFanPagePublicEvent = false;
  @Input() isGroupPublicEvent = false;
  @Input() oldDataEvent: EventApiModels.EventDetails | undefined;
  @Output() actionCloseModal = new EventEmitter();
  @Output() handleCreate = new EventEmitter();
  @Output() handleEdit = new EventEmitter();
  selectedOption: string = '1';
  eventName: string = '';
  eventLink: string = '';
  eventAddress: string = '';
  listFriends: any[];
  itemsAutoComplex: ItemsAutoComplete[];
  checked: boolean = false;
  startDate: Date | null;
  endDate: Date | null;
  invalidDate: Date = new Date(3000, 0, 1); // Ngày "không hợp lệ"
  startTime: Date | null;
  endTime: Date | null;
  minStartDate: Date;
  textareaValue: string = '';
  selectedOptionPrice: any;
  isShowIconSearch: boolean = false;
  isShowAddress: boolean = true;
  placeholder: string = 'Choose host';
  defaultImageUrl = 'assets/images/event-default-image.png';
  selectedImage: string | ArrayBuffer | null = null;
  bannerFile: File;
  eventNameId: FormControl = new FormControl('', [Validators.required]);
  showError: boolean = false;
  avatarBanner: any;
  isShowErrorTime: boolean = false;
  showBannerGallery: boolean = false;
  isShowErrorEndTime: boolean = false;
  isEndTimeBeforeStartTime: boolean = false;
  backgroundUrl?: NgxCroppedEvent | string;
  @ViewChild('autoComplete') autoComplete: AutoComplete;
  @ViewChild('addressDropdown') addressDropdown: Dropdown;
  baseUrl: string = environment.baseURL;
  filteredFriends: any[];
  isShowErrorEventLink: boolean = false;
  dataFriendsClone: any[] = [];
  selectedFriends: any[] = [];
  index1: number;
  index2: number;
  showDropdowns1: boolean = false;
  showDropdowns2: boolean = false;
  selectTime: string | null = null;
  dropdownOptions = [
    { name: 'COMMON.PRIVACY.PUBLIC', icon: 'sctr-icon-globe-04 text-lg', value: 1 },
    { name: 'COMMON.PRIVACY.PRIVATE', icon: 'sctr-icon-user-01 text-lg', value: 2 }
  ];
  privacy = this.dropdownOptions[0];
  addressList: LocationApiModels.SearchPlaceDestination[] = [];
  suggestedPlaces: LocationApiModels.SearchPlaceDestination[] = [];
  searchAddressSubject = new Subject<string>();
  selectedAddress: LocationApiModels.SearchPlaceDestination | null;
  isSearchingAddress = false;
  startTimePickerVisibility = false;
  endTimePickerVisibility = false;
  //handle edit events
  isEditButtonEnabled = false;

  constructor(
    private mediaService: MediaService,
    private service: NgxPhotoEditorService,
    private TranslationService: TranslationService,
    private commonService: CommonService,
    private toastMessageService: ToastMessageService,
    private locationService: LocationService,
    private store: Store<GroupInterfaceState>
  ) {
    this.searchAddressSubject.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(value => {
      this.searchLocationList(value);
    });
    this.minStartDate = new Date();
    this.endDate = null;
  }

  ngOnInit(): void {
    this.dropdownOptions = this.dropdownOptions.map((item: any) => {
      return { ...item, name: this.TranslationService.getTranslation(item.name || '') };
    });
    this.setInitialValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['eventMembers']) {
      this.filteredFriends = changes['eventMembers'].currentValue;
    }

    if (changes['visible']) {
      (this.eventName = ''),
        (this.listFriends = []),
        (this.textareaValue = ''),
        (this.startDate = null),
        (this.startTime = null),
        (this.endDate = null),
        (this.endTime = null),
        (this.checked = false);
      this.isShowErrorEndTime = false;
      this.selectedFriends = [];
      if (this.groupPrivacy === 'Private') {
        this.privacy = this.dropdownOptions[1];
      }
      if (this.oldDataEvent) {
        const oldEvent = this.oldDataEvent;
        const userProfileId = JSON.parse(localStorage.getItem('user_profile') || '{}').id;
        const listUser = oldEvent.hosts_info.filter(user => user.id !== userProfileId);
        const listFriendSelected = listUser.map(user => ({ user_id: user.id, ...user.user_info }));

        this.eventName = oldEvent.name;
        this.startDate = new Date(oldEvent.start_date_time);
        this.endDate = new Date(oldEvent.end_date_time);
        this.startTime = new Date(oldEvent.start_date_time);
        this.endTime = new Date(oldEvent.end_date_time);
        if (oldEvent.address) {
          this.selectedAddress = {
            main_text: oldEvent.address.address,
            lat: oldEvent.address.latitude,
            lon: oldEvent.address.longitude,
            addr: ''
          } as LocationApiModels.SearchPlaceDestination;
          this.searchLocationList(oldEvent.address.address);
        }
        this.selectedOption = `${oldEvent.type}`;
        this.eventLink = oldEvent.link || '';
        this.selectedFriends = listFriendSelected;
        this.privacy = this.dropdownOptions.find(item => item.value === oldEvent.privacy) || this.dropdownOptions[0];
        this.textareaValue = oldEvent.description;
        this.backgroundUrl = this.getImgUrl(oldEvent.avatar);
        if (this.filteredFriends && this.filteredFriends.length) {
          listFriendSelected.forEach(friend => {
            this.filteredFriends = this.filteredFriends.filter(fe => fe.user_id !== friend.user_id);
          });
        }
      }
    }
  }

  disableEventAction(): boolean {
    return (
      !this.allFieldsFilled ||
      this.isEndTimeBeforeStartTime ||
      this.isShowErrorEndTime ||
      this.isShowErrorEventLink ||
      this.isShowErrorTime ||
      this.showError
    );
  }

  handleStartTime(event: Date): void {
    this.startTime = event;
    this.checkErrorTwoDate();
    this.checkChangesForEdit();
    let typeOption = parseInt(this.selectedOption);
    if (typeOption === 1) {
      this.allFieldsFilled =
        !!this.eventName &&
        !!this.selectedAddress &&
        !!this.startDate &&
        !!this.startTime &&
        !!this.endDate &&
        !!this.endTime &&
        !this.isShowErrorTime;
    } else {
      this.allFieldsFilled =
        !!this.eventName && !!this.startDate && !!this.endDate && !!this.endTime && !!this.eventLink;
    }
  }

  handleEndTime(event: Date): void {
    this.endTime = event;
    this.checkErrorTwoDate();
    this.checkChangesForEdit();
    let typeOption = parseInt(this.selectedOption);
    if (typeOption === 1 && this.endDate) {
      this.allFieldsFilled =
        !!this.eventName &&
        !!this.selectedAddress &&
        !!this.startDate &&
        !!this.endDate &&
        !this.isShowErrorEndTime &&
        !!this.startTime &&
        !this.isShowErrorTime;
    } else {
      this.allFieldsFilled = !!this.eventName && !!this.startDate && !!this.eventLink;
    }
  }

  handleCloseModal() {
    this.actionCloseModal.emit(false);
    this.showError = false;
    this.eventName = '';
    this.selectedOption = '1';
    this.selectedImage = null;
    this.allFieldsFilled = false;
    this.isShowErrorTime = false;
    this.eventLink = '';
    this.selectTime = '';
    this.selectedFriends = [];
    this.selectedAddress = null;

    this.startTimePickerVisibility = false;
    this.endTimePickerVisibility = false;
  }

  checkAllFields() {
    if (this.eventLink) {
      const linkRegex = /^(http(s)?:\/\/)?[\w.-]+\.[a-z]{2,4}/;
      linkRegex.test(this.eventLink) ? (this.isShowErrorEventLink = false) : (this.isShowErrorEventLink = true);
    }
    this.checkErrorTwoDate();
    let typeOption = parseInt(this.selectedOption);
    if (typeOption === 1) {
      this.allFieldsFilled =
        !!this.eventName &&
        !!this.selectedAddress?.addr &&
        !!this.startDate &&
        !!this.startTime &&
        !!this.endDate &&
        !!this.endTime &&
        !this.isShowErrorTime;
    } else {
      this.allFieldsFilled =
        !!this.eventName &&
        !!this.startDate &&
        !!this.eventLink &&
        !!this.endDate &&
        !!this.endTime &&
        !this.isShowErrorEventLink;
    }
    this.eventNameId.setValue(this.eventName);
    this.showError = this.eventNameId?.invalid;
  }

  handleEmitEvent(param: any) {
    if (this.oldDataEvent) {
      this.handleEdit.emit({ ...param, id: this.oldDataEvent.id });
    } else {
      this.handleCreate.emit(param);
    }
  }

  handleActionEvent() {
    this.allFieldsFilled = false;
    let typeOption = parseInt(this.selectedOption);
    const address: EventApiModels.EventAddress = {
      address:
        this.selectedAddress?.main_text === this.selectedAddress?.addr
          ? this.selectedAddress?.addr || ''
          : `${this.selectedAddress?.main_text || ''} - ${this.selectedAddress?.addr || ''}`,
      latitude: this.selectedAddress?.lat || null,
      longitude: this.selectedAddress?.lon || null
    };
    let idFriends = this.selectedFriends?.map(item => (item.user_id ? item.user_id : item.id));
    const formatStartDate = moment(this.startDate).format('DD/MM/YYYY');
    const formatStartTime = moment(this.startTime).format('HH:mm');
    const startDateTimeString = `${formatStartDate} ${formatStartTime}`;
    const timestampStartDateTime = moment(startDateTimeString, 'DD/MM/YYYY HH:mm').valueOf();
    const formattedDate = moment(this.endDate).format('DD/MM/YYYY');
    const formattedTime = moment(this.endTime).format('HH:mm');
    const dateTimeString = `${formattedDate} ${formattedTime}`;
    const timestampEndDateTime = moment(dateTimeString, 'DD/MM/YYYY HH:mm').valueOf();
    if (this.selectedImage) {
      const formData = new FormData();
      formData.append('files', this.bannerFile);
      this.mediaService.uploadFile(formData).subscribe({
        next: ({ data }) => {
          if (data) {
            if (typeOption === 1) {
              let param = {
                type: typeOption,
                name: this.eventName,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                hosts: idFriends,
                address: address,
                avatar: '/storage/files/web/' + data[0].id,
                start_date_time: timestampStartDateTime,
                end_date_time: timestampEndDateTime,
                privacy: this.privacy.value,
                description: this.textareaValue
              };
              this.handleEmitEvent(param);
            } else {
              let param = {
                type: typeOption,
                name: this.eventName,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                hosts: idFriends,
                link: this.eventLink,
                avatar: '/storage/files/web/' + data[0].id,
                start_date_time: timestampStartDateTime,
                end_date_time: timestampEndDateTime,
                privacy: this.privacy.value,
                description: this.textareaValue
              };
              this.handleEmitEvent(param);
            }
          } else {
            this.toastMessageService.addToastMessage(
              TOAST_MESSAGE_SEVERITY_LEVELS.error,
              this.TranslationService.getTranslation('EVENT.BANNER_CANNOT_BE_EMPTY')
            );
          }
        },
        error: e => {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            this.TranslationService.getTranslation('EVENT.AN_ERROR_OCCURRED_WHILE_UPLOADING_THE_FILE')
          );
        }
      });
    } else {
      if (typeOption === 1) {
        let param = {
          type: typeOption,
          name: this.eventName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          hosts: idFriends,
          address: address,
          avatar: this.oldDataEvent?.avatar,
          start_date_time: timestampStartDateTime,
          end_date_time: timestampEndDateTime,
          privacy: this.privacy.value,
          description: this.textareaValue
        };
        this.handleEmitEvent(param);
      } else {
        let param = {
          type: typeOption,
          name: this.eventName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          hosts: idFriends,
          link: this.eventLink,
          avatar: this.oldDataEvent?.avatar,
          start_date_time: timestampStartDateTime,
          end_date_time: timestampEndDateTime,
          privacy: this.privacy.value,
          description: this.textareaValue
        };
        this.handleEmitEvent(param);
      }
    }
  }

  onFileSelected($event: any) {
    this.service
      .open($event, {
        aspectRatio: ASPECT_RATIO.landscapeEvent,
        autoCropArea: 1
      })
      .subscribe((data: any) => {
        this.backgroundUrl = data.base64;
        const file = $event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = e => {
            this.selectedImage = e.target?.result || null;
          };
          reader.readAsDataURL(file);
          this.bannerFile = data.file;
        } else {
          this.selectedImage = null;
        }
      });
  }

  // friend
  onFriendSelect(event: any) {
    const selectedFriendIndex = this.selectedFriends.findIndex(friend => friend.user_id === event.user_id);
    if (selectedFriendIndex !== -1) {
      // Đã chọn, xóa khỏi danh sách filteredFriends
      const filteredFriendIndex = this.filteredFriends.findIndex(friend => friend.user_id === event.user_id);
      if (this.dataFriendsClone.length) {
        if (filteredFriendIndex !== -1) {
          this.dataFriendsClone = this.filteredFriends.filter(
            ftId => ftId.user_id !== this.filteredFriends[filteredFriendIndex].user_id
          );
        }
      } else {
        if (filteredFriendIndex !== -1) {
          this.filteredFriends = this.filteredFriends.filter(
            ftId => ftId.user_id !== this.filteredFriends[filteredFriendIndex].user_id
          );
        }
      }
    } else {
      // Chưa chọn, thêm vào danh sách selectedFriends
      this.selectedFriends.push(event);
    }
  }

  showDropdownFriend(autoComplete: AutoComplete) {
    autoComplete.overlayVisible = !autoComplete.overlayVisible;
    this.filteredFriends = this.filteredFriends;
  }

  filterFriend(event: any) {
    let filteredFriend: any[] = [];
    this.dataFriendsClone = this.filteredFriends;
    let query = event.query.toLowerCase();
    for (let i = 0; i < this.filteredFriends.length; i++) {
      let friend = this.filteredFriends[i];
      const isSelected = this.selectedFriends.some(selectedFriend => selectedFriend.user_id === friend.user_id);
      if (friend?.user_info) {
        if (!isSelected && friend.user_info.full_name.toLowerCase().indexOf(query) === 0) {
          filteredFriend.push(friend);
        }
      } else {
        if (!isSelected && friend.full_name.toLowerCase().indexOf(query) === 0) {
          filteredFriend.push(friend);
        }
      }
    }
    this.dataFriendsClone = filteredFriend;
  }

  onFriendUnSelect(event: any) {
    this.filteredFriends.push(event);
  }

  handleInputChangeFriend(event: any) {
    if (!event.target.value) {
      this.dataFriendsClone = this.eventMembers;
      this.filteredFriends = this.eventMembers;
    }
  }

  handleCloseDialog() {
    if (this.showDropdowns1) {
      this.showDropdowns1 = false;
    } else this.showDropdowns2 = false;
  }

  handleShowDialog(event: Event, index: number) {
    event.stopPropagation();
    if (index === 1) {
      this.index1 = index;
      this.showDropdowns1 = true;
      this.showDropdowns2 = false;
    } else {
      this.index2 = index;
      this.showDropdowns2 = true;
      this.showDropdowns1 = false;
    }
  }

  onVisibleTimePickerChange(visible: boolean, index: number): void {
    if (visible) {
      if (index === 1) {
        this.endTimePickerVisibility = false;
      } else {
        this.startTimePickerVisibility = false;
      }
    }
  }

  getImgUrl(imgUrl: string): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${environment.baseURL}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  handleCheckDatePickerStartDate(event: any) {
    this.startDate = event;
    this.checkAllFields();
    this.checkChangesForEdit();
  }
  handleCheckDatePickerEndDate(event: any) {
    this.endDate = event;
    this.checkAllFields();
    this.checkChangesForEdit();
  }

  checkErrorTwoDate() {
    if (this.startDate && this.endDate && this.startTime && this.endTime) {
      const currentDate = new Date();

      this.isEndTimeBeforeStartTime =
        this.startDate.toDateString() === this.endDate.toDateString() && this.startTime >= this.endTime;

      if (!this.isEndTimeBeforeStartTime) this.isEndTimeBeforeStartTime = this.startDate > this.endDate;

      if (this.startDate.toDateString() !== this.endDate.toDateString() && !this.isEndTimeBeforeStartTime) {
        if (this.startDate.toDateString() === currentDate.toDateString())
          this.isShowErrorTime = this.startTime <= currentDate;
        else this.isShowErrorTime = false;

        if (this.endDate.toDateString() === currentDate.toDateString())
          this.isShowErrorEndTime = this.endTime <= currentDate;
        else this.isShowErrorEndTime = false;
      }

      if (this.isShowErrorTime || this.isShowErrorEndTime || this.isEndTimeBeforeStartTime)
        this.allFieldsFilled = false;
    }
  }

  onChangeSearchKey(event: string | null): void {
    if (typeof event === 'string' && event.length) {
      this.searchAddressSubject.next(event);
      this.isSearchingAddress = true;
    } else {
      this.searchAddressSubject.next('');
      this.selectedAddress = null;
      this.checkAllFields();
      this.addressDropdown.hide();
    }
  }

  onFocusAddress(event: any): void {
    if (this.addressList.length) {
      this.addressDropdown.show();
    } else {
      this.getPlacesByCoordinates();
    }
  }

  showSuggestedPlaces(): void {
    if (this.suggestedPlaces.length && !this.selectedAddress) {
      this.addressList = this.suggestedPlaces;
      this.addressDropdown.show();
    }
  }

  getPlacesByCoordinates(): void {
    if (navigator.geolocation && !this.suggestedPlaces.length) {
      navigator.geolocation.getCurrentPosition(position => {
        this.isSearchingAddress = true;
        this.locationService
          .searchPlaceByCoordinates(position.coords.latitude, position.coords.longitude)
          .subscribe(res => {
            if (res && res.data && res.data.length) {
              this.suggestedPlaces = res.data.map(data => {
                return {
                  ...data,
                  address: `${!!data.main_text ? data.main_text + ' ' : ''}${data.addr || ''}`
                };
              });
              this.addressList = this.suggestedPlaces;
              this.addressDropdown.show();
              this.isSearchingAddress = false;
            }
          });
      });
    } else {
      this.showSuggestedPlaces();
    }
  }

  searchLocationList(searchKey: string): void {
    if (searchKey.length) {
      this.locationService.searchLocation(searchKey, 0, 15).subscribe(res => {
        if (res) {
          const addressList: any[] = [];
          res.destinations &&
            res.destinations.data.forEach(data =>
              addressList.push({
                ...data,
                address: `${!!data.main_text ? data.main_text + ' ' : ''}${data.addr || ''}`
              })
            );
          res.places &&
            res.places.data.forEach(data =>
              addressList.push({
                ...data,
                address: `${!!data.main_text ? data.main_text + ' ' : ''}${data.addr || ''}`
              })
            );
          this.addressList = addressList;
        }
        this.isSearchingAddress = false;
      });
    } else {
      this.showSuggestedPlaces();
    }
  }

  setInitialValues() {
    if (this.oldDataEvent) {
      this.eventName = this.oldDataEvent.name || '';
      this.eventLink = this.oldDataEvent.link || '';
      this.textareaValue = this.oldDataEvent.description || '';
      this.startDate = new Date(this.oldDataEvent.start_date_time);
      this.endDate = new Date(this.oldDataEvent.end_date_time);

      if (typeof this.oldDataEvent.privacy === 'number') {
        this.privacy =
          this.dropdownOptions.find(option => option.value === this.oldDataEvent?.privacy) || this.dropdownOptions[0];
      } else {
        this.privacy = this.oldDataEvent.privacy || this.dropdownOptions[0];
      }

      this.isEditButtonEnabled = false;
    }
  }

  checkChangesForEdit() {
    if (this.oldDataEvent) {
      const startDateChanged = this.startDate?.getTime() !== new Date(this.oldDataEvent.start_date_time).getTime();
      const endDateChanged = this.endDate?.getTime() !== new Date(this.oldDataEvent.end_date_time).getTime();
      const eventNameChanged = this.eventName !== this.oldDataEvent.name;
      const eventAddressChanged = this.selectedAddress?.main_text !== this.oldDataEvent?.address.address;
      const eventLinkChanged = this.selectedOption === '2' ? this.eventLink !== this.oldDataEvent.link : false;
      const descriptionChanged = this.textareaValue !== this.oldDataEvent.description;
      const privacyChanged = this.privacy?.value !== this.oldDataEvent.privacy;

      const changesDetected =
        startDateChanged ||
        endDateChanged ||
        eventNameChanged ||
        eventLinkChanged ||
        eventAddressChanged ||
        descriptionChanged ||
        privacyChanged;

      this.isEditButtonEnabled = changesDetected;
    }
  }

  handleInputChange() {
    this.checkChangesForEdit();
  }
}
