<div class="center-center w-full" *ngIf="listItems">
  <div class="multiselect relative">
    <span #input class="w-full">
      <i *ngIf="isShowIconSearch" class="pi pi-search"></i>
      <input
        class="h-11 w-full rounded-lg"
        type="text"
        pInputText
        [(ngModel)]="value"
        (keyup)="onExit($event)"
        placeholder="{{ placeholder }}" />
    </span>
    <ul
      #list
      class="absolute w-full max-h-[320px] p-1 bg-palette-base-white rounded-xl border border-slate-200 overflow-y-auto z-[1001]"
      [ngClass]="{ 'hidden': isListHidden }">
      <span *ngIf="isShowAddress" class="flex font-semibold text-sm py-3 px-4">Suggestions</span>
      <li
        class="flex justify-between rounded-s-lg py-2 px-1.5 cursor-pointer mb-0.5 select-none hover:bg-palette-gray-200"
        [ngClass]="{ 'bg-palette-gray-200': item.isSelected }"
        *ngFor="let item of listItems | filters: 'fullName' : value : 'contains'; let i = index"
        (click)="onToggle(item)">
        <div class="flex items-center gap-2" *ngIf="item?.user_info">
          <img
            class="chip__image rounded-full"
            [src]="baseUrl + item.user_info.avatar_thumbnail_url"
            alt="" />
          <span class="text-base font-medium text-palette-gray-900 break-words">{{
            item.user_info.full_name
          }}</span>
        </div>
        <div class="flex items-center gap-2" *ngIf="!item?.user_info">
          <img
            class="chip__image rounded-full"
            [src]="baseUrl + item.avatar_thumbnail_url"
            alt="" />
          <span class="text-base font-medium text-palette-gray-900 break-words">{{
            item.full_name
          }}</span>
        </div>
        <div
          class="multiselect__list__item__icon"
          [class.multiselect__list__item__icon--selected]="item.isSelected">
          <i class="pi pi-check"></i>
        </div>
      </li>
    </ul>
    <div class="multiselect__selected-items flex flex-row flex-wrap pt-4 gap-2">
      <div
        class="chip flex h-7 items-center justify-between border border-solid border-palette-gray-300 rounded-md"
        *ngFor="let selectedItem of listItems | filters: 'isSelected' : true : 'equal'">
        <div *ngIf="selectedItem?.user_info">
          <img
            class="chip__image rounded-full"
            [src]="baseUrl + selectedItem.user_info.avatar_thumbnail_url"
            alt="" />
        </div>
        <div *ngIf="selectedItem?.user_info" class="chip__name">{{ selectedItem.user_info.full_name }}</div>
        <div *ngIf="!selectedItem?.user_info">
          <img
            class="chip__image rounded-full"
            [src]="baseUrl + selectedItem.avatar_thumbnail_url"
            alt="" />
        </div>
        <div *ngIf="!selectedItem?.user_info" class="chip__name">{{ selectedItem.full_name }}</div>
        <div class="chip__remove">
          <i class="pi pi-times" (click)="onDeselect(selectedItem)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
