import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ApiClient } from '@app/lib/api/api-client';
import { WalletApiModels } from '@app/lib/api/wallet/api.wallet.models';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private api: ApiClient;

  constructor(
    private toastMessageService: ToastMessageService,
    private http: HttpClient
  ) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getBalance(walletId: string): Observable<WalletApiModels.WalletBalance> {
    return this.api.wallet.getBalance(walletId).pipe(
      map((res: any) => {
        if (res && res.success) {
          return res.data;
        }
        return res;
      })
    );
  }

  topUpWallet(walletId: string, amount: number, isShowToast = true): Observable<WalletApiModels.WalletBalance> {
    return this.api.wallet.topUpWallet(walletId, amount).pipe(
      map((res: any) => {
        if (res && res.success) {
          if (isShowToast) {
            this.toastMessageService.addToastMessage(
              res.success ? TOAST_MESSAGE_SEVERITY_LEVELS.success : TOAST_MESSAGE_SEVERITY_LEVELS.error,
              res.success
                ? 'ADVERTISING.PAYMENT.DEPOSITED_INTO_WALLET_SUCCESSFULLY'
                : 'ADVERTISING.PAYMENT.DEPOSITED_INTO_WALLET_FAILED'
            );
          }
          return res.data;
        }
        return res;
      })
    );
  }

  getWalletTransactionHistories(
    walletId: string,
    pageNum = 0,
    pageSize = 10,
    status?: string,
    startTime?: number,
    endTime?: number,
    transactionCode?: string,
    sort?: string
  ): Observable<WalletApiModels.WalletTransactionHistoriesRes> {
    return this.api.wallet
      .getWalletTransactionHistories(walletId, pageNum, pageSize, status, startTime, endTime, transactionCode, sort)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getPaymentStatus(token: string) {
    return this.api.wallet.getPaymentStatus(token);
  }
}
