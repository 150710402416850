import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { removeCookie } from 'typescript-cookie';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isModerate = false;
  constructor(
    private socAuthenService: SocialAuthService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.isModerate = params['admin'] === 'true';
    });
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem(environment.TOKEN_KEY) || localStorage.getItem(environment.TOKEN_KEY);
    if (token) {
      let headers: any = {
        authorization: `Bearer ${(JSON.parse(token) as AuthAPI.Token).accessToken}`
      };

      if (this.isModerate) {
        headers['owner-user-id'] = sessionStorage.getItem(environment.OWNER_USER_ID) || '';
      }

      request = request.clone({
        setHeaders: headers
      });
    }
    // return next.handle(request).pipe(
    //   tap(() => {}),
    //   finalize(() => {})
    // );
    return next.handle(request).pipe(
      catchError(err => {
        if ([401].includes(err.status)) {
          // && this.accountService.userValue) {
          // auto logout if 401 or 403 response returned from api
          //this.socAuthenService.signOut();
          sessionStorage.clear();
          localStorage.clear();
          removeCookie(environment.USER_PROFILE);
          removeCookie(environment.TOKEN_KEY);
          this.router.navigateByUrl('/login');
          //this.store.dispatch(AuthenticationActions.onLogout());
        }

        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    );
  }
}
