import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import {
  BloomFilter,
  BloomFilterResponse,
  BookMarkCategory,
  BookMarkCollection,
  BookMarkCollectionResponse,
  Bookmark,
  BookmarkRequest,
  BookmarkResponse,
  CreateBookMarkCategoryResponse,
  DeleteBookmarkResponse,
  IsExistBookmark,
  IsExistBookmarkResponse
} from './api.bookmark.model';

export class BookmarkApi {
  private apiUrl = environment.baseURL;
  BOOKMARK = '/bookmarks/bookmarks';
  FOLLOW_BOOKMARK = '/follower/followers/me';
  LIST_FRIEND_BOOKMARK = '/friend';
  BOOKMARK_COLLECTION = '/bookmarks/bookmarks/groups/search/tab';
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  createBookmark(body: BookmarkRequest): Observable<Bookmark> {
    return this.http.post<BookmarkResponse>(`${this.apiUrl}${this.BOOKMARK}`, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getBloomFilter(): Observable<BloomFilter> {
    return this.http.get<BloomFilterResponse>(`${this.apiUrl}${this.BOOKMARK}/bloom-filter`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getFollowBloomFilter(follow: string): Observable<BloomFilter> {
    return this.http.get<BloomFilterResponse>(`${this.apiUrl}${this.FOLLOW_BOOKMARK}/bloom-filter/${follow}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  getListFriendBloomFilter(userId: string): Observable<BloomFilter> {
    return this.http.get<BloomFilterResponse>(`${this.apiUrl}${this.LIST_FRIEND_BOOKMARK}/${userId}/bloom-filter`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  checkExistBookmark(postId: string): Observable<IsExistBookmark> {
    return this.http.get<IsExistBookmarkResponse>(`${this.apiUrl}${this.BOOKMARK}/${postId}/is-existed`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  deleteBookmark(postId: string): Observable<boolean> {
    return this.http.delete<DeleteBookmarkResponse>(`${this.apiUrl}${this.BOOKMARK}/${postId}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.success;
      }),
      catchError(err => {
        throw err.error;
      })
    );
  }

  createCategory(nameCategory: string): Observable<BookMarkCategory> {
    return this.http
      .post<CreateBookMarkCategoryResponse>(`${this.apiUrl}${this.BOOKMARK_COLLECTION}/${nameCategory}`, null)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res.data;
        }),
        catchError(err => {
          throw err.error;
        })
      );
  }

  getBookMarkCollection(pageNum: number, pageSize: number, subCategoryType = 'SOCIAL'): Observable<BookMarkCollection> {
    const url = `${this.apiUrl}${this.BOOKMARK_COLLECTION}?pageNum=${pageNum}&pageSize=${pageSize}&subCategoryType=${subCategoryType}`;
    return this.http.get<BookMarkCollectionResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => res.data),
      catchError(err => {
        throw err.error;
      })
    );
  }
}
