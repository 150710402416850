import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phone: string | undefined, area_code: string | undefined): string | undefined {
    if (phone) {
      const value = phone.charAt(0) === '0' ? phone.slice(1, phone.length) : phone;
      const strippedValArr = value.split('');

      const first = strippedValArr.splice(0, 3).join('');
      const second = strippedValArr.splice(0, 3).join('');
      const third = strippedValArr.splice(0, 5).join('');

      return `${area_code} ${first} ${second} ${third}`;
    }

    return;
  }
}
