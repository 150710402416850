<div class="flex w-full justify-between">
  <div class="flex gap gap-x-4">
    <p-checkbox
      [(ngModel)]="checkBoxValue"
      [binary]="true"
      inputId="binary"
      (onChange)="onSelectedChange(checkBoxValue, data)"></p-checkbox>
    <div *ngIf="data">
      <div (click)="navigateToProfile(data)" class="flex items-center cursor-pointer">
        <img [src]="getImgUrl(data.user_info.avatar_thumbnail_url)" alt="person" class="w-[80px] h-[80px] mr-2" />
        <span class="font-semibold text-base text-palette-gray-900 break-all">
          {{ data.user_info.full_name || 'Full Name' }}
        </span>
      </div>
    </div>
  </div>
  <div class="flex items-center">
    <div class="member__item__right flex gap-2">
      <p-button
        class="btn-block"
        icon="pi pi-ban"
        label="{{ 'COMMON.BLOCK' | translate }}"
        [disabled]="selectedMember >= 2"
        (onClick)="handleBanMember(data?.user_id)"></p-button>
      <p-button
        class="btn-decline"
        icon="pi pi-times"
        label="{{ 'COMMON.BUTTON_ACTION.DECLINE' | translate }}"
        [disabled]="selectedMember >= 2"
        (onClick)="handleDeclineMember(data?.user_id)"></p-button>
      <p-button
        class="btn-approve"
        icon="pi pi-plus"
        label="{{ 'COMMON.BUTTON_ACTION.ACCEPT' | translate }}"
        [disabled]="selectedMember >= 2"
        (onClick)="handleApproveMember(data?.user_id)"></p-button>
      <!-- <p-button class="btn-decline" icon="pi pi-ellipsis-h" label=""></p-button> -->
    </div>
  </div>
</div>

<p-dialog
  header="{{ 'GROUP.BLOCK_USER' | translate }}"
  [(visible)]="isShowBlockDialog"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'width': '440px' }"
  [modal]="true"
  class="custom-dialog">
  <div>
    <span class="text-base font-normal text-palette-gray-600"
      >{{ 'GROUP.MESSAGE.ARE_YOU_SURE_TO_BAN' | translate }}
    </span>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="isShowBlockDialog = false">
        <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-palette-base-white bg-palette-blue-600"
        type="submit"
        [disabled]="isLoadingActionItem"
        (click)="banUser()">
        <i *ngIf="isLoadingActionItem" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
        <span>{{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  header="{{ 'GROUP.DECLINE_USER' | translate }}"
  [(visible)]="isShowDeclineDialog"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'width': '440px' }"
  [modal]="true"
  class="custom-dialog">
  <div>
    <span class="text-base font-normal text-palette-gray-600"
      >{{ 'GROUP.MESSAGE.ARE_YOU_SURE_TO_DECLINE' | translate }}
    </span>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="isShowDeclineDialog = false">
        <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-palette-base-white bg-palette-red-600"
        type="submit"
        [disabled]="isLoadingActionItem"
        (click)="declineUser()">
        <i *ngIf="isLoadingActionItem" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
        <span>{{ 'COMMON.BUTTON_ACTION.DECLINE' | translate }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog
  header="{{ 'GROUP.ACCEPT_USER' | translate }}"
  [(visible)]="isShowAcceptDialog"
  [draggable]="false"
  [resizable]="false"
  [style]="{ 'width': '440px' }"
  [modal]="true"
  class="custom-dialog">
  <div>
    <span class="text-base font-normal text-palette-gray-600"
      >{{ 'GROUP.MESSAGE.ARE_YOU_SURE_TO_ACCEPT' | translate }}
    </span>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="isShowAcceptDialog = false">
        <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4 text-palette-base-white bg-palette-blue-600"
        type="submit"
        [disabled]="isLoadingActionItem"
        (click)="acceptUser()">
        <i *ngIf="isLoadingActionItem" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
        <span>{{ 'COMMON.BUTTON_ACTION.ACCEPT' | translate }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>
