import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsFeedPostsState } from './newsfeed-posts.state';

export const newsFeedPostsState = createFeatureSelector<NewsFeedPostsState>('newsFeeds');

export const selectNewsFeedPosts = createSelector(newsFeedPostsState, newsFeedsState => newsFeedsState.newsFeeds);

export const selectNewsFeedLoading = createSelector(newsFeedPostsState, newsFeedsState => newsFeedsState.loading);

export const selectNewsFeedIsOutOfFeedsEngine = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.isOutOfFeedsEngine
);

export const selectNewsFeedBookmarkBloomFilter = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.bloomFilter
);

export const selectNewsFeedFollowUserBloomFilter = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.followUserBloomFilter
);

export const selectNewsFeedFollowFanpageBloomFilter = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.followFanpageBloomFilter
);

export const selectNewsFeedListFriendBloomFilter = createSelector(
  newsFeedPostsState,
  newFeedsState => newFeedsState.listFriendBloomFilter
);

export const selectNewsFeedAds = createSelector(newsFeedPostsState, newsFeedsState => newsFeedsState.newsFeeds);

export const selectBookMarkCollection = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.bookMarkCollection
);

export const selectLoadMoreBookMarkCollection = createSelector(
  newsFeedPostsState,
  newsFeedsState => newsFeedsState.loadMoreBookMarkCollection
);

export const selectCommentList = createSelector(newsFeedPostsState, newsFeedsState => newsFeedsState.commentList);
