<p-dialog [closable]="displayCloseButton" [(visible)]="showDialog" [modal]="true" [styleClass]="styleClass"
  [draggable]="false" [dismissableMask]="closeFromOutSide" (onHide)="closeDialog() " [resizable]="resizable"
  [position]="position">
  <ng-template pTemplate="header">
    <ng-content select="[modal-header]"></ng-content>
  </ng-template>
  <div class="truncate">
    <ng-content select="[modal-content]"></ng-content>
  </div>
  <ng-template pTemplate="footer">
    <ng-content select="[modal-footer]"></ng-content>
  </ng-template>
</p-dialog>