import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'host-component',
  templateUrl: './host-component.component.html',
  styleUrls: ['./host-component.component.scss']
})
export class HostComponent {
  @Input() items: any[];
  @Input() visible: boolean = false;
  @Input() placeholder: string = '';
  @Input() isShowIconSearch: boolean = false;
  @Input() isShowAddress: boolean = false;
  @Output() completeSelected = new EventEmitter();
  value = '';
  baseUrl: string = environment.baseURL;
  isListHidden = true;
  listItems: any[] = [];
  clearDataFriends: any[] = [];
  @ViewChild('input') input: ElementRef;
  @ViewChild('list') list: ElementRef;

  constructor(private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      this.isListHidden = false;
      if (
        !this.input.nativeElement.contains(e.target) &&
        !this.list.nativeElement.contains(e.target) &&
        e.target !== this.input.nativeElement &&
        e.target !== this.list.nativeElement
      ) {
        this.isListHidden = true;
        this.completeSelected.emit(this.listItems);
      }
    });
  }

  ngOnInit(): void {
    this.renderItems();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.renderItems();
    }
    this.clearDataFriends = this.listItems?.filter(item => item.isSelected === true);
    if (!this.visible) {
      this.listItems = this.clearDataFriends;
    } else {
      this.renderItems();
    }
  }

  onToggle(item: any) {
    const objItem = this.listItems?.find(d => d.__id === item.__id);
    if (!objItem) return;
    objItem.isSelected = !objItem.isSelected;
  }

  onDeselect(item: any) {
    const objItem = this.listItems?.find(d => d.__id === item.__id);
    if (!objItem) return;
    objItem.isSelected = false;
  }

  onExit(event: any) {
    if (event.key === 'Escape') {
      this.isListHidden = true;
    }
  }
  renderItems() {
    this.listItems = this.items;
    this.listItems = this.listItems?.map((d: any, index: number) => ({ ...d, __id: index }));
  }
}
