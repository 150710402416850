import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupInterfaceState } from './reducer';

const group = createFeatureSelector<GroupInterfaceState>('groups');

export const selectGroup = createSelector(group, state => state.groups.data);
export const selectGroupAllRequest = createSelector(group, state => state.dataGroupAllRequest);
export const selectGroupAllToJoin = createSelector(group, state => state.dataGroupAllToJoin);
export const selectLeaveGroups = createSelector(group, state => state.leaveGroups);
export const selectCancelRequestGroup = createSelector(group, state => state.cancelRequestGroups);
export const selectGroupAllPendingInvites = createSelector(group, state => state.dataGroupAllPendingInvites);
export const selectAllFriends = createSelector(group, state => state.dataGetAllFriends);
export const selectAcceptGroups = createSelector(group, state => state.acceptGroups);
export const selectDeclineGroups = createSelector(group, state => state.declineGroups);
export const selectGroupDiscover = createSelector(group, state => state.dataDiscover.data);
export const selectTotalGroupDiscover = createSelector(group, state => state.dataDiscover.totalElement);
export const selectLoadingDiscover = createSelector(group, state => state.isLoadingDiscover);
export const selectLoadingMyGroup = createSelector(group, state => state.isLoadingMyGroup);
export const selectTotalMyGroup = createSelector(group, state => state.groups.totalElement);
export const selectIsLoadingJoined = createSelector(group, state => state.isLoadingJoined);
export const selectIsLoadingRequest = createSelector(group, state => state.isLoadingRequest);
export const selectIsLoadingPendingInvite = createSelector(group, state => state.isLoadingPendingInvite);

const member = createFeatureSelector<GroupInterfaceState>('members');
