import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { LoginService } from '@app/core/services/login.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { countryCode } from '@app/modules/login/mock/country-code';
import { OTP_REQUIRED_STATUS, USER_STATUS } from '@app/shared/constant';
import { environment } from '@env/environment';
import { setCookie } from 'typescript-cookie';

@Component({
  selector: 'popup-login',
  templateUrl: './popup-login.component.html',
  styleUrls: ['./popup-login.component.scss']
})
export class PopupLoginComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter();

  constructor(
    public readonly loginService: LoginService,
    private router: Router,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private TranslationService: TranslationService
  ) {
    addEventListener('AdminPage', (event: any) => {
      if (event) {
        const value = event.detail;
        localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
        localStorage.setItem(environment.TOKEN_KEY, JSON.stringify({ accessToken: value.access_token }));
        this.router.navigate([value.url], {
          queryParams: {
            role: 'Admin'
          }
        });
      }
    });
  }
  showPassword = false;
  errorMessage = '';
  loginForm = new FormGroup({
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  countries: any[];
  selectedCountry: any;
  signInMethod = 'email';
  currentYear = new Date().getFullYear();
  appName = environment.APP_NAME;
  companyName = environment.COMPANY_NAME;
  moveToVerification = false;
  credentials: AuthAPI.SignIn;
  userProfile: string;
  userStatus: string;
  deviceInfo: AuthAPI.DeviceInfo;
  isSignUp = false;

  ngOnInit(): void {
    this.resetInforInit();
    this.countries = countryCode;
    this.selectedCountry = this.countries.find(country => country.name === 'United States');
    this.moveToVerification = false;
    this.authService.getDeviceInfo().subscribe(data => {
      this.deviceInfo = {
        ...data,
        device_type: 'W'
      };
    });
    this.authService.detectWindows11().subscribe(data => {
      if (data) {
        this.deviceInfo.device = data;
      }
    });
  }

  onDropDownChange(event: any): void {
    this.selectedCountry = event.value;
  }

  getIcon(country: any): string {
    return country.icon;
  }

  changeSignInMethod(): void {
    this.signInMethod = this.signInMethod === 'email' ? 'phone' : 'email';
    this.errorMessage = '';
  }

  onLoginSubmit(): void {
    const { email, phone, password } = this.loginForm.getRawValue();
    this.displayErrorMessage(email, phone, password);
    if (this.errorMessage) {
      return;
    }
    const phoneOrEmail = this.signInMethod === 'email' ? email : phone;
    const areaCode = this.signInMethod === 'phone' ? this.selectedCountry.code : '';
    this.credentials = {
      phone_or_email: phoneOrEmail,
      password,
      area_code: areaCode,
      authenticator_otp: '',
      language: localStorage.getItem('unauthLang') || '',
      ...this.deviceInfo
    };
    this.authService.login(this.credentials).subscribe(
      response => {
        if (response.success) {
          const isEnabledAuth = response.data?.user?.is_enabled_auth;
          this.errorMessage = '';
          this.userStatus = response.data?.user?.status;
          this.userProfile = JSON.stringify(response.data.user);
          this.credentials.password = '';
          this.authService.setInfoLocalStorage(response?.data);
          if (isEnabledAuth) {
            this.moveToVerification = true;
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
          } else {
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
            setCookie(environment.USER_PROFILE, this.userProfile);
            this.loginSuccess();
          }
        } else {
          if (response?.error?.code === OTP_REQUIRED_STATUS) {
            this.moveToVerification = true;
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
          } else {
            // We will continue to retrieve messages from the BE for the
            // following scenarios: incorrect verification code, email/phone number already registered,
            // and incorrect email/phone number. We will proceed to display error messages corresponding to
            // the returned code on the FE once the BE standardizes the code along with the respective errors.
            this.errorMessage = response.error.message;
            if (this.errorMessage.includes('verified')) {
              this.userService.isNotVerified = true;
              this.userService.emailNotVerified = this.loginForm.get('email')?.value || '';
              setTimeout(() => {
                this.router.navigate(['/sign-up']);
              }, 3000);
            }
          }
        }
      },
      error => {
        if (error.hasOwnProperty('error')) {
          this.errorMessage = error.error.message;
        }
        localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: false }));
      }
    );
  }

  onShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  clickSkipChangeBtn(): void {
    this.router.navigateByUrl('/login/forgot-password', { skipLocationChange: true });
  }

  displayErrorMessage(email: any, phone: any, password: any): void {
    if (!phone && this.signInMethod === 'phone') {
      this.errorMessage = this.TranslationService.getTranslation('SIGNIN_SIGNUP.ERROR_MESSAGES.PHONE_NUMBER_REQUIRED');
    } else if (!email && this.signInMethod === 'email') {
      this.errorMessage = this.TranslationService.getTranslation('SIGNIN_SIGNUP.ERROR_MESSAGES.EMAIL_REQUIRED');
    } else if (!password) {
      this.errorMessage = this.TranslationService.getTranslation('SIGNIN_SIGNUP.ERROR_MESSAGES.PASSWORD_REQUIRED');
    } else {
      this.errorMessage = '';
    }
  }

  onForgotPassword(): void {
    this.router.navigateByUrl('/login/forgot-password', { skipLocationChange: true });
  }

  handleVerifyCodeEvent(success: boolean): void {
    if (success) {
      localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
      this.loginSuccess();
    }
  }

  loginSuccess() {
    let returnUrl = this.authService.getReturnUrl();
    localStorage.setItem(
      'is_scheduled_deleted',
      JSON.stringify({ is_scheduled_deleted: this.userStatus === USER_STATUS.SCHEDULED_DELETED })
    );
    localStorage.setItem('credentials', JSON.stringify(this.credentials));
    localStorage.setItem(environment.USER_PROFILE, this.userProfile);
    window.location.href = returnUrl;
  }

  resetInforInit() {
    this.userStatus = '';
    this.userProfile = '';
    this.credentials = {
      phone_or_email: '',
      password: '',
      area_code: '',
      device: '',
      language: localStorage.getItem('unauthLang') || ''
    };
  }

  signUp() {
    this.isSignUp = true;
  }

  onHide() {
    this.isSignUp = false;
    this.visibleChange.emit(false);
  }

  setUserInfoThirdparty(event: any) {
    if (event) {
      this.userProfile = event?.userProfile;
      this.credentials = event?.credentials;
      this.userStatus = event?.userStatus;
    }
  }
}
