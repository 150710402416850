import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserPostsState } from './user-posts.state';

export const userPostsState = createFeatureSelector<UserPostsState>('posts');

export const selectUserPosts = createSelector(userPostsState, postsState => postsState.posts);

export const selectPinedPost = createSelector(userPostsState, postsState => postsState.pinnedPost);

export const selectLoading = createSelector(userPostsState, postsState => postsState.loading);

export const selectIsUpdatedPost = createSelector(userPostsState, postsState => postsState.isUpdatedPost);

export const themePosts = createSelector(userPostsState, postsState => postsState.theme);

export const selectArchived = createSelector(userPostsState, postsState => postsState.postId);
