export const ListQuickSummary = [
  {
    bgColor: 'reaction-gradient',
    textColor: 'text-palette-rose-600',
    icon: 'sctr-icon-solid-heart-hand',
    label: 'ENTITY.REACTIONS',
    bgIcon: 'icon-reaction',
    value: 'REACTION',
    key: "reactions"
  },
  {
    bgColor: 'comments-gradient',
    textColor: 'text-palette-green-600',
    icon: 'sctr-icon-solid-message-text-square-01',
    label: 'ENTITY.COMMENTS',
    bgIcon: 'icon-comment',
    value: 'COMMENT',
    key: "comments"
  },
  {
    bgColor: 'mention-gradient',
    textColor: 'text-palette-teal-600',
    icon: 'sctr-icon-solid-tag-02',
    label: 'QUICK_SUMMARY.MENTIONED',
    value: 'MENTION',
    bgIcon: 'icon-mention',
    key: "mentioned"
  },
  {
    bgColor: 'share-gradient',
    textColor: 'text-palette-blue-light-600',
    icon: 'sctr-icon-solid-share-06',
    label: 'ENTITY.SHARES',
    value: 'SHARE',
    bgIcon: 'icon-share',
    key: "shares"
  },
  {
    bgColor: 'view-profile-gradient',
    textColor: 'text-palette-blue-600',
    icon: 'sctr-icon-solid-users-01',
    label: 'QUICK_SUMMARY.VIEW_PROFILE',
    value: 'VIEW_PROFILE',
    bgIcon: 'icon-view-profile',
    key: "view_profile"
  },
  //TODO: Temporarily hidden
  // {
  //   bgColor: 'order-gradient',
  //   textColor: 'text-palette-gray-700',
  //   icon: 'sctr-icon-solid-file-heart-02',
  //   label: 'QUICK_SUMMARY.ORDERS',
  //   value: 'ORDER',
  //   bgIcon: 'icon-order',
  //   key: "orders"
  // },
  // {
  //   bgColor: 'coin-gradient',
  //   textColor: 'text-palette-orange-600',
  //   icon: 'sctr-icon-solid-coins-02',
  //   label: 'QUICK_SUMMARY.COINS',
  //   value: 'COIN',
  //   bgIcon: 'icon-coins',
  //   key: "coins"
  // },
  {
    bgColor: 'new-friends-gradient',
    textColor: 'text-palette-fuchsia-600',
    icon: 'sctr-icon-solid-user-plus-01',
    label: 'QUICK_SUMMARY.NEW_FRIENDS',
    value: 'NEW_FRIEND',
    bgIcon: 'icon-new-friends',
    key: "new_friends"
  }
];

export const TimeRangeType = {
  THIS_WEEK: 'THIS_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  THIS_YEAR: 'THIS_YEAR'
};

export const TimeRange = [
  {
    label: 'QUICK_SUMMARY.THIS_WEEK',
    value: TimeRangeType.THIS_WEEK
  },
  {
    label: 'QUICK_SUMMARY.THIS_MONTH',
    value: TimeRangeType.THIS_MONTH
  },
  {
    label: 'QUICK_SUMMARY.THIS_YEAR',
    value: TimeRangeType.THIS_YEAR
  }
];

export enum SelectedReport {
  ReactionSummary = 'reaction_summary',
  CommentSummary = 'comment_summary',
  ShareSummary = 'share_summary'
}