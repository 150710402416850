import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiClientConfig } from '../api-client.config';
import { Observable, catchError, map, timeout } from 'rxjs';
import { StatsApiModels } from './api.statistics.models';

const STATISTICS_PATH = {
  group: '/statistics/statistics/group',
  fanpage: '/statistics/statistics/page',
  location: '/statistics/statistics/location'
};

export class StatisticsApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getGroupStatsInsights(
    groupId: string,
    startTime: number,
    endTime: number
  ): Observable<StatsApiModels.GroupStatsInsightsResponse> {
    const params = `group_id=${groupId}&start_date_time=${startTime}&end_date_time=${endTime}`;
    return this.http
      .get<StatsApiModels.GroupStatsInsightsResponse>(`${this.apiUrl}${STATISTICS_PATH.group}?${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getFanpageStatsInsights(
    fanpageId: string,
    startTime: number,
    endTime: number
  ): Observable<StatsApiModels.FanpageStatsInsightsResponse> {
    const params = `page_id=${fanpageId}&start_date_time=${startTime}&end_date_time=${endTime}`;
    return this.http
      .get<StatsApiModels.FanpageStatsInsightsResponse>(`${this.apiUrl}${STATISTICS_PATH.fanpage}?${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getMemberLocationStats(objectId: string): Observable<StatsApiModels.LocationStatsResponse> {
    const params = `object_id=${objectId}`;
    return this.http
      .get<StatsApiModels.LocationStatsResponse>(`${this.apiUrl}${STATISTICS_PATH.location}?${params}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
