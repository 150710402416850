<div class="flex justify-around border border-gray-500 shadow-sm rounded-xl bg-palette-gray-50">
  <div class="" *ngIf="!loading">
    <div class="flex justify-between px-4 pt-4 cursor-pointer" (click)="navigatePostOriginal()">
      <div class="flex items-center w-full space-x-3">
        <!-- <div class="w-10 h-10 rounded-full ring-2 ring-transparent">
        <img class="w-full h-full rounded-full" onerror="this.src='assets/images/default_user_avatar.png'"
          [src]="validUrl(data.avatar)" />
      </div> -->
        <div class="flex flex-col">
          <div class="flex items-center gap-1.5">
            <div
              class="text-base text-palette-gray-700 font-semibold cursor-pointer truncate max-w-[200px] md:max-w-[480px]">
              {{ data.title }}
            </div>
          </div>
          <div class="flex items-center py-0.5">
            <span class="text-xs font-semibold text-gray-400">
              <!-- {{ data.sub_title ? data.sub_title : (shareLinkType | titlecase) }} -->
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 pt-1 pb-3 overflowWrap">
      <ng-container *ngIf="!isShowAllContent && descriptionHeight > MAX_TRUNCATED_TEXT_HEIGHT; else elseDiv">
        <div class="whitespace-pre-wrap post-description truncated-text" [innerHTML]="data.description + ' ...'"></div>
        <div class="font-bold cursor-pointer hover:underline w-fit" (click)="onExpandText()">
          {{ 'COMMON.VIEW_MORE' | translate }}
        </div>
      </ng-container>
      <ng-template #elseDiv>
        <div class="block whitespace-pre-wrap post-description" [innerHTML]="data.description"></div>
        <div
          *ngIf="isShowAllContent || descriptionHeight > MAX_TRUNCATED_TEXT_HEIGHT"
          class="font-bold cursor-pointer hover:underline w-fit"
          (click)="onExpandText()">
          {{ 'COMMON.VIEW_LESS' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="loading">
    <loading-spinner [styleClass]="'w-16 h-16'"></loading-spinner>
  </ng-container>
  <div class="relative" *ngIf="mediaList.length > 0 && !loading">
    <media [mediaList]="mediaList" (clickImage)="clickImage($event)"></media>
    <div
      *ngIf="data.rating"
      class="absolute flex flex-row items-center gap-3 p-3 rounded-md left-3 bottom-3 rating text-palette-base-white">
      <div class="border border-palette-base-white p-1 h-fit rounded-md text-center min-w-[32px]">
        {{ data.rating }}
      </div>
      <div class="flex flex-col">
        <label class="text-base font-medium">{{ 'POST.SUPERB' | translate }}</label>
        <span class="text-sm font-normal">{{
          data.total_reviews + ' ' + ('POST.REVIEWS' | translate | lowercase)
        }}</span>
      </div>
    </div>
  </div>
</div>
<div class="" *ngIf="!loading">
  <p-galleria
    [value]="mediaList"
    [(visible)]="displayFullImage"
    [(activeIndex)]="activeIndex"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showItemNavigators]="mediaList.length > 1"
    [showThumbnails]="false">
    <ng-template pTemplate="item" let-item>
      <div *ngIf="item.type === MEDIA_TYPE.image" class="w-[850px]">
        <div
          [style.backgroundImage]="'url(' + validUrl(item.original, 'web') + ')'"
          class="bg-no-repeat bg-center w-full h-full bg-contain pt-[100%]"></div>
      </div>
    </ng-template>
  </p-galleria>
</div>
