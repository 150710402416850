import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { notificationResponse } from '@app/lib/api/notifications/api.notification.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  apiUrl = environment.baseURL;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  currentMessage = new BehaviorSubject(null);
  constructor(private http: HttpClient) {}

  getNotifications(pageNum: number, pageSize: number) {
    return this.http
      .get<any>(this.apiUrl + '/notification/notifications/me?pageNum=' + pageNum + '&pageSize=' + pageSize)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }
  toggleReadNotification(id: any) {
    return this.http.put<any>(this.apiUrl + `/notification/notifications/${id}/read/toggle`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  markAllReadNotification() {
    return this.http.post<notificationResponse>(this.apiUrl + `/notification/notifications/all/read`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  completeCTANotification(id: any) {
    return this.http.put<notificationResponse>(this.apiUrl + `/notification/notifications/${id}/cta/complete`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }
  deleteNotification(id: any) {
    return this.http.delete<notificationResponse>(this.apiUrl + `/notification/notifications/${id}`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }
  callToActionNotification(url: any) {
    return this.http.put<any>(this.apiUrl + url, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  countUnReadNotification(): Observable<number> {
    return this.http.get(this.apiUrl + `/notification/notifications/unread/count`).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  getUserNotification(userId: string): Observable<notificationResponse> {
    return this.http.get<notificationResponse>(`${this.apiUrl}/notification/usernotifications/${userId}`).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
