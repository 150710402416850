import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { LoginService } from '@app/core/services/login.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { AuthAPI } from '@app/lib/api/auth/api.auth.model';
import { UserAPI } from '@app/lib/api/user/api.user.model';
import { AuthenticationActions } from '@app/modules/main/states/account-settings/authentication/authentication.actions';
import { USER_STATUS } from '@app/shared';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { removeCookie, setCookie } from 'typescript-cookie';

@Component({
  selector: 'otp-twofa-form',
  templateUrl: './otp-twofa-form.component.html',
  styleUrls: ['./otp-twofa-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OtpTwofaFormComponent implements OnInit {
  @Input() signInParams: AuthAPI.SignIn;
  @Output() verifyCodeEvent = new EventEmitter();
  @Output() changeMoveToVerification = new EventEmitter();
  errorMessage = '';
  isLoading = false;
  otpForm: FormGroup;
  translateResult: any;
  translateCommonResult: any;
  otpDigits: number[] = [1, 2, 3, 4, 5, 6];

  constructor(
    private readonly route: Router,
    private store: Store,
    private fb: FormBuilder,
    private readonly userService: UserService,
    public readonly loginService: LoginService,
    private translateService: TranslationService,
    private socAuthenService: SocialAuthService,
    private readonly authService: AuthService
  ) {}

  get isFullCode(): boolean {
    return this.otpForm.valid;
  }

  ngOnInit(): void {
    this.createOtpForm();
    this.translateResult = this.translateService.getTranslation('SIGNIN_SIGNUP');
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
  }

  createOtpForm(): void {
    const formControls: any = {};
    for (let digit of this.otpDigits) {
      formControls[`digit${digit}`] = ['', Validators.required];
    }
    this.otpForm = this.fb.group(formControls);
  }

  onInputChange(currentInput: number): void {
    const inputElement = document.getElementById(`verification-2fa-code-input-${currentInput}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.addEventListener('keydown', event => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          inputElement.value = '';
          this.otpForm.get(`digit${currentInput}`)?.setValue('');
          const previousInput = document.getElementById(`verification-2fa-code-input-${currentInput - 1}`);
          if (previousInput) {
            previousInput.focus();
          }
          event.preventDefault();
        } else {
          const nextInput = document.getElementById(`verification-2fa-code-input-${currentInput + 1}`);
          if (this.otpForm.get(`digit${currentInput}`)?.value && nextInput) {
            nextInput.focus();
          }
        }
      });
      inputElement.focus();
    }
    this.handleSubmitCode();
  }

  shouldDisableInputCode(currentInput: number): boolean {
    if (currentInput === 1) {
      return !!this.otpForm.get('digit1')?.value;
    }
    return !this.otpForm.get(`digit${currentInput - 1}`)?.value || this.otpForm.get(`digit${currentInput}`)?.value;
  }

  onVerifyCode(): void {
    this.isLoading = true;
    this.disableAllInput();
    const otp = Object.values(this.otpForm.value).join('').toUpperCase();
    const isSignInParamsEmpty = Object.values(this.signInParams).every(x => x === null || x === '');
    if (!isSignInParamsEmpty) {
      this.authService.login({ ...this.signInParams, authenticator_otp: otp }).subscribe({
        next: response => {
          if (response?.success) {
            this.authService.setInfoLocalStorage(response?.data);
            localStorage.setItem('auth_status', JSON.stringify({ isLoggedIn: true }));
            let returnUrl = this.authService.getReturnUrl();
            localStorage.setItem(
              'is_scheduled_deleted',
              JSON.stringify({ is_scheduled_deleted: response.data?.user?.status === USER_STATUS.SCHEDULED_DELETED })
            );
            const userProfile = JSON.stringify(response.data.user);
            localStorage.setItem('credentials', JSON.stringify({ ...this.signInParams, password: '' }));
            localStorage.setItem(environment.USER_PROFILE, userProfile);
            setCookie(environment.USER_PROFILE, userProfile);
            if (returnUrl != '') window.location.href = returnUrl;
            else window.location.href = '/';
          } else this.handleIncorrectOtpInput(response?.error?.message);
          this.isLoading = false;
          this.enableAllInput();
        },
        error: error => {
          if (error.hasOwnProperty('error')) {
            this.errorMessage = error?.error?.message;
          }
          this.createOtpForm();
          this.isLoading = false;
          this.enableAllInput();
        }
      });
    } else {
      // TODO: This is old flow, and it will be removed when new flow 2FA from BE is applied completely.
      const deviceSignIn = JSON.parse(localStorage.getItem('device_sign_in') || '');
      const body: UserAPI.VerifyOTPTwoFa = {
        otp,
        refresh_token: JSON.parse(localStorage.getItem(environment.REFRESH_TOKEN_KEY) ?? '[]')?.refreshToken || '',
        ...deviceSignIn
      };
      this.userService.verifyTwoFAOtp(body).subscribe(
        res => {
          if (res && res?.data?.isValid) {
            this.errorMessage = '';
            this.verifyCodeEvent.emit(true);
            this.isLoading = false;
            this.enableAllInput();
          } else this.handleIncorrectOtpInput(res?.error?.message);
          this.isLoading = false;
          this.enableAllInput();
        },
        error => {
          if (error.hasOwnProperty('error')) {
            this.errorMessage = error?.error?.message;
          }
          this.createOtpForm();
          this.isLoading = false;
          this.enableAllInput();
        }
      );
    }
  }

  handleIncorrectOtpInput(message: string): void {
    if (message === 'LOCKED') {
      this.errorMessage = 'ACCOUNT_SETTING.SECURITY_SETTING.TWO_FA.LOCKED';
    } else {
      this.errorMessage = 'ACCOUNT_SETTING.SECURITY_SETTING.TWO_FA.INCORRECT_CODE';
    }
    this.otpDigits.forEach(index => this.otpForm.get(`digit${index}`)?.setValue(''));
    const firstInputElement = document.getElementById('verification-2fa-code-input-1');
    firstInputElement?.focus();
    this.verifyCodeEvent.emit(false);
  }

  disableAllInput() {
    this.otpDigits.forEach(digit => {
      this.otpForm.get(`digit${digit}`)?.disable();
    });
  }

  enableAllInput() {
    this.otpDigits.forEach(digit => {
      this.otpForm.get(`digit${digit}`)?.enable();
    });
  }

  handleSignOut(): void {
    localStorage.clear();
    removeCookie(environment.USER_PROFILE);
    removeCookie(environment.TOKEN_KEY);
    this.socAuthenService.signOut();
    sessionStorage.clear();
    this.store.dispatch(AuthenticationActions.onLogout());
    this.changeMoveToVerification.emit(false);
    this.route.navigateByUrl('/login');
  }

  handleSubmitCode(): void {
    const result = Object.values(this.otpForm.value).join('');
    if (result.length === this.otpDigits.length) this.onVerifyCode();
  }

  onPasteCode(event: any): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const otpArray = pastedText.slice(0, 6).split('');
    otpArray.forEach((char: string, index: number) => {
      const inputName = `digit${index + 1}`;
      this.otpForm.get(inputName)?.setValue(char);
    });
    this.handleSubmitCode();
  }
}
