<div class="card flex justify-content-center">
  <p-dialog
    class="approve-dialog bg-white"
    [(visible)]="visible"
    [position]="position"
    [style]="dialogStype"
    [modal]="true"
    (onHide)="onHide()">
    <ng-template pTemplate="header">
      <p class="text-lg font-semibold text-gray-900">{{ header }} {{ title }}</p>
      <!-- <i class="pi pi-check flex items-center justify-center h-12 w-12 bg-[#D1E9FF]" style="color: #1570EF;border: 8px solid #EFF8FF;border-radius: 28px;"></i> -->
    </ng-template>
    <ng-content pTemplate="content"></ng-content>
  </p-dialog>
</div>
