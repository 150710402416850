import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], searchText: any, keySearch: string, isFilterFriend = false): any[] {
    if (!value || !searchText) {
      return value;
    }
    searchText = searchText.toLowerCase();
    if (keySearch === 'full_name' && !isFilterFriend) {
      return value.filter(item => item.user_info['full_name'].toLowerCase().includes(searchText));
    }
    return value.filter(item => item[keySearch].toLowerCase().includes(searchText));
  }
}
