<div class="flex flex-col gap-2 relative bg-palette-base-white lg:bg-transparent">
  <div>
    <ng-container *ngTemplateOutlet="pendingPostHeaderActions"></ng-container>
  </div>
  <div>
    <ng-container *ngTemplateOutlet="pendingPostItems"></ng-container>
  </div>
  <ng-container *ngIf="!pendingPosts.length && !isLoading">
    <div
      class="flex flex-col gap-8 justify-center items-center py-20 rounded-md bg-palette-base-white lg:border border-palette-gray-200">
      <img src="./assets/images/Illustration.svg" alt="No pending post" />
      <span class="text-lg text-palette-gray-900 font-semibold">{{ 'POST_REVIEW.NO_PENDING_POST' | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div class="card">
      <ng-container *ngFor="let item of [].constructor(10)">
        <div class="border-round border-1 surface-border p-4 surface-card">
          <p-skeleton width="75%" height="150px" styleClass="skeleton-bold" />
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<div
  *ngIf="selectedPost"
  class="lg:hidden fixed w-full bottom-0 right-0 bg-palette-base-white p-4 border-t border-palette-gray-200">
  <ng-container *ngTemplateOutlet="selectedPostActionButtons"></ng-container>
</div>

<div
  *ngIf="showPendingPostDialog"
  class="lg:hidden fixed w-full h-full bottom-0 right-0 bg-palette-gray-900/40 flex flex-col justify-end z-[1000]">
  <div
    class="bg-palette-base-white px-4 pb-[34px] pt-1.5 shadow-[0_-20px_24px_-4px_rgba(16,24,40,0.08)] flex flex-col gap-4 rounded-t-xl">
    <div class="w-full flex justify-center">
      <div class="w-9 h-[5px] bg-[#3C3C43]/30 rounded-full"></div>
    </div>
    <div class="flex flex-col gap-1">
      <span class="text-lg font-semibold text-palette-gray-900">{{ postReviewConfirmation.title }}</span>
      <span class="text-sm text-palette-gray-600">{{ postReviewConfirmation.message }}</span>
    </div>
    <div class="w-full grid grid-cols-2 gap-3">
      <button
        class="w-full h-10 px-4 rounded-lg border border-palette-gray-300 bg-white hover:bg-palette-gray-200 focus:ring-2 ring-branding-primary-300 ring-offset-2"
        type="button"
        (click)="showPendingPostDialog = false"
        [disabled]="isLoading">
        <ng-container *ngIf="!isLoading; else loadingIcon">
          {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
        </ng-container>
        <ng-template #loadingIcon>
          <i class="pi-spin pi sctr-icon-loading-02 mr-2"></i>
        </ng-template>
      </button>
      <button
        pButton
        class="w-full h-10 px-4 rounded-lg border mr-0 {{
          postReviewConfirmation.buttonClass
        }} ring-branding-primary-300 focus:ring-2 ring-offset-2"
        type="submit"
        (click)="confirmedAction()"
        [disabled]="isLoading">
        <ng-container *ngIf="!isLoading; else loadingIcon">
          {{ postReviewConfirmation.buttonTitle }}
        </ng-container>
        <ng-template #loadingIcon>
          <i class="pi-spin pi sctr-icon-loading-02 mr-2"></i>
        </ng-template>
      </button>
    </div>
  </div>
</div>

<div class="hidden lg:block">
  <p-confirmDialog
    #confirmReviewPostAction
    id="confirmReviewPostAction"
    styleClass="w-[500px] rounded-xl text-palette-gray-700"
    contentStyleClass="rounded-b-lg">
    <ng-template pTemplate="footer">
      <div class="flex justify-end gap-3">
        <button
          class="h-10 px-4 rounded-lg border border-palette-gray-300 bg-white hover:bg-palette-gray-200 focus:ring-2 ring-branding-primary-300 ring-offset-2"
          type="button"
          (click)="confirmReviewPostAction.reject()">
          {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
        </button>
        <button
          class="h-10 px-4 rounded-lg border mr-0 {{
            postReviewConfirmation.buttonClass
          }} ring-branding-primary-300 focus:ring-2 ring-offset-2"
          type="submit"
          [disabled]="isLoading"
          (click)="confirmReviewPostAction.accept()">
          <i *ngIf="isLoading" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
          {{ postReviewConfirmation.buttonTitle }}
        </button>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>

<ng-template #pendingPostHeaderActions>
  <div class="w-full flex flex-col p-4 rounded-md bg-palette-base-white lg:border border-palette-gray-200 gap-4">
    <div class="flex flex-col lg:flex-row gap-4 lg:justify-between">
      <span class="hidden lg:block w-full lg:w-1/2 text-lg font-semibold text-palette-gray-900">
        <ng-template [ngIf]="customTitlePedingPost" [ngIfElse]="noCustomTitle">
          {{ customTitlePedingPost | translate }}
        </ng-template>
        <ng-template #noCustomTitle>
          {{ 'POST_REVIEW.PENDING_POSTS' | translate }}
        </ng-template>
      </span>
      <div *ngIf="!hideSearchBox" class="w-full lg:w-1/2 flex items-center lg:justify-end lg:gap-3">
        <span class="p-input-icon-left h-10 w-full lg:w-fit">
          <i class="sctr-icon-search-lg text-xl text-palette-gray-500 align-middle leading-none top-[45%]"></i>
          <input
            type="text"
            pInputText
            placeholder="{{ 'COMMON.SEARCH' | translate }}"
            class="h-10 max-h-10 w-full py-2 pr-3.5 rounded-lg text-palette-gray-500"
            #searchPost
            (keyup)="onSearchPendingPost(searchPost.value)" />
        </span>
      </div>
    </div>
    <div *ngIf="!isModerate" class="flex justify-between">
      <div class="flex gap-3 items-center">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="checkBoxValue"
          (onChange)="onSelectedAllPosts($event)"
          [disabled]="!pendingPosts.length"></p-checkbox>
        <span class="text-palette-gray-600">{{ 'POST_REVIEW.SELECTED' | translate }} ({{ selectedPost }})</span>
      </div>
      <div class="hidden lg:block">
        <ng-container *ngTemplateOutlet="selectedPostActionButtons"></ng-container>
      </div>
    </div>
    <ng-container *ngIf="paging.totalElement > PAGE_SIZE_OPTIONS[0].size" [ngTemplateOutlet]="paginator">
    </ng-container>
  </div>
</ng-template>

<ng-template #pendingPostItems>
  <div
    class="w-full flex flex-col items-center gap-2 pb-2 lg:pb-0 bg-palette-gray-200 lg:bg-transparent"
    *ngIf="pendingPosts && pendingPosts.length && !isLoading">
    <div *ngFor="let pendingPost of pendingPosts; index as index" class="w-full flex justify-center">
      <div class="w-full lg:w-[690px] bg-palette-base-white rounded-md border border-palette-gray-200 flex flex-col">
        <post
          [post]="pendingPost"
          [isPostInProfile]="true"
          [isPostReview]="isReviewTimeLine"
          [isPostGroup]="true"
          [isShowActualPoster]="true"
          [isShowComment]="false"></post>
        <review-post-footer
          [checkBoxValue]="postListReview[index].isSelected"
          (selectedPost)="onSelectedPost(postListReview[index], $event)"
          (approvePost)="onConfirmReviewedPost(postListReview[index], true)"
          (rejectPost)="onConfirmReviewedPost(postListReview[index], false)"></review-post-footer>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectedPostActionButtons>
  <div class="flex gap-2 lg:gap-3 justify-between items-center">
    <ng-container
      [ngTemplateOutlet]="selectedPostActionButton"
      [ngTemplateOutletContext]="{
        disabled: selectedPost === 0,
        activeButtonClass: 'text-system-error-700 hover:bg-system-error-50',
        deactiveButtonClass: 'text-system-error-300 hover:bg-palette-gray-100',
        buttonIcon: 'sctr-icon-x-close',
        buttonTitle: 'POST_REVIEW.DECLINE_ALL' | translate,
        type: POST_REVIEW_ACTIONS.declineAll
      }"></ng-container>
    <ng-container
      [ngTemplateOutlet]="selectedPostActionButton"
      [ngTemplateOutletContext]="{
        disabled: selectedPost === 0,
        activeButtonClass: 'text-branding-primary-700 hover:bg-branding-primary-50',
        deactiveButtonClass: 'text-palette-gray-300 hover:bg-palette-gray-100',
        buttonIcon: 'sctr-icon-check',
        buttonTitle: 'POST_REVIEW.APPROVE_ALL' | translate,
        type: POST_REVIEW_ACTIONS.approveAll
      }"></ng-container>
  </div>
</ng-template>

<ng-template
  #selectedPostActionButton
  let-disabled="disabled"
  let-activeButtonClass="activeButtonClass"
  let-deactiveButtonClass="deactiveButtonClass"
  let-buttonIcon="buttonIcon"
  let-buttonTitle="buttonTitle"
  let-type="type">
  <button
    class="h-10 w-full lg:w-fit flex justify-center items-center gap-2 px-4 py-2.5 rounded-lg bg-palette-base-white cursor-pointer text-sm font-semibold"
    [class]="disabled ? deactiveButtonClass : activeButtonClass"
    type="button"
    (click)="onConfirmReviewedAllPosts(type)"
    [disabled]="disabled">
    <i class="text-xl {{ buttonIcon }}"></i>
    <span>{{ buttonTitle }}</span>
  </button>
</ng-template>

<ng-template #paginator>
  <paginator [paging]="paging" (pageSize)="onChangedPageSize($event)" (pagingDetail)="onPageChange($event)"></paginator>
</ng-template>
