import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() isOverlay = false;
  @Input() styleClass = 'w-16 h-16 md:w-32 md:h-32';
  @Input() strokeWidth = '4';
  @Input() spinSpeed = '1s';
  @Input() isFullScreen = false;
}
