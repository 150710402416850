import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FollowStatusState,
  FriendState,
  FriendStatusState,
  IsFollowingState,
  RelationShipStatusState
} from './friend.state';

export const friendStatusState = createFeatureSelector<FriendStatusState>('friendStatus');

export const selectRelationshipState = createSelector(friendStatusState, friend => friend.data.relationship_status);

export const selectRelationshipIdState = createSelector(friendStatusState, friend => friend.data.friend_invitation_id);

export const selectLoadingRelationshipState = createSelector(friendStatusState, friend => friend.loading);

export const friendState = createFeatureSelector<FriendState>('friend');

export const selectLoadingFriendState = createSelector(friendState, friend => friend.friendList.loading);

export const selectFriendListState = createSelector(friendState, friend => friend.friendList.data);

export const selectLoadingFriendWidgetState = createSelector(friendState, friend => friend.friendWidgetList.loading);

export const selectFriendWidgetState = createSelector(friendState, friend => friend.friendWidgetList.data);

export const selectTotalFriendWidgetState = createSelector(
  friendState,
  friend => friend.friendWidgetList.data.total_records
);

export const selectFriendListTotalState = createSelector(friendState, friend => friend.friendList?.data?.total_records);

export const selectReceiveRequestState = createSelector(friendState, friend => friend.receiveRequest.data);

export const selectLoadingReceiveRequestState = createSelector(friendState, friend => friend.receiveRequest.loading);

export const selectSendRequestState = createSelector(friendState, friend => friend.sendRequest.data);

export const selectLoadingSendRequestState = createSelector(friendState, friend => friend.sendRequest.loading);

export const selectLoadingSuggestFriendsState = createSelector(
  friendState,
  suggestFriend => suggestFriend.suggestFriends.loading
);
export const selectSuggestFriendsState = createSelector(
  friendState,
  suggestFriend => suggestFriend.suggestFriends && suggestFriend.suggestFriends.data
);

export const selectSuggestListFriends = createSelector(
  friendState,
  suggestFriend => suggestFriend.suggestFriends && suggestFriend.suggestFriends.data.content
);

export const selectUserFollowList = createSelector(friendState, userFollow => userFollow.userFollower.data);

export const selectLoadingUserFollowList = createSelector(friendState, userFollow => userFollow.userFollower.loading);

export const selectUserFollowingList = createSelector(friendState, userFollowing => userFollowing.userFollowing.data);

export const selectTotalUserFollowingList = createSelector(
  friendState,
  userFollowing => userFollowing.userFollowing.data.totalElement
);

export const selectLoadingUserFollowingList = createSelector(
  friendState,
  userFollowing => userFollowing.userFollowing.loading
);

export const relationshipState = createFeatureSelector<RelationShipStatusState>('relationshipStatus');

export const selectCheckRelationshipLoading = createSelector(relationshipState, relationship => relationship.loading);

export const selectCheckRelationship = createSelector(relationshipState, relationship => relationship.isFriend);

export const unFollowState = createFeatureSelector<FollowStatusState>('unFollowStatus');

export const selectUnFollowLoading = createSelector(unFollowState, unfollow => unfollow.loading);

export const selectFollowStatus = createSelector(unFollowState, unfollow => unfollow.followStatus);

export const isFollowingState = createFeatureSelector<IsFollowingState>('isFollowing');

export const selectIsFollowLoading = createSelector(isFollowingState, isFollowing => isFollowing.loading);

export const selectIsFollowStatus = createSelector(isFollowingState, isFollowing => isFollowing.isFollowing);
