import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LibraryState } from './library.state';

export const libraryStates = createFeatureSelector<LibraryState>('library');

export const selectLibraryId = createSelector(libraryStates, state => state.libraryId);

export const selectLibraryLibrarySyncStatus = createSelector(libraryStates, state => state.librarySyncStatus);

export const selectLibraryLoading = createSelector(libraryStates, state => state.loading);

export const selectLibraryProcessing = createSelector(libraryStates, state => state.processing);

export const selectLibraryAlbumList = createSelector(libraryStates, state => state.albumList);

export const selectLibraryEditAlbum = createSelector(libraryStates, state => state.onEditAlbum);

export const selectLibraryMediaList = createSelector(libraryStates, state => state.mediaList);

export const selectLibraryMediaListPostId = createSelector(libraryStates, state => state.mediaList.post_id);

export const selectHighlightsLibrary = createSelector(libraryStates, state => state.highlightsLibrary);

export const selectLibraryLoadingHighlightsLibrary = createSelector(
  libraryStates,
  state => state.loadingHighlightsLibrary
);
