<div class="member-request-content" *ngIf="property">
  <div class="grid grid-cols-1 rounded-md">
    <div class="rounded-md bg-white">
      <div class="flex justify-between p-4">
        <span class="flex items-center text-lg font-semibold text-palette-gray-900">{{
          'GROUP.SETTING.GROUP_REQUEST' | translate
        }}</span>
        <div class="flex gap-x-3 h-[40px]">
          <span class="p-input-icon-left h-[40px]">
            <i class="sctr-icon-search-sm text-xl/5"></i>
            <input
              type="text"
              pInputText
              [formControl]="search"
              [(ngModel)]="searchText"
              (keyup)="handleSearch()"
              placeholder="{{ 'GROUP.SETTING.SEARCH' | translate }}"
              class="w-[292px] py-2 pr-3.5" />
          </span>
        </div>
      </div>
      <div class="flex gap-3 pt-0 px-4 pb-4 border-b border-solid border-gray-200">
        <button
          [ngClass]="selectedButton === 'MemberRequest' ? 'selected' : 'unselected'"
          class="btn-header-tab py-2.5 px-3 font-semibold text-sm"
          (click)="selectButtonClick('MemberRequest')">
          {{ 'GROUP.SETTING.MEMBER_REQUESTS' | translate }}
        </button>
        <button
          [ngClass]="selectedButton === 'SenRequest' ? 'selected' : 'unselected'"
          class="btn-header-tab py-2.5 px-3 font-semibold text-sm"
          (click)="selectButtonClick('SenRequest')">
          {{ 'GROUP.SETTING.SENT_INVITES' | translate }}
        </button>
      </div>
    </div>
    <div
      class="p-4 rounded-md bg-white gap-x-4 flex flex-col"
      [ngClass]="{ hidden: selectedButton !== 'MemberRequest' }">
      <div class="action flex flex-row justify-between items-center p-4 w-full">
        <div class="flex flex-row gap-4">
          <p-checkbox
            class="h-5 w-5"
            [(ngModel)]="checked"
            [binary]="true"
            inputId="selected"
            checkboxIcon="pi pi-minus"
            (onChange)="selectedAllChange(checked)"></p-checkbox>
          <label class="text-gray-600 font-medium text-sm" for="selected"
            >{{ 'GROUP.SELECTED' | translate }} ({{ listMemberSelected.length }})</label
          >
        </div>
        <div class="flex flex-row gap-2">
          <p-button
            class="t-declineButton"
            styleClass=" hover:bg-white border-0 p-4 h-10 text-sm font-semibold"
            icon="pi pi-times"
            label="{{ 'GROUP.SETTING.DECLINE_ALL' | translate }}"
            (onClick)="DeclineAll()"
            [disabled]="!listMemberSelected.length"></p-button>
          <p-button
            class="t-acceptButton"
            styleClass="p-4 h-10 rounded-lg  text-sm font-semibold"
            icon="pi pi-check"
            label="{{ 'GROUP.SETTING.ACCEPT_ALL' | translate }}"
            [disabled]="!listMemberSelected.length"
            (onClick)="handleShowModalMember()"></p-button>
        </div>
      </div>
      <div
        class="p-4 rounded-md bg-white gap-x-4 flex justify-between"
        *ngFor="
          let dataMemberPending of searchResult.length > 0 || searchText !== '' ? searchResult : property;
          let i = index
        ">
        <member-item
          class="w-full"
          [selectedMember]="listMemberSelected.length"
          [data]="dataMemberPending"
          (declineMember)="handleDeclineMember($event)"
          (banMember)="handleBanMember($event)"
          (approveMember)="handleApproveMember($event)"
          (onClickCheckItem)="onSelectedChange($event)"
          [isLoadingActionItem]="(loadingActionForUser$ | async) || false"
          [checked]="MemberItemCheck"></member-item>
      </div>
    </div>
    <div
      class="p-4 rounded-md bg-white gap-x-10 gap-y-8 flex grid grid-cols-1 md:grid-cols-2"
      [ngClass]="{ hidden: selectedButton !== 'SenRequest' }"
      *ngIf="dataGroupAllRequestInvited?.length">
      <div class="flex gap-x-3 justify-between w-full" *ngFor="let item of dataGroupAllRequestInvited">
        <div class="flex gap-x-3 items-center" *ngIf="item">
          <div (click)="navigateToProfile(item)" class="flex items-center cursor-pointer">
            <img [src]="getImgUrl(item.avatar_thumbnail_url)" alt="person" class="w-[80px] h-[80px] mr-2" />
            <div class="flex flex-col justify-center">
              <span
                class="font-semibold text-base flex items-center text-palette-gray-900"
                [style]="{ wordBreak: 'break-all' }">
                <span class="mr-2">{{ item.full_name ?? 'Full Name' }}</span>
              </span>
              <span class="text-sm text-palette-gray-500" *ngIf="item.pending_role === 'ADMIN'">{{
                'GROUP.MESSAGE.PROMOTING' | translate
              }}</span>
              <span class="text-sm text-palette-gray-500" *ngIf="item.pending_role === 'MOD'">{{
                'GROUP.MESSAGE.PROMOTING_MOD' | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <p-button
            *ngIf="item.pending_role !== null"
            (click)="handleCancelRequest(item.id)"
            class="flex text-palette-gray-800 font-semibold text-sm custom-button-group"
            icon="scr-icon-user-x-01"
            label="{{ 'COMMON.CANCEL_REQUEST' | translate }}"></p-button>
          <p-button
            *ngIf="item.pending_role === null"
            (click)="handleCancelInviteRequest(item.user_id)"
            class="flex text-palette-gray-800 font-semibold text-sm custom-button-group"
            icon="scr-icon-user-x-01"
            label="{{ 'COMMON.CANCEL_REQUEST' | translate }}"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Approve member"
  [(visible)]="declineDialogVisible"
  [modal]="true"
  [style]="{ width: '400px' }"
  [resizable]="false">
  <div *ngIf="listMemberSelected">
    <span class="text-base font-normal text-palette-gray-600">{{
      'GROUP.SETTING.ARE_YOU_SURE_TO_APPROVE_ALL' | translate
    }}</span>
    <div class="pt-4 gap-y-1 flex flex-col" *ngFor="let items of listMemberSelected">
      <div class="font-medium text-sm flex gap-x-1.5 items-center text-palette-gray-700">
        <div class="w-[10px] h-[10px] rounded-full bg-black"></div>
        <span>{{ items?.user_info?.full_name ?? 'N/A' }}</span>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2 items-center justify-end">
      <button
        class="rounded-lg border border-solid border-gray-300 px-4 h-10 bg-white"
        type="submit"
        (click)="declineDialogVisible = false">
        <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
      </button>
      <button
        class="rounded-lg h-10 px-4"
        style="border: 1 solid #1570ef; background-color: #1570ef; color: white"
        [disabled]="(loadingActionForUser$ | async) || false"
        type="submit"
        (click)="handleActionForUser(listMemberSelected, false)">
        <i *ngIf="(loadingActionForUser$ | async) || false" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
        <span>{{ 'GROUP.SETTING.ACCEPT_ALL' | translate }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<dialog-confirm [visible]="acceptDialogVisible" (hideDialog)="onHide()" [header]="type" [title]="title">
  <div class="flex flex-col gap-1">
    <p class="text-sm font-normal text-gray-600">
      {{ textTranslate }}
    </p>
  </div>
  <div class="flex flex-row gap-3 justify-end pt-8">
    <button
      class="flex items-center h-11 px-2.5 text-base text-gray-700 font-semibold rounded-lg border border-solid border-gray-300"
      (click)="onHide()">
      {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
    </button>
    <button
      class="flex items-center h-11 text-white px-2.5 text-base font-semibold rounded-lg border border-solid bg-palette-red-600"
      [disabled]="(loadingActionForUser$ | async) || false"
      (click)="handleActionForUser(listMemberSelected, true)">
      <i *ngIf="(loadingActionForUser$ | async) || false" class="pi-spin pi sctr-icon-loading-02 mt-1 mr-1"></i>
      <span>{{ 'GROUP.SETTING.DECLINE_ALL' | translate }}</span>
    </button>
  </div>
</dialog-confirm>
