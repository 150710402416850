<div class="hidden md:block">
  <div class="flex gap-4 w-full bg-palette-gray-200 h-fit">
    <div class="min-w-[282px] max-w-xs w-fit">
      <ng-container [ngTemplateOutlet]="menuItems"></ng-container>
    </div>
    <div class="grow">
      <ng-container [ngTemplateOutlet]="settingContents"></ng-container>
    </div>
  </div>
</div>

<div class="block md:hidden w-full h-full absolute top-0 z-20 bg-palette-base-white pt-14" id="topSetting">
  <ng-container class="w-full h-full" *ngIf="!isActiveGroupSetting">
    <div
      class="w-full h-14 rounded-b-2xl shadow-[0_1px_3px_0_rgba(16,24,40,0.1)] p-2 flex justify-between items-center text-lg font-semibold text-palette-gray-800 bg-palette-base-white fixed top-0 z-40">
      <div class="h-10 w-10 cursor-pointer flex items-center justify-center" (click)="onBackToGroup()">
        <i class="sctr-icon-chevron-left text-xl"></i>
      </div>
      <span> {{ 'FANPAGE.NAVIGATION.SETTINGS' | translate }} </span>
      <div class="h-10 w-10"></div>
    </div>
    <ng-container [ngTemplateOutlet]="menuItems"> </ng-container>
  </ng-container>
  <ng-container class="w-full h-full" *ngIf="isActiveGroupSetting">
    <div
      *ngIf="activeIndex !== groupSettings.generalInformation.id"
      class="w-full h-14 rounded-b-2xl shadow-[0_1px_3px_0_rgba(16,24,40,0.1)] p-2 flex justify-between items-center text-lg font-semibold text-palette-gray-800 mb-1 bg-palette-base-white fixed top-0 z-40">
      <div class="h-10 w-10 cursor-pointer flex items-center justify-center" (click)="onBackToGroupSettings()">
        <i class="sctr-icon-chevron-left text-xl"></i>
      </div>
      <span>
        {{ 'GROUP.SETTING.' + selectedSettingTitle | translate }}
      </span>
      <div class="h-10 w-10"></div>
    </div>
    <ng-container [ngTemplateOutlet]="settingContents" class="mt-16"> </ng-container>
  </ng-container>
</div>

<ng-template #menuItem let-groupSettingOption="groupSettingOption" let-quantity="quantity">
  <button
    class="h-11 py-2.5 px-[14px] gap-x-2 text-base font-semibold flex items-center justify-between text-palette-gray-700"
    [ngClass]="
      activeIndex === groupSettingOption.id
        ? ' md:bg-branding-primary-50 md:text-branding-primary-700 md:rounded-lg'
        : ''
    "
    (click)="selectedSetting(groupSettingOption.id, groupSettingOption.title)">
    <div class="flex items-center gap-x-2" title="{{ 'GROUP.SETTING.' + groupSettingOption.title | translate }}">
      <i class="{{ groupSettingOption.icon }} text-xl"></i>
      <span class="line-clamp-1">{{ 'GROUP.SETTING.' + groupSettingOption.title | translate }}</span>
    </div>
    <div class="flex items-center text-center gap-1 text-palette-gray-600">
      <span
        *ngIf="quantity"
        class="text-sm"
        [ngClass]="activeIndex === groupSettingOption.id ? ' md:text-branding-primary-700' : ''"
        >{{ quantity }}</span
      >
      <i class="block md:hidden sctr-icon-chevron-right text-xl"></i>
    </div>
  </button>
</ng-template>

<ng-template #menuItems>
  <div
    class="menuItems w-full h-full flex flex-col md:bg-white rounded-md md:shadow-sm md:border border-palette-gray-200">
    <div class="py-3 md:py-6 px-0 md:px-4 flex flex-col gap-2" *ngIf="activeIndex >= 0">
      <ng-container
        *ngIf="!isShowRoleSetting && !isLoading"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.generalInformation
        }"></ng-container>
      <ng-container
        *ngIf="postPendingList && postPendingList.length; else noPendingPost"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.pendingPosts,
          quantity: postPendingList.length
        }"></ng-container>
      <ng-template #noPendingPost>
        <ng-container
          [ngTemplateOutlet]="menuItem"
          [ngTemplateOutletContext]="{
            groupSettingOption: groupSettings.pendingPosts
          }"></ng-container>
      </ng-template>
      <ng-container
        *ngIf="memberPendingGroup$ | async as memberPendingGroup"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.memberRequests,
          quantity: memberPendingGroup.length + dataGroupAllRequestInvited.length
        }"></ng-container>
      <ng-container
        *ngIf="dataReportContent && dataReportContent.length; else noReportRequest"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.reportedContents,
          quantity: dataReportContent.length
        }"></ng-container>
      <ng-template #noReportRequest>
        <ng-container
          [ngTemplateOutlet]="menuItem"
          [ngTemplateOutletContext]="{
            groupSettingOption: groupSettings.reportedContents
          }"></ng-container>
      </ng-template>
      <ng-container
        *ngIf="!isShowRoleSetting && !isLoading"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.groupRules
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{
          groupSettingOption: groupSettings.groupStatistics
        }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #settingContents>
  <div class="w-full flex flex-col bg-palette-gray-200 rounded-md active-tab-view">
    <ng-container [ngSwitch]="activeIndex">
      <ng-container *ngSwitchCase="groupSettings.generalInformation.id">
        <span *ngIf="infoDetailGroup$ | async as infoDetailGroup">
          <general-information
            *ngIf="!isLoading"
            [dataInfoDetailGroup]="infoDetailGroup"
            (isBackToGroupSettings)="onBackToGroupSettings()"></general-information
        ></span>
      </ng-container>
      <ng-container *ngSwitchCase="groupSettings.pendingPosts.id">
        <group-pending-posts></group-pending-posts>
      </ng-container>
      <ng-container *ngSwitchCase="groupSettings.memberRequests.id">
        <ng-container *ngIf="memberPendingGroup$ | async as memberPendingGroup">
          <member-requests
            [property]="memberPendingGroup"
            [dataGroupAllRequestInvited]="dataGroupAllRequestInvited"></member-requests>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="groupSettings.reportedContents.id">
        <report-content
          [isLoadingReportPost]="isLoadingReportPost"
          [dataReportContent]="dataReportContent"
          (onAction)="PendingPostAction($event)"
          (reportContent)="handlePostAction($event)"
          (searchValue)="handleSearchReportContent($event)"
          (declineAll)="declineAll($event)"
          (approveAll)="approveAll($event)"></report-content>
      </ng-container>
      <ng-container *ngSwitchCase="groupSettings.groupRules.id">
        <group-rules (isBackToGroupSettings)="onBackToGroupSettings()"></group-rules>
      </ng-container>
      <ng-container *ngSwitchCase="groupSettings.groupStatistics.id">
        <group-statistics></group-statistics>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
