import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import { MEDIA_TYPE } from '@app/shared/constant';
import { environment } from '@env/environment';

@Component({
  selector: 'galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.scss']
})
export class GalleriaComponent {
  @Input() mediaType: string;
  @Input() avatar: any;
  @Input() isShowAvatar: any;
  @Output() onCloseGallery = new EventEmitter<boolean>();

  baseUrl: string = environment.baseURL;
  type = MEDIA_TYPE;
  constructor(private fanpageService: FanpageService) {}

  closeGalleryHandler(): void {
    this.onCloseGallery.emit();
    this.fanpageService.showGallery = false;
  }
}
