import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'content-not-available',
  templateUrl: './content-not-available.component.html',
  styleUrls: ['./content-not-available.component.scss']
})
export class ContentNotAvailableComponent {
  @Input() isShowReturnBtn = true;
  @Input() isArchived = false;
  @Input() isStory = false;
  @Output() goBackEvent = new EventEmitter();

  constructor(
    private router: Router,
    private titleService: Title
  ) {}

  goToHomePage() {
    this.router.navigateByUrl('');
    this.titleService.setTitle(environment.APP_NAME);
    this.goBackEvent.emit();
  }
}
