<div class="h-16 flex justify-between items-center p-4 border-t border-palette-gray-200">
  <div>
    <p-checkbox [binary]="true" [(ngModel)]="checkBoxValue" (onChange)="onSelectedPost($event)"></p-checkbox>
  </div>
  <div class="flex items-center gap-3">
    <button
      class="review-post-buttons bg-white text-palette-gray-700 hover:bg-palette-gray-200 flex items-center gap-2"
      type="button"
      (click)="onRejectPost()">
      <i class="sctr-icon-x-close text-lg/none"></i>
      {{ 'COMMON.BUTTON_ACTION.DECLINE' | translate }}
    </button>
    <button
      class="review-post-buttons bg-branding-primary-600 text-white hover:bg-branding-primary-700 flex items-center gap-2"
      type="button"
      (click)="onApprovePost()">
      <i class="sctr-icon-check text-lg/none"></i>
      {{ 'POST_REVIEW.APPROVE' | translate }}
    </button>
  </div>
</div>
