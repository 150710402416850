import { ReportCategory } from '@app/lib/api/report/api.report.model';
import { ADS_CREATE } from '@app/shared/constant';
import { createReducer, on } from '@ngrx/store';
import * as reportType from './report.actions';

export interface ReportState {
  reportCategories: any;
}

const initialState: ReportState = {
  reportCategories: null
};

export const ReportReducer = createReducer(
  initialState,
  on(reportType.getReportCategories, state => ({ ...state })),
  on(reportType.getReportCategoriesSuccess, (state, { reportCategories }) => {
    return {
      ...state,
      reportCategories: reportCategories.filter((item: ReportCategory) => item.key !== ADS_CREATE.other)
    };
  }),
  on(reportType.getReportCategoriesFailed, (state, action) => ({ ...state, error: action.message }))
);
