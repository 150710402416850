import { Component, Input, SimpleChanges } from '@angular/core';
import { LocationData } from '@app/shared/components/statistics/statistics.models';
import { FollowerApiModels } from '@app/lib/api/follower/api.follower.model';
import { FollowerService } from '@app/core/services/follower.service';
import { GroupService } from '@app/core/services/group.service';

@Component({
  selector: 'group-member-stats',
  templateUrl: './group-member-stats.component.html',
  styleUrls: ['./group-member-stats.component.scss']
})
export class GroupMemberStatsComponent {
  @Input() groupId: string | null;
  ageAndGenderData: FollowerApiModels.AgeAndGenderStats;
  locationData: LocationData[] = [];

  constructor(
    private followerService: FollowerService,
    private groupService: GroupService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['groupId']) {
      if (this.groupId) {
        this.getAgeAndGenderChartData(this.groupId);
        this.getLocationData(this.groupId);
      }
    }
  }

  getAgeAndGenderChartData(groupId: string): void {
    this.groupService.getGroupAgeAndGenderStats(groupId).subscribe(res => {
      this.ageAndGenderData = res;
    });
  }

  getLocationData(groupId: string): void {
    this.followerService.getMemberLocationStats(groupId).subscribe(res => {
      this.locationData = this.followerService.proceedLocationData(res);
    });
  }
}
