import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { FollowerApiModels } from '@app/lib/api/follower/api.follower.model';
import {
  GroupProfileInfo,
  PageAPIGroup,
  PostPending,
  SearchUserInfoPagingData,
  SearchUserInfoResponse
} from '@app/lib/api/group/api.group.model';
import { PageAPI } from '@app/lib/api/page/api.page.model';
import { PostListPaging, PostListResponse, TopPerfPost, TopPerfPostResponse } from '@app/lib/api/post/api.post.model';
import { StatsApiModels } from '@app/lib/api/statistics/api.statistics.models';
import { PutLeaveGroup } from '@app/modules/main/group/components/group-info/group-events/tab-events-upcoming/events-tab.component';
import { PostIdsList } from '@app/modules/main/group/components/group-tab/group-settings/member-requests/member-requests.component';
import { GroupPost } from '@app/modules/main/group/components/group-tab/group-timeline/group-timeline.component';
import {
  UploadAvatarModel,
  UploadBannerModel
} from '@app/modules/main/group/components/group-tab/group-view/group-view.component';
import { MEMBER_STATUS, PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, SORT } from '@app/shared/constant';
import { GroupDetailModel } from '@app/shared/models/group.model';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  isUpdateInfo = false;
  pageNumMyGroup = 0;
  pageSizeMyGroup = 0;
  pageNumGroupJoined = 0;
  pageSizeGroupJoined = 0;
  private api: ApiClient;
  constructor(public readonly httpClient: HttpClient) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  // get info detail group
  getGroupDetail = (groupId: string, admin = false) => this.api.group.getGroupDetail(groupId, admin);
  //get group role
  getGroupRole = (groupId: string) => this.api.group.getGroupRole(groupId);

  // get member group
  getMemberGroup = (groupId: string, pageNum = PAGE_NUM_DEFAULT, pageSize = PAGE_SIZE_DEFAULT) =>
    this.api.group.getMembers(groupId, pageNum, pageSize);

  // get member pending group
  getMemberPendingGroup = (groupId: string) => this.api.group.getPendingMembers(groupId);

  // put member accept group
  putMemberAcceptGroup(groupId: string, postIdsList: PostIdsList) {
    return this.api.group.putMemberAccepted(groupId, postIdsList);
  }

  // put member decline group
  putMemberDeclineGroup(groupId: string, postIdsList: PostIdsList) {
    return this.api.group.putMemberDeclined(groupId, postIdsList);
  }

  // put member ban group
  postMemberBanGroup(groupId: string, user_id: string) {
    return this.api.group.putMemberBanned(groupId, user_id);
  }

  getGroupAboutDetail = (groupId: string) => this.api.group.getGroupAboutDetail(groupId);

  putGroupAboutDetail = (groupId: string, payload: any) => this.api.group.putGroupAboutDetail(groupId, payload);

  getGroupContactDetail = (groupId: string) => this.api.group.getGroupContactDetail(groupId);

  putGroupContactDetail = (groupId: string, payload: any) => this.api.group.putGroupContactDetail(groupId, payload);

  // view get post group
  getViewPostGroup = (groupId: string, params: GroupPost) => this.api.post.getGroupViewPost(groupId, params);

  // tạo bài pots group
  postGroupCreate = (newsPostGroup: any) => this.api.group.postGroupCreate(newsPostGroup);

  // upload Avatar group
  uploadAvatar = (param: UploadAvatarModel) => this.api.group.uploadAvatar(param);

  // upload Avatar group
  uploadBannerGroup = (param: UploadBannerModel) => this.api.group.uploadBanner(param);

  // get member group
  getUserInfoMember = (id: string) => this.api.group.getUserInfoMember(id);

  // get report post group
  getGroupReportContent = (param: any) => {
    return this.api.group.getGroupReportContent(param);
  };

  // upload Avatar group
  uploadGroupAvatar(param: UploadAvatarModel, groupId: any) {
    return this.api.group.uploadGroupAvatar(param, groupId);
  }

  getGroupReportContentPost(groupId: string): Observable<any[]> {
    return this.api.post.getGroupReportContentPost(groupId);
  }

  getGroupMemberPendingPost(groupId: string): Observable<any[]> {
    return this.api.group.getGroupMemberPendingPost(groupId);
  }

  getGroupAllRequestInvitedUsers = (groupId: string | null) => this.api.group.getGroupAllRequestInvitedUsers(groupId);

  getPendingPost(groupId: string | null, params: GroupPost): Observable<PostPending[]> {
    return this.api.group.getPendingPost(groupId, params);
  }

  manageByPostIds(groupID: string, action: 'decline' | 'accept', ids: string[]) {
    return this.api.group.manageByPostIds(groupID, action, ids);
  }

  updateGroupInfo(groupInfo: GroupDetailModel): Observable<GroupDetailModel> {
    return this.api.group.updateGroupInfo(groupInfo);
  }

  banUser(user_id: string, group_id: string | null) {
    return this.api.group.banUser(user_id, group_id);
  }

  updateUserRole(user_id: string, role: string, group_id: string | null) {
    return this.api.group.updateUserRole(user_id, role, group_id);
  }

  getInviteFriendList(groupId: string | null, page = 0) {
    return this.api.group.getInviteFriendList(groupId, page);
  }

  searchInviteFriendList(groupId: string | null, keyword: string, page = 0) {
    return this.api.group.searchInviteFriendList(groupId, keyword, page);
  }

  inviteFriendsToGroup(friendList: any, groupId: string | null) {
    return this.api.group.inviteFriendsToGroup(friendList, groupId);
  }

  getGroupRule(groupId: string | null) {
    return this.api.group.getGroupRule(groupId);
  }

  editGroupRule(groupRule: string, groupId: string | null) {
    return this.api.group.editGroupRule(groupRule, groupId);
  }

  PostFilterGroupReportCategories(params: any) {
    return this.api.group.PostFilterGroupReportCategories(params);
  }

  postReportGroup(param: any) {
    return this.api.group.postReportGroup(param);
  }

  groupRemoveRoleMember(user_id: string, group_id: string | null) {
    return this.api.group.groupRemoveRoleMember(user_id, group_id);
  }

  groupRemoveMember(param: any, group_id: string | null) {
    return this.api.group.groupRemoveMember(param, group_id);
  }

  //invite admin as mod
  groupInviteRole(param: any, group_id: string | null) {
    return this.api.group.groupInviteRole(param, group_id);
  }

  //invite admin as mod
  groupTransferOwner(param: any, group_id: string | null) {
    return this.api.group.groupTransferOwner(param, group_id);
  }

  groupAcceptRoleInvitation(group_id: string | null) {
    return this.api.group.groupAcceptRoleInvitation(group_id);
  }

  groupDeclineRoleInvitation(group_id: string | null) {
    return this.api.group.groupDeclineRoleInvitation(group_id);
  }

  PostGroupPendingSearch(group_id: string | null, searchingText: string) {
    return this.api.group.PostGroupPendingSearch(group_id, searchingText);
  }

  getGroupRules(groupRule: string | null, groupId: string | null) {
    return this.api.group.getGroupRules(groupRule, groupId);
  }

  //confirm password
  postConfirmPassWord(confirm_password: string) {
    return this.api.group.postConfirmPassWord(confirm_password);
  }

  getGroupDiscover(
    pageSize: number,
    pageNum: number,
    textSearch: string
  ): Observable<PageAPIGroup.MyPageResponseDiscover> {
    return this.api.group.getGroupDiscover(pageSize, pageNum, textSearch);
  }

  groupReportContent(param: any) {
    return this.api.group.groupReportContent(param);
  }

  putGroupCancelRequestPromote = (groupId: string, id: string) =>
    this.api.group.putGroupCancelRequestPromote(groupId, id);

  putGroupCancelRequestInvitedUsers = (groupId: string, user_id: string) =>
    this.api.group.putGroupCancelRequestInvitedUsers(groupId, user_id);

  postGroupRejectPending(params: any[]): Observable<any[]> {
    return this.api.group.postGroupRejectPending(params);
  }

  getGroup(pageSize: number, pageNum: number, textSearch: string): Observable<GroupProfileInfo[] | any> {
    return this.api.group.getGroup(pageSize, pageNum, textSearch);
  }

  getGroupsWithAdmins(pageSize = PAGE_SIZE_DEFAULT, pageNum = PAGE_NUM_DEFAULT): Observable<GroupProfileInfo[] | any> {
    return this.api.group.getGroupsWithAdmins(pageSize, pageNum);
  }

  getGroupsWithNoAdmins(
    pageSize = PAGE_SIZE_DEFAULT,
    pageNum = PAGE_NUM_DEFAULT
  ): Observable<GroupProfileInfo[] | any> {
    return this.api.group.getGroupsWithNoAdmins(pageSize, pageNum);
  }

  createGroup(group: GroupProfileInfo) {
    return this.api.group.createGroup(group);
  }

  //List all request to join group
  getAllGroupRequestJoin(pageSize: number, pageNum: number, textSearch: string) {
    return this.api.group.getAllGroupRequestJoin(pageSize, pageNum, textSearch);
  }

  //List all group to join
  getAllGroupToJoin(pageSize: number, pageNum: number, textSearch: string) {
    return this.api.group.getAllGroupToJoin(pageSize, pageNum, textSearch);
  }

  // put member decline group
  putCancelRequestGroup(groupId: string) {
    const requestData = {};
    return this.api.group.putCancelRequestGroup(groupId);
  }

  // put leave group
  putLeaveGroup(groupId: PutLeaveGroup) {
    return this.api.group.putLeaveGroup(groupId);
  }

  //List all group pending invites
  getAllGroupPendingInvites(pageSize: number, pageNum: number, textSearch: string) {
    return this.api.group.getAllGroupPendingInvites(pageSize, pageNum, textSearch);
  }

  //List all friends
  getAllFriends() {
    return this.api.group.getAllFriends();
  }

  // put leave group
  putAcceptToJoinGroup(groupId: string) {
    return this.api.group.putAcceptToJoinGroup(groupId);
  }

  // put leave group
  putDeclineToJoinGroup(groupId: string) {
    return this.api.group.putDeclineToJoinGroup(groupId);
  }

  // leave group owner
  groupLeave(group_ids: any) {
    return this.api.group.groupLeave(group_ids);
  }

  // leave group owner
  groupJoin(group_ids: any) {
    return this.api.group.groupJoin(group_ids);
  }

  //search all friends
  searchAllFriends(value: string) {
    return this.api.group.searchAllFriends(value);
  }

  getGroupStatsInsights(
    groupId: string,
    startTime: number,
    endTime: number
  ): Observable<StatsApiModels.GroupStatsInsights[]> {
    return this.api.statistics.getGroupStatsInsights(groupId, startTime, endTime).pipe(
      map((res: any) => {
        if (!res.success) {
          return res;
        }
        return res.data;
      })
    );
  }

  getGroupAgeAndGenderStats(groupId: string): Observable<FollowerApiModels.AgeAndGenderStats> {
    return this.api.group.getGroupAgeAndGenderStats(groupId).pipe(
      map(res => {
        const nullData = {} as FollowerApiModels.AgeAndGenderStats;
        return res.data ? res.data.stats : nullData;
      })
    );
  }

  getGroupTopPerfPost(groupId: string): Observable<TopPerfPost> {
    return this.api.post.getGroupTopPerfPost(groupId).pipe(
      map((res: TopPerfPostResponse) => {
        const nullData = {} as TopPerfPost;
        return res.data ? res.data : nullData;
      })
    );
  }

  getGroupPostList(groupId: string, pageSize = 10, pageNum = 0, sortByDate = SORT.desc): Observable<PostListPaging> {
    return this.api.post.getGroupPostList(groupId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  getGroupStatsPostList(
    groupId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListPaging> {
    return this.api.post.getGroupStatsPostList(groupId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  getGroupPendingPosts(
    groupId: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.desc
  ): Observable<PostListPaging> {
    return this.api.post.getGroupPendingPosts(groupId, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  searchGroupPostList(
    groupId: string,
    status: string[],
    searchKey: string,
    pageSize = 10,
    pageNum = 0,
    sortByDate = SORT.asc
  ): Observable<PostListPaging> {
    return this.api.post.searchGroupPostList(groupId, status, searchKey, pageSize, pageNum, sortByDate).pipe(
      map((res: PostListResponse) => {
        const nullData = {} as PostListPaging;
        return res.data ? res.data : nullData;
      })
    );
  }

  putMeRemoveRole(group_id: string | null) {
    return this.api.group.putMeRemoveRole(group_id);
  }

  censorshipPost(postListIds: string[], censorship_status: boolean) {
    return this.api.group.censorshipPost(postListIds, censorship_status);
  }

  searchGroupMembers(
    groupId: string,
    searchKey: string,
    pageNum = 0,
    pageSize = 10,
    userStatus = MEMBER_STATUS.ACCEPTED
  ): Observable<SearchUserInfoPagingData> {
    return this.api.group.searchGroupMembers(groupId, searchKey, pageNum, pageSize, userStatus).pipe(
      map((res: SearchUserInfoResponse) => {
        const nullData = {} as SearchUserInfoPagingData;
        return res.data ? res.data : nullData;
      })
    );
  }

  cancelTransferOwnershipRequest(groupId: string): Observable<boolean> {
    return this.api.group.cancelTransferOwnershipRequest(groupId).pipe(
      map((res: PageAPI.TransferOwnershipResponse) => {
        return res.success;
      })
    );
  }
}
