import { Component, Input } from '@angular/core';

@Component({
  selector: 'search-not-found',
  templateUrl: './search-not-found.component.html',
  styleUrls: ['./search-not-found.component.scss']
})
export class SearchNotFoundComponent {
  @Input() isNotFoundInSearchHeader: boolean;
  @Input() message = 'HEADER.SEARCH.NOT_FOUND';
}
