import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getLocations() {
    return this.api.checkIn.getLocations();
  }
}
