import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { CheckInAPI } from '@app/lib/api/check-in/api.check-in.model';
import { CHECK_IN } from '@app/shared/constant';
const STATUS_LIST = [
  { status: 'Excited' },
  { status: 'Cheerful' },
  { status: 'Happy' },
  { status: 'Loving' },
  { status: 'Relax' },
  { status: 'Playful' },
  { status: 'Frighten' },
  { status: 'Sad' },
  { status: 'Angry' },
  { status: 'Quite' },
  { status: 'Scornful' },
  { status: 'Sick' },
  { status: 'Cool' },
  { status: 'Sleepy' },
  { status: 'Fine' },
  { status: 'Bored' },
  { status: 'Surprised' },
  { status: 'Tearful' }
];

interface Status {
  status: string;
  label: string;
}

@Component({
  selector: 'custom-status',
  templateUrl: './custom-status.component.html',
  styleUrls: ['./custom-status.component.scss']
})
export class CustomStatusComponent {
  @Input() isCheckIn: boolean = false;
  @Input() inputValue: string | null;
  @Input() selectedStatus: string = '';
  @Input() loadingMoreLocation: boolean = false;
  @Input() searchText: string;
  @Input() locations?: CheckInAPI.LocationSelection[];
  @Input() onSelect: (item: Status) => void;
  @Input() selectedLocation?: CheckInAPI.CheckInResponse;
  @Output() selectedStatusChange = new EventEmitter();
  @Output() selectedLocationEmit = new EventEmitter();
  @Output() scrollToBottom = new EventEmitter();
  listItems: Status[];
  isEditting = false;

  constructor(private translateService: TranslationService) {}

  ngOnInit() {
    if (!this.isCheckIn) {
      this.translateFeelStatuses();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedLocation'] && changes['selectedLocation'].currentValue) {
      this.locations && this.handleSetActiveLocation();
    }
  }

  translateFeelStatuses(): void {
    this.translateService.language$.subscribe(() => {
      const translateResult = this.translateService.getTranslation('POST.STATUS');
      this.listItems = STATUS_LIST.map(item => {
        return { ...item, label: translateResult[item.status.toLocaleUpperCase()] };
      });
    });
  }

  unSelectLocation(): void {
    this.selectedLocation = undefined;
    const data: { selectedStatus?: CheckInAPI.CheckInResponse; isEditting: boolean } = {
      selectedStatus: this.selectedLocation,
      isEditting: true
    };
    this.selectedLocationEmit.emit(data);
  }

  onSelectLocation(item: CheckInAPI.LocationSelection): void {
    const isLocationId = !!this.selectedLocation && this.selectedLocation.object_id === item.id;
    this.selectedLocation = !isLocationId
      ? {
          object_id: item.id,
          object_type: CHECK_IN.FANPAGE,
          object_data_check_in: {
            page_name: item.name,
            page_id: item.id
          }
        }
      : undefined;
    const data: { selectedStatus?: CheckInAPI.CheckInResponse; isEditting: boolean } = {
      selectedStatus: this.selectedLocation,
      isEditting: true
    };
    this.selectedLocationEmit.emit(data);
    this.locations?.forEach(item => (item.isActive = false));
    this.handleSetActiveLocation();
  }

  handleSetActiveLocation(): void {
    this.locations?.map(loc => {
      const isPageId = !!this.selectedLocation && loc.id === this.selectedLocation.object_id;
      return {
        ...loc,
        isActive: isPageId
      };
    });
  }

  handleLoadMoreFanpage(): void {
    if (this.isCheckIn) this.scrollToBottom.emit();
  }
}
