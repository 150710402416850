<div class="flex flex-col gap-4 mb-5 bg-palette-base-white rounded-md border border-palette-gray-200">
  <div class="flex justify-between px-4 pt-4 pb-4 border-b border-solid border-gray-200">
    <div class="flex gap-3">
      <button
        [ngClass]="selectedButton === tabEvents.upcoming ? 'selected' : 'unselected'"
        class="btn-header-tab"
        (click)="selectButtonClick(tabEvents.upcoming)">
        {{ 'FANPAGE.UPCOMING' | translate }}
      </button>
      <button
        [ngClass]="selectedButton === tabEvents.current ? 'selected' : 'unselected'"
        class="btn-header-tab"
        (click)="selectButtonClick(tabEvents.current)">
        {{ 'FANPAGE.HAPPENING_NOW' | translate }}
      </button>
      <button
        [ngClass]="selectedButton === tabEvents.past ? 'selected' : 'unselected'"
        class="btn-header-tab"
        (click)="selectButtonClick(tabEvents.past)">
        {{ 'FANPAGE.PAST' | translate }}
      </button>
    </div>
    <div class="create-group gap-3">
      <span class="p-input-icon-left h-10 w-[320px] text-gray-500">
        <i class="sctr-icon-search-lg text-lg top-[35%] cursor-pointer"></i>
        <input
          type="text"
          pInputText
          [formControl]="eventSearchValue"
          placeholder="{{ 'FANPAGE.SEARCH' | translate }}"
          class="w-[320px] h-10 text-base font-normal rounded-lg" />
      </span>
      <div *ngIf="!isModerate && isShowCreateButton">
        <button
          type="button"
          icon="pi pi-bars"
          (click)="showDialogCreateEvents()"
          class="relative h-10 max-w-[200px] w-full rounded-lg border py-2.5 px-4 gap-2 border-palette-gray-300 bg-white hover:bg-palette-gray-200 flex items-center justify-center focus:ring-2 ring-branding-primary-300 ring-offset-2">
          <i class="sctr-icon-plus text-xl"></i>
          <p class="text-sm font-semibold not-italic text-palette-gray-700">
            {{ 'EVENT.CREATE_EVENT' | translate }}
          </p>
        </button>
      </div>
    </div>
  </div>
  <div class="search-mobie gap-3 px-4">
    <span class="p-input-icon-left h-10 w-full">
      <i class="pi pi-search" style="font-size: 20px"></i>
      <input
        type="text"
        pInputText
        [formControl]="eventSearchValue"
        placeholder="{{ 'FANPAGE.SEARCH' | translate }}"
        class="w-full h-10 text-sm font-normal" />
    </span>
    <p-button class="btn-create-group h-10 w-10" icon="sctr-icon-plus" (click)="showDialogCreateEvents()"></p-button>
  </div>
  <ng-container *ngIf="!isLoading; else loading">
    <div class="content px-4 pt-0 pb-4" [ngClass]="{ hidden: selectedButton !== tabEvents.upcoming }">
      <events-tab
        [eventsActions]="
          [groupRoles.OWNER, groupRoles.MOD, groupRoles.ADMIN, groupRoles.EDITOR].includes(userRole ?? '')
            ? eventsActionsAdmin
            : eventsActionsUser
        "
        [dataGroupEvents]="dataGroupEvents"
        [dataFanPageDetail]="dataFanPageDetail"
        [userRole]="userRole"
        [fanPageIsShow]="fanPageIsShow"
        [currentTab]="selectedButton"
        (actionDropDow)="actionDropDow1($event)"
        (actionAttend)="handleActionAttend($event)"
        (navigateDetail)="handleNavigateDetail($event)"
        (actionHeart)="handleActionHeart($event)"></events-tab>
    </div>
    <div class="content px-4 pt-0 pb-4" [ngClass]="{ hidden: selectedButton !== tabEvents.current }" *ngIf="!isLoading">
      <events-tab
        [eventsActions]="
          [groupRoles.OWNER, groupRoles.MOD, groupRoles.ADMIN, groupRoles.EDITOR].includes(userRole ?? '')
            ? eventsActionsAdmin
            : eventsActionsUser
        "
        [currentTab]="selectedButton"
        [dataGroupEvents]="dataGroupEventsHappeningNow"
        [fanPageIsShow]="fanPageIsShow"
        (actionDropDow)="actionDropDow1($event)"
        (actionAttend)="handleActionAttend($event)"
        (navigateDetail)="handleNavigateDetail($event)"
        (actionHeart)="handleActionHeart($event)"></events-tab>
    </div>
    <div class="content px-4 pt-0 pb-4" [ngClass]="{ hidden: selectedButton !== tabEvents.past }" *ngIf="!isLoading">
      <events-tab
        [eventsActions]="pastEventMenu"
        [currentTab]="selectedButton"
        [dataGroupEvents]="dataGroupEventsPast"
        [fanPageIsShow]="fanPageIsShow"
        (actionDropDow)="actionDropDow1($event)"
        (actionAttend)="handleActionAttend($event)"
        (navigateDetail)="handleNavigateDetail($event)"
        (actionHeart)="handleActionHeart($event)"></events-tab>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="border-gray-200 grid gap-4 md:grid-cols-2 xs:grid-cols-1 px-4 pt-0 pb-4">
      <ng-container *ngFor="let _ of fanpageService.createRange(4)">
        <div class="flex gap-4 flex-col shadow-sm border border-palette-gray-200 rounded-md bg-white">
          <p-skeleton height="20px" width="350px" class="pt-4 pl-4"></p-skeleton>
          <div class="flex gap-3 px-4 pb-4">
            <p-skeleton size="120px"></p-skeleton>
            <div class="flex flex-col gap-1 w-full">
              <p-skeleton height="25px" width="50px"></p-skeleton>
              <p-skeleton height="25px" width="250px"></p-skeleton>
              <p-skeleton height="20px" width="250px"></p-skeleton>
              <p-skeleton height="40px" width="250px"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <p-paginator
    #paginator
    *ngIf="!isChangeTab"
    [alwaysShow]="false"
    [first]="pageNumDefault"
    [rows]="pageSizeDefault"
    [totalRecords]="pageTotal"
    (onPageChange)="onPageChange($event)"></p-paginator>
</div>
