import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmojiList } from '@app/shared/models/post';

@Component({
  selector: 'icon-popup',
  templateUrl: './icon-popup.component.html',
  styleUrls: ['./icon-popup.component.scss']
})
export class IconPopupComponent {
  @Input() selectedEmoji: any;
  @Input() overlayVisible = false;
  @Input() isCommentPopup = false;
  @Input() isStoryPopup = false;
  @Output() emojiChanged = new EventEmitter();
  @Output() closePopup = new EventEmitter();
  boxVisible = false;
  emojiList = EmojiList;

  handleMouseEvent(flag: boolean) {
    this.closePopup.emit(flag);
  }

  selectEmoji(emoji: any) {
    if (emoji.type === this.selectedEmoji?.type) {
      this.emojiChanged.emit('');
    } else {
      this.emojiChanged.emit(emoji.type);
    }
  }
}
