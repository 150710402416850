import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PAGE_SIZE_OPTIONS } from '@app/shared/constant';

export interface Paging {
  page: number;
  pageSize: number;
  totalElement: number;
}

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() paging: Paging = {
    page: 0,
    pageSize: 10,
    totalElement: 0
  };
  @Input() visibleItemsCount = false;
  @Output() pageSize = new EventEmitter();
  @Output() pagingDetail = new EventEmitter();
  selectedPageSize = PAGE_SIZE_OPTIONS[0].size;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  currentLastRow: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['paging']) {
      if (this.paging && Object.keys(this.paging).length) {
        this.currentLastRow = Math.min(this.paging.pageSize * (this.paging.page + 1), this.paging.totalElement);
      }
    }
  }

  onChangedPageSize(size: number): void {
    this.pageSize.emit(size);
  }

  onPageChange(event: any): void {
    this.pagingDetail.emit(event);
  }
}
