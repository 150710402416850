import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { Comment, CommentReplies, CommenterList } from '@app/lib/api/comments/api.comments.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getCommentsByPostId(object_id: string, pageNum: number, pageSize: number): Observable<Comment[]> {
    return this.api.comments.getCommentsByPostId(object_id, pageNum, pageSize);
  }

  getCommentsByParentId(parentId: string, pageNum: number, pageSize: number): Observable<CommentReplies> {
    return this.api.comments.getCommentsByParentId(parentId, pageNum, pageSize);
  }

  getCommentObjectById(object_id: string): Observable<Comment> {
    return this.api.comments.getCommentObjectById(object_id);
  }

  postComment(body: any): Observable<Comment> {
    return this.api.comments.postComment(body);
  }

  deleteComment(object_id: string): Observable<any> {
    return this.api.comments.deleteComment(object_id);
  }

  putComment(comment: Comment): Observable<Comment> {
    return this.api.comments.putComment(comment);
  }

  getCommenterListByEntityId(commentId: string): Observable<CommenterList> {
    return this.api.comments.getCommenterListByEntityId(commentId);
  }

  getCommentBloomFilter(): Observable<string> {
    return this.api.comments.getCommentBloomFilter();
  }

  getCommentList(postIdList: string[]): Observable<any> {
    return this.api.comments.getCommentList(postIdList);
  }
}
