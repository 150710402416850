import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CommonService } from '@app/core/services/common.service';
import { LoginService } from '@app/core/services/login.service';
import { UserService } from '@app/core/services/user.service';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

const ERROR_CODES = {
  incorrectOTPCode: 46084,
  blockedResendOTP: 46081
};

const BLOCK_RESEND_MESSAGE_COUNTDOWN = 10;

@Component({
  selector: 'auth-dialog',
  templateUrl: './auth-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent {
  @Input() isVisible = false;
  @Input() isOTPRequired = false;
  @Input() isOnlyOTPRequired = false;
  @Output() isAuthorized = new EventEmitter();
  @Output() isVisibleChange = new EventEmitter();
  userInfo$ = this.store.select(selectUserInfo);
  showPassword = false;
  baseUrl: string = environment.baseURL;
  password = new FormControl('', Validators.required);
  appName = environment.APP_NAME;
  email = '';
  areaCode = '';
  phoneNumber = '';
  isLoading = false;
  isPasswordVerified = false;
  isVerifyCodeFailed = false;
  isBlockedResend = false;
  OTPCode = '';
  countdown = BLOCK_RESEND_MESSAGE_COUNTDOWN;

  constructor(
    private store: Store,
    private userService: UserService,
    public readonly loginService: LoginService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.resetOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['isVisible']) {
      if (this.isVisible) {
        this.resetOnInit();
        if (this.isOnlyOTPRequired) this.resendCode();
      }
    }
  }

  resetOnInit(): void {
    this.password.setValue('');
    this.password.setErrors(null);
    this.password.markAsPristine();
    this.isLoading = false;
    this.isPasswordVerified = this.isOnlyOTPRequired;
    this.isVerifyCodeFailed = false;
    this.isBlockedResend = false;
    this.OTPCode = '';
    this.userInfo$.subscribe(res => {
      if (res) {
        this.email = res.email ? res.email : '';
        this.areaCode = res.area_code ? res.area_code : '';
        this.phoneNumber = res.phone ? res.phone : '';
      }
    });
  }

  onCloseAuthDialog(isClose: boolean): void {
    if (isClose) {
      this.isVisibleChange.emit(false);
      this.resetOnInit();
    }
  }

  getImgUrl(id: string | undefined): string {
    return this.commonService.getImageUrl(id);
  }

  onClickConfirmBtn(): void {
    if (this.isOTPRequired && this.isPasswordVerified) {
      if (this.email.length || (this.phoneNumber.length && this.areaCode.length)) {
        const phoneOrEmail = this.email.length ? this.email : this.phoneNumber;
        const areaCode = this.email.length ? '' : this.areaCode;
        const verifyCodeData = {
          phone_or_email: phoneOrEmail,
          area_code: areaCode,
          get_code_verification: this.OTPCode
        };
        this.isLoading = true;
        this.userService.verifyOTPCode(verifyCodeData).subscribe(res => {
          if (res) {
            if (res.success) {
              setTimeout(() => {
                this.isLoading = false;
                this.isAuthorized.emit(true);
              }, 1000);
            } else {
              this.isLoading = false;
              if (res!.error!.code) {
                if (res.error.code === ERROR_CODES.incorrectOTPCode) {
                  this.isVerifyCodeFailed = true;
                } else if (res!.error!.code === ERROR_CODES.blockedResendOTP) {
                  this.blockedResend();
                }
              }
            }
          } else {
            this.isLoading = false;
          }
        });
      }
      return;
    }
    if (this.password.value) {
      this.isLoading = true;
      this.userService.confirmPassword(this.password.value).subscribe(res => {
        if (res) {
          if (this.isOTPRequired && (this.email.length || (this.phoneNumber.length && this.areaCode.length))) {
            const phoneOrEmail = this.email.length ? this.email : this.phoneNumber;
            const areaCode = this.email.length ? '' : this.areaCode;
            const sendCodeData = {
              phone_or_email: phoneOrEmail,
              area_code: areaCode,
              is_check_phone_or_email: false
            };
            this.userService.sendOTPCode(sendCodeData).subscribe(res => {
              this.isLoading = false;
              if (res) {
                this.isPasswordVerified = res.success ? true : false;
              }
            });
          } else {
            if (!this.isOTPRequired) {
              setTimeout(() => {
                this.isLoading = false;
                this.isAuthorized.emit(true);
              }, 1000);
            }
          }
        } else {
          this.isLoading = false;
          this.password.setErrors({ 'incorrect-password': true });
        }
      });
    } else {
      this.password.setErrors({ 'incorrect-password': true });
    }
  }

  isDisableConfirmBtn(): boolean {
    const isDisabledConfirmPW =
      !this.password.value ||
      (!this.password.pristine && (this.password.hasError('required') || this.password.hasError('incorrect-password')));
    const isDisabledConfirmOTP = this.OTPCode.length !== 6 || isNaN(Number(this.OTPCode));
    return this.isOTPRequired && this.isPasswordVerified ? isDisabledConfirmOTP : isDisabledConfirmPW;
  }

  resendCode(): void {
    if (this.isOTPRequired && this.email.length) {
      const sendCodeData = {
        phone_or_email: this.email,
        area_code: '',
        is_check_phone_or_email: false
      };
      this.isLoading = true;
      this.userService.sendOTPCode(sendCodeData).subscribe(res => {
        this.isLoading = false;
        if (res) {
          this.isPasswordVerified = res.success ? true : false;
        }
      });
    }
  }

  blockedResend(): void {
    this.isBlockedResend = true;
    this.startCountDown();
    setTimeout(() => {
      this.onCloseAuthDialog(true);
    }, BLOCK_RESEND_MESSAGE_COUNTDOWN * 1000);
  }

  startCountDown(): void {
    this.loginService.startCountDown(BLOCK_RESEND_MESSAGE_COUNTDOWN).subscribe(value => {
      this.countdown = value;
    });
  }

  hiddenStringPartially(
    text: string,
    maxVisiblePrefixCharacters = 2,
    maxVisibleSuffixCharacters = 2,
    minHiddenCharacters = 2
  ): string {
    const visibleSuffixCharacters = Math.min(
      maxVisibleSuffixCharacters,
      text.length - maxVisiblePrefixCharacters - minHiddenCharacters
    );

    return text.length < minHiddenCharacters + 2
      ? '*'.repeat(text.length)
      : text.length < maxVisiblePrefixCharacters + maxVisibleSuffixCharacters + minHiddenCharacters - 1
      ? text.slice(0, 2) + '*'.repeat(text.length - 2)
      : text.slice(0, maxVisiblePrefixCharacters) +
        '*'.repeat(Math.max(0, text.length - maxVisiblePrefixCharacters - visibleSuffixCharacters)) +
        text.slice(-visibleSuffixCharacters);
  }

  hideEmailPartially(): string {
    const email = this.email;
    if (email.includes('@')) {
      const [username, domain] = email.split('@');
      const hiddenUsername = this.hiddenStringPartially(username, 3);
      const domainName = domain.split('.')[0];
      const domainExtension = domain.substring(domain.indexOf('.'));
      const hiddenDomainName = this.hiddenStringPartially(domainName);
      return `${hiddenUsername}@${hiddenDomainName}${domainExtension}`;
    }
    return email;
  }

  hidePhonePartially(): string {
    const areaCode = this.areaCode;
    const phone = this.phoneNumber;
    return this.hiddenStringPartially(areaCode + phone, 4);
  }
}
