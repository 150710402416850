import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountSettingNotificationsAPI } from '@app/lib/api/account-setting-notifications/api.account-setting-notifications.model';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingNotificationsService {
  private api: ApiClient;

  selectSetting$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getAccountNotifications(user_id: string): Observable<AccountSettingNotificationsAPI.Notification[]> {
    return this.api.accountSettingNotifications.getAccountNotifications(user_id);
  }

  editAccountNotifications(
    notification: AccountSettingNotificationsAPI.Notification
  ): Observable<AccountSettingNotificationsAPI.Notification> {
    const { id, user_id, ...body } = notification;
    return this.api.accountSettingNotifications.editAccountNotifications(user_id || '', body);
  }

  resetAccountNotifications(user_id: string): Observable<AccountSettingNotificationsAPI.Notification[]> {
    return this.api.accountSettingNotifications.resetAccountNotifications(user_id);
  }
}
