import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { CommentsService } from '@app/core/services/comments.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { ADS_ACTION } from '@app/lib/api/client/api.client.constant';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { DetailPostActions } from '@app/modules/main/states/detail-post/detail-post.actions';
import { selectComments } from '@app/modules/main/states/detail-post/detail-post.selectors';
import { selectCommentList } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { ENTITY_TYPE, Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';
import { CommentFormComponent } from '../comment-form/comment-form.component';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements AfterViewInit, AfterViewChecked {
  @Input() postOwnerId: string;
  @Input() totalCommentsAndReplies = 0;
  @Input() pageSize = 3;
  @Input() isPostView = false;
  @Input() post: Post;
  @Input() isPostDetail = false;
  @Input() hideCommentBox = false;
  @Output() totalCommentsAndRepliesChange = new EventEmitter<number>();
  @Output() isShowComment = new EventEmitter<boolean>();
  @Output() createdComment = new EventEmitter();
  @ViewChild(CommentFormComponent) commentFormComponent: CommentFormComponent;
  userInfo$ = this.store.select(selectUserInfo);
  totalComments = 0;
  loginUser: UserInfo;
  @Input() comments: Comment[] = [];
  isFirstExpand = true;
  pageNum = 0;
  isLoading = false;
  language = '';
  isGettingComment = false;
  constructor(
    private store: Store,
    private commentsService: CommentsService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private toastMessageService: ToastMessageService
  ) {}

  ngAfterViewChecked(): void {
    this.setHeightForComments();
  }

  ngAfterViewInit(): void {
    if (this.isPostDetail) {
      this.getComment();
      if (!this.isPostView) {
        this.store.select(selectComments).subscribe(data => {
          if (data.postId && data.postId === this.post.id && data.isReload) {
            this.getComment();
            this.store.dispatch(DetailPostActions.onUpdateComments({ data: {} }));
          }
        });
      }
      return;
    }
    this.store.select(selectCommentList).subscribe(res => {
      if (res.length) {
        const commentData = res.find(el => el.post_id === this.post.id)?.comments;
        if (commentData) {
          this.comments = commentData.filter((item: any) => item.created_by);
        }
      }
      if (!this.isPostView) {
        this.store.select(selectComments).subscribe(data => {
          if (data.postId && data.postId === this.post.id && data.isReload) {
            this.getComment();
            this.store.dispatch(DetailPostActions.onUpdateComments({ data: {} }));
          }
        });
      }
      this.getTotalComments();
    });
  }

  getComment() {
    this.isLoading = true;
    this.commentsService.getCommentsByPostId(this.post.id, this.pageNum, this.pageSize).subscribe(res => {
      if (res) {
        this.comments = res.filter(item => item.created_by);
        if (this.route.snapshot.queryParams['comment-id']) {
          const commentId = this.route.snapshot.queryParams['comment-id'];
          const index = this.comments.findIndex(item => item.id === commentId);
          if (index !== -1) {
            const commentData = this.comments[index];
            this.comments.splice(index, 1);
            this.comments.unshift(commentData);
          }
        }
        this.getTotalComments();
      }
      this.isLoading = false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setHeightForComments();
  }

  getTotalComments() {
    this.totalComments = this.totalCommentsAndReplies;
    this.comments.forEach(el => {
      this.totalComments -= el.total_replies;
    });
  }

  addComment(data: any) {
    const userProfile = localStorage.getItem('user_profile');
    if (userProfile) {
      const userInfo = JSON.parse(userProfile);
      this.language = userInfo.language;
    }
    const param = {
      object_id: this.post.id,
      object_type: ENTITY_TYPE.post,
      parent_id: this.post.id,
      content: data.content,
      message_ranges: data.messageRanges,
      medias: data.medias,
      language: this.language,
      preview_data: data.preview_data
    };
    this.commentsService.postComment(param).subscribe(res => {
      if (res) {
        this.analyticsService.pushPostAction(this.post, ADS_ACTION.comment);
        if (data.mediaLocal !== '') {
          res.medias[0].id = data.mediaLocal;
        }
        this.comments.unshift(res);
        this.createdComment.emit();
        this.onIncreaseTotal();
      } else {
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'FRIEND.USER_BLOCKED');
      }
    });
  }

  onIncreaseTotal(parent_id: string = '') {
    this.comments = this.comments.map(el => {
      if (el.id === parent_id) {
        el.total_replies += 1;
      }
      return el;
    });
    this.calculateTotalComment(1);
  }

  calculateTotalComment(number: number) {
    this.totalCommentsAndReplies += number;
    if (this.isPostView) {
      this.store.dispatch(
        DetailPostActions.onUpdateComments({
          data: { postId: this.post.id, total_comments: number, isReload: false }
        })
      );
    }
    this.totalCommentsAndRepliesChange.emit(this.totalCommentsAndReplies);
    this.getTotalComments();
  }

  showMoreComments() {
    this.isGettingComment = true;
    this.commentsService.getCommentsByPostId(this.post.id, this.pageNum, 10).subscribe(res => {
      if (res) {
        this.isGettingComment = false;
      }
      if (this.isFirstExpand) {
        this.comments = [...this.comments, ...res.slice(this.comments.length).filter(item => item.created_by)];
        this.isFirstExpand = false;
      } else {
        this.comments = [...this.comments, ...res.filter(item => item.created_by)];
      }
      this.pageNum += 1;
      this.getTotalComments();
    });
  }

  deleteComment(id: string) {
    const indexToRemove = this.comments.findIndex(item => {
      return item.id === id;
    });
    if (indexToRemove !== -1) {
      const totalReplies = this.comments[indexToRemove].total_replies;
      this.comments.splice(indexToRemove, 1);
      this.calculateTotalComment(-(1 + totalReplies));
    } else {
      // Comment id deleted is a reply
      this.calculateTotalComment(-1);
    }
    // Hide comments if comments length < 1
    if (this.comments.length < 1 && !this.isPostView) {
      this.isShowComment.emit(false);
    }
  }

  setHeightForComments() {
    if (this.isPostView) {
      const postView = document.getElementById('postView');
      let otherHeight = 0;
      ['p-card-title', 'p-card-content', 'divTopFooter', 'commentForm', 'heightComments'].forEach(key => {
        const el = postView?.getElementsByClassName(key)[0] as HTMLElement;
        if (!el) return;
        if (key !== 'heightComments') {
          otherHeight += el.getBoundingClientRect().height;
        } else {
          otherHeight += 45; // 45 is padding and margin of comments
          el.style.setProperty('height', `calc(100vh - ${otherHeight}px)`);
        }
      });
    }
  }

  focusCommentForm() {
    this.commentFormComponent.onClick();
  }

  isHideComment(commentId: string): boolean {
    let arrHideCommentId = localStorage.getItem('arrHideCommentId');
    let newArrHideCommentId = JSON.parse(arrHideCommentId || '[]');
    return newArrHideCommentId.includes(commentId);
  }
}
