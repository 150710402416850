<div class="flex flex-col gap-2">
  <h1 class="text-sm md:text-base font-semibold text-palette-gray-700">
    {{ 'STATISTIC.AGE_AND_GENDER.TITLE' | translate }}
  </h1>
  <ng-container *ngIf="isHasData(); else loadingState">
    <div class="w-full flex justify-center md:justify-end gap-2 h-5 md:h-6">
      <ng-container
        [ngTemplateOutlet]="legendItem"
        [ngTemplateOutletContext]="{
          squareClass: 'bg-branding-primary-300',
          percentValue: malePercent,
          label: 'MALE'
        }"></ng-container>
      <div class="w-[1px] h-5 md:h-6 bg-[#D9D9D9]"></div>
      <ng-container
        [ngTemplateOutlet]="legendItem"
        [ngTemplateOutletContext]="{
          squareClass: 'bg-palette-violet-300',
          percentValue: femalePercent,
          label: 'FEMALE'
        }"></ng-container>
      <div class="w-[1px] h-5 md:h-6 bg-[#D9D9D9]"></div>
      <ng-container
        [ngTemplateOutlet]="legendItem"
        [ngTemplateOutletContext]="{
          squareClass: 'bg-palette-orange-300',
          percentValue: otherGenderPercent,
          label: 'OTHER'
        }"></ng-container>
    </div>
    <p-chart type="bar" [data]="data" [options]="options" height="214px"></p-chart>
  </ng-container>
</div>

<ng-template #legendItem let-squareClass="squareClass" let-percentValue="percentValue" let-label="label">
  <div class="flex gap-2 items-center">
    <div class="w-3 h-3 md:w-4 md:h-4 rounded-full md:rounded {{ squareClass }} "></div>
    <span class="text-sm md:text-base text-palette-gray-700 font-medium"
      >{{ percentValue | percent: '1.0-1' }} {{ 'COMMON.GENDER.' + label | translate }}</span
    >
  </div>
</ng-template>

<ng-template #loadingState>
  <div class="w-full flex flex-col gap-2">
    <div class="w-full flex justify-center md:justify-end gap-2 h-5 md:h-6">
      <ng-container *ngFor="let _ of [].constructor(3)">
        <div class="flex gap-2 items-center">
          <p-skeleton size="16px"></p-skeleton>
          <p-skeleton width="75px" height="24px"></p-skeleton>
        </div>
      </ng-container>
    </div>
    <div class="h-[220px] w-full border-b-2 border-b-gray-500 flex justify-between items-end">
      <p-skeleton width="20px" height="100px"></p-skeleton>
      <div class="flex items-end gap-1">
        <p-skeleton width="20px" height="200px"></p-skeleton>
        <p-skeleton width="20px" height="110px"></p-skeleton>
      </div>
      <div class="flex items-end gap-1">
        <p-skeleton width="20px" height="120px"></p-skeleton>
        <p-skeleton width="20px" height="180px"></p-skeleton>
      </div>
      <p-skeleton width="20px" height="170px"></p-skeleton>
    </div>
  </div>
</ng-template>
