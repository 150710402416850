<div
  class="h-full flex justify-center items-center z-[1000]"
  [ngClass]="{
    'absolute top-0 left-0 w-full bg-palette-base-white/30': isOverlay,
    'fixed left-0 right-0 top-0 bottom-0 bg-palette-base-white/30' : isFullScreen
  }"
  >
  <p-progressSpinner
    [styleClass]="styleClass"
    [strokeWidth]="strokeWidth"
    [animationDuration]="spinSpeed"></p-progressSpinner>
</div>
