<div
  class="flex flex-col items-center border rounded-lg border-palette-gray-100 relative"
  [ngClass]="profileCoin ? 'w-[133px] bg-palette-base-white' : 'w-[160px] ml-2'">
  <div class="justify-center flex flex-col">
    <div class="flex justify-center items-center md:pb-6 md:pt-2">
      <p-skeleton shape="circle" size="6rem" class="rounded-full md:border-[3px] md:border-white md:shadow-md">
      </p-skeleton>
    </div>
    <div class="flex flex-col justify-center items-center max-md:ml-3">
      <p-skeleton width="6rem" [styleClass]="profileCoin ? 'mb-4' : 'mb-2'" borderRadius="16px"></p-skeleton>
      <p-skeleton
        *ngIf="!profileCoin"
        width="4rem"
        height="0.7rem"
        styleClass="mb-2"
        borderRadius="16px"
        class="pb-2"></p-skeleton>
      <p-skeleton width="4rem" height="1.5rem" styleClass="mb-[15px]" borderRadius="5px"></p-skeleton>
    </div>
  </div>
</div>
