import { Component, ViewEncapsulation } from '@angular/core';
import { DeviceService } from '@app/core/services/device.service';

@Component({
  selector: 'download-app-dialog',
  templateUrl: './download-app-dialog.component.html',
  styleUrls: ['./download-app-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadAppDialogComponent {
  constructor(public deviceService: DeviceService) {}
}
