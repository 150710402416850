import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UploadPostState } from './create-post.state';

export const uploadPostState = createFeatureSelector<UploadPostState>('createPost');

export const uploadPost = createSelector(uploadPostState, uploadPostState => uploadPostState);

export const selectLargeFileUploaded = createSelector(
  uploadPostState,
  uploadPostState => uploadPostState.largeFileUploaded
);

export const postCreated = createSelector(uploadPostState, uploadPostState => uploadPostState.postCreated);

export const isLoadingCreatePost = createSelector(
  uploadPostState,
  uploadPostState => uploadPostState.isLoadingCreatePost
);

export const isCreatingPost = createSelector(uploadPostState, uploadPostState => uploadPostState.isCreatingPost);
