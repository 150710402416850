import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { ShareAPI } from './api.share.model';

export class ShareApi {
  private apiUrl: string = environment.baseURL;
  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getPostsShareByPostId(
    data: ShareAPI.RequestPostsShareByPostId
  ): Observable<ShareAPI.MultipleDataResponse<ShareAPI.PostShare>> {
    const apiUrl = `${this.apiUrl}/share/shares/get-by-post-id?pageNum=${data.pageNum}&pageSize=${data.pageSize}&postId=${data.post_id}&sort=${data.sort}`;
    return this.http.get<ShareAPI.MultipleDataResponse<ShareAPI.PostShare>>(apiUrl).pipe(
      timeout(this.config.responseTimeout),
      map((response: ShareAPI.MultipleDataResponse<ShareAPI.PostShare>) => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  countMySharesByListId(data: ShareAPI.RequestCountMySharesByListId): Observable<ShareAPI.SingleDataResponse<number>> {
    const apiUrl = `${this.apiUrl}/share/shares/my-shares-count`;
    return this.http.post<ShareAPI.SingleDataResponse<number>>(apiUrl, data).pipe(
      timeout(this.config.responseTimeout),
      map((response: ShareAPI.SingleDataResponse<number>) => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
