import { Injectable } from '@angular/core';
import { ReportService } from '@app/core/services/report.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import * as reportType from './report.actions';

@Injectable()
export class ReportEffects {
  constructor(
    private actions: Actions,
    private reportService: ReportService
  ) {}

  getReportCategories$ = createEffect(() =>
    this.actions.pipe(
      ofType(reportType.getReportCategories),
      concatMap(() =>
        this.reportService.getReportCategories().pipe(
          concatMap(response => {
            return of(reportType.getReportCategoriesSuccess({ reportCategories: response.data }));
          })
        )
      )
    )
  );
}
