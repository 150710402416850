import { Component } from '@angular/core';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'progress-toast',
  templateUrl: './progress-toast.component.html',
  styleUrls: ['./progress-toast.component.scss']
})
export class ProgressToastComponent {
  visible = false;
  forceClear = false;
  progress = 0;

  constructor(private messageService: MessageService) {}

  showConfirm() {
    if (!this.visible) {
      this.messageService.add({
        key: 'progressPercent',
        sticky: true,
        severity: TOAST_MESSAGE_SEVERITY_LEVELS.info
      });
      this.visible = true;
    }
  }

  onClose() {
    this.visible = false;
    this.forceClear = true;
  }

  clear() {
    this.messageService.clear('progressPercent');
  }
}
