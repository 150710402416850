import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { BusinessAccountApiModels } from './api.business-account.models';

const BUSINESS_ACCOUNT_PATH = {
  member: '/member',
  members: '/members',
  share: '/share',
  categories: '/categories',
  page: '/page',
  forAdv: '/for-adv',
  payment: '/payment',
  topUp: '/top-up',
  download: '/bill/download'
};

export class BusinessAccountApi {
  private apiUrl = `${environment.baseURL}/business-account/account`;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  createBusinessAccount(
    businessAccountInfo: BusinessAccountApiModels.BusinessAccountInfo
  ): Observable<BusinessAccountApiModels.AdAccountInfoRes> {
    return this.http.post<BusinessAccountApiModels.AdAccountInfoRes>(`${this.apiUrl}`, businessAccountInfo).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getAllBusinessAccounts(): Observable<BusinessAccountApiModels.BusinessAccountListRes> {
    return this.http.get<BusinessAccountApiModels.BusinessAccountListRes>(`${this.apiUrl}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getBusinessAccountInfo(businessAccountId: string): Observable<BusinessAccountApiModels.AdAccountInfoRes> {
    return this.http.get<BusinessAccountApiModels.AdAccountInfoRes>(`${this.apiUrl}/${businessAccountId}/detail`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editBusinessAccountInfo(
    businessAccountId: string,
    businessAccountInfo: BusinessAccountApiModels.BusinessAccountInfo
  ): Observable<BusinessAccountApiModels.AdAccountInfoRes> {
    return this.http
      .put<BusinessAccountApiModels.AdAccountInfoRes>(`${this.apiUrl}/${businessAccountId}`, businessAccountInfo)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSharedBusinessAccounts(): Observable<BusinessAccountApiModels.BusinessAccountListRes> {
    return this.http
      .get<BusinessAccountApiModels.BusinessAccountListRes>(`${this.apiUrl}${BUSINESS_ACCOUNT_PATH.share}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSharedAdAccountsInBusiness(
    businessAccountId: string
  ): Observable<BusinessAccountApiModels.SharedAdAccountListRes> {
    return this.http
      .get<BusinessAccountApiModels.SharedAdAccountListRes>(
        `${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.members}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addAdAccountsInBusiness(
    businessAccountId: string,
    accountList: BusinessAccountApiModels.AdAccount[]
  ): Observable<BusinessAccountApiModels.AddAdAccountListRes> {
    const body = {
      members: accountList
    };
    return this.http
      .post<BusinessAccountApiModels.AddAdAccountListRes>(
        `${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.member}`,
        body
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  removeAdAccountInBusiness(
    businessAccountId: string,
    accountId: string
  ): Observable<BusinessAccountApiModels.RemoveAdAccountRes> {
    return this.http
      .delete<BusinessAccountApiModels.RemoveAdAccountRes>(
        `${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.members}/${accountId}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  editBusinessAccount(
    businessAccountInfo: BusinessAccountApiModels.BusinessAccountInfo
  ): Observable<BusinessAccountApiModels.AdAccountInfoRes> {
    return this.http
      .put<BusinessAccountApiModels.AdAccountInfoRes>(`${this.apiUrl}/${businessAccountInfo.id}`, businessAccountInfo)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  paymentForAdvertising(
    body: BusinessAccountApiModels.AdAccountPaymentBody
  ): Observable<BusinessAccountApiModels.AdAccountPaymentRes> {
    return this.http
      .post<BusinessAccountApiModels.AdAccountPaymentRes>(`${this.apiUrl}${BUSINESS_ACCOUNT_PATH.payment}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  topUpForAdvertising(
    body: BusinessAccountApiModels.AdAccountTopUpBody
  ): Observable<BusinessAccountApiModels.AdAccountTopUpRes> {
    return this.http
      .post<BusinessAccountApiModels.AdAccountTopUpRes>(`${this.apiUrl}${BUSINESS_ACCOUNT_PATH.topUp}`, body)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addFanpageManagement(
    fanpageManagementList: BusinessAccountApiModels.FanpageManagementList
  ): Observable<BusinessAccountApiModels.FanpageManagementListRes> {
    return this.http
      .post<BusinessAccountApiModels.FanpageManagementListRes>(
        `${this.apiUrl}/${fanpageManagementList.id}${BUSINESS_ACCOUNT_PATH.page}`,
        fanpageManagementList
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAllFanpagesManagement(businessAccountId: string): Observable<BusinessAccountApiModels.FanpageManagementListRes> {
    return this.http
      .get<BusinessAccountApiModels.FanpageManagementListRes>(
        `${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.page}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteFanpagesManagement(businessAccountId: string, pageId: string) {
    return this.http.delete(`${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.page}/${pageId}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getAccountSelectWallet(businessAccountId: string): Observable<BusinessAccountApiModels.AccountWallet> {
    return this.http
      .get<BusinessAccountApiModels.AccountWalletListRes>(
        `${this.apiUrl}/${businessAccountId}${BUSINESS_ACCOUNT_PATH.forAdv}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  downloadBillAccount(payload: BusinessAccountApiModels.BillDownload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    });
    return this.http
      .post(`${this.apiUrl}${BUSINESS_ACCOUNT_PATH.download}`, payload, {
        responseType: 'blob',
        headers: headers
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
