import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AddressService } from '@app/core/services/address.service';
import { BillingService } from '@app/core/services/billing.service';
import { TranslationService } from '@app/core/services/translation.service';
import { AddressAPI } from '@app/lib/api/address/api.address.models';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '../../constant';

export interface Region {
  value: string;
  label: string;
}

export interface Language {
  value: string;
  icon: string;
  nativeCountryName: string;
  label: string;
}

export interface Currency {
  label: string;
  value: string;
}

@Component({
  selector: 'language-setting',
  templateUrl: './language-setting.component.html',
  styleUrls: ['./language-setting.component.scss']
})
export class LanguageSettingComponent implements OnInit, OnChanges {
  @Input() visible: boolean;
  @Output() visibleChange = new EventEmitter();
  @Output() changeText = new EventEmitter();
  optionRegion: Region[];
  optionLanguage: Language[] = [];
  optionCurrency: Currency[];
  selectedRegion: Region;
  selectedLanguage: Language;
  selectedCurrency: Currency;
  isShowDialog = false;
  currentCurrency = '';
  isEditing = true;

  dialogConfig = {
    closeFromOutSide: false,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 'w-[36rem] sm:w-screen default-lang-dialog'
  };
  constructor(
    private translationService: TranslationService,
    private translateService: TranslateService,
    private addressService: AddressService,
    private billingService: BillingService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && changes['visible'].currentValue) {
      this.getCurrentLanguage();
    }
  }

  ngOnInit(): void {
    this.addressService.getSupportedLanguages().subscribe(supportedList => {
      this.optionLanguage = LANGUAGES.filter(language => {
        return supportedList.some((item: { language_code: string; }) => item.language_code === language.value);
      });
    });
    this.optionRegion = [
      { label: 'United States', value: 'US' },
      { label: 'Việt Nam', value: 'VN' }
    ];

    this.optionCurrency = [
      { label: 'USD', value: 'USD' },
      { label: 'VND', value: 'VND' },
      { label: 'EUR', value: 'EUR' },
      { label: 'INR', value: 'INR' },
      { label: 'AUD', value: 'AUD' }
    ];

    this.selectedRegion = this.optionRegion[0];
    this.initLanguage();
    this.initCurrency();
  }

  // set language for anonymous  user
  initLanguage() {
    let lang = environment.languages[0];
    this.translateService.addLangs(environment.languages);
    const browserLang = this.translateService.getBrowserLang() || lang;
    const selectedLang = this.translationService.getKeyLocalUserProfile('language')?.toLowerCase();
    if (selectedLang && environment.languages.includes(selectedLang)) {
      lang = selectedLang;
    } else if (!selectedLang && environment.languages.includes(browserLang)) {
      lang = localStorage.getItem('unauthLang') || (environment.languages.includes(browserLang) ? browserLang : lang);
    }
    this.translationService.setLanguage(lang);
  }

  initCurrency() {
    const isHaveProfile = localStorage.getItem('user_profile');
    const currencyCode = this.translationService.getKeyLocalUserProfile('currency');
    const currencyData = JSON.parse(localStorage.getItem('currency-conversation-data') || '{}');
    this.selectedCurrency = this.optionCurrency.filter(item => item.value === currencyData.currency)[0];
    if (currencyCode) {
      this.selectedCurrency = this.optionCurrency.filter(item => item.value === currencyCode)[0];
    } else if (isHaveProfile) {
      this.selectedCurrency = this.optionCurrency[0];
      const putData = {
        currency: 'USD',
        language: 'en'
      };
      this.updateCurrencyAndLanguage(putData);
    }
    const currentLanguage =
      LANGUAGES.find(el => el.value === this.translationService.getKeyLocalUserProfile('language')?.toLowerCase())?.label ||
      'HEADER.LANGUAGES.EN';
    const currentCurrency = this.selectedCurrency ? this.selectedCurrency.value : 'USD';
    this.currentCurrency = currentCurrency;
  }

  updateCurrencyAndLanguage(data: AddressAPI.CurrencyAndLanguages) {
    const currencyKey = 'currency-conversation-data';
    const currencyData = JSON.parse(localStorage.getItem(currencyKey) || '{}');
    currencyData.currency = data.currency;
    localStorage.setItem(currencyKey, JSON.stringify(currencyData));
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    if (!isLoggedIn) return;
    this.translationService.updateLocalCurrencyAndLanguage(data);
    this.addressService.updateSelectedLanguages(data).subscribe({
      next: res => {
        if (!res) this.isShowDialog = true;
        if (this.currentCurrency !== this.selectedCurrency.value) this.getRate();
      },
      error: () => {
        this.isShowDialog = true;
      }
    });
  }

  getRate() {
    const currencyData = JSON.parse(localStorage.getItem('currency-conversation-data') || '{}');
    this.billingService.getExchangeRateFromUSD(this.selectedCurrency.value).subscribe((res) => {
      if (res) {
        currencyData.rate = res;
        currencyData.currency = this.selectedCurrency.value;
        currencyData.expiry = new Date().getTime() + 48 * 60 * 60 * 1000;
        currencyData.hash = currencyData.currency + currencyData.rate + currencyData.expiry;
        localStorage.setItem('currency-conversation-data', JSON.stringify(currencyData));
      }
    });
  }

  closeDialog() {
    this.visibleChange.emit(false);
  }

  getCurrentLanguage() {
    const selectedLangCode = this.translationService.getKeyLocalUserProfile('language')?.toLowerCase() || localStorage.getItem('unauthLang');
    const selectedLang = this.optionLanguage.find(el => el.value === selectedLangCode?.toLowerCase());
    if (selectedLang) this.selectedLanguage = selectedLang;
  }

  changeData() {
    const updateData = {
      language: this.selectedLanguage.value,
      currency: this.selectedCurrency.value
    };
    this.updateCurrencyAndLanguage(updateData);
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    if (this.currentCurrency !== this.selectedCurrency.value && !isLoggedIn) this.getRate();
    this.changeText.emit({ label: this.selectedLanguage.label, currency: this.selectedCurrency.value });
    this.translationService.setLanguage(this.selectedLanguage.value);
    localStorage.setItem('unauthLang', this.selectedLanguage.value.toLowerCase());
    this.getCurrentLanguage();
    this.visibleChange.emit(false);
  }

  onCloseDialog(events: boolean) {
    this.isShowDialog = events;
  }

  acceptDefaultLang() {
    this.selectedLanguage = this.optionLanguage[0];
    this.selectedCurrency = this.optionCurrency[0];
    this.isShowDialog = false;
    const updateData = {
      language: 'en',
      currency: 'USD'
    };
    this.translationService.updateLocalCurrencyAndLanguage(updateData);
    this.changeText.emit({ label: this.selectedLanguage.label, currency: this.selectedCurrency.value });
    this.translationService.setLanguage(updateData.language);
  }
  onChange(event: any) {
    this.isEditing = false;
  }
}
