export enum SharedPrivileges {
  LARGE_FILE_UPLOAD = '_video_upload',
  PINNED_FEED_LATEST = 'pinned_latest_post'
}

export enum GroupPrivileges {

}

export enum FanpagePrivileges {

}
