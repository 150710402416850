<p-dialog
  [(visible)]="sentRequest"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [showHeader]="false"
  [closable]="true"
  [styleClass]="'w-[560px] round-xl'"
  (onHide)="onHideDialog()"
  [dismissableMask]="true">
  <ng-template pTemplate="content">
    <div class="flex flex-col items-center justify-center relative">
      <div
        class="w-12 h-12 p-3 justify-center items-center flex rounded-full border-8 border-solid border-palette-emerald-50 bg-palette-emerald-100">
        <i class="sctr-icon-check-circle text-2xl text-palette-emerald-600"></i>
      </div>
      <div class="text-lg mt-4 font-semibold">
        {{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.REQUEST_SUCCESSFULLY' | translate }}
      </div>
      <div class="text-sm font-normal mb-6 text-center">
        {{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.IT_WILL_TAKE_US' | translate }}
      </div>
      <div class="flex gap-4">
        <div class="w-40 flex flex-col gap-1">
          <div class="font-medium text-xs text-palette-gray-400">
            {{
              requestType === requestVerificationType.profile
                ? ('ENTITY.ACCOUNT' | translate | uppercase)
                : ('ENTITY.FANPAGE' | translate | uppercase)
            }}
          </div>
          <div class="flex items-center gap-2">
            <img
              *ngIf="requestType === requestVerificationType.profile"
              class="w-6 h-6 rounded-full"
              [src]="validUrl((userInfo$ | async).avatar_thumbnail_url)" />
            <ng-container *ngIf="fanpageAvatar && requestType === requestVerificationType.business; else defaultAvatar">
              <img class="w-6 rounded-full h-6" [src]="baseUrl + fanpageAvatar" alt="avatar" />
            </ng-container>
            <ng-template #defaultAvatar>
              <p-avatar
                *ngIf="requestType === requestVerificationType.business"
                styleClass="w-6 rounded-full h-6 bg-palette-blue-50 text-palette-blue-600 font-bold text-xs"
                [label]="fanpageName | abbreviate: 'fanpage'"></p-avatar>
            </ng-template>
            <span class="font-normal text-sm text-palette-gray-700 truncate">{{
              requestType === requestVerificationType.profile ? (userInfo$ | async).full_name : fanpageName
            }}</span>
          </div>
        </div>
        <div class="w-40 gap-1 flex flex-col">
          <div class="font-medium text-xs text-palette-gray-400">
            {{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.CATEGORY' | translate | uppercase }}
          </div>
          <span class="font-normal text-sm text-palette-gray-700 truncate">{{ category }}</span>
        </div>
        <div class="w-40 gap-1 flex flex-col">
          <div class="font-medium text-xs text-palette-gray-400">
            {{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.STATUS' | translate | uppercase }}
          </div>
          <div
            class="bg-palette-orange-50 w-fit truncate items-end flex rounded-full px-2 py-[2px] text-palette-orange-600 font-medium text-sm">
            {{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.PENDING' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button
      (onClick)="sendRequestDone()"
      [styleClass]="
        'rounded-lg border-palette-gray-300 hover:bg-palette-gray-200 w-[95px] text-gray-900 font-semibold text-sm'
      "
      label="{{ 'ACCOUNT_SETTING.REQUEST_VERIFICATION.DONE' | translate }}"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
