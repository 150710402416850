<p-card *ngIf="post" class="post-report shadow-sm border-gray-200 w-full">
  <div class="grid grid-flow-row-dense grid-cols-3 grid-rows-1 gap gap-x-4 px-4">
    <div class="flex col-span-2 gap-x-4" *ngIf="post">
      <div *ngIf="post.status && post.status === 'PENDING'">
        <p-checkbox
          [(ngModel)]="checkBoxValue"
          [binary]="true"
          inputId="binary"
          (onChange)="onSelectedChange(checkBoxValue, post)"></p-checkbox>
      </div>
      <div class="flex flex-col border-slate-200 border rounded-md w-full">
        <div class="flex justify-between px-4 pb-3 pt-4">
          <div class="flex items-center gap-3">
            <div class="relative">
              <img
                [src]="getImgUrl(post.object.user_object.avatar_thumbnail_url)"
                alt=""
                class="t-avatar h-10 w-10 rounded-full" />
              <img
                src="./../../../../../../../../assets/icons/Account-Tier.svg"
                class="absolute top-0 right-0 w-[16px] h-[16px] p-0.5 bg-palette-base-white rounded-[100px]" />
            </div>

            <span class="flex flex-col gap-1">
              <span class="text-base font-semibold text-gray-700">{{ post.object.user_object.full_name }}</span>
              <span class="text-xs text-gray-500 flex flex-row gap-2">
                <img src="../../../../../../../../assets/icons/global.svg" class="w-[16px] h-[16px]" />
                <p class="font-medium text-xs">
                  {{ formattedDateTime }}
                </p>
              </span>
            </span>
          </div>
        </div>
        <div class="px-4 pb-3" *ngIf="post.object.content">
          <ng-container *ngIf="!showFullText && post.object.content.length > MAX_POST_LENGTH; else elseDiv">
            <div
              class="block whitespace-pre-wrap text-sm font-normal text-palette-gray-900"
              [innerHTML]="(post.object.content | slice: 0 : MAX_POST_LENGTH) + ' ...'"></div>
            <div class="cursor-pointer font-semibold text-sm w-fit text-gray-500" (click)="onExpandText()">
              {{ 'COMMON.SHOW_MORE' | translate }}
            </div>
          </ng-container>
          <ng-template #elseDiv>
            <div
              class="block whitespace-pre-wrap text-sm font-normal text-palette-gray-900"
              [innerHTML]="post.object.content"></div>
            <div
              *ngIf="showFullText || post.object.content.length > MAX_CAPTION_LENGTH"
              class="cursor-pointer hover:underline font-semibold text-sm w-fit text-gray-500"
              (click)="onExpandText()">
              {{ 'COMMON.SHOW_LESS' | translate }}
            </div>
          </ng-template>
        </div>
        <div
          *ngIf="mediaData"
          class="gap-0.5 p-0.5"
          [ngClass]="{
            'grid auto-rows-auto box-border': mediaList.length > 1,
            'grid-cols-2': mediaData.layout_type === LAYOUT_TYPE.vertical,
            'grid-cols-1': mediaData.layout_type === LAYOUT_TYPE.horizontal
          }">
          <ng-container *ngFor="let item of mediaList; let i = index">
            <div *ngIf="i < 2" class="relative cursor-pointer bg-gray-100">
              <custom-video
                *ngIf="item.type === MEDIA_TYPE.video"
                (click)="clickVideo(i)"
                (isClickedPlay)="checkPlayClicked($event)"
                [item]="item"
                [isSquareContainer]="mediaList.length > 1"></custom-video>

              <div
                class="w-full h-full"
                *ngIf="item.type === MEDIA_TYPE.image"
                [ngClass]="!isLandscapeImg(item) ? 'aspect-square' : ''"
                (click)="clickImage(i)">
                <div
                  *ngIf="!isLandscapeImg(item)"
                  [style.backgroundImage]="
                    'url(' +
                    (isValidUrl(item.original)
                      ? item.original
                      : baseUrl + '/storage/files/thumbnail/' + item.original) +
                    ')'
                  "
                  class="bg-no-repeat bg-center w-full h-full bg-contain"></div>
                <img
                  *ngIf="isLandscapeImg(item)"
                  [src]="
                    isValidUrl(item.original) ? item.original : baseUrl + '/storage/files/thumbnail/' + item.original
                  "
                  class="bg-no-repeat bg-center w-full h-full bg-contain"
                  defaultImage />
              </div>
              <span
                *ngIf="mediaList.length > 2 && i === 1"
                (click)="clickImage(i)"
                class="w-full h-full absolute top-0 left-0 text-center flex justify-center items-center">
                <b class="absolute text-[32px] leading-9 text-white rounded-full accent-gray-400 p-3"
                  >+{{ mediaList.length - 1 }}</b
                >
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-y-3" *ngIf="post.reporters">
      <div class="flex flex-col border-slate-200 border rounded-md">
        <div class="py-3 px-4 bg-slate-100 border-b border-slate-300 font-medium text-xs">
          {{ 'COMMON.REPORTERS' | translate }}
        </div>
        <p-scrollPanel styleClass="max-h-[456px]">
          <div
            class="p-4 gap-y-3 flex flex-col {{ post.reporters.length > 1 ? 'border-b border-palette-gray-300' : '' }}"
            *ngFor="let item of post.reporters">
            <div class="flex gap-x-3" *ngIf="item.user_object">
              <img
                [src]="getImgUrl(item.user_object.avatar_thumbnail_url)"
                alt=""
                class="t-avatar h-10 w-10 rounded-full" />
              <div class="flex flex-col">
                <span class="font-medium text-sm text-palette-gray-900">{{ item.user_object.full_name }}</span>
                <span class="font-normal text-sm text-palette-gray-600">{{
                  item.created_at | date: 'dd/MM/yyy hh:mm a'
                }}</span>
              </div>
            </div>
            <div class="flex flex-col gap-y-1.5">
              <span class="font-medium text-xs text-palette-gray-600">{{
                'COMMON.CATEGORY_OF_REPORT' | translate
              }}</span>
              <div class="flex gap-1 flex-wrap">
                <ng-container *ngFor="let chip of item.category_type; let i = index">
                  <div class="flex">
                    <p-chip class="chip-report">
                      <span class="font-medium text-xs py-0.5 px-2 whitespace-nowrap">{{
                        'GROUP.SETTING.' + (chip | uppercase) | translate
                      }}</span>
                    </p-chip>
                  </div>
                  <p-chip class="chip-report" *ngIf="i === 2 && item.category_type.length > 10">
                    <span class="font-medium text-xs py-0.5 px-1">{{ item.category_type.length }}</span>
                  </p-chip>
                </ng-container>
              </div>
              <div class="flex flex-col gap-y-1.5">
                <span class="font-medium text-xs text-palette-gray-600">{{
                  'COMMON.REASON_OF_REPORT' | translate
                }}</span>
                <div class="card flex justify-content-center" *ngIf="item.report_reason">
                  <textarea
                    rows="{{ calculateRows(item.report_reason) }}"
                    cols="30"
                    pInputTextarea
                    [disabled]="true"
                    [(ngModel)]="item.report_reason"></textarea>
                </div>
              </div>
            </div>
          </div>
        </p-scrollPanel>
      </div>
      <div class="flex flex-col border-slate-200 border rounded-md p-3 gap-3" *ngIf="post.status">
        <div class="flex flex-col gap-1">
          <span class="text-palette-gray-500 font-medium text-sm">{{ 'GROUP.SETTING.STATUS' | translate }}</span>
          <div class="bg-[#ECFDF3] text-[#027A48] rounded-2xl w-fit font-normal text-sm py-1 px-3">
            {{ 'PROFILE.GROUP.' + post.status | translate }}
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <span class="text-palette-gray-500 font-medium text-sm" *ngIf="post.status === 'REJECT'">{{
            'GROUP.SETTING.REJECT_REASON' | translate
          }}</span>
          <div class="card flex justify-content-center" *ngIf="post.status === 'REJECT'">
            <textarea
              rows="5"
              cols="30"
              pInputTextarea
              [(ngModel)]="post.censorship_reason"
              [disabled]="true"
              [autoResize]="true"></textarea>
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <span class="text-palette-gray-500 font-medium text-sm" *ngIf="post.status === 'DELETE'">{{
            'GROUP.SETTING.APPROVAL_REASON' | translate
          }}</span>
          <div class="card flex justify-content-center" *ngIf="post.status === 'DELETE'">
            <textarea
              rows="5"
              cols="30"
              pInputTextarea
              [(ngModel)]="post.censorship_reason"
              [disabled]="true"
              [autoResize]="true"></textarea>
          </div>
        </div>
      </div>
      <div>
        <div
          class="flex flex-col border-slate-200 border rounded-md h-[76px] w-full items-center justify-center"
          *ngIf="post.status === 'PENDING'">
          <div class="flex flex-row gap-3 justify-center">
            <p-button
              styleClass="w-fit general-btn px-4 py-2.5 rounded-lg border-solid border-gray-300 text-sm font-semibold text-gray-700"
              icon="pi pi-times"
              label="{{ 'GROUP.SETTING.REJECT' | translate }}"
              (onClick)="onSelect('decline', [reporters], post.id)"></p-button>
            <p-button
              styleClass="w-fit edit-btn px-4 py-2.5 rounded-lg border-solid border-sky-600 text-sm font-semibold text-white"
              icon="pi pi-check"
              label="{{ 'COMMON.BUTTON_ACTION.APPROVE' | translate }}"
              (onClick)="onSelect('approval', [reporters], post.id)"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>
