import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/core/services/common.service';
import { GroupService } from '@app/core/services/group.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { modalMemberGroupPending } from '@app/lib/api/group/api.group.model';
import {
  loadMemberAcceptGroup,
  loadMemberBanGroup,
  loadMemberDeclineGroup
} from '@app/modules/main/group/store/actions';
import { GroupInterfaceState } from '@app/modules/main/group/store/reducer';
import { selectIsLoading } from '@app/modules/main/group/store/selectors';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';

export interface RequestMemberProps {
  id: string;
  name: string;
  avatar: string;
  mututalFriendsNumber: number;
}
export interface PostIdsList {
  user_id_list: string[];
}
export interface PutApproveAllMember {
  user_id_list: string[];
}
export interface BanGroup {
  user_id: string | null;
}

@Component({
  selector: 'member-requests',
  templateUrl: './member-requests.component.html',
  styleUrls: ['./member-requests.component.scss']
})
export class MemberRequestsComponent implements OnInit {
  @Input() property: modalMemberGroupPending[] = [];
  @Input() dataGroupAllRequestInvited: any[] = [];
  membersGroup: any;
  search = new FormControl('');
  checked: boolean = false;
  declineDialogVisible = false;
  listMemberSelected: modalMemberGroupPending[] = [];
  MemberItemCheck: boolean | undefined = false;
  checkBoxValue: boolean = false;
  acceptDialogVisible = false;
  type: string = 'Decline';
  textTranslate: string = '';
  title: string = 'pending member';
  selectedButton: string = 'MemberRequest';
  groupId: string | null;
  baseUrl: string = environment.baseURL;
  searchText = '';
  searchResult: modalMemberGroupPending[] = [];
  loadingActionForUser$ = this.store.select(selectIsLoading);

  constructor(
    private store: Store<GroupInterfaceState>,
    private route: ActivatedRoute,
    private groupServer: GroupService,
    private commonService: CommonService,
    private TranslationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private router: Router
  ) {}

  selectButtonClick(buttonID: string) {
    this.selectedButton = buttonID;
  }

  ngOnInit(): void {
    this.textTranslate = this.TranslationService.getTranslation('GROUP.SETTING.REJECT_REQUEST_ALL');
    this.route.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });
    this.groupServer.getGroupAllRequestInvitedUsers(this.groupId).subscribe((res: any) => {
      if (res && res.data) {
        this.dataGroupAllRequestInvited = res.data;
      }
    });
    if (this.groupId) {
      this.groupServer.getMemberGroup(this.groupId).subscribe((res: any) => {
        if (res && res.data) {
          this.membersGroup = res.data;
        }
      });
    }
  }

  handleCancelRequest(id: string): void {
    if (this.groupId) {
      this.groupServer.putGroupCancelRequestPromote(this.groupId, id).subscribe((res: any) => {
        if (res && res.success) {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.success,
            'GROUP.SETTING.SUCCESS.YOU_HAVE_REVOKED'
          );
          this.groupServer.getGroupAllRequestInvitedUsers(this.groupId).subscribe((res: any) => {
            if (res && res.data) {
              this.dataGroupAllRequestInvited = res.data;
            }
          });
        } else {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            'ERROR.GROUP.YOU_HAVE_FAILED_TO_CANCEL'
          );
        }
      });
    }
  }

  handleCancelInviteRequest(user_id: string): void {
    if (this.groupId) {
      this.groupServer.putGroupCancelRequestInvitedUsers(this.groupId, user_id).subscribe((res: any) => {
        if (res && res.success) {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.success,
            'GROUP.SETTING.SUCCESS.YOU_HAVE_REVOKED'
          );
          this.groupServer.getGroupAllRequestInvitedUsers(this.groupId).subscribe((res: any) => {
            if (res && res.data) {
              this.dataGroupAllRequestInvited = res.data;
            }
          });
        } else {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            'ERROR.GROUP.YOU_HAVE_FAILED_TO_CANCEL'
          );
        }
      });
    }
  }

  handleApproveMember(user_id: string | null) {
    let postIdsList: PostIdsList = {
      user_id_list: []
    };
    if (user_id != null) {
      postIdsList.user_id_list.push(user_id);
    }
    if (this.groupId) {
      this.removeUserAlreadyAction(user_id);
      this.store.dispatch(loadMemberAcceptGroup({ groupId: this.groupId, postIdsList }));
    }
  }

  handleDeclineMember(user_id: string | null = null) {
    let postIdsList: PostIdsList = {
      user_id_list: []
    };
    if (user_id != null) {
      postIdsList.user_id_list.push(user_id);
    }
    if (this.groupId) {
      this.removeUserAlreadyAction(user_id);
      this.store.dispatch(loadMemberDeclineGroup({ groupId: this.groupId, postIdsList }));
    }
  }

  DeclineAll() {
    this.acceptDialogVisible = true;
    this.textTranslate;
  }

  onHide() {
    this.acceptDialogVisible = false;
  }

  handleShowModalMember() {
    this.declineDialogVisible = true;
  }

  handleActionForUser(items: any, isApproval: boolean) {
    let userIDs = items.map((item: any) => item.user_id);
    let postIdsList: PutApproveAllMember = {
      user_id_list: userIDs
    };
    if (this.groupId) {
      this.declineDialogVisible = false;
      this.listMemberSelected = [];

      if (!isApproval) {
        this.store.dispatch(loadMemberAcceptGroup({ groupId: this.groupId, postIdsList }));
      } else {
        this.store.dispatch(loadMemberDeclineGroup({ groupId: this.groupId, postIdsList }));
      }
      this.acceptDialogVisible = false;
    }
  }

  handleBanMember(user_id: string | null = null) {
    if (this.groupId && user_id) {
      this.removeUserAlreadyAction(user_id);
      this.store.dispatch(loadMemberBanGroup({ groupId: this.groupId, user_id: user_id }));
    }
  }

  selectedAllChange(checked: boolean) {
    if (checked) {
      this.MemberItemCheck = true;
      this.listMemberSelected = this.property;
    } else {
      this.MemberItemCheck = false;
      this.listMemberSelected = [];
    }
  }

  onSelectedChange(event: any) {
    if (event.checked) {
      this.listMemberSelected.push(event.data);
      this.MemberItemCheck = undefined;
    } else {
      this.listMemberSelected = this.listMemberSelected.filter(item => item.user_id !== event.data.user_id);
    }
    this.checked = !!this.listMemberSelected.length;
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  handleSearch() {
    this.searchResult = this.property.filter(member => {
      return member.user_info.full_name.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  navigateToProfile(data: any): void {
    const userId = data.user_id || '';
    const username = data.username || '';
    this.router.navigate([`/personal-profile/${username || userId}`]);
  }

  removeUserAlreadyAction(userId: string | null): void {
    if (userId) {
      this.listMemberSelected = this.listMemberSelected.filter(user => user.user_id !== userId);
    }
  }
}
