import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { omit } from 'lodash-es';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { UserCoverResponse, UserProfile, UserProfileResponse } from './api.user-profile.models';

export class UserProfileApi {
  private apiUrl: string = environment.baseURL;
  USER_PROFILE = '/user-profile/user-profiles';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getUserProfile(userId: string): Observable<UserProfile> {
    const url = `${this.apiUrl}${this.USER_PROFILE}/${userId}`;
    return this.http.get<UserProfileResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  updateUserProfile(userId: string, newProfile: UserProfile): Observable<UserProfile> {
    const url = `${this.apiUrl}${this.USER_PROFILE}/${userId}`;
    const body = omit(newProfile, ['id']);
    return this.http.put<UserProfileResponse>(url, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  uploadUserCover(userProfile: UserProfile, path: string): Observable<string> {
    const updateCover = { ...userProfile, cover_url: path };
    const url = `${this.apiUrl}${this.USER_PROFILE}/update-cover-by-user-id/${userProfile.user_id}`;
    return this.http.put<UserCoverResponse>(url, updateCover).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res?.data?.cover_url;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
