<emoji-mart
  set="facebook"
  [class]="styleClass"
  [ngClass]="{ 'hidden': !isShowEmoji }"
  [showPreview]="false"
  [enableSearch]="false"
  [isNative]="true"
  [perLine]="9.1"
  [i18n]="i18n"
  (emojiSelect)="onSelectEmoji($event)"></emoji-mart>
