import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Access, listQuickAccess } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';

interface QuickAccess {
  title: string;
  accesses: Access[];
}

@Component({
  selector: 'quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent {
  quickAccess: QuickAccess = {
    title: '',
    accesses: []
  };
  isOpenPopupLogin = false
  translateResult: any = {};
  isLoggedIn = false;
  @Input() userInfo: UserInfo;
  @Input() dataDisplayQuickAccess: Access[];
  @Input() isDialogVisible = false;
  @Output() isDialogVisibleChange = new EventEmitter();

  constructor(
    private router: Router,
    private store: Store
  ) {
    const authStatus = JSON.parse(localStorage.getItem('auth_status') || '[]');
    this.isLoggedIn = authStatus.isLoggedIn;
  }

  ngOnChanges(changes: SimpleChanges) {
    const storedDisplayAccesses = JSON.parse(localStorage.getItem('listDisplayQuickAccess') || '[]');
    if (!storedDisplayAccesses.length) {
      localStorage.setItem(
        'listDisplayQuickAccess',
        JSON.stringify(listQuickAccess(this.router, this.userInfo?.username || this.userInfo?.id, this.isLoggedIn))
      );
    }
    this.quickAccess = {
      title: 'HOME.QUICK_ACCESS',
      accesses: storedDisplayAccesses.length
        ? (this.quickAccess.accesses = listQuickAccess(this.router, this.userInfo?.username || this.userInfo?.id, this.isLoggedIn).filter(
          access => {
            return storedDisplayAccesses?.some((displayAccess: any) => displayAccess.index === access.index);
          }
        ))
        : listQuickAccess(this.router, this.userInfo?.username || this.userInfo?.id, this.isLoggedIn)
    };

    if (this.dataDisplayQuickAccess) {
      this.quickAccess = {
        title: 'HOME.QUICK_ACCESS',
        accesses: listQuickAccess(this.router, this.userInfo?.username || this.userInfo?.id, this.isLoggedIn).filter(access => {
          return this.dataDisplayQuickAccess?.some((displayAccess: any) => displayAccess.index === access.index);
        })
      };
    }
  }

  openCustomQuickAccessDialog() {
    if (!this.isOpenPopupLogin)
      this.isDialogVisibleChange.emit(true);
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event
  }
}
