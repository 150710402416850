import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';

@Pipe({
  name: 'getDatePipe',
  pure: false
})
export class GetDatePipe implements PipeTransform {
    constructor(private translateService: TranslationService) {}

  transform(value: moment.Moment, time: number) {
    const language = this.translateService.getKeyLocalUserProfile('language')?.toLowerCase() || 'en';
    if ( time > 604800) {
        switch(language){
            case 'vi':
                return value.locale(language).format('DD [tháng] M [lúc] HH:mm');
            case 'en':
                return value.locale(language).format('DD MMM [at] HH:mm');
        }
      } else {
        return value
        .locale(language)
        .fromNow();
      }
    return value;
  }
}