import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FriendStateEnum, FriendStateKeyEnum, RelationshipStatusEnum } from '@app/core/enums/makeFriend.enum';
import { FriendAPI } from '@app/lib/api/friend/api.friend.model';
import { FriendActions } from '@app/modules/main/states/friend/friend.actions';

import { selectLoadingSuggestFriendsState } from '@app/modules/main/states/friend/friend.selectors';
import { CURRENT_SCREEN, VISIBLE_SMALL_SCREEN } from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import { Store } from '@ngrx/store';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'people-you-may-know',
  templateUrl: './people-you-may-know.component.html',
  styleUrls: ['./people-you-may-know.component.scss']
})
export class PeopleYouMayKnowComponent implements OnChanges, OnDestroy {
  @Input() friendsSuggested: FriendAPI.FriendData;
  numVisible: number = VISIBLE_SMALL_SCREEN;
  CURRENT_SCREEN = CURRENT_SCREEN;
  loading = true;
  friendsSuggestLoading$ = this.store.select(selectLoadingSuggestFriendsState);
  friendStateKeyEnum = FriendStateKeyEnum;
  status = RelationshipStatusEnum;
  friendStateEnum = FriendStateEnum;
  isLastPage = false;
  pageSize = 10;
  pageNum = 0;
  loadingSkeletons = 3;
  isFirstLoad = true;
  countNoSuggestFriendData = 0;

  constructor(private store: Store) {
    Carousel.prototype.onTouchMove = () => {};
  }

  ngOnInit() {
    this.getFriendsSuggest();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkLastPage();
    if (
      this.friendsSuggested.content &&
      this.friendsSuggested.content.length < this.pageSize &&
      this.friendsSuggested.is_last_page !== true &&
      this.loading !== true
    ) {
      this.onLoadMorePeople();
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(FriendActions.onResetFriendState({ item: FriendStateEnum.suggestFriends }));
  }

  checkLastPage() {
    this.isLastPage =
      (this.friendsSuggested.content &&
        this.friendsSuggested.content.length !== 0 &&
        this.friendsSuggested.is_last_page === true) ??
      false;
  }

  getFriendsSuggest() {
    this.store.dispatch(FriendActions.onGetSuggestFriendsState({ pageNum: this.pageNum, pageSize: this.pageSize }));
    this.pageNum++;
    this.friendsSuggestLoading$.subscribe(loading => {
      if (loading === false) {
        this.loading = false;
        this.isFirstLoad = false;
      }
    });
  }

  onLoadMorePeople() {
    if (
      (!this.friendsSuggested?.content || !this.friendsSuggested.content.length) &&
      !this.friendsSuggested?.is_last_page
    ) {
      this.countNoSuggestFriendData++;
    }
    if (!this.loading && !this.isLastPage && this.countNoSuggestFriendData < 3) {
      this.getFriendsSuggest();
      this.loading = true;
    }
  }

  handleFriendActions(clickedItem: any) {
    switch (clickedItem.actionType) {
      case this.status.SentRequest:
        this.onCancelRequest(clickedItem.item);
        break;
      case this.status.UnKnow:
        this.onAddFriend(clickedItem.item);
        break;
      case this.status.ReceivedRequest:
        this.onAcceptRequest(clickedItem.item);
        break;
      default:
        this.onRemoveUser(clickedItem.item);
        break;
    }
  }

  onAddFriend(clickedItem: any) {
    this.store.dispatch(
      FriendActions.onMakeFriendState({
        userId: clickedItem.id,
        idToFind: this.friendStateKeyEnum.id,
        stateItem: this.friendStateEnum.suggestFriends
      })
    );
  }

  onAcceptRequest(clickedItem: FriendList) {
    this.store.dispatch(
      FriendActions.onAcceptRequestFriendState({
        requestId: clickedItem.friend_invitation_id,
        idToFind: this.friendStateKeyEnum.fenInvitationId,
        stateItem: this.friendStateEnum.suggestFriends
      })
    );
  }

  onCancelRequest(clickedItem: FriendList) {
    this.store.dispatch(
      FriendActions.onCancelRequestFriendState({
        requestId: clickedItem.friend_invitation_id,
        idToFind: this.friendStateKeyEnum.fenInvitationId,
        stateItem: this.friendStateEnum.suggestFriends
      })
    );
  }

  onRemoveUser(clickedItem: FriendList) {
    if (this.friendsSuggested.content && this.friendsSuggested.content.length > 4) {
      this.store.dispatch(
        FriendActions.onDeleteFriendsStateById({
          item: this.friendStateEnum.suggestFriends,
          id: clickedItem.id
        })
      );
    }
  }
}
