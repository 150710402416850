import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNameFormatPipe implements PipeTransform {
  transform(user: any) {
    const hasName = user && (user.first_name || user.last_name);

    if (hasName) {
      const { first_name, last_name } = user;
      return `${first_name} ${last_name}`;
    }
    return '';
  }
}
