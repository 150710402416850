import { createActionGroup, props } from '@ngrx/store';

export const DetailPostActions = createActionGroup({
  source: 'DetailPost',
  events: {
    'On Update Post Detail': props<{ infoPost: any }>(),
    'On Update Comments': props<{ data: any }>(),
    'On Update Reaction Comment': props<{ info: any }>(),
    'On Reload Comments': props<{ isReload: boolean }>(),
    'On Hide Post': props<{ id: string }>(),
  }
});
