import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, catchError, map, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { AddressAPI } from './api.address.models';

const ADDRESS_PATH = {
  shipment: '/shipment/address'
};

const REGIONAL_SERVICE = {
  regional: '/regional/regional',
  languages: '/regional/languages',
  country: '/country',
  region: '/region',
  city: '/cities',
  languageCurrency: '/user/language-currency'
};

export class AddressApi {
  private apiUrl: string = environment.baseURL;

  constructor(
    public http: HttpClient,
    public config: ApiClientConfig
  ) {}

  getCountryList(validCountry = false): Observable<AddressAPI.GetCountryListRes> {
    const params = `?validCountry=${validCountry}`;
    return this.http
      .get<AddressAPI.GetCountryListRes>(
        `${this.apiUrl}${REGIONAL_SERVICE.regional}${REGIONAL_SERVICE.country}${params}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getRegionList(countryCode: string): Observable<AddressAPI.GetRegionListRes> {
    return this.http
      .get<AddressAPI.GetRegionListRes>(
        `${this.apiUrl}${REGIONAL_SERVICE.regional}/${countryCode}${REGIONAL_SERVICE.region}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCityList(region: string): Observable<AddressAPI.GetCityListRes> {
    return this.http
      .get<AddressAPI.GetCityListRes>(`${this.apiUrl}${REGIONAL_SERVICE.regional}/${region}${REGIONAL_SERVICE.city}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCountries() {
    return this.http
      .get<AddressAPI.MultipleDataResponse<AddressAPI.CountriesModelResponse>>(
        `${this.apiUrl}${ADDRESS_PATH.shipment}/countries`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getProvinces(countryId: string): Observable<AddressAPI.MultipleDataResponse<AddressAPI.ProvinceModelResponse>> {
    return this.http
      .get<AddressAPI.MultipleDataResponse<AddressAPI.ProvinceModelResponse>>(
        `${this.apiUrl}${ADDRESS_PATH.shipment}/provinces?countryId=${countryId}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getDistricts(provinceId: string): Observable<AddressAPI.MultipleDataResponse<AddressAPI.DistrictsModelResponse>> {
    return this.http
      .get<AddressAPI.MultipleDataResponse<AddressAPI.DistrictsModelResponse>>(
        `${this.apiUrl}${ADDRESS_PATH.shipment}/districts?provinceId=${provinceId}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getSupportedLanguages(): Observable<AddressAPI.GetRegionalLanguages> {
    return this.http
      .get<AddressAPI.GetRegionalLanguages>(`${this.apiUrl}${REGIONAL_SERVICE.languages}/application`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateSelectedLanguages(data: AddressAPI.CurrencyAndLanguages): Observable<AddressAPI.SetCurrencyLanguages> {
    return this.http
      .put<AddressAPI.SetCurrencyLanguages>(`${this.apiUrl}${REGIONAL_SERVICE.languageCurrency}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(res => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
