import { Component, Input } from '@angular/core';

@Component({
  selector: 'personal-profile-no-friends',
  templateUrl: './personal-profile-no-friends.component.html',
  styleUrls: ['./personal-profile-no-friends.component.scss']
})
export class PersonalProfileNoFriendsComponent {
  @Input() noFriendText = 'FRIEND.NO_FRIENDS';
}
