<div class="rounded-xl border p-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] min-h-[120px] bg-palette-base-white">
  <div class="hobby-multiselect relative">
    <span #input class="p-input-icon-left w-full">
      <i class="pi pi-search"></i>
      <input
        *ngIf="!isCheckIn"
        #searchEmoji
        (keyup)="onSearchEmoji(searchEmoji.value)"
        class="w-full bg-palette-base-white rounded-lg font-inter text-base"
        type="text"
        pInputText
        [(ngModel)]="inputValue"
        [placeholder]="'POST.STATUS.HOW_ARE_YOU' | translate" />

      <input
        *ngIf="isCheckIn"
        class="w-full bg-palette-base-white rounded-lg font-inter text-base"
        type="text"
        pInputText
        #searchInputCheckIn
        (keyup)="onInputChange(searchInputCheckIn.value)"
        [placeholder]="'POST.STATUS.SEARCH' | translate" />
    </span>
    <custom-status
      [locations]="locations"
      [onSelect]="onSelect"
      [isCheckIn]="isCheckIn"
      [inputValue]="(searchText | async) || null"
      [searchText]="searchEmoji"
      [(selectedStatus)]="selectedStatus"
      [selectedLocation]="selectedLocation"
      [loadingMoreLocation]="loadingMoreLocation"
      (selectedLocationEmit)="selectedLocationEmit.emit($event)"
      (scrollToBottom)="handleLoadMoreFanpage()"></custom-status>
  </div>
</div>
