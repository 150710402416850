import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatPipe } from '@soctrip-common/datetime-formatter';

@Pipe({
  name: 'dateFormatPipe',
  pure: false
})
export class CustomDateFormatter implements PipeTransform {
  constructor(private dateFormatter: DateFormatPipe) {}

  transform(value: Date | string | number, isIncludeTime?: boolean, is24Hours = false, format?: string): string | null {
    return this.dateFormatter.transform(value, isIncludeTime, is24Hours, format);
  }
}
