import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'limit-image-box',
  templateUrl: './limit-image-box.component.html',
  styleUrls: ['./limit-image-box.component.scss']
})
export class LimitImageBoxComponent {
  @Input() images: string[] = [];
  private _limit?: number

  @Input()
  get limit(): number | undefined {
    return this._limit;
  }
  set limit(value: number | undefined) {    
    this._limit = value && value >= 0 ? value : 0
  }

  displayImages: string[] = [];
  lastImageIndex: number  = -1
  remainImages: number = -1

  ngOnInit(): void {
    this.initialData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialData()
  }

  initialData() {
    if (this.limit) {
      this.displayImages = this.images.slice(0, this.limit)
      this.lastImageIndex = this.limit - 1 
      this.remainImages = this.images.length - this.limit
    } else {
      this.displayImages = this.images
    }
  }

  isShowFull(index: number): boolean {
    const isOdd = this.displayImages.length % 2
    if (isOdd)  {
      return index === 0
    }

    return false;
  }
}
