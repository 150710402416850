<div class="relative flex">
  <i class="sctr-icon-calendar calendar-icon top-[calc((100%-20px)/2)] right-3"></i>
  <i
    *ngIf="showClear && rangeDates[0]"
    class="sctr-icon-x-close calendar-icon top-[calc((100%-24px)/2)] right-10 z-10 hover:bg-palette-gray-200 rounded-full transition-colors p-0.5"
    (click)="onClickedClear($event)"></i>
  <div class="hidden md:block h-11">
    <ng-container
      [ngTemplateOutlet]="dateRangesCalendar"
      [ngTemplateOutletContext]="{
        touchUI: false
      }"></ng-container>
  </div>
  <div class="block md:hidden h-10">
    <ng-container
      [ngTemplateOutlet]="dateRangesCalendar"
      [ngTemplateOutletContext]="{
        touchUI: true
      }"></ng-container>
  </div>
</div>

<ng-template #dateRangesCalendar let-touchUI="touchUI">
  <p-calendar
    #calendar
    [appendTo]="appendTo ? appendTo : null"
    [(ngModel)]="rangeDates"
    selectionMode="range"
    [readonlyInput]="true"
    [dateFormat]="'COMMON.CALENDER_FORMAT' | translate"
    placeholder="{{ 'STATISTIC.PICK_DATE_RANGE' | translate }}"
    inputStyleClass="h-10 md:h-11 text-sm align-middle rounded-lg border text-palette-gray-700 {{
      showClear ? 'pr-16' : 'pr-8'
    }} cursor-pointer focus:ring-0 {{
      isValidRanges ? 'bg-transparent border-palette-gray-300' : 'bg-palette-red-200/20 border-palette-red-500'
    }}"
    [touchUI]="touchUI"
    [disabled]="disabled"
    [maxDate]="dateLimit"
    [selectOtherMonths]="true"
    [disabledDates]="disabledDates"
    (onSelect)="onSelectedDate($event)"
    (onShow)="resetDateRanges = rangeDates"
    (onClickOutside)="onCancelSelectDate()"
    panelStyleClass="rounded-t-2xl md:rounded-lg shadow-[0_20px_24px_-4px_rgba(16,24,40,0.08)] {{
      touchUI ? 'translate-x-[-50%] top-[unset] min-w-[100vw] bottom-0' : rightElement ? 'right-0 left-[unset]' : ''
    }}"
    styleClass="bg-transparent">
    <ng-template pTemplate="header">
      <div class="block md:hidden pt-2.5">
        <div class="w-full flex justify-center">
          <div class="w-9 h-[5px] bg-[#3C3C43]/30 rounded-full"></div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div
        class="grid grid-cols-2 md:flex md:justify-end pb-8 pt-4 md:py-4 px-8 md:px-4 md:border-t md:border-palette-gray-200 gap-3">
        <button
          class="cta-button bg-palette-base-white text-palette-gray-700 hover:bg-palette-gray-200 border border-palette-gray-300"
          type="button"
          (click)="calendar.overlayVisible && calendar.toggle(); onCancelSelectDate()">
          {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
        </button>
        <ng-container *ngIf="!isValidRanges; else enableApplyButton">
          <button class="cta-button text-palette-base-white bg-branding-primary-200" type="submit" [disabled]="true">
            {{ 'COMMON.BUTTON_ACTION.APPLY' | translate }}
          </button>
        </ng-container>
        <ng-template #enableApplyButton>
          <button
            class="cta-button text-palette-base-white bg-branding-primary-600 hover:bg-branding-primary-700"
            type="submit"
            (click)="calendar.overlayVisible && calendar.toggle(); onApplyChanges()">
            {{ 'COMMON.BUTTON_ACTION.APPLY' | translate }}
          </button>
        </ng-template>
      </div>
    </ng-template>
  </p-calendar>
</ng-template>
