import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FULL_NAME_LIMIT } from './constant';

const emailRegex = /^[a-zA-Z0-9.!#$%&'+-/=?^_`{|}~]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,6})+$/;
const phoneNumberRegex = /^(\+\d{1,3}\s?)?(?!0{7,})\d{7,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^*?&#])[A-Za-z\d@$!%^*?&#]{8,}$/;
const websiteRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\w.-]*)*\/?$/;
const inviteCodeRegex = /^[a-zA-Z0-9]{6}$/;
const linkRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const validateEmail = (input: string) => emailRegex.test(input);
export const validatePhoneNumber = (input: string) => phoneNumberRegex.test(input);
export const validatePassword = (input: string) => passwordRegex.test(input);
export const validateWebsite = (input: string) => websiteRegex.test(input);
export const validateInviteCode = (input: string) => inviteCodeRegex.test(input);

export function EmailValidator(control: AbstractControl): ValidationErrors | null {
  return !control.value.length || emailRegex.test(control.value) ? null : { invalidEmail: true };
}

export function PhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  return !control?.value?.length || phoneNumberRegex.test(control.value) ? null : { invalidPhoneNumber: true };
}

export function PasswordValidator(control: AbstractControl): ValidationErrors | null {
  return !control.value.length || passwordRegex.test(control.value) ? null : { invalidPassword: true };
}

export function WebsiteValidator(control: AbstractControl): ValidationErrors | null {
  return !control?.value?.length || websiteRegex.test(control.value) ? null : { invalidUrl: true };
}

export function urlValidator(control: AbstractControl): ValidationErrors | null {
  return linkRegex.test(control.value) ? null : { invalidUrl: true };
}

export function nameValidator(control: AbstractControl): ValidationErrors | null {
  const firstName = (control.get('firstName')?.value || control.get('first_name')?.value || '')
    .replace(/\s+/g, ' ')
    .trim();
  const lastName = (control.get('lastName')?.value || control.get('last_name')?.value || '')
    .replace(/\s+/g, ' ')
    .trim();
  const nameLength = `${firstName} ${lastName}`.length;

  return nameLength <= FULL_NAME_LIMIT ? null : { invalidName: true };
}

export function userNameValidator(control: AbstractControl): ValidationErrors | null {
  const forbidden = /[^a-zA-Z0-9.\s]/.test(control.value);
  const hasSpace = /\s/.test(control.value);

  if (forbidden) return { specialCharacter: true };
  if (hasSpace) return { hasSpaceCharacter: true };
  return null;
}
