export class GROUP_API_ENDPOINTS {
  public static GET_INFO: string = '/basic';
  public static GET_ROLE: string = '/me/role';
  public static GET_ABOUT: string = '/about';
  public static GET_CONTACT: string = '/contact';
  public static UPLOAD_AVATAR_GROUP: string = 'icon';
  public static UPLOAD_BANNER_GROUP: string = '/cover';
  public static MY_GROUP: string = '/group/groups/me/my-groups';
  public static ADMIN_GROUP: string = '/group/groups/admins';
  public static NO_ADMIN_GROUP: string = '/group/groups/no-admins';
  public static POST_GROUP: string = '/group/groups';
  public static RULE: string = '/rule';
  public static ALL_REQUEST: string = '/users/invitation';
  public static CANCEL_REQUEST_INVITED: string = '/invitation/cancel';
  public static CANCEL_REQUEST_PROMOTE_ROLE: string = '/role/promote/cancel';
}

export class MEMBER_GROUP_API_ENDPOINTS {
  public static GET_MEMBER: string = '/users';
  public static GET_PENDING_MEMBER: string = '/users/request';
  public static GET_ACCEPT_MEMBER: string = '/users/request/accept';
  public static GET_DECLINE_MEMBER: string = '/users/request/decline';
  public static SEARCH_INVITE_MEMBERS: string = '/users/invitation/friends/search';
  public static GET_BAN_MEMBER: string = '/ban';
  public static GROUP_MEMBERS: string = '/group/members/';
  public static SEARCH_MEMBERS: string = '/users/search';
  public static GET_INFO_MEMBERS: string = '/user/users/info/';
}

export class POST_GROUP_API_ENDPOINTS {
  public static GROUP_POSTS: string = '/group/posts/';
  public static GROUP_GROUPS: string = '/group/groups/';
  public static GROUP_ADMIN: string = '/group/admin/';
  public static POSTS_POSTS: string = '/posts/posts';
  public static GET_PENDING_POST: string = '/review';
  public static GROUP_MEMBERS: string = '/group/members/';
}

export class GROUP_CONTROLLER_API_ENDPOINTS {
  public static GET_GROUP_REQUEST: string = '/group/groups/me/request';
  public static GET_GROUP_TO_JOIN: string = '/group/groups/me/joined-groups';
  public static PUT_CANCEL_REQUEST_GROUP: string = '/group/groups/me/cancel-request';
  public static PUT_LEAVE_GROUP: string = '/group/groups/me/leave-group';
  public static GET_INVITED_GROUP: string = '/group/groups/me/invitation';
  public static PUT_ACCEPT_GROUP: string = '/group/groups/accept-invited';
  public static PUT_DECLINE_GROUP: string = '/group/groups/decline-invited';
  public static AGE_AND_GENDER_STATS: string = '/group/groups/statistic/age-and-gender';
}
