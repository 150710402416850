<div class="center-center">
  <div class="multiselect relative">
    <span #input class="p-input-icon-left multiselect__input">
      <i class="pi pi-search"></i>
      <input class="h-11" type="text" pInputText [formControl]="value" (keyup)="onExit($event)" placeholder="Search" />
    </span>
    <ul #list class="multiselect__list" [class.multiselect__list--hidden]="isListHidden">
      <li
        class="multiselect__list__item flex justify-between rounded-s-lg"
        [class.multiselect__list__item--selected]="item.isSelected"
        *ngFor="let item of listItems; let i = index"
        (click)="onToggle(item)">
        <div class="flex items-center gap-2">
          <img
            class="chip__image rounded-full"
            [src]="baseUrl + item.avatar_thumbnail_url"
            alt="" />
          <span
            class="multiselect__list__item__display text-base font-medium text-palette-gray-900 max-w-[175px] break-words"
            >{{ item.full_name }}</span
          >
        </div>
        <div class="multiselect__list__item__icon" [class.multiselect__list__item__icon--selected]="item.isSelected">
          <i class="pi pi-check"></i>
        </div>
      </li>
    </ul>
    <div class="multiselect__selected-items flex flex-row flex-wrap pt-4 gap-2">
      <div
        class="chip flex h-7 items-center justify-between border border-solid border-palette-gray-300 rounded-md"
        *ngFor="let selectedItem of selectedItems">
        <div>
          <img class="chip__image rounded-full" [src]="baseUrl + selectedItem.avatar_thumbnail_url" alt="" />
        </div>
        <div class="chip__name">{{ selectedItem.full_name }}</div>
        <div class="chip__remove">
          <i class="pi pi-times" (click)="onDeselect(selectedItem)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
