import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditPostState } from './edit-post.state';

export const editPostState = createFeatureSelector<EditPostState>('editPost');

export const loadingEditPost = createSelector(editPostState, editPost => editPost.loading);

export const isEditPostSuccess = createSelector(editPostState, editPost => editPost.isSuccess);

export const isEditPostFail = createSelector(editPostState, editPost => !!editPost.message);

export const postUpdated = createSelector(editPostState, editPost => (editPost.isSuccess ? editPost.postUpdated : {}));
