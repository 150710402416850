import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoinManagementService } from '@app/core/services/coin-management.service';
import { CommonService } from '@app/core/services/common.service';
import { PaymentService } from '@app/core/services/payment.service';
import { PollService } from '@app/core/services/poll.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { PollObject } from '@app/lib/api/poll/api.poll.model';
import { CoinManagementActions } from '@app/modules/main/personal-profile/components/personal-profile-coin/store/action';
import { selectMyCoin } from '@app/modules/main/personal-profile/components/personal-profile-coin/store/selector';
import { TOAST_MESSAGE_SEVERITY_LEVELS, TYPE_SEND_COIN } from '@app/shared/constant';
import { checkUrl, FILE_TYPE_URL } from '@app/shared/models/post';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { getAvailableCoinAmounts } from './store/actions';
import { selectAvailableCoinAmounts } from './store/selector';

const DIALOG_WIDTH_VALUE = {
  topUp: '588px',
  vote: '384px',
  send: '384px'
};

@Component({
  selector: 'manage-coins-dialog',
  templateUrl: './manage-coins-dialog.component.html',
  styleUrls: ['./manage-coins-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageCoinsDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() visible = false;
  @Input() title: string;
  @Input() pollData: PollObject;
  @Input() sendingType: string; // FRIEND, POST
  @Input() coinRecipientData: any; //USER, ANSWER,
  @Input() coinUsagePurpose: string; //VOTE, TOPUP, SENDING
  @Output() visibleChange = new EventEmitter();
  @Output() onSendCoinSuccessEvent = new EventEmitter();
  @Output() onTopUpSuccess = new EventEmitter();

  private destroy$ = new Subject<void>();
  myCoin: any;
  availableCoinAmounts: any;

  selectedCoinsData: any;
  isLoading = false;
  coinAmountNeedToTopUp: any = {};
  paymentProcessStep = {
    selectCoinAmount: 'SELECT_COIN_AMOUNT', // Step 1: Selecting the number of coins to top up / vote / sending
    selectPaymentMethod: 'SELECT_PAYMENT_METHOD' // Step 2: Choosing the payment method
  };
  paymentStep = this.paymentProcessStep.selectCoinAmount;
  paymentUrl: any;
  socialAppURL = environment.SOCIAL_APP_URL;
  visibleConfirm = false;
  currentToken: string;
  isWaitingPaymentProgress = false;
  isShowNotiPopup = false;
  needTopUpCoin = false;
  private getPaymentEventListener: () => void;

  constructor(
    private store: Store,
    public coinService: CoinManagementService,
    private pollService: PollService,
    private toastMessageService: ToastMessageService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private paymentService: PaymentService,
    private renderer: Renderer2
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && !!changes['visible'].currentValue) {
      this.store.dispatch(CoinManagementActions.onGetMyCoin());
    }
  }

  ngOnInit(): void {
    this.store.dispatch(CoinManagementActions.onGetMyCoin());
    this.store.select(selectMyCoin).subscribe((res) => {
      this.myCoin = res;
      this.needTopUpCoin = this.myCoin.total_balance < (this.selectedCoinsData?.amountCoin || 0);
    });
    this.store.select(selectAvailableCoinAmounts).subscribe((res) => {
      if (!res) {
        this.store.dispatch(getAvailableCoinAmounts());
      } else {
        this.availableCoinAmounts = res;
        this.onSelectCoinOption(res[0]);
      }
    });

    this.getPaymentEventListener = this.renderer.listen('window', 'message', (event) => {
      if (event.origin && event?.data?.paymentStatus) {
        if (this.currentToken) this.getPaymentStatus();
        this.paymentStep = this.paymentProcessStep.selectCoinAmount;
        window.parent.postMessage({ paymentStatus: null }, '*');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.getPaymentEventListener) {
      this.getPaymentEventListener();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPaymentStatus() {
    this.isWaitingPaymentProgress = true;
    this.paymentService.getPaymentStatus(this.currentToken).subscribe({
      next: res => {
        if (res.success) {
          switch (res.data.status) {
            case 'pending':
              setTimeout(() => {
                this.getPaymentStatus();
              }, 1000);
              break;
            case 'payment_failed':
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.error,
                'POLL.FAILED_TO_DEPOSIT_COINS'
              );
              this.onTopUpSuccess.emit(true);
              this.isWaitingPaymentProgress = false;
              break;
            case 'succeeded':
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.success,
                'POLL.DEPOSIT_COINS_SUCCESSFULLY'
              );
              this.store.dispatch(CoinManagementActions.onGetMyCoin());
              this.onTopUpSuccess.emit(true);
              this.isWaitingPaymentProgress = false;
              break;
            default:
              break;
          }
        } else {
          setTimeout(() => {
            this.getPaymentStatus();
          }, 1000);
        }
      },
      error: () => {
        setTimeout(() => {
          this.getPaymentStatus();
        }, 1000);
      }
    });
  }

  get dialogWidth(): string {
    if (this.paymentStep === this.paymentProcessStep.selectPaymentMethod) {
      return '800px';
    } else {
      switch (this.coinUsagePurpose) {
        case 'TOPUP':
          return DIALOG_WIDTH_VALUE.topUp;
        case 'VOTE':
          return DIALOG_WIDTH_VALUE.vote;
        default:
          return DIALOG_WIDTH_VALUE.send;
      }
    }
  }

  getVotingCoin(): any {
    if (!this.selectedCoinsData?.amountCoin) {
      this.selectedCoinsData = this.availableCoinAmounts[0];
    }
    return this.selectedCoinsData?.amountCoin ?? 0;
  }

  findClosetCoinAmount(neededCoins: number): void {
    this.coinAmountNeedToTopUp = this.availableCoinAmounts
      .filter((item: any) => item.amountCoin >= neededCoins)
      .reduce((closest: any, item: any) => (!closest || item.amountCoin < closest.amountCoin ? item : closest), null);
  }

  onSelectCoinOption(data: any): void {
    this.selectedCoinsData = data;
    this.needTopUpCoin = this.myCoin.total_balance < this.selectedCoinsData?.amountCoin;
    if (this.needTopUpCoin) {
      this.findClosetCoinAmount(this.selectedCoinsData?.amountCoin - this.myCoin?.total_balance);
    }
  }

  voteByCoin(): void {
    this.isLoading = true;
    const payload = {
      pollId: this.pollData.id,
      answerId: this.coinRecipientData?.answer_id,
      value: this.selectedCoinsData?.amountCoin
    };
    this.pollService.voteByCoin(payload).subscribe({
      next: res => {
        if (res?.success) {
          this.store.dispatch(CoinManagementActions.onGetMyCoin());
          this.onSendCoinSuccessEvent.emit(this.selectedCoinsData?.amountCoin);
          this.visibleChange.emit(false);
          this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, 'POLL.VOTE_SUCCESSFULLY');
        } else {
          if (res.error.code === 9524) {
            this.isShowNotiPopup = true;
          } else {
            this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.FAILED_TO_VOTE');
          }
        }
        this.isLoading = false;
      },
      error: () => {
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.FAILED_TO_VOTE');
        this.isLoading = false;
      }
    });
    this.visibleConfirm = false;
  }

  onSendCoins(): void {
    const recipientId =
      this.sendingType === 'FRIEND'
        ? this.coinRecipientData?.id
        : this.coinRecipientData?.page_object?.page_id || this.coinRecipientData?.user_object?.id;
    const amount = this.selectedCoinsData?.amountCoin;
    const payload =
      this.sendingType === 'FRIEND'
        ? { destination: recipientId, amount }
        : {
          to: recipientId,
          amount,
          type: this.coinRecipientData?.page_object ? TYPE_SEND_COIN.fanpage : TYPE_SEND_COIN.user,
          receiving_object_type: 'POST',
          receiving_object_id: this.coinRecipientData?.id
        };
    this.sendingType === 'FRIEND'
      ? this.store.dispatch(CoinManagementActions.onSendCoinToUser({ body: payload }))
      : this.store.dispatch(CoinManagementActions.onPostGiftCoin({ body: payload }));
    this.onSendCoinSuccessEvent.emit();
    this.visibleConfirm = false;
    this.visibleChange.emit(false);
  }

  onTopUpCoins(): void {
    this.isLoading = true;
    if (this.coinUsagePurpose === 'TOPUP') {
      this.coinAmountNeedToTopUp = this.selectedCoinsData;
    }
    const { price, amountCoin } = this.coinAmountNeedToTopUp;
    const userProfile = JSON.parse(localStorage.getItem('user_profile') || '{}');
    const payload = {
      total: price,
      sub_total: price,
      success_callback: `${this.socialAppURL}/payment-status?result=SUCCESS`,
      fail_callback: `${this.socialAppURL}/payment-status?result=FAILED`,
      amount: amountCoin,
      account_id: userProfile?.id,
      currency: JSON.parse(localStorage.getItem('currency-conversation-data') || '{}').currency
    };
    this.coinService.topUpCoins(payload).subscribe({
      next: (topUpResponse: any) => {
        if (topUpResponse?.token) {
          this.currentToken = topUpResponse.token;
          this.coinService.createTransition(topUpResponse?.token).subscribe({
            next: (transitionResponse: any) => {
              if (transitionResponse?.success) {
                this.paymentStep = this.paymentProcessStep.selectPaymentMethod;
                this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${topUpResponse?.payment_url}?paymentToken=${topUpResponse?.token}&noheader=true`
                );
              }
              this.isLoading = false;
            },
            error: () => {
              this.isLoading = false;
              this.toastMessageService.addToastMessage(
                TOAST_MESSAGE_SEVERITY_LEVELS.error,
                'POLL.FAILED_TO_DEPOSIT_COINS'
              );
            }
          });
        }
      },
      error: () => {
        this.isLoading = false;
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.FAILED_TO_DEPOSIT_COINS');
      }
    });
  }

  showAvatarDefault(): boolean {
    return (
      this.sendingType === 'POST' && this.coinRecipientData?.page_object && !this.coinRecipientData?.page_object?.avatar
    );
  }

  getAvatarUrl(): string {
    const url =
      this.sendingType === 'FRIEND'
        ? this.coinRecipientData?.avatar_thumbnail_url
        : this.coinRecipientData?.page_object?.avatar || this.coinRecipientData?.user_object?.avatar_thumbnail_url;

    return this.commonService.getImageUrl(url);
  }

  getReceiverName(): string {
    const name =
      this.sendingType === 'FRIEND'
        ? this.coinRecipientData?.full_name
        : this.coinRecipientData?.page_object?.page_name || this.coinRecipientData?.user_object?.full_name;
    return name;
  }

  validUrl(url: string) {
    return `${checkUrl(url, FILE_TYPE_URL.web)}.webp`;
  }

  handleImageError(event: any) {
    event.target.src = 'assets/images/default_user_avatar.png';
  }
}
