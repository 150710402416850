import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TopPerfPost } from '@app/lib/api/post/api.post.model';
import { FILE_EXTENSION, POST_TYPE } from '@app/shared/constant';
import { Post } from '@app/shared/models/post';
import { environment } from '@env/environment';

const TOP_POST_CONTEXT = {
  reaction: {
    icon: './assets/icons/analysis/TopPerforming-Reaction.svg',
    label: 'ENTITY.REACTION',
    pluralLabel: 'ENTITY.REACTIONS',
    title: 'HIGHEST_REACTIONS_ON_POST'
  },
  comment: {
    icon: './assets/icons/analysis/TopPerforming-Comment.svg',
    label: 'ENTITY.COMMENT',
    pluralLabel: 'ENTITY.COMMENTS',
    title: 'HIGHEST_COMMENTS_ON_POST'
  },
  share: {
    icon: './assets/icons/analysis/TopPerforming-Reaction.svg',
    label: 'ENTITY.SHARE',
    pluralLabel: 'ENTITY.SHARES',
    title: 'HIGHEST_SHARES_ON_POST'
  },
  coin: {
    icon: './assets/icons/analysis/TopPerforming-Reaction.svg',
    label: 'ENTITY.XU',
    pluralLabel: 'ENTITY.XU',
    title: 'HIGHEST_COINS_ON_POST'
  },
  reach: {
    icon: './assets/icons/analysis/TopPerforming-Reaction.svg',
    label: 'ENTITY.REACH',
    pluralLabel: 'ENTITY.REACHES',
    title: 'HIGHEST_REACHES_ON_POST'
  }
};

@Component({
  selector: 'top-performing-post',
  templateUrl: './top-performing-post.component.html',
  styleUrls: ['./top-performing-post.component.scss']
})
export class TopPerformingPostComponent implements OnInit {
  @Input() topPerfomingPostList = {} as TopPerfPost;
  @Input() isLoading = false;
  @Input() isPostGroup = false;
  topPerformingPosts: any = [];
  baseURL = environment.baseURL;

  ngOnInit(): void {
    this.processPostData(this.topPerfomingPostList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['topPerfomingPostList']) {
      this.processPostData(this.topPerfomingPostList);
    }
  }

  processPostData(postData: TopPerfPost): void {
    const topPerformingPosts = [];
    const topPostReaction = postData.top_post_reaction
      ? {
          post: postData.top_post_reaction,
          context: TOP_POST_CONTEXT.reaction,
          totalInteraction: postData.top_post_reaction.total_reactions ? postData.top_post_reaction.total_reactions : 0,
          thumbnailURL: this.getThumbnailURL(postData.top_post_reaction)
        }
      : null;
    if (topPostReaction && topPostReaction.totalInteraction) {
      topPerformingPosts.push(topPostReaction);
    }
    const topPostComment = postData.top_post_comment
      ? {
          post: postData.top_post_comment,
          context: TOP_POST_CONTEXT.comment,
          totalInteraction: postData.top_post_comment.total_comments ? postData.top_post_comment.total_comments : 0,
          thumbnailURL: this.getThumbnailURL(postData.top_post_comment)
        }
      : null;
    if (topPostComment && topPostComment.totalInteraction) {
      topPerformingPosts.push(topPostComment);
    }
    const topPostShare = postData.top_post_share
      ? {
          post: postData.top_post_share,
          context: TOP_POST_CONTEXT.share,
          totalInteraction: postData.top_post_share.total_shares ? postData.top_post_share.total_shares : 0,
          thumbnailURL: this.getThumbnailURL(postData.top_post_share)
        }
      : null;
    if (topPostShare && topPostShare.totalInteraction) {
      topPerformingPosts.push(topPostShare);
    }
    const topPostCoin = postData.top_post_coin
      ? {
          post: postData.top_post_coin,
          context: TOP_POST_CONTEXT.coin,
          totalInteraction: postData.top_post_coin.total_coins ? postData.top_post_coin.total_coins : 0,
          thumbnailURL: this.getThumbnailURL(postData.top_post_coin)
        }
      : null;
    if (topPostCoin && topPostCoin.totalInteraction) {
      topPerformingPosts.push(topPostCoin);
    }
    this.topPerformingPosts = topPerformingPosts;
  }

  getThumbnailURL(post: Post): string {
    if (post.post_pattern === POST_TYPE.normal || post.post_pattern === POST_TYPE.album) {
      return this.getThumbnailURLFromPost(post);
    } else if (post.post_pattern === POST_TYPE.share) {
      return post.parent ? this.getThumbnailURLFromPost(post.parent) : '';
    } else {
      return '';
    }
  }

  getThumbnailURLFromPost(post: Post): string {
    const mediaThumbnail =
      post.media_urls && post.media_urls.media && post.media_urls.media.length
        ? `${this.baseURL}/storage/files/thumbnail/${post.media_urls.media[0].thumbnail}${FILE_EXTENSION.image}`
        : '';
    const albumThumbnail =
      post.library_object && post.library_object.thumbnail ? this.baseURL + post.library_object.thumbnail : '';
    const thumbnailURL = albumThumbnail ? albumThumbnail : mediaThumbnail;
    return thumbnailURL;
  }

  onClickedPost(postDetails: Post): void {
    //TODO: Handle click to post
  }
}
