<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="false"
  (onHide)="onCloseDialog()"
  contentStyleClass="overflow-visible border-0 px-6 py-0"
  styleClass="w-[440px] border border-palette-gray-200 rounded-xl bg-palette-base-white">
  <ng-template pTemplate="header" class="rounded-t-xl">
    <div class="flex flex-col w-full">
      <div class="flex justify-between items-center">
        <span class="text-lg text-palette-gray-900 font-bold">{{ 'TRANSFER_OWNERSHIP.TITLE' | translate }}</span>
        <i class="sctr-icon-x-close text-xl text-palette-gray-500 cursor-pointer" (click)="onCloseDialog()"></i>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngIf="!(isSelectedUser && isSelectedNewRole); else confirmationContent">
      <ng-container
        *ngIf="!isSelectedUser; else selectNewRoleContent"
        [ngTemplateOutlet]="selectUserContent"></ng-container>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row items-center justify-end gap-3">
      <ng-container *ngIf="this.isSelectedUser && !this.isSelectedNewRole; else cancelBtn">
        <button
          class="cta-button border-palette-gray-300 bg-palette-base-white"
          type="submit"
          (click)="this.isSelectedUser = false">
          <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.BACK' | translate }}</p>
        </button>
      </ng-container>
      <ng-template #cancelBtn>
        <button
          class="cta-button border-palette-gray-300 bg-palette-base-white"
          type="submit"
          (click)="onCloseDialog()">
          <p class="text-sm font-semibold text-palette-gray-700">{{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}</p>
        </button>
      </ng-template>
      <ng-container *ngIf="!(isSelectedUser && isSelectedNewRole); else confirmationBtn">
        <ng-container
          *ngIf="!isSelectedUser; else confirmRoleBtn"
          [ngTemplateOutlet]="continueBtn"
          [ngTemplateOutletContext]="{
            disabled: !selectedUser
          }">
        </ng-container>
        <ng-template #confirmRoleBtn>
          <ng-container
            [ngTemplateOutlet]="continueBtn"
            [ngTemplateOutletContext]="{
              disabled: !newRole
            }">
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #confirmationBtn>
        <ng-container
          [ngTemplateOutlet]="countdownBtn"
          [ngTemplateOutletContext]="{
            countdown: countdown
          }">
        </ng-container>
      </ng-template>
    </div>
  </ng-template>
</p-dialog>

<ng-template #continueBtn let-disabled="disabled">
  <button
    (click)="onClickedContinueBtn()"
    class="cta-button text-palette-base-white {{
      !disabled
        ? 'bg-branding-primary-600 border-branding-primary-600'
        : 'bg-branding-primary-200 border-branding-primary-200'
    }}"
    [disabled]="disabled">
    {{ 'COMMON.BUTTON_ACTION.CONTINUE' | translate }}
  </button>
</ng-template>

<ng-template #countdownBtn let-disabled="disabled" let-countdown="countdown">
  <button
    (click)="onClickedConfirmBtn()"
    class="cta-button text-palette-base-white {{
      countdown === 0 ? 'bg-system-error-600 border-system-error-600' : 'bg-system-error-200 border-system-error-200'
    }} "
    [disabled]="countdown !== 0">
    {{ 'COMMON.BUTTON_ACTION.CONFIRM' | translate }}
    <span *ngIf="countdown !== 0">({{ countdown }})</span>
  </button>
</ng-template>

<ng-template #userItems>
  <div class="flex flex-col gap-1">
    <div *ngIf="!isLoadingSearch">
      <ng-container *ngFor="let userSelect of userSelectList">
        <div
          class="flex justify-between items-center p-1 rounded-md hover:bg-palette-gray-200 cursor-pointer"
          (click)="onSelectedItem(userSelect)">
          <div class="flex gap-2 items-center">
            <img [src]="getImgUrl(userSelect.userInfo.avatar_thumbnail_url)" alt="" class="h-8 w-8 rounded-full" />
            <div class="flex flex-col">
              <span class="text-sm text-palette-gray-700 line-clamp-1">{{ userSelect.userInfo.full_name }}</span>
              <span *ngIf="roleLabels.includes(userSelect.userInfo.role)" class="text-xs text-palette-gray-400"
                >{{ 'ROLES.' + userSelect.userInfo.role | translate }}
              </span>
            </div>
          </div>
          <p-checkbox
            [binary]="true"
            [(ngModel)]="userSelect.isSelected"
            [disabled]="true"
            checkboxIcon="hover:sctr-icon-minus sctr-icon-check text-xs font-bold text-branding-primary-600"></p-checkbox>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isLoadingSearch" [ngTemplateOutlet]="loadingItem"> </ng-container>
  </div>
</ng-template>

<ng-template #selectUserContent>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col w-full gap-4">
      <span class="p-input-icon-left h-10 w-full">
        <i class="sctr-icon-search-lg text-lg text-palette-gray-500 leading-none"></i>
        <input
          type="text"
          pInputText
          placeholder="{{ 'COMMON.SEARCH' | translate }}"
          class="w-full py-2 pr-3.5 rounded-lg text-palette-gray-500"
          #searchUser
          (keyup)="onSearch(searchUser.value)" />
      </span>
    </div>
    <span class="text-sm text-palette-gray-700 font-bold">{{ 'COMMON.SUGGESTIONS' | translate }}</span>
    <div *ngIf="userList && userList.length; else noAvailableFriends">
      <ng-container *ngIf="userList.length < 5; else userListWithScrollBar">
        <ng-container *ngTemplateOutlet="userItems"></ng-container>
      </ng-container>
      <ng-template #userListWithScrollBar>
        <p-scrollPanel styleClass="w-full h-[216px]">
          <ng-container *ngTemplateOutlet="userItems"></ng-container>
        </p-scrollPanel>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #selectNewRoleContent>
  <div class="flex flex-col gap-4">
    <span
      class="break-words"
      [innerHTML]="'TRANSFER_OWNERSHIP.SELECT_NEW_ROLE_DESCRIPTION' | translate: { fullName: selectedUser.full_name }">
    </span>
    <p-dropdown
      styleClass="w-full rounded-lg h-11"
      [options]="userRoles"
      [(ngModel)]="newRole"
      placeholder="{{ 'TRANSFER_OWNERSHIP.SELECT_ROLE_PLACEHOLDER' | translate }}"
      optionLabel="label"
      scrollHeight="240px">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="newRole">
          <span>{{ 'ROLES.' + newRole.label | translate }}</span>
        </div>
      </ng-template>
      <ng-template let-role pTemplate="item">
        <span>{{ 'ROLES.' + role.label | translate }}</span>
      </ng-template>
    </p-dropdown>
  </div>
</ng-template>

<ng-template #confirmationContent>
  <div class="flex flex-col gap-2">
    <span
      class="break-words"
      [innerHTML]="
        'TRANSFER_OWNERSHIP.CONFIRM_SENT_REQUEST_DESCRIPTION' | translate: { fullName: selectedUser.full_name }
      ">
    </span>
    <span>{{ 'COMMON.CONFIRM_PROCEED_ACTION' | translate }}</span>
  </div>
</ng-template>

<ng-template #noAvailableFriends>
  <div class="w-full pr-5 flex flex-col justify-center items-center gap-2">
    <div
      class="h-14 w-14 bg-palette-gray-100 border-8 border-palette-gray-50 rounded-full flex items-center justify-center">
      <i class="sctr-icon-users-01 text-2xl font-medium text-palette-gray-600"></i>
    </div>
    <span>{{ 'FRIEND.NO_RESULTS' | translate }}</span>
  </div>
</ng-template>

<ng-template #loadingItem let-widthSkeleton="widthSkeleton">
  <div class="flex gap-2 items-center">
    <p-skeleton shape="circle" size="32px"></p-skeleton>
    <p-skeleton [width]="widthSkeleton ? widthSkeleton : '12rem'" height="1rem"></p-skeleton>
  </div>
</ng-template>
