import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PollService } from '@app/core/services/poll.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { PollInfo, PollObject, VoteUnVoteRequest } from '@app/lib/api/poll/api.poll.model';
import { PostDialogActions } from '@app/shared/components/create-post-dialog/store/post-dialog.actions';
import { selectPreviousUrl } from '@app/shared/components/create-post-dialog/store/post-dialog.selector';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/constant';
import { checkUrl, FILE_TYPE_URL, POLL_MODE, POLL_STATUS, POLL_TYPE, POLL_VOTING_TYPE, Post } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { first, interval, Subscription } from 'rxjs';

@Component({
  selector: 'post-content-poll',
  templateUrl: './post-content-poll.component.html',
  styleUrls: ['./post-content-poll.component.scss']
})
export class PostContentPollComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userInfo: UserInfo;
  @Input() postData: Post;
  @Input() editedPollId: string;
  @Input() isFullScreen = false;
  @Input() isSharedPost = false;
  private startTimeSubscription: Subscription | null;
  private endTimeSubscription: Subscription | null;
  data: PollObject;
  backgroundStyle: any;
  visibleDetail = false;
  intervalSubs: Subscription;
  isPollOwner = false;
  isShowEndPollDialog = false;
  isShowDownLoadQrCode = false;
  pollResult: PollInfo[];
  votingType = POLL_VOTING_TYPE;
  showManageCoinDialog = false;
  selectedAnswerData: PollInfo;
  pollStatus = POLL_STATUS;
  isPollView = false;
  isOpenPopupLogin = false;
  isShowNotiPopup = false;
  isEndTimeCountdown = false;
  countdownStartTime: any;
  countdownEndTime: any;
  options: any = {
    path: 'assets/animations/Confetti.json'
  };
  pollMode = POLL_MODE;
  visiblePresentationControl = false;
  isStartPresentationPoll = false;

  constructor(
    private pollService: PollService,
    private el: ElementRef,
    private router: Router,
    private location: Location,
    private store: Store,
    private toastMessageService: ToastMessageService,
    private translationService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editedPollId'] && changes['editedPollId'].currentValue) {
      if (this.postData.poll_id === changes['editedPollId'].currentValue) {
        this.pollService.getPollData(this.postData.poll_id || '').subscribe({
          next: res => {
            if (res.success) {
              this.reloadView(res.data);
            }
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.startCountdown();
    this.isPollView = this.router.url?.startsWith('/poll/') || false;
    if (this.postData.poll_id) {
      this.pollService.getPollData(this.postData.poll_id).subscribe(res => {
        this.data = res.data;
        if (this.data) {
          if (this.data.poll_mode === this.pollMode.presentation && this.data.start_date) this.isStartPresentationPoll = true;
          this.pollResult = this.data.poll_result;
          if (this.data.background_image) {
            const bgImg = this.data.background_image;
            const img = new Image();
            img.src = this.validUrl(bgImg);
            img.onload = () => {
              this.backgroundStyle = {
                'background-image': `url(${this.validUrl(bgImg)})`,
                'background-position': 'center',
                'background-size': 'cover',
                'background-repeat': 'no-repeat'
              };
            };
          }
          // Detect is poll in view after loaded data
          setTimeout(() => {
            this.isScrolledIntoView();
          }, 2000);
        }
      });
    }
    if (this.userInfo && this.postData.user_id) {
      this.isPollOwner = this.userInfo.id === this.postData.user_id;
    }
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    const element = this.el.nativeElement.querySelector('.poll-container');
    if (element) {
      if (this.isElementInViewport(element) && this.isStartedPoll() && !this.isEndedPoll()) {
        this.refreshData();
      } else {
        this.clearPollInterval();
      }
    }
  }

  isElementInViewport(el: any) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) ||
      rect.bottom >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  ngOnDestroy(): void {
    this.clearPollInterval();
    this.clearCountdown();
  }

  validUrl(url: string) {
    return `${checkUrl(url, FILE_TYPE_URL.web)}.webp`;
  }

  onUpdateValue(event: number) {
    const voteInfo = {
      user_id: this.userInfo.id,
      user_data: {
        full_name: this.userInfo.full_name,
        avatar_thumbnail_url: this.userInfo.avatar_thumbnail_url
      }
    };
    const isLatestVote = this.selectedAnswerData.latest_votes.find(el => el.user_id === this.userInfo.id);
    if (!isLatestVote) {
      this.selectedAnswerData.latest_votes.push(voteInfo);
    }
    this.selectedAnswerData.value ? (this.selectedAnswerData.value += event) : (this.selectedAnswerData.value = event);
    this.refreshData();
  }

  onClickOption(selectedPoll: PollInfo, index: number) {
    if (!this.isStartedPoll()) {
      this.isShowNotiPopup = true;
      return;
    }
    if (this.data.voting_type === this.votingType.COINS) {
      this.showManageCoinDialog = true;
      const selectedAnswer = this.pollResult.find(answer => answer?.answer_id === selectedPoll.answer_id);
      if (selectedAnswer) this.selectedAnswerData = selectedAnswer;
      return;
    }

    switch (this.data.poll_type) {
      case POLL_TYPE.SINGLE:
        if (!this.pollResult.find(el => el.is_voted)) {
          this.votePoll(selectedPoll.answer_id, index);
        } else {
          const isSelected = this.pollResult.find(el => el.answer_id === selectedPoll.answer_id)?.is_voted;
          if (isSelected) {
            this.unVotePoll(selectedPoll.answer_id, index);
          } else {
            this.votePoll(selectedPoll.answer_id, index, true);
          }
        }
        break;
      case POLL_TYPE.MULTIPLE:
        const isSelected = this.pollResult.find(el => el.answer_id === selectedPoll.answer_id)?.is_voted;
        if (isSelected) {
          this.unVotePoll(selectedPoll.answer_id, index);
        } else {
          this.votePoll(selectedPoll.answer_id, index);
        }
        break;
      default:
        break;
    }
  }

  clearPollInterval() {
    if (this.intervalSubs && !this.intervalSubs.closed) {
      this.intervalSubs.unsubscribe();
    }
  }

  refreshData() {
    this.clearPollInterval();
    this.intervalSubs = interval(3000).subscribe(() => {
      if (!this.isStartedPoll() || this.isEndedPoll()) return;
      this.pollService.getPollData(this.postData.poll_id || '').subscribe({
        next: res => {
          if (res.success) {
            this.reloadView(res.data);
          }
        }
      });
    });
  }

  reloadView(currentData: PollObject) {
    const currentAnswer = currentData.poll_result;
    this.pollResult.forEach((el, i) => {
      if (el.is_voted !== currentAnswer[i].is_voted) this.pollResult[i].is_voted = currentAnswer[i].is_voted;
      if (JSON.stringify(el.latest_votes) !== JSON.stringify(currentAnswer[i].latest_votes))
        this.pollResult[i].latest_votes = currentAnswer[i].latest_votes;
      if (el.rate !== currentAnswer[i].rate) this.pollResult[i].rate = currentAnswer[i].rate;
      if (el.value !== currentAnswer[i].value) this.pollResult[i].value = currentAnswer[i].value;
      if (el.votes !== currentAnswer[i].votes) this.pollResult[i].votes = currentAnswer[i].votes;
      if (el.image !== currentAnswer[i].image) this.pollResult[i].image = currentAnswer[i].image;
      if (el.name !== currentAnswer[i].name) this.pollResult[i].name = currentAnswer[i].name;
    });
    if (currentAnswer.length !== this.pollResult.length) {
      this.pollResult = currentAnswer;
    }
    this.data = { ...currentData };
  }

  isStartedPoll() {
    const startTime = new Date(this.data.start_date);
    const currentTime = new Date();
    return startTime < currentTime;
  }

  isEndedPoll() {
    const endTime = new Date(this.data.end_date);
    const currentTime = new Date();
    return this.data.end_date && (endTime < currentTime || this.data.status === this.pollStatus.END);
  }

  getStartTime() {
    return {
      time: moment(this.data.start_date).format('HH:mm'),
      day: moment(this.data.start_date).format('DD'),
      month: moment(this.data.start_date).format('MM'),
      year: moment(this.data.start_date).format('YYYY')
    };
  }

  isExpiredToday() {
    const endDate = moment(this.data.end_date).format('YYYY-MM-DD');
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    return endDate === currentDate;
  }

  getEndTime() {
    return {
      time: moment(this.data.end_date).format('HH:mm'),
      day: moment(this.data.end_date).format('DD'),
      month: moment(this.data.end_date).format('MM'),
      year: moment(this.data.end_date).format('YYYY')
    };
  }

  handleImageError(event: any, imgId?: string) {
    event.target.src = 'assets/images/default_user_avatar.png';
    if (imgId) {
      setTimeout(() => {
        event.target.src = this.validUrl(imgId);
      }, 1000);
    }
  }

  votePoll(answerId: string, index: number, isSelectedAnswerInSingleMode = false) {
    this.clearPollInterval();
    const body: VoteUnVoteRequest = {
      poll_id: this.data.id,
      answers: [
        {
          answer_id: answerId
        }
      ]
    };
    if (isSelectedAnswerInSingleMode) {
      const unVoteIndex = this.pollResult.findIndex(el => el.is_voted);
      this.pollResult[unVoteIndex].latest_votes = this.pollResult[unVoteIndex].latest_votes.filter(
        el => el.user_id !== this.userInfo.id
      );
      if (this.pollResult[unVoteIndex].votes !== 0) this.pollResult[unVoteIndex].votes -= 1;
      this.pollResult[unVoteIndex].is_voted = false;
    }
    this.pollResult[index].is_voted = true;
    const voteInfo = {
      user_id: this.userInfo.id,
      user_data: {
        full_name: this.userInfo.full_name,
        avatar_thumbnail_url: this.userInfo.avatar_thumbnail_url
      }
    };
    const isLatestVote = this.pollResult[index].latest_votes.find(el => el.user_id === this.userInfo.id);
    if (!isLatestVote) {
      this.pollResult[index].latest_votes.push(voteInfo);
    }
    this.pollResult[index].votes += 1;
    if (!isSelectedAnswerInSingleMode) this.data.total_votes += 1;
    this.pollResult = this.pollResult.map(el => {
      el.rate =
        this.data.voting_type === this.votingType.COINS
          ? el.value || 0 / this.data.total_value
          : el.votes / this.data.total_votes;
      return el;
    });
    this.pollService.votePoll(body).subscribe({
      next: (res) => {
        // 9524: Reach maximum vote error code
        if (!res.success && res.error.code === 9524) {
          this.toastMessageService.addToastMessage(
            TOAST_MESSAGE_SEVERITY_LEVELS.error,
            this.translationService.getTranslationWithParams('POLL.REACH_MAX_NUMBER_VOTE', {number: this.data.limit_vote_times?.votes, duration: (this.data.limit_vote_times?.duration || 0) / (60 * 60)})
          );
        }
        this.refreshData();
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  unVotePoll(answerId: string, index: number) {
    this.clearPollInterval();
    const body: VoteUnVoteRequest = {
      poll_id: this.data.id,
      answers: [
        {
          answer_id: answerId
        }
      ]
    };
    this.pollResult[index].is_voted = false;
    this.pollResult[index].latest_votes = this.pollResult[index].latest_votes.filter(
      el => el.user_id !== this.userInfo.id
    );
    if (this.pollResult[index].votes !== 0) this.pollResult[index].votes -= 1;
    if (this.data.total_votes !== 0) this.data.total_votes -= 1;
    this.pollResult = this.pollResult.map(el => {
      el.rate =
        this.data.voting_type === this.votingType.COINS
          ? el.value || 0 / this.data.total_value
          : el.votes / this.data.total_votes;
      return el;
    });
    this.pollService.unVotePoll(body).subscribe({
      next: () => {
        this.refreshData();
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  endPoll() {
    this.pollService.endPoll(this.data.id).subscribe(res => {
      if (res.success) {
        this.isShowEndPollDialog = false;
        this.visiblePresentationControl = false;
        this.data.status = this.pollStatus.END;
      }
    });
  }

  getVoteRateWidth(rate: number) {
    return {
      'width': `${rate * 100}%`
    };
  }

  onVisibleChange(event: boolean) {
    this.visibleDetail = event;
    if (!event) {
      this.pollService.getPollData(this.postData.poll_id || '').subscribe({
        next: (res) => {
          this.data = res.data;
          if (this.data) {
            this.pollResult = this.data.poll_result;
            this.refreshData();
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }

  showOverviewPopup() {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') || '[]')?.isLoggedIn || false;
    if (isLoggedIn) {
      this.clearPollInterval();
      this.visibleDetail = true;
    }
  }

  quantityView(quantity: number) {
    if (quantity >= 1000000) {
      return (quantity / 1000000).toFixed(1) + 'm';
    } else if (quantity >= 10000) {
      return (quantity / 1000).toFixed(0) + 'k';
    } else {
      return quantity.toString();
    }
  }

  isAnyImageInList() {
    return !!this.pollResult.find(el => el.image !== '');
  }

  withoutTop3List() {
    let sortedArr = [];
    if (this.data.voting_type === this.votingType.COINS)
      sortedArr = this.pollResult.sort((a, b) => (b.value || 0) - (a.value || 0));
    sortedArr = this.pollResult.sort((a, b) => b.votes - a.votes);
    return this.backgroundStyle ? sortedArr.slice(3) : sortedArr;
  }

  private startCountdown() {
    this.updateStartCountdown();
    this.startTimeSubscription = interval(1000).subscribe(() => {
      this.updateStartCountdown();
    });
  }

  private updateStartCountdown() {
    const now = new Date();
    const startDate = new Date(this.data?.start_date || '');

    if (isNaN(startDate.getTime())) {
      this.countdownStartTime = '';
      return;
    }

    const timeDiff = startDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      this.countdownStartTime = '00:00:00';
      this.clearStartCountdown();
      this.startEndCountdown();
    } else {
      this.countdownStartTime = this.formatTimeDiff(timeDiff);
    }
  }

  private startEndCountdown() {
    this.isEndTimeCountdown = true;
    this.updateEndCountdown();
    this.endTimeSubscription = interval(1000).subscribe(() => {
      this.updateEndCountdown();
    });
  }

  private updateEndCountdown() {
    const now = new Date();
    const endDate = new Date(this.data?.end_date || '');

    if (isNaN(endDate.getTime())) {
      this.countdownEndTime = '';
      return;
    }

    const timeDiffEnd = endDate.getTime() - now.getTime();

    if (timeDiffEnd <= 0) {
      this.countdownEndTime = '00:00:00';
      this.clearEndCountdown();
    } else {
      this.countdownEndTime = this.formatTimeDiff(timeDiffEnd);
    }
  }

  private formatTimeDiff(timeDiff: number) {
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);
    return {
      days: days,
      time: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    };
  }

  private clearCountdown() {
    this.clearStartCountdown();
    this.clearEndCountdown();
  }

  private clearStartCountdown() {
    if (this.startTimeSubscription) {
      this.startTimeSubscription.unsubscribe();
      this.startTimeSubscription = null;
    }
  }

  private clearEndCountdown() {
    if (this.endTimeSubscription) {
      this.endTimeSubscription.unsubscribe();
      this.endTimeSubscription = null;
    }
  }

  expandPollView(minimize = false) {
    if (minimize) {
      this.store
        .select(selectPreviousUrl)
        .pipe(first())
        .subscribe(previousUrl => {
          if (previousUrl?.length) return this.location.back();
          if (!previousUrl?.length) {
            setTimeout(() => {
              return this.router.navigate([`/post/${this.postData.id}`]);
            }, 0);
          }
        });
    }
    this.store.dispatch(PostDialogActions.onGetPreviousUrl({ previousUrl: this.router.url }));
    return this.router.navigate([`/poll/${this.postData.id}`]);
  }

  startPresentationPoll() {
    this.clearPollInterval();
    this.pollService.startPoll(this.data.id).subscribe({
      next: (res) => {
        if (res.success) this.isStartPresentationPoll = true;
        this.refreshData();
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  updatePriorityAnswer(answerId: string) {
    if (this.data.priority_answer_id === answerId) return;
    this.clearPollInterval();
    if (!this.data.start_date) {
      this.pollService.startPoll(this.data.id).subscribe({
        next: (res) => {
          if (res.success) {
            this.pollService.updatePriorityAnswer(this.data.id, answerId).subscribe({
              next: (res) => {
                if (res.success) {
                  this.refreshData();
                  this.data.priority_answer_id = answerId;
                }
              },
              error: () => {
                this.refreshData();
              }
            });
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    } else {
      this.pollService.updatePriorityAnswer(this.data.id, answerId).subscribe({
        next: (res) => {
          if (res.success) {
            this.refreshData();
            this.data.priority_answer_id = answerId;
          }
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }

  answerInfo(answerId: string) {
    return this.pollResult.find(el => el.answer_id === answerId);
  }

  turnToWaitingScreen() {
    if (!this.data.priority_answer_id) return;
    this.clearPollInterval();
    this.pollService.updatePriorityAnswer(this.data.id, '').subscribe({
      next: (res) => {
        if (res.success) {
          this.refreshData();
          delete this.data.priority_answer_id;
        }
      },
      error: () => {
        this.refreshData();
      }
    });
  }

  votePresentationMode(answerId: string) {
    if (this.data.voting_type === this.votingType.COINS) {
      this.showManageCoinDialog = true;
      const selectedAnswer = this.pollResult.find(answer => answer?.answer_id === answerId);
      if (selectedAnswer) this.selectedAnswerData = selectedAnswer;
      return;
    }

    const body: VoteUnVoteRequest = {
      poll_id: this.data.id,
      answers: [
        {
          answer_id: answerId
        }
      ]
    };
    const currentAnswerIndex = this.pollResult.findIndex(el => el.answer_id === answerId);
    if (currentAnswerIndex === -1) {
      this.refreshData();
      return;
    }
    this.pollResult[currentAnswerIndex].is_voted = true;
    if (this.data.poll_mode === this.pollMode.presentation) {
      this.pollService.votePoll(body).subscribe({
        next: () => {
          this.refreshData();
        },
        error: () => {
          this.refreshData();
        }
      });
    }
  }
}
