import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@app/core/services/translation.service';
import { EventApiModels } from '@app/lib/api/event/api.event.models';
import { FanpageService } from '@app/modules/main/fanpage/service/fanpage.service';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, ROLES, TAB_EVENTS } from '@app/shared';
import { MenuItem } from 'primeng/api';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'group-events',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './group-events.component.html',
  styleUrls: ['./group-events.component.scss']
})
export class GroupEventsComponent implements OnInit, OnChanges {
  @Input() dataGroupEvents: EventApiModels.EventDetails[];
  @Input() dataFanPageDetail: any;
  @Input() dataGroupEventsHappeningNow: EventApiModels.EventDetails[];
  @Input() dataGroupEventsPast: EventApiModels.EventDetails[];
  @Input() eventsActionsAdmin: MenuItem[] | any;
  @Input() eventsActionsUser: MenuItem[] | any;
  @Input() fanPageIsShow: boolean = false;
  @Input() userRole: string | undefined;
  @Input() isShowCreateButton: boolean = false;
  @Input() isShowCopyLink: boolean;
  @Input() pageTotal: number;
  @Input() pageNum: number;
  @Input() isLoading: boolean;
  @Output() pageNumChange = new EventEmitter();
  @Output() pageIndexChange = new EventEmitter();
  @Output() actionDropDow = new EventEmitter();
  @Output() valueSearch = new EventEmitter();
  @Output() actionAttend = new EventEmitter();
  @Output() actionHeart = new EventEmitter();
  @Output() navigateDetail = new EventEmitter();
  @Output() actionVisibleOpen = new EventEmitter();
  @Output() actionVisibleClose = new EventEmitter();
  @Output() handleDetailEventGroup = new EventEmitter();

  private destroy$ = new Subject<void>();
  groupRoles = ROLES;
  selectedButton: string = '';
  eventSearchValue = new FormControl('');
  isHiddenAction: boolean = true;
  isShowActionEvent: boolean = false;
  groupId: string | null;
  pageSizeDefault = PAGE_SIZE_DEFAULT;
  pageNumDefault = PAGE_NUM_DEFAULT;
  isChangeTab: boolean = false;
  tabEvents = TAB_EVENTS;
  pastEventMenu: MenuItem[] | any;
  isModerate = false;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private translationService: TranslationService,
    public fanpageService: FanpageService
  ) {}

  ngOnInit(): void {
    this.selectedButton = 'Upcoming';
    this.isModerate = this.activeRoute.snapshot.queryParams['admin'] === 'true';
    if (this.dataFanPageDetail) {
      this.isShowActionEvent =
        this.dataFanPageDetail.role === this.groupRoles.OWNER ||
        this.dataFanPageDetail.role === this.groupRoles.ADMIN ||
        this.dataFanPageDetail.role === this.groupRoles.MOD;
    }
    this.activeRoute.paramMap.subscribe(params => {
      this.groupId = params.get('id');
    });
    this.pastEventMenu = [
      {
        label: `<span class="text-system-error-600 font-semibold text-base">${this.translationService.getTranslation(
          'COMMON.BUTTON_ACTION.DELETE'
        )}</span>`,
        escape: false,
        icon: 'sctr-icon-trash-01 text-lg',
        iconClass: 'text-system-error-600 font-medium text-base',
        disabled: false
      }
    ];
    this.eventSearchValue.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(value => {
      this.isChangeTab = true;
      this.valueSearch.emit({
        valueSearch: value,
        selectedButton: this.selectedButton
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading'] && this.isChangeTab) {
      this.isChangeTab = changes['isLoading'].currentValue;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectButtonClick(buttonID: string): void {
    this.selectedButton = buttonID;
    this.pageNumDefault = PAGE_NUM_DEFAULT;
    this.isChangeTab = true;
    this.pageNumChange.emit(0);
    this.valueSearch.emit({
      valueSearch: this.eventSearchValue.value,
      selectedButton: this.selectedButton
    });
  }

  actionDropDow1(event: any): void {
    this.actionDropDow.emit(event);
  }

  handleActionAttend(event: any): void {
    this.actionAttend.emit(event);
  }
  handleActionHeart(event: any): void {
    this.actionHeart.emit(event);
  }

  showDialogCreateEvents(): void {
    this.actionVisibleOpen.emit(true);
  }

  handleCloseModal(event: boolean): void {
    this.actionVisibleClose.emit(event);
  }

  handleNavigateDetail(event: any): void {
    if (this.router.url.startsWith('/group')) {
      this.router.navigate([`group/${this.groupId}/event/${event.eventDetailId}`]);
      localStorage.setItem('groupDetailEvent', 'active');
      this.handleDetailEventGroup.emit('active');
    } else {
      let fan_page_id = localStorage.getItem('fanPageId');
      this.router.navigate([`fanpage/${fan_page_id}/event/${event.eventDetailId}`]);
    }
  }

  onPageChange(event: any): void {
    let param = {
      valueSearch: '',
      selectedButton: this.selectedButton
    };
    this.pageIndexChange.emit({ pageIndex: event, param });
  }
}
