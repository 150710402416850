import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Livestream, SearchLives } from '@app/shared/models/livestream.model';
import { environment } from '@env/environment';
import { catchError, map, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LivestreamService {
  apiUrl = environment.baseURL + '/livestream';
  constructor(private http: HttpClient) {}

  createNewLive(body: any) {
    return this.http.post<Livestream>(this.apiUrl + `/lives/new`, body).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getLiveById(id: string) {
    return this.http.get<Livestream>(this.apiUrl + `/lives/v2/${id}`).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  updateLiveById(body: any) {
    return this.http.put<Livestream>(this.apiUrl + `/lives/${body.id}`, body).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  deleteLiveById(id: string) {
    return this.http.delete<Livestream>(this.apiUrl + `/lives/${id}`).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  searchLives(object: SearchLives) {
    return this.http
      .get<any[]>(
        this.apiUrl +
          `/lives/search?viewGroupType=${object.viewGroupType}&pageNum=${object.pageNum}&pageSize=${object.pageSize}`
      )
      .pipe(
        retry(1),
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError)
      );
  }

  getFristPage() {
    const body: any = { pageSize: 10 };
    return this.http.get<Livestream>(this.apiUrl + '/lives/first-page', body).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  updatePostIdForLive(body: { id: string; postId: string }) {
    return this.http.put<Livestream>(this.apiUrl + `/lives/${body.id}/updatePostId?postId=${body.postId}`, body).pipe(
      retry(1),
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
