import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { UtilityService } from '@app/core/services/utility.service';
import { SEARCH_DEBOUNCE_TIME } from '@app/shared/constant';
import { Subject, debounceTime } from 'rxjs';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'invite-friends-dialog',
  templateUrl: './invite-friends-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./invite-friends-dialog.component.scss']
})
export class InviteFriendsDialogComponent {
  @Input() isVisible = false;
  @Input() isLoading = false;
  @Input() isLoadingMore = false;
  @Input() invitingFriends = false;
  @Input() title: string;
  @Input() friendList: any[];
  @Input() friendCheckboxes: boolean[];
  @Output() isVisibleChange = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onSearchFriends = new EventEmitter();
  @Output() isConfirmedSendInvites = new EventEmitter();
  @Output() loadMore = new EventEmitter();

  selectedFriends: any[] = [];
  multipleChanges = false;
  baseUrl: string = environment.baseURL;
  searchKeyword = '';
  searchFriends = new Subject<string>();

  constructor(
    private commonService: CommonService,
    public utilityService: UtilityService
  ) {
    this.searchFriends.pipe(debounceTime(SEARCH_DEBOUNCE_TIME)).subscribe(value => {
      this.onSearchFriends.emit({ searchKeyword: value, selectedFriends: this.selectedFriends });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['friendCheckboxes']) {
      if (!this.selectedFriends.length) {
        this.multipleChanges = false;
      }
    }
  }

  onMultipleChanges(): void {
    if (this.multipleChanges) {
      this.friendCheckboxes.fill(true);
      for (let i = 0; i < this.friendList.length; i++) {
        this.onSelectedFriend(this.friendList[i], i);
      }
    } else {
      this.friendCheckboxes.fill(false);
      this.selectedFriends = [];
    }
  }

  onSelectedFriend(friend: any, index: number): void {
    this.multipleChanges = true;
    const selectedFriendIds = this.selectedFriends.map(item => item.id);
    if (selectedFriendIds.includes(friend.id)) {
      this.selectedFriends = this.selectedFriends.filter(item => item.id !== friend.id);
      if (!this.selectedFriends.length) {
        this.multipleChanges = false;
      }
    } else {
      friend['index'] = index;
      this.selectedFriends.unshift(friend);
    }
  }

  onRemoveSeletedFriend(friend: any): void {
    this.friendCheckboxes[friend.index] = false;
    this.selectedFriends = this.selectedFriends.filter(item => item !== friend);
    if (!this.selectedFriends.length) {
      this.multipleChanges = false;
    }
  }

  onSendInviteFriend(): void {
    this.isConfirmedSendInvites.emit(this.selectedFriends);
  }

  onSearch(keyword: string): void {
    this.searchFriends.next(keyword);
  }

  onCloseDialog(): void {
    this.selectedFriends = [];
    this.multipleChanges = false;
    this.searchKeyword = '';
    this.isVisibleChange.emit(false);
  }

  onShowDialog(): void {
    this.onShow.emit(true);
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  onScrolledToBottom(): void {
    this.loadMore.emit();
  }
}
