import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[checkAuthentication]'
})
export class CheckAuthenticationDirective {
  @Output() openLoginPopup = new EventEmitter<boolean>();
  constructor() { }


  @HostListener('click') onClick() {
    const isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    if (!isLoggedIn) {
      this.openLoginPopup.emit(true);
    }
  }
}
