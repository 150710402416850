import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommentsService } from '@app/core/services/comments.service';
import { ReactionsService } from '@app/core/services/reactions.service';
import { Comment } from '@app/lib/api/comments/api.comments.model';
import { Viewer } from '@app/lib/api/reactions/api.reactions.model';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import { FILE_EXTENSION, MEDIA_TYPE } from '@app/shared/constant';
import { ENTITY_TYPE, EmojiList, FILE_TYPE_URL, checkUrl } from '@app/shared/models/post';
import { Story } from '@app/shared/models/story';
import { Store } from '@ngrx/store';

@Component({
  selector: 'dialog-viewers',
  templateUrl: './dialog-viewers.component.html',
  styleUrls: ['./dialog-viewers.component.scss']
})
export class DialogViewersComponent implements OnChanges {
  @Input() visibleViewers: boolean;
  @Input() transferContent: number;
  @Input() storiesByUser: Story[];
  @Input() isModerateStory = false;
  @Input() myStory: boolean;
  @Input() comments: Comment[] = [];
  @Input() comment: Comment;
  @Input() totalCommentsAndReplies = 0;
  @Input() activeIndex = 0;
  @Input() isViewArchive = false;
  @Input() userInfoId = '';
  @Output() totalCommentsAndRepliesChange = new EventEmitter<number>();
  @Output() onHideEvent = new EventEmitter<number>();
  @Output() toggleImageSelectionEvent = new EventEmitter<number>();

  userInfo$ = this.store.select(selectUserInfo);
  mediaType = MEDIA_TYPE;
  fileType = FILE_TYPE_URL;
  listViewer: Viewer[] = [];
  loading = false;

  constructor(
    private store: Store,
    private commentsService: CommentsService,
    private reactionsService: ReactionsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.myStory && (changes['transferContent'] || changes['storiesByUser'])) {
      this.listViewer = [];
      if (this.storiesByUser[this.transferContent].total_viewers) {
        this.loading = true;
        this.reactionsService.getViewer(this.storiesByUser[this.transferContent].id).subscribe(res => {
          this.listViewer = res.filter(item => item.created_by.id !== this.userInfoId);
          this.loading = false;
        });
      }
    }
  }

  getEmojiIcon(reaction: string): string {
    const emoji = EmojiList.find(emoji => emoji.type === reaction);
    return emoji ? emoji.icon : '';
  }

  toggleImageSelection(index: number) {
    this.toggleImageSelectionEvent.emit(index);
  }

  onHide() {
    this.onHideEvent.emit(this.activeIndex);
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    let fileExtension = FILE_EXTENSION.image;
    if (style === FILE_TYPE_URL.streamVideo) {
      fileExtension = FILE_EXTENSION.video;
    }
    return checkUrl(urlString, style) + fileExtension;
  }

  addComment(data: any) {
    const param = {
      object_id: this.storiesByUser[this.transferContent].id,
      object_type: ENTITY_TYPE.story,
      parent_id: this.storiesByUser[this.transferContent].id,
      content: data.content,
      message_ranges: data.messageRanges,
      medias: data.medias,
      preview_data: data.preview_data
    };
    this.commentsService.postComment(param).subscribe(res => {
      if (res) {
        this.onIncreaseTotal();
        this.comments.push(res);
      }
    });
  }

  onIncreaseTotal(parent_id: string = '') {
    this.comments = this.comments.map(el => {
      if (el.id === parent_id) {
        el.total_replies++;
      }
      return el;
    });
    this.calculateTotalComment(1);
  }

  calculateTotalComment(number: number) {
    this.totalCommentsAndReplies += number;
    this.totalCommentsAndRepliesChange.emit(this.totalCommentsAndReplies);
  }

  deleteComment(id: string) {
    const indexToRemove = this.comments.findIndex(item => {
      return item.id === id;
    });
    if (indexToRemove !== -1) {
      const totalReplies = this.comments[indexToRemove].total_replies;
      this.comments.splice(indexToRemove, 1);
      this.calculateTotalComment(-(1 + totalReplies));
    } else {
      // Comment id deleted is a reply
      this.calculateTotalComment(-1);
    }
  }

  isCommentHidden(commentId: string): boolean {
    let arrHideCommentId = localStorage.getItem('arrHideCommentId');
    let newArrHideCommentId = JSON.parse(arrHideCommentId || '[]');
    return newArrHideCommentId.includes(commentId);
  }
}
