<p-dialog
  [(visible)]="dialogVisible"
  [styleClass]="'w-[792px] h-[534px] rounded-xl overflow-hidden custom-quick-summary'"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  (onShow)="initData()"
  (onHide)="onHide()">
  <ng-template pTemplate="header">
    <span class="text-lg font-semibold text-palette-gray-900">{{
      'QUICK_SUMMARY.CUSTOMIZE_QUICK_REPORT' | translate
    }}</span>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex gap-6">
      <div class="w-[358px] min-h-[350px] p-3 bg-gray-100 rounded-lg">
        <div class="grid w-full grid-cols-2 gap-x-[12px] gap-y-3">
          <ng-container *ngFor="let item of listQuickSummary; let i = index">
            <div
              *ngIf="customReport[item.key]"
              class="flex gap-[6px] w-auto py-2 px-1 bg-palette-base-white rounded-[6px] items-center">
                <div class="{{item.bgIcon}}">
                  <i class="{{ item.icon }} m-[8px] text-lg"></i>
                </div>
              <div class="text-xs font-semibold">{{ item.label | translate }}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="w-[370px] h-[350px] bg-transparent">
        <div class="text-base font-semibold text-palette-gray-700">
          {{ 'QUICK_SUMMARY.SELECT_THE_CONTENT_TO_DISPLAY' | translate }}
        </div>
        <div class="text-xs font-normal text-palette-gray-700">
          {{ 'QUICK_SUMMARY.CHOOSE_FROM_THE_OPTION_BELOW' | translate }}
        </div>
        <div class="grid w-full grid-cols-2 gap-x-7 gap-y-3 mt-4 pb-4 border-b">
          <ng-container *ngFor="let item of listQuickSummary; let i = index">
            <div class="flex gap-2 items-center">
              <p-checkbox
                [(ngModel)]="customReport[item.key]"
                [binary]="true"
                class="flex h-[18px] w-4 items-start justify-center"></p-checkbox>
              <div class="checkbox-option flex flex-col">
                <label class="font-medium text-sm text-palette-gray-700">{{ item.label | translate }}</label>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="mt-4 flex justify-between items-center">
          <div class="font-medium text-sm text-palette-gray-700">{{ 'QUICK_SUMMARY.TIME_RANGE' | translate }}</div>
          <p-dropdown
            [panelStyleClass]="'dropdown-quick-report'"
            appendTo="body"
            [styleClass]="'custom-dropdown w-[144px]'"
            [options]="timeRange"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="customReport.time_range_type">
            <ng-template let-item pTemplate="selectedItem">
              <span>{{ item.label | translate }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="flex gap-1 w-full justify-between items-center">
                <span class="font-normal text-sm text-palette-gray-700">{{ item.label | translate}}</span>
                <i *ngIf="customReport.time_range_type === item.value" class="sctr-icon-check text-palette-blue-600"></i>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template pTemplate="footer">
    <p-button
      [styleClass]="
        'px-5 rounded-lg border-palette-gray-300 hover:bg-palette-gray-200 y-3 text-palette-gray-900 font-semibold text-sm'
      "
      (onClick)="cancel()"
      [label]="'COMMON.BUTTON_ACTION.CANCEL' | translate"
      styleClass="p-button-text"></p-button>
    <p-button
      [styleClass]="
        'px-[20px] rounded-lg bg-palette-blue-600 hover:bg-palette-blue-700 border-palette-gray-300 y-3 text-palette-base-white font-semibold text-sm'
      "
      (onClick)="applySelection()"
      [label]="'COMMON.BUTTON_ACTION.APPLY' | translate"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
