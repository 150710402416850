import { createAction, emptyProps, props } from '@ngrx/store';

export enum ReportTypes {
  getReportCategories = '[Report Categories] get all report categories',
  getReportCategoriesSuccess = '[Report Categories] get all report categories success',
  getReportCategoriesFailed = '[Report Categories] get all report categories failed'
}

// get report categories
export const getReportCategories = createAction(ReportTypes.getReportCategories, emptyProps);
export const getReportCategoriesSuccess = createAction(
  ReportTypes.getReportCategoriesSuccess,
  props<{ reportCategories: any }>()
);
export const getReportCategoriesFailed = createAction(
  ReportTypes.getReportCategoriesFailed,
  props<{ message: string }>()
);
