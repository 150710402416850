<div class="flex flex-col md:flex-row gap-2 h-fit md:h-11 items-center">
  <date-range-calender
    [rangeDates]="dateRanges1"
    (selectedDateRanges)="onSelectedDateRanges1($event)"></date-range-calender>
  <span class="text-xs text-palette-gray-700">{{ 'STATISTIC.COMPARE_TO' | translate }}</span>
  <date-range-calender
    [rangeDates]="dateRanges2"
    [dateRangesCount]="dateRangesCount"
    [disabledDates]="disabledDates"
    (selectedDateRanges)="onSelectedDateRanges2($event)"></date-range-calender>
</div>
