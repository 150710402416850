import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ThirdPartySignUpComponent } from './thirdparty-sign-up.component';

const routes = [
  {
    path: 'callback/:registrationId',
    component: ThirdPartySignUpComponent
  }
];
@NgModule({
  declarations: [ThirdPartySignUpComponent],
  exports: [ThirdPartySignUpComponent],
  imports: [CommonModule, ReactiveFormsModule, RouterModule.forChild(routes), GoogleSigninButtonModule],

})
export class ThirdPartySignUpModule {}
