import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { ClickOutsideService } from '@app/core/services/outside-click.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[outsideClick]'
})
export class OutsideClickDirective {
  @Output() outsideClicked: EventEmitter<MouseEvent> = new EventEmitter();
  private clickSubscription!: Subscription;

  constructor(private elementRef: ElementRef, private clickOutsideService: ClickOutsideService) {}

  ngOnInit(): void {
    this.clickSubscription = this.clickOutsideService.click$.subscribe((event: Event) => {
      const clickedInside = this.elementRef.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.outsideClicked.emit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
    }
  }
}
