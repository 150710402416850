import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RelationshipStatusEnum } from '@app/core/enums/makeFriend.enum';
import { CommonService } from '@app/core/services/common.service';
import { FriendAPI } from '@app/lib/api/friend/api.friend.model';
import { CURRENT_SCREEN, VISIBLE_SMALL_SCREEN } from '@app/shared/constant';

@Component({
  selector: 'people-card',
  templateUrl: './people-card.component.html',
  styleUrls: ['./people-card.component.scss']
})
export class PeopleCardComponent {
  @Input() type: string;
  @Input() loading: boolean;
  @Input() totalLoadedData: FriendAPI.FriendContentModel[];
  @Input() customData: FriendAPI.FriendContentModel;
  @Output() actionsSentCoinEmit = new EventEmitter();
  @Output() newsFeedActionEmit = new EventEmitter();
  CURRENT_SCREEN = CURRENT_SCREEN;

  friendButton = [
    {
      color: 'bg-palette-blue-dark-400 border-palette-blue-dark-400 text-palette-base-white',
      text: 'COMMON.CANCEL_REQUEST',
      relationshipStatus: RelationshipStatusEnum.SentRequest
    },
    {
      color: 'bg-palette-blue-50 text-palette-blue-700',
      text: 'COMMON.ADD_FRIEND',
      relationshipStatus: RelationshipStatusEnum.UnKnow
    },
    {
      color: 'bg-palette-blue-700 border-palette-blue-700 text-palette-base-white',
      text: 'COMMON.BUTTON_ACTION.CONFIRM',
      relationshipStatus: RelationshipStatusEnum.ReceivedRequest
    },
    {
      color: 'bg-palette-green-700 border-palette-green-700 text-palette-base-white',
      text: 'ENTITY.FRIEND',
      relationshipStatus: RelationshipStatusEnum.FriendShip
    }
  ];

  constructor(private commonService: CommonService) {}

  getAvatarUrl(avatar: string): string {
    return this.commonService.getImageUrl(avatar);
  }

  onEmitNewsFeedAction(item: FriendAPI.FriendContentModel, actionType: string) {
    this.newsFeedActionEmit.emit({ item, actionType });
  }

  onLoadingData(customData: FriendAPI.FriendContentModel) {
    if (this.totalLoadedData?.length >= Math.round(VISIBLE_SMALL_SCREEN)) {
      return this.totalLoadedData?.indexOf(customData) === this.totalLoadedData?.length - 1;
    }
    return false;
  }

  navigateToProfile(data: any) {
    if (!data) return;
    return ['/personal-profile', data.id];
  }

  onEmitSentCoinAction(coinReceiver: FriendAPI.FriendContentModel, showPopup: boolean) {
    this.actionsSentCoinEmit.emit({ coinReceiver, showPopup });
  }
}
