import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { FriendService } from '@app/core/services/friend.service';
import { MediaService } from '@app/core/services/media.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { CheckInAPI } from '@app/lib/api/check-in/api.check-in.model';
import { PreviewLink } from '@app/lib/api/post/api.post.model';
import { loadMemberGroup } from '@app/modules/main/group/store/actions';
import { selectGroupPrivacy } from '@app/modules/main/group/store/selectors';
import { CreatePostActions, CreatePostApiActions } from '@app/modules/main/states/create-post/create-post.actions';
import { postCreated } from '@app/modules/main/states/create-post/create-post.selectors';
import { themePosts } from '@app/modules/main/states/user-posts/user-posts.selectors';
import { PrivilegeService } from '@app/services/privilege.service';
import {
  AllowedTypes,
  BREAKPOINTS,
  CREATE_POST_TYPES,
  CURRENT_PAGE,
  DISCOVER_VISIBLE_NORMAL,
  FILE_EXTENSION,
  KEYBOARD_EVENT_CODES,
  LAYOUT_TYPE,
  MAX_TRUNCATED_TEXT_HEIGHT,
  MEDIA_TYPE,
  PAGE_NUM_DEFAULT,
  PAGE_SIZE_DEFAULT,
  POST_TYPE,
  PRIVACY,
  PRIVACY_OPTIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS,
  TemplatePost,
  VERIFICATION_STATUS
} from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import {
  FILE_TYPE_URL,
  LivestreamInfo,
  MessageRanges,
  Post,
  ProgressBar,
  SHARE_TYPE,
  checkUrl
} from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import * as htmlToImage from 'html-to-image';
import { difference } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { Carousel } from 'primeng/carousel';
import { OverlayPanel } from 'primeng/overlaypanel';
import Resumable from 'resumablejs';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PostDialogActions } from '../store/post-dialog.actions';
import { isLoadingCreatePost } from './../../../../modules/main/states/create-post/create-post.selectors';
import { TextareaPostComponent } from './textarea-post/textarea-post.component';
import { UserPostsActions } from '@app/modules/main/states/user-posts/user-posts.actions';

export interface FileWithPreview {
  thumbnail: string;
  original: string;
  file?: any;
  type: any;
  upload?: boolean;
}

interface DropdownOption {
  label: string;
  privacy: any;
  icon: string;
}

interface MentionOverlayPosition {
  top: number;
  left: number;
  contentBeforeMention?: string;
}

@Component({
  selector: 'post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
  providers: [MessageService]
})
export class PostDialogComponent implements OnChanges, AfterViewInit {
  @Input() sharePostType = '';
  @Input() sharedPost?: Post;
  @Input() groupInfo: any;
  @Input() visible = false;
  @Input() isGroupPost = false;
  @Input() isFanpagePost = false;
  @Input() groupPrivacy: string;
  @Input() groupId: string;
  @Input() isImmediatelyShownPost = true;
  @Input() currentPage: string;
  @Input() captureImg: string;
  @Input() userInfo: UserInfo;
  @Input() fanpageInfo: any;
  @Input() loading = false;
  @Input() isUploadFileGroup: boolean = false;
  @Input() friendList: FriendList[];
  @Input() groupList: FriendList[];
  @Input() messageRanges: MessageRanges[] = [];
  @Input() contentComment: string;
  @Input() isEditPost = false;
  @Input() memberGroup: any;
  @Input() checkInObject?: CheckInAPI.CheckInRequest;
  @Input() postEdit: any;
  @Output() postData: EventEmitter<any> = new EventEmitter<any>();
  @Output() callProgress: EventEmitter<any> = new EventEmitter<any>();
  @Output() visibleChange = new EventEmitter(false);
  @Output() goBackToGroupDialog = new EventEmitter();
  @Output() isEditPostChange = new EventEmitter<boolean>();
  @Output() messageRangesChange = new EventEmitter<[]>();
  @Output() shareTypeChange = new EventEmitter();
  @Output() privacyPost = new EventEmitter();
  @Output() postSubmitted = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagFriend', { static: false }) inputTagFriend: ElementRef;
  @ViewChild('tagGroup', { static: false }) inputTagGroup: ElementRef;
  contentHeight = 0;
  selectGroupPrivacy$ = this.store.pipe(select(selectGroupPrivacy));
  checkPrivacyGroup: string | undefined;
  apiUrl: string = environment.baseURL;
  selectedPrivacy: string = PRIVACY.public;
  selectedFiles: FileWithPreview[] = [];
  filesPost: FileWithPreview[] = [];
  showUploadSection = false;
  showChooseLayout = false;
  activeLayout = LAYOUT_TYPE.vertical;
  isEditImgVideo = false;
  isFormDisabled = true;
  progressValue = 0;
  tagCount = 0;
  hashtagCount = 0;
  caretPosition = 0;
  video = MEDIA_TYPE.video;
  image = MEDIA_TYPE.image;
  privacyOptions: DropdownOption[];
  privacy = PRIVACY_OPTIONS[0];
  POST_TYPE = POST_TYPE;
  baseUrl = environment.baseURL;
  MAX_TRUNCATED_TEXT_HEIGHT = MAX_TRUNCATED_TEXT_HEIGHT;
  limit = 30000;
  exceedsLimit = false;
  previewData: PreviewLink | null = null;
  removedPreviewLinks: string[] = [];
  shareType?: number;
  // Index of the dragged item
  draggedItem: any;
  draggedIndex: number = -1;
  //FriendTag variable
  friendTag: any[];
  friend_tags: any[];
  suggestions: any[];
  selectionFriendTag: any[] = [];
  isShowSuggestions = false;
  showProgress = false;
  showFriendTag = false;
  taggingFriend = false;
  content = '';
  //MemberTag variable
  showGroupMembers = false;
  groupTag: any[];
  memberGroupSuggestion: any[];
  isShowSuggestionsGroup = false;
  selectionGroupTag: any[] = [];
  // Status variable
  showStatusTag: boolean = false;
  selectedStatus: string;
  // Share variable
  shareTypeList = SHARE_TYPE;
  // Check In variable
  isPendingCheckIn = false;
  //
  selectedTheme: any;
  selectedThemeBefore: any;
  livestream_info: LivestreamInfo;
  themes: any = [
    {
      'id': '0000000-0000-0000-0000-000000000000',
      'name': 'Default',
      'code': 'default',
      'background_color': ['string'],
      'background_img': 'string',
      'font_style': 'string',
      'theme_type': 'COLOR',
      'background_thumbnail': 'string',
      'font_color': 'string'
    }
  ];
  numVisible = DISCOVER_VISIBLE_NORMAL;
  discover: TemplatePost[];
  isEditting = false;
  initialTextValue = '';
  isTheme = true;
  screenWidth = window.innerWidth;
  currentCharacter = 0;
  loadingUrl = false;
  submitted = false;
  responsiveOptions = [
    {
      breakpoint: '768px',
      numVisible: 7,
      numScroll: 5
    }
  ];
  visibleCloseConfirm = false;
  confirmCloseActions = {
    discard: 'DISCARD',
    saveAsDraft: 'SAVE_AS_DRAFT',
    keepEditing: 'KEEP_EDITING'
  };
  pollId: string;

  @ViewChild('captureContainer') captureContainer: ElementRef;
  @ViewChild('textareaPost') textareaEl: ElementRef;
  @ViewChild(TextareaPostComponent) textArea: TextareaPostComponent;
  private searchSubjectGroup = new Subject<string>();
  private searchSubscription: Subscription | undefined;
  private searchSubscriptionGroup: Subscription | undefined;
  private hasFetchedGroupMembers = false;

  //list friend tag
  showMentionOverlay = true;
  mentionStartPosition = 0;
  overlayPosition: MentionOverlayPosition = { top: 0, left: 0 };
  highlightedIndex = 0;
  searchKey = '';
  searchMentionSubject = new Subject<any>();
  allFriendList: FriendList[] = [];
  mentionList: FriendList[] = [];
  mentionedList: { fullName: string; mentionStartPosition: number }[] = [];
  pageNum = PAGE_NUM_DEFAULT;
  pageNumAllFriends = PAGE_NUM_DEFAULT;
  isLastPage = false;
  isLastPageAllFriends = false;
  isLoadingMentionList = false;
  isLoadingThemes = false;
  isLoadingMoreMentionList = false;
  resumable: Resumable | null;
  isLargeVideoFileSize = false;
  @ViewChild('tagOverlayPanel') tagOverlayPanel!: OverlayPanel;
  @ViewChild('tagListContainer') tagListContainer!: ElementRef<HTMLDivElement>;
  allowedTypes = AllowedTypes;

  constructor(
    private messageService: MessageService,
    private mediaService: MediaService,
    private postsService: PostsService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private toastMessageService: ToastMessageService,
    private store: Store,
    private el: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private friendService: FriendService,
    private privilegeService: PrivilegeService
  ) {
    Carousel.prototype.onTouchMove = () => {};
    this.searchMentionSubject.pipe(debounceTime(300)).subscribe(data => {
      this.searchMention(data);
    });
  }

  ngAfterViewInit(): void {
    // This case handle for Profile Timline
    if (
      this.currentPage === 'TIMELINE' &&
      this.userInfo?.post_privacy &&
      this.privacyOptions &&
      this.privacyOptions.length
    ) {
      const found =
        this.privacyOptions.find(opt => opt.privacy === this.userInfo.post_privacy) || this.privacyOptions[0];
      this.privacy = found;
      this.selectedPrivacy = found.privacy || PRIVACY.public;
    }
  }

  ngOnInit(): void {
    this.translationService.language$.subscribe(() => {
      this.privacyOptions = PRIVACY_OPTIONS.map(item => ({
        ...item,
        label: this.translationService.getTranslation(item.label),
        icon: item.icon
      }));
    });

    this.store.select(postCreated).subscribe(post => {
      if (post) {
        this.loading = false;
        this.isFormDisabled = true;
        this.resetDataInForm();
        if (!this.isLargeVideoFileSize) this.callProgress.emit(ProgressBar.value_100);
      }
    });

    this.store.select(isLoadingCreatePost).subscribe(isLoading => {
      if (isLoading) {
        this.loading = false;
        this.isFormDisabled = true;
        this.resetDataInForm();
        if (!this.isLargeVideoFileSize) this.callProgress.emit(ProgressBar.value_100);
      }
    });

    this.searchSubscriptionGroup = this.searchSubjectGroup.pipe(debounceTime(100)).subscribe(query => {
      this.filterSuggestionsGroup(query);
    });

    this.handlePrivacyGroup();
  }

  loadThemes() {
    this.isLoadingThemes = true;
    this.store.select(themePosts).subscribe(res => {
      if(!res.length){
        this.store.dispatch(UserPostsActions.onGetThemePost());
      }else{
        this.isLoadingThemes = false;
        this.themes = [this.themes[0], ...res];
        return this.themes;
      }
    });
  }

  get hasNewPostData() {
    return !!(
      this.content ||
      (this.selectedFiles && this.selectedFiles.length) ||
      (this.selectionFriendTag && this.selectionFriendTag.length) ||
      (this.selectionGroupTag && this.selectionGroupTag.length) ||
      this.selectedStatus ||
      this.checkInObject ||
      this.selectedTheme ||
      this.previewData ||
      this.captureImg ||
      this.shareType ||
      this.isEditPost
    );
  }

  get overlayPositionTop() {
    return this.selectionFriendTag && this.selectionFriendTag.length > 0
      ? this.overlayPosition.top + 300
      : this.overlayPosition.top + 270;
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.searchSubscriptionGroup) {
      this.searchSubscriptionGroup.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items;
    if (items) {
      const itemsArray = Array.from(items);
      for (const item of itemsArray) {
        if (item.type.startsWith('image')) {
          const blob = item.getAsFile();
          if (blob) {
            event.preventDefault();
            let items = [blob];
            this.handleFileUpload({ target: { files: items || [] } });
          }
          break;
        }
      }
    }
  }

  checkCharacterLimit() {
    return this.textArea?.$textarea?.nativeElement?.value.length > this.limit;
  }

  isURL(str: string): boolean {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(str);
  }

  onRemovePreviewLink(event: string): void {
    this.removedPreviewLinks.push(event);
    this.previewData = null;
  }

  changeContent(content: string) {
    if (typeof content !== 'string' || !content.length) {
      this.content = '';
      return;
    }
    this.content = content;
    if (
      this.isURL(this.content) &&
      !this.previewData &&
      !this.removedPreviewLinks.includes(this.content) &&
      !this.selectedTheme &&
      !this.selectedFiles.length &&
      !this.captureImg
    ) {
      this.loadingUrl = true;
      this.postsService.getPreviewLink(this.content).subscribe(res => {
        this.previewData = res && res.success && res.data ? res.data : null;
        this.loadingUrl = false;
        this.disabledTheme();
      });
    }
    const lines = content.split('\n');
    const isLongContent = content.length > 150;
    const hasMoreThanFiveLines = lines.length > 5;
    if (isLongContent || hasMoreThanFiveLines) {
      this.isTheme = false;
      this.disabledTheme();
    } else if (!this.selectedFiles.length) {
      this.isTheme = true;
      if (this.selectedThemeBefore) {
        this.selectedTheme = this.selectedThemeBefore;
      }
    }
  }

  selectTheme(theme: any): void {
    if (theme?.code === 'default') {
      this.selectedTheme = null;
      this.el.nativeElement.querySelector('.theme-bg').style.backgroundImage = '';
    } else {
      this.selectedTheme = theme;
      if (theme?.theme_type === 'IMAGE') {
        if (this.el.nativeElement.querySelector('.theme-bg')) {
          this.el.nativeElement.querySelector(
            '.theme-bg'
          ).style.backgroundImage = `url("${this.apiUrl}/storage/files/web/${theme?.background_img}.webp")`;
        }
      }
    }
  }
  disabledTheme(): void {
    if (this.selectedTheme) {
      this.selectedThemeBefore = this.selectedTheme;
    }
    this.selectedTheme = null;
  }

  applyThemeStyles(theme: any) {
    if (!theme) {
      return `min-h-[120px]`;
    }
    if (theme?.theme_type === 'IMAGE') {
      if (this.el.nativeElement.querySelector('.theme-bg')) {
        this.el.nativeElement.querySelector(
          '.theme-bg'
        ).style.backgroundImage = `url("${this.apiUrl}/storage/files/web/${theme?.background_img}.webp")`;
      }
      return `bg-cover max-h-[400px] bg-[#${theme?.background_color[0]}]`;
    } else if (theme?.theme_type === 'COLOR') {
      if (this.el.nativeElement.querySelector('.theme-bg')) {
        this.el.nativeElement.querySelector('.theme-bg').style.backgroundImage = '';
      }
      return `${theme?.background_thumbnail} max-h-[400px]`;
    }
    return `min-h-[120px]`;
  }
  applyText(theme: any) {
    if (theme) {
      return `text-[#${theme.font_color}]`;
    }
    return `text-palette-gray-500`;
  }
  onShowOverlayPanel() {
    (document.querySelector('.emoji-mart-anchor') as HTMLElement).click();
  }
  onSelectEmoji(event: any): void {
    const cursorPosition = this.textArea.textarea.nativeElement.selectionStart;
    const textBeforeMention = this.content.substring(0, cursorPosition - this.searchKey.length);
    const textAfterMention = this.content.substring(cursorPosition);
    this.content = textBeforeMention + event.emoji.native + textAfterMention;
    const mentionedCursor = `${textBeforeMention}${event.emoji.native}`.length;
    setTimeout(() => {
      this.textArea.textarea.nativeElement.focus();
      this.textArea.textarea.nativeElement.setSelectionRange(mentionedCursor, mentionedCursor);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEditPost'] && changes['isEditPost'].currentValue) {
      this.initialTextValue = this.content;
      setTimeout(() => {
        const textareaEl = this.textareaEl?.nativeElement;
        if (textareaEl) {
          textareaEl.innerHTML = this.contentComment;
          this.moveCursorToEnd(textareaEl);
        }
      });
      this.selectedPrivacy = this.postEdit.post_privacy;
    }
    if (changes['groupList']) {
      this.groupList = changes['groupList'].currentValue || [];
      if (this.isEditPost) {
        this.messageRanges = this.messageRanges.map((i: MessageRanges, idx: number) => {
          const found = this.groupList.find(friend => friend.id === i.entity_id);
          this.tagCount++;
          return {
            ...i,
            id: `tag-${idx}`,
            full_name: found?.full_name
          };
        });
      }
    }

    if (changes['loading'] && changes['loading'].currentValue === false) {
      this.closeDialog();
    }

    if (changes['groupInfo'] && changes['groupInfo'].currentValue) {
      this.selectedPrivacy = changes['groupInfo'].currentValue.privacy.toUpperCase();
      this.setPrivacyBasedOnGroupInfo();
    }

    if (changes['userInfo'] && changes['userInfo'].currentValue) {
      const userInfo = changes['userInfo'] && changes['userInfo'].currentValue;

      if (userInfo.post_privacy && this.privacyOptions && this.privacyOptions.length) {
        const found = this.privacyOptions.find(opt => opt.privacy === userInfo.post_privacy) || this.privacyOptions[0];
        this.privacy = found;
        this.selectedPrivacy = found.privacy || PRIVACY.public;
      }
    }
    this.setPrivacyEditPost();
  }

  // Event handler for search input keyup
  onSearchKeyUp(event: any): void {
    if (
      ![KEYBOARD_EVENT_CODES.ArrowUp, KEYBOARD_EVENT_CODES.ArrowDown, KEYBOARD_EVENT_CODES.Enter].includes(event.key)
    ) {
      this.handleMention(event);
    }
  }
  // Event handler for search input keyup
  onSearchKeyUpGroup(event: any): void {
    this.searchSubjectGroup.next(event.target.value);
  }
  // Event handler for adding friend tag
  addGroupTag(event: any) {
    // Extract the clicked item from the event
    const clickedItem: any = event.option;

    // Find the index of the clicked item within the suggestions array
    const clickedIndex = this.memberGroupSuggestion.indexOf(clickedItem);

    // Check if the clicked friend tag is already in the selection list
    const isSelected = this.selectionGroupTag.some(tag => tag === this.memberGroupSuggestion[clickedIndex]);

    if (!isSelected) {
      // Add the selected friend tag to the selection list
      this.selectionGroupTag = [...this.selectionGroupTag, this.memberGroupSuggestion[clickedIndex]];
    }

    // Hide the suggestions after a selection is made
    this.isShowSuggestionsGroup = false;

    this.inputTagGroup.nativeElement.value = '';
    this.isEditting = true;
  }
  // Event handler for deleting friend tags
  deleteFriendTags(index: number) {
    this.mentionList.push(this.selectionFriendTag[index]);
    this.selectionFriendTag.splice(index, 1);
    const updateSuggest = difference(this.friendTag, this.selectionFriendTag).filter(item => item);
    this.suggestions = updateSuggest;
    this.isEditting = true;
  }
  deleteGroupTags(index: number) {
    this.selectionGroupTag.splice(index, 1);
    const updateSuggest = difference(this.groupTag, this.selectionGroupTag).filter(item => item);
    this.suggestions = updateSuggest;
  }
  // Toggle friend tag section
  toggleFriendTags(): void {
    this.showFriendTag = !this.showFriendTag; // Toggle visibility without making the API call
  }
  // Filter friend tag suggestions based on search query
  filterSuggestionsGroup(query: string): void {
    // Filter suggestions based on query
    let filtered: any[] = [];
    if (query.startsWith('@')) {
      let queryAfterAt = query.slice(1);
      for (let i = 0; i < (this.groupTag as any[]).length; i++) {
        let group = (this.groupTag as any[])[i];

        if (group?.user_info.full_name.toLowerCase().indexOf(queryAfterAt.toLowerCase()) === 0) {
          filtered.push(group);
        }
      }
      this.isShowSuggestionsGroup = filtered.length > 0 ? true : false;
      this.memberGroupSuggestion = filtered;
      if (this.selectionGroupTag.length) {
        const removeGroupTagged = difference(this.memberGroupSuggestion, this.selectionGroupTag);
        this.memberGroupSuggestion = removeGroupTagged;
      }
    } else {
      this.isShowSuggestionsGroup = false;
      this.memberGroupSuggestion = [];
    }
  }
  // Toggle member tag section
  toggleGroupMembers(): void {
    if (!this.hasFetchedGroupMembers) {
      if (this.memberGroup) {
        this.groupTag = this.memberGroup.data;
        this.hasFetchedGroupMembers = true;
        this.showGroupMembers = !this.showGroupMembers;
      }
    } else {
      this.showGroupMembers = !this.showGroupMembers;
    }
  }

  toggleStatusTags(enable: boolean) {
    this.showStatusTag = enable;
  }

  onDialogHide(): void {
    this.visibleChange.emit(false);
    this.isPendingCheckIn = false;
    this.showStatusTag = false;
    if (!this.submitted) {
      if (this.hasNewPostData) {
        this.visibleCloseConfirm = true;
      } else {
        !this.submitted &&
          this.store.dispatch(
            CreatePostApiActions.onSetStatusPost({
              isCreatingPost: !this.shouldDisableButton() || !!this.content
            })
          );
        this.submitted = false;
      }
    }
  }

  shouldDisableButton(): boolean {
    let check = false;
    if (this.shareType) {
      return false;
    }
    if (
      (this.selectedFiles && !this.selectedFiles.length) ||
      (this.selectionFriendTag && !this.selectionFriendTag.length) ||
      !this.selectedStatus?.length ||
      this.showProgress ||
      !this.checkInObject ||
      this.textArea?.$textarea?.nativeElement?.value.length < 1 ||
      this.textArea?.$textarea?.nativeElement?.value.length > this.limit ||
      this.textArea?.$textarea?.nativeElement?.value.trim().length === 0
    ) {
      check = true;
    }
    if (
      ((this.selectedFiles && this.selectedFiles.length) ||
        (this.selectionFriendTag && this.selectionFriendTag.length) ||
        this.selectedStatus?.length ||
        this.captureImg ||
        this.checkInObject ||
        (this.textArea?.$textarea?.nativeElement?.value.length > 0 &&
          this.textArea?.$textarea?.nativeElement?.value.length <= this.limit &&
          this.textArea?.$textarea?.nativeElement?.value.trim().length !== 0) ||
        (this.messageRanges && this.messageRanges.length)) &&
      this.textArea?.$textarea?.nativeElement?.value.length <= this.limit
    ) {
      check = false;
    }
    return check;
  }

  resetDataInForm(): void {
    this.isEditPostChange.emit(false);
    this.messageRanges = [];
    this.messageRangesChange.emit([]);
    this.content = '';
    this.selectedFiles = [];
    this.filesPost = [];
    this.showUploadSection = false;
    this.showChooseLayout = false;
    this.showProgress = false;
    this.selectedPrivacy = PRIVACY.public;
    this.handlePrivacyGroup();
    this.selectionFriendTag = [];
    this.selectionGroupTag = [];
    this.selectedStatus = '';
    this.showFriendTag = false;
    this.showGroupMembers = false;
    this.showStatusTag = false;
    this.isShowSuggestions = false;
    this.content = '';
    this.selectedTheme = null;
    this.previewData = null;
    this.removedPreviewLinks = [];
    this.loadingUrl = false;
    this.isEditting = false;
    this.checkInObject = undefined;
    this.shareType = undefined;
    this.captureImg = '';
  }

  openDialog() {
    this.visible = true;
    this.loadThemes();
    if (this.groupId) {
      this.store.dispatch(loadMemberGroup({ groupId: this.groupId }));
    }
  }

  closeDialog() {
    this.isEditPost = false;
    this.isEditting = false;
    this.visible = false;
  }

  // Event handler for option change
  onChangePriority(value: any) {
    this.selectedPrivacy = value;
  }

  // Toggle the display of the upload section
  toggleUploadSection() {
    this.showUploadSection = !this.showUploadSection;
  }

  // Toggle the display of the choose layout section
  toggleChooseLayout() {
    this.showChooseLayout = !this.showChooseLayout;
  }

  // Form submission handler
  async onSubmit() {
    this.submitted = true;
    const listTagUser = this.selectionFriendTag.map(element => ({
      user_id: element.id || element.user_id,
      status: element?.allow_tag_moderation ? VERIFICATION_STATUS.pending : VERIFICATION_STATUS.approved
    }));
    this.friend_tags = listTagUser;

    const { selectedStatus, checkInObject, selectedPrivacy } = this;

    this.privacyPost.emit(selectedPrivacy);
    if (!this.isLargeVideoFileSize) this.callProgress.emit(ProgressBar.value_10);

    const hasValidInput =
      this.content !== '' ||
      this.shareType ||
      this.selectedFiles.length ||
      this.friend_tags.length ||
      selectedStatus ||
      this.captureImg ||
      checkInObject;

    if (hasValidInput) {
      this.loading = true;
      this.postSubmitted.emit();
      const formData = new FormData();

      // Append all selected files to formData
      for (const { file } of this.selectedFiles) {
        formData.append('files', file);

        if (!this.resumable && this.isLargeVideoFileSize) {
          this.initResumable(file);
        }

        if (this.resumable?.support) {
          this.resumable.addFile(file);
        }
      }

      if (this.captureImg) {
        const options = {
          canvasWidth: this.captureContainer.nativeElement.offsetWidth * 5,
          canvasHeight: this.captureContainer.nativeElement.offsetHeight * 5
        };
        const dataUrl = await htmlToImage.toPng(this.captureContainer.nativeElement, options);
        const file = this.convertToFile(dataUrl);
        formData.append('files', file);
      }
      let content = this.textArea?.$textarea?.nativeElement?.value;
      const handleUpload =
        (prevSelectedFiles: any[]) =>
        ({ data }: any) => {
          this.handleSuccessfulUpload(
            data,
            prevSelectedFiles,
            selectedStatus,
            this.selectedPrivacy,
            checkInObject,
            content
          );
          this.showProgress = false;
          this.loading = false;
          if (!this.isLargeVideoFileSize) this.callProgress.emit(ProgressBar.value_100);
        };

      const handleError = () => {
        this.messageService.add({
          severity: 'error',
          summary: 'File Upload Failed',
          detail: 'An error occurred while uploading files.'
        });
        this.showProgress = false;
        this.loading = false;
      };
      if (this.selectedFiles.length > 0 || this.captureImg) {
        const prevSelectedFiles = [...this.selectedFiles];
        if (this.isEditPost || !this.currentPage) {
          this.mediaService.uploadFile(formData).subscribe({
            next: handleUpload(prevSelectedFiles),
            error: handleError
          });
        } else {
          this.handleUploadAndCreatePost(
            formData,
            prevSelectedFiles,
            selectedStatus,
            this.selectedPrivacy,
            checkInObject,
            content,
            this.isImmediatelyShownPost,
            this.selectedFiles
          );
        }
      } else {
        // No files to upload, proceed without uploading
        this.handleSuccessfulUpload([], [], selectedStatus, this.selectedPrivacy, checkInObject, content);
      }
    }
    this.closeDialog();
  }

  handleUploadAndCreatePost(
    formData: FormData,
    prevSelectedFiles: FileWithPreview[],
    status?: string,
    privacy?: string,
    checkInObject?: CheckInAPI.CheckInRequest,
    content?: string,
    isImmediatelyShownPost = true,
    selectedFiles: FileWithPreview[] = []
  ) {
    const post = {
      content: content,
      posted_date: new Date(),
      post_type: POST_TYPE.user,
      post_privacy: privacy,
      friend_tags: this.friend_tags.length ? this.friend_tags : this.selectionGroupTag,
      media_urls: {
        layout_type: this.activeLayout,
        media: [...prevSelectedFiles.filter(item => !item.file)]
      },
      check_in: checkInObject
        ? {
            object_id: checkInObject.object_id,
            object_type: checkInObject.object_type
          }
        : null,
      message_ranges: this.messageRanges,
      post_feeling: this.selectedStatus || status,
      theme_id: this.selectedTheme?.id,
      preview_data: this.previewData
    };

    this.isFormDisabled = true;

    // Reset form and related variables
    this.resetDataInForm();

    switch (this.currentPage) {
      case CURRENT_PAGE.GROUP:
        this.store.dispatch(
          CreatePostActions.onUploadPost({
            currentPage: this.currentPage,
            dataCreate: {
              ...post,
              post_privacy: this.groupPrivacy,
              group_id: this.groupId
            },
            formData,
            isLargeFileSize: this.isLargeVideoFileSize,
            isPostShare: false,
            isImmediatelyShownPost,
            localLink: selectedFiles
          })
        );
        break;
      case CURRENT_PAGE.FANPAGE:
        this.store.dispatch(
          CreatePostActions.onUploadPost({
            currentPage: this.currentPage,
            dataCreate: {
              ...post,
              post_privacy: PRIVACY.public,
              page_id: this.fanpageInfo?.page_id
            },
            formData,
            isLargeFileSize: this.isLargeVideoFileSize,
            isPostShare: false,
            isImmediatelyShownPost,
            localLink: selectedFiles
          })
        );
        break;

      default:
        this.store.dispatch(
          CreatePostActions.onUploadPost({
            currentPage: this.currentPage,
            dataCreate: post,
            formData,
            isPostShare: false,
            isLargeFileSize: this.isLargeVideoFileSize,
            isImmediatelyShownPost,
            localLink: selectedFiles
          })
        );
        break;
    }
  }

  handleSuccessfulUpload = (
    data: any[],
    prevSelectedFiles: FileWithPreview[],
    status?: string,
    privacy?: string,
    checkInObject?: CheckInAPI.CheckInRequest,
    content?: string
  ) => {
    const mappedMedia = (data || []).map(file => ({
      type: file.type.startsWith('image/') ? this.image : this.video,
      thumbnail: file.id,
      original: file.id,
      signature: file.signature
    }));

    if (!this.isLargeVideoFileSize) this.callProgress.emit(ProgressBar.value_70);
    this.showProgress = false;

    const post = {
      content: content?.trim(),
      posted_date: new Date(),
      post_type: POST_TYPE.user,
      post_privacy: privacy,
      friend_tags: this.friend_tags.length ? this.friend_tags : this.selectionGroupTag,
      media_urls: {
        layout_type: this.activeLayout,
        media: [...prevSelectedFiles.filter(item => !item.file), ...mappedMedia]
      },
      check_in: checkInObject
        ? {
            object_id: checkInObject.object_id,
            object_type: checkInObject.object_type
          }
        : null,
      message_ranges: this.messageRanges,
      post_feeling: this.selectedStatus || status,
      theme_id: content?.trim() === '' ? '' : this.selectedTheme?.id,
      livestream_info: this.livestream_info || null,
      page_id: this.fanpageInfo?.page_id,
      group_id: this.groupId,
      preview_data: this.previewData,
      poll_id: this.pollId
    };

    this.loading = false;
    this.isFormDisabled = true;
    this.postData.emit(post);

    // Reset form and related variables
    this.resetDataInForm();
    this.store.dispatch(CreatePostApiActions.onResetDataCreatePost());
  };

  // Open the file input dialog
  openFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  // Handle the upload button click
  handleUploadButtonClick(): void {
    const fileInput = document.getElementById('file_upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  onDragOver(event: any) {
    event.preventDefault();
    event.dataTransfer!.dropEffect = 'copy'; // Show a "copy" cursor when dragging over the area
  }

  onDrop(event: any) {
    event.preventDefault();
    this.handleFileUpload(event);
  }

  onDragStart(index: number) {
    this.draggedItem = this.selectedFiles[index];
    this.draggedIndex = index;
  }

  onDropOver(event: DragEvent, dropIndex: number) {
    event.preventDefault();
    if (this.draggedItem && this.draggedIndex !== -1) {
      this.selectedFiles = this.changePositionInBlock(this.selectedFiles, this.draggedIndex, dropIndex);

      this.draggedItem = null;
      this.draggedIndex = -1;
    }
  }

  private changePositionInBlock(list: any[], from: number, to: number): any[] {
    if (from !== to) {
      const temp = list[to];
      list[to] = list[from];
      list[from] = temp;
    }
    return [...list];
  }

  handleMaxSizeInBytesVideo(): number {
    if (this.privilegeService.hasLargeFileUploadPrivilege(this.fanpageInfo?.privileges))
      return this.privilegeService.getPrivilegedUploadLimit(this.fanpageInfo?.privileges);
    else if (this.privilegeService.hasLargeFileUploadPrivilege(this.groupInfo?.privileges))
      return this.privilegeService.getPrivilegedUploadLimit(this.groupInfo?.privileges);
    else return environment.FILE_SIZE_LIMITS.video;
  }

  // Inject the MessageService into the component constructor
  handleFileUpload(event: any) {
    this.disabledTheme();
    const files = event.target.files || event.dataTransfer.files;

    if (this.selectedFiles.length + files.length > 20) {
      this.showMaxItemsError();
      return;
    }

    const maxSizeInBytesImage = environment.FILE_SIZE_LIMITS.image;
    const maxSizeInBytesVideo = this.handleMaxSizeInBytesVideo();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.isLargeVideoFileSize = file.size > environment.FILE_SIZE_LIMITS.video;
      if (!this.isValidFileType(file)) {
        this.showInvalidFileTypeError();
        continue;
      }

      const maxSizeInBytes = file.type.startsWith('image/') ? maxSizeInBytesImage : maxSizeInBytesVideo;

      if (file.size >= maxSizeInBytes) {
        this.showFileTooLargeError();
        continue;
      }

      this.readAndProcessFile(file);
    }
    this.isEditting = true;
  }

  showMaxItemsError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Maximum items for post',
      detail: 'You can upload a maximum of 20 items.'
    });
  }

  isValidFileType(file: File) {
    const allowedTypes = AllowedTypes;
    if (!allowedTypes.includes(file.type)) {
      return false;
    }
    return true;
  }

  showInvalidFileTypeError() {
    this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'COMMON.ALLOW_IMG_VIDEO');
  }

  showFileTooLargeError() {
    this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'COMMON.FILE_MAXIMUM');
  }

  readAndProcessFile(file: File) {
    const url = URL.createObjectURL(file);

    this.selectedFiles = [
      ...this.selectedFiles,
      {
        file: file,
        thumbnail: '0x',
        original: url,
        upload: true,
        type: file.type.startsWith('image/') ? this.image : this.video
      }
    ];
  }

  initResumable(file: File) {
    const resumableIdentifier = this.privilegeService.generateResumableIdentifier(file, this.userInfo);
    const fileName = this.privilegeService.generateUpdatedFilename(file.name, this.userInfo.id, 'vn');

    this.resumable = new Resumable({
      target: `${environment.baseURL}/storage-v2/files/upload`,
      chunkSize: 20 * 1024 * 1024,
      simultaneousUploads: 3,
      query: {
        resumableIdentifier,
        resumableFilename: fileName,
        resumableRelativePath: fileName
      }
    });

    this.resumable.on('fileAdded', () => {
      this.resumable?.upload();
    });

    this.resumable.on('fileProgress', (file: any) => {
      const percentage = Math.floor(file.progress() * 100);
      if (percentage > 0) {
        this.store.dispatch(
          PostDialogActions.onUploadFile({ fileUpload: { showProgress: true, progress: percentage } })
        );
      }
    });

    this.resumable.on('fileSuccess', (_: File, response: string) => {
      const responseData = JSON.parse(response);
      this.store.dispatch(CreatePostActions.onHandleUploadedLargeFileSizeSuccessfully({ data: responseData }));
      this.store.dispatch(PostDialogActions.onUploadFile({ fileUpload: { showProgress: false, progress: 0 } }));
    });

    this.resumable.on('fileError', (file, message) => {
      this.store.dispatch(PostDialogActions.onUploadFile({ fileUpload: { showProgress: false, progress: 0 } }));
      console.error(`Error uploading ${file.fileName}:`, message);
    });
  }

  // Delete an image from the selected files
  deleteImage(index: number) {
    this.selectedFiles = this.selectedFiles.filter((_, i) => i !== index);
    this.isEditting = true;
  }

  // Go back to select 'Share to group' Popup
  goBack() {
    this.visibleChange.emit(false);
    this.goBackToGroupDialog.emit();
  }

  editGroupToShare() {
    this.goBackToGroupDialog.emit();
  }

  getAvatar(image_url: string): string {
    return this.commonService.getImageUrl(image_url);
  }

  customUrl(item: FileWithPreview) {
    if (item.original && item.upload) {
      return item.original;
    }
    return item.type.startsWith(MEDIA_TYPE.image)
      ? checkUrl(item.original, FILE_TYPE_URL.thumbnail) + FILE_EXTENSION.image
      : checkUrl(item.original, FILE_TYPE_URL.streamVideo) + FILE_EXTENSION.video;
  }

  convertToFile(base64String: string) {
    let binaryString = window.atob(base64String.split(',')[1]);
    let mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
    let length = binaryString.length;
    let arrayBuffer = new ArrayBuffer(length);
    let uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], `share_profile_${this.userInfo.full_name}.png`, { type: mimeString });
  }

  moveCursorToEnd(el: HTMLElement): void {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }

  getCaretPosition(element: Element): number {
    let caretPos = 0;
    let sel, range;

    if (window.getSelection) {
      sel = window.getSelection();
      if (sel && sel.rangeCount) {
        range = sel.getRangeAt(0);
        let preCaretRange = document.createRange();
        preCaretRange.setStart(element, 0);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        let content = preCaretRange.cloneContents();
        let tempDiv = document.createElement('div');
        tempDiv.appendChild(content);
        caretPos = tempDiv.innerHTML.length;
      }
    }
    return caretPos;
  }

  countNumOfLine(): number {
    const editableDiv = this.textareaEl.nativeElement;
    const lineHeight = getComputedStyle(editableDiv).lineHeight;
    const height = editableDiv.clientHeight;
    const lineCount = Math.round(height / parseFloat(lineHeight));
    return lineCount;
  }

  onChangeMessageRanges(items: any) {
    this.messageRanges = items;
  }

  triggerPostContent() {
    this.isFormDisabled = !this.textareaEl.nativeElement.innerHTML.length;
    this.caretPosition = this.getCaretPosition(this.textareaEl.nativeElement);
  }

  handleGenerateTitleDialog(): string {
    if (this.isPendingCheckIn) {
      return 'POST.STATUS.CHECKIN_TITLE';
    }
    if (this.shareType) {
      return 'POST.SHARE_POST';
    }
    if (this.sharePostType.length) {
      return this.sharePostType === CREATE_POST_TYPES.shareAlbum.id
        ? CREATE_POST_TYPES.shareAlbum.title
        : CREATE_POST_TYPES.createPost.title;
    }
    return this.isEditPost ? CREATE_POST_TYPES.editPost.title : CREATE_POST_TYPES.createPost.title;
  }

  setPrivacyBasedOnGroupInfo(): void {
    if (this.groupInfo) {
      this.privacy =
        this.groupInfo.privacy.toLocaleUpperCase() === PRIVACY.private
          ? this.privacyOptions[1]
          : this.privacyOptions[0];
    }
  }

  setPrivacyEditPost(): void {
    if (this.isEditPost) {
      if (this.selectedPrivacy.toLocaleUpperCase() === PRIVACY.private) {
        this.privacy = this.privacyOptions[1];
      } else if (this.selectedPrivacy.toLocaleUpperCase() === PRIVACY.public) {
        this.privacy = this.privacyOptions[0];
      } else {
        this.privacy = this.privacyOptions[2];
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  handlePrivacyGroup() {
    if (typeof this.groupPrivacy === 'string' && this.groupPrivacy.toLocaleUpperCase() === PRIVACY.private) {
      this.privacy = this.privacyOptions[1];
    } else {
      this.privacy = this.privacyOptions[0];
    }
  }

  deleteMedia() {
    this.selectedFiles = [];
    this.isEditting = true;
  }

  selectedStatusChange(event: any) {
    this.selectedStatus = event.selectedStatus;
    this.isEditting = event.isEditting;
    this.toggleStatusTags(false);
  }

  selectedLocationChange(event: any) {
    this.checkInObject = event?.selectedStatus;
    this.isEditting = event.isEditting;
    this.isPendingCheckIn = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: any): void {
    if (
      this.hasNewPostData ||
      this.shareType ||
      (!this.isEditPost && this.content) ||
      (this.isEditPost && this.isEditting) ||
      this.captureImg ||
      (this.textArea?.$textarea?.nativeElement?.value &&
        this.initialTextValue !== this.textArea?.$textarea?.nativeElement?.value) ||
      (!this.isEditPost && this.selectedFiles.length > 0)
    ) {
      event.returnValue = true;
    }
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  translate(status: string): string {
    return this.translationService.getTranslation(`POST.STATUS.${status.toUpperCase()}`);
  }

  applyThemeSelection(theme: any, themeSelect: any) {
    if (theme?.theme_type === 'IMAGE') {
      themeSelect.style.backgroundImage = `url("${this.apiUrl}/storage/files/web/${theme?.background_thumbnail}.webp")`;
      return `bg-cover bg-[#${theme?.background_color[1]}]`;
    } else if (theme?.theme_type === 'COLOR') {
      return theme.background_thumbnail;
    }
  }

  onBlurFocusInputTag(value: boolean, inputValue: string): void {
    setTimeout(() => {
      if (value) {
        this.onSearchKeyUp(inputValue);
      } else {
        this.isShowSuggestions = false;
      }
    }, 200);
  }

  confirm(value: string) {
    switch (value) {
      case this.confirmCloseActions.discard:
        this.resetDataInForm();
        this.store.dispatch(CreatePostApiActions.onSetStatusPost({ isCreatingPost: this.hasNewPostData }));
        break;
      case this.confirmCloseActions.saveAsDraft:
        this.store.dispatch(CreatePostApiActions.onSetStatusPost({ isCreatingPost: this.hasNewPostData }));
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.success, 'POST.POST_SAVED_AS_DRAFT');
        break;
      case this.confirmCloseActions.keepEditing:
        this.visible = true;
        this.visibleChange.emit(true);
        break;
      default:
        this.store.dispatch(CreatePostApiActions.onSetStatusPost({ isCreatingPost: this.hasNewPostData }));
        break;
    }
    this.visibleCloseConfirm = false;
  }

  loadMoreMentionFriends(): void {
    if (
      ((this.searchKey.length && !this.isLastPage) || (!this.searchKey.length && !this.isLastPageAllFriends)) &&
      !this.isLoadingMentionList &&
      !this.isLoadingMoreMentionList
    ) {
      this.isLoadingMoreMentionList = true;
      const isMentionEnabled = false;
      const isTagEnabled = true;
      this.friendService
        .getOrSearchFriendV2(
          this.searchKey,
          PAGE_SIZE_DEFAULT,
          (this.searchKey.length ? this.pageNum : this.pageNumAllFriends) + 1,
          isMentionEnabled,
          isTagEnabled
        )
        .subscribe((res: any) => {
          if (res && res.success && res.data && res.data.data) {
            this.mentionList = difference([...this.mentionList, ...res.data.data], this.selectionFriendTag);
            if (this.searchKey.length) {
              this.isLastPage = PAGE_SIZE_DEFAULT * (res.data.page + 1) > res.data.totalElement;
              this.pageNum++;
            } else {
              this.pageNumAllFriends++;
              this.isLastPageAllFriends = PAGE_SIZE_DEFAULT * (this.pageNumAllFriends + 1) > res.data.totalElement;
              this.allFriendList = difference([...this.allFriendList, ...res.data.data], this.selectionFriendTag);
            }
          }
          this.isLoadingMoreMentionList = false;
        });
    }
  }

  searchMention(data: { event: any; searchKey: string }): void {
    this.mentionList = [];
    this.searchKey = data.searchKey;
    this.pageNum = PAGE_NUM_DEFAULT;
    this.isLoadingMentionList = true;
    this.handleMentionOverlayPosition(data.searchKey);
    this.showMentionOverlay = true;
    this.tagOverlayPanel.show(data.event, this.inputTagFriend.nativeElement);
    if (!data.searchKey.length && this.allFriendList.length) {
      this.isLoadingMentionList = false;
      this.mentionList = difference(this.allFriendList, this.selectionFriendTag);
      this.highlightedIndex = 0;
      this.pageNum = this.pageNumAllFriends;
    } else {
      const isMentionEnabled = false;
      const isTagEnabled = true;
      this.friendService
        .getOrSearchFriendV2(data.searchKey, PAGE_SIZE_DEFAULT, PAGE_NUM_DEFAULT, isMentionEnabled, isTagEnabled)
        .subscribe({
          next: (res: any) => {
            if (res && res.success && res.data) {
              this.mentionList = difference(res.data.data, this.selectionFriendTag);
              this.highlightedIndex = 0;
              this.isLastPage = PAGE_SIZE_DEFAULT * (res.data.page + 1) > res.data.totalElement;

              if (!data.searchKey.length) {
                this.pageNumAllFriends = PAGE_NUM_DEFAULT;
                this.allFriendList = difference(res.data.data, this.selectionFriendTag);
                this.isLastPageAllFriends = PAGE_SIZE_DEFAULT * (res.data.page + 1) >= res.data.totalElement;
              }
              if (!this.mentionList.length) {
                this.showMentionOverlay = false;
                this.tagOverlayPanel.hide();
              }
            }
            setTimeout(() => {
              this.isLoadingMentionList = false;
            }, 300);
          },
          error: () => {
            this.showMentionOverlay = false;
            this.tagOverlayPanel.hide();
            this.isLoadingMentionList = false;
          }
        });
    }
  }

  handleMentionOverlayPosition(searchText: string): void {
    const textarea = this.inputTagFriend.nativeElement;
    const contentBeforeMention = textarea.value.slice(0, textarea.selectionStart - searchText.length - 1);
    if (
      this.overlayPosition.contentBeforeMention === undefined ||
      this.overlayPosition.contentBeforeMention !== contentBeforeMention
    ) {
      const textareaPadding = 10;
      const textareaMaxHeight = 132;
      const span = document.createElement('span');
      span.textContent = contentBeforeMention;
      span.style.whiteSpace = 'pre-wrap';
      const spanMention = document.createElement('span');
      spanMention.textContent = '@';
      const div = document.createElement('div');
      div.appendChild(span);
      span.appendChild(spanMention);
      const style = getComputedStyle(textarea);
      Object.assign(div.style, {
        ...Array.from(style).reduce((acc, key) => ({ ...acc, [key]: style.getPropertyValue(key) }), {}),
        position: 'absolute',
        visibility: 'hidden',
        whiteSpace: 'pre-wrap',
        width: `${textarea.clientWidth}px`,
        height: `${textarea.clientHeight}px`,
        overflow: 'hidden'
      });
      document.body.appendChild(div);
      const spanRect = span.getBoundingClientRect();
      const spanMentionRect = spanMention.getBoundingClientRect();
      const top = Math.min(spanRect.height - textarea.scrollTop + textareaPadding, textareaMaxHeight - textareaPadding);
      const left =
        window.innerWidth > BREAKPOINTS.md ? spanMentionRect.left - textarea.scrollLeft + textareaPadding : 0;
      this.overlayPosition = { top, left, contentBeforeMention };
      document.body.removeChild(div);
    }
  }

  onSelectTagFriend(friend: FriendList): void {
    this.selectionFriendTag = [...this.selectionFriendTag, friend];
    this.mentionList = this.mentionList.filter(obj => obj.id !== friend.id);
    this.isShowSuggestions = false;
    this.inputTagFriend.nativeElement.value = '';
    this.isEditting = true;
    this.showMentionOverlay = false;
    this.tagOverlayPanel.hide();
  }

  handleMention(event: KeyboardEvent): void {
    const cursorPosition = this.inputTagFriend.nativeElement.selectionStart;
    const textBeforeCursor = this.inputTagFriend.nativeElement.value.slice(0, cursorPosition);
    const mentionMatches = [...textBeforeCursor.matchAll(/@[^@]*$\S*(?: \S*){0,2}$/gu)];
    const mentionTrigger = mentionMatches.length > 0 ? mentionMatches[mentionMatches.length - 1] : null;
    const whiteSpaceCount = mentionTrigger ? (mentionTrigger[0].match(/ /g) || []).length : 0;
    const isExistedMention = this.mentionedList.find(
      item => mentionTrigger && item.fullName === mentionTrigger[0].substring(1)
    );
    if (mentionTrigger && whiteSpaceCount < 2 && !isExistedMention) {
      this.mentionStartPosition = cursorPosition;
      this.searchMentionSubject.next({ event, searchKey: mentionTrigger[0].substring(1) });
    } else {
      this.showMentionOverlay = false;
      this.tagOverlayPanel.hide();
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (
      this.showMentionOverlay &&
      [KEYBOARD_EVENT_CODES.ArrowUp, KEYBOARD_EVENT_CODES.ArrowDown, KEYBOARD_EVENT_CODES.Enter].includes(event.key)
    ) {
      event.preventDefault();
      switch (event.key) {
        case KEYBOARD_EVENT_CODES.ArrowDown:
          this.highlightedIndex = (this.highlightedIndex + 1) % this.mentionList.length;
          this.scrollIntoHighlightedMention(this.highlightedIndex);
          break;
        case KEYBOARD_EVENT_CODES.ArrowUp:
          this.highlightedIndex = (this.highlightedIndex - 1 + this.mentionList.length) % this.mentionList.length;
          this.scrollIntoHighlightedMention(this.highlightedIndex);
          break;
        case KEYBOARD_EVENT_CODES.Enter:
          this.onSelectTagFriend(this.mentionList[this.highlightedIndex]);
          break;
        default:
          break;
      }
    }
  }
  scrollIntoHighlightedMention(index: number): void {
    if (!this.tagListContainer || !this.tagListContainer.nativeElement) return;
    const element = this.tagListContainer.nativeElement.querySelector(`#tag-item-${index}`);
    if (!element) return;

    const elementRect = element.getBoundingClientRect();
    const containerRect = this.tagListContainer.nativeElement.getBoundingClientRect();
    if (elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom) {
      element.scrollIntoView({ behavior: 'smooth', block: elementRect.top < containerRect.top ? 'start' : 'end' });
    }
  }
}
