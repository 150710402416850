import { Directive, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { CustomVideoService } from './custom-video.service';

@Directive({
  selector: '[custom-video-directive]'
})
export class CustomVideoDirective implements OnInit {
  @Input() isPreview: boolean;
  @Input() item: any;
  @Input() isCover: boolean;
  @Input() isAutoPlay: boolean;
  @Input() isPostView = false;
  @Input() isShowScaleIcon = false;
  @Output() isClickedPlay: EventEmitter<any> = new EventEmitter<any>();
  @Output() scaleVideo: EventEmitter<any> = new EventEmitter<any>();
  @Output() collectVideo: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public viewContainerRef: ViewContainerRef,
    private customVideoService: CustomVideoService
  ) {}

  ngOnInit(): void {
    this.initVideo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['isPreview']) {
      this.initVideo();
      this.customVideoService.updateProperties(this.item.original, 'isPostView', this.isPostView);
    }
  }

  initVideo() {
    this.customVideoService.attach(this.viewContainerRef, {
      isPreview: this.isPreview,
      item: this.item,
      isCover: this.isCover,
      isClickedPlay: this.isClickedPlay,
      isAutoPlay: this.isAutoPlay,
      isPostView: this.isPostView,
      isShowScaleIcon: this.isShowScaleIcon,
      collectVideo: this.collectVideo,
      scaleVideo: this.scaleVideo
    });
  }
}
