import { Component, Input } from '@angular/core';
import { LocationData } from '../../statistics.models';

@Component({
  selector: 'location-chart',
  templateUrl: './location-chart.component.html',
  styleUrls: ['./location-chart.component.scss']
})
export class LocationChartComponent {
  @Input() locationData: LocationData[] = [];
  @Input() isLoading = false;

  isViewFullList = false;
  maxDisplayedItems = 4;

  getPercent(user_count: number, fixedDecimalPlace = 1): number {
    const totalUsers = this.locationData.reduce((accumulator, currentValue) => accumulator + currentValue.userCount, 0);
    return Number(((user_count / totalUsers) * 100).toFixed(fixedDecimalPlace));
  }
}
