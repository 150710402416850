<div *ngIf="loading; else showPreview" class="w-full">
  <loading-skeleton rounded></loading-skeleton>
</div>

<ng-template #showPreview>
  <div *ngIf="data && data.link" class="w-full relative">
    <div
      *ngIf="isCreatingPost"
      (click)="onClickRemovePreview(data.link)"
      class="absolute z-10 top-2 right-2 p-1 rounded-full flex items-center justify-center bg-palette-gray-900/10 hover:bg-palette-gray-900/30 transition-colors cursor-pointer group">
      <i
        class="sctr-icon-x-close text-xl/5 text-palette-gray-500 group-hover:text-palette-base-white transition-colors"></i>
    </div>
    <a class="w-full relative" [href]="data.link" target="_blank">
      <div
        class="w-full overflow-clip shadow-sm bg-palette-gray-50 cursor-pointer"
        [ngClass]="{ 'rounded-lg': isCreatingPost || commentUI }">
        <div
          class="items-center {{
            data.image
              ? isVerticalImg || commentUI
                ? 'w-full flex flex-row '
                : 'w-full flex flex-col justify-center'
              : ''
          }}">
          <div
            *ngIf="data.image"
            class="relative {{
              isVerticalImg && !commentUI
                ? 'w-2/5 min-w-[40%] flex items-center'
                : commentUI
                ? 'w-24 md:w-32 min-w-fit max-w-[40%]'
                : 'w-full'
            }}">
            <div *ngIf="!isImageLoaded" class="w-full h-full absolute top-0 right-0 bg-palette-gray-50">
              <p-skeleton styleClass="w-full h-full"></p-skeleton>
            </div>
            <img
              defaultImage
              [src]="getThumbnailURL(data.image)"
              alt=""
              (error)="isImageLoaded = true; isImageFailed = true"
              (load)="isImageLoaded = true"
              class="w-full {{
                commentUI
                  ? 'h-24 md:h-32 max-h-24 md:max-h-32 min-w-[96px] md:min-w-[128px]'
                  : 'max-h-72 md:max-h-[480px]'
              }} border border-palette-gray-100 object-cover overflow-clip flex items-center justify-center {{
                isVerticalImg || commentUI ? 'aspect-auto' : 'aspect-video'
              }}"
              [ngClass]="{ 'rounded-lg': commentUI }" />
          </div>
          <div
            class="flex flex-col gap-1 text-palette-gray-700 text-base {{
              data.image ? (commentUI ? 'p-2 grow' : isVerticalImg ? 'p-4 grow' : 'px-4 py-2 w-full') : 'w-full'
            }}">
            <span
              *ngIf="domainLink"
              class="text-sm md:text-base text-palette-gray-500 break-all line-clamp-1 md:pb-1 font-semibold">
              {{ domainLink }}
            </span>
            <div
              *ngIf="data.title"
              class="font-semibold text-sm md:text-base cursor-pointer overflowWrap {{
                data.image && (isVerticalImg || commentUI) ? 'line-clamp-2' : 'line-clamp-1'
              }}">
              {{ data.title }}
            </div>
            <div
              *ngIf="data.description && !commentUI"
              class="text-xs md:text-sm overflowWrap {{
                data.image && isVerticalImg ? 'line-clamp-2 md:line-clamp-3' : 'line-clamp-2'
              }}">
              {{ data.description }}
            </div>
            <span
              class="place-content-end text-xs md:text-sm text-palette-blue-600 break-all {{
                data.image && isVerticalImg && !commentUI ? 'line-clamp-1 md:line-clamp-2' : 'line-clamp-1'
              }}">
              {{ data.link }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</ng-template>
