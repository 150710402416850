import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private api: ApiClient;
  constructor(private httpClient: HttpClient) {
    this.api = new ApiClient(this.httpClient, { responseTimeout: environment.API_TIMEOUT });
  }

  getReportCategories() {
    return this.api.report.getReportCategories();
  }

  createReportPost(param: any) {
    return this.api.report.createReportPost(param);
  }
}
