<div class="flex h-full overflow-hidden" *ngIf="isViewArchive; else Stories">
  <div class="w-3/4 max-md:w-full">
    <ng-container [ngTemplateOutlet]="Stories"></ng-container>
  </div>
  <div class="h-full w-1/4 max-md:hidden bg-palette-base-white pr-4 min-w-[350px]">
    <dialog-viewers
      *ngIf="currentStories.length"
      [visibleViewers]="true"
      [storiesByUser]="currentStories"
      [transferContent]="transferContent"
      [comments]="comments"
      [myStory]="true"
      [(totalCommentsAndReplies)]="totalCommentsAndReplies"
      [activeIndex]="indexTabDialogViewers"
      (toggleImageSelectionEvent)="toggleImageSelection($event)"
      [isViewArchive]="isViewArchive"
      (onHideEvent)="showHideDialogViewers($event, false)"></dialog-viewers>
  </div>
</div>

<ng-template #Stories>
  <div class="bg-palette-gray-900 items-center overflow-hidden grid grid-cols-10 h-full">
    <!-- Previous story -->
    <div class="col-span-3 max-[1350px]:hidden" *ngIf="currentStories.length > 0">
      <div
        *ngIf="transferStory - 1 >= 0 && !isViewArchive"
        class="flex justify-end cursor-pointer"
        (click)="onTransferStoryUser(actionType.previous)">
        <side-stories [dataStory]="allStories[transferStory - 1]"></side-stories>
      </div>
    </div>
    <div class="col-span-4 z-[1] max-[1350px]:col-span-10" *ngIf="currentStories.length > 0 && allStories.length > 0">
      <div class="flex items-center justify-center gap-8 max-[708px]:gap-2">
        <div class="min-w-[25px]">
          <i
            *ngIf="transferStory - 1 >= 0 || transferContent > 0"
            class="sctr-icon-chevron-left text-2xl cursor-pointer text-palette-base-white"
            (click)="onTransferStory(actionType.previous)"></i>
        </div>
        <!-- Current Stories -->
        <div
          class="transition duration-150 ease-in-out relative w-[50vh] min-w-[375px] max-w-[550px] max-[708px]:min-w-[310px]">
          <div class="flex items-center justify-between truncate">
            <div class="flex items-center gap-4 truncate pl-1">
              <avatar [isSmallThumbnail]="true" [userInfo]="allStories[transferStory].user_object"></avatar>
              <div class="truncate">
                <div
                  class="text-palette-gray-300 flex items-center gap-1 max-w-[95%] cursor-pointer"
                  [routerLink]="navigateToProfile(allStories[transferStory].user_object)">
                  <span class="truncate">{{ allStories[transferStory].user_object.full_name }}</span>
                  <div
                    class="flex justify-center items-center relative w-[18px] h-[18px]"
                    *ngIf="
                      allStories[transferStory].user_object && allStories[transferStory].user_object.is_verified_user
                    ">
                    <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-lg/[18px]"></i>
                    <i class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-base/[16px]"></i>
                  </div>
                </div>
                <div class="flex items-center justify-start">
                  <div class="text-palette-gray-300 text-base mr-1.5">
                    {{ getFromNow(getStory(this.transferContent)?.created_at || '') }} •
                  </div>
                  <i class="{{ storiesPrivacy }} mt-0.5 text-palette-base-white"></i>
                </div>
              </div>
            </div>
            <div class="flex gap-3">
              <i
                [ngClass]="{ 'sctr-icon-play-circle': !isAnimating, 'sctr-icon-pause-circle': isAnimating }"
                class="text-xl cursor-pointer text-palette-base-white"
                (click)="onClickPlayPauseBtn(!configVideo.isPlay)"></i>
              <i
                *ngIf="
                  getStory()?.story_type === mediaType.video ||
                  (getStory()?.story_media?.video_url && getStory()?.story_type === mediaType.image)
                "
                [ngClass]="{
                  'sctr-icon-solid-volume-max': !configVideo.isMuted,
                  'sctr-icon-solid-volume-x': configVideo.isMuted
                }"
                class="text-xl cursor-pointer text-palette-base-white"
                (click)="configVideo.isMuted = !configVideo.isMuted"></i>
              <div>
                <i
                  class="sctr-icon-dots-horizontal text-xl cursor-pointer text-palette-base-white"
                  (click)="!isModerateStory ? showHideStoryOption(!this.overlayVisible) : null"></i>
                <ng-container *ngTemplateOutlet="options"></ng-container>
              </div>
              <i
                *ngIf="!isModerateStory"
                class="sctr-icon-x-close text-xl cursor-pointer text-palette-base-white"
                (click)="goBack()"></i>
            </div>
          </div>
          <div class="w-full flex gap-0.5">
            <div
              *ngFor="let i of currentStories; let index = index"
              [style.width]="'calc(100% / ' + currentStories.length + ')'">
              <div class="progress-bar my-3 bg-palette-gray-500 h-0.5 rounded-full">
                <span *ngIf="transferContent === index" [style.width]="progressValues + '%'"></span>
                <span *ngIf="transferContent > index" [style.width]="'100%'"></span>
              </div>
            </div>
          </div>
          <div
            class="relative overflow-hidden flex flex-col justify-center rounded-2xl bg-palette-gray-300 w-full"
            [ngStyle]="{
              'background-image': getStory()?.story_media?.background_image
                ? 'url(' + validUrl(getStory()?.story_media?.background_image || '', fileType.thumbnail) + ')'
                : 'none',
              'background-size': 'cover',
              'background-position': 'center',
              'background-color': !getStory()?.story_media?.background_image ? 'gray' : 'bg-transparent'
            }"
            [style.height]="'calc(95vh - 165px)'">
            <video
              #videoPlayer
              [autoplay]="configVideo.isAutoPlay"
              [muted]="configVideo.isMuted"
              *ngIf="getStory()?.story_type === mediaType.video"
              [src]="durationOfVideo ? validUrl(getStory()?.story_media?.video_url || '', fileType.streamVideo) : ''"
              class="h-full w-full"
              [ngClass]="{
                'object-contain': isLandscapeImg,
                'rounded-2xl': !isLandscapeImg
              }"></video>
            <video
              #videoPlayer
              [autoplay]="configVideo.isAutoPlay"
              [muted]="configVideo.isMuted"
              [loop]="true"
              *ngIf="getStory()?.story_media?.video_url && getStory()?.story_type === mediaType.image"
              [src]="durationOfVideo ? validUrl(getStory()?.story_media?.video_url || '', fileType.streamVideo) : ''"
              class="h-0 w-0"></video>
            <img
              *ngIf="
                getStory()?.story_type === mediaType.image ||
                (getStory()?.story_type === mediaType.video && getStory()?.story_media?.image_url)
              "
              [src]="validUrl(getStory()?.story_media?.image_url || '', fileType.thumbnail)"
              class="h-full w-full absolute"
              [ngClass]="{
                'object-contain': isLandscapeImg,
                'rounded-2xl': !isLandscapeImg
              }" />
            <div
              class="absolute max-h-40 left-0 cursor-pointer px-4 w-full"
              [ngClass]="{ 'bottom-14': selectedEmoji?.icon, 'bottom-3': !selectedEmoji?.icon }"
              (click)="showHideDialogViewers(1)"
              *ngIf="!isViewArchive">
              <div *ngFor="let item of comments; let i = index">
                <ng-container *ngIf="item.content !== null && i < 3">
                  <div class="bg-[#101828d0] p-1 flex gap-1.5 mt-3 w-fit rounded-full max-w-full">
                    <avatar [isDisableNavigate]="true" [avatarSize]="32" [userInfo]="item.created_by"></avatar>
                    <div class="ml-[6px] mr-2 truncate">
                      <div class="flex items-center gap-0.5">
                        <div class="text-palette-base-white text-[10px] truncate">{{ item.created_by.full_name }}</div>
                        <div
                          class="flex justify-center items-center relative w-3 h-3"
                          *ngIf="item.created_by && item.created_by.is_verified_user">
                          <i class="text-palette-base-white sctr-icon-solid-shield-01 absolute text-xs/[12px]"></i>
                          <i
                            class="text-branding-primary-600 sctr-icon-solid-shield-tick absolute text-[10px]/[10px]"></i>
                        </div>
                      </div>
                      <div class="text-palette-base-white text-[10px] truncate whitespace-pre-line">
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div
              *ngIf="selectedEmoji?.icon"
              class="absolute max-h-40 bottom-3 left-0 px-4 w-full flex gap-2 pointer-events-none">
              <img src="{{ selectedEmoji.icon }}" class="w-5 h-5 z-1" />
              <span class="text-palette-base-white mix-blend-difference text-xs self-center truncate">
                {{ 'STORY.REACTED_TITLE' | translate: { name: allStories[transferStory].user_object.full_name } }}
              </span>
            </div>
          </div>
          <div
            *ngIf="getStory()?.user_id === userInfo.id && !isViewArchive; else userStory"
            class="flex justify-between mt-3 ml-4">
            <div class="flex items-center gap-2 cursor-pointer" (click)="showHideDialogViewers(0)">
              <span class="text-palette-base-white text-lg font-medium"
                >{{ getStory()?.total_viewers || 0 }}
                {{
                  (getStory()?.total_viewers || 0) > 1
                    ? ('STORY.VIEWERS' | translate | lowercase)
                    : ('STORY.VIEWER' | translate | lowercase)
                }}</span
              >
              <i class="sctr-icon-chevron-up text-[28px] text-palette-base-white"></i>
            </div>
            <div
              class="h-12 w-12 justify-center items-center flex relative cursor-pointer"
              (click)="showHideDialogViewers(1)">
              <i class="sctr-icon-message-dots-square text-2xl text-palette-base-white"></i>
              <div
                class="w-3 h-3 text-center absolute bottom-0 right-0 text-[9px] rounded-full text-white bg-palette-blue-600">
                {{ totalCommentsAndReplies }}
              </div>
            </div>
          </div>
          <ng-template #userStory>
            <div class="min-[708px]:mx-4" *ngIf="!isViewArchive && !isModerateStory">
              <div class="relative">
                <icon-popup
                  (emojiChanged)="executedEmojiChanged($event)"
                  (closePopup)="showHideOverlayIcons($event)"
                  [isStoryPopup]="true"
                  [overlayVisible]="overlayIconVisible"></icon-popup>
              </div>
              <div class="flex items-center w-full mt-3 gap-4">
                <div class="flex items-center gap-2 relative">
                  <img
                    src="{{ selectedEmoji?.icon ? selectedEmoji.icon : 'assets/emoji/L - Icon - Heart.svg' }}"
                    class="w-12 h-12 z-10"
                    (mouseenter)="showHideOverlayIcons(true)"
                    (mouseleave)="showHideOverlayIcons(false)"
                    (click)="executedEmojiChanged(selectedEmoji ? 'VIEW' : emojiList[0].type)" />
                  <span
                    *ngIf="selectedEmoji"
                    class="text-palette-red-500 absolute -bottom-3 left-[44%] z-11 text-xl/none"
                    >.</span
                  >
                </div>
                <ng-container *ngFor="let icon of animateIcons">
                  <ng-container
                    [ngTemplateOutlet]="animateIcon"
                    [ngTemplateOutletContext]="{ animate: icon.animate, emojiIcon: icon.emojiIcon }">
                  </ng-container>
                </ng-container>
                <div class="flex-1">
                  <comment-form
                    [userStory]="true"
                    [isStory]="true"
                    [loginUser]="userInfo"
                    [color]="'text-palette-gray-300'"
                    (valueComment)="addComment($event)"
                    (focusEvent)="handleCommentForm($event)"></comment-form>
                </div>
              </div>
            </div>
          </ng-template>
          <dialog-viewers
            [userInfoId]="userInfo.id"
            *ngIf="currentStories.length > 0"
            [visibleViewers]="visibleViewers"
            [isModerateStory]="isModerateStory"
            [comment]="comment"
            [storiesByUser]="currentStories"
            [transferContent]="transferContent"
            [comments]="comments"
            [myStory]="isModerateStory ? false : getStory()?.user_id === userInfo.id"
            [(totalCommentsAndReplies)]="totalCommentsAndReplies"
            [activeIndex]="indexTabDialogViewers"
            (toggleImageSelectionEvent)="toggleImageSelection($event)"
            (onHideEvent)="showHideDialogViewers($event, false)"></dialog-viewers>
        </div>
        <div class="min-w-[25px]">
          <i
            *ngIf="
              transferStory + 1 <= allStories.length - 1 ||
              transferContent < currentStories.length - 1 ||
              (isViewArchive && totalStoriesArchived > this.allStories.length)
            "
            class="sctr-icon-chevron-right text-2xl cursor-pointer text-palette-base-white"
            (click)="onTransferStory(actionType.next)"></i>
        </div>
      </div>
    </div>

    <!-- Next Stories -->
    <div class="col-span-3 max-[1350px]:hidden" *ngIf="currentStories.length > 0">
      <div
        (click)="onTransferStoryUser(actionType.next)"
        *ngIf="transferStory + 1 <= allStories.length - 1 && !isViewArchive"
        class="flex justify-start cursor-pointer w-full">
        <side-stories [dataStory]="allStories[transferStory + 1]"></side-stories>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #options>
  <p-overlay
    [(visible)]="overlayVisible"
    [contentStyleClass]="'mt-3 shadow-2 border-round '"
    (onHide)="showHideStoryOption(false)"
    [responsive]="{ breakpoint: '640px', direction: 'bottom-end' }"
    [styleClass]="'z-10'">
    <p-listbox
      [options]="getStory()?.user_id === userInfo.id ? myStoryOptions : userStoryOptions"
      class="p-0"
      [styleClass]="'lg:w-[375px] rounded-lg border-[#EAECF0] border'"
      [listStyleClass]="'py-0.5'"
      (onClick)="onListItemClick($event)">
      <ng-template let-item pTemplate="item">
        <div class="flex w-full" [ngClass]="{ 'items-center': item.description }">
          <div class="flex gap-2 w-full min-w-[128px]" [ngClass]="{ 'items-center': !item.description }">
            <span
              class="{{ item.icon }} text-base text-palette-gray-700"
              [ngClass]="{ 'text-palette-red-500': item.type === StoryAction.delete }"></span>
            <div>
              <div
                class="text-sm text-palette-gray-700"
                [ngClass]="{ 'text-palette-red-500': item.type === StoryAction.delete }">
                {{ item.label | translate }}
              </div>
              <div class="text-xs text-palette-gray-500">
                {{ item.description | translate: { appName: this.appName } }}
              </div>
            </div>
          </div>
          <p-inputSwitch
            [styleClass]="'w-[40px] h-5'"
            *ngIf="getStory()?.user_id !== userInfo.id && item.type === 2"
            [(ngModel)]="checked"></p-inputSwitch>
        </div>
      </ng-template>
    </p-listbox>
  </p-overlay>
</ng-template>

<confirm-dialog
  [isRedBtn]="true"
  [widthClass]="'auto'"
  [(isShowDialog)]="isDeleteStory"
  [headerName]="'STORY.DELETE_STORY' | translate"
  [description]="'STORY.DELETE_STORY_MESSAGE' | translate"
  [rightBtnLabel]="'COMMON.BUTTON_ACTION.DELETE' | translate"
  [leftBtnLabel]="'COMMON.BUTTON_ACTION.CANCEL' | translate"
  (confirm)="confirmDeleteDialog($event)"></confirm-dialog>

<lib-report-popup
  [(isLoading)]="isLoadingReport"
  [reportTarget]="getReportTarget()"
  [reportVisibility]="isShowReport"
  [reportCategories]="reportCategories$ | async"
  (closeModal)="handleCloseModal($event)"
  (confirmReport)="handlePostReport($event)"></lib-report-popup>

<ng-template #animateIcon let-animate="animate" let-emojiIcon="emojiIcon">
  <div
    *ngIf="animate === randomAnimateType.left"
    [@animateLeft]
    class="absolute flex min-[708px]:mx-4 justify-center items-center w-20 h-20">
    <img [src]="emojiIcon" class="w-12 h-12 z-1" />
  </div>
  <div
    *ngIf="animate === randomAnimateType.right"
    [@animateRight]="true"
    class="absolute flex min-[708px]:mx-4 justify-center items-center w-20 h-20">
    <img [src]="emojiIcon" class="w-12 h-12 z-1" />
  </div>
  <div
    *ngIf="animate === randomAnimateType.center"
    [@animateCenter]="true"
    class="absolute flex min-[708px]:mx-4 justify-center items-center w-20 h-20">
    <img [src]="emojiIcon" class="w-12 h-12 z-1" />
  </div>
</ng-template>
