import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post } from '@app/shared/models/post';

export interface PostReview {
  pendingPostInfo: Post;
  isSelected: boolean;
}

@Component({
  selector: 'review-post-footer',
  templateUrl: './review-post-footer.component.html',
  styleUrls: ['./review-post-footer.component.scss']
})
export class ReviewPostFooterComponent {
  @Input() checkBoxValue: boolean = true;
  @Output() rejectPost = new EventEmitter();
  @Output() approvePost = new EventEmitter();
  @Output() selectedPost = new EventEmitter();

  constructor() {}

  onRejectPost(): void {
    this.rejectPost.emit(true);
  }

  onApprovePost(): void {
    this.approvePost.emit(true);
  }

  onSelectedPost(checkEvent: any): void {
    this.selectedPost.emit(checkEvent.checked);
  }
}
