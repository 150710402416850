import { Component, Input } from '@angular/core';
import { Story } from '@app/lib/api/stories/api.stories.models';
import { FILE_EXTENSION, MEDIA_TYPE } from '@app/shared/constant';
import { FILE_TYPE_URL, checkUrl } from '@app/shared/models/post';
import { getFromNow } from '../../../models/post';

@Component({
  selector: 'side-stories',
  templateUrl: './side-stories.component.html',
  styleUrls: ['./side-stories.component.scss']
})
export class SideStoriesComponent {
  @Input() dataStory: Story | any;

  mediaType = MEDIA_TYPE;
  getFromNow = getFromNow

  validUrl() {
    let urlString = '';
    let style = FILE_TYPE_URL.thumbnail;
    let fileExtension = FILE_EXTENSION.image;
    if (this.dataStory) {
      urlString = this.dataStory.story_media.image_url;
      if (this.dataStory.story_type === MEDIA_TYPE.video) {
        urlString = this.dataStory.story_media.video_url;
        style = FILE_TYPE_URL.streamVideo;
        fileExtension = FILE_EXTENSION.video;
      }
    }
    return checkUrl(urlString, style) + fileExtension;
  }
}
