import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';

@Directive({
  selector: '[appOtpPaste]'
})
export class OtpPasteDirective {
  @Input('appOtpPaste') otpForm!: FormGroup;
  constructor(private ngControl: NgControl) {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') || '';
    const otpArray = pastedText.slice(0, 6).split('');
    otpArray.forEach((char, index) => {
      const inputName = `digit${index + 1}`;
      this.otpForm.get(inputName)?.setValue(char);
    });
  }
}
