import { createReducer, on } from '@ngrx/store';
import { PostDialogActions } from './post-dialog.actions';
import { PostDialogState } from './post-dialog.state';

const initialState: PostDialogState = {
  poll_id: '',
  previousUrl: '',
  fileUpload: {
    showProgress: false,
    progress: 0
  }
};

export const PostDialogReducer = createReducer(
  initialState,

  on(PostDialogActions.onGetPollId, (state, { poll_id }) => ({
    ...state,
    poll_id
  })),

  on(PostDialogActions.onGetPreviousUrl, (state, { previousUrl }) => ({
    ...state,
    previousUrl
  })),

  on(PostDialogActions.onUploadFile, (state, { fileUpload: { showProgress, progress } }) => ({
    ...state,
    fileUpload: { showProgress, progress }
  }))
);
