import { PostRequest } from '@app/lib/api/post/api.post.model';
import { Post } from '@app/shared/models/post';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const EditPostActions = createActionGroup({
  source: 'Edit Post',
  events: {
    'On Edit Privacy Own Post ': props<{ post: PostRequest; postId: string }>(),
    'On Edit Privacy Shared Post ': props<{ post: PostRequest; postId: string }>()
  }
});

export const EditPostApiActions = createActionGroup({
  source: 'Edit Post Api',
  events: {
    'On Load Edit Privacy Post Success': props<{ post: Post; isSuccess: boolean }>(),
    'On Load Edit Privacy Post Fail': props<{ message: string }>(),
    'On Reset Edit Privacy': emptyProps()
  }
});
